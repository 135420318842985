<div class="list-container w-full !mb-16">
  <h2 class="subtitle" translate>titles.orders</h2>
  <mat-form-field appearance="outline" class="search-field">
    <input
      matInput
      type="search"
      [(ngModel)]="searchField"
      autocomplete="off"
      (keyup)="filterItems()"
      (change)="filterItems()"
      placeholder="{{ 'formFields.search' | translate }}"
    />
    <button
      mat-button
      mat-icon-button
      matSuffix
      (click)="clearSearchField()"
      *ngIf="searchField"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <div fxLayout="column" fxLayoutGap="5px">
    <div
      class="questions-boundary"
      novalidate
      fxLayout="column"
      fxLayoutAlign="center"
    >
      <div *ngFor="let item of filteredItems; let i = index">
        <mat-card class="card-container">
          <div fxLayout="row" class="hanlde-container items-center">
            <fa-icon
              class="list-item-menu-icon"
              [icon]="activityIconMap[item.content.entityType || 'CATEGORY']"
            ></fa-icon>
            <a
              class="text-blue-400 ml-2 cursor-pointer"
              [href]="getOrderUrl(item.content.hubUrlHandler, item.content.id)"
              >{{
                item.contentType === ReportContentType.CATEGORY_ACCESS
                  ? item.content.categoryName
                  : item.content.activityName
              }}</a
            >
          </div>
          <div fxLayout="row" class="text-gray-500 ml-4">
            {{ item.content.clientName }}
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
