<button class="!px-3 !min-w-[56px] !w-fit" mat-icon-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger">
    <img *ngIf="userLoggedIn" referrerpolicy="no-referrer" [src]="userProfile?.picture ? userProfile.picture : noImage" alt="user-image" width="70" height="70" class="mat-elevation-z6 rounded-full w-8 h-8 object-cover">
    <div *ngIf="!userLoggedIn" class="flex">
        <mat-icon class="mr-1">account_circle</mat-icon>
        <p class="text-base text-white hidden md:block" translate>titles.login</p>
    </div>
</button>

<mat-menu #userMenu="matMenu" [overlapTrigger]="false" class="toolbar-dropdown-menu user-menu">
    <span (mouseleave)="userMenuTrigger.closeMenu()">
  <mat-toolbar color="primary">
    <div class="app-version">{{currentApplicationVersion}}</div><br/>
    <div *ngIf="userLoggedIn" fxLayout="row" fxLayoutAlign="space-around center" class="user-info">
      <img referrerpolicy="no-referrer" [src]="userProfile?.picture ? userProfile.picture : noImage" alt="user-image" width="70" height="70" class="rounded mat-elevation-z6">
      <p class="user-name">{{userProfile?.name}} <br> <small>{{userProfile?.email}}</small></p>
    </div>
    <div *ngIf="!userLoggedIn" fxLayout="row" fxLayoutAlign="space-around center" class="user-info">
        <img [src]="noImage" alt="user-image" width="70" height="70" class="rounded mat-elevation-z6">
        <p translate>userMenu.user_anonymous</p>
      </div>
  </mat-toolbar>
    <a *ngIf="userLoggedIn" mat-menu-item (click)="goToUserPreferences()">
        <mat-icon>person</mat-icon>
        <span translate>userMenu.user_preferences</span>
    </a>
    
    <div class="divider"></div>

    <ng-template [ngIf]="userLoggedIn" [ngIfElse]="userloggedout">
        <a mat-menu-item (click)="logOut()">
            <mat-icon>power_settings_new</mat-icon>
            <span translate>userMenu.log_out</span>
        </a>
    </ng-template>

    <ng-template #userloggedout>
        <a mat-menu-item (click)="logIn()">
            <mat-icon>power_settings_new</mat-icon>
            <span translate>userMenu.login_or_register</span>
        </a>
    </ng-template>
    </span>
</mat-menu>