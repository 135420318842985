import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageService } from './image.service';
import { HttpService } from './http.service';
import { QrCodeReaderService } from './qr-code-reader.service';
import { IpLocationService } from './ip-location.service';
import { GeocoderService } from './geocoder.service';
import { FeatureFlagsService } from './feature-flags.service';
import { WorkflowService } from './hub-workflow.service';
import { MatureContentService } from './mature-content.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    ImageService,
    HttpService,
    QrCodeReaderService,
    IpLocationService,
    GeocoderService,
    FeatureFlagsService,
    WorkflowService,
    MatureContentService,
  ],
  schemas: [],
})
export class ServiceModule {}
