import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouteConstants } from '../app.constants';
import { HubEntity } from '../models/hub';
import { HubCreationStatus } from '../models/hub-creation-status-enum';

@Injectable()
export class WorkflowService {

  constructor(private router: Router) {}

  async check(hub: HubEntity, redirect:string = null): Promise<boolean> {
    if (!hub || (hub.creationStatus != HubCreationStatus.TEMPLATE && hub.creationStatus != HubCreationStatus.NONE)) {
      if(redirect) {
        localStorage.setItem('return_from_login_url', redirect);
        localStorage.setItem('return_url', redirect);
      }
      await this.router.navigateByUrl(`${hub ? hub.urlHandler : RouteConstants.hub}/workflow`);
      return false;
    } else if (!redirect) {
      await this.router.navigateByUrl(
        localStorage.getItem(RouteConstants.returnUrl)
      );
    }
    return true;
  }
}
