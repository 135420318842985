import { Component, OnInit } from "@angular/core";
import { AppSettings } from "../../../app.settings";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpService } from "../../../service/http.service";
import { AuthService } from "../../../auth/auth.serviceV2";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../../environments/environment";
import { Location } from "@angular/common";
import ActivityEvent from "../../../models/activity-event-model";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TicketStatus } from "../../../models/ticket-status-enum";
import { setDefaultPageHead } from "../../../util/seo-utils";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-activity-event-scanner",
  templateUrl: "./activity-event-scanner.component.html",
  styleUrls: ["./activity-event-scanner.component.scss"],
})
export class ActivityEventScannerComponent implements OnInit
{

  activityType = "activity-event";
  scannerEnabled: boolean = true;
  user = null;
  hubId = null;
  categoryId = null;
  handler = null;
  activityId = null;
  collaboratos: string[] = [];
  allowed = false;
  showingFeedback = false;
  activity: ActivityEvent = null;

  validAudio;
  invalidAudio;

  protected settings;
  readonly apiUrl = environment.apiUrl;

  constructor(
    private authService: AuthService,
    public translate: TranslateService,
    public router: Router,
    public route: ActivatedRoute,
    public httpService: HttpService,
    public appSettings: AppSettings,
    public snackBar: MatSnackBar,
    protected location: Location,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    this.settings = this.appSettings.settings;
  }

  async ngOnInit() {
    this.settings.loadingSpinner = true;
    setDefaultPageHead(this.titleService, this.metaTagService, false);
    this.user = this.authService.getUserProfile();
    this.hubId = this.route.snapshot.queryParams["hubId"];
    this.categoryId = this.route.snapshot.queryParams["categoryId"];
    this.activityId = this.route.snapshot.queryParams["activityId"];
    this.handler = this.route.snapshot.queryParams["handler"];

    this.validAudio = new Audio();
    this.validAudio.src = "../../../../assets/sounds/valid.mp3";
    this.validAudio.load();

    this.invalidAudio = new Audio();
    this.invalidAudio.src = "../../../../assets/sounds/invalid.mp3";
    this.invalidAudio.load();

    
    const url = `${this.apiUrl}/activity-event/v1/${this.activityId}`;
    try {
      const response = await this.httpService.doGet(url).toPromise();
      this.activity = response;
      this.activityId = response.id;
      this.categoryId = response.categoryId;
      this.hubId = response.hubId;
      this.collaboratos = response.staffEmails;
      if(response.userId === this.user.userId || this.collaboratos.includes(this.user.email)) {
        this.allowed = true;
      }
      this.settings.loadingSpinner = false;
    } catch(error) {
      this.settings.loadingSpinner = false;
      console.log("Error getting collaborators: ", error);
    }
  }

  public async scanSuccessHandler($event: any) {

    //This prevents sending a new request if there is already one being processed
    if( !this.settings.loadingSpinner ) {
      const event = JSON.parse(`${$event}`)
      this.validateCode(event);
    }
  }

  async validateCode(scannerValue: any) {
    this.settings.loadingSpinner = true;
    const url = `${this.apiUrl}/activity-event/v1/ticket-from-staff`;
    try {
      if(!scannerValue.ticketId || !scannerValue.ticketRequestId) {
        throw Error('invalid ticket');
      }
      const { tickets } = await this.httpService.doPut(url, {
        activityId: this.activityId,
        hubId: this.hubId,
        ticketId: scannerValue.ticketId,
        ticketRequestId: scannerValue.ticketRequestId,
        userId: this.user.userId,
      }).toPromise();
      const ticket = tickets.find((t: any) => t.id === scannerValue.ticketId);
      this.settings.loadingSpinner = false;
      if(ticket.status === TicketStatus.CHECKED_IN) {
        this.success();
      } else {
        this.warning();
      }
    } catch(error) {
      this.settings.loadingSpinner = false;
      console.log("Error validating ticket: ", error);
      this.error();
    }
  }

  public enableScanner() {
    this.scannerEnabled = true;
  }

  back() {
    this.location.back();
  }

  success(){
    this.showingFeedback = true;
    this.validAudio.play();
    Swal.fire({
      title: this.translate.instant('messages.ticket_checked_in'),
      icon: 'success',
      confirmButtonColor: '#5D6293',
      didClose: () => {
        this.showingFeedback = false;
      }
    });
  }

  warning(){
    this.showingFeedback = true;
    this.validAudio.play();
    Swal.fire({
      title: this.translate.instant('messages.ticket_checked_out'),
      icon: 'warning',
      confirmButtonColor: '#5D6293',
      didClose: () => {
        this.showingFeedback = false;
      }
    });
  }

  error(){
    this.showingFeedback = true;
    this.invalidAudio.play();
    Swal.fire({
      title: this.translate.instant('messages.invalid_ticket'),
      icon: 'error',
      confirmButtonColor: '#5D6293',
      didClose: () => {
        this.showingFeedback = false;
      }
    });
  }

 /* error(message: string) {
    swal({
      type: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      footer: '<a href>Why do I have this issue?</a>'
    })
  }

  info(message: string) {
    swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      this.confirm = result;
      if (result.value) {
        swal(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })
  }

  warn(message: string) {
     swal({
      type: 'warning',
      title: 'Oops...',
      text: 'This is a warning message !',
      footer: '<a href>Why do I have this issue?</a>'
    })
  }*/

}
