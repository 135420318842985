import { Component, OnInit, Input } from "@angular/core";
import ActivityIconMap from "../../util/activity-icon-map";
import { faHome, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import {
  NavigationMenuEntity,
  NavigationMenuItem,
} from "../../models/navigation-menu";
import { ContentType } from "../../models/content-type-enum";
import ContentToActivity from "../../util/content-to-activity";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { firstValueFrom } from "rxjs";
import { HttpService } from "../../service/http.service";

@Component({
  selector: "app-navigation-menu",
  templateUrl: "./navigation-menu.component.html",
  styleUrls: ["./navigation-menu.component.scss"],
})
export class NavigationMenuComponent implements OnInit {
  private readonly apiUrl = environment.apiUrl;
  readonly faHome = faHome;
  readonly faInfoCircle = faInfoCircle;
  readonly activityIconMap = ActivityIconMap;

  @Input()
  handler: string;

  @Input()
  hubId: string;

  @Input()
  isDefaultOpen: boolean;

  @Input()
  showHome: boolean = true;

  @Input()
  showBautUs: boolean = true;

  items: NavigationMenuItem[] = [];

  constructor(private router: Router, private httpService: HttpService) {}

  ngOnInit(): void {
    this.retrieveHubNavigationMenu();
  }

  goToHome(): void {
    this.router.navigateByUrl(
      `${this.handler}/client-view?hubId=${this.hubId}`
    );
  }

  goToAboutUs(): void {
    this.router.navigateByUrl(`/${this.handler}/about-us?hubId=${this.hubId}`);
  }

  public goToEntity(item: NavigationMenuItem): void {
    const start = this.handler
      ? `${this.handler}/${ContentToActivity[item.type]}`
      : `/~${ContentToActivity[item.type]}`;
    let url = `/${start}/client-view?hubId=${this.hubId}&activityId=${item.activityId}`;
    if (item.type === ContentType.CATEGORY) {
      url = `/${start}/client-view?hubId=${this.hubId}&categoryId=${item.activityId}`;
    }
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(url);
    });
  }

  private async retrieveHubNavigationMenu() {
    const Url = `${this.apiUrl}/navigation-menu/v1/${this.hubId}`;
    try {
      const response: NavigationMenuEntity = await firstValueFrom(
        this.httpService.doGet(Url)
      );
      this.items = response.items;
    } catch (error) {
      console.log(error);
    }
  }
}
