<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <button (click)="close()" mat-button><span translate>tinyAll.back</span></button>
    <div>
      <button (click)="save()" color="accent" mat-raised-button [disabled]="!form || form.invalid || !form.dirty ">
        <span translate>tinyAll.save</span>
      </button>
      <span *ngIf="parentData && parentData.selectedIndex !== undefined">
        <button class="menu-button" mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
        <mat-menu #menu="matMenu">
          <button class="delete" mat-menu-item (click)="canDelete()"><span translate>buttons.delete</span></button>
        </mat-menu>
      </span>
    </div>
</div>

<div class="page-container" fxLayout="row wrap" fxLayoutAlign="center start">
    <h2 class="title" translate>titles.address_and_location</h2>
  <app-address-form
    *ngIf="
      form &&
      location &&
      addressForm
    "
    [form]="form"
    [location]="location"
    [addressForm]="addressForm"
    [alwaysOpen]="true"
  ></app-address-form>
</div>
