import { UntypedFormBuilder, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Component, Input} from '@angular/core';
import AdditionalChargesUtil from '../../util/additionalChargesUtil';

@Component({
  selector: 'app-confirmation-message-management',
  templateUrl: './confirmation-message-management.component.html',
  styleUrls: ['./confirmation-message-management.component.scss']
})
export class ConfirmationMessageManagementComponent {

  @Input() form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {}
}
