import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-payment-link-form',
  templateUrl: './payment-link-form.component.html',
  styleUrls: ['./payment-link-form.component.scss'],
})
export class PaymentLinkFormComponent implements OnInit {
  readonly faLink = faLink;

  @Input() currentPaymentLink?: string;
  @Output() updateLink: EventEmitter<string> = new EventEmitter();

  form;
  loading = false;
  editMode = false;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.createForm();
  }

  get paymentLink() {
    return this.form.get('paymentLink') as UntypedFormControl;
  }

  private createForm() {
    this.form = this.fb.group({
      paymentLink: ['', [Validators.required, RxwebValidators.url()]],
    });
  }

  addUpdatePaymentLink() {
    if (this.form.invalid) return;
    this.updateLink.emit(this.paymentLink.value);
    this.editMode = false;
  }

  editPaymentLink() {
    this.editMode = true;
    this.paymentLink.setValue(this.currentPaymentLink);
  }
}
