import { Component, EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {

  @Output() back: EventEmitter<any> = new EventEmitter();
 
  constructor() {}

  goBack() {
    this.back.emit();
  }

}
