import { Component, Input, Output, EventEmitter } from '@angular/core';
import QUANTITY_SELECTOR from '../../../../util/quantity-selector';
import CURRENCY_SYMBOLS from '../../../../util/currency-symbols';

@Component({
  selector: 'app-table-dish',
  templateUrl: './table-dish.component.html',
  styleUrls: ['./table-dish.component.scss']
})
export class TableDishComponent {

  @Input() item: any;
  @Input() index: number;
  @Output() onQuantityChange = new EventEmitter();
  @Output() onRemove = new EventEmitter();

  readonly quantitySelector = QUANTITY_SELECTOR;
  readonly currencySymbols = CURRENCY_SYMBOLS;
  readonly noImage = '/assets/img/app/default-dish.png';

  isOpen = false;

  constructor() {}

  quantityChange(event) {
    let action = '+';
    let quantity = 0;
    if (this.item.quantity > event.value) {
      quantity = this.item.quantity - event.value;
      action = '-';
    } else {
      quantity =  event.value - this.item.quantity;
    }
    this.item.quantity = event.value;
    this.onQuantityChange.emit({
      item: this.item,
      action, 
      quantity
    });
  }

  remove() {
    this.onRemove.emit();
  }

  getTotal(price, quantity) {
    return Number(price) * Number(quantity);
  }

  get id() {
    return `${this.item.name}${this.item.variantNam}${this.index}`;
  }

  onItemOpen(){
    setTimeout(() => {
      const element = document.getElementById(this.id);
      if (element !== null) {
        element.scrollIntoView();
      }
    }, 200);
  }

}
