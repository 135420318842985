<div class="flex flex-row-reverse items-center justify-between mb-5 gap-1">
  <mat-icon class="text-white !min-w-[24px]">info_outlined</mat-icon>
  <audio *ngIf="parentData.audio" controls [src]="parentData.audio" autoplay></audio>
</div>
<div fxLayout="column" fxLayoutAlign="center center">
  <div class="text-white flex flex-col gap-3 text-justify" [innerHTML]="parentData.content"></div>
  <p *ngIf="parentData.moreInfoLink" class="text-white mt-3">
    <span translate>hubWorkflow.activities_step.more_info_link_text</span>
    <a class="underline" [href]="parentData.moreInfoLink" target="_blank" translate>hubWorkflow.activities_step.more_info_link</a>
  </p>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    class="actions"
    mat-dialog-actions
  >
    <button
      class="border border-white bg-accent text-white px-5 py-0.5 rounded my-3"
      (click)="ok()"
    >
      <span translate>buttons.ok</span>
    </button>
  </div>
</div>
