import { Component, OnInit, Input} from '@angular/core';
import { UntypedFormBuilder,  UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import {TranslateService} from '@ngx-translate/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { CanDeleteDialogComponent } from '../../../../components/can-delete-dialog/can-delete-dialog.component';

@Component({
  selector: 'app-menu-section-list-management',
  templateUrl: './menu-section-list-management.component.html',
  styleUrls: ['./menu-section-list-management.component.scss']
})
export class MenuSectionListManagementComponent implements OnInit {

  @Input()
  sectionListForm: UntypedFormArray;
  @Input()
  actForm: UntypedFormGroup;

  form: UntypedFormGroup;
  sectionName: string;
  selectedQuestion = new Set<number>();


  constructor(
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    protected dialog: MatDialog,
  ) {
    this.sectionName = this.translate.instant('formFields.section');
  }

 ngOnInit(): void {
    this.form = this.fb.group({
      sectionList: this.sectionListForm
    });
  }

  getDishes(section: number) {
    return this.sectionListForm.controls[section].get('dishes') as UntypedFormArray;
  }

  addSection(): void {
    this.sectionListForm.push(this.createSection());
    this.sectionListForm.markAsDirty();
  }

  createSection(): UntypedFormGroup {
    return this.fb.group({
      sectionName: [`${this.sectionName} ${this.sectionListForm.length + 1}`, [Validators.required, RxwebValidators.unique()]],
      sectionDescription: [''],
      dishes: this.fb.array([])
    });
  }

  removeSection(index: number): void {
    this.sectionListForm.removeAt(index);
    for (const control of this.sectionListForm.controls) {
      control.get('sectionName').updateValueAndValidity();
    }
    this.sectionListForm.markAsDirty();
    this.sectionListForm.updateValueAndValidity();
    this.actForm.updateValueAndValidity();
  }

  canRemoveSection(index: number) {
    const dialogRef = this.dialog.open(CanDeleteDialogComponent, {
      minWidth: '240px',
      data: {
        title: this.translate.instant('titles.delete_section'),
        content: this.translate.instant('messages.delete_section'),
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeSection(index);
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    this.moveItemInFormArray(this.sectionListForm, event.previousIndex, event.currentIndex);
    this.sectionListForm.markAsDirty();
  }

  moveItemInFormArray(formArray: UntypedFormArray, previusIndex: number, currentIndex: number) {
    const current = formArray.at(previusIndex);
    formArray.removeAt(previusIndex);
    formArray.insert(currentIndex, current);
  }

  addDish(section: number): void {
    const dishes = this.getDishes(section);
    dishes.push(this.createDish());
    dishes.markAsDirty();
    this.sectionListForm.markAsDirty();
    this.sectionListForm.updateValueAndValidity();
    this.actForm.updateValueAndValidity();
  }

  createDish(): UntypedFormGroup {
    return this.fb.group({
      name: ['', [Validators.required]],
      description: [''],
      image: [''],
      dishOptions: this.fb.array([]),
      available: [true],
    });
  }

  onSectionOpen(sectionId?: string){
    setTimeout(() => {
      const element = document.getElementById(sectionId);
      if (element !== null) {
        element.scrollIntoView();
      }
    }, 200);
  }

}
