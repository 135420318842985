<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
  <button (click)="close()" mat-button>
    <span translate>tinyAll.back</span>
  </button>
  <h2 class="title" translate>titles.request</h2>
</div>

<app-request-category-access-details
  [report]="report"
  (updated)="onUpdate($event)"
>
</app-request-category-access-details>
