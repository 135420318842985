import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, startWith, switchMap} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import { HttpService } from '../../../../service/http.service';
import { environment } from '../../../../../environments/environment';
import SEPARATOR_KEYS_CODES from '../../../../util/separator-keys-codes';

@Component({
  selector: 'app-product-search-bar',
  templateUrl: './product-search-bar.component.html',
  styleUrls: ['./product-search-bar.component.scss']
})
export class ProductSearchBarComponent implements OnInit {

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;


  @Input() items: any;
  @Input() max = 1000;
  @Output() onClickSort: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() onClickFilter: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() onSearhChange: EventEmitter<any> = new EventEmitter();

  private readonly apiUrl = environment.apiUrl;
  private readonly validateTag = /^[ña-zÑA-Z0-9\-]+$/;
  private readonly validateAdd = /[^0-9ña-z\-]/gi;
  search = new UntypedFormControl();
  filteredOptions: Observable<string[]>;
  separatorKeysCodes = SEPARATOR_KEYS_CODES;

  constructor(
    private httpService: HttpService
  ) {}

  ngOnInit() {
      this.filteredOptions = this.search.valueChanges.pipe(
        startWith(null),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(value => this._filter(value)),
      );
  }

  validateKey(event: any): void {
    if (!event.data || typeof event.data !== 'string') {
      return;
    }
    if (!this.validateTag.test(event.data)) {
      this.add({
        input: event.target,
        value: event.target.value
      } as MatChipInputEvent);
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    if (!event.value || !event.value.trim()) {
      return;
    }
    const value = event.value.replace(this.validateAdd, '');
    if (!value) {
      return;
    }

    const readyValue = value.trim().toLocaleLowerCase();
    if (this.validateUnique(readyValue)) { 
      this.items.push(readyValue);
      this.searchChange();
    }
    
    if (input) {
      input.value = '';
    }
    this.search.setValue(null);
    
  }

  remove(item: string): void {
    const index = this.items.indexOf(item);

    if (index >= 0) {
      this.items.splice(index, 1);
    }
    this.searchChange();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.items.pop();
    const value = event.option.viewValue.toLocaleLowerCase();

    if (this.validateUnique(value)) { 
      this.items.push(event.option.viewValue);
      this.searchInput.nativeElement.value = '';
      this.search.setValue(null);
      this.searchChange();
      return;
    }

    this.searchInput.nativeElement.value = '';
    this.search.setValue(null);
  }

  validateUnique(value: string) {
    for (const item of this.items) {
      if (item === value) { return false; }
    }
    return true;
  } 

  searchChange() {
    this.onSearhChange.emit(this.items);
  }

  private async _filter(value: string) {
    if (!value) {
      return [];
    }
    const filterValue = value.toLowerCase();

    const response = await this.httpService
    .doGet(`${this.apiUrl}/product-item-view/v1/search?querySearch=tags%23'${filterValue}*'&page=0&size=20`)
    .toPromise();
    
    if (response && response.content) {
      const tags = new Set();
      for (const ITEM of response.content) {
        for (const TAG of ITEM.tags) {
          const lowerTag = TAG.toLowerCase();
          if (lowerTag.startsWith(filterValue) && !this.items.includes(lowerTag)) {
            tags.add(lowerTag);
          }
        }
      }
      return Array.from(tags) as string[];
    } else {
      return [];
    }
  }

  cleanSearch() {
    this.items.length = 0;
    this.searchChange();
  }

  clear(event: MatChipInputEvent): void {
    this.items.pop();
    this.searchChange();
  }

  openSort(event: MouseEvent) {
    this.onClickSort.emit(event);
  }

  openFilter(event: MouseEvent) {
    this.onClickFilter.emit(event);
  }

  panelOpened() {
    const el: any = document.getElementsByClassName('cdk-overlay-pane')[0];
    if (el) {
      el.classList.add('product-search-bar-panel-overlay');
    }
  }

  panelClosed() {
    const el: any = document.getElementsByClassName('cdk-overlay-pane')[0];
    if (el) {
      el.classList.remove('product-search-bar-panel-overlay');
    }
  }
}
