import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-youtube-url-dialog',
  templateUrl: './youtube-url-dialog.component.html',
  styleUrls: ['./youtube-url-dialog.component.scss']
})
export class YoutubeUrlDialogComponent implements OnInit { 
  youtubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
  form: UntypedFormGroup;
  parentData: any; 
  constructor(
    public fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<YoutubeUrlDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
      this.parentData = data; 
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      url: [this.parentData.url, [
        Validators.required, 
        RxwebValidators.url(), 
        Validators.pattern(this.youtubeRegExp)
      ]],
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  addVideo(): void {
    this.dialogRef.close(this.form.get('url').value);
  }
  
}