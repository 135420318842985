<div class="container">
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
      <button (click)="cancel()" mat-button><span translate>reportsFilter.cancel</span></button>
      <button color="primary" (click)="apply()" mat-button><span translate>reportsFilter.apply</span></button>
  </div>
  <form novalidate [formGroup]="form" fxLayout="column" fxLayoutAlign="center">
    <div class="filter-group-title" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 translate>labels.status</h2>
    </div>
    <div class="filter-row" matRipple fxLayout="row" fxLayoutAlign="space-between center">
      <mat-button-toggle-group formControlName="active" color="primary">
        <mat-button-toggle [value]="null"><span translate>labels.all</span></mat-button-toggle>
        <mat-button-toggle [value]="true"><span translate>productList.active</span></mat-button-toggle>
        <mat-button-toggle [value]="false"><span translate>labels.inactives</span></mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="filter-group-title" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 translate>labels.into_navigation_menu</h2>
    </div>
    <div class="filter-row" matRipple fxLayout="row" fxLayoutAlign="space-between center">
      <mat-button-toggle-group formControlName="includeInMenu" color="primary">
        <mat-button-toggle [value]="null"><span translate>labels.all</span></mat-button-toggle>
        <mat-button-toggle [value]="true"><span translate>buttons.yes</span></mat-button-toggle>
        <mat-button-toggle [value]="false"><span translate>buttons.no</span></mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="filter-group-title" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 translate>reportsDetails.activity_type</h2>
    </div>
    <div class="filter-row" matRipple fxLayout="row" fxLayoutAlign="space-between center">
      <mat-checkbox color="primary" formControlName="ACTIVITY_INFO"><span translate>types.ACTIVITY_INFO</span></mat-checkbox>
    </div>
    <div class="filter-row" matRipple fxLayout="row" fxLayoutAlign="space-between center">
      <mat-checkbox color="primary" formControlName="ACTIVITY_REQUEST"><span translate>types.ACTIVITY_REQUEST</span></mat-checkbox>
    </div>
    <div class="filter-row" matRipple fxLayout="row" fxLayoutAlign="space-between center">
      <mat-checkbox color="primary" formControlName="ACTIVITY_PRODUCT_LISTING"><span translate>types.ACTIVITY_PRODUCT_LISTING</span></mat-checkbox>
    </div>
    <div class="filter-row" matRipple fxLayout="row" fxLayoutAlign="space-between center">
      <mat-checkbox color="primary" formControlName="ACTIVITY_RESERVATION"><span translate>types.ACTIVITY_RESERVATION</span></mat-checkbox>
    </div>
    <div class="filter-row" matRipple fxLayout="row" fxLayoutAlign="space-between center">
      <mat-checkbox color="primary" formControlName="ACTIVITY_MENU"><span translate>types.ACTIVITY_MENU</span></mat-checkbox>
    </div>
    <div class="filter-row" matRipple fxLayout="row" fxLayoutAlign="space-between center">
      <mat-checkbox color="primary" formControlName="ACTIVITY_EVENT"><span translate>types.ACTIVITY_EVENT</span></mat-checkbox>
    </div>
    <div class="filter-row" matRipple fxLayout="row" fxLayoutAlign="space-between center">
      <mat-checkbox color="primary" formControlName="CATEGORY"><span translate>types.CATEGORY</span></mat-checkbox>
    </div>
    <div class="filter-group-title" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 translate>reportsFilter.date_gorup_title</h2>
    </div>
    <div class="filter-row" fxLayout="row" fxLayoutAlign="space-between center">
     <div fxFlex="50">
          <mat-form-field class="date-range" appearance="outline">
            <mat-label><span translate>reportsFilter.date_from</span></mat-label>
            <mat-datetimepicker [touchUi]="true" #dateFrom type="date" [multiYearSelector]="true" [openOnFocus]="true" [timeInterval]="5" mode="auto"></mat-datetimepicker>
            <input matInput (dateChange)="dateChangeFrom($event)" formControlName="dateFrom" [matDatetimepicker]="dateFrom" autocomplete="false">
            <mat-icon *ngIf="form.get('dateFrom').value" (click)="clearField('dateFrom', $event)" matSuffix class="clear-icon">clear</mat-icon>
        </mat-form-field>
      </div>

      <div fxFlex="50">
        <mat-form-field class="date-range" appearance="outline">
          <mat-label><span translate>reportsFilter.date_to</span></mat-label>
          <mat-datetimepicker [touchUi]="true" #dateTo type="date" [multiYearSelector]="true" [openOnFocus]="true" [timeInterval]="5" mode="auto"></mat-datetimepicker>
          <input (dateChange)="dateChangeTo($event)" matInput formControlName="dateTo" [matDatetimepicker]="dateTo" autocomplete="false">
          <mat-icon *ngIf="form.get('dateTo').value" (click)="clearField('dateTo', $event)" matSuffix class="clear-icon">clear</mat-icon>
        </mat-form-field>
      </div>
  </div>

  </form>

</div>