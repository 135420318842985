<mat-expansion-panel *ngIf="form" [ngClass]="{'error': !isServiceTypeValid()}">
    <mat-expansion-panel-header>
        <mat-panel-title translate>subtitles.menu_type_of_service</mat-panel-title>
    </mat-expansion-panel-header>

    <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="7px" class="container" [formGroup]="form">
        <section fxLayout="column" fxLayoutAlign="space-between stretch">
            <mat-checkbox formControlName="inRestaurant">
                <span class="info-tooltip-container" translate>formFields.in_restaurant</span>
            </mat-checkbox>
        </section>
        <section fxLayout="column" fxLayoutAlign="space-between stretch">
            <mat-checkbox formControlName="pickUp">
                <span class="info-tooltip-container" translate>formFields.pick_up</span>
            </mat-checkbox>
        </section>
        <section fxLayout="column" fxLayoutAlign="space-between stretch">
            <mat-checkbox formControlName="delivery">
                <span class="info-tooltip-container" translate>formFields.delivery</span>
            </mat-checkbox>
        </section>
        <mat-error *ngIf="!isServiceTypeValid()" translate>formErrors.one_service_type_required</mat-error>
    </div>
</mat-expansion-panel>