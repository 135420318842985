import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { ServiceModule } from '../../service/service.module';
import { AuthGuard } from '../../auth/auth.guard';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ActivityProductListingClientViewComponent } from './activity-product-listing-client-view/activity-product-listing-client-view.component';
import { ActivityProductListingManagementComponent } from './activity-product-listing-management/activity-product-listing-management.component';
import { ProductSearchBarComponent } from './components/product-search-bar/product-search-bar.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { ProductItemComponent } from './components/product-item/product-item.component';
import { ProductListManagementComponent } from './components/product-list-management/product-list-management.component';
import { ProductItemManagementComponent } from './components/product-item-management/product-item-management.component';
import { CartListComponent } from './components/cart-list/cart-list.component';
import { CartItemComponent } from './components/cart-item/cart-item.component';
import { OrderListClientViewComponent } from './components/order-list-client-view/order-list-client-view.component';
import { OrderListClientViewDialogComponent } from './components/order-list-client-view-dialog/order-list-client-view-dialog.component';
import { OrderDetailsClientViewComponent } from './components/order-details-client-view/order-details-client-view.component';
import { OrderDetailsClientViewDialogComponent } from './components/order-details-client-view-dialog/order-details-client-view-dialog.component';

import { ProductTagPickerComponent } from './components/product-tag-picker/product-tag-picker.component';
import { TrackingStatusManagementComponent } from './components/tracking-status-management/tracking-status-management.component';
import { ComponentsModule } from '../../components/componets.module';
import { DirectivesModule } from '../../directives/directives.module';
import { FormGuard } from '../../guards/form.guard';

export const routes = [
  {
    path: 'client-view',
    component: ActivityProductListingClientViewComponent,
  },
  {
    path: 'manage',
    component: ActivityProductListingManagementComponent,
    canActivate: [AuthGuard],
    canDeactivate: [FormGuard],
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ComponentsModule,
    DirectivesModule,
    ServiceModule,
    RouterModule.forChild(routes),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [],
  declarations: [
    ActivityProductListingClientViewComponent,
    ActivityProductListingManagementComponent,
    ProductSearchBarComponent,
    ProductListComponent,
    ProductItemComponent,
    ProductListManagementComponent,
    ProductItemManagementComponent,
    CartItemComponent,
    CartListComponent,
    OrderListClientViewComponent,
    OrderListClientViewDialogComponent,
    OrderDetailsClientViewComponent,
    OrderDetailsClientViewDialogComponent,
    ProductTagPickerComponent,
    TrackingStatusManagementComponent,
  ],
  exports: [
    ActivityProductListingManagementComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ActivityProductListingModule {}
