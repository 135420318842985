import { Component, Input } from '@angular/core';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { LocationBean } from '../../models/types';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-chosen-address',
  templateUrl: './chosen-address.component.html',
  styleUrls: ['./chosen-address.component.scss'],
})
export class ChosenAddressComponent {
  @Input() chosenLocation: LocationBean | null;

  constructor(
    private translate: TranslateService,
  ) {}

  getCountryName(country: string) {
    return i18nIsoCountries.getName(country, this.translate.getDefaultLang());
  }

}
