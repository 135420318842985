import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-banner',
  templateUrl: './info-banner.component.html',
  styleUrls: ['./info-banner.component.scss']
})
export class InfoBannerComponent { 
  @Input() title: string;
  @Input() description: string;
  @Input() params: any = {};
  @Input() link: string;
  @Input() linkText: string;
}