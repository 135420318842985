import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import * as i18nIsoCountries from 'i18n-iso-countries';
import { AppSettings } from "../../app.settings";
import { AuthService } from "../../auth/auth.serviceV2";

@Component({
  selector: "address-list-dialog",
  templateUrl: "./address-list-dialog.component.html",
  styleUrls: ["./address-list-dialog.component.scss"],
})
export class AddressListDialogComponent {

  parentData: any;
  settings;
  user;
  selectedAddressIndex

  constructor(
    private authService: AuthService,
    private appSettings: AppSettings,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<AddressListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.settings = this.appSettings.settings;
    this.user = this.authService.getUserProfile();
    this.parentData = data;
    const { selectedLocationIndex } = data;
    this.selectedAddressIndex = selectedLocationIndex;
  }

  getCountryName(country: string) {
    return i18nIsoCountries.getName(country, this.translate.getDefaultLang());
  }

  selectAddress(selected) {
    this.selectedAddressIndex = selected;
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close({
      index: this.selectedAddressIndex,
      location: this.user.locations[this.selectedAddressIndex],
    });
  }
}
