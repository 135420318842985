import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { HttpService } from '../../../../service/http.service';
import { environment } from '../../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CanDeleteDialogComponent } from '../../../../components/can-delete-dialog/can-delete-dialog.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProductItemManagementComponent } from '../product-item-management/product-item-management.component';
import REGEX from '../../../../util/regex';

@Component({
  selector: 'app-product-list-management',
  templateUrl: './product-list-management.component.html',
  styleUrls: ['./product-list-management.component.scss']
})
export class ProductListManagementComponent implements OnInit {

  @ViewChild('newItemForm') newItemFormRef:  ProductItemManagementComponent;

  @Input() activityId: string;
  @Input() hubId: string;
  @Input() userId: string;

  readonly apiUrl = environment.apiUrl;
  readonly urlRegx = REGEX.URL
 
  settings: Settings;
  items = [];
  pageSize = 10;
  pageIndex = 0;
  length = 0;
  loading = false;
  loadingSave = false;
  handler = null;

  selectedForm: UntypedFormGroup;
  firstSearch = false;
  searchCriteria = [];

  images = [];

  newItemTemplate: any;

  constructor(
    private translate: TranslateService,
    protected snackBar: MatSnackBar,
    private httpService: HttpService,
    private appSettings: AppSettings,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.settings.loadingSpinner = true;
    this.loading = true;
    this.createForm();
    this.route.paramMap.pipe(
      map((params: ParamMap) => params.get('handler'))
    ).subscribe((value) => {
        this.handler = value;
        this.retrieveContent();
    });
  }

  private async retrieveContent() {
    this.settings.loadingSpinner = false;
    await this.search();
  }
  
  searchCriteriaChanged(items: any) {
    this.pageIndex = 0;
    this.searchCriteria = items;
    this.search();
  } 

  async search() {
    this.loading = true;
    const querySearch = [];
    querySearch.push(`querySearch=(activityId:'${this.activityId}' AND hubId:'${this.hubId}')`);

    let hasSearchCriteria = false;
    for (const word of this.searchCriteria) {
        if (!hasSearchCriteria) {
          querySearch.push(' AND (');
          hasSearchCriteria = true;
        } else {
          querySearch.push(' OR ');
        }
        querySearch.push(`tags%23'${word}' OR name:'*${word}*'`);
    }
    if (hasSearchCriteria) {
      querySearch.push(')');
    }

    if (querySearch.length > 0) {
      querySearch.push('&');
    }

    try {
 
      const itemsUrl = `${this.apiUrl}/activity-product-listing/v1/product-item/search/${this.hubId}?${querySearch.join('')}page=${this.pageIndex}&size=${this.pageSize}&sort=createdOn,desc`;
    
      const response = await this.httpService.doGet(itemsUrl).toPromise();
      if (response) {
        this.items = response.content;
        this.length = response.totalElements;
      } else {
        this.items = [];
        this.length = 0;
      }
      this.loading = false;
      const top = document.getElementById('app-top-search-bar');
      if (top !== null && this.firstSearch) {
        top.scrollIntoView();
      }
      this.firstSearch = true;
      
    } catch (error) {
      this.loading = false;
    }

  }

  pageEvent(pageChangedEvent: any) {
    this.pageIndex = pageChangedEvent.pageIndex;
    this.search();
  }

  createForm() {
    this.selectedForm = this.fb.group({
      id: [''],
      activityId: [this.activityId, Validators.required],
      hubId: [this.hubId, Validators.required],
      userId: [this.userId, Validators.required],
      name: ['', Validators.required],
      infoText: ['', Validators.required],
      options: this.fb.array([], Validators.required),
      tags: this.fb.array([]),
      links: this.fb.control('', [Validators.pattern(this.urlRegx)]),
      active: [true],
      available: [true],
    });

    this.newItemTemplate = {
      id: 'new-item-template',
      activityId: this.activityId,
      hubId: this.hubId,
      userId: this.userId,
      images: [''],
      name: '',
      infoText: '',
      active: true,
      available: true,
      options: [
        {
          description: 'Variant 1',
          price: '0.00',
          available: true,
        }
      ],
      tags: [],
      links: ''
    };
  }

  addNewItem() {
    if (this.loadingSave) {
      return;
    }
    this.newItemFormRef.selectItem();
  }

  showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  save() {
    if (!this.images || this.images.length === 0 || !this.images[0]) {
      this.showSnackBar(this.translate.instant('messages.at_least_one_image'), 'OK', 2000);
      return;
    }
    const ID = this.selectedForm.get('id').value;
    if (!ID || ID === this.newItemTemplate.id) {
      this.create();
    } else {
      this.update();
    }
  }

  async delete() {
    try {
      this.settings.loadingSpinner = true;
      const response: any = await this.httpService.doDelete(`${this.apiUrl}/activity-product-listing/v1/product-item/${this.selectedForm.get('id').value}`).toPromise();
      if (response.id) {
        this.showSnackBar(this.translate.instant('messages.product_deleted'), 'OK', 2000);
        this.selectedForm.reset();
        this.search();
      }
      this.settings.loadingSpinner = false;
    } catch(error) {
      this.settings.loadingSpinner = false;
      this.showSnackBar(this.translate.instant('messages.product_delete_error'), 'OK', 2000);
    }

  }

  canDelete = () => {
    if (this.loading) {
      return;
    }
    const dialogRef = this.dialog.open(CanDeleteDialogComponent, {
      minWidth: '240px',
      data: {
        title: 'titles.delete_product',
        content: 'messages.delete_product',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.delete();
      }
    });
  }



  create() {
    this.loadingSave = true;
    this.selectedForm.disable();
    const url = `${this.apiUrl}/activity-product-listing/v1/product-item`;
    const rawValues = this.selectedForm.getRawValue();
    rawValues['images'] = this.images;
    delete rawValues['id'];
    rawValues['links'] = rawValues['links'] ? [rawValues['links']] : [];
    this.httpService.doPost(url, rawValues).subscribe(
      response => {
        this.loadingSave = false;
        this.selectedForm.get('id').setValue('');
        this.selectedForm.enable();
        this.selectedForm.markAsPristine();
        this.search();
        this.showSnackBar(this.translate.instant('productList.product_save_msg', {
          name: this.selectedForm.get('name').value
        }), 'OK', 3000);
      }, error => {
        this.settings.loadingSpinner = false;
        this.loadingSave = false;
        this.showSnackBar('Error Processing Your Request', 'OK', 2000);
        console.log('Error creating activity: ', error);
      }
    );
  }

  update() {
    this.loadingSave = true;
    this.selectedForm.disable();
    const url = `${this.apiUrl}/activity-product-listing/v1/product-item`;
    const rawValues = this.selectedForm.getRawValue();
    rawValues['images'] = this.images;
    rawValues['links'] = rawValues['links'] ? [rawValues['links']] : [];
    this.httpService.doPut(url, rawValues).subscribe(
      response => {
        this.loadingSave = false;
        this.selectedForm.enable();
        this.selectedForm.markAsPristine();
        this.showSnackBar(this.translate.instant('productList.product_save_msg', {
          name: this.selectedForm.get('name').value
        }), 'OK', 3000);
      }, error => {
        this.settings.loadingSpinner = false;
        this.loadingSave = false;
        this.showSnackBar('Error Processing Your Request', 'OK', 2000);
        console.log('Error creating activity: ', error);
      }
    );
  }

}
