<div
  class="flex xs:justify-between rounded-full items-center border-accent border-[3px] cursor-pointer px-6 py-1 mx-2 my-1"
  (click)="gotoReports()"
>
  <div class="flex items-center justify-center h-[45px]">
    <img
      src="/assets/img/app/hub-manage-your-orders.png"
      alt="Woman looking for a book"
      class="xs:hidden object-contain h-full w-auto"
    />
    <img
      src="/assets/img/app/hub-manage-your-orders-b.png"
      alt="Man on a laptop"
      class="hidden xs:block object-contain h-full w-auto"
    />
  </div>
  <h3 class="text-accent font-semibold text-base ml-4 uppercase xs:mr-4 xs:text-center" translate>
    reports.go_to_reports
  </h3>
  <div class="hidden xs:flex items-center justify-center h-[45px]">
  </div>
</div>
