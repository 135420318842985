import { UntypedFormBuilder, UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Component, Input} from '@angular/core';
import { 
  faLink
 } from '@fortawesome/free-solid-svg-icons';
 import REGEX from '../../../../util/regex';

@Component({
  selector: 'app-custom-links-management',
  templateUrl: './custom-links-management.component.html',
  styleUrls: ['./custom-links-management.component.scss']
})
export class CustomLinksManagementComponent {


  @Input() form: UntypedFormArray;
  @Input() noCard: boolean = false;

  readonly faLink = faLink;
  readonly urlRegx = REGEX.URL; 

  constructor(private fb: UntypedFormBuilder) {}

  get links() {
    return this.form.controls as UntypedFormGroup[];
  }

  addLink() {
    const fg: UntypedFormGroup = this.fb.group({
      name: this.fb.control('', [Validators.required, RxwebValidators.unique()]),
      value: this.fb.control('', [Validators.required, Validators.pattern(this.urlRegx)])
    });
    this.form.push(fg);
  }

  removeLink(index: number): void {
    this.form.removeAt(index);
    this.form.markAsDirty();
  }

  fixURL(id: number): void {
    const control = this.form.controls[id];
    if (control.value.value && !control.value.value.startsWith('https://') && !control.value.value.startsWith('http://')) {
      control.setValue({
        name: control.value.name,
        value:'https://' + control.value.value
      });
    };
  }
}
