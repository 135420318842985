export const AnswerTypeClass = {
    DATE: 'app.tinyall.common.form.answer.DateAnswer',
    DATETIME: 'app.tinyall.common.form.answer.DateTimeAnswer',
    GROUP: 'app.tinyall.common.form.answer.GroupAnswer',
    IMAGE: 'app.tinyall.common.form.answer.ImageAnswer',
    MULTI_CHOICE_PRICE: 'app.tinyall.common.form.answer.MultiChoicePriceAnswer',
    MULTI_CHOICE: 'app.tinyall.common.form.answer.MultiChoiceAnswer',
    NUMBER: 'app.tinyall.common.form.answer.NumberAnswer',
    SINGLE_CHOICE_PRICE: 'app.tinyall.common.form.answer.SingleChoicePriceAnswer',
    SINGLE_CHOICE: 'app.tinyall.common.form.answer.SingleChoiceAnswer',
    TEXT: 'app.tinyall.common.form.answer.TextAnswer',
    TIME: 'app.tinyall.common.form.answer.TimeAnswer',
    GEOPOINT: 'app.tinyall.common.form.answer.GeoPointAnswer',
};