import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

const CURRENCIES = [
  {
    code: 'USD'
  },
  {
    code: 'DOP'
  },
]

@Component({
  selector: 'app-currency-picker',
  templateUrl: './currency-picker.component.html',
  styleUrls: ['./currency-picker.component.scss']
})
export class CurrencyPickerComponent {
  @Input() control: UntypedFormControl;

  currencies = CURRENCIES;

}
