import { Component, OnInit, Input,Output, EventEmitter} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';

@Component({
  selector: 'app-menu-section-list-client-view',
  templateUrl: './menu-section-list-client-view.component.html',
  styleUrls: ['./menu-section-list-client-view.component.scss']
})
export class MenuSectionListClientViewComponent implements OnInit {

  @Input() sectionList: any[];
  @Input() activityId: string;
  @Output() onAddToTable = new EventEmitter();

  private storageKey: string;
  settings: Settings;

  constructor(
    private translate: TranslateService,
    protected dialog: MatDialog,
    protected snackBar: MatSnackBar,
    private appSettings: AppSettings,
  ) {
    this.settings = this.appSettings.settings;
  }

 ngOnInit(): void {
  this.storageKey = `menu/${this.activityId}`;
 }

 getFromLocal() {
  return JSON.parse(localStorage.getItem(this.storageKey));
}

addToLocal(dish) {
  const dishStorageKey = `${dish.sectionName.replace(/ /g, '')}/${dish.name.replace(/ /g, '')}/${dish.variantName.replace(/ /g, '')}`;
  let localTable = this.getFromLocal();
  if (!localTable) {
    localTable = {};
  }
  
  let quantityToAdd = 1;
  if (localTable[dishStorageKey]) {
    quantityToAdd += localTable[dishStorageKey].quantity;
  }

  localTable[dishStorageKey] = {
    key: dishStorageKey,
    ...dish,
    quantity: quantityToAdd
  };

  localStorage.setItem(this.storageKey, JSON.stringify(localTable));
}
// end localStoreage


  addToTable(item, {sectionName, sectionDescription}) {
    const dish = {
      ...item,
      sectionName, 
      sectionDescription
    };
    this.settings.loadingSpinner = true;
    this.addToLocal(dish);
    setTimeout(() => {
      this.showSnackBar(this.translate.instant('messages.dish_added_msg', {
        name: `${dish.name} - ${dish.variantName}`,
        quantity: 1
      }), 'OK', 2000, 'snack-bar-dish');
      this.onAddToTable.emit();
      this.settings.loadingSpinner = false;
    }, 1000);
  }

  showSnackBar(message, action, duration, panelClass = '') {
    this.snackBar.open(message, action, {
      duration: duration,
      panelClass
    });
  }
  
  onSectionOpen(sectionId: string){
    setTimeout(() => {
      const element = document.getElementById(sectionId);
      if (element !== null) {
        element.scrollIntoView();
      }
    }, 200);
  }
}
