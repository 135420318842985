<div fxLayout="row wrap" fxLayoutAlign="center start">
    <div fxFlex="100">
        <mat-card class="select-card">
            <mat-card-content>
                <div class="content" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center" [ngSwitch]="chosenPaymentType">
                    <div class="select-options" *ngSwitchCase="PaymentType.CASH">
                        <div class="option-content" mb-6>
                            <span translate>labels.deliverypay</span>
                            <fa-icon class="method-icon" [icon]="faMoneyBillAlt"></fa-icon>
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                    <div class="select-options" *ngSwitchCase="PaymentType.CC_DELIVERY">
                        <div class="option-content" mb-6>
                            <span translate>labels.cc_delivery_pay</span>
                            <fa-icon class="method-icon" [icon]="faCreditCard"></fa-icon>
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                    <div class="select-options" *ngSwitchCase="PaymentType.CC_IN_ADVANCE">
                        <div class="option-content" mb-6>
                            <span translate>labels.cc_in_advance_pay</span>
                            <fa-icon class="method-icon" [icon]="faCreditCard"></fa-icon>
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                    <div class="select-options" *ngSwitchCase="PaymentType.PAYMENT_LINK">
                        <div class="option-content" mb-6>
                            <span translate>labels.payment_link</span>
                            <fa-icon class="method-icon" [icon]="faLink"></fa-icon>
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                    <div class="select-options" *ngSwitchCase="PaymentType.BANK_TRANSFERENCE">
                        <div class="option-content" mb-6>
                            <span class="payment-method-title" translate>labels.payment_method</span>:
                            <span translate>labels.banktransfer</span>
                            <fa-icon class="method-icon" [icon]="faWallet"></fa-icon>
                        </div>
                    </div>
                    <div class="select-options" *ngSwitchCase="PaymentType.TINYALL_PAY">
                        <div class="option-content" mb-6>
                            <span translate>labels.tinyallpay</span>
                            <fa-icon class="method-icon" [icon]="faCreditCard"></fa-icon>
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <div fxFlex="100" *ngIf="chosenPaymentType === PaymentType.CASH && cashReturn">
        <mat-card class="bank-card">
            <mat-card-content>
                <span class="return-label" translate>labels.return_of</span>: {{getAmount(cashReturn)}}
            </mat-card-content>
        </mat-card>
    </div>

    <div fxFlex="100" *ngIf="chosenPaymentType === PaymentType.BANK_TRANSFERENCE && paymentImage">
        <mat-card class="bank-card">
            <mat-card-content>
                <img class="placeholder-image image" [src]="paymentImage" alt="user" />
            </mat-card-content>
        </mat-card>
    </div>
</div>