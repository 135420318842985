<mat-card [id]="getId()" class="card-container">
    <mat-card-header>
        <img mat-card-avatar class="avatar" referrerpolicy="no-referrer" [src]="comment?.userProfileImage ? comment?.userProfileImage : noImage" alt="user" />
        <mat-card-title>{{comment.userName}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div fxLayout="row" class="rating-container">
            <star-rating [value]="comment.rate" [totalstars]="5" checkedcolor="#29a6a4" uncheckedcolor="#cccccc" size="18px" [readonly]="true"></star-rating>
            <span class="time">{{comment.createdOn | timeAgo}}</span>
        </div>
        <p [class.expanded]="expanded" (click)="expanded = !expanded" class="text">
            {{comment.comment}}
        </p>
        <span *ngIf="comment.likeCount > 0" class="likes">{{comment.likeCount}} {{comment.likeCount > 1 ? 'labels.helpful_comments' : 'labels.helpful_comment' | translate}}</span>

        <section class="actions" fxLayoutAlign="space-between">
            <button [class.hidden]="!user || like" [disabled]="!user || like" mat-button color="accent" class="like-button" (click)="likeComment()"><span translate>messages.like_comment_question</span></button>
            <app-report-abuse-button *ngIf="!reported && user" [user]="user" [entityId]="getReviewId()" [entityType]="type" [entityName]="entityName" [entity]="comment" (reported)="report()"></app-report-abuse-button>
        </section>
    </mat-card-content>
</mat-card>