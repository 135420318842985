

<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <button (click)="close()" mat-button><span translate>tinyAll.back</span></button>
    <h2 class="title" translate>productList.order</h2>
</div>

<div class="page-container" fxLayout="row wrap" fxLayoutAlign="center start">
    <app-order-details-client-view 
        [order]="parentData"
        (updated)="onUpdated()">
    </app-order-details-client-view>
</div>



