import { Component, Input } from '@angular/core';
import CURRENCY_SYMBOLS from '../../util/currency-symbols';

@Component({
  selector: 'app-dummy-summary',
  templateUrl: './dummy-summary.component.html',
  styleUrls: ['./dummy-summary.component.scss']
})
export class DummySummaryComponent {

  @Input() 
  total: any;

  @Input() 
  currency: string;

  readonly currencySymbols = CURRENCY_SYMBOLS;

}
