import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import CURRENCY_SYMBOLS from '../../util/currency-symbols';
import { HubPromoCodeEntity } from '../../models/hub-promo-code';
import { PriceOption, QuestionAnswerMap } from '../../models/types';
import { AnswerTypeClass } from '../../models/answer-type-class';

@Component({
  selector: 'app-questions-summary-view',
  templateUrl: './questions-summary-view.component.html',
  styleUrls: ['./questions-summary-view.component.scss'],
})
export class QuestionsSummaryViewComponent {
  @Input() entityId;
  @Input() hubId;
  @Input() questionAnswerMap: QuestionAnswerMap;
  @Input() promoCodeEntity: HubPromoCodeEntity;
  @Input() totalPrice: string;
  @Input() originalQuestions: any = [];
  @Input() currency: string = 'DOP';
  @Input() additionalCharges = {};
  @Input() itemLabel = 'productList.products';
  @Input() value: string | number = 0;
  @Input() quantity = 0;
  @Input() shipping: string | number = 0;
  @Input() tip = 0;
  @Input() tipForm: UntypedFormGroup;
  
  readonly currencySymbols = CURRENCY_SYMBOLS;

  getPromoDiscount() {
    if (!this.promoCodeEntity || !this.promoCodeEntity.discount || this.promoCodeEntity.discount <= 0) return 0;
    const discount = (Number(this.totalPrice) - Number(this.tip)) * (this.promoCodeEntity.discount / 100);
    return this.promoCodeEntity.topDiscount ? Math.min(discount, this.promoCodeEntity.topDiscount) : discount;
  }

  getQuestionRows() {
    const questionRows = [];
    for (const key in this.questionAnswerMap) {

      const QUESTION = this.questionAnswerMap[key];

      if (QUESTION._class === AnswerTypeClass.SINGLE_CHOICE_PRICE) {
        const option = QUESTION.value as PriceOption;
        questionRows.push({ name: option.option, value: option.price });
      } else if (QUESTION._class === AnswerTypeClass.MULTI_CHOICE_PRICE) {
        const options = QUESTION.value as PriceOption[];
        if (options && options.length > 0) {
          for (const OPTION of options) {
            questionRows.push({ name: OPTION.option, value: OPTION.price });
          }
        }
      }
    }
    return questionRows;
  }

}
