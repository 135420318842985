import { Component, Input } from '@angular/core';
import { faBiking, faShoppingBag, faUtensils } from '@fortawesome/free-solid-svg-icons';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { LocationBean } from '../../models/types';
import { MenuServiceType } from '../../models/menu-service-type-enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-chosen-service-type',
  templateUrl: './chosen-service-type.component.html',
  styleUrls: ['./chosen-service-type.component.scss'],
})
export class ChosenServiceTypeComponent {
  @Input() chosenServiceType: MenuServiceType;
  @Input() chosenLocation: LocationBean | null;

  readonly faUtensils = faUtensils;
  readonly faShoppingBag = faShoppingBag;
  readonly faBiking = faBiking;

  constructor(
    private translate: TranslateService,
  ) {}

  get MenuServiceType() {
    return MenuServiceType;
  }

  getCountryName(country: string) {
    return i18nIsoCountries.getName(country, this.translate.getDefaultLang());
  }

}
