<mat-expansion-panel *ngIf="!noCard; else noCardContent">
  <mat-expansion-panel-header>
    <mat-panel-title translate>subtitles.weekly_schedule</mat-panel-title>
  </mat-expansion-panel-header>

  <form novalidate [formGroup]="form" fxLayout="column" fxLayoutAlign="center">
    <ng-container *ngFor="let day of days; let i = index">
      <div
        class="group-title"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <h2>{{ weekDays[i] }}</h2>
      </div>
      <div class="container-row">
        <div
          class="row"
          fxLayout="row"
          [formGroupName]="day"
          fxLayoutAlign="space-between center"
        >
          <!--<div fxFlex="50" class="from">
                        <mat-form-field appearance="outline">
                            <mat-label><span translate>reportsFilter.date_from</span></mat-label>
                            <mat-datetimepicker [touchUi]="true" #dateFrom type="time" [twelvehour]="true" [openOnFocus]="true" [timeInterval]="5" mode="auto"></mat-datetimepicker>
                            <input matInput formControlName="from" [matDatetimepicker]="dateFrom" autocomplete="false">
                            <mat-icon *ngIf="form.get(day).get('from').value" (click)="clearField(day, 'from', $event)" matSuffix class="clear-icon">clear</mat-icon>
                        </mat-form-field>
                    </div>

                    <div fxFlex="50" class="to">
                        <mat-form-field appearance="outline">
                        <mat-label><span translate>reportsFilter.date_to</span></mat-label>
                        <mat-datetimepicker [touchUi]="true" #dateTo type="time" [twelvehour]="true" [openOnFocus]="true" [timeInterval]="5" mode="auto"></mat-datetimepicker>
                        <input matInput formControlName="to" [matDatetimepicker]="dateTo" autocomplete="false">
                        <mat-icon *ngIf="form.get(day).get('to').value" (click)="clearField(day, 'to', $event)" matSuffix class="clear-icon">clear</mat-icon>
                        </mat-form-field>
                    </div>-->

          <div fxFlex="50" class="from">
            <mat-form-field appearance="outline">
              <mat-label
                ><span translate>reportsFilter.date_from</span></mat-label
              >
              <ngx-mat-timepicker #dateFrom></ngx-mat-timepicker>
              <input
                matInput
                formControlName="from"
                [ngxMatTimepicker]="dateFrom"
                autocomplete="false"
              />
              <mat-icon
                *ngIf="form.get(day).get('from').value"
                (click)="clearField(day, 'from', $event)"
                matSuffix
                class="clear-icon"
                >clear</mat-icon
              >
            </mat-form-field>
          </div>

          <div fxFlex="50" class="from">
            <mat-form-field appearance="outline">
              <mat-label
                ><span translate>reportsFilter.date_to</span></mat-label
              >
              <ngx-mat-timepicker #dateTo></ngx-mat-timepicker>
              <input
                matInput
                formControlName="to"
                [ngxMatTimepicker]="dateTo"
                autocomplete="false"
              />
              <mat-icon
                *ngIf="form.get(day).get('to').value"
                (click)="clearField(day, 'to', $event)"
                matSuffix
                class="clear-icon"
                >clear</mat-icon
              >
            </mat-form-field>
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          *ngIf="
            form.get(day).get('from').value &&
            form.get(day).get('to').value &&
            form.get(day).valid
          "
        >
          <button
            class="copyButton"
            (click)="copyDown(day, i)"
            mat-button
            color="accent"
          >
            <span translate>buttons.repeat_one</span>
          </button>
          <button
            class="copyButton"
            (click)="copyAllDown(day, i)"
            mat-button
            color="accent"
          >
            <span translate>buttons.repeat_all</span>
          </button>
        </div>
      </div>
    </ng-container>
  </form>
</mat-expansion-panel>

<ng-template #noCardContent>
  <form novalidate [formGroup]="form" fxLayout="column" fxLayoutAlign="center">
    <ng-container *ngFor="let day of days; let i = index">
      <div
        class="group-title"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <h2>{{ weekDays[i] }}</h2>
      </div>
      <div class="container-row">
        <div
          class="row"
          fxLayout="row"
          [formGroupName]="day"
          fxLayoutAlign="space-between center"
        >
          <div fxFlex="50" class="from">
            <mat-form-field appearance="outline">
              <mat-label
                ><span translate>reportsFilter.date_from</span></mat-label
              >
              <ngx-mat-timepicker #dateFrom></ngx-mat-timepicker>
              <input
                matInput
                formControlName="from"
                [ngxMatTimepicker]="dateFrom"
                autocomplete="false"
              />
              <mat-icon
                *ngIf="form.get(day).get('from').value"
                (click)="clearField(day, 'from', $event)"
                matSuffix
                class="clear-icon"
                >clear</mat-icon
              >
            </mat-form-field>
          </div>

          <div fxFlex="50" class="from">
            <mat-form-field appearance="outline">
              <mat-label
                ><span translate>reportsFilter.date_to</span></mat-label
              >
              <ngx-mat-timepicker #dateTo></ngx-mat-timepicker>
              <input
                matInput
                formControlName="to"
                [ngxMatTimepicker]="dateTo"
                autocomplete="false"
              />
              <mat-icon
                *ngIf="form.get(day).get('to').value"
                (click)="clearField(day, 'to', $event)"
                matSuffix
                class="clear-icon"
                >clear</mat-icon
              >
            </mat-form-field>
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          *ngIf="
            form.get(day).get('from').value &&
            form.get(day).get('to').value &&
            form.get(day).valid
          "
        >
          <button
            class="copyButton"
            (click)="copyDown(day, i)"
            mat-button
            color="accent"
          >
            <span translate>buttons.repeat_one</span>
          </button>
          <button
            class="copyButton"
            (click)="copyAllDown(day, i)"
            mat-button
            color="accent"
          >
            <span translate>buttons.repeat_all</span>
          </button>
        </div>
      </div>
    </ng-container>
  </form>
</ng-template>
