import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-slot-capacity-picker',
  templateUrl: './slot-capacity-picker.component.html',
  styleUrls: ['./slot-capacity-picker.component.scss']
})
export class SlotCapacityPickerComponent {
  @Input() control: UntypedFormControl;
}
