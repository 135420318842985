import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormArray,
  Validators,
} from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { AppSettings } from '../../../app.settings';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from '../../../service/http.service';
import { ImageService } from '../../../service/image.service';
import { BaseFormComponent } from '../../baseform.component';
import { AuthService } from '../../../auth/auth.serviceV2';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DatetimeAdapter } from '@mat-datetimepicker/core';
import MenuManagementUtil from '../../../util/menuManagementUtil';
import AdditionalChargesUtil from '../../../util/additionalChargesUtil';
import { encodeUUID } from '../../../util/encode';
import { ContentType } from '../../../models/content-type-enum';
import { RichTextEditorComponent } from '../../../components/rich-text-editor/rich-text-editor.component';
import { ShortUrlHandlerComponent } from '../../../components/short-url-handler/short-url-handler.component';
import { firstValueFrom } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { setDefaultPageHead } from '../../../util/seo-utils';
import {
  ActivityMenuEntity,
  CreateActivityMenuCommand,
  UpdateActivityMenuCommand,
} from '../../../models/activity-menu';
import {
  getMenuServiceTypeList,
  MenuServiceType,
} from '../../../models/menu-service-type-enum';
import { atLeastOneCheckboxCheckedValidator } from '../../../util/form-utils';

@Component({
  selector: 'app-activity-menu-management',
  templateUrl: './activity-menu-management.component.html',
  styleUrls: ['./activity-menu-management.component.scss'],
})
export class ActivityMenuManagementComponent
  extends BaseFormComponent
  implements OnInit
{

  @Input() hubIdParam: string;
  @Input() categoryIdParam: string;
  @Input() activityIdParam: string;
  @Output() next: EventEmitter<any> = new EventEmitter();
  
  activityType = 'activity-menu';
  additionalChargesForm: UntypedFormArray;
  sectionListForm: UntypedFormArray;

  serviceTypesForm;
  

  constructor(
    private authService: AuthService,
    public translate: TranslateService,
    public fb: UntypedFormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    public httpService: HttpService,
    public appSettings: AppSettings,
    public snackBar: MatSnackBar,
    public imageService: ImageService,
    public dialog: MatDialog,
    public _adapter: DatetimeAdapter<Date>,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    super(
      httpService,
      appSettings,
      snackBar,
      imageService,
      router,
      dialog,
      translate
    );
  }

  async ngOnInit() {
    this.settings.loadingSpinner = true;
    setDefaultPageHead(this.titleService, this.metaTagService, false);
    await this.authService.isAuthenticated();
    this.user = this.authService.getUserProfile();
    this.hubId = this.route.snapshot.queryParams["hubId"] || this.hubIdParam;
    this.categoryId = this.route.snapshot.queryParams["categoryId"] || this.categoryIdParam;
    this.activityId = this.route.snapshot.queryParams["activityId"] || this.activityIdParam;
    this.originId = this.route.snapshot.queryParams["originId"];
    this.handler = this.route.snapshot.queryParams['handler'];

    this.serviceTypesForm = this.fb.group(
      {
        inRestaurant: [false],
        pickUp: [false],
        delivery: [false],
      },
      {
        validators: atLeastOneCheckboxCheckedValidator(),
      }
    );

    this.form = this.fb.group({
      id: [],
      hubId: [this.hubId],
      userId: [this.user?.userId],
      categoryId: [this.categoryId ? this.categoryId : ''],
      name: ['', Validators.required],
      order: [0, [Validators.required, RxwebValidators.numeric()]],
      acceptOrder: [true],
      active: [true],
      unListed: [false],
      includeInMenu: [true],
      clonable: [false],
      infoText: ['', Validators.required],
      newRequestMessage: [""],
      tableRequired: [false],
      qrCodeScanRequired: [false],
      sectionList: this.fb.array([]),
      additionalCharges: this.fb.array([]),
      serviceTypes: this.serviceTypesForm,
    });

    this.sectionListForm = this.form.get('sectionList') as UntypedFormArray;
    this.additionalChargesForm = this.form.get(
      'additionalCharges'
    ) as UntypedFormArray;

    await this.setIsHubNavigationMenuFull();

    if (this.activityId || this.originId) {
      const url = `${this.apiUrl}/activity-menu/v1/${this.activityId ? this.activityId : this.originId}`;
      try {
        let response: ActivityMenuEntity = await firstValueFrom(
          this.httpService.doGet(url)
        );
        if (this.activityId) {
          this.activityId = response.id;
          this.hubId = response.hubId;
          this.categoryId = response.categoryId;
        } else {
          response = {
            ...response,
            id: null,
            hubId: this.hubId,
            userId: this.user?.userId,
            categoryId: this.categoryId ? this.categoryId : "",
            active: false,
          };
        }
        this.setForm(response);
        this.form.markAllAsTouched();
        this.form.updateValueAndValidity();
        if (response.images && response.images.length > 0) {
          this.images = response.images;
        } else {
          this.images = [''];
        }
        if (this.originId) {
          await this.cloneImages();
          this.removeOriginId();
        }
        setTimeout(() => {
          this.settings.loadingSpinner = false;
        }, 300);
      } catch (error) {
        this.settings.loadingSpinner = false;
        console.log('Error getting activity: ', error);
      }
    } else {
      this.images = [''];
      if (this.isHubNavigationMenuFull) {
        this.includeInMenu.disable();
        this.form.patchValue({ includeInMenu: false });
        this.showNavigationMenuLink = true;
      }
      this.settings.loadingSpinner = false;
    }
  }

  get name(): UntypedFormControl {
    return this.form.get('name') as UntypedFormControl;
  }

  get info(): UntypedFormControl {
    return this.form.get('infoText') as UntypedFormControl;
  }

  get newRequestMessage(): UntypedFormControl {
    return this.form.get("newRequestMessage") as UntypedFormControl;
  }

  get acceptOrder() {
    return this.form.get('acceptOrder');
  }

  get active() {
    return this.form.get('active');
  }

  get unListed() {
    return this.form.get('unListed');
  }

  get includeInMenu() {
    return this.form.get('includeInMenu');
  }

  get order() {
    return this.form.get('order');
  }

  get inRestaurant() {
    return this.serviceTypesForm.get('inRestaurant');
  }

  get pickUp() {
    return this.serviceTypesForm.get('pickUp');
  }

  get delivery() {
    return this.serviceTypesForm.get('delivery');
  }

  get qrCode() {
    if (!this.handler || !this.hubId || !this.activityId) {
      return false;
    }
    return `${ShortUrlHandlerComponent.shortActCatUrl(
      this.activityId
    )}?code=${encodeUUID(this.activityId)}`;
  }

  canGoBack(): boolean {
    this.forceWarming = this.sectionListForm.dirty;
    return super.canGoBack();
  }

  setForm(response: any) {
    this.form.patchValue({ id: response.id });
    this.form.patchValue({ hubId: response.hubId });
    this.form.patchValue({ userId: response.userId });
    this.form.patchValue({ categoryId: response.categoryId });
    this.form.patchValue({ name: response.name });
    this.form.patchValue({ acceptOrder: response.acceptOrder });
    this.form.patchValue({ tableRequired: response.tableRequired });
    this.form.patchValue({ qrCodeScanRequired: response.qrCodeScanRequired });
    this.form.patchValue({ active: response.active });
    this.form.patchValue({ unListed: response.unListed });
    this.form.patchValue({ includeInMenu: response.includeInMenu });
    this.form.patchValue({ clonable: response.clonable });
    this.form.patchValue({ order: response.order });
    this.form.patchValue({ infoText: response.infoText });
    this.form.patchValue({ newRequestMessage: response.newRequestMessage });

    this.serviceTypesForm.patchValue({
      inRestaurant: response.serviceTypes
        ? response.serviceTypes.includes(MenuServiceType.RESTAURANT)
        : false,
    });
    this.serviceTypesForm.patchValue({
      pickUp: response.serviceTypes
        ? response.serviceTypes.includes(MenuServiceType.PICKUP)
        : false,
    });
    this.serviceTypesForm.patchValue({
      delivery: response.serviceTypes
        ? response.serviceTypes.includes(MenuServiceType.DELIVERY)
        : false,
    });

    if (!response.includeInMenu && this.isHubNavigationMenuFull) {
      this.includeInMenu.disable();
      this.showNavigationMenuLink = true;
    }

    this.sectionListForm.clear();
    if (response.sectionList) {
      for (const SECTION of response.sectionList) {
        this.sectionListForm.push(
          MenuManagementUtil.createMenuFromResponse(SECTION, this.fb)
        );
      }
    }

    this.descriptionImages = RichTextEditorComponent.getImagesSrcFromHtmlString(
      response.infoText
    );

    this.confirmationMessageImages = RichTextEditorComponent.getImagesSrcFromHtmlString(
      response.newRequestMessage
    );

    this.additionalChargesForm.clear();
    if (response.additionalCharges) {
      AdditionalChargesUtil.createAdditionalChargesFromResponse(
        response.additionalCharges,
        this.fb,
        this.additionalChargesForm
      );
    }

    this.serviceTypesForm.markAsPristine();
    this.form.markAsPristine();
    this.originalActivity = response;
  }

  onSubmit() {
    if (this.activityId) {
      this.update();
    } else {
      this.create();
    }
  }

  async create() {
    if (!this.images || this.images.length === 0 || !this.images[0]) {
      this.showSnackBar(
        this.translate.instant('messages.at_least_one_image_or_video'),
        'OK',
        2000
      );
      return;
    }
    this.settings.loadingSpinner = true;
    const url = `${this.apiUrl}/${this.activityType}/v1`;
    const rawValues = this.form.getRawValue();
    rawValues.additionalCharges = AdditionalChargesUtil.cleanContent(
      rawValues.additionalCharges
    );
    rawValues.images = this.images;
    rawValues.infoText = await RichTextEditorComponent.uploadImages(
      rawValues.infoText,
      this.descriptionImages,
      this.imageService
    );

    rawValues.newRequestMessage = await RichTextEditorComponent.uploadImages(
      rawValues.newRequestMessage,
      this.confirmationMessageImages,
      this.imageService
    );

    if (!this.sectionListForm.length) {
      delete rawValues.sectionList;
    }

    const {
      hubId,
      userId,
      categoryId,
      name,
      infoText,
      newRequestMessage,
      order,
      active,
      unListed,
      includeInMenu,
      clonable,
      images,
      additionalCharges,
      sectionList,
      acceptOrder,
      tableRequired,
      qrCodeScanRequired,
      serviceTypes,
    } = rawValues;

    const { inRestaurant, pickUp, delivery } = serviceTypes;

    const body: CreateActivityMenuCommand = {
      hubId,
      userId,
      categoryId,
      name,
      infoText,
      newRequestMessage,
      order,
      active,
      unListed,
      includeInMenu,
      clonable,
      images,
      additionalCharges,
      sectionList,
      acceptOrder,
      tableRequired,
      qrCodeScanRequired,
      serviceTypes: getMenuServiceTypeList(inRestaurant, pickUp, delivery),
    };

    try {
      const response: ActivityMenuEntity = await firstValueFrom(
        this.httpService.doPost(url, body)
      );
      this.activityId = response.id;
      this.categoryId = response.categoryId;
      this.hubId = response.hubId;
      this.setForm(response);
      this.validateActiveLimitReached(
        rawValues['active'],
        response.active,
        ContentType.ACTIVITY_MENU
      );
      this.showSnackBar('Operation successful ', 'OK', 2000);
    } catch (error) {
      this.showSnackBar('Error Processing Your Request', 'OK', 2000);
      console.log('Error creating activity: ', error);
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  async update() {
    if (!this.images || this.images.length === 0 || !this.images[0]) {
      this.showSnackBar(
        this.translate.instant('messages.at_least_one_image_or_video'),
        'OK',
        2000
      );
      return;
    }
    this.settings.loadingSpinner = true;
    const url = `${this.apiUrl}/${this.activityType}/v1`;
    const rawValues = this.form.getRawValue();
    rawValues.additionalCharges = AdditionalChargesUtil.cleanContent(
      rawValues.additionalCharges
    );
    rawValues.images = this.images;
    rawValues.infoText = await RichTextEditorComponent.uploadImages(
      rawValues.infoText,
      this.descriptionImages,
      this.imageService
    );

    rawValues.newRequestMessage = await RichTextEditorComponent.uploadImages(
      rawValues.newRequestMessage,
      this.confirmationMessageImages,
      this.imageService
    );

    if (!this.sectionListForm.length) {
      delete rawValues.sectionList;
    }

    const {
      id,
      hubId,
      userId,
      categoryId,
      name,
      infoText,
      newRequestMessage,
      order,
      active,
      unListed,
      includeInMenu,
      clonable,
      images,
      additionalCharges,
      sectionList,
      acceptOrder,
      tableRequired,
      qrCodeScanRequired,
      serviceTypes,
    } = rawValues;

    const { inRestaurant, pickUp, delivery } = serviceTypes;

    const body: UpdateActivityMenuCommand = {
      id,
      hubId,
      userId,
      categoryId,
      name,
      infoText,
      newRequestMessage,
      order,
      active,
      unListed,
      includeInMenu,
      clonable,
      images,
      additionalCharges,
      sectionList,
      acceptOrder,
      tableRequired,
      qrCodeScanRequired,
      serviceTypes: getMenuServiceTypeList(inRestaurant, pickUp, delivery),
    };
    try {
      const response: ActivityMenuEntity = await firstValueFrom(
        this.httpService.doPut(url, body)
      );
      this.activityId = response.id;
      this.categoryId = response.categoryId;
      this.hubId = response.hubId;
      this.setForm(response);
      this.validateActiveLimitReached(
        rawValues['active'],
        response.active,
        ContentType.ACTIVITY_MENU
      );
      this.showSnackBar('Operation successful ', 'OK', 2000);
      this.next.emit();
    } catch (error) {
      this.validateCreatedLimitReached(error, ContentType.ACTIVITY_MENU);
      this.showSnackBar('Error Processing Your Request', 'OK', 2000);
      console.log('Error updating activity: ', error);
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  canDelete() {
    super.canDelete(
      'titles.delete_activity',
      'messages.delete_activity',
      async () => {
        try {
          this.settings.loadingSpinner = true;
          await firstValueFrom(
            this.httpService.doDelete(
              `${this.apiUrl}/${this.activityType}/v1/${this.activityId}`
            )
          );
          this.showSnackBar(
            this.translate.instant('messages.activity_deleted'),
            'OK',
            2000
          );
          this.back();
        } catch (error) {
          this.validateCreatedLimitReached(error, ContentType.ACTIVITY_MENU);
          this.showSnackBar(
            this.translate.instant('messages.activity_delete_error'),
            'OK',
            2000
          );
        } finally {
          this.settings.loadingSpinner = false;
        }
      }
    );
  }

  isDirty(): boolean {
    const dirtyDishes = this.sectionListForm.controls.some((control) => {
      return (control.get('dishes') as UntypedFormArray).dirty == true;
    });

    return (
      this.form &&
      (this.form.dirty || this.sectionListForm.dirty || dirtyDishes)
    );
  }

  isInvalid(): boolean {
    const invalidDishes = this.sectionListForm.controls.some((control) => {
      return (control.get('dishes') as UntypedFormArray).invalid == true;
    });
    return (
      !this.form ||
      this.form.invalid ||
      this.sectionListForm.invalid ||
      invalidDishes
    );
  }

  isServiceTypeValid(): boolean {
    return this.inRestaurant.value || this.pickUp.value || this.delivery.value;
  }
}
