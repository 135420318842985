<mat-form-field class="field" floatLabel="never" appearance="standard">
  <mat-chip-list #chipList aria-label="Tag selection">
    <mat-chip
      color="primary"
      *ngFor="let item of items.controls; let i = index"
      selectable
      removable
      (removed)="remove(i)">
        <p class="chip-text">{{item.value}}</p>
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      [hidden]="items.length >= max"
      [disabled]="items.length >= max"
      placeholder="{{'hubManagement.tags' | translate}}"
      #tagInput
      [formControl]="tag"
      [matAutocomplete]="auto"
      matAutocompletePosition="below"
      panelWidth="100%"
      class="tag-bar-panel"
      [matChipInputFor]="chipList"
      [matChipInputAddOnBlur]="true"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (input)="validateKey($event)"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete"
      matAutocompletePosition="below"
      (opened)="panelOpened()" 
      (closed)="panelClosed()" 
      minWidth="100%" 
      panelClass="tag-bar-panel" 
      backdropClass="tag-bar-panel" 
      class="tag-bar-panel" 
      (optionSelected)="selected($event)">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{option}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>