<div class="page-container" *ngIf="comments">
  <div fxLayoutAlign="space-between enter">
    <h1 class="subtitle" translate>subtitles.comments</h1>
    <button
      *ngIf="!editingComment && !addingComment"
      mat-flat-button
      class="link"
      (click)="onAddMode()"
    >
      <span translate>buttons.add_a_comment</span>
    </button>
  </div>

  <mat-card class="card-container" *ngIf="addingComment">
    <mat-card-content>
      <form
        novalidate
        [formGroup]="form"
        fxLayout="column"
        fxLayoutAlign="center"
      >
        <mat-form-field appearance="outline">
          <textarea
            matInput
            matTextareaAutosize
            rows="6"
            placeholder="{{ 'placeholders.your_comment' | translate }}"
            formControlName="comment"
          ></textarea>
          <mat-error *ngIf="commentField.hasError('required')" translate
            >formErrors.field_required</mat-error
          >
        </mat-form-field>
        <mat-error
          *ngIf="commentField.value && commentField.value.length > 300"
          class="comment-error"
          translate
          >formErrors.comment_max_length</mat-error
        >
        <div class="grid grid-cols-2 rounded overflow-hidden mt-2" *ngIf="!client">
          <button
            class="bg-primary text-white p-2"
            [ngClass]="{ 'brightness-50 shadow-inner': !internalField.value }"
            (click)="internalField = true"
          >
            <span translate>labels.internal</span>
          </button>
          <button
            class="bg-accent text-white p-2"
            [ngClass]="{ 'brightness-50 shadow-inner': internalField.value }"
            (click)="internalField = false"
          >
            <span translate>labels.external</span>
          </button>
        </div>
      </form>

      <section class="actions" fxLayoutAlign="start">
        <button
          mat-raised-button
          color="accent"
          class="save-button"
          (click)="onAdd()"
          [disabled]="
            loading ||
            !form ||
            !form.dirty ||
            form.invalid ||
            (commentField.value && commentField.value.length > 300)
          "
        >
          <span *ngIf="!loading" translate>buttons.save_your_comment</span>
          <mat-spinner
            class="loading-spinner"
            diameter="25"
            *ngIf="loading"
          ></mat-spinner>
        </button>

        <button
          *ngIf="!loading"
          mat-button
          class="cancel-button"
          (click)="cancel()"
        >
          <span translate>buttons.cancel</span>
        </button>
      </section>
    </mat-card-content>
  </mat-card>

  <div
    *ngIf="comments.length > 0"
    class="filter-container"
    fxLayoutAlign="start center"
  >
    <mat-icon>sort</mat-icon>
    <mat-button-toggle-group [(ngModel)]="sort" (change)="onSort()">
      <mat-button-toggle value="desc"
        ><span translate>buttons.most_recent</span></mat-button-toggle
      >
      <mat-button-toggle value="asc"
        ><span translate>buttons.less_recent</span></mat-button-toggle
      >
    </mat-button-toggle-group>
  </div>
  <app-reports-comment
    *ngFor="let comment of comments"
    [client]="client"
    [user]="user"
    [comment]="comment"
    [editingComment]="editingComment"
    (onEditModeChanged)="onEditModeChanged($event)"
    (onEdited)="onEdited($event)"
    (onDeleted)="onDeleted($event)"
  >
  </app-reports-comment>
  <!--<mat-paginator *ngIf="length > pageSize" #paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent($event)">
    </mat-paginator>-->
  <div *ngIf="comments.length === 0" class="no-comment">
    <fa-icon class="no-comment-icon" [icon]="noCommentIcon"></fa-icon>
    <p translate>messages.no_comments</p>
    <section *ngIf="!editingComment" class="actions" fxLayoutAlign="center">
      <button
        mat-raised-button
        color="accent"
        class="save-button"
        (click)="onAddMode()"
      >
        <span translate>buttons.add_your_comment</span>
      </button>
    </section>
  </div>
</div>
