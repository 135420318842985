import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map } from 'rxjs/operators';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { HttpService } from '../../../../service/http.service';
import { environment } from '../../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-cart-list',
  templateUrl: './cart-list.component.html',
  styleUrls: ['./cart-list.component.scss'],
})
export class CartListComponent implements OnInit {
  @Input() activityId: string;
  @Input() hubId: string;
  @Input() currency: string;

  readonly faShoppingCart = faShoppingCart;
  readonly apiUrl = environment.apiUrl;

  settings: Settings;
  items = [];
  products = new Map();
  pageSize = 10000;
  pageIndex = 0;
  loading = false;
  itemLoading = false;
  handler = null;

  filterForm: UntypedFormGroup;
  searchCriteria = [];

  selectedForm: UntypedFormGroup;
  summary = {
    quantity: 0,
    total: 0,
  };
  private mainContent;
  private cartStorageKey: string;

  constructor(
    private translate: TranslateService,
    protected snackBar: MatSnackBar,
    private httpService: HttpService,
    private appSettings: AppSettings,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.cartStorageKey = `product-list/${this.activityId}`;
    this.mainContent = document.getElementById('main-content');
    this.settings.loadingSpinner = true;
    this.loading = true;
    this.createForm();
    this.route.paramMap
      .pipe(map((params: ParamMap) => params.get('handler')))
      .subscribe((value) => {
        this.handler = value;
        this.retrieveContent();
      });
  }

  searchCriteriaChanged(items: any) {
    this.pageIndex = 0;
    this.searchCriteria = items;
    this.search();
  }

  // local Storage
  getFromLocal() {
    return JSON.parse(localStorage.getItem(this.cartStorageKey));
  }

  addToLocal(productId, variant, quantity, price) {
    const productStorageKey = `${productId}/${variant.replace(/ /g, '')}`;
    let localCart = this.getFromLocal();
    if (!localCart) {
      localCart = {};
    }

    localCart[productStorageKey] = {
      productId,
      variant,
      price: Number(price),
      quantity: quantity,
    };

    localStorage.setItem(this.cartStorageKey, JSON.stringify(localCart));
  }

  removeFromLocal(productId, variant) {
    const productStorageKey = `${productId}/${variant.replace(/ /g, '')}`;
    let localCart = this.getFromLocal();
    if (!localCart) {
      localCart = {};
    }

    if (localCart[productStorageKey]) {
      delete localCart[productStorageKey];
    }

    localStorage.setItem(this.cartStorageKey, JSON.stringify(localCart));
  }

  clearLocal() {
    localStorage.setItem(this.cartStorageKey, JSON.stringify({}));
    this.resetItems();
  }

  getVariantMap(options) {
    const variants = {};
    for (const variant of options) {
      variants[variant.description] = Number(variant.price);
    }
    return variants;
  }

  // end local Storage

  resetItems() {
    this.products.clear();
    this.items = [];
    this.setSummary();
    this.loading = false;
    this.mainContent.scrollTop = 0;
  }

  async search() {
    this.loading = true;
    const querySearch = [];
    querySearch.push(
      `querySearch=(activityId:'${this.activityId}' AND hubId:'${this.hubId}')`
    );

    const localCart = this.getFromLocal();
    let hasProducts = false;
    this.items = [];
    this.products.clear();
    if (localCart) {
      for (const productKey of Object.keys(localCart)) {
        if (!hasProducts) {
          querySearch.push('AND (');
          hasProducts = true;
        } else {
          querySearch.push(' OR ');
        }
        querySearch.push(`id:'${localCart[productKey].productId}'`);
        this.items.push(localCart[productKey]);
      }
      if (hasProducts) {
        querySearch.push(')');
      }
    }

    if (!hasProducts) {
      this.resetItems();
      return;
    }

    if (querySearch.length > 0) {
      querySearch.push('&');
    }

    try {
      const itemsUrl = `${
        this.apiUrl
      }/product-item-view/v1/search?${querySearch.join('')}page=${
        this.pageIndex
      }&size=${this.pageSize}&sort=createdOn,desc`;
      const response = await this.httpService.doGet(itemsUrl).toPromise();
      if (response) {
        for (const product of response.content) {
          product['variants'] = this.getVariantMap(product.options);
          this.products.set(product.id, product);
        }
      } else {
        this.products.clear();
      }
      this.setSummary();
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  }

  createForm() {
    this.selectedForm = this.fb.group({
      id: [''],
      variant: [null],
      quantity: [1],
    });
  }

  quantityChange(event: any) {
    this.itemLoading = true;
    this.selectedForm.disable();
    this.addToLocal(
      this.selectedForm.get('id').value,
      this.selectedForm.get('variant').value.description,
      this.selectedForm.get('quantity').value,
      this.selectedForm.get('variant').value.price
    );
    this.setSummary();
    this.itemLoading = false;
    this.selectedForm.enable();
    const msg = this.translate.instant(
      event.action === '+'
        ? 'productList.product_added_msg'
        : 'productList.product_removed_msg',
      {
        name: `${event.name} - ${
          this.selectedForm.get('variant').value.description
        }`,
        quantity: event.quantity,
      }
    );
    this.showSnackBar(msg, 'OK', 2000);
  }

  removeFromCart() {
    const ID = this.selectedForm.get('id').value;
    const VARIANT = this.selectedForm.get('variant').value.description;
    const QUANTITY = this.selectedForm.get('quantity').value;
    this.itemLoading = true;
    this.selectedForm.disable();
    this.removeFromLocal(ID, VARIANT);
    let index = 0;
    for (const item of this.items) {
      if (item.productId === ID && item.variant === VARIANT) {
        break;
      }
      index++;
    }
    this.items.splice(index, 1);
    this.setSummary();

    this.mainContent.scrollTop = Math.max(this.mainContent.scrollTop - 800, 0);

    this.selectedForm.reset();
    this.itemLoading = false;
    this.selectedForm.enable();
    this.showSnackBar(
      this.translate.instant('productList.product_removed_msg', {
        name: `${this.products.get(ID).name} - ${VARIANT}`,
        quantity: QUANTITY,
      }),
      'OK',
      2000
    );
  }

  showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  setSummary() {
    this.summary = this.items.reduce(
      (prev, item) => {
        const product = this.products.get(item.productId);
        if (
          product &&
          (product.variants[item.variant] ||
            product.variants[item.variant] === 0)
        ) {
          prev.quantity += item.quantity;
          prev.total += product.variants[item.variant] * item.quantity;
        }
        return prev;
      },
      {
        quantity: 0,
        total: 0,
      }
    );
  }

  getShoppingList() {
    const shoppingList = [];
    for (const item of this.items) {
      const product = this.products.get(item.productId);
      if (
        product &&
        (product.variants[item.variant] || product.variants[item.variant] === 0)
      ) {
        const shippingItem = {
          productItemId: item.productId,
          amount: item.quantity,
          itemOption: {
            description: item.variant,
            price: product.variants[item.variant],
          },
        };
        shoppingList.push(shippingItem);
      }
    }
    return shoppingList;
  }

  getId(id: string, variant: string) {
    if (!id || !variant) {
      return '';
    }
    return `${id}/${variant.replace(/ /g, '')}`;
  }

  private async retrieveContent() {
    this.settings.loadingSpinner = false;
    await this.search();
  }
}
