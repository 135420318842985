<mat-expansion-panel [id]="id" [(expanded)]="isOpen" (opened)="onItemOpen()">
    <mat-expansion-panel-header>
        <div *ngIf="!isOpen" class="closed">
            <mat-card-header class="header">
                <div mat-card-avatar class="avatar-container">
                    <p *ngIf="!dish.available" translate>labels.not_available</p>
                    <img mat-card-avatar class="avatar" [src]="dish.image || noImage" alt="dish" />
                </div>
                
                <mat-card-title>{{dish.name}}</mat-card-title>
                <mat-card-subtitle class="description">{{dish.description}}</mat-card-subtitle>
                <mat-card-subtitle fxLayout="row nowrap" class="variant-subtitle">
                    <span class="variant" translate [translateParams]="{value: dish.dishOptions.length}">messages.has_variant</span>&nbsp;
                </mat-card-subtitle>
            </mat-card-header>
        </div>
    </mat-expansion-panel-header>
    
    <mat-card class="item-card">
        <div class="opened">
            <mat-card-content class="content">
                <div class="image-container">
                    <p *ngIf="!dish.available" translate>labels.not_available</p>
                    <app-swiper-view [images]="[dish.image || noImage]" [square]="true">
                    </app-swiper-view>
                </div>
                <mat-card-header class="header">
                    <mat-card-title>{{dish.name}}</mat-card-title>
                    <mat-card-subtitle class="description">{{dish.description}}</mat-card-subtitle>
                </mat-card-header>
                <div class="variant-header" fxLayout="row nowrap" fxLayoutAlign="space-between center">
                    <span fxFlex="58" translate>formFields.variant_name</span>
                    <span fxFlex="32" translate>formFields.price</span>
                    <span fxFlex="10"></span>
                </div>
                <mat-card-subtitle 
                    *ngFor="let variant of dish.dishOptions; let i = index; let length = count;" 
                    class="variant" 
                    fxLayout="row nowrap" 
                    fxLayoutAlign="space-between center">

                    <span fxFlex="63">{{variant.description}}<span *ngIf="!variant.available" class="no-available-label">(<span translate>labels.not_available</span>)</span></span>
                    <span fxFlex="27"><span matPrefix>$&nbsp;</span>{{variant.price}}</span>
            
                    <button class="variant-add" type="button" (click)="addToTable(variant)" [disabled]="!dish.available || !variant.available" mat-icon-button>
                        <mat-icon>add</mat-icon>
                    </button>
                </mat-card-subtitle>
            </mat-card-content>
        </div>
    </mat-card>

</mat-expansion-panel>