<div [class.compact]= "settings.menuType == 'compact'"
     [class.fixed-header]="settings.fixedHeader"
     [class.horizontal-menu]="settings.menu == 'horizontal'"
     [class.mini]="settings.menuType == 'mini'"
     [dir]="(settings.rtl) ? 'rtl' : 'ltr'"
     [ngClass]="settings.theme"
     class="app" id="app">
    <router-outlet></router-outlet>
    <!--
    <div [class.hide]="!settings.loadingSpinner" id="app-spinner">
        <mat-spinner color="primary"></mat-spinner>
        <h4 translate>tinyAll.loading</h4>
    </div>
    -->
</div>