import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { HttpService } from '../../../../service/http.service';
import { environment } from '../../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit {
  @Input() activityId: string;
  @Input() hubId: string;
  @Input() currency: string;

  @Output() onAddToCart = new EventEmitter();

  readonly apiUrl = environment.apiUrl;

  settings: Settings;
  items = [];
  pageSize = 10;
  pageIndex = 0;
  length = 0;
  loading = false;
  loadingAddToCart = false;
  handler = null;

  searchCriteria = [];

  selectedForm: UntypedFormGroup;
  firstSearch = false;

  private cartStorageKey: string;

  constructor(
    private translate: TranslateService,
    protected snackBar: MatSnackBar,
    private httpService: HttpService,
    private appSettings: AppSettings,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.cartStorageKey = `product-list/${this.activityId}`;
    this.settings.loadingSpinner = true;
    this.loading = true;
    this.createForm();
    this.route.paramMap
      .pipe(map((params: ParamMap) => params.get('handler')))
      .subscribe((value) => {
        this.handler = value;
        this.retrieveContent();
      });
  }

  private async retrieveContent() {
    this.settings.loadingSpinner = false;
    await this.search();
  }

  searchCriteriaChanged(items: any) {
    this.pageIndex = 0;
    this.searchCriteria = items;
    this.search();
  }

  async search() {
    this.loading = true;
    const querySearch = [];
    querySearch.push(
      `querySearch=(activityId:'${this.activityId}' AND hubId:'${this.hubId}')`
    );

    let hasSearchCriteria = false;
    for (const word of this.searchCriteria) {
      if (!hasSearchCriteria) {
        querySearch.push(' AND (');
        hasSearchCriteria = true;
      } else {
        querySearch.push(' OR ');
      }
      querySearch.push(`tags%23'${word}' OR name:'*${word}*'`);
    }
    if (hasSearchCriteria) {
      querySearch.push(')');
    }

    if (querySearch.length > 0) {
      querySearch.push('&');
    }

    try {
      const itemsUrl = `${
        this.apiUrl
      }/product-item-view/v1/search?${querySearch.join('')}page=${
        this.pageIndex
      }&size=${this.pageSize}&sort=createdOn,desc`;

      const response = await this.httpService.doGet(itemsUrl).toPromise();
      if (response) {
        this.items = response.content;
        this.length = response.totalElements;
      } else {
        this.items = [];
        this.length = 0;
      }
      this.loading = false;
      const top = document.getElementById('app-top-search-bar');
      if (top !== null && this.firstSearch) {
        top.scrollIntoView();
      }
      this.firstSearch = true;
    } catch (error) {
      this.loading = false;
    }
  }

  pageEvent(pageChangedEvent: any) {
    this.pageIndex = pageChangedEvent.pageIndex;
    this.search();
  }

  createForm() {
    this.selectedForm = this.fb.group({
      id: [''],
      variant: [null],
      quantity: [1],
    });
  }

  // localStoreage
  getFromLocal() {
    return JSON.parse(localStorage.getItem(this.cartStorageKey));
  }

  addToLocal(productId, variant, quantity, price) {
    const productStorageKey = `${productId}/${variant.replace(/ /g, '')}`;
    let localCart = this.getFromLocal();
    if (!localCart) {
      localCart = {};
    }

    let quantityToAdd = quantity;
    if (localCart[productStorageKey]) {
      quantityToAdd += localCart[productStorageKey].quantity;
    }

    localCart[productStorageKey] = {
      productId,
      variant,
      price: Number(price),
      quantity: quantityToAdd,
    };

    localStorage.setItem(this.cartStorageKey, JSON.stringify(localCart));
  }
  // end localStoreage

  addToCart(item) {
    this.loadingAddToCart = true;
    this.selectedForm.disable();
    this.addToLocal(
      this.selectedForm.get('id').value,
      this.selectedForm.get('variant').value.description,
      this.selectedForm.get('quantity').value,
      this.selectedForm.get('variant').value.price
    );
    setTimeout(() => {
      this.loadingAddToCart = false;
      this.selectedForm.enable();
      this.showSnackBar(
        this.translate.instant('productList.product_added_msg', {
          name: `${item.name} - ${
            this.selectedForm.get('variant').value.description
          }`,
          quantity: this.selectedForm.get('quantity').value,
        }),
        'OK',
        2000
      );
      this.selectedForm.get('quantity').setValue(1);
      this.onAddToCart.emit();
    }, 1000);
  }

  showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }
}
