<swiper #swiper class="swiper-container" [ngClass]="{ square: square }">
  <div *ngFor="let image of images; let i = index" class="swiper-slide">
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      fxFlexFill
      class="swiper-card-image-wrapper"
    >
      <mat-card class="swiper-card-image-container">
        <input
          hidden="true"
          type="file"
          (change)="saveImage($any($event).target.files, i)"
          #imagesInput
        />
        <a
          class="add-image-link"
          *ngIf="!validateYoutubeURL(images[i])"
          (click)="imagesInput.click(); $event.preventDefault()"
        >
          <img class="placeholder-image" [src]="images[i] || noImage" alt="video"/>
        </a>
        <!--<youtube-player *ngIf="videoWidthNum >= 444 && validateYoutubeURL(images[i])"
                    [videoId]="getYoutubeUrl(images[i])" 
                    [height]="videoHeight" 
                    [width]="videoWidth">
                </youtube-player>-->
        <a
          class="add-image-link"
          *ngIf="validateYoutubeURL(images[i])"
          (click)="addVideo(i)"
        >
          <img class="placeholder-image" [src]="getYoutubeImage(images[i])" alt="video" />
        </a>
        <button
          *ngIf="!images[i] && (max === 0 || images.length < max)"
          (click)="imagesInput.click(); $event.preventDefault()"
          class="add-image-button"
          mat-stroked-button
          color="primary"
          [ngClass]="{ left: allowVideo }"
        >
          <span translate>activityInfoManagement.add_image</span>
        </button>
        <button
          *ngIf="allowVideo && !images[i]"
          (click)="addVideo(i)"
          class="add-video-button"
          mat-stroked-button
        >
          <span translate>buttons.add_video</span>
        </button>
      </mat-card>
    </div>
  </div>
</swiper>

<div
  *ngIf="images[0]"
  fxLayout="row"
  fxLayoutAlign="space-around"
  class="image-controller-container"
>
  <div fxLayoutAlign="start">
    <button
      type="button"
      (click)="removeImage()"
      mat-icon-button
      color="primary"
    >
      <mat-icon class="delete-icon">delete_forever</mat-icon>
    </button>
  </div>

  <div *ngIf="max === 0 || images.length < max" fxLayoutAlign="end">
    <button type="button" (click)="addImage()" mat-icon-button color="primary">
      <mat-icon class="add-icon">add_photo_alternate</mat-icon>
    </button>
  </div>

</div>

<mat-error class="error" *ngIf="maxSizeError" translate>{{ 'formErrors.image_max_size' | translate:{ size: maxImageSize } }}</mat-error>
