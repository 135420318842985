<mat-form-field>
  <input 
  type="text"
  matInput
  placeholder="{{'formFields.address' | translate}}"
  [formControl]="field"
  [matAutocomplete]="auto">
  <mat-error *ngIf="field.hasError('required')" translate>hubManagement.error_address</mat-error>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      <mat-icon class="row-icon">location_on</mat-icon>{{option}}
    </mat-option>
  </mat-autocomplete>
  <mat-icon class="icon" matSuffix>location_on</mat-icon>
</mat-form-field>
