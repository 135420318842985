import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-services-type-management',
  templateUrl: './services-type-management.component.html',
  styleUrls: ['./services-type-management.component.scss']
})
export class ServicesTypeManagementComponent {

  @Input() form: UntypedFormGroup;

  get inRestaurant() {
    return this.form.get("inRestaurant");
  }

  get pickUp() {
    return this.form.get("pickUp");
  }

  get delivery() {
    return this.form.get("delivery");
  }

  isServiceTypeValid(): boolean {
    return this.inRestaurant.value || this.pickUp.value || this.delivery.value;
  }
  
}
