export default {
  TEXT: 'short_text',
  NUMBER: 'plus_one',
  DATE: 'calendar_today',
  TIME: 'access_time',
  DATETIME: 'date_range',
  SINGLE_CHOICE: 'radio_button_checked',
  MULTI_CHOICE: 'check_box_outline_blank',
  GROUP: 'line_weight',
  IMAGE: 'image',
  GEOPOINT: 'map',
};
