import { UntypedFormBuilder, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Component, Input} from '@angular/core';
import AdditionalChargesUtil from '../../util/additionalChargesUtil';

@Component({
  selector: 'app-additional-charges-management',
  templateUrl: './additional-charges-management.component.html',
  styleUrls: ['./additional-charges-management.component.scss']
})
export class AdditionalChargesManagementComponent {

  @Input() form: UntypedFormArray;

  constructor(private fb: UntypedFormBuilder) {}

  get additionalCharges() {
    return this.form.controls as UntypedFormGroup[];
  }

  add() {
    const fg = AdditionalChargesUtil.createAdditionalCharge('', 0, this.fb);
    this.form.push(fg);
  }

  remove(index: number): void {
    this.form.removeAt(index);
    this.form.markAsDirty();
  }
  
}
