import {
  Component,
  EventEmitter,
  Output,
  OnDestroy,
  Input,
  ViewChild,
} from "@angular/core";
import { ZXingScannerComponent } from "@zxing/ngx-scanner";

@Component({
  selector: "app-code-scanner",
  templateUrl: "./code-scanner.component.html",
  styleUrls: ["./code-scanner.component.scss"],
})
export class CodeScannerComponent implements OnDestroy {
  @Input() canFlip: boolean = false;
  @Input() canTorch: boolean = false;

  @Output() scanSuccessHandler: EventEmitter<any> = new EventEmitter();

  @ViewChild('scanner') zxing: ZXingScannerComponent;

  scannerEnabled: boolean = true;
  torch: boolean = false;
  isTorchAvailable: boolean = false;
  desiredDevice: any;
  availableCameras: any[];

  ngOnDestroy() {
    this.scannerEnabled = false;
  }

  get showFlip() {
    return (
      this.canFlip &&
      this.scannerEnabled &&
      this.availableCameras &&
      this.availableCameras.length > 1 &&
      this.desiredDevice
    );
  }

  get showTorch() {
    return this.canTorch && this.isTorchAvailable;
  }

  flipCamera() {
    const currentDeviceId = this.desiredDevice.deviceId;
    const currentDeviceIndex = this.availableCameras.findIndex(
      (device) => device.deviceId === currentDeviceId
    );
    this.zxing.scanStop();
    if (currentDeviceIndex >= this.availableCameras.length - 1) {
      this.desiredDevice = this.availableCameras[0];
    } else {
      this.desiredDevice = this.availableCameras[currentDeviceIndex + 1];
    }
  }

  toggleFlash() {
    this.torch = !this.torch;
  }

  onTorchCompatible(event: any) {
    this.isTorchAvailable = event;
  }

  camerasFoundHandler(event: any) {
    this.availableCameras = event;
  }

  camerasNotFoundHandler(_event: any) {
    this.availableCameras = null;
  }

  successScanHandler(event: any) {
    this.scanSuccessHandler.emit(event);
  }
}
