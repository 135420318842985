import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormArray,
  Validators,
} from "@angular/forms";
import { RxwebValidators } from "@rxweb/reactive-form-validators";
import { AppSettings } from "../../../app.settings";
import { MatSnackBar } from "@angular/material/snack-bar";
import { isPast, parseISO } from "date-fns";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpService } from "../../../service/http.service";
import { ImageService } from "../../../service/image.service";
import { BaseFormComponent } from "../../baseform.component";
import { AuthService } from "../../../auth/auth.serviceV2";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { DatetimeAdapter } from "@mat-datetimepicker/core";
import QuestionsManagementUtil from "../../../util/questionsManagementUtil";
import AdditionalChargesUtil from "../../../util/additionalChargesUtil";
import { ContentType } from "../../../models/content-type-enum";
import { RichTextEditorComponent } from "../../../components/rich-text-editor/rich-text-editor.component";
import { LocationPickerDialogComponent } from "../../../components/location-picker-dialog/location-picker-dialog.component";
import { ShortUrlHandlerComponent } from "../../../components/short-url-handler/short-url-handler.component";
import { setDefaultPageHead } from "../../../util/seo-utils";
import { Meta, Title } from "@angular/platform-browser";
import { firstValueFrom } from "rxjs";
import {
  ActivityEventEntity,
  CreateActivityEventCommand,
  UpdateActivityEventCommand,
} from "../../../models/activity-event";
import { createHigherValueValidator } from "../../../util/form-utils";

@Component({
  selector: "app-activity-event-management",
  templateUrl: "./activity-event-management.component.html",
  styleUrls: ["./activity-event-management.component.scss"],
})
export class ActivityEventManagementComponent
  extends BaseFormComponent
  implements OnInit
{

  @Input() hubIdParam: string;
  @Input() categoryIdParam: string;
  @Input() activityIdParam: string;
  @Output() next: EventEmitter<any> = new EventEmitter();
  
  questionsForm: UntypedFormArray;
  additionalChargesForm: UntypedFormArray;
  collaboratorsForm: UntypedFormArray;
  selectedInnerQuestion: Map<number, Map<number, Set<number>>> = new Map();
  selectedQuestion: number;
  optionName: string;
  activityType = "activity-event";
  lastUpdate = new Date();
  today = new Date();
  hubLocation;

  constructor(
    private authService: AuthService,
    public translate: TranslateService,
    public fb: UntypedFormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    public httpService: HttpService,
    public appSettings: AppSettings,
    public snackBar: MatSnackBar,
    public imageService: ImageService,
    public dialog: MatDialog,
    public _adapter: DatetimeAdapter<Date>,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    super(
      httpService,
      appSettings,
      snackBar,
      imageService,
      router,
      dialog,
      translate
    );
    _adapter.setLocale(translate.getDefaultLang());
    (_adapter as any)._delegate.setLocale(translate.getDefaultLang());
    this.optionName = this.translate.instant("questionsDialog.option");
  }

  async ngOnInit() {
    this.settings.loadingSpinner = true;
    setDefaultPageHead(this.titleService, this.metaTagService, true);
    await this.authService.isAuthenticated();
    this.user = this.authService.getUserProfile();
    this.hubId = this.route.snapshot.queryParams["hubId"] || this.hubIdParam;
    this.categoryId = this.route.snapshot.queryParams["categoryId"] || this.categoryIdParam;
    this.activityId = this.route.snapshot.queryParams["activityId"] || this.activityIdParam;
    this.originId = this.route.snapshot.queryParams["originId"];
    this.handler = this.route.snapshot.queryParams["handler"];

    this.createForm();

    try {
      this.settings.loadingSpinner = true;
      const url = `${this.apiUrl}/hubs/v1/${this.hubId}`;
      const {
        location: { coordinates },
      } = await firstValueFrom(this.httpService.doGet(url));
      this.hubLocation = coordinates.reverse();
    } catch (error) {
      this.settings.loadingSpinner = false;
    }

    await this.setIsHubNavigationMenuFull();

    if (this.activityId || this.originId) {
      this.settings.loadingSpinner = true;
      const url = `${this.apiUrl}/activity-event/v1/${this.activityId ? this.activityId : this.originId}`;
      try {
        let response: ActivityEventEntity = await firstValueFrom(
          this.httpService.doGet(url)
        );
        if (this.activityId) {
          this.activityId = response.id;
          this.hubId = response.hubId;
          this.categoryId = response.categoryId;
        } else {
          response = {
            ...response,
            id: null,
            hubId: this.hubId,
            userId: this.user?.userId,
            categoryId: this.categoryId ? this.categoryId : "",
            active: false,
          };
        }
        this.setForm(response);
        this.form.markAllAsTouched();
        this.form.updateValueAndValidity();
        if (response.images && response.images.length > 0) {
          this.images = response.images;
        } else {
          this.images = [""];
        }
        if (this.originId) {
          await this.cloneImages();
          this.removeOriginId();
        }
        setTimeout(() => {
          this.settings.loadingSpinner = false;
        }, 300);
      } catch (error) {
        this.settings.loadingSpinner = false;
        console.log("Error getting activity: ", error);
      }
    } else {
      this.images = [""];
      if (this.isHubNavigationMenuFull) {
        this.includeInMenu.disable();
        this.form.patchValue({ includeInMenu: false });
        this.showNavigationMenuLink = true;
      }
      this.settings.loadingSpinner = false;
    }
  }

  createForm() {
    this.form = this.fb.group(
      {
        active: [true],
        clonable: [false],
        categoryId: [this.categoryId ? this.categoryId : ""],
        hubId: [this.hubId],
        id: [],
        infoText: ["", Validators.required],
        newRequestMessage: [""],
        name: ["", Validators.required],
        order: [0, [Validators.required, RxwebValidators.numeric()]],
        unListed: [false],
        includeInMenu: [true],
        userId: [this.user?.userId],
        availableTicketCount: [
          0,
          [
            Validators.required,
            RxwebValidators.numeric(),
            RxwebValidators.minNumber({
              value: 1,
            }),
          ],
        ],
        capacity: [0, [Validators.required, RxwebValidators.numeric()]],
        eventDate: ["", [Validators.required, RxwebValidators.date()]],
        address: ["", Validators.required],
        location: ["", Validators.required],
        maxTicketsPerUser: [
          1,
          [
            Validators.required,
            RxwebValidators.numeric(),
            RxwebValidators.minNumber({
              value: 1,
            }),
            RxwebValidators.maxNumber({
              value: 50,
            }),
          ],
        ],
        ticketPrice: ["", Validators.required],
        root: [!this.categoryId],
        // gpsRequired: [false],
        // phoneNumberRequired: [false],
        addressRequired: [false],
        questions: this.fb.array([]),
        additionalCharges: this.fb.array([]),
        createdOn: [""],
        updatedOn: [""],
        staffEmails: this.fb.array([]),
      },
      {
        validators: createHigherValueValidator(
          "maxTicketsPerUser",
          "availableTicketCount"
        ),
      }
    );

    this.questionsForm = this.form.get("questions") as UntypedFormArray;
    this.additionalChargesForm = this.form.get(
      "additionalCharges"
    ) as UntypedFormArray;
    this.collaboratorsForm = this.form.get("staffEmails") as UntypedFormArray;
  }

  get name(): UntypedFormControl {
    return this.form.get("name") as UntypedFormControl;
  }

  get info(): UntypedFormControl {
    return this.form.get("infoText") as UntypedFormControl;
  }

  get newRequestMessage(): UntypedFormControl {
    return this.form.get("newRequestMessage") as UntypedFormControl;
  }


  get active() {
    return this.form.get("active");
  }

  get unListed() {
    return this.form.get("unListed");
  }

  get includeInMenu() {
    return this.form.get("includeInMenu");
  }
  
  get getQuestions(): UntypedFormArray {
    return this.form.get("questions") as UntypedFormArray;
  }

  get availableTicketCount(): UntypedFormControl {
    return this.form.get("availableTicketCount") as UntypedFormControl;
  }

  get capacity(): UntypedFormControl {
    return this.form.get("capacity") as UntypedFormControl;
  }

  get order() {
    return this.form.get("order");
  }

  get ticketPrice(): UntypedFormControl {
    return this.form.get("ticketPrice") as UntypedFormControl;
  }

  get eventDate(): UntypedFormControl {
    return this.form.get("eventDate") as UntypedFormControl;
  }

  get address(): UntypedFormControl {
    return this.form.get("address") as UntypedFormControl;
  }

  get maxTicketsPerUser(): UntypedFormControl {
    return this.form.get("maxTicketsPerUser") as UntypedFormControl;
  }

  get location(): UntypedFormControl {
    return this.form.get("location") as UntypedFormControl;
  }

  get qrCode() {
    if (!this.handler || !this.hubId || !this.activityId) {
      return false;
    }
    return ShortUrlHandlerComponent.shortActCatUrl(this.activityId);
  }

  get collaboratorUrl() {
    if (!this.handler || !this.activityId) {
      return false;
    }
    return ShortUrlHandlerComponent.shortScannerUrl(this.activityId);
  }

  get isPastDate(): boolean {
    if (!this.eventDate || !this.eventDate.value) return false;
    return isPast(parseISO(this.eventDate.value));
  }

  canGoBack(): boolean {
    this.forceWarming = this.questionsForm.dirty;
    return super.canGoBack();
  }

  setForm(response: any) {
    this.lastUpdate = new Date(response.createdOn);
    this.form.patchValue({ id: response.id });
    this.form.patchValue({ hubId: response.hubId });
    this.form.patchValue({ userId: response.userId });
    this.form.patchValue({ categoryId: response.categoryId });
    this.form.patchValue({ name: response.name });
    this.form.patchValue({ active: response.active });
    this.form.patchValue({ unListed: response.unListed });
    this.form.patchValue({ includeInMenu: response.includeInMenu });
    this.form.patchValue({ clonable: response.clonable });
    this.form.patchValue({ order: response.order });
    this.form.patchValue({ infoText: response.infoText });
    this.form.patchValue({ newRequestMessage: response.newRequestMessage });
    this.form.patchValue({ createdOn: response.createdOn });
    this.form.patchValue({ updatedOn: response.updatedOn });
    this.form.patchValue({
      availableTicketCount: response.availableTicketCount,
    });
    this.form.patchValue({ capacity: response.capacity });
    this.form.patchValue({ eventDate: new Date(response.eventDate) });
    this.form.patchValue({ address: response.address });
    this.form.patchValue({ location: response.location });
    this.form.patchValue({ ticketPrice: response.ticketPrice });
    this.form.patchValue({ maxTicketsPerUser: response.maxTicketsPerUser });
    this.form.patchValue({ addressRequired: response.addressRequired });

    if (!response.includeInMenu && this.isHubNavigationMenuFull) {
      this.includeInMenu.disable();
      this.showNavigationMenuLink = true;
    }

    this.collaboratorsForm.clear();
    for (const COLLABORATOR of response.staffEmails) {
      this.collaboratorsForm.push(
        this.fb.control(COLLABORATOR, [Validators.email, Validators.required])
      );
    }

    this.questionsForm.clear();
    if (response.questions) {
      for (const QUESTION of response.questions) {
        this.questionsForm.push(
          QuestionsManagementUtil.createQuestionFromResponse(
            QUESTION,
            this.fb,
            this.optionName
          )
        );
      }
    }
    this.selectedInnerQuestion =
      QuestionsManagementUtil.createSelectedContainer(this.questionsForm);

    this.descriptionImages = RichTextEditorComponent.getImagesSrcFromHtmlString(
      response.infoText
    );

    this.confirmationMessageImages = RichTextEditorComponent.getImagesSrcFromHtmlString(
      response.newRequestMessage
    );

    this.additionalChargesForm.clear();
    if (response.additionalCharges) {
      AdditionalChargesUtil.createAdditionalChargesFromResponse(
        response.additionalCharges,
        this.fb,
        this.additionalChargesForm
      );
    }

    this.form.clearValidators();
    this.form.addValidators(
      createHigherValueValidator("maxTicketsPerUser", "capacity")
    );

    this.form.controls["availableTicketCount"].clearValidators();
    this.form.controls["availableTicketCount"].addValidators([
      Validators.required,
      RxwebValidators.numeric(),
      RxwebValidators.minNumber({
        value: 1,
      }),
      RxwebValidators.maxNumber({
        value: response.capacity,
      }),
    ]);

    this.form.markAsPristine();
    this.originalActivity = response;
  }

  onSubmit() {
    if (this.activityId) {
      this.update();
    } else {
      this.create();
    }
  }

  async create() {
    if (!this.images || this.images.length === 0 || !this.images[0]) {
      this.showSnackBar(
        this.translate.instant("messages.at_least_one_image_or_video"),
        "OK",
        2000
      );
      return;
    }
    this.settings.loadingSpinner = true;
    const url = `${this.apiUrl}/${this.activityType}/v1`;
    const rawValues = this.form.getRawValue();
    rawValues.questions = QuestionsManagementUtil.cleanContent(
      rawValues.questions
    );
    rawValues.additionalCharges = AdditionalChargesUtil.cleanContent(
      rawValues.additionalCharges
    );
    rawValues.images = this.images;
    rawValues.infoText = await RichTextEditorComponent.uploadImages(
      rawValues.infoText,
      this.descriptionImages,
      this.imageService
    );

    rawValues.newRequestMessage = await RichTextEditorComponent.uploadImages(
      rawValues.newRequestMessage,
      this.confirmationMessageImages,
      this.imageService
    );
    rawValues.capacity = rawValues.availableTicketCount;

    const {
      hubId,
      userId,
      categoryId,
      name,
      infoText,
      newRequestMessage,
      order,
      active,
      unListed,
      includeInMenu,
      clonable,
      images,
      additionalCharges,
      questions,
      eventDate,
      capacity,
      maxTicketsPerUser,
      ticketPrice,
      location,
      address,
      staffEmails,
      addressRequired,
    } = rawValues;

    const body: CreateActivityEventCommand = {
      hubId,
      userId,
      categoryId,
      name,
      infoText,
      newRequestMessage,
      order,
      active,
      unListed,
      includeInMenu,
      clonable,
      images,
      additionalCharges,
      questions,
      eventDate,
      capacity,
      maxTicketsPerUser,
      ticketPrice,
      location,
      address,
      staffEmails,
      addressRequired,
    };

    try {
      const response: ActivityEventEntity = await firstValueFrom(
        this.httpService.doPost(url, body)
      );
      this.activityId = response.id;
      this.categoryId = response.categoryId;
      this.hubId = response.hubId;
      this.setForm(response);
      this.validateActiveLimitReached(
        rawValues["active"],
        response.active,
        ContentType.ACTIVITY_EVENT
      );
      this.showSnackBar("Operation successful ", "OK", 2000);
    } catch (error) {
      this.validateCreatedLimitReached(error, ContentType.ACTIVITY_EVENT);
      this.showSnackBar("Error Processing Your Request", "OK", 2000);
      console.log("Error creating activity: ", error);
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  async update() {
    if (!this.images || this.images.length === 0 || !this.images[0]) {
      this.showSnackBar(
        this.translate.instant("messages.at_least_one_image_or_video"),
        "OK",
        2000
      );
      return;
    }
    this.settings.loadingSpinner = true;
    const url = `${this.apiUrl}/${this.activityType}/v1`;
    const rawValues = this.form.getRawValue();
    rawValues.questions = QuestionsManagementUtil.cleanContent(
      rawValues.questions
    );
    rawValues.additionalCharges = AdditionalChargesUtil.cleanContent(
      rawValues.additionalCharges
    );
    rawValues.images = this.images;
    rawValues.infoText = await RichTextEditorComponent.uploadImages(
      rawValues.infoText,
      this.descriptionImages,
      this.imageService
    );
    rawValues.newRequestMessage = await RichTextEditorComponent.uploadImages(
      rawValues.newRequestMessage,
      this.confirmationMessageImages,
      this.imageService
    );
    const {
      id,
      hubId,
      userId,
      categoryId,
      name,
      infoText,
      newRequestMessage,
      order,
      active,
      unListed,
      includeInMenu,
      clonable,
      images,
      additionalCharges,
      questions,
      eventDate,
      capacity,
      maxTicketsPerUser,
      ticketPrice,
      location,
      address,
      staffEmails,
      addressRequired,
    } = rawValues;

    const body: UpdateActivityEventCommand = {
      id,
      hubId,
      userId,
      categoryId,
      name,
      infoText,
      newRequestMessage,
      order,
      active,
      unListed,
      includeInMenu,
      clonable,
      images,
      additionalCharges,
      questions,
      eventDate,
      capacity,
      maxTicketsPerUser,
      ticketPrice,
      location,
      address,
      staffEmails,
      addressRequired,
    };

    try {
      const response: ActivityEventEntity = await firstValueFrom(
        this.httpService.doPut(url, body)
      );
      this.activityId = response.id;
      this.categoryId = response.categoryId;
      this.hubId = response.hubId;
      this.setForm(response);
      this.validateActiveLimitReached(
        rawValues["active"],
        response.active,
        ContentType.ACTIVITY_EVENT
      );
      this.showSnackBar("Operation successful ", "OK", 2000);
      this.next.emit();
    } catch (error) {
      this.validateCreatedLimitReached(error, ContentType.ACTIVITY_EVENT);
      this.showSnackBar("Error Processing Your Request", "OK", 2000);
      console.log("Error updating activity: ", error);
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  canDelete() {
    super.canDelete(
      "titles.delete_activity",
      "messages.delete_activity",
      async () => {
        try {
          this.settings.loadingSpinner = true;
          await firstValueFrom(
            this.httpService.doDelete(
              `${this.apiUrl}/${this.activityType}/v1/${this.activityId}`
            )
          );
          this.showSnackBar(
            this.translate.instant("messages.activity_deleted"),
            "OK",
            2000
          );
          this.back();
          this.settings.loadingSpinner = false;
        } catch (error) {
          this.settings.loadingSpinner = false;
          this.showSnackBar(
            this.translate.instant("messages.activity_delete_error"),
            "OK",
            2000
          );
        }
      }
    );
  }

  updateLocation(event) {
    if (!this.location.value && Array.isArray(event)) {
      const location = { coordinates: event.reverse(), type: "Point" };
      this.location.setValue(location);
    }
  }

  openMap() {
    const dialogRef = this.dialog.open(LocationPickerDialogComponent, {
      width: "100vw",
      height: "100vh",
      maxWidth: "100vw",
      maxHeight: "100vh",
      panelClass: "location-picker-dialog",
      data: {
        location: this.location.value,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.location.setValue(result.location);
        this.location.markAsDirty();
        if (!this.address.value && result.address) {
          this.address.setValue(result.address);
          this.address.markAsDirty();
        }
      }
    });
  }
}
