import { Component, Input } from '@angular/core';
import { faLink } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-payment-link',
  templateUrl: './payment-link.component.html',
  styleUrls: ['./payment-link.component.scss'],
})
export class PaymentLinkComponent {
  readonly faLink = faLink;

  @Input() currentPaymentLink: string;
}
