import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Location } from "@angular/common";
import { MatureContentDialogComponent } from '../components/mature-content-dialog/mature-content-dialog.component';

@Injectable()
export class MatureContentService {

  constructor(
    private dialog: MatDialog,
    private location: Location
  ) {}

  async check(validate: boolean = false): Promise<boolean> {
    if (!validate) return true;
    const legalAge = sessionStorage.getItem('legalAge');
    if (legalAge === 'true') {
      return true;
    }
    const dialogRef = this.openMatureDialog();
    const result = await firstValueFrom(dialogRef.afterClosed());
    if (result) {
      sessionStorage.setItem('legalAge', 'true');
      return true;
    }
    this.location.back();
    return false;
  }

  private openMatureDialog() {
    return this.dialog.open(MatureContentDialogComponent, {
      minWidth: '240px',
    });
  }
}
