import { NgModule } from '@angular/core';
import { CurrencyMaskDirective } from './currency-mask.directive';

@NgModule({
  imports: [ ],
  providers: [],
  declarations: [ CurrencyMaskDirective ],
  exports: [CurrencyMaskDirective ]
})
export class DirectivesModule { }
