import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EntityType } from '../../models/entity-type-enum';
import { LikeReviewCommand, ReviewEntity } from '../../models/review';
import UserProfile from '../../models/user-profile-model';
import { HttpService } from '../../service/http.service';
import { YesNoDialogComponent } from '../yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent {

  @Input() comment: ReviewEntity;
  @Input() user: UserProfile;
  @Input() entityId: string;
  @Input() entityType: string;
  @Input() entityName: string;

  private readonly apiUrl = environment.apiUrl;
  expanded = false;
  like = false;
  reported = false;
  public noImage = '../../../assets/img/app/default-user.jpg';

  type = EntityType.REVIEW;

  constructor(
    private dialog: MatDialog,
    private httpService: HttpService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {}

  likeComment(): void {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      minWidth: '240px',
      data: {
        title: 'messages.like_comment_question',
      }
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        let url = `${this.apiUrl}/review/v1/like`;
        const requestBody: LikeReviewCommand = {
          reviewId: {
            entityId: this.entityId,
            entityType: this.entityType as EntityType,
            userId: this.comment.userId,
          },
          userId: this.user.userId
        };
        try {
          await firstValueFrom(this.httpService.doPost(url, requestBody));
          this.like = true;
          this.showSnackBar(this.translate.instant('messages.thank_you_for_your_feedback'), 'OK', 2000);
        } catch(error) {
          console.log('Error: ', error);
          this.showSnackBar(this.translate.instant('messages.error_processing_request'), 'OK', 2000);
        }
      }
    });
  }

  getReviewId() {
    return JSON.stringify({
      entityId: this.entityId,
      entityType: this.entityType,
      userId: this.user.userId
    });
  }

  report(): void {
    this.reported = true;
  }

  showSnackBar(message, action, duration, panelClass = '') {
    this.snackBar.open(message, action, {
      duration: duration,
      panelClass
    });
  }

  getId(): string {
    return `${this.user?.userId}_${this.entityType}_${this.entityId}`
  }
}
