<div class="container">
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
      <button (click)="cancel()" mat-button><span translate>reportsFilter.cancel</span></button>
      <button color="primary" (click)="apply()" mat-button><span translate>reportsFilter.apply</span></button>
  </div>
  <form novalidate [formGroup]="form" fxLayout="column" fxLayoutAlign="center">
    <div class="sort-group-title" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 translate>reportsFilter.sort_gorup_title</h2>
    </div>
    <mat-radio-group formControlName="sort">
      <div class="sort-row" matRipple fxLayout="row" fxLayoutAlign="space-between center">
        <mat-radio-button value="createdOn,desc" color="primary" >
          <span translate>reportsFilter.sort_date_desc</span>
        </mat-radio-button>
        <mat-icon class="icon">arrow_downward</mat-icon>
      </div>
      <div class="sort-row" matRipple fxLayout="row" fxLayoutAlign="space-between center">
        <mat-radio-button value="createdOn,asc" color="primary" >
          <span translate>reportsFilter.sort_date_asc</span>
        </mat-radio-button>
        <mat-icon class="icon">arrow_upward</mat-icon>
      </div>
    </mat-radio-group>

  </form>

</div>