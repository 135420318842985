import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { ServiceModule } from '../../service/service.module';
import { AuthGuard } from '../../auth/auth.guard';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ActivityReservationClientViewComponent } from './activity-reservation-client-view/activity-reservation-client-view.component';
import { ActivityReservationManagementComponent } from './activity-reservation-management/activity-reservation-management.component';
import { AppointmentDurationPickerComponent } from './components/appointment-duration-picker/appointment-duration-picker.component';
import { SlotCapacityPickerComponent } from './components/slot-capacity-picker/slot-capacity-picker.component';
import { ReservationRequestListClientViewComponent } from './components/reservation-request-list-client-view/reservation-request-list-client-view.component';
import { ComponentsModule } from '../../components/componets.module';
import { FormGuard } from '../../guards/form.guard';
import { RequestDetailsDialogComponent } from './components/request-details-dialog/request-details-dialog.component';
import { RequestReservationDetailsComponent } from './components/request-reservation-details/request-reservation-details.component';
import { RequestReservationGeneralInfoComponent } from './components/request-reservation-general-info/request-reservation-general-info.component';

export const routes = [
  { path: 'client-view', component: ActivityReservationClientViewComponent },
  {
    path: 'manage',
    component: ActivityReservationManagementComponent,
    canActivate: [AuthGuard],
    canDeactivate: [FormGuard],
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ComponentsModule,
    ServiceModule,
    RouterModule.forChild(routes),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [],
  declarations: [
    ActivityReservationClientViewComponent,
    ActivityReservationManagementComponent,
    AppointmentDurationPickerComponent,
    SlotCapacityPickerComponent,
    ReservationRequestListClientViewComponent,
    RequestDetailsDialogComponent,
    RequestReservationDetailsComponent,
    RequestReservationGeneralInfoComponent
  ],
  exports: [
    ActivityReservationManagementComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ActivityReservationModule {}
