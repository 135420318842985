<ng-container *ngIf="!notFound; else pageNotFound">
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <button (click)="back()" mat-button>
      <span translate>tinyAll.back</span>
    </button>
    <app-report-abuse-button
      *ngIf="!reported"
      [user]="user"
      [entityId]="activityId"
      [entityType]="type"
      [entityName]="entity?.name"
      [entity]="entity"
      (reported)="reported = true"
    ></app-report-abuse-button>
  </div>

  <div class="page-container" fxLayout="row wrap" fxLayoutAlign="center start">
    <div class="flex-p" fxFlex="100">
      <app-navigation-menu
        *ngIf="hub"
        [hubId]="hub?.id"
        [handler]="hub?.urlHandler"
      >
      </app-navigation-menu>
    </div>
    <div class="px-4 py-2.5 bg-[#fafafa] sticky xs:static -top-[10px] z-10 xs:z-0 -ml-[8px] w-[calc(100%+10px)]">
      <h1 class="text-gray-500 font-semibold text-xl">{{hub?.name}}</h1>
    </div>
    <div class="flex-p" fxFlex="100">
      <mat-card class="card-container">
        <mat-card-header>
          <mat-card-title class="activity-title">{{
            entity?.name
          }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <app-swiper-view
            *ngIf="entity"
            [images]="entity.images"
            [canPlay]="true"
            [canLink]="true"
          >
          </app-swiper-view>
        </mat-card-content>
        <app-social-card-actions
          *ngIf="entity && commentsPreview"
          [title]="entity.name"
          [description]="entity.infoText"
          [images]="entity.images"
          [preview]="commentsPreview"
          [views]="entity.views"
          [requests]="entity.requestCount"
          [id]="activityId"
          [createdOn]="entity.createdOn"
        >
        </app-social-card-actions>
      </mat-card>
    </div>

    <div class="flex-p" fxFlex="100">
      <mat-card class="preview-card custom-card">
        <app-comments-preview
          #commentsPreview
          *ngIf="entity"
          entityType="ACTIVITY_EVENT"
          [entityName]="entity.name"
          [entityId]="entity.id"
        ></app-comments-preview>
      </mat-card>
    </div>

    <app-collapsible-description
      [text]="entity?.infoText"
      [collapsible]="true"
    ></app-collapsible-description>

    <div class="flex-p" fxFlex="100" *ngIf="entity">
      <app-warning-message
        *ngIf="entity && isPastDate"
        message="messages.past_date_event"
      >
      </app-warning-message>
      <mat-card class="date-address">
        <mat-card-content>
          <div fxLayout="row" fxLayoutAlign="start">
            <div class="date-space">
              <span>{{ entity.eventDate | localizedDate: "EEEE," }}</span
              ><br />
              <span class="long-date">{{
                entity.eventDate | localizedDate: "longDate"
              }}</span
              ><br />
              <span class="time">{{
                entity.eventDate | date: "shortTime"
              }}</span
              ><br />
            </div>
            <div class="address-space">
              <h2>
                <mat-icon class="row-icon">location_on</mat-icon
                ><span translate>formFields.address</span>
              </h2>
              {{ entity.address }}
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <app-location-map
      *ngIf="entity && entity.location"
      [location]="entity.location"
      [launch]="true"
    >
    </app-location-map>

    <app-address-selector *ngIf="!locked && form && entity?.addressRequired" [form]="form"></app-address-selector>

    <app-question-list-client-view
      *ngIf="!locked && entity && form && getQuestionsLength() > 0 && canBuy"
      class="question-list"
      [form]="form"
      [originalQuestions]="questionsV1"
    >
    </app-question-list-client-view>

    <app-warning-message
      *ngIf="entity && entity.availableTicketCount < 1"
      message="messages.no_available_tickets"
    >
    </app-warning-message>

    <app-quantity-picker
      *ngIf="!locked && form && entity && canBuy"
      [quantity]="optionsQuantity"
      [form]="form"
      name="quantity"
      label="labels.how_many_tickets"
    ></app-quantity-picker>

    <app-questions-summary
      *ngIf="showPriceSummary && form && hub && entity"
      itemLabel="labels.tickets"
      [entityId]="entity.id"
      [hubId]="hub?.id"
      [questionAnswerMap]="questionAnswerMap"
      [items]="total"
      [quantity]="quantity"
      [totalPrice]="totalPrice"
      [originalQuestions]="questionsV1"
      [additionalCharges]="entity.additionalCharges"
      [promoCodeForm]="form"
    >
    </app-questions-summary>

    <app-select-payment-method
      *ngIf="showPaymentMethods && canBuy"
      buyLabel="buttons.buy_tickets"
      [form]="form"
      [hub]="hub"
      [totalPrice]="totalPrice"
      (sendRequest)="sendRequest()"
    >
    </app-select-payment-method>
    <app-azul-payment-form #azulPaymentEl></app-azul-payment-form>
    <div fxLayout="row" fxFlex="100" fxLayoutAlign="end center">
      <button
        *ngIf="showSendRequest && canBuy"
        [disabled]="disableSendRequest"
        class="speed-dial-action-button-fix"
        (click)="sendRequest()"
        mat-fab
        color="accent"
      >
        <span translate>buttons.buy_tickets</span>&nbsp;<mat-icon
          >credit_card</mat-icon
        >
      </button>
    </div>
    <app-company-info-card
      *ngIf="showSendRequest && hub && canBuy"
      [name]="hub.name"
      [address]="hub.address"
      [phone]="hub.phoneNumber"
      [email]="hub.email"
      [handler]="hub.urlHandler"
      [image]="hub.icon"
    >
    </app-company-info-card>

    <app-event-request-list-client-view
      *ngIf="entity && user"
      #requestListTag
      class="request-list"
      id="request-list"
      [activityId]="activityId"
      [activityType]="activityType"
      [hubId]="hubId"
      [user]="user"
      [size]="5"
      (cancel)="onCancel()"
      (hasOpen)="onHasOpen($event)"
    >
    </app-event-request-list-client-view>
    <app-company-info-card
      *ngIf="locked"
      class="footer"
      [name]="hub.name"
      [address]="hub.address"
      [phone]="hub.phoneNumber"
      [email]="hub.email"
      [handler]="hub.urlHandler"
      [image]="hub.icon"
    >
    </app-company-info-card>
  </div>
</ng-container>
<ng-template #pageNotFound>
  <app-page-not-found (back)="back()"></app-page-not-found>
</ng-template>
<app-loading></app-loading>
