import {
  faInfo,
  faClipboardList,
  faCalendarAlt,
  faShoppingCart,
  faCalendarCheck,
  faFolderOpen,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";

export default {
  ACTIVITY_INFO: faInfo,
  ACTIVITY_PRODUCT_LISTING: faShoppingCart,
  ACTIVITY_REQUEST: faClipboardList,
  ACTIVITY_RESERVATION: faCalendarAlt,
  ACTIVITY_MENU: faUtensils,
  ACTIVITY_EVENT: faCalendarCheck,
  CATEGORY: faFolderOpen,
};
