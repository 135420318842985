import { FormControl, UntypedFormGroup } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { DatetimeAdapter } from '@mat-datetimepicker/core';
import { TranslateService } from '@ngx-translate/core';
import DAYS from '../../../../util/days';


@Component({
  selector: 'app-open-hours-management',
  templateUrl: './open-hours-management.component.html',
  styleUrls: ['./open-hours-management.component.scss'],
})
export class OpenHoursManagementComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() noCard: boolean = false;

  weekDays = [];
  days;

  constructor(
    public _adapter: DatetimeAdapter<Date>,
    private translate: TranslateService
  ) {
    _adapter.setLocale(translate.getDefaultLang());
    (_adapter as any)._delegate.setLocale(translate.getDefaultLang());
  }

  ngOnInit(): void {
    this.days = [...DAYS];
    this.weekDays = this._adapter.getDayOfWeekNames('long'); // 'long' | 'short' | 'narrow'
  }

  clearField(day: string, field: string, event: MouseEvent) {
    event.stopPropagation();
    this.form.get(day).get(field).setValue(null);
  }

  // Deprecated
  dateChangeFrom(_, day: string) {
    const from = this.form.get(day).get('from').value;
    const to = this.form.get(day).get('to').value;

    if (from && this._adapter.isValid(from) && to && this._adapter.isValid(to)) {
      if (this._adapter.compareDatetime(from, to) === 0) {
        this.form.get(day).get('to').setValue((this._adapter.addCalendarMinutes(to, -1)));
      }
    }
  }

  // Deprecated
  dateChangeTo(_, day: string) {
    const from = this.form.get(day).get('from').value;
    const to = this.form.get(day).get('to').value;

    if (from && this._adapter.isValid(from) && to && this._adapter.isValid(to)) {
      if (this._adapter.compareDatetime(from, to) === 0) {
        this.form.get(day).get('from').setValue((this._adapter.addCalendarMinutes(from, 1)));
      }
    }
  }

  copyDown(day: string, index: number) {
    const form = this.form.get(day) as UntypedFormGroup;
    const from = form.get('from').value;
    const to = form.get('to').value;
    const formToUpdate = this.form.get(this.days[index + 1]) as UntypedFormGroup;
    formToUpdate.get('from').setValue(from);
    formToUpdate.get('to').setValue(to);
    formToUpdate.markAsDirty();
  }


  copyAllDown(day: string, index: number) {
    const form = this.form.get(day) as UntypedFormGroup;
    for (let i = index; i < this.days.length; i++) {
      this.copyDown(day, i);
    }
  }

}
