import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { RxwebValidators } from "@rxweb/reactive-form-validators";
import { AppSettings } from "../../../app.settings";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpService } from "../../../service/http.service";
import { ImageService } from "../../../service/image.service";
import { MatDialog } from "@angular/material/dialog";
import { BaseFormComponent } from "../../baseform.component";
import { AuthService } from "../../../auth/auth.serviceV2";
import { TranslateService } from "@ngx-translate/core";
import { ContentType } from "../../../models/content-type-enum";
import { RichTextEditorComponent } from "../../../components/rich-text-editor/rich-text-editor.component";
import { ShortUrlHandlerComponent } from "../../../components/short-url-handler/short-url-handler.component";
import { setDefaultPageHead } from "../../../util/seo-utils";
import { Meta, Title } from "@angular/platform-browser";
import {
  ActivityInfoEntity,
  CreateActivityInfoCommand,
  UpdateActivityInfoCommand,
} from "../../../models/activity-info";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-actinfo-management",
  templateUrl: "./activity-info-management.component.html",
  styleUrls: ["./activity-info-management.component.scss"],
})
export class ActivityInfoManagementComponent
  extends BaseFormComponent
  implements OnInit
{

  @Input() hubIdParam: string;
  @Input() categoryIdParam: string;
  @Input() activityIdParam: string;
  @Output() next: EventEmitter<any> = new EventEmitter();

  constructor(
    private authService: AuthService,
    public translate: TranslateService,
    private fb: UntypedFormBuilder,
    protected router: Router,
    private route: ActivatedRoute,
    protected httpService: HttpService,
    protected appSettings: AppSettings,
    protected snackBar: MatSnackBar,
    protected imageService: ImageService,
    public dialog: MatDialog,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    super(
      httpService,
      appSettings,
      snackBar,
      imageService,
      router,
      dialog,
      translate
    );
  }

  async ngOnInit() {
    this.settings.loadingSpinner = true;
    setDefaultPageHead(this.titleService, this.metaTagService, false);
    await this.authService.isAuthenticated();
    this.user = this.authService.getUserProfile();
    this.hubId = this.route.snapshot.queryParams["hubId"] || this.hubIdParam;
    this.categoryId = this.route.snapshot.queryParams["categoryId"] || this.categoryIdParam;
    this.activityId = this.route.snapshot.queryParams["activityId"] || this.activityIdParam;
    this.originId = this.route.snapshot.queryParams["originId"];
    this.handler = this.route.snapshot.queryParams["handler"];

    this.form = this.fb.group({
      id: [],
      hubId: [this.hubId],
      userId: [this.user?.userId],
      categoryId: [this.categoryId ? this.categoryId : ""],
      name: ["", Validators.required],
      infoText: ["", Validators.required],
      order: [0, [Validators.required, RxwebValidators.numeric()]],
      active: [true],
      unListed: [false],
      includeInMenu: [true],
      clonable: [false],
      root: [!this.categoryId],
      createdOn: [""],
      updatedOn: [""],
    });

    await this.setIsHubNavigationMenuFull();

    if (this.activityId || this.originId) {
      const url = `${this.apiUrl}/activity-info/v1/${this.activityId ? this.activityId : this.originId}`;
      try {
        let response: ActivityInfoEntity = await firstValueFrom(
          this.httpService.doGet(url)
        );
        if (this.activityId) {
          this.activityId = response.id;
          this.hubId = response.hubId;
          this.categoryId = response.categoryId;
        } else {
          response = {
            ...response,
            id: null,
            hubId: this.hubId,
            userId: this.user?.userId,
            categoryId: this.categoryId ? this.categoryId : "",
            active: false,
          };
        }
        
        this.setForm(response);
        if (response.images && response.images.length > 0) {
          this.images = response.images;
        } else {
          this.images = [""];
        }

        if (this.originId) {
          await this.cloneImages();
          this.removeOriginId();
        }
        
        setTimeout(() => {
          this.settings.loadingSpinner = false;
        }, 300);
      } catch (error) {
        this.settings.loadingSpinner = false;
        console.error("Error getting activity: ", error);
      }
    } else {
      this.images = [""];
      if (this.isHubNavigationMenuFull) {
        this.includeInMenu.disable();
        this.form.patchValue({ includeInMenu: false });
        this.showNavigationMenuLink = true;
      }
      this.settings.loadingSpinner = false;
    }
  }

  get name() {
    return this.form.get("name");
  }

  get infoText() {
    return this.form.get("infoText");
  }

  get order() {
    return this.form.get("order");
  }

  get active() {
    return this.form.get("active");
  }

  get unListed() {
    return this.form.get("unListed");
  }

  get includeInMenu() {
    return this.form.get("includeInMenu");
  }

  get qrCode() {
    if (!this.handler || !this.hubId || !this.activityId) {
      return false;
    }
    return ShortUrlHandlerComponent.shortActCatUrl(this.activityId);
  }

  onSubmit() {
    if (this.activityId) {
      this.update();
    } else {
      this.create();
    }
  }

  async create() {
    if (!this.images || this.images.length === 0 || !this.images[0]) {
      this.showSnackBar(
        this.translate.instant("messages.at_least_one_image_or_video"),
        "OK",
        2000
      );
      return;
    }
    this.settings.loadingSpinner = true;
    const url = this.apiUrl + "/activity-info/v1";
    const rawValues = this.form.getRawValue();
    rawValues["images"] = this.images;
    rawValues.infoText = await RichTextEditorComponent.uploadImages(
      rawValues.infoText,
      this.descriptionImages,
      this.imageService
    );

    const {
      hubId,
      userId,
      categoryId,
      name,
      infoText,
      order,
      active,
      unListed,
      includeInMenu,
      clonable,
      images,
    } = rawValues;

    const body: CreateActivityInfoCommand = {
      hubId,
      userId,
      categoryId,
      name,
      infoText,
      order,
      active,
      unListed,
      includeInMenu,
      clonable,
      images,
    };

    try {
      const response: ActivityInfoEntity = await firstValueFrom(
        this.httpService.doPost(url, body)
      );
      this.activityId = response.id;
      this.categoryId = response.categoryId;
      this.hubId = response.hubId;
      this.setForm(response);
      this.validateActiveLimitReached(
        rawValues["active"],
        response.active,
        ContentType.ACTIVITY_INFO
      );
      this.showSnackBar("Operation successful ", "OK", 2000);
    } catch (error) {
      this.showSnackBar("Error Processing Your Request", "OK", 2000);
      console.error("Error creating activity: ", error);
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  async update() {
    if (!this.images || this.images.length === 0 || !this.images[0]) {
      this.showSnackBar(
        this.translate.instant("messages.at_least_one_image_or_video"),
        "OK",
        2000
      );
      return;
    }
    this.settings.loadingSpinner = true;
    const rawValues = this.form.getRawValue();
    rawValues["images"] = this.images;
    rawValues.infoText = await RichTextEditorComponent.uploadImages(
      rawValues.infoText,
      this.descriptionImages,
      this.imageService
    );
    const url = this.apiUrl + "/activity-info/v1";

    const {
      id,
      hubId,
      userId,
      categoryId,
      name,
      infoText,
      order,
      active,
      unListed,
      includeInMenu,
      clonable,
      images,
    } = rawValues;

    const body: UpdateActivityInfoCommand = {
      id,
      hubId,
      userId,
      categoryId,
      name,
      infoText,
      order,
      active,
      unListed,
      includeInMenu,
      clonable,
      images,
    };

    try {
      const response: ActivityInfoEntity = await firstValueFrom(
        this.httpService.doPut(url, body)
      );
      this.activityId = response.id;
      this.categoryId = response.categoryId;
      this.hubId = response.hubId;
      this.setForm(response);
      this.validateActiveLimitReached(
        rawValues["active"],
        response.active,
        ContentType.ACTIVITY_INFO
      );
      this.showSnackBar("Operation successful ", "OK", 2000);
      this.next.emit();
    } catch (error) {
      this.validateCreatedLimitReached(error, ContentType.ACTIVITY_INFO);
      this.showSnackBar("Error Processing Your Request", "OK", 2000);
      console.error("Error updating activity: ", error);
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  canDelete() {
    super.canDelete(
      "titles.delete_activity",
      "messages.delete_activity",
      async () => {
        try {
          this.settings.loadingSpinner = true;
          await firstValueFrom(
            this.httpService.doDelete(
              `${this.apiUrl}/activity-info/v1/${this.activityId}`
            )
          );
          this.showSnackBar(
            this.translate.instant("messages.activity_deleted"),
            "OK",
            2000
          );
          this.back();
        } catch (error) {
          this.validateCreatedLimitReached(error, ContentType.ACTIVITY_INFO);
          this.showSnackBar(
            this.translate.instant("messages.activity_delete_error"),
            "OK",
            2000
          );
        } finally {
          this.settings.loadingSpinner = false;
        }
      }
    );
  }

  private setForm(response: any) {
    this.form.patchValue({ id: response.id });
    this.form.patchValue({ hubId: response.hubId });
    this.form.patchValue({ userId: response.userId });
    this.form.patchValue({ categoryId: response.categoryId });
    this.form.patchValue({ name: response.name });
    this.form.patchValue({ infoText: response.infoText });
    this.form.patchValue({ active: response.active });
    this.form.patchValue({ unListed: response.unListed });
    this.form.patchValue({ includeInMenu: response.includeInMenu });
    this.form.patchValue({ clonable: response.clonable });
    this.form.patchValue({ order: response.order });
    this.form.patchValue({ createdOn: response.createdOn });
    this.form.patchValue({ updatedOn: response.updatedOn });

    if (!response.includeInMenu && this.isHubNavigationMenuFull) {
      this.includeInMenu.disable();
      this.showNavigationMenuLink = true;
    }

    this.descriptionImages = RichTextEditorComponent.getImagesSrcFromHtmlString(
      response.infoText
    );
    this.form.markAsPristine();
    this.originalActivity = response;
  }
}
