<mat-card class="custom-activity-card">
    <mat-card-content>
        <div fxLayout="column">
            <div class="text" translate>activityReservation.appointment_duration<app-info-tooltip class="absolute-tooltip info-reservation-duration" info="tooltip.reservation_duration" infoClass="secondary"></app-info-tooltip></div>
            <div>
                <mat-radio-group [formControl]="control" aria-label="Select an option" fxLayout="row" fxLayoutAlign="center" fxFlex>
                    <mat-radio-button fxLayoutAlign="center center" [value]="15"><span class="minute-text">15<br>M</span></mat-radio-button>
                    <mat-radio-button fxLayoutAlign="center center" [value]="30"><span class="minute-text">30<br>M</span></mat-radio-button>
                    <mat-radio-button fxLayoutAlign="center center" [value]="60"><span class="minute-text">60<br>M</span></mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </mat-card-content>
</mat-card>