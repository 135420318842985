import { Component, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { isPast, parseISO } from "date-fns";
import { Location } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { DatetimeAdapter } from "@mat-datetimepicker/core";
import { TranslateService } from "@ngx-translate/core";
import { AppSettings } from "../../../app.settings";
import { HttpService } from "../../../service/http.service";
import { ImageService } from "../../../service/image.service";
import { AuthService } from "../../../auth/auth.serviceV2";
import { SentRequestDialogComponent } from "../../../components/sent-request-dialog/sent-request-dialog.component";
import { BaseClientViewComponent } from "../../baseclientview.component";
import QCVUtil from "../../../util/questionsClientViewUtil";
import { CommentsPreviewComponent } from "../../../components/comments-preview/comments-preview.component";
import { EntityType } from "../../../models/entity-type-enum";
import { RouteConstants } from "../../../app.constants";
import {
  bankTransferImageValidator,
  cashReturnValidator,
  chosenPaymentTypeValidator,
} from "../../../util/form-utils";
import { PaymentType } from "../../../models/payment-type-enum";
import { firstValueFrom } from "rxjs";
import { isEmpty } from "lodash";
import AdditionalChargesUtil from "../../../util/additionalChargesUtil";
import {
  setActivityPageHead,
  setDefaultPageHead,
} from "../../../util/seo-utils";
import { Meta, Title } from "@angular/platform-browser";
import { AzulPaymentFormComponent } from "../../../components/azul-payment-form/azul-payment-form.component";
import {
  ActivityEventEntity,
  ActivityEventRequestEntity,
  CreateActivityEventTicketRequestCommand,
} from "../../../models/activity-event";
import { HubEntity } from "../../../models/hub";
import QMUtil from "../../../util/questionsManagementUtil";
import { UserAdditionalInfoService } from "../../authentication/login/user-additional-info.service";
import { MatureContentService } from "../../../service/mature-content.service";

@Component({
  selector: "app-activity-event-client-view",
  templateUrl: "./activity-event-client-view.component.html",
  styleUrls: ["./activity-event-client-view.component.scss"],
})
export class ActivityEventClientViewComponent
  extends BaseClientViewComponent
  implements OnInit
{
  @ViewChild("commentsPreview") commentsPreview: CommentsPreviewComponent;
  @ViewChild("requestListTag") requestList;
  @ViewChild("azulPaymentEl") azulPayment: AzulPaymentFormComponent;

  readonly activityType = "ticketRequest";
  coordinates = null;
  entity: ActivityEventEntity;
  sendRequestPin;
  type = EntityType.ACTIVITY_EVENT;
  reported = false;
  questionsV1 = [];
  isPastDate = true;

  constructor(
    private authService: AuthService,
    private userAdditionalInfoService: UserAdditionalInfoService,
    private httpService: HttpService,
    protected appSettings: AppSettings,
    protected router: Router,
    protected snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    protected imageService: ImageService,
    public _adapter: DatetimeAdapter<Date>,
    protected translate: TranslateService,
    protected location: Location,
    private matureContentService: MatureContentService,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    super(appSettings, snackBar, imageService, router, location, translate);
    _adapter.setLocale(translate.getDefaultLang());
    (_adapter as any)._delegate.setLocale(translate.getDefaultLang());
  }

  async ngOnInit() {
    await this.authService.isAuthenticated();
    this.user = this.authService.getUserProfile();
    this.hubId = this.route.snapshot.queryParams["hubId"];
    this.categoryId = this.route.snapshot.queryParams["categoryId"];
    this.activityId = this.route.snapshot.queryParams["activityId"];
    this.settings.loadingSpinner = true;
    this.showPaymentResult(this.route.snapshot.queryParams["status"]);
    this.handler = this.route.snapshot.paramMap.get("handler");
    localStorage.removeItem('request_return_url');
    await this.retrieveHub(this.hubId);
    this.matureContentService.check(this.hub.adultContent);
    this.initPage();
  }

  private async initPage() {
    try {
      const activityUrl = `${this.apiUrl}/activity-event/v1/${this.activityId}`;

      const activityResponse: ActivityEventEntity = await firstValueFrom(
        this.httpService.doGet(activityUrl)
      );
      if (activityResponse != null) {
        this.entity = activityResponse;
        this.questionsV1 = QMUtil.transformQuestionsV2ToV1(
          activityResponse.questions
        );
        this.isPastDate = isPast(parseISO(this.entity.eventDate));
        setActivityPageHead(
          this.titleService,
          this.metaTagService,
          activityResponse,
          this.hub
        );
      } else {
        setDefaultPageHead(this.titleService, this.metaTagService, true);
      }
      await this.createForm();
      if (
        this.route.snapshot.fragment === "request" &&
        (await this.authService.isAuthenticated())
      ) {
        window.location.hash = "";
        if (this.sendRequestPin) {
          clearTimeout(this.sendRequestPin);
        }
        this.sendRequestPin = setTimeout(() => {
          const data = localStorage.getItem(this.entity.id);
          if (data) {
            const parsedData = JSON.parse(data);
            this.sendRequest({
              ...parsedData,
              clientId: this.user.userId,
            });
          } else {
            this.settings.loadingSpinner = false;
          }
        }, 1000);
      } else {
        this.settings.loadingSpinner = false;
      }
    } catch (error) {
      setDefaultPageHead(this.titleService, this.metaTagService, true);
      console.log("Error getting activity: ", error);
      if (error.status === 404) {
        this.notFound = true;
      }
      this.settings.loadingSpinner = false;
    }
  }

  private async retrieveHub(hubId: string) {
    try {
      const hubUrl = `${this.apiUrl}/hubs/v1/${hubId}`;
      const hubResponse: HubEntity = await firstValueFrom(
        this.httpService.doGet(hubUrl)
      );
      if (!hubResponse) return;
      this.hub = hubResponse;
    } catch (error) {
      console.log("Error getting activity: ", error);
    }
  }

  private async createForm() {
    if (!this.entity) {
      return;
    }

    this.form = this.fb.group(
      {
        hubId: [this.hubId],
        activityId: [this.activityId],
        clientId: [this.user?.userId],
        questionAnswerMap: this.fb.group(
          QCVUtil.createQuestionsForm(this.entity.questions)
        ),
        chosenPaymentType: [""],
        cashReturn: [null],
        paymentImage: [""],
        quantity: [1, [Validators.required, Validators.min(1)]],
        currentTicketPrice: [this.entity.ticketPrice],
        totalPrice: [0],
        promoCode: [''],
        promoCodeApplied: [false],
      },
      {
        validators: [
          bankTransferImageValidator,
          cashReturnValidator,
          chosenPaymentTypeValidator(this.hub.supportedPaymentTypes),
        ],
      }
    );

    if (this.entity.addressRequired) {
      this.form.addControl(
        'choosenLocation',
        this.fb.control('', [
          Validators.required,
        ])
      );
    }
  }

  get chosenPaymentType() {
    return this.form.get("chosenPaymentType") as UntypedFormControl;
  }

  get totalPrice() {
    return this.form.get("totalPrice") as UntypedFormControl;
  }

  get questionAnswerMap() {
    return this.form.get("questionAnswerMap") as UntypedFormGroup;
  }

  getCurrentPosition(options = {}) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
  }

  getQuestionsLength(): number {
    if (!this.form || !this.form.get("questionAnswerMap")) {
      return 0;
    }
    const GROUP = this.form.get("questionAnswerMap") as UntypedFormGroup;
    return Object.keys(GROUP.controls).length;
  }

  async sendRequest(body?: any) {
    if (!this.entity || !this.form || this.locked) {
      this.settings.loadingSpinner = false;
      return;
    }

    if (!(await this.authService.isAuthenticated())) {
      localStorage.setItem("return_url", `${this.router.url}#request`);
      localStorage.setItem('request_return_url', `${this.router.url}#request`);
      localStorage.setItem(
        this.entity.id,
        JSON.stringify(this.getRequestBody(body))
      );
      await this.router.navigate([RouteConstants.auth]);
      this.settings.loadingSpinner = false;
      return;
    }

    this.settings.loadingSpinner = true;

    if (!await this.userAdditionalInfoService.check(`${this.router.url}#request`)) {
      localStorage.setItem(
        this.entity.id,
        JSON.stringify(this.getRequestBody(body))
      );
      this.settings.loadingSpinner = false;
      return;
    }

    const url = `${this.apiUrl}/activity-event/v1/ticket-request`;
    const rawValues = this.getRequestBody(body);

    const {
      clientId,
      hubId,
      activityId,
      questionAnswerMap,
      location,
      totalPrice,
      clientPhoneNumber,
      chosenPaymentType,
      cashReturn,
      paymentImage,
      quantity,
      currentTicketPrice,
      choosenLocation,
      promoCode,
      promoCodeApplied,
    } = rawValues;

    const requestBody: CreateActivityEventTicketRequestCommand = {
      clientId,
      hubId,
      activityId,
      questionAnswerMap: QCVUtil.transformAnswersV1ToV2(questionAnswerMap, this.entity.questions),
      location,
      totalPrice,
      clientPhoneNumber,
      chosenPaymentType,
      cashReturn,
      paymentImage,
      quantity,
      currentTicketPrice,
      choosenLocation,
      ...(promoCodeApplied && { promoCode })
    };

    try {
      const response: ActivityEventRequestEntity = await firstValueFrom(
        this.httpService.doPost(url, requestBody)
      );
      this.locked = true;
      this.form.reset();
      this.createForm();
      if (rawValues.chosenPaymentType === PaymentType.TINYALL_PAY) {
        this.azulPayment?.azulPay(response);
        return;
      }
      this.openDialog();
    } catch (error) {
      this.showSnackBar("Error Processing Your Request", "OK", 2000);
    } finally {
      localStorage.removeItem(this.entity.id);
      this.settings.loadingSpinner = false;
    }
  }

  private getRequestBody(body) {
    if (body) {
      return body;
    }
    const rawValues = this.form.getRawValue();
    rawValues.questionAnswerMap = QCVUtil.cleanContent(
      this.entity.questions,
      rawValues
    );
    rawValues["totalPrice"] =
      Number(rawValues["totalPrice"]) > 0
        ? Number(rawValues["totalPrice"]).toFixed(2)
        : "0";
    return AdditionalChargesUtil.creanPaymentContent(rawValues);
  }

  async onCancel() {
    this.locked = false;
  }

  onHasOpen(event: any) {
    this.locked = event;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(SentRequestDialogComponent, {
      minWidth: "240px",
      data: {
        title: "labels.tickets",
        content: "messages.operation_successful",
        hasRichText: !!this.entity.newRequestMessage,
        richContent: this.entity.newRequestMessage
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.requestList.search();
      setTimeout(() => {
        const element = document.getElementById("request-list");
        if (element !== null) {
          element.scrollIntoView();
        }
      }, 300);
    });
  }

  get quantity() {
    return this.form.get("quantity").value;
  }

  get total() {
    return Number(this.entity.ticketPrice) * this.quantity;
  }

  get optionsQuantity() {
    return Math.min(
      this.entity.availableTicketCount,
      this.entity.maxTicketsPerUser
    );
  }

  get showPriceSummary() {
    return (
      !this.locked &&
      this.entity &&
      this.form &&
      this.totalPrice &&
      (this.getQuestionsLength() > 0 ||
        !isEmpty(this.entity.additionalCharges || {}) ||
        this.total > 0)
    );
  }

  get showPaymentMethods() {
    return (
      !this.locked &&
      this.form &&
      this.hub &&
      this.hub.supportedPaymentTypes &&
      this.hub.supportedPaymentTypes.length > 0 &&
      this.totalPrice.value
    );
  }

  get showSendRequest() {
    return (
      !this.locked &&
      this.form &&
      (this.chosenPaymentType.value !== PaymentType.TINYALL_PAY ||
        !this.totalPrice.value)
    );
  }

  get disableSendRequest() {
    return !this.form || this.form.invalid;
  }

  get canBuy() {
    return (
      this.entity && this.entity.availableTicketCount > 0 && !this.isPastDate
    );
  }
}
