<div
  class="header no-print"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <button (click)="close()" mat-button>
    <span translate>tinyAll.back</span>
  </button>
  <h2 class="title" translate>titles.report_abuse</h2>
</div>

<div class="page-container" fxLayout="row wrap" fxLayoutAlign="center start">
  <mat-card class="card-container">
    <mat-card-header *ngIf="parentData && parentData.user">
      <img
        mat-card-avatar
        class="avatar"
        referrerpolicy="no-referrer"
        [src]="parentData.user?.picture || noImage"
      />
      <mat-card-title>{{ parentData.user.name }}</mat-card-title>
      <mat-card-subtitle>{{ parentData.user.email }}</mat-card-subtitle>
      <mat-card-subtitle *ngIf="parentData.user.cellPhone">{{
        parentData.user.cellPhone
      }}</mat-card-subtitle>
    </mat-card-header>
  </mat-card>

  <mat-card *ngIf="entity" class="card-container">
    <div *ngIf="parentData.entityType === EntityType.REVIEW" class="comment">
      <mat-card-header>
        <img
          mat-card-avatar
          class="avatar"
          referrerpolicy="no-referrer"
          [src]="entity.userProfileImage"
        />
        <h1>{{ entity.userName }}</h1>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="row" class="rating-container">
          <star-rating
            [value]="entity.rate"
            [totalstars]="5"
            checkedcolor="#29a6a4"
            uncheckedcolor="#cccccc"
            size="18px"
            [readonly]="true"
          ></star-rating>
          <span class="time">{{ entity.createdOn | timeAgo }}</span>
        </div>
        <app-collapsible-description
          [text]="entity?.comment"
          [collapsible]="true"
          [hideCard]="true"
          [expandedByDefault]="false"
        ></app-collapsible-description>
      </mat-card-content>
    </div>
    <div *ngIf="parentData.entityType === EntityType.HUB" class="activity">
      <mat-card-header>
        <img
          mat-card-avatar
          class="avatar-square"
          referrerpolicy="no-referrer"
          [src]="entity.image"
        />
        <span class="time">{{ entity.createdOn | timeAgo }}</span>
      </mat-card-header>
      <mat-card-content>
        <h1>{{ entity.name }}</h1>
        <app-collapsible-description
          [text]="entity?.infoText"
          [collapsible]="true"
          [hideCard]="true"
          [expandedByDefault]="false"
        ></app-collapsible-description>
      </mat-card-content>
    </div>
    <div *ngIf="isActivity(parentData.entityType)" class="activity">
      <mat-card-header>
        <img
          mat-card-avatar
          class="avatar-square"
          referrerpolicy="no-referrer"
          [src]="entity.images[0]"
        />
        <span class="time">{{ entity.createdOn | timeAgo }}</span>
      </mat-card-header>
      <mat-card-content>
        <h1>{{ entity.name }}</h1>
        <app-collapsible-description
          [text]="entity?.infoText"
          [collapsible]="true"
          [hideCard]="true"
          [expandedByDefault]="false"
        ></app-collapsible-description>
      </mat-card-content>
    </div>
  </mat-card>

  <form novalidate [formGroup]="form" fxLayout="column" fxLayoutAlign="center">
    <mat-form-field appearance="outline">
      <textarea
        rows="4"
        matInput
        matTextareaAutosize
        placeholder="{{ 'placeholders.report_details' | translate }}"
        formControlName="message"
      ></textarea>
      <mat-error *ngIf="message.hasError('maxlength')" translate
        >formErrors.details_max_length</mat-error
      >
      <mat-error *ngIf="message.hasError('minlength')" translate
        >formErrors.details_min_length</mat-error
      >
      <mat-error *ngIf="message.hasError('required')" translate
        >formErrors.field_required</mat-error
      >
    </mat-form-field>
    <mat-checkbox class="can-be-contact" formControlName="canBeContact">
      <span translate>messages.follow_up_report</span>
    </mat-checkbox>

    <section class="actions" fxLayoutAlign="space-between">
      <button
        [disabled]="loading"
        mat-button
        class="cancel-button"
        (click)="close()"
      >
        <span translate>buttons.cancel</span>
      </button>

      <button
        mat-raised-button
        color="accent"
        class="save-button"
        (click)="send()"
        [disabled]="!form || !form.dirty || form.invalid"
      >
        <span *ngIf="!loading" translate>buttons.report</span>
        <mat-spinner diameter="25" *ngIf="loading"></mat-spinner>
      </button>
    </section>
  </form>
</div>
