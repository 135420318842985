import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaginationPipe} from './pagination/pagination.pipe';
import { ProfilePicturePipe } from './profilePicture/profilePicture.pipe';
import { ChatPersonSearchPipe } from './search/chat-person-search.pipe';
import { UserSearchPipe } from './search/user-search.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';
import { MailSearchPipe } from './search/mail-search.pipe';
import { TimeAgoPipe } from './timeAgo/time-ago.pipe';
import { TimeAgoAltPipe } from './timeAgoAlt/time-ago-alt.pipe';
import { TimeAgoStrictStaticPipe } from './timeAgoStrictStatic/time-ago-string-static.pipe';
import { SafeHtmlPipe } from './safeHtml/safe-html.pipe';
import { LocalizedDatePipe } from './localizedDate/localized-date.pipe';
import { PluralTranslatePipe } from './plurarTranslate/plurarlTranstale.pipe';

@NgModule({
    imports: [ 
        CommonModule 
    ],
    declarations: [
        PaginationPipe,
        ProfilePicturePipe,
        ChatPersonSearchPipe,
        UserSearchPipe,
        TruncatePipe,
        MailSearchPipe,
        TimeAgoPipe,
        TimeAgoAltPipe,
        TimeAgoStrictStaticPipe,
        SafeHtmlPipe,
        LocalizedDatePipe,
        PluralTranslatePipe,
    ],
    exports: [
        PaginationPipe,
        ProfilePicturePipe,
        ChatPersonSearchPipe,
        UserSearchPipe,
        TruncatePipe,
        MailSearchPipe,
        TimeAgoPipe,
        TimeAgoAltPipe,
        TimeAgoStrictStaticPipe,
        SafeHtmlPipe,
        LocalizedDatePipe,
        PluralTranslatePipe,
    ]
})
export class PipesModule { }
