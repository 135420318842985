<form
    novalidate
    *ngIf="form"
    [formGroup]="form"
    class="container"
    data-gramm="false"
    data-gramm_editor="false"
    data-enable-grammarly="false"
>
    <angular-editor *ngIf="mode === 'edit'" id="editMode" [formControlName]="fieldName" [config]="editConfig" [placeholder]="placeholder" [perfectScrollbar]="perfectScrollBarConfig"></angular-editor>
</form>