import { UntypedFormBuilder, UntypedFormArray, Validators, UntypedFormControl } from '@angular/forms';
import { Component, Input} from '@angular/core';
import { 
  faLink
 } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-tracking-status-management',
  templateUrl: './tracking-status-management.component.html',
  styleUrls: ['./tracking-status-management.component.scss']
})
export class TrackingStatusManagementComponent {


  @Input() form: UntypedFormArray;

  constructor(private fb: UntypedFormBuilder) {}

  get trackingStatuses() {
    return this.form.controls as UntypedFormControl[];
  }

  add() {
    const fg = this.fb.control('', [Validators.required]);
    this.form.push(fg);
  }

  remove(index: number): void {
    this.form.removeAt(index);
    this.form.markAsDirty();
  }
}
