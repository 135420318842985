<div fxFlex="100" class="flex-p" *ngIf="show">
  <mat-card>
    <mat-card-header>
      <mat-card-subtitle>
        <h3 translate>titles.readiness_checklist</h3>
      </mat-card-subtitle>
    </mat-card-header>

    <p class="description">
      <span translate>messages.hub_readiness_checklist_description</span>
      <a class="text-accent underline" href="https://tinyall.app/su/a/d3efe467-9acb-4a52-bcc3-452b5ef69896" target="_blank" translate>messages.hub_readiness_checklist_description_2</a>
    </p>

    <div fxLayout="column wrap" class="checklist-container">
      <div fxLayout="row wrap" fxLayoutAlign="start center">
        <div class="icon number" *ngIf="!hub">1</div>
        <div class="icon check" *ngIf="hub"><mat-icon>check</mat-icon></div>
        <button mat-button (click)="goToManageHub()">
          <span translate>buttons.hub_readiness_checklist_1</span>
          
        </button>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start center">
        <div
          class="icon number"
          [ngClass]="{ disabled: !hub }"
          *ngIf="!hasPaymentMethod"
        >
          2
        </div>
        <div class="icon check" *ngIf="hasPaymentMethod">
          <mat-icon>check</mat-icon>
        </div>
        <button mat-button [disabled]="!hub" (click)="goToPayments()">
          <span translate>buttons.hub_readiness_checklist_3</span>
        </button>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start center">
        <div
          class="icon number"
          [ngClass]="{ disabled: !hub }"
          *ngIf="!hasActivities"
        >
          3
        </div>
        <div class="icon check" *ngIf="hasActivities">
          <mat-icon>check</mat-icon>
        </div>
        <button mat-button [disabled]="!hub" (click)="goToCreateActivity()">
          <span translate>buttons.hub_readiness_checklist_4</span>
        </button>
      </div>
    </div>
  </mat-card>
</div>
