import { Meta, Title } from '@angular/platform-browser';
import { ShortUrlHandlerComponent } from '../components/short-url-handler/short-url-handler.component';
import { environment } from '../../environments/environment';

interface metaHeadOptions {
  title: string;
  description?: string;
  image: string;
  keywords: string[];
  noIndex?: boolean;
  infoText?: string;
  url: string;
}

export const setPageHead = (
  titleService: Title,
  metaTagService: Meta,
  options: metaHeadOptions
) => {
  const { title, description, infoText, image, keywords, noIndex, url } =
    options;
  const descriptionText = infoText
    ? infoText
        .replace(/<[^>]*>/g, '')
        .replace(new RegExp('&nbsp;', 'g'), '')
        .trim()
    : description;

  titleService.setTitle(title);
  const tags = [
    { name: 'keywords', content: keywords.toString() },
    { name: 'description', content: descriptionText },
    { property: 'og:image', content: image },
    { property: 'og:type', content: 'website' },
    { property: 'og:title', content: title },
    { property: 'og:description', content: descriptionText },
    { property: 'og:site_name', content: 'TinyAll' },
    { property: 'og:url', content: url },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: descriptionText },
    { name: 'twitter:image', content: image },
    !environment.production
      ? { name: 'robots', content: 'noindex, nofollow' }
      : { name: 'robots', content: 'index, follow' },
  ];
  tags.forEach(m=> metaTagService.updateTag(m));
};

export const setDefaultPageHead = (
  titleService: Title,
  metaTagService: Meta,
  noIndex: boolean
) => {
  const title = 'TinyAll';
  const image =
    'https://tinyall.app/assets/img/app/full-icon.png';
  const description =
    'TinyAll.app es la plataforma más fácil y rápida para manejar tu presencia en línea como un app, para que puedas manejar las interacciones con tus clientes y seguidores y proveerles productos y servicios en línea.';
  titleService.setTitle(title);
  const tags = [
    { name: 'keywords', content: 'cms, customer, interaction, reservation, website, ticket, events, shopping, menu' },
    { name: 'description', content: description },
    { property: 'og:image', content: image },
    { property: 'og:type', content: 'website' },
    { property: 'og:title', content: title },
    { property: 'og:description', content: description },
    { property: 'og:site_name', content: title },
    { property: 'og:url', content: location.href },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:image', content: image },
    !environment.production
      ? { name: 'robots', content: 'noindex, nofollow' }
      : { name: 'robots', content: 'index, follow' },
  ];
  tags.forEach(m=> metaTagService.updateTag(m));
};

export const setHubPageHead = (
  titleService: Title,
  metaTagService: Meta,
  hub: any
) => {
  const url = ShortUrlHandlerComponent.shortHubUrl(hub.urlHandler);
  setPageHead(titleService, metaTagService, {
    title: hub.name,
    infoText: hub.infoText,
    image: hub.icon,
    keywords: hub.tags,
    url: url || location.href,
    noIndex: hub.unlisted || hub.restricted || !hub.active,
  });
};

export const setActivityPageHead = (
  titleService: Title,
  metaTagService: Meta,
  activity: any,
  hub: any
) => {
  const url = ShortUrlHandlerComponent.shortActCatUrl(activity.id);
  setPageHead(titleService, metaTagService, {
    title: activity.name,
    infoText: activity.infoText,
    image: activity.images[0],
    keywords: hub ? hub.tags : [],
    url: url || location.href,
    noIndex: activity.unListed || !activity.active,
  });
};

//
