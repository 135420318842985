import { Component, Input } from '@angular/core';
import { AzulStatus } from '../../models/azul-status-enum';

@Component({
  selector: 'app-payment-status-tag',
  templateUrl: './payment-status-tag.component.html',
  styleUrls: ['./payment-status-tag.component.scss'],
})
export class PaymentStatusTag {
  @Input() status: AzulStatus | null | undefined;
}
