import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UpdateUserCommand, UserEntity } from '../models/user';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class UserService {
  private readonly USER_API_URL = environment.apiUrl + '/users/v1';

  constructor(private http: HttpClient) {}

  public async getUser(tokenId: string): Promise<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + tokenId,
      Accept: 'application/json',
    });
    return firstValueFrom(
      this.http.get<any>(this.USER_API_URL, { headers: httpHeaders })
    );
  }

  public async registerUser(tokenId: string, user: any): Promise<UserEntity> {
    const userData: UpdateUserCommand = {
      userId: user.uid,
      cellPhone: null,
      email: user.email,
      name: user.displayName,
      picture: user.photoURL,
      language: window.navigator.language
        ? window.navigator.language.split('-')[0]
        : 'es',
      notifiableFromClients: true,
      notifiableFromHubs: true,
      notifiableFromPromo: false,
      termOfServiceAgree: false,
      locations: [],
    };
    if (!userData.picture) {
      delete userData.picture;
    }
    const httpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + tokenId,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    return firstValueFrom(
      this.http.post<UserEntity>(this.USER_API_URL, JSON.stringify(userData), {
        headers: httpHeaders,
      })
    );
  }

  public async updateUser(
    tokenId: string,
    user: UpdateUserCommand
  ): Promise<UserEntity> {
    const {
      userId,
      cellPhone,
      email,
      name,
      picture,
      language,
      notifiableFromClients,
      notifiableFromHubs,
      notifiableFromPromo,
      termOfServiceAgree,
      locations,
    } = user;

    const userData: UpdateUserCommand = {
      userId,
      cellPhone,
      email,
      name,
      picture,
      language,
      notifiableFromClients,
      notifiableFromHubs,
      notifiableFromPromo,
      termOfServiceAgree,
      locations,
    };
    if (!userData.picture) {
      delete userData.picture;
    }
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + tokenId,
      Accept: 'application/json',
    });
    return firstValueFrom(
      this.http.put<UserEntity>(this.USER_API_URL, JSON.stringify(userData), {
        headers: httpHeaders,
      })
    );
  }
}
