import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Country } from '@angular-material-extensions/select-country';
import { DatetimeAdapter } from '@mat-datetimepicker/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom, Observable, of } from 'rxjs';
import { debounceTime, map, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppSettings } from '../../../app.settings';
import { HttpService } from '../../../service/http.service';
import { ImageService } from '../../../service/image.service';
import { BaseFormComponent } from '../../baseform.component';
import { RouteConstants } from '../../../app.constants';
import EntityTypeToUrlMap from '../../../util/entity-type-to-url-map';
import DAYS from '../../../util/days';
import {
  getCountries,
  getCountryFromAlpha3Code,
} from '../../../util/countries';
import { RichTextEditorComponent } from '../../../components/rich-text-editor/rich-text-editor.component';
import REGEX from '../../../util/regex';
import { setDefaultPageHead } from '../../../util/seo-utils';
import { Meta, Title } from '@angular/platform-browser';
import {
  CreateHubCommand,
  HubEntity,
  UpdateHubCommand,
} from '../../../models/hub';
import { AddressBean, PageEntity } from '../../../models/types';
import { UserAdditionalInfoService } from '../../authentication/login/user-additional-info.service';
import { environment } from '../../../../environments/environment';
import { WorkflowInfoDialogComponent } from '../components/workflow-info-dialog/workflow-info-dialog.component';
import { templates, templateBusinessCategories } from '../../../util/templates';
import { ShortUrlHandlerComponent } from '../../../components/short-url-handler/short-url-handler.component';
import { HubClonerComponent } from '../../../components/hub-cloner/hub-cloner.component';
import Swal from 'sweetalert2';
import { EntityType } from '../../../models/entity-type-enum';
import { AuthService } from '../../../auth/auth.serviceV2';
import {
  HubCreationStatus,
  getStepFromStatus,
} from '../../../models/hub-creation-status-enum';
import { YesNoDialogComponent } from '../../../components/yes-no-dialog/yes-no-dialog.component';
import { ContentType } from '../../../models/content-type-enum';
import { SpecialPromoCodeDialogComponent } from '../components/special-promo-code-dialog/special-promo-code-dialog.component';
import { UserEntity } from '../../../models/user';
import { FeatureFlag } from '../../../models/feature-flag-enum';
import { FeatureFlagsService } from '../../../service/feature-flags.service';
import { delay } from '../../../util';
import { isImage } from '../../../util/image-utils';

@Component({
  selector: 'app-hub-management-workflow',
  templateUrl: './hub-management-workflow.component.html',
  styleUrls: ['./hub-management-workflow.component.scss'],
})
export class HubManagementWorkflowComponent
  extends BaseFormComponent
  implements OnInit
{
  lastUpdate = new Date();
  daysOff = new Set<string>();
  canShowMap = false;

  predefinedCountries: Country[] = [];
  selectedCountry = null;

  readonly urlRegx = REGEX.URL;
  readonly maxImageSize = environment.maxImageSizeInMB;
  readonly ContentType = ContentType;
  readonly ShortUrlHandlerComponent = ShortUrlHandlerComponent;

  hub: HubEntity;
  reseting = false;

  maxSizeErrors = {
    imageMaxSizeError: false,
    splashMaxSizeError: false,
    iconMaxSizeError: false,
  };

  selectedTab: number = 0;
  MAX_TAB_INDEX = 12;
  private mainContent;

  detailsForm: UntypedFormGroup;
  imagesForm: UntypedFormGroup;
  descriptionForm: UntypedFormGroup;
  hubAddressForm: UntypedFormGroup;
  locationForm: UntypedFormGroup;
  socialNetworksForm: UntypedFormGroup;
  scheduleForm: UntypedFormGroup;
  paymentMethodsForm: UntypedFormGroup;
  businessTypesForm: UntypedFormGroup;

  templateData;
  seledtedTemplateCategory;
  triedToSubmitBusinessType: boolean = false;
  triedToSubmitTemplate: boolean = false;
  cloningActivity?: string = null;
  clonedActivity: number = 0;
  totalActivities: number = 0;

  isSaving = false;

  introAudio;

  activitiesData;
  currentActivity = 0;
  applySpecialPromoCode: boolean = false;

  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    protected router: Router,
    protected httpService: HttpService,
    private userAdditionalInfoService: UserAdditionalInfoService,
    public _adapter: DatetimeAdapter<Date>,
    protected appSettings: AppSettings,
    protected snackBar: MatSnackBar,
    protected imageService: ImageService,
    public translate: TranslateService,
    public featureFlags: FeatureFlagsService,
    public dialog: MatDialog,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    super(
      httpService,
      appSettings,
      snackBar,
      imageService,
      router,
      dialog,
      translate
    );
    _adapter.setLocale(translate.getDefaultLang());
    (_adapter as any)._delegate.setLocale(translate.getDefaultLang());
  }

  get qrCode() {
    if (!this.hub?.urlHandler) {
      return false;
    }
    return `${environment.appUrl}/${this.hub.urlHandler}`;
  }

  async ngOnInit() {
    setDefaultPageHead(this.titleService, this.metaTagService, false);
    this.predefinedCountries = getCountries(this.translate.getDefaultLang());
    this.mainContent = document.getElementById('main-content');
    this.settings.loadingSpinner = true;
    this.user = this.authService.getUserProfile();
    this.userAdditionalInfoService.check(this.router.url);
    this.applySpecialPromoCode = await this.featureFlags.getFeatureFlag(
      FeatureFlag.SPECIAL_PROMO_CODE
    );
    this.introAudio = `/assets/audio/workflow/${this.translate.getDefaultLang()}/step-0.mp3`;
    this.createForm();
    let currentStep = await this.getHub();
    if (currentStep == 9) {
      await this.getAllActivities();
      if (this.activitiesData) {
        currentStep = 11;
      }
    }
    this.selectedTab = currentStep;
  }

  get HubCreationStatus() {
    return HubCreationStatus;
  }

  async getHub() {
    this.settings.loadingSpinner = true;
    try {
      this.hub = await firstValueFrom(
        this.httpService.doGet(`${this.apiUrl}/hubs/v1`)
      );
      this.settings.loadingSpinner = false;
      this.hubId = this.hub.id;
      if (this.hubId) {
        this.detailsFormUrlHandler.disable();
      }

      if (this.hub && this.hub.address && this.hub.address.country) {
        this.selectedCountry = getCountryFromAlpha3Code(
          this.hub.address.country,
          this.translate.getDefaultLang()
        );
      }

      this.setForm(this.hub);
      return getStepFromStatus(this.hub.creationStatus);
    } catch (error) {
      return 0;
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  createForm() {
    this.createDetailsForm();
    this.createImagesForm();
    this.createDescriptionForm();
    this.createAddressForm();
    this.createLocationForm();
    this.createSocialNetworksForm();
    this.createScheduleForm();
  }

  setForm(response: HubEntity) {
    this.setDetailsForm(response);
    this.setImagesForm(response);
    this.setDescriptionForm(response);
    this.setAddressForm(response, response.address || {});
    this.setLocationForm(response);
    this.setSocialNetworksForm(response);
    this.setScheduleForm(response);
  }

  // images_step

  createImagesForm() {
    this.imagesForm = this.fb.group({
      id: [''],
      userId: [''],
      image: [null, Validators.required],
      icon: [null, Validators.required],
    });
  }

  setImagesForm(response: HubEntity) {
    this.imagesForm.patchValue({ id: response.id });
    this.imagesForm.patchValue({ userId: response.userId });
    this.imagesForm.patchValue({ image: response.image });
    this.imagesForm.patchValue({ icon: response.icon });
    this.imagesForm.markAsPristine();
  }

  get imagesFormImage() {
    return this.imagesForm.get('image');
  }

  get imagesFormIcon() {
    return this.imagesForm.get('icon');
  }

  // END images_step

  // details_step

  createDetailsForm() {
    this.detailsForm = this.fb.group({
      id: [''],
      userId: [''],
      name: ['', Validators.required],
      urlHandler: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.-]+$'),
          Validators.maxLength(30),
          Validators.minLength(3),
        ],
        this.existingValidator(),
      ],
      email: ['', [Validators.email, Validators.required]],
      phoneNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^(([1-9][0-9]{2})|[1-9][0-9]{2})[1-9][0-9]{2}[0-9]{4}$'
          ),
        ],
      ],
      tags: this.fb.array([]),
    });
  }

  setDetailsForm(response: HubEntity) {
    this.detailsForm.patchValue({ id: response.id });
    this.detailsForm.patchValue({ userId: response.userId });
    this.detailsForm.patchValue({ name: response.name });
    this.detailsForm.patchValue({ urlHandler: response.urlHandler });
    this.detailsForm.patchValue({ email: response.email });
    this.detailsForm.patchValue({ phoneNumber: response.phoneNumber });
    const TAGS = this.detailsFormGetTags();
    TAGS.clear();

    for (const TAG of response.tags) {
      TAGS.push(this.fb.control(TAG));
    }
    this.detailsForm.markAsPristine();
  }

  get detailsFormName() {
    return this.detailsForm.get('name');
  }

  get detailsFormUrlHandler() {
    return this.detailsForm.get('urlHandler');
  }

  get detailsFormEmail() {
    return this.detailsForm.get('email');
  }

  get detailsFormPhoneNumber() {
    return this.detailsForm.get('phoneNumber');
  }

  detailsFormGetTags(): UntypedFormArray {
    return this.detailsForm.get('tags') as UntypedFormArray;
  }

  // END details_step

  // description_step

  createDescriptionForm() {
    this.descriptionForm = this.fb.group({
      id: [''],
      userId: [''],
      infoText: [''],
      active: [true],
      unlisted: [false],
      adultContent: [false],
    });
  }

  setDescriptionForm(response: HubEntity) {
    this.descriptionForm.patchValue({ id: response.id });
    this.descriptionForm.patchValue({ userId: response.userId });
    this.descriptionForm.patchValue({ infoText: response.infoText });
    this.descriptionForm.patchValue({ unlisted: response.unlisted });
    this.descriptionForm.patchValue({ adultContent: response.adultContent });

    if (response.adultContent) {
      this.descriptionFormAdultContent.disable();
    }

    if (
      getStepFromStatus(response.creationStatus) >=
      getStepFromStatus(HubCreationStatus.DESCRIPTION)
    ) {
      this.descriptionForm.patchValue({ active: response.active });
    }

    this.descriptionImages = RichTextEditorComponent.getImagesSrcFromHtmlString(
      response.infoText
    );

    this.descriptionForm.markAsPristine();
  }

  get descriptionFormActive() {
    return this.descriptionForm.get('active');
  }

  get descriptionFormUnlisted() {
    return this.descriptionForm.get('unlisted');
  }

  get descriptionFormAdultContent() {
    return this.descriptionForm.get('adultContent');
  }

  // END description_step

  // address_step

  createAddressForm() {
    this.hubAddressForm = this.fb.group({
      id: [''],
      userId: [''],
      country: ['', Validators.required],
      stateOrProvidence: ['', Validators.required],
      city: ['', Validators.required],
      address: ['', Validators.required],
      address2: [''],
      zipCode: [''],
      locationHint: [''],
    });
  }

  setAddressForm(response: HubEntity, responseAddress: AddressBean | any) {
    this.hubAddressForm.patchValue({ id: response.id });
    this.hubAddressForm.patchValue({ userId: response.userId });
    this.hubAddressForm.patchValue({ country: responseAddress.country });
    this.hubAddressForm.patchValue({
      stateOrProvidence: responseAddress.stateOrProvidence,
    });
    this.hubAddressForm.patchValue({ city: responseAddress.city });
    this.hubAddressForm.patchValue({ address: responseAddress.address });
    this.hubAddressForm.patchValue({ address2: responseAddress.address2 });
    this.hubAddressForm.patchValue({ zipCode: responseAddress.zipCode });
    this.hubAddressForm.patchValue({
      locationHint: responseAddress.locationHint,
    });

    this.hubAddressForm.markAsPristine();
  }

  get hubAddressFormCountry() {
    return this.hubAddressForm.get('country') as UntypedFormControl;
  }

  get hubAddressFormStateOrProvidence() {
    return this.hubAddressForm.get('stateOrProvidence') as UntypedFormControl;
  }

  get hubAddressFormCity() {
    return this.hubAddressForm.get('city') as UntypedFormControl;
  }

  get hubAddressFormAddress() {
    return this.hubAddressForm.get('address');
  }

  hubAddressFormOnCountrySelected(country: Country) {
    this.hubAddressFormCountry.setValue(country.alpha3Code);
    this.selectedCountry = country;
    this.hubAddressForm.markAsDirty();
  }

  // END address_step

  // location_step
  createLocationForm() {
    this.locationForm = this.fb.group({
      id: [''],
      userId: [''],
      location: [''],
    });
  }

  setLocationForm(response: HubEntity) {
    this.locationForm.patchValue({ id: response.id });
    this.locationForm.patchValue({ userId: response.userId });
    this.locationForm.patchValue({ location: response.location });

    this.locationForm.markAsPristine();
  }

  get locationFormLocation() {
    return this.locationForm.get('location');
  }

  locationFormSetLocation() {
    this.settings.loadingSpinner = true;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            coordinates: [position.coords.latitude, position.coords.longitude],
            type: 'Point',
          };
          this.locationForm.patchValue({ location: location });
          this.locationForm.markAsDirty();
          this.settings.loadingSpinner = false;
        },
        (error) => {
          this.settings.loadingSpinner = false;
          console.error('Geo position not found');
        }
      );
    } else {
      this.settings.loadingSpinner = false;
      console.error('Geo location not found');
    }
  }

  // END location_step

  // socialnetworks_step
  createSocialNetworksForm() {
    this.socialNetworksForm = this.fb.group({
      id: [''],
      userId: [''],
      socialLinks: this.fb.group({
        facebook: [''],
        twitter: [''],
        linkedin: [''],
        instagram: [''],
        snapchat: [''],
        pinterest: [''],
        reddit: [''],
        whatsapp: [''],
        tumblr: [''],
        telegram: [''],
        youtube: [''],
        medium: [''],
      }),
      customLinks: this.fb.array([]),
    });
  }

  setSocialNetworksForm(response: HubEntity) {
    this.socialNetworksForm.patchValue({ id: response.id });
    this.socialNetworksForm.patchValue({ userId: response.userId });
    this.socialNetworksForm.patchValue({ socialLinks: response.socialLinks });

    const customLinksForm = this.socialNetworksFormCustomLinks;
    customLinksForm.clear();

    if (response.customLinks) {
      for (const key of Object.keys(response.customLinks || {})) {
        customLinksForm.push(
          this.fb.group({
            name: this.fb.control(key, [
              Validators.required,
              RxwebValidators.unique(),
            ]),
            value: this.fb.control(response.customLinks[key], [
              Validators.required,
              Validators.pattern(this.urlRegx),
            ]),
          })
        );
      }
    }

    this.socialNetworksForm.markAsPristine();
  }

  get socialNetworksFormSocialLinks() {
    return this.socialNetworksForm.get('socialLinks') as UntypedFormGroup;
  }

  get socialNetworksFormCustomLinks() {
    return this.socialNetworksForm.get('customLinks') as UntypedFormArray;
  }

  // END socialnetworks_step

  // schedule_step
  createScheduleForm() {
    this.scheduleForm = this.fb.group({
      SUNDAY: this.getDayForm(),
      MONDAY: this.getDayForm(),
      TUESDAY: this.getDayForm(),
      WEDNESDAY: this.getDayForm(),
      THURSDAY: this.getDayForm(),
      FRIDAY: this.getDayForm(),
      SATURDAY: this.getDayForm(),
    });
  }

  setScheduleForm(response: HubEntity) {
    if (response.scheduleBean && response.scheduleBean.entries) {
      this.scheduleFormSetScheduleTime(response.scheduleBean.entries);
    }
    this.daysOff = new Set(response.exceptions);

    this.scheduleForm.markAsPristine();
  }

  scheduleFormSetScheduleTime(entries: any[]) {
    for (const ENTRY of entries) {
      const CONTROL_DAY = this.scheduleForm.get(ENTRY.dayOfWeek);
      if (ENTRY.intervals && ENTRY.intervals.length > 0) {
        const INTERVAL = ENTRY.intervals[0];

        const START = new Date(INTERVAL.start).toLocaleTimeString();
        const END = new Date(INTERVAL.end).toLocaleTimeString();

        const SPLITTED_START = START.split(':');
        const SPLITTED_END = END.split(':');

        CONTROL_DAY.get('from').setValue(
          `${SPLITTED_START[0]}:${SPLITTED_START[1]} ${START.split(' ')[1]}`
        );
        CONTROL_DAY.get('to').setValue(
          `${SPLITTED_END[0]}:${SPLITTED_END[1]} ${END.split(' ')[1]}`
        );
      }
    }
  }

  scheduleFormOnDaysOffChange() {
    this.scheduleForm.markAsDirty();
  }

  // END schedule_step

  showMap() {
    this.canShowMap = true;
  }

  dirtyImages(): boolean {
    return (
      this.imagesForm.get('image').dirty || this.imagesForm.get('icon').dirty
    );
  }

  deleteDirtyImages() {
    if (this.imagesForm.get('image').dirty) {
      this.deleteImage(this.imagesForm.get('image').value);
    }

    if (this.imagesForm.get('icon').dirty) {
      this.deleteImage(this.imagesForm.get('icon').value);
    }
  }

  back() {
    if (this.hubId && this.dirtyImages()) {
      this.deleteDirtyImages();
    }
    this.router.navigateByUrl(`${RouteConstants.hub}/owner`).then();
  }

  async onSubmit(status: HubCreationStatus, noValidate: boolean = false) {
    if (this.isSaving || (!noValidate && !this.getValidateForm())) return false;
    if (this.hubId) {
      return await this.updateHub(status);
    }
    return await this.createHub();
  }

  async submitAndGoTo(
    status: HubCreationStatus,
    step: number,
    noValidate: boolean = false
  ) {
    const result = await this.onSubmit(status, noValidate);
    if (result) {
      if (HubCreationStatus.TEMPLATE === status) {
        await this.applyPromoCode();
      }
      this.goTo(step);
    }
  }

  async createHub() {
    this.isSaving = true;
    const rawValues = this.detailsForm.getRawValue();
    rawValues.urlHandler = rawValues.urlHandler.toLowerCase();

    const { userId, name, urlHandler, email, phoneNumber, tags } = rawValues;

    const body: CreateHubCommand = {
      name,
      urlHandler,
      email,
      userId,
      phoneNumber,
      tags,
      creationStatus: HubCreationStatus.INITIAL_DETAILS,
    };

    try {
      const response: HubEntity = await firstValueFrom(
        this.httpService.doPost(`${this.apiUrl}/hubs/v1`, body)
      );
      this.hubId = response.id;
      if (this.hubId) {
        this.detailsFormUrlHandler.disable();
      }
      this.hub = response;
      this.setForm(response);
      this.showSnackBar('Operation successful ', 'OK', 2000);
      return true;
    } catch (error) {
      this.showSnackBar('Error Processing Your Request', 'OK', 2000);
      return false;
    } finally {
      this.isSaving = false;
    }
  }

  async updateHub(status: HubCreationStatus) {
    this.isSaving = true;
    let rawValues: any = {};

    if (status === HubCreationStatus.INITIAL_DETAILS) {
      rawValues = this.detailsForm.getRawValue();
    } else if (status === HubCreationStatus.IMAGES) {
      rawValues = this.imagesForm.getRawValue();
    } else if (status === HubCreationStatus.DESCRIPTION) {
      rawValues = this.descriptionForm.getRawValue();
      rawValues.infoText = await RichTextEditorComponent.uploadImages(
        rawValues.infoText,
        this.descriptionImages,
        this.imageService
      );
    } else if (status === HubCreationStatus.ADDRESS) {
      const {
        country,
        stateOrProvidence,
        city,
        address,
        address2,
        zipCode,
        locationHint,
      } = this.hubAddressForm.getRawValue();
      rawValues.address = {
        country,
        stateOrProvidence,
        city,
        address,
        address2,
        zipCode,
        locationHint,
      };
    } else if (status === HubCreationStatus.LOCATION) {
      rawValues = this.locationForm.getRawValue();
    } else if (status === HubCreationStatus.SOCIAL) {
      rawValues = this.socialNetworksForm.getRawValue();
      rawValues.socialLinks = this.cleanEmptyMap(rawValues.socialLinks);
      rawValues.customLinks = this.arrayToMap(rawValues.customLinks);
    } else if (status === HubCreationStatus.AVAILABILITY) {
      rawValues = {
        scheduleBean: {
          durationInMinutes: 60,
          entries: this.getEntries(this.scheduleForm),
        },
      };
      rawValues.exceptions = Array.from(this.daysOff);
    } else {
      rawValues = {};
    }

    const {
      id,
      name,
      urlHandler,
      email,
      userId,
      phoneNumber,
      image,
      address,
      adultContent,
      exceptions,
      tags,
      socialLinks,
      customLinks,
      location,
      scheduleBean,
      icon,
      infoText,
      supportedPaymentTypes,
      bankTransactionInfo,
    } = this.hub;

    const body: UpdateHubCommand = {
      id,
      name,
      urlHandler,
      email,
      userId,
      phoneNumber,
      image,
      address,
      active: true,
      unlisted: false,
      adultContent,
      exceptions,
      tags,
      socialLinks,
      customLinks,
      location,
      scheduleBean,
      icon,
      infoText,
      supportedPaymentTypes,
      bankTransactionInfo,
      ...rawValues,
      creationStatus: status,
    };

    try {
      const response: HubEntity = await firstValueFrom(
        this.httpService.doPut(`${this.apiUrl}/hubs/v1`, body)
      );
      this.hubId = response.id;
      this.hub = response;
      this.setForm(response);
      this.showSnackBar('Operation Successful', 'OK', 2000);
      return true;
    } catch (error) {
      this.showSnackBar('Error Processing Your Request', 'OK', 2000);
      console.error('Error updating hub: ', error);
      return false;
    } finally {
      this.isSaving = false;
    }
  }

  hasHubId(): boolean {
    return !!this.hubId;
  }

  cleanEmptyMap(linksMap) {
    const cleanMap = {};
    for (const key of Object.keys(linksMap)) {
      const value = linksMap[key].trim();
      if (value) {
        cleanMap[key] = value;
      }
    }
    return cleanMap;
  }

  arrayToMap(array: any[]) {
    const tMap = {};
    for (const obj of array) {
      if (obj.value && obj.value.trim() && obj.name) {
        tMap[obj.name] = obj.value.trim();
      }
    }
    return tMap;
  }

  // Scheduler picker
  getDayForm(): UntypedFormGroup {
    return this.fb.group({
      from: [null],
      to: [null],
    });
  }

  getEntries(formEntries: UntypedFormGroup) {
    const ENTRIES = [];
    for (const DAY of DAYS) {
      const ENTRY = formEntries.get(DAY);
      if (ENTRY) {
        const FROM = ENTRY.get('from').value;
        const TO = ENTRY.get('to').value;
        if (FROM && TO) {
          ENTRIES.push({
            dayOfWeek: DAY,
            intervals: [
              {
                start: new Date(`January 01, 2000 ${FROM}`).toISOString(),
                end: new Date(`January 01, 2000 ${TO}`).toISOString(),
              },
            ],
          });
        }
      }
    }
    return ENTRIES;
  }

  // Deprecated
  getFullTimeFormat(date): string {
    const HOUR = date.getUTCHours();
    const MINUTES = date.getUTCMinutes();
    let HOUR_TEXT = String(HOUR);
    let MINUTES_TEXT = String(MINUTES);
    if (HOUR < 10) {
      HOUR_TEXT = '0' + HOUR_TEXT;
    }
    if (MINUTES < 10) {
      MINUTES_TEXT = '0' + MINUTES_TEXT;
    }
    return `${HOUR_TEXT}:${MINUTES_TEXT}:00`;
  }

  // END Schedule Picker

  infoValidateError(): boolean {
    return (
      this.detailsFormName.hasError('required') ||
      this.detailsFormUrlHandler.hasError('required') ||
      this.detailsFormUrlHandler.hasError('pattern') ||
      this.detailsFormUrlHandler.hasError('maxlength') ||
      this.detailsFormUrlHandler.hasError('minlength') ||
      this.detailsFormUrlHandler.hasError('existing') ||
      this.detailsFormEmail.hasError('email') ||
      this.detailsFormEmail.hasError('required') ||
      this.detailsFormPhoneNumber.hasError('required')
    );
  }

  addressValidateError(): boolean {
    return (
      this.hubAddressFormCountry.hasError('required') ||
      this.hubAddressFormStateOrProvidence.hasError('required') ||
      this.hubAddressFormCity.hasError('required') ||
      this.hubAddressFormAddress.hasError('required')
    );
  }

  isEmptyInputValue(value: any): boolean {
    return value === null || value.length === 0;
  }

  existingValidator(initial: string = ''): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<{ [key: string]: any } | null>
      | Observable<{ [key: string]: any } | null> => {
      if (this.isEmptyInputValue(control.value)) {
        return of(null);
      } else if (control.value === initial) {
        return of(null);
      } else {
        return control.valueChanges.pipe(
          debounceTime(300),
          take(1),
          switchMap((_) =>
            this.getUrlHandler(control.value.toLowerCase()).pipe(
              map((handler) => {
                return handler && handler.content && handler.content.length > 0
                  ? { existing: { value: control.value } }
                  : null;
              })
            )
          )
        );
      }
    };
  }

  getUrlHandler(value): Observable<PageEntity<HubEntity>> {
    return this.httpService.doGet(
      `${this.apiUrl}/hubs/v1/search?querySearch=urlHandler:'${value}'`
    );
  }

  onSelectedImage(file: FileList, form: UntypedFormGroup, field: string) {
    if(!isImage(file.item(0).name)) {
      return;
    }
    this.settings.loadingSpinner = true;
    const fileSize = file.item(0).size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb > this.maxImageSize) {
      this.maxSizeErrors[field] = true;
      this.settings.loadingSpinner = false;
      return;
    } else {
      this.maxSizeErrors[field] = false;
    }

    super.onSelectedImage(file, form, field);
  }

  async goNext() {
    if (
      this.isSaving ||
      this.selectedTab >= this.MAX_TAB_INDEX ||
      !this.getValidateForm()
    )
      return;

    await this.shouldUpdate();
    setTimeout(() => {
      this.mainContent.scrollTop = 0;
    }, 200);
    this.selectedTab = this.selectedTab + 1;
    this.openInfo(this.selectedTab);
  }

  goBack() {
    if (this.isSaving || this.selectedTab <= 0) return;
    setTimeout(() => {
      this.mainContent.scrollTop = 0;
    }, 200);
    this.selectedTab = this.selectedTab - 1;
  }

  goTo(index: number) {
    if (this.isSaving || index > this.MAX_TAB_INDEX) return;
    setTimeout(() => {
      this.mainContent.scrollTop = 0;
    }, 200);
    this.selectedTab = index;
    this.openInfo(this.selectedTab);
  }

  openInfo(index: number) {
    const infoContent = this.getInfoContent(index);
    const infoAudio = this.getInfoAudio(index);
    if (!infoContent) return;
    const moreInfoLink = this.getActivityLink(index);
    this.dialog.open(WorkflowInfoDialogComponent, {
      width: '100vw',
      height: 'fit-content',
      maxWidth: '600px',
      maxHeight: '100vh',
      panelClass: 'workflow-info-dialog',
      data: {
        content: infoContent,
        audio: infoAudio,
        moreInfoLink: moreInfoLink,
      },
    });
  }

  getInfoContent(index: number) {
    switch (index) {
      case 1:
        return this.translate.instant('hubWorkflow.details_step.info');
      case 2:
        return this.translate.instant('hubWorkflow.images_step.info');
      case 3:
        return this.translate.instant('hubWorkflow.address_step.info');
      case 4:
        return this.translate.instant('hubWorkflow.description_step.info');
      case 5:
        return this.translate.instant('hubWorkflow.location_step.info');
      case 6:
        return this.translate.instant('hubWorkflow.socialnetworks_step.info');
      case 7:
        return this.translate.instant('hubWorkflow.schedule_step.info');
      case 8:
        return this.translate.instant('hubWorkflow.paymentmethods_step.info');
      case 9:
        return this.translate.instant('hubWorkflow.businesstypes_step.info');
      case 10:
        return this.translate.instant('hubWorkflow.templates_step.info');
      case 11:
        if (!this.activitiesData || !this.activitiesData[this.currentActivity])
          return null;
        return this.translate.instant(
          `hubWorkflow.activities_step.${
            this.activitiesData[this.currentActivity].contentType
          }`
        );
      default:
        return '';
    }
  }

  getInfoAudio(index: number) {
    const lang = this.translate.getDefaultLang();
    switch (index) {
      case 1:
        return `/assets/audio/workflow/${lang}/step-1.mp3`;
      case 2:
        return `/assets/audio/workflow/${lang}/step-2.mp3`;
      case 3:
        return `/assets/audio/workflow/${lang}/step-3.mp3`;
      case 4:
        return `/assets/audio/workflow/${lang}/step-4.mp3`;
      case 5:
        return `/assets/audio/workflow/${lang}/step-5.mp3`;
      case 6:
        return `/assets/audio/workflow/${lang}/step-6.mp3`;
      case 7:
        return `/assets/audio/workflow/${lang}/step-7.mp3`;
      case 8:
        return `/assets/audio/workflow/${lang}/step-8.mp3`;
      case 9:
        return `/assets/audio/workflow/${lang}/step-9.mp3`;
      case 10:
        return `/assets/audio/workflow/${lang}/step-10.mp3`;
      case 11:
        return null;
      default:
        return '';
    }
  }

  getActivityLink(index: number) {
    if (
      index != 11 ||
      !this.activitiesData ||
      !this.activitiesData[this.currentActivity]
    )
      return null;
    switch (this.activitiesData[this.currentActivity].contentType) {
      case ContentType.ACTIVITY_INFO:
        return 'https://tinyall.app/su/a/5c39784e-df33-488e-b86d-73b0c4ecdb05';
      case ContentType.ACTIVITY_REQUEST:
        return 'https://tinyall.app/su/a/edf18214-ef76-4d50-af03-6cd1140053a6';
      case ContentType.ACTIVITY_RESERVATION:
        return 'https://tinyall.app/su/a/7643d995-05c7-4958-b19a-adb0d59779e7';
      case ContentType.ACTIVITY_MENU:
        return null;
      case ContentType.ACTIVITY_PRODUCT_LISTING:
        return 'https://tinyall.app/su/a/ec7e0380-e298-4984-9c81-a0db21b1f915';
      case ContentType.ACTIVITY_EVENT:
        return 'https://tinyall.app/su/a/089a1c50-1388-45c5-a4fe-880ea0b95eb6';
      case ContentType.CATEGORY:
        return 'https://tinyall.app/su/a/38f4b952-cd19-4a82-b08c-9e42b99e4fa3';
      default:
        return null;
    }
  }

  getValidateForm() {
    switch (this.selectedTab) {
      case 1: {
        this.detailsForm.markAllAsTouched();
        return this.detailsForm.valid;
      }
      case 2: {
        this.imagesForm.markAllAsTouched();
        return this.imagesForm.valid;
      }
      case 3: {
        this.hubAddressForm.markAllAsTouched();
        return this.hubAddressForm.valid;
      }
      case 4: {
        this.descriptionForm.markAllAsTouched();
        return this.descriptionForm.valid;
      }
      case 5: {
        this.locationForm.markAllAsTouched();
        return this.locationForm.valid;
      }
      case 6: {
        this.socialNetworksForm.markAllAsTouched();
        return this.socialNetworksForm.valid;
      }
      case 7: {
        this.scheduleForm.markAllAsTouched();
        return this.scheduleForm.valid;
      }
      case 8: {
        //this.paymentMethodsForm.markAllAsTouched();
        return true;
        //return this.paymentMethodsForm.valid;
      }
      case 9: {
        this.triedToSubmitBusinessType = true;
        return this.seledtedTemplateCategory != null;
      }
      case 10: {
        this.triedToSubmitTemplate = true;
        return true;
      }
      default:
        return true;
    }
  }

  async shouldUpdate() {
    switch (this.selectedTab) {
      case 1: {
        if (!this.detailsForm.dirty) return;
        await this.onSubmit(HubCreationStatus.INITIAL_DETAILS, false);
        this.detailsForm.markAsPristine();
        return;
      }
      case 2: {
        if (!this.imagesForm.dirty) return;
        await this.onSubmit(HubCreationStatus.IMAGES, false);
        this.imagesForm.markAsPristine();
        return;
      }
      case 3: {
        if (!this.hubAddressForm.dirty) return;
        await this.onSubmit(HubCreationStatus.ADDRESS, false);
        this.hubAddressForm.markAsPristine();
        return;
      }
      case 4: {
        if (!this.descriptionForm.dirty) return;
        await this.onSubmit(HubCreationStatus.DESCRIPTION, false);
        this.descriptionForm.markAsPristine();
        return;
      }
      case 5: {
        if (!this.locationForm.dirty) return;
        await this.onSubmit(HubCreationStatus.LOCATION, false);
        this.locationForm.markAsPristine();
        return;
      }
      case 6: {
        if (!this.socialNetworksForm.dirty) return;
        await this.onSubmit(HubCreationStatus.SOCIAL, false);
        this.socialNetworksForm.markAsPristine();
        return;
      }
      case 7: {
        if (!this.scheduleForm.dirty) return;
        await this.onSubmit(HubCreationStatus.AVAILABILITY, false);
        this.scheduleForm.markAsPristine();
        return;
      }
      default:
        return true;
    }
  }

  selectedIndexChanged(index: number) {
    if (index === 9 || index === 10) {
      this.retriveHubTemplates();
    }
    if (index === 11 && !this.activitiesData) {
      this.getAllActivities();
    }
    if (index <= 9) {
      this.triedToSubmitBusinessType = false;
      this.seledtedTemplateCategory = null;
    }
    if (index <= 10) {
      this.triedToSubmitTemplate = false;
    }
  }

  async retriveHubTemplates() {
    this.settings.loadingSpinner = true;
    const querySearch: string[] = [];
    querySearch.push('querySearch=(');
    for (const tag of templates) {
      if (querySearch.length > 1) {
        querySearch.push(' OR ');
      }
      querySearch.push(`tags%23'${tag}'`);
    }
    querySearch.push(' AND active:true&page=0&size=500');
    const URL = `${this.apiUrl}/hubs/v1/search?${querySearch.join('')}`;
    try {
      const response: PageEntity<HubEntity> = await firstValueFrom(
        this.httpService.doGet(URL)
      );
      if (response && response.content && response.content.length > 0) {
        this.templateData = [];
        templates.forEach((current) => {
          const hubs = response.content.filter((hub) =>
            hub.tags.includes(current)
          );
          const template = templateBusinessCategories[current];
          if (hubs && hubs.length > 0 && template) {
            this.templateData.push({
              ...template,
              hubs,
            });
          }
        });
      }
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  goToTemplate(category) {
    this.seledtedTemplateCategory = category;
    this.goNext();
  }

  getHubUrl(handler: string) {
    return ShortUrlHandlerComponent.shortHubUrl(handler);
  }

  async cloneHub(hub: HubEntity) {
    if (this.cloningActivity) return;
    this.clonedActivity = 0;
    this.cloningActivity = hub.id;
    const activities = await HubClonerComponent.getActCatEntity(
      hub.id,
      this.httpService
    );
    this.totalActivities = activities.length;
    for (const catAct of activities) {
      const activity = catAct.content;

      const url = `${this.apiUrl}/${
        EntityTypeToUrlMap[activity.entityType]
      }/v1`;
      const body = {
        ...HubClonerComponent.getActivityBody(activity),
        hubId: this.hubId,
        userId: this.user.userId,
        active: false,
        includeInMenu: false,
        order: 0,
      };

      try {
        const response = await firstValueFrom(
          this.httpService.doPost(url, body)
        );
        await HubClonerComponent.cloneImages(response, this.imageService);
        if (activity.entityType === EntityType.CATEGORY) {
          const innerActivities = await HubClonerComponent.getInnerActCatEntity(
            activity.hubId,
            activity.id,
            this.httpService
          );
          for (const innerCatAct of innerActivities) {
            const innerActivity = innerCatAct.content;
            const innerUrl = `${this.apiUrl}/${
              EntityTypeToUrlMap[innerActivity.entityType]
            }/v1`;
            const innerBody = {
              ...HubClonerComponent.getActivityBody(innerActivity),
              hubId: this.hubId,
              userId: this.user.userId,
              categoryId: response.id,
              active: false,
              includeInMenu: false,
              order: 0,
            };
            try {
              const innerResponse = await firstValueFrom(
                this.httpService.doPost(innerUrl, innerBody)
              );
              await HubClonerComponent.cloneImages(
                innerResponse,
                this.imageService
              );
            } catch (error) {}
          }
        }
      } catch (error) {
      } finally {
        this.clonedActivity += 1;
      }
    }
    Swal.fire({
      titleText: this.translate.instant('titles.congratulations'),
      text: this.translate.instant('messages.cloning_complete'),
      icon: 'success',
      toast: true,
      position: 'top',
      iconColor: 'white',
      customClass: {
        popup: 'colored-toast',
      },
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
    //await this.onSubmit(HubCreationStatus.TEMPLATE, true);
    await this.applyPromoCode();
    this.cloningActivity = null;
    this.goTo(11);
  }

  async getAllActivities() {
    this.settings.loadingSpinner = true;
    const URL = `${environment.apiUrl}/actcatview/v1/owner-search?page=0&size=500&querySearch=(hubId:'${this.hub.id}')&sort=updatedOn,desc`;
    try {
      const response = await firstValueFrom(this.httpService.doGet(URL));
      if (response && response.content && response.content.length) {
        this.activitiesData = response.content;
        if (this.activitiesData.length > 0) {
          const localCurrentActivity = this.activitiesData.findIndex(
            (activity) => activity.createdOn == activity.updatedOn
          );
          if (localCurrentActivity == -1) {
            await this.onSubmit(HubCreationStatus.TEMPLATE, true);
            this.goTo(12);
          } else {
            this.currentActivity = localCurrentActivity;
          }
        }
      }
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  getPercentage(clonedActivity) {
    if (this.clonedActivity === 0 || this.totalActivities === 0) return 0;
    return `${(clonedActivity / this.totalActivities) * 100}%`;
  }

  async done() {
    await this.onSubmit(HubCreationStatus.TEMPLATE);
    this.router.navigateByUrl(`${RouteConstants.hub}/owner`);
  }

  tryToClone(hub: HubEntity): void {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      minWidth: '240px',
      data: {
        title: 'messages.clone_hub_question',
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.cloneHub(hub);
      }
    });
  }

  onPaymentMethodUpdate(hub: HubEntity) {
    this.hub = hub;
  }

  isActivity(type: string): boolean {
    return type.startsWith('ACTIVITY');
  }

  isCategory(type: string): boolean {
    return type.startsWith('CATEGORY');
  }

  async nextActivity() {
    if (this.currentActivity + 1 >= this.activitiesData.length) {
      await this.onSubmit(HubCreationStatus.TEMPLATE, true);
      this.goTo(12);
    } else {
      if (this.activitiesData[this.currentActivity].contentType === this.activitiesData[this.currentActivity + 1].contentType) {
        this.reseting = true;
        this.settings.loadingSpinner = true;
        await delay(300);
      }
      this.currentActivity += 1;
      this.openInfo(this.selectedTab);
      this.settings.loadingSpinner = false;
    }
    this.reseting = false;
    this.mainContent.scrollTop = 0;
  }


  async applyPromoCode() {
    if (!this.applySpecialPromoCode) return;
    
    const url = `${this.apiUrl}/admin-center/promo-code/v1/apply/${environment.specialPromoCode}`;

    try {
      await firstValueFrom(this.httpService.doPost(url, {}));
      await this.updateUserAccount();
      this.dialog.open(SpecialPromoCodeDialogComponent, {
        width: '100vw',
        height: 'fit-content',
        maxWidth: '600px',
        maxHeight: '100vh',
        panelClass: 'special-promo-code-dialog',
      });
      return;
    } catch (error) {
      this.showSnackBar("Error Processing Your Request", "OK", 2000);
    }
  }

  async updateUserAccount() {
    const url = `${this.apiUrl}/users/v1`;
    try {
      const user: UserEntity = await firstValueFrom(
        this.httpService.doGet(url)
      );
      if (user) {
        this.user = user;
        localStorage.setItem("user_profile", JSON.stringify(user));
      }
    } catch (error) {
      this.showSnackBar("Error Processing Your Request", "OK", 2000);
      console.error("Error creating activity: ", error);
    }
  }
  
}
