<div class="page-container">
    <img class="not-found-image" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcR3_6ByqpXgluX0mIUlmXLnjbhBkKZ0I-Rmxw&usqp=CAU" alt="not found" />
    <div fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="90">
            <mat-card class="p-0 mat-elevation-z24 box">
                <div fxLayout="column" fxLayoutAlign="start center" class="bg-primary header-box">
                    <h1 class="error">404</h1>
                </div>
                <mat-card-content fxLayout="column" fxLayoutAlign="end center" class="box-content">
                    <mat-card fxLayout="column" fxLayoutAlign="center center" class="mat-elevation-z12 box-content-inner">
                        <p class="box-text" translate>notFount.text_1</p>
                    </mat-card>
                    <button mat-raised-button color="accent" class="mat-elevation-z12 box-button" type="button" (click)="goBack()"><span translate>buttons.back</span></button>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>