import { Component, Input } from "@angular/core";
import {
  faFacebookSquare,
  faTwitterSquare,
  faLinkedin,
  faInstagramSquare,
  faSnapchatSquare,
  faPinterestSquare,
  faRedditSquare,
  faWhatsappSquare,
  faTumblrSquare,
  faTelegram,
  faYoutubeSquare,
  faMedium,
} from "@fortawesome/free-brands-svg-icons";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

@Component({
  selector: "app-social-media-links-client-view",
  templateUrl: "./social-media-links-client-view.component.html",
  styleUrls: ["./social-media-links-client-view.component.scss"],
})
export class SocialMediaLinksClientViewComponent {
  public perfectScrollBarConfig: PerfectScrollbarConfigInterface = {};
  @Input() socialLinks: any;

  readonly faFacebookSquare = faFacebookSquare;
  readonly faTwitterSquare = faTwitterSquare;
  readonly faLinkedin = faLinkedin;
  readonly faInstagramSquare = faInstagramSquare;
  readonly faSnapchatSquare = faSnapchatSquare;
  readonly faPinterestSquare = faPinterestSquare;
  readonly faRedditSquare = faRedditSquare;
  readonly faWhatsappSquare = faWhatsappSquare;
  readonly faTumblrSquare = faTumblrSquare;
  readonly faTelegram = faTelegram;
  readonly faYoutubeSquare = faYoutubeSquare;
  readonly faMedium = faMedium;

  constructor() {}

  showSocialLinks(links) {
    if (!links || Object.keys(links).length === 0) {
      return false;
    }
    return true;
  }
}
