import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { SwiperViewComponent } from '../swiper-view/swiper-view.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SocialShareBottomSheetComponent } from '../social-share-bottom-sheet/social-share-bottom-sheet.component';
import ContentToActivity from '../../util/content-to-activity';
import ActivityIconMap from '../../util/activity-icon-map';
import { ShortUrlHandlerComponent } from '../short-url-handler/short-url-handler.component';
import { ActCatViewEntity } from '../../models/activity-category-view';

const SWIPER_STOP_PROPAGATION = [
  'swiper-button-next',
  'swiper-button-prev',
  'swiper-pagination-handle',
  'swiper-button-next swiper-button-disabled',
  'swiper-button-prev swiper-button-disabled',
];

@Component({
  selector: 'app-hub-activity',
  templateUrl: './hub-activity.component.html',
  styleUrls: ['./hub-activity.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HubActivityComponent implements OnInit {
  @Input() activity: ActCatViewEntity;
  @Input() handler;
  @Output() click: EventEmitter<any> = new EventEmitter();
  @ViewChild('swiper') swiper: SwiperViewComponent;
  @Input() isOwnerView: boolean = false;

  images = [];
  url;
  activityIcon;

  constructor(private _bottomSheet: MatBottomSheet) {}

  ngOnInit(): void {
    this.activityIcon = ActivityIconMap[this.activity.contentType];
    this.url = ShortUrlHandlerComponent.shortActCatUrl(this.activity.id);
    if (this.activity.content.images) {
      this.images = this.activity.content.images;
    } else {
      this.images = [];
    }
  }

  public goToActivity(
    activityId: string,
    contentType: string,
    categoryId: string
  ): void {
    const type = ContentToActivity[contentType];
    this.click.emit({
      id: activityId,
      type: type,
      categoryId: categoryId,
    });
  }

  public validateGoToActivity(event: any): void {
    if (SWIPER_STOP_PROPAGATION.includes(event.target.className)) {
      event.stopPropagation();
    }
  }

  stopVideo() {
    if (!this.swiper) {
      return;
    }
    this.swiper.youtubeVideoControl(null);
  }

  openShare(event: MouseEvent): void {
    event.stopPropagation();
    this._bottomSheet
      .open(SocialShareBottomSheetComponent, {
        panelClass: 'share-bottom-sheet',
        data: {
          url: this.url,
          title: this.activity.content.name,
          description: this.activity.content.infoText,
          images: this.activity.content.images,
          tags: null,
        },
      })
      .afterDismissed()
      .subscribe((result) => {});
  }
}
