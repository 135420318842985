
<div fxLayout="row" *ngIf="showTitle">
    <h2 class="title" translate>activityReservation.pick_up_days_off<app-info-tooltip class="absolute-tooltip" info="tooltip.days_off" infoClass="secondary"></app-info-tooltip></h2>
</div>
<mat-card class="custom-activity-card" [ngClass]="{'no-card': noCard}">
    <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="text" translate [translateParams]="{value: _daysSelected.size}">activityReservation.has_days_off</div>
            <div>
                <button class="calendar-button " 
                mat-icon-button [matMenuTriggerFor]="appMenu"
                (menuOpened)="panelOpened()" 
                  (menuClosed)="panelClosed()">
                    <mat-icon>calendar_today</mat-icon>
                  </button>
                  <mat-menu #appMenu="matMenu" hasBackdrop="true" backdropClass="days-off-picker-backdrop" class="drop-calendar">
                      <div (click)="$event.stopPropagation()">
                          <mat-calendar #calendar (selectedChange)="select($event,calendar)" [dateClass]="isSelected"></mat-calendar>
                      </div>
                  </mat-menu>
            </div>
        </div>
    </mat-card-content>
</mat-card>