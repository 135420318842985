import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { ServiceModule } from '../../service/service.module';
import { ActivityInfoManagementComponent } from './activity-info-management/activity-info-management.component';
import { ActivityInfoClientViewComponent } from './activity-info-client-view/activity-info-client-view.component';
import { AuthGuard } from '../../auth/auth.guard';
import { ComponentsModule } from '../../components/componets.module';
import { FormGuard } from '../../guards/form.guard';

export const routes = [
  { path: 'client-view', component: ActivityInfoClientViewComponent },
  {
    path: 'manage',
    component: ActivityInfoManagementComponent,
    canActivate: [AuthGuard],
    canDeactivate: [FormGuard],
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ComponentsModule,
    ServiceModule,
    RouterModule.forChild(routes),
  ],
  providers: [],
  declarations: [
    ActivityInfoManagementComponent,
    ActivityInfoClientViewComponent,
  ],
  exports: [
    ActivityInfoManagementComponent,
  ]
})
export class ActivityInfoModule {}
