<ng-container *ngIf="!notFound; else pageNotFound">
    <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
        <button (click)="back()" mat-button><span translate>tinyAll.back</span></button>
        <app-report-abuse-button *ngIf="!reported" [user]="user" [entityId]="categoryId" [entityType]="type" [entityName]="entity?.name" [entity]="entity" (reported)="reported = true"></app-report-abuse-button>
    </div>

    <div class="page-container" fxLayout="row wrap" fxLayoutAlign="center start">
        <div class="flex-p" fxFlex="100">
            <app-navigation-menu *ngIf="hub" [hubId]="hub?.id" [handler]="hub?.urlHandler">
            </app-navigation-menu>
        </div>
        <div class="px-4 py-2.5 bg-[#fafafa] sticky xs:static -top-[10px] z-10 xs:z-0 -ml-[8px] w-[calc(100%+10px)]">
            <h1 class="text-gray-500 font-semibold text-xl">{{hub?.name}}</h1>
        </div>
        <div class="flex-p" fxFlex="100">
            <mat-card class="card-container">
                <mat-card-header>
                    <mat-card-title class="category-title">{{entity?.name}}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <app-swiper-view *ngIf="entity?.images && entity.images.length > 0" 
                        [images]="entity.images"
                        [canPlay]="true"
                        [canLink]="true">
                    </app-swiper-view>
                    <app-warning-message *ngIf="entity?.restricted && !locked"
                        message="messages.restricted_category_warning">
                    </app-warning-message>
                    <div class="waiting" *ngIf="entity?.restricted && locked && requestList && requestList.data && requestList.data.status == 'PENDING'">
                        <mat-icon>query_builder</mat-icon>
                        <span translate>messages.restricted_category_waiting</span>
                    </div>
                    <div class="waiting" *ngIf="entity?.restricted && locked && requestList && requestList.data && requestList.data.status == 'PENDING_PAYMENT'">
                        <mat-icon>query_builder</mat-icon>
                        <span translate>messages.restricted_category_pending_payment</span>
                    </div>
                    <div class="rejected" *ngIf="entity?.restricted && locked && requestList && requestList.data && requestList.data.status == 'REJECTED'">
                        <mat-icon>error_outline</mat-icon>
                        <span translate>messages.restricted_category_rejected</span>
                    </div>
                </mat-card-content>
                <app-social-card-actions 
                    *ngIf="entity && commentsPreview" 
                    [title]="entity.name"
                    [description]="entity.infoText"
                    [images]="entity.images"
                    [preview]="commentsPreview"
                    [views]="entity.views"
                    [requests]="entity.requestCount"
                    [id]="categoryId"
                    [createdOn]="entity.createdOn"
                >
                </app-social-card-actions>
            </mat-card>
        </div>

        <div class="flex-p" fxFlex="100">
            <mat-card class="preview-card custom-card">
                <app-comments-preview #commentsPreview *ngIf="entity" entityType="CATEGORY" [entityName]="entity.name" [entityId]="entity.id"></app-comments-preview>
            </mat-card>
        </div>

        <app-collapsible-description [text]="entity?.infoText" [collapsible]="true"></app-collapsible-description>

        <app-address-selector *ngIf="!locked && form && entity?.addressRequired" [form]="form"></app-address-selector>

        <app-question-list-client-view *ngIf="!locked && entity && entity.restricted && form && (getQuestionsLength() > 0 || entity.gpsRequired || entity.phoneNumberRequired)" class="question-list" [form]="form" [originalQuestions]="questionsV1">
        </app-question-list-client-view>

        <app-questions-summary *ngIf="showPriceSummary && form && hub && entity"
            [entityId]="entity.id"
            [hubId]="hub?.id"
            [questionAnswerMap]="questionAnswerMap"
            [totalPrice]="totalPrice"
            [originalQuestions]="questionsV1"
            [additionalCharges]="entity.additionalCharges"
            [promoCodeForm]="form"
        >
        </app-questions-summary>

        <app-select-payment-method
            *ngIf="showPaymentMethods" 
            [form]="form"
            [hub]="hub"
            [totalPrice]="totalPrice"
            (sendRequest)="sendRequest()"
        >
        </app-select-payment-method>
        <app-azul-payment-form #azulPaymentEl></app-azul-payment-form>
        <div *ngIf="!locked && entity && entity.restricted" fxLayout="row" fxFlex="100" fxLayoutAlign="end center" >
            <button *ngIf="showSendRequest" [disabled]="disableSendRequest" class="speed-dial-action-button-fix" (click)="sendRequest()" mat-fab color="accent">
                <span translate>activityRequestClientView.send_request</span>&nbsp;<mat-icon>send</mat-icon>
            </button>
        </div>

        <app-access-list-client-view *ngIf="entity && user" 
            #requestListTag 
            class="request-list"
            id="request-list"
            [categoryId]="categoryId"
            [hubId]="hubId"
            [user]="user"
            (cancel)="onCancel()"
            (hasOpen)="onHasOpen($event)">
        </app-access-list-client-view>

        <ng-container *ngIf="entity && (!entity.restricted || (requestList && requestList.data && requestList.data.status == 'GRANTED'))">
            <div *ngFor="let item of items" io-view (inView)="item['show'] = true" fxFlex="100" class="flex-p">
                <app-hub-activity id="item.id" 
                    *ngIf="item.show && isActivity(item.contentType)" 
                    [activity]="item" 
                    [handler]="handler"
                    (click)="goToActivity($event)">
                </app-hub-activity>
            </div>
        </ng-container>

        <app-company-info-card
            *ngIf="showSendRequest && hub"
            [name]="hub.name"
            [address]="hub.address"
            [phone]="hub.phoneNumber"
            [email]="hub.email"
            [handler]="hub.urlHandler"
            [image]="hub.icon"
        >
        </app-company-info-card>

    </div>
</ng-container>
<ng-template #pageNotFound>
    <app-page-not-found (back)="back()"></app-page-not-found>
</ng-template>
<app-loading></app-loading>
