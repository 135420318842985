import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-workflow-info-dialog',
  templateUrl: './workflow-info-dialog.component.html',
  styleUrls: ['./workflow-info-dialog.component.scss']
})
export class WorkflowInfoDialogComponent { 

    parentData: any; 
    constructor(
      public dialogRef: MatDialogRef<WorkflowInfoDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.parentData = data; 
    }

    ok(): void {
      this.dialogRef.close();
    }
  
  }