<div class="flex-col" *ngIf="questions.length">
  <h2 class="text-4xl font-extrabold text-primary mb-5 leading-tight max-w-3xl mx-auto" translate>titles.frequently_asked_questions</h2>
  <mat-expansion-panel
    *ngFor="let question of questions; let i = index;"
    class="!shadow-none"
    [expanded]="show === i"
    (opened)="setOpen(i)"
  >
    <mat-expansion-panel-header class="!px-0 !py-5 !h-fit !border-t !border-t-accent !rounded-none">
      <h2 class="font-semibold leading-none text-xl text-primary text-left">{{question.question | translate}}</h2>
    </mat-expansion-panel-header>

    <p class="text-left py-4 text-base leading-5 !border-t !border-t-accent text-primary">{{question.answer | translate}}</p>
  </mat-expansion-panel>
</div>
