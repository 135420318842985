<mat-expansion-panel *ngIf="!noCard; else noCardContent">
  <mat-expansion-panel-header>
    <mat-panel-title translate
      >hubManagement.social_media_links</mat-panel-title
    >
  </mat-expansion-panel-header>

  <div
    [formGroup]="form"
    fxLayout="column"
    fxLayoutAlign="center"
    fxLayoutGap="5px"
  >
    <mat-form-field floatLabel="always" class="facebook-label">
      <input
        matInput
        placeholder="Facebook"
        formControlName="facebook"
        autocomplete="off"
        (keyup)="removePrefix('facebook')"
        (change)="removePrefix('facebook')"
        (focusout)="removePrefix('facebook')"
      />
      <fa-icon
        matPrefix
        class="front-icon facebook"
        [icon]="faFacebookSquare"
      ></fa-icon>
      <span matPrefix>{{ socialMediaPrefix['facebook'] }}</span>
      <mat-error *ngIf="form.get('facebook').hasError('pattern')" translate
        >formErrors.url_invalid</mat-error
      >
    </mat-form-field>

    <mat-form-field floatLabel="always" class="twitter-label">
      <input
        matInput
        placeholder="Twitter"
        formControlName="twitter"
        autocomplete="off"
        (keyup)="removePrefix('twitter')"
        (change)="removePrefix('twitter')"
        (focusout)="removePrefix('twitter')"
      />
      <fa-icon
        matPrefix
        class="front-icon twitter"
        [icon]="faTwitterSquare"
      ></fa-icon>
      <span matPrefix>{{ socialMediaPrefix['twitter'] }}</span>
      <mat-error *ngIf="form.get('twitter').hasError('pattern')" translate
        >formErrors.url_invalid</mat-error
      >
    </mat-form-field>

    <mat-form-field floatLabel="always" class="linkedin-label">
      <input
        matInput
        placeholder="Linkedin"
        formControlName="linkedin"
        autocomplete="off"
        (keyup)="removePrefix('linkedin')"
        (change)="removePrefix('linkedin')"
        (focusout)="removePrefix('linkedin')"
      />
      <fa-icon
        matPrefix
        class="front-icon linkedin"
        [icon]="faLinkedin"
      ></fa-icon>
      <span matPrefix>{{ socialMediaPrefix['linkedin'] }}</span>
      <mat-error *ngIf="form.get('linkedin').hasError('pattern')" translate
        >formErrors.url_invalid</mat-error
      >
    </mat-form-field>

    <mat-form-field floatLabel="always" class="instagram-label">
      <input
        matInput
        placeholder="Instagram"
        formControlName="instagram"
        autocomplete="off"
        (keyup)="removePrefix('instagram')"
        (change)="removePrefix('instagram')"
        (focusout)="removePrefix('instagram')"
      />
      <fa-icon
        matPrefix
        class="front-icon instagram"
        [icon]="faInstagramSquare"
      ></fa-icon>
      <span matPrefix>{{ socialMediaPrefix['instagram'] }}</span>
      <mat-error *ngIf="form.get('instagram').hasError('pattern')" translate
        >formErrors.url_invalid</mat-error
      >
    </mat-form-field>

    <mat-form-field floatLabel="always" class="snapchat-label">
      <input
        matInput
        placeholder="Snapchat"
        formControlName="snapchat"
        autocomplete="off"
        (keyup)="removePrefix('snapchat')"
        (change)="removePrefix('snapchat')"
        (focusout)="removePrefix('snapchat')"
      />
      <fa-icon
        matPrefix
        class="front-icon snapchat"
        [icon]="faSnapchatSquare"
      ></fa-icon>
      <span matPrefix>{{ socialMediaPrefix['snapchat'] }}</span>
      <mat-error *ngIf="form.get('snapchat').hasError('pattern')" translate
        >formErrors.url_invalid</mat-error
      >
    </mat-form-field>

    <mat-form-field floatLabel="always" class="pinterest-label">
      <input
        matInput
        placeholder="Pinterest"
        formControlName="pinterest"
        autocomplete="off"
        (keyup)="removePrefix('pinterest')"
        (change)="removePrefix('pinterest')"
        (focusout)="removePrefix('pinterest')"
      />
      <fa-icon
        matPrefix
        class="front-icon pinterest"
        [icon]="faPinterestSquare"
      ></fa-icon>
      <span matPrefix>{{ socialMediaPrefix['pinterest'] }}</span>
      <mat-error *ngIf="form.get('pinterest').hasError('pattern')" translate
        >formErrors.url_invalid</mat-error
      >
    </mat-form-field>

    <mat-form-field floatLabel="always" class="reddit-label">
      <input
        matInput
        placeholder="Reddit"
        formControlName="reddit"
        autocomplete="off"
        (keyup)="removePrefix('reddit')"
        (change)="removePrefix('reddit')"
        (focusout)="removePrefix('reddit')"
      />
      <fa-icon
        matPrefix
        class="front-icon reddit"
        [icon]="faRedditSquare"
      ></fa-icon>
      <span matPrefix>{{ socialMediaPrefix['reddit'] }}</span>
      <mat-error *ngIf="form.get('reddit').hasError('pattern')" translate
        >formErrors.url_invalid</mat-error
      >
    </mat-form-field>

    <mat-form-field floatLabel="always" class="whatsapp-label">
      <input
        matInput
        placeholder="WhatsApp"
        formControlName="whatsapp"
        autocomplete="off"
        (keyup)="removePrefix('whatsapp')"
        (change)="removePrefix('whatsapp')"
        (focusout)="removePrefix('whatsapp')"
      />
      <fa-icon
        matPrefix
        class="front-icon whatsapp"
        [icon]="faWhatsappSquare"
      ></fa-icon>
      <span matPrefix>{{ socialMediaPrefix['whatsapp'] }}</span>
      <mat-error *ngIf="form.get('whatsapp').hasError('pattern')" translate
        >formErrors.url_invalid</mat-error
      >
    </mat-form-field>

    <!--<mat-form-field floatLabel="always" class="tumblr-label">
            <input matInput placeholder="Tumblr" formControlName="tumblr" autocomplete="off" (keyup)="removeSuffix('tumblr')" (change)="removeSuffix('tumblr')" (focusout)="removeSuffix('tumblr')">
            <fa-icon matPrefix class="front-icon tumblr" [icon]="faTumblrSquare"></fa-icon>
            <span matPrefix>https://</span>
            <span matSuffix>{{socialMediaPrefix["tumblr"]}}</span>
            <mat-error *ngIf="form.get('tumblr').hasError('pattern')" translate>formErrors.url_invalid</mat-error>
        </mat-form-field>-->

    <mat-form-field floatLabel="always" class="telegram-label">
      <input
        matInput
        placeholder="Telegram"
        formControlName="telegram"
        autocomplete="off"
        (keyup)="removePrefix('telegram')"
        (change)="removePrefix('telegram')"
        (focusout)="removePrefix('telegram')"
      />
      <fa-icon
        matPrefix
        class="front-icon telegram"
        [icon]="faTelegram"
      ></fa-icon>
      <span matPrefix>{{ socialMediaPrefix['telegram'] }}</span>
      <mat-error *ngIf="form.get('telegram').hasError('pattern')" translate
        >formErrors.url_invalid</mat-error
      >
    </mat-form-field>

    <mat-form-field floatLabel="always" class="youtube-label">
      <input
        matInput
        placeholder="Youtube"
        formControlName="youtube"
        autocomplete="off"
        (keyup)="removePrefix('youtube')"
        (change)="removePrefix('youtube')"
        (focusout)="removePrefix('youtube')"
      />
      <fa-icon
        matPrefix
        class="front-icon youtube"
        [icon]="faYoutubeSquare"
      ></fa-icon>
      <span matPrefix>{{ socialMediaPrefix['youtube'] }}</span>
      <mat-error *ngIf="form.get('youtube').hasError('pattern')" translate
        >formErrors.url_invalid</mat-error
      >
    </mat-form-field>

    <mat-form-field floatLabel="always" class="medium-label">
      <input
        matInput
        placeholder="Medium"
        formControlName="medium"
        autocomplete="off"
        (keyup)="removePrefix('facebook')"
        (change)="removePrefix('facebook')"
        (focusout)="removePrefix('facebook')"
      />
      <fa-icon matPrefix class="front-icon medium" [icon]="faMedium"></fa-icon>
      <span matPrefix>{{ socialMediaPrefix['medium'] }}</span>
      <mat-error *ngIf="form.get('medium').hasError('pattern')" translate
        >formErrors.url_invalid</mat-error
      >
    </mat-form-field>
  </div>
</mat-expansion-panel>

<ng-template #noCardContent>
  <div
    [formGroup]="form"
    fxLayout="column"
    fxLayoutAlign="center"
    fxLayoutGap="5px"
  >
    <mat-form-field floatLabel="always" class="facebook-label">
      <input
        matInput
        placeholder="Facebook"
        formControlName="facebook"
        autocomplete="off"
        (keyup)="removePrefix('facebook')"
        (change)="removePrefix('facebook')"
        (focusout)="removePrefix('facebook')"
      />
      <fa-icon
        matPrefix
        class="front-icon facebook"
        [icon]="faFacebookSquare"
      ></fa-icon>
      <span matPrefix>{{ socialMediaPrefix['facebook'] }}</span>
      <mat-error *ngIf="form.get('facebook').hasError('pattern')" translate
        >formErrors.url_invalid</mat-error
      >
    </mat-form-field>

    <mat-form-field floatLabel="always" class="twitter-label">
      <input
        matInput
        placeholder="Twitter"
        formControlName="twitter"
        autocomplete="off"
        (keyup)="removePrefix('twitter')"
        (change)="removePrefix('twitter')"
        (focusout)="removePrefix('twitter')"
      />
      <fa-icon
        matPrefix
        class="front-icon twitter"
        [icon]="faTwitterSquare"
      ></fa-icon>
      <span matPrefix>{{ socialMediaPrefix['twitter'] }}</span>
      <mat-error *ngIf="form.get('twitter').hasError('pattern')" translate
        >formErrors.url_invalid</mat-error
      >
    </mat-form-field>

    <mat-form-field floatLabel="always" class="linkedin-label">
      <input
        matInput
        placeholder="Linkedin"
        formControlName="linkedin"
        autocomplete="off"
        (keyup)="removePrefix('linkedin')"
        (change)="removePrefix('linkedin')"
        (focusout)="removePrefix('linkedin')"
      />
      <fa-icon
        matPrefix
        class="front-icon linkedin"
        [icon]="faLinkedin"
      ></fa-icon>
      <span matPrefix>{{ socialMediaPrefix['linkedin'] }}</span>
      <mat-error *ngIf="form.get('linkedin').hasError('pattern')" translate
        >formErrors.url_invalid</mat-error
      >
    </mat-form-field>

    <mat-form-field floatLabel="always" class="instagram-label">
      <input
        matInput
        placeholder="Instagram"
        formControlName="instagram"
        autocomplete="off"
        (keyup)="removePrefix('instagram')"
        (change)="removePrefix('instagram')"
        (focusout)="removePrefix('instagram')"
      />
      <fa-icon
        matPrefix
        class="front-icon instagram"
        [icon]="faInstagramSquare"
      ></fa-icon>
      <span matPrefix>{{ socialMediaPrefix['instagram'] }}</span>
      <mat-error *ngIf="form.get('instagram').hasError('pattern')" translate
        >formErrors.url_invalid</mat-error
      >
    </mat-form-field>

    <mat-form-field floatLabel="always" class="snapchat-label">
      <input
        matInput
        placeholder="Snapchat"
        formControlName="snapchat"
        autocomplete="off"
        (keyup)="removePrefix('snapchat')"
        (change)="removePrefix('snapchat')"
        (focusout)="removePrefix('snapchat')"
      />
      <fa-icon
        matPrefix
        class="front-icon snapchat"
        [icon]="faSnapchatSquare"
      ></fa-icon>
      <span matPrefix>{{ socialMediaPrefix['snapchat'] }}</span>
      <mat-error *ngIf="form.get('snapchat').hasError('pattern')" translate
        >formErrors.url_invalid</mat-error
      >
    </mat-form-field>

    <mat-form-field floatLabel="always" class="pinterest-label">
      <input
        matInput
        placeholder="Pinterest"
        formControlName="pinterest"
        autocomplete="off"
        (keyup)="removePrefix('pinterest')"
        (change)="removePrefix('pinterest')"
        (focusout)="removePrefix('pinterest')"
      />
      <fa-icon
        matPrefix
        class="front-icon pinterest"
        [icon]="faPinterestSquare"
      ></fa-icon>
      <span matPrefix>{{ socialMediaPrefix['pinterest'] }}</span>
      <mat-error *ngIf="form.get('pinterest').hasError('pattern')" translate
        >formErrors.url_invalid</mat-error
      >
    </mat-form-field>

    <mat-form-field floatLabel="always" class="reddit-label">
      <input
        matInput
        placeholder="Reddit"
        formControlName="reddit"
        autocomplete="off"
        (keyup)="removePrefix('reddit')"
        (change)="removePrefix('reddit')"
        (focusout)="removePrefix('reddit')"
      />
      <fa-icon
        matPrefix
        class="front-icon reddit"
        [icon]="faRedditSquare"
      ></fa-icon>
      <span matPrefix>{{ socialMediaPrefix['reddit'] }}</span>
      <mat-error *ngIf="form.get('reddit').hasError('pattern')" translate
        >formErrors.url_invalid</mat-error
      >
    </mat-form-field>

    <mat-form-field floatLabel="always" class="whatsapp-label">
      <input
        matInput
        placeholder="WhatsApp"
        formControlName="whatsapp"
        autocomplete="off"
        (keyup)="removePrefix('whatsapp')"
        (change)="removePrefix('whatsapp')"
        (focusout)="removePrefix('whatsapp')"
      />
      <fa-icon
        matPrefix
        class="front-icon whatsapp"
        [icon]="faWhatsappSquare"
      ></fa-icon>
      <span matPrefix>{{ socialMediaPrefix['whatsapp'] }}</span>
      <mat-error *ngIf="form.get('whatsapp').hasError('pattern')" translate
        >formErrors.url_invalid</mat-error
      >
    </mat-form-field>

    <mat-form-field floatLabel="always" class="telegram-label">
      <input
        matInput
        placeholder="Telegram"
        formControlName="telegram"
        autocomplete="off"
        (keyup)="removePrefix('telegram')"
        (change)="removePrefix('telegram')"
        (focusout)="removePrefix('telegram')"
      />
      <fa-icon
        matPrefix
        class="front-icon telegram"
        [icon]="faTelegram"
      ></fa-icon>
      <span matPrefix>{{ socialMediaPrefix['telegram'] }}</span>
      <mat-error *ngIf="form.get('telegram').hasError('pattern')" translate
        >formErrors.url_invalid</mat-error
      >
    </mat-form-field>

    <mat-form-field floatLabel="always" class="youtube-label">
      <input
        matInput
        placeholder="Youtube"
        formControlName="youtube"
        autocomplete="off"
        (keyup)="removePrefix('youtube')"
        (change)="removePrefix('youtube')"
        (focusout)="removePrefix('youtube')"
      />
      <fa-icon
        matPrefix
        class="front-icon youtube"
        [icon]="faYoutubeSquare"
      ></fa-icon>
      <span matPrefix>{{ socialMediaPrefix['youtube'] }}</span>
      <mat-error *ngIf="form.get('youtube').hasError('pattern')" translate
        >formErrors.url_invalid</mat-error
      >
    </mat-form-field>

    <mat-form-field floatLabel="always" class="medium-label">
      <input
        matInput
        placeholder="Medium"
        formControlName="medium"
        autocomplete="off"
        (keyup)="removePrefix('facebook')"
        (change)="removePrefix('facebook')"
        (focusout)="removePrefix('facebook')"
      />
      <fa-icon matPrefix class="front-icon medium" [icon]="faMedium"></fa-icon>
      <span matPrefix>{{ socialMediaPrefix['medium'] }}</span>
      <mat-error *ngIf="form.get('medium').hasError('pattern')" translate
        >formErrors.url_invalid</mat-error
      >
    </mat-form-field>
  </div>
</ng-template>
