<form novalidate #azulFormEl [formGroup]="azulForm" action="https://pruebas.azul.com.do/paymentpage/Default.aspx" method="post">
    <input type="hidden" formControlName="MerchantId" id="MerchantId" name="MerchantId" >
    <input type="hidden" formControlName="MerchantName" id="MerchantName" name="MerchantName" >
    <input type="hidden" formControlName="MerchantType" id="MerchantType" name="MerchantType" >
    <input type="hidden" formControlName="CurrencyCode" id="CurrencyCode" name="CurrencyCode" >
    <input type="hidden" formControlName="OrderNumber" id="OrderNumber" name="OrderNumber" >
    <input type="hidden" formControlName="Amount" id="Amount" name="Amount" value="36000" >
    <input type="hidden" formControlName="ApprovedUrl" id="ApprovedUrl" name="ApprovedUrl" >
    <input type="hidden" formControlName="DeclinedUrl" id="DeclinedUrl" name="DeclinedUrl" >
    <input type="hidden" formControlName="CancelUrl" id="CancelUrl" name="CancelUrl" >
    <input type="hidden" formControlName="UseCustomField1" id="UseCustomField1" name="UseCustomField1" >
    <input type="hidden" formControlName="CustomField1Label" id="CustomField1Label" name="CustomField1Label" >
    <input type="hidden" formControlName="CustomField1Value" id="CustomField1Value" name="CustomField1Value" >
    <input type="hidden" formControlName="UseCustomField2" id="UseCustomField2" name="UseCustomField2" >
    <input type="hidden" formControlName="CustomField2Label" id="CustomField2Label" name="CustomField2Label" >
    <input type="hidden" formControlName="CustomField2Value" id="CustomField2Value" name="CustomField2Value" >
    <input type="hidden" formControlName="Locale" id="Locale" name="Locale" >
    <input type="hidden" formControlName="ITBIS" id="ITBIS" name="ITBIS" >
    <input type="hidden" formControlName="AuthHash" id="AuthHash" name="AuthHash" >
</form>