<div fxLayout="row wrap" fxLayoutAlign="center start" class="mx-2">
  <div fxFlex="100">
    <mat-card class="bank-card">
      <div
        class="card-title"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <h2 translate>labels.clone_activity</h2>
      </div>
      <mat-card-content>
        <P translate> messages.activity_cloner_message</P>
        <form novalidate *ngIf="form" [formGroup]="form">
          <div class="flex mt-3 mb-2">
            <mat-form-field appearance="outline" class="w-full h-16">
              <input
                matInput
                formControlName="activityUrl"
                placeholder="{{ 'formFields.activity_url' | translate }}"
                class="text-base"
              />
              <button
                mat-button
                *ngIf="!activity && activityUrlValue"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clearActivityUrl()"
              >
                <mat-icon>close</mat-icon>
              </button>
              <mat-error
                *ngIf="
                  activityUrl.hasError('required') ||
                  activityUrl.hasError('invalid')
                "
                translate
                >formErrors.activity_url_invalid</mat-error
              >
            </mat-form-field>
            <button
              [disabled]="cloning || loading"
              class="h-11 ml-4 mt-1.5 bg-accent text-white p-3 rounded disabled:bg-gray-300 disabled:text-gray-700"
              (click)="searchActivity()"
            >
              <mat-icon *ngIf="!loading && !activity">search</mat-icon>
              <mat-icon *ngIf="!loading && activity">close</mat-icon>
              <mat-spinner diameter="25" *ngIf="loading"></mat-spinner>
            </button>
          </div>
        </form>

        <ng-container *ngIf="activity">
          <div class="flex items-stretch p-2 border-gray-300 border rounded">
            <img
              class="w-20 h-20 rounded object-cover"
              [src]="activity.images[0]"
            />
            <div class="w-full mx-2">
              <h2 class="font-semibold">{{ activity.name }}</h2>
              <h2 class="font-semibold text-gray-500">
                {{ 'entity_type.' + activity.entityType | translate }}
              </h2>
              <section
                *ngIf="innerActivities.length > 0"
                fxLayoutAlign="space-between stretch"
                fxLayout="column"
              >
                <mat-checkbox [disabled]="cloning" [(ngModel)]="cloneFolderContent">
                  <span class="info-tooltip-container underline" translate
                    >formFields.clone_all_content<app-info-tooltip
                      class="checkbox-tooltip"
                      info="tooltip.clone_all_content"
                      infoClass="secondary"
                    ></app-info-tooltip
                  ></span>
                </mat-checkbox>
                <p
                  class="text-gray-500 text-sm mt-1"
                  translate
                  [translateParams]="{ value: innerActivities.length }"
                >
                  messages.clone_folder_content
                </p>
                <p
                  *ngIf="cloning"
                  class="text-gray-500 text-sm mt-1"
                  translate
                  [translateParams]="{
                    total: innerActivities.length,
                    completed: clonedActivity
                  }"
                >
                  messages.cloning_activities
                </p>
              </section>
            </div>
            <button
              [disabled]="!activity.clonable"
              class="h-11 ml-4 mt-1.5 bg-accent text-white p-3 rounded disabled:bg-gray-300 disabled:text-gray-700 self-center"
              (click)="cloneActivity()"
            >
              <span *ngIf="!cloning" translate>buttons.clone</span>
              <mat-spinner diameter="25" *ngIf="cloning"></mat-spinner>
            </button>
          </div>
          <mat-error class="mt-2" *ngIf="!activity.clonable" translate
            >formErrors.unclonable_activity</mat-error
          >
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>
</div>
