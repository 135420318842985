import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import VCard from 'vcard-creator';
import { AddressBean } from '../../models/types';
import { getCountryName } from '../../util/countries';
import { getBase64Image } from '../../util/image-utils';
import { ShortUrlHandlerComponent } from '../short-url-handler/short-url-handler.component';
import { MatDialog } from '@angular/material/dialog';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';

@Component({
  selector: 'app-company-info-card',
  templateUrl: './company-info-card.component.html',
  styleUrls: ['./company-info-card.component.scss'],
})
export class CompanyInfoCardComponent {
  @Input() name: string;
  @Input() address: AddressBean;
  @Input() phone: string;
  @Input() email: string;
  @Input() handler?: string;
  @Input() image?: string;


  constructor(
    private translate: TranslateService,
    protected dialog: MatDialog,
  ) {}

  async downloadVcf() {
    const vCard = new VCard();
    vCard
      .addName(this.name)
      .addCompany(this.name)
      .addEmail(this.email)
      .addPhoneNumber(this.phone, 'WORK')
      .addAddress(
        null,
        this.address.address2 ? this.address.address2 : null,
        this.address.address,
        this.address.city,
        this.address.stateOrProvidence,
        this.address.zipCode,
        getCountryName(this.address.country, this.translate.getDefaultLang())
      );
    if (this.handler) {
      vCard.addURL(ShortUrlHandlerComponent.shortHubUrl(this.handler));
    }

    if (this.image) {
      vCard.addPhoto(await getBase64Image(this.image));
    }

    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(
      new Blob([vCard.buildVCard()], { type: 'text/vcf' })
    );
    downloadLink.target = '_self';
    downloadLink.download = `${this.name.toLocaleLowerCase().replace(/ /g, '-')}-contact.vcf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    this.openDialog();
  }

  openDialog(): void {
    this.dialog.open(NotificationDialogComponent, {
      minWidth: '240px',
      data: {
        title: this.translate.instant('titles.business_contact'),
        content: this.translate.instant('messages.business_contact_info'),
      },
    });
  }
}
