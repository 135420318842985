import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppSettings } from '../../../../app.settings';
import { ImageService } from '../../../../service/image.service';
import { Settings } from '../../../../app.settings.model';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '../../../../service/http.service';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { CategoryAccessEntity } from '../../../../models/category';
import { RequestDetailsDialogComponent } from '../request-details-dialog/request-details-dialog.component';

@Component({
  selector: 'app-access-list-client-view',
  templateUrl: './access-list-client-view.component.html',
  styleUrls: ['./access-list-client-view.component.scss'],
})
export class AccessListClientViewComponent implements OnInit {
  @Input()
  hubId: string;

  @Input()
  categoryId: string;

  @Input()
  user: any;

  @Output()
  hasOpen: EventEmitter<any> = new EventEmitter();

  @Output()
  cancel: EventEmitter<any> = new EventEmitter();

  private readonly apiUrl = environment.apiUrl;

  data: any;
  /*pageSize = 5;
  pageIndex = 0;
  length = 0;*/
  settings: Settings;
  loading = false;

  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private translate: TranslateService,
    private appSettings: AppSettings,
    protected imageService: ImageService,
    protected snackBar: MatSnackBar,
    private router: Router,
    private httpService: HttpService,
    private dialog: MatDialog
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.search();
  }

  async search() {
    let url = `${this.apiUrl}/categories/v1/access/category/${this.categoryId}`;
    this.loading = true;
    try {
      const response: CategoryAccessEntity = await firstValueFrom(
        this.httpService.doGet(url)
      );
      if (response) {
        this.data = response;
        if (this.data && this.data.id) {
          this.hasOpen.emit(true);
        }
      } else {
        this.data = null;
      }
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      this.loading = false;
    }
  }

  showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  openDetails(request): void {
    const dialogRef = this.dialog.open(RequestDetailsDialogComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'reports-details-dialog',
      data: {
        report: request,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.search();
      }
    });
  }
}
