import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-appointment-duration-picker',
  templateUrl: './appointment-duration-picker.component.html',
  styleUrls: ['./appointment-duration-picker.component.scss']
})
export class AppointmentDurationPickerComponent {
  @Input() control: UntypedFormControl;
}
