import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rich-text-view',
  templateUrl: './rich-text-view.component.html',
  styleUrls: ['./rich-text-view.component.scss']
})
export class RichTextViewComponent {
  @Input() text: string;
  constructor() {}

}
