import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { ServiceModule } from '../../service/service.module';
import { DirectivesModule } from '../../theme/directives/directives.module';
import { CategoryManagementComponent } from './category-management/category-management.component';
import { CategoryOwnerViewComponent } from './category-owner-view/category-owner-view.component';
import { CategoryClientViewComponent } from './category-client-view/category-client-view.component';
import { AuthGuard } from '../../auth/auth.guard';
import { CategorySpeedDialFabComponent } from './components/category-speed-dial-fab/category-speed-dial-fab.component';
import { ComponentsModule } from '../../components/componets.module';
import { SortActCatManagementComponent } from '../../components/sort-act-cat-management/sort-act-cat-management.component';
import { FormGuard } from '../../guards/form.guard';
import { SortHubMenuManagementComponent } from '../../components/sort-hub-menu-management/sort-hub-menu-management.component';
import { AccessListClientViewComponent } from './components/access-list-client-view/access-list-client-view.component';
import { RequestDetailsDialogComponent } from './components/request-details-dialog/request-details-dialog.component';
import { RequestCategoryAccessGeneralInfoComponent } from './components/request-category-access-general-info/request-category-access-general-info.component';
import { RequestCategoryAccessDetailsComponent } from './components/request-category-access-details/request-category-access-details.component';

export const routes = [
  { path: 'owner', component: CategoryOwnerViewComponent },
  {
    path: 'sort',
    component: SortActCatManagementComponent,
    canActivate: [AuthGuard],
    canDeactivate: [FormGuard],
  },
  {
    path: 'sort-menu',
    component: SortHubMenuManagementComponent,
    canActivate: [AuthGuard],
    canDeactivate: [FormGuard],
  },
  {
    path: 'manage',
    component: CategoryManagementComponent,
    canActivate: [AuthGuard],
    canDeactivate: [FormGuard],
  },
  { path: 'client-view', component: CategoryClientViewComponent },
  {
    path: 'client-view-vip',
    component: CategoryClientViewComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ServiceModule,
    RouterModule.forChild(routes),
    DirectivesModule,
    ComponentsModule,
  ],
  providers: [],
  declarations: [
    CategoryOwnerViewComponent,
    CategoryManagementComponent,
    CategoryClientViewComponent,
    CategorySpeedDialFabComponent,
    AccessListClientViewComponent,
    RequestDetailsDialogComponent,
    RequestCategoryAccessGeneralInfoComponent,
    RequestCategoryAccessDetailsComponent
  ],
  exports: [
    CategoryManagementComponent,
  ],
})
export class CategoryModule {}
