<div class="page-container" fxLayout="column">
  <form *ngIf="form" [formGroup]="form" class="main-report-info">
    <app-request-request-general-info
      *ngIf="hub && report && reportDetail && activity"
      [hub]="hub"
      [report]="report"
      [reportDetail]="reportDetail"
      [activity]="activity"
      [status]="status"
      [statusOptions]="getAvailableStatus(reportDetail.status)"
      (statusChanged)="sendRequest()"
    >
    </app-request-request-general-info>
  </form>

  <app-location-map
    *ngIf="reportDetail && reportDetail.location"
    [location]="reportDetail.location"
    [name]="reportDetail.clientName"
    [launch]="true"
  >
  </app-location-map>

  <app-chosen-address
    *ngIf="reportDetail && reportDetail.choosenLocation"
    [chosenLocation]="reportDetail.choosenLocation"
  ></app-chosen-address>

  <app-view-question-list
    *ngIf="reportDetail"
    [answers]="reportDetail.questionAnswerMap"
  >
  </app-view-question-list>

  <app-chosen-payment-method
    *ngIf="reportDetail && reportDetail.chosenPaymentType"
    [chosenPaymentType]="reportDetail.chosenPaymentType"
    [cashReturn]="reportDetail.cashReturn"
    [paymentImage]="reportDetail.paymentImage"
  >
  </app-chosen-payment-method>

  <app-payment-link
    *ngIf="reportDetail && reportDetail.paymentLink"
    [currentPaymentLink]="reportDetail.paymentLink"
  >
  </app-payment-link>

  <app-questions-summary-view *ngIf="reportDetail && totalPrice > 0"
    [questionAnswerMap]="reportDetail.questionAnswerMap"
    [totalPrice]="reportDetail.totalPrice"
    [additionalCharges]="reportDetail.additionalCharges"
    [promoCodeEntity]="reportDetail.issuedPromoCode"
  >
  </app-questions-summary-view>
  
  <app-reports-comments-view
    *ngIf="reportDetail"
    [client]="true"
    [entityId]="reportDetail.id"
  ></app-reports-comments-view>
</div>
