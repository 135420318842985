<div fxLayout="row">
    <h2 class="title" translate>questionsDialog.questions</h2>
</div>
<div class="question-list-container">
    <div fxLayout="column" fxLayoutGap="20px">
        <form *ngIf="questionsForm.length > 0; else noQuestions" 
            class="questions-boundary"
            cdkDropList 
            (cdkDropListDropped)="drop($event)"
            novalidate 
            [formGroup]="form" 
            fxLayout="column" 
            fxLayoutAlign="center">
            <div class="flex-p" 
              formArrayName="questions" 
              *ngFor="let questionItem of questionsForm.controls; let i = index;" 
              cdkDragLockAxis="y" 
              cdkDrag>
                <mat-card class="card-container"  [formGroupName]="i" *ngIf="selectedQuestion.has(i)">
                    <div class="hanlde-container">
                        <mat-icon class="handle-move" cdkDragHandle>drag_handle</mat-icon>
                        <mat-icon class="close-question" (click)="closeQuestion(i)">keyboard_arrow_down</mat-icon>
                    </div>
                    <mat-card-content>
                        <mat-form-field class="main-field">
                            <input matInput placeholder="{{'questionsDialog.question' | translate}}" formControlName="question">
                            <mat-error *ngIf="questionsForm.controls[i].get('question').hasError('required')" translate>questionsDialog.error_question</mat-error>
                            <mat-error *ngIf="questionsForm.controls[i].get('question').hasError('unique')" translate>questionsDialog.error_unique_question</mat-error>
                            <mat-error *ngIf="questionsForm.controls[i].get('question').hasError('pattern')" translate>questionsDialog.error_pattern</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="main-field-description-form">
                            <mat-label>{{'formFields.description' | translate}}</mat-label>
                            <textarea rows="2" matInput matTextareaAutosize formControlName="description"></textarea>
                        </mat-form-field>
                        <div fxLayout="row nowrap" fxLayoutAlign="space-between center">
                            <mat-form-field class="question-content">
                                <app-info-tooltip matSuffix info="tooltip.question_type" infoClass="secondary" class="info-question-type"></app-info-tooltip>
                                <mat-select placeholder="{{'questionsDialog.type' | translate}}" formControlName="type" required>
                                    <mat-select-trigger>
                                        <mat-icon class="type-icon">{{ICONS[questionItem.value.type]}}</mat-icon> &nbsp; {{("questionsDialog."+questionItem.value.type.toLowerCase()) | translate}}
                                    </mat-select-trigger>
                                    <mat-option value="TEXT">
                                        <mat-icon>short_text</mat-icon> <span translate>questionsDialog.text</span>
                                    </mat-option>
                                    <mat-option value="NUMBER">
                                        <mat-icon>plus_one</mat-icon> <span translate>questionsDialog.number</span>
                                    </mat-option>
                                    <mat-option value="DATE">
                                        <mat-icon>calendar_today</mat-icon><span translate>questionsDialog.date</span>
                                    </mat-option>
                                    <mat-option value="TIME">
                                        <mat-icon>access_time</mat-icon> <span translate>questionsDialog.time</span>
                                    </mat-option>
                                    <mat-option value="DATETIME">
                                        <mat-icon>date_range</mat-icon><span translate>questionsDialog.datetime</span>
                                    </mat-option>
                                    <mat-option value="SINGLE_CHOICE">
                                        <mat-icon>radio_button_checked</mat-icon><span translate>questionsDialog.single_choice</span>
                                    </mat-option>
                                    <mat-option value="MULTI_CHOICE">
                                        <mat-icon>check_box_outline_blank</mat-icon><span translate>questionsDialog.multi_choice</span>
                                    </mat-option>
                                    <mat-option value="GROUP">
                                        <mat-icon>line_weight</mat-icon><span translate>questionsDialog.group</span>
                                    </mat-option>
                                    <mat-option value="GEOPOINT">
                                        <mat-icon>map</mat-icon><span translate>questionsDialog.geopoint</span>
                                    </mat-option>
                                    <mat-option value="IMAGE">
                                        <mat-icon>image</mat-icon><span translate>questionsDialog.image</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-slide-toggle class="question-cost" *ngIf="questionItem.value.type === 'SINGLE_CHOICE' || questionItem.value.type === 'MULTI_CHOICE'" formControlName="price">
                                <span translate>formFields.cost<app-info-tooltip class="checkbox-tooltip" info="tooltip.question_cost" infoClass="secondary"></app-info-tooltip></span>
                            </mat-slide-toggle>
                        </div>

                        <div class="question-content" [ngSwitch]="questionItem.value.type">
                            <div *ngSwitchCase="'TEXT'">
                                <P class="field-type-text" translate>
                                    questionsDialog.text_answer
                                </P>
                            </div>
                            <div *ngSwitchCase="'NUMBER'">
                                <P class="field-type-text" translate>
                                    questionsDialog.number_answer
                                </P>
                            </div>
                            <div *ngSwitchCase="'DATE'">
                                <div class="field-type-text" fxLayout="row" fxLayoutAlign="space-between center">
                                    <span translate>questionsDialog.date_answer</span>
                                    <mat-icon>calendar_today</mat-icon>
                                </div>
                            </div>
                            <div *ngSwitchCase="'TIME'">
                                <div class="field-type-text" fxLayout="row" fxLayoutAlign="space-between center">
                                    <span translate>questionsDialog.time_answer</span>
                                    <mat-icon>access_time</mat-icon>
                                </div>
                            </div>
                            <div *ngSwitchCase="'DATETIME'">
                                <div class="field-type-text" fxLayout="row" fxLayoutAlign="space-between center">
                                    <span translate>questionsDialog.datetime_answer</span>
                                    <mat-icon>date_range</mat-icon>
                                </div>
                            </div>
                            <div *ngSwitchCase="'GEOPOINT'">
                                <div class="field-type-text" fxLayout="row" fxLayoutAlign="space-between center">
                                    <span translate>questionsDialog.geopoint</span>
                                    <mat-icon>map</mat-icon>
                                </div>
                            </div>
                            <div *ngSwitchCase="'SINGLE_CHOICE'">
                                <div class="field-type-options">
                                    <mat-radio-group disabled value="none">
                                        <div [formGroup]="option" *ngFor="let option of getQuestionOptionsControls(i); let j = index; let length = count;" fxLayout="row nowrap" fxLayoutAlign="space-between center">
                                            <div fxLayout="column">
                                                <mat-radio-button>
                                                    <mat-form-field floatLabel='never' class="field-type-option-input" color="accent">
                                                        <input matInput placeholder="{{option.value.name}}" formControlName="name" (focus)="focusOption(option)">
                                                        <mat-error *ngIf="option.get('name').hasError('required')" translate>questionsDialog.error_required_option</mat-error>
                                                        <mat-error *ngIf="option.get('name').hasError('unique')" translate>questionsDialog.error_unique_option</mat-error>
                                                        <mat-error *ngIf="option.get('name').hasError('pattern')" translate>questionsDialog.error_pattern</mat-error>
                                                    </mat-form-field>
                                                </mat-radio-button>
                                                <mat-form-field *ngIf="questionItem.value.price" class="field-type-option-input field-price" floatLabel='never' color="accent">
                                                    <input type="number"
                                                        matInput 
                                                        formControlName="price">
                                                        <span matPrefix>$&nbsp;</span>
                                                </mat-form-field>
                                            </div>

                                            <button fxFlex="10" *ngIf="length > 2" type="button" (click)="removeOption(i, j)" mat-icon-button>
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </div>
                                    </mat-radio-group>
                                    <button (click)="addOption(i)" mat-button color="accent"><span translate>questionsDialog.add_option</span></button>
                                </div>
                            </div>
                            <div *ngSwitchCase="'MULTI_CHOICE'">
                                <div class="field-type-options">

                                    <div [formGroup]="option" *ngFor="let option of getQuestionOptionsControls(i); let j = index; let length = count;" fxLayout="row" fxLayoutAlign="space-between center">
                                        <div fxLayout="column">
                                            <mat-checkbox disabled>
                                                <mat-form-field floatLabel='never' class="field-type-option-input" color="accent">
                                                    <input matInput placeholder="{{option.value.name}}" formControlName="name" (focus)="focusOption(option)">
                                                    <mat-error *ngIf="option.get('name').hasError('required')" translate>questionsDialog.error_required_option</mat-error>
                                                    <mat-error *ngIf="option.get('name').hasError('unique')" translate>questionsDialog.error_unique_option</mat-error>
                                                    <mat-error *ngIf="option.get('name').hasError('pattern')" translate>questionsDialog.error_pattern</mat-error>
                                                </mat-form-field>
                                            </mat-checkbox>
                                            <mat-form-field *ngIf="questionItem.value.price" class="field-type-option-input field-price" floatLabel='never' color="accent">
                                                <input type="number"
                                                    matInput 
                                                    formControlName="price">
                                                    <span matPrefix>$&nbsp;</span>
                                            </mat-form-field>
                                        </div>
                                        <button fxFlex="10" *ngIf="length > 2" type="button" (click)="removeOption(i, j)" mat-icon-button>
                                            <mat-icon>close</mat-icon>
                                        </button>
                                    </div>

                                    <button (click)="addOption(i)" mat-button color="accent"><span translate>questionsDialog.add_option</span></button>
                                </div>
                            </div>
                            <div *ngSwitchCase="'GROUP'">
                                <div class="field-type-options-group">
                                    
                                    <div class="group-container" *ngFor="let option of getQuestionOptionsControls(i); let j = index; let length = count;" fxLayout="row" fxLayoutAlign="space-between center">
                                        <mat-expansion-panel [hideToggle]="true">
                                            <mat-expansion-panel-header>
                                                <mat-radio-button (keydown.Space)="$event.stopImmediatePropagation();">
                                                    <span [formGroup]="option">
                                                        <mat-form-field floatLabel='never' class="field-type-option-input" color="accent">
                                                            <input matInput placeholder="{{option.value.name}}" formControlName="name" (focus)="focusOption(option)">
                                                            <mat-error *ngIf="option.get('name').hasError('required')" translate>questionsDialog.error_required_option</mat-error>
                                                            <mat-error *ngIf="option.get('name').hasError('unique')" translate>questionsDialog.error_unique_option</mat-error>
                                                            <mat-error *ngIf="option.get('name').hasError('pattern')" translate>questionsDialog.error_pattern</mat-error>
                                                        </mat-form-field>
                                                    </span>
                                                </mat-radio-button>
                                                <button *ngIf="length > 2" type="button" (click)="removeOption(i, j)" mat-icon-button>
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </mat-expansion-panel-header>
                                            <div class="panel-body">
                                                <div fxLayout="column" fxLayoutGap="20px">
                                                    <form
                                                        class="questions-boundary"
                                                        cdkDropList 
                                                        (cdkDropListDropped)="innerDrop($event, i, j)"
                                                        novalidate 
                                                        fxLayout="column" 
                                                        fxLayoutAlign="center">
                                                        <div class="flex-p" 
                                                          *ngFor="let innerQuestionItem of getInnerQuestionControls(i, j); let k = index;" 
                                                          cdkDragLockAxis="y" 
                                                          cdkDrag>
                                                            <mat-card class="card-container" [formGroup]="innerQuestionItem" *ngIf="selectedQuestionHas(i, j, k)">
                                                                <div class="hanlde-container">
                                                                    <mat-icon class="handle-move" cdkDragHandle>drag_handle</mat-icon>
                                                                    <mat-icon class="close-question" (click)="closeInnerQuestion(i, j, k)">keyboard_arrow_down</mat-icon>
                                                                </div>
                                                                <mat-card-content>
                                                                    <mat-form-field class="main-field">
                                                                        <input matInput placeholder="{{'questionsDialog.question' | translate}}" formControlName="question">
                                                                        <mat-error *ngIf="innerQuestionItem.get('question').hasError('required')" translate>questionsDialog.error_question</mat-error>
                                                                        <mat-error *ngIf="innerQuestionItem.get('question').hasError('unique')" translate>questionsDialog.error_unique_question</mat-error>
                                                                        <mat-error *ngIf="innerQuestionItem.get('question').hasError('pattern')" translate>questionsDialog.error_pattern</mat-error>
                                                                    </mat-form-field>
                                                                    <mat-form-field appearance="outline" class="main-field-description-form">
                                                                        <mat-label>{{'formFields.description' | translate}}</mat-label>
                                                                        <textarea rows="2" matInput matTextareaAutosize formControlName="description"></textarea>
                                                                    </mat-form-field>
                                                                    <div fxLayout="row nowrap" fxLayoutAlign="space-between center">
                                                                        <mat-form-field class="question-content">
                                                                            <mat-select placeholder="{{'questionsDialog.type' | translate}}" formControlName="type" required>
                                                                                <mat-select-trigger>
                                                                                    <mat-icon class="type-icon">{{ICONS[innerQuestionItem.value.type]}}</mat-icon> &nbsp; {{("questionsDialog."+innerQuestionItem.value.type.toLowerCase()) | translate}}
                                                                                </mat-select-trigger>
                                                                                <mat-option value="TEXT">
                                                                                    <mat-icon>short_text</mat-icon> <span translate>questionsDialog.text</span>
                                                                                </mat-option>
                                                                                <mat-option value="NUMBER">
                                                                                    <mat-icon>plus_one</mat-icon> <span translate>questionsDialog.number</span>
                                                                                </mat-option>
                                                                                <mat-option value="DATE">
                                                                                    <mat-icon>calendar_today</mat-icon><span translate>questionsDialog.date</span>
                                                                                </mat-option>
                                                                                <mat-option value="TIME">
                                                                                    <mat-icon>access_time</mat-icon> <span translate>questionsDialog.time</span>
                                                                                </mat-option>
                                                                                <mat-option value="DATETIME">
                                                                                    <mat-icon>date_range</mat-icon><span translate>questionsDialog.datetime</span>
                                                                                </mat-option>
                                                                                <mat-option value="SINGLE_CHOICE">
                                                                                    <mat-icon>radio_button_checked</mat-icon><span translate>questionsDialog.single_choice</span>
                                                                                </mat-option>
                                                                                <mat-option value="GEOPOINT">
                                                                                    <mat-icon>map</mat-icon><span translate>questionsDialog.geopoint</span>
                                                                                </mat-option>
                                                                                <mat-option value="MULTI_CHOICE">
                                                                                    <mat-icon>check_box_outline_blank</mat-icon><span translate>questionsDialog.multi_choice</span>
                                                                                </mat-option>
                                                                                <mat-option value="IMAGE">
                                                                                    <mat-icon>image</mat-icon><span translate>questionsDialog.image</span>
                                                                                </mat-option>
                                                                            </mat-select>
                                                                        </mat-form-field>
                                                                        <mat-slide-toggle class="question-content" *ngIf="innerQuestionItem.value.type === 'SINGLE_CHOICE' || innerQuestionItem.value.type === 'MULTI_CHOICE'" formControlName="price">
                                                                            <span translate>formFields.cost</span>
                                                                        </mat-slide-toggle>
                                                                    </div>
                                            
                                                                    <div class="question-content" [ngSwitch]="innerQuestionItem.value.type">
                                                                        <div *ngSwitchCase="'TEXT'">
                                                                            <P class="field-type-text" translate>
                                                                                questionsDialog.text_answer
                                                                            </P>
                                                                        </div>
                                                                        <div *ngSwitchCase="'NUMBER'">
                                                                            <P class="field-type-text" translate>
                                                                                questionsDialog.number_answer
                                                                            </P>
                                                                        </div>
                                                                        <div *ngSwitchCase="'DATE'">
                                                                            <div class="field-type-text" fxLayout="row" fxLayoutAlign="space-between center">
                                                                                <span translate>questionsDialog.date_answer</span>
                                                                                <mat-icon>calendar_today</mat-icon>
                                                                            </div>
                                                                        </div>
                                                                        <div *ngSwitchCase="'TIME'">
                                                                            <div class="field-type-text" fxLayout="row" fxLayoutAlign="space-between center">
                                                                                <span translate>questionsDialog.time_answer</span>
                                                                                <mat-icon>access_time</mat-icon>
                                                                            </div>
                                                                        </div>
                                                                        <div *ngSwitchCase="'DATETIME'">
                                                                            <div class="field-type-text" fxLayout="row" fxLayoutAlign="space-between center">
                                                                                <span translate>questionsDialog.datetime_answer</span>
                                                                                <mat-icon>date_range</mat-icon>
                                                                            </div>
                                                                        </div>
                                                                        <div *ngSwitchCase="'GEOPOINT'">
                                                                            <div class="field-type-text" fxLayout="row" fxLayoutAlign="space-between center">
                                                                                <span translate>questionsDialog.geopoint</span>
                                                                                <mat-icon>map</mat-icon>
                                                                            </div>
                                                                        </div>
                                                                        <div *ngSwitchCase="'SINGLE_CHOICE'">
                                                                            <div class="field-type-options">
                                                                                <mat-radio-group disabled value="none">
                                                                                    <div [formGroup]="option" *ngFor="let option of getInnerQuestionOptionsControls(i, j, k); let l = index; let innerLength = count;" fxLayout="row" fxLayoutAlign="space-between center">
                                                                                        <div fxLayout="column">
                                                                                            <mat-radio-button>
                                                                                                <mat-form-field floatLabel='never' class="field-type-option-input" color="accent">
                                                                                                    <input matInput placeholder="{{option.value.name}}" formControlName="name" (focus)="focusOption(option)">
                                                                                                    <mat-error *ngIf="option.get('name').hasError('required')" translate>questionsDialog.error_required_option</mat-error>
                                                                                                    <mat-error *ngIf="option.get('name').hasError('unique')" translate>questionsDialog.error_unique_option</mat-error>
                                                                                                    <mat-error *ngIf="option.get('name').hasError('pattern')" translate>questionsDialog.error_pattern</mat-error>
                                                                                                </mat-form-field>
                                                                                            </mat-radio-button>
                                                                                            <mat-form-field *ngIf="innerQuestionItem.value.price" class="field-type-option-input field-price" floatLabel='never' color="accent">
                                                                                                <input type="number"
                                                                                                    matInput 
                                                                                                    formControlName="price">
                                                                                                    <span matPrefix>$&nbsp;</span>
                                                                                            </mat-form-field>
                                                                                        </div>

                                                                                        <button fxFlex="10" *ngIf="innerLength > 2" type="button" (click)="removeInnerOption(i, j, k, l)" mat-icon-button>
                                                                                            <mat-icon>close</mat-icon>
                                                                                        </button>
                                                                                    </div>
                                                                                </mat-radio-group>
                                                                                <button (click)="addInnerOption(i, j, k)" mat-button color="accent"><span translate>questionsDialog.add_option</span></button>
                                                                            </div>
                                                                        </div>
                                                                        <div *ngSwitchCase="'MULTI_CHOICE'">
                                                                            <div class="field-type-options">
                                            
                                                                                <div [formGroup]="option" *ngFor="let option of getInnerQuestionOptionsControls(i, j, k); let l = index; let innerLength = count;" fxLayout="row" fxLayoutAlign="space-between center">
                                                                                    <div fxLayout="column">
                                                                                        <mat-checkbox disabled>
                                                                                            <mat-form-field floatLabel='never' class="field-type-option-input" color="accent">
                                                                                                <input matInput placeholder="{{option.value.name}}" formControlName="name" (focus)="focusOption(option)">
                                                                                                <mat-error *ngIf="option.get('name').hasError('required')" translate>questionsDialog.error_required_option</mat-error>
                                                                                                <mat-error *ngIf="option.get('name').hasError('unique')" translate>questionsDialog.error_unique_option</mat-error>
                                                                                                <mat-error *ngIf="option.get('name').hasError('pattern')" translate>questionsDialog.error_pattern</mat-error>
                                                                                            </mat-form-field>
                                                                                            <mat-form-field *ngIf="innerQuestionItem.value.price" class="field-type-option-input field-price" floatLabel='never' color="accent">
                                                                                                <input type="number"
                                                                                                    matInput 
                                                                                                    formControlName="price">
                                                                                                    <span matPrefix>$&nbsp;</span>
                                                                                            </mat-form-field>
                                                                                        </mat-checkbox>
                                                                                    </div>
                                                                                    <button fxFlex="10" *ngIf="length > 2" type="button" (click)="removeInnerOption(i, j, k, l)" mat-icon-button>
                                                                                        <mat-icon>close</mat-icon>
                                                                                    </button>
                                                                                </div>
                                            
                                                                                <button (click)="addInnerOption(i, j, k)" mat-button color="accent"><span translate>questionsDialog.add_option</span></button>
                                                                            </div>
                                                                        </div>
                                                                        <div *ngSwitchCase="'IMAGE'">
                                                                            <span class="field-type-image" fxLayout="row" fxLayoutAlign="space-between center">
                                                                                <mat-icon>cloud_upload</mat-icon>
                                                                                <span translate>questionsDialog.image_answer</span>
                                                                            </span>
                                                                        </div>
                                                                        <span *ngSwitchDefault>...</span>
                                                                    </div>
                                                                </mat-card-content>
                                                                <mat-card-actions class="footer">
                                                                    <div fxLayout="row" fxLayoutAlign="end center">
                                                                        <button class="remove-button" type="button" (click)="removeInnerQuestion(i, j, k)" mat-icon-button color="warn">
                                                                            <mat-icon class="delete-icon">delete_forever</mat-icon>
                                                                        </button>
                                                                        <mat-slide-toggle *ngIf="innerQuestionItem.value.type === 'TEXT' || innerQuestionItem.value.type === 'NUMBER'" formControlName="hidden">
                                                                            <span translate>questionsDialog.encrypted</span>
                                                                        </mat-slide-toggle>
                                                                        <mat-slide-toggle formControlName="required">
                                                                            <span translate>questionsDialog.required</span>
                                                                        </mat-slide-toggle>
                                                                    </div>
                                            
                                                                </mat-card-actions>
                                                            </mat-card>
                                            
                                                            <mat-card class="card-container card-closed" [formGroup]="innerQuestionItem" *ngIf="!selectedQuestionHas(i, j, k)" (click)="selectInnerQuestion(i, j, k)">
                                                                <div class="hanlde-container">
                                                                    <mat-icon class="handle-move" cdkDragHandle>drag_handle</mat-icon>
                                                                </div>
                                                                <mat-card-content>
                                                                    <p class="main-field">
                                                                        {{innerQuestionItem.value.question || 'questionsDialog.question' | translate}} <span *ngIf="innerQuestionItem.value.required" class="required-field">*</span>
                                                                    </p>
                                                                    <p *ngIf="innerQuestionItem.value.description" class="main-field-description">
                                                                        <mat-icon>info_outline</mat-icon> {{ innerQuestionItem.value.description }}
                                                                    </p>
                                                                    <div class="question-content" [ngSwitch]="innerQuestionItem.value.type">
                                                                        <div *ngSwitchCase="'TEXT'">
                                                                            <P class="field-type-text" translate>
                                                                                questionsDialog.text_answer
                                                                            </P>
                                                                        </div>
                                                                        <div *ngSwitchCase="'NUMBER'">
                                                                            <P class="field-type-text" translate>
                                                                                questionsDialog.number_answer
                                                                            </P>
                                                                        </div>
                                                                        <div *ngSwitchCase="'DATE'">
                                                                            <div class="field-type-text" fxLayout="row" fxLayoutAlign="space-between center">
                                                                                <span translate>questionsDialog.date_answer</span>
                                                                                <mat-icon>calendar_today</mat-icon>
                                                                            </div>
                                                                        </div>
                                                                        <div *ngSwitchCase="'TIME'">
                                                                            <div class="field-type-text" fxLayout="row" fxLayoutAlign="space-between center">
                                                                                <span translate>questionsDialog.time_answer</span>
                                                                                <mat-icon>access_time</mat-icon>
                                                                            </div>
                                                                        </div>
                                                                        <div *ngSwitchCase="'DATETIME'">
                                                                            <div class="field-type-text" fxLayout="row" fxLayoutAlign="space-between center">
                                                                                <span translate>questionsDialog.datetime_answer</span>
                                                                                <mat-icon>date_range</mat-icon>
                                                                            </div>
                                                                        </div>
                                                                        <div *ngSwitchCase="'GEOPOINT'">
                                                                            <div class="field-type-text" fxLayout="row" fxLayoutAlign="space-between center">
                                                                                <span translate>questionsDialog.geopoint</span>
                                                                                <mat-icon>map</mat-icon>
                                                                            </div>
                                                                        </div>
                                                                        <div *ngSwitchCase="'SINGLE_CHOICE'">
                                                                            <div class="field-type-options">
                                                                                <mat-radio-group disabled value="none">
                                                                                    <div class="field-type-option" *ngFor="let option of getInnerQuestionOptionsControls(i, j, k); let l = index;" fxLayout="row" fxLayoutAlign="space-between center">
                                                                                        <mat-radio-button>
                                                                                            {{option.value.name}} <span *ngIf="innerQuestionItem.value.price"> - ({{option.value.price | currency:'$'}})</span>
                                                                                        </mat-radio-button>
                                                                                    </div>
                                                                                </mat-radio-group>
                                                                            </div>
                                                                        </div>
                                            
                                                                        <div *ngSwitchCase="'MULTI_CHOICE'">
                                                                            <div class="field-type-options">
                                                                                <div class="field-type-option" *ngFor="let option of getInnerQuestionOptionsControls(i, j, k); let l = index;" fxLayout="row" fxLayoutAlign="space-between center">
                                                                                    <mat-checkbox disabled>
                                                                                        {{option.value.name}} <span *ngIf="innerQuestionItem.value.price"> - ({{option.value.price | currency:'$'}})</span>
                                                                                    </mat-checkbox>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div *ngSwitchCase="'IMAGE'">
                                                                            <span class="field-type-image" fxLayout="row" fxLayoutAlign="space-between center">
                                                                                <mat-icon>cloud_upload</mat-icon>
                                                                                <span translate>questionsDialog.image_answer</span>
                                                                            </span>
                                                                        </div>
                                                                        <span *ngSwitchDefault>...</span>
                                                                    </div>
                                                                </mat-card-content>
                                                            </mat-card>
                                                        </div>
                                                    </form>
                                                </div>
                                                <button class="innerAddQuestion" (click)="addInnerQuestion(i, j)" mat-button color="accent"><span translate>questionsDialog.add_question</span></button>
                                            </div>
                                        </mat-expansion-panel>
                                    </div>
                                    
                                    <button class="group-add-option" (click)="addOption(i)" mat-button color="accent"><span translate>questionsDialog.add_option</span></button>
                                </div>
                            </div>
                            <div *ngSwitchCase="'IMAGE'">
                                <span class="field-type-image" fxLayout="row" fxLayoutAlign="space-between center">
                                    <mat-icon>cloud_upload</mat-icon>
                                    <span translate>questionsDialog.image_answer</span>
                                </span>
                            </div>
                            <span *ngSwitchDefault>...</span>
                        </div>
                    </mat-card-content>
                    <mat-card-actions class="footer">
                        <div fxLayout="row" fxLayoutAlign="end center">
                            <button class="remove-button" type="button" (click)="removeQuestion(i)" mat-icon-button color="warn">
                                <mat-icon class="delete-icon">delete_forever</mat-icon>
                            </button>
                            <mat-slide-toggle *ngIf="questionItem.value.type === 'TEXT' || questionItem.value.type === 'NUMBER'" formControlName="hidden">
                                <span translate>questionsDialog.encrypted</span>
                            </mat-slide-toggle>
                            <mat-slide-toggle formControlName="required">
                                <span translate>questionsDialog.required</span>
                            </mat-slide-toggle>
                        </div>

                    </mat-card-actions>
                </mat-card>

                <mat-card class="card-container card-closed" [formGroupName]="i" *ngIf="!selectedQuestion.has(i)" (click)="selectQuestion(i)">
                    <div class="hanlde-container">
                        <mat-icon class="handle-move" cdkDragHandle>drag_handle</mat-icon>
                    </div>
                    <mat-card-content>
                        <p class="main-field">
                            {{questionItem.value.question || 'questionsDialog.question' | translate}} <span *ngIf="questionItem.value.required" class="required-field">*</span>
                        </p>
                        <p *ngIf="questionItem.value.description" class="main-field-description">
                            <mat-icon>info_outline</mat-icon> {{ questionItem.value.description }}
                        </p>
                        <div class="question-content" [ngSwitch]="questionItem.value.type">
                            <div *ngSwitchCase="'TEXT'">
                                <P class="field-type-text" translate>
                                    questionsDialog.text_answer
                                </P>
                            </div>
                            <div *ngSwitchCase="'NUMBER'">
                                <P class="field-type-text" translate>
                                    questionsDialog.number_answer
                                </P>
                            </div>
                            <div *ngSwitchCase="'DATE'">
                                <div class="field-type-text" fxLayout="row" fxLayoutAlign="space-between center">
                                    <span translate>questionsDialog.date_answer</span>
                                    <mat-icon>calendar_today</mat-icon>
                                </div>
                            </div>
                            <div *ngSwitchCase="'TIME'">
                                <div class="field-type-text" fxLayout="row" fxLayoutAlign="space-between center">
                                    <span translate>questionsDialog.time_answer</span>
                                    <mat-icon>access_time</mat-icon>
                                </div>
                            </div>
                            <div *ngSwitchCase="'DATETIME'">
                                <div class="field-type-text" fxLayout="row" fxLayoutAlign="space-between center">
                                    <span translate>questionsDialog.datetime_answer</span>
                                    <mat-icon>date_range</mat-icon>
                                </div>
                            </div>
                            
                            <div *ngSwitchCase="'GEOPOINT'">
                                <div class="field-type-text" fxLayout="row" fxLayoutAlign="space-between center">
                                    <span translate>questionsDialog.geopoint</span>
                                    <mat-icon>map</mat-icon>
                                </div>
                            </div>

                            <div *ngSwitchCase="'SINGLE_CHOICE'">
                                <div class="field-type-options">
                                    <mat-radio-group disabled value="none">
                                        <div class="field-type-option" *ngFor="let option of getQuestionOptionsControls(i); let j = index;" fxLayout="row" fxLayoutAlign="space-between center">
                                            <mat-radio-button>
                                                {{option.value.name}} <span *ngIf="questionItem.value.price"> - ({{option.value.price | currency:'$'}})</span>
                                            </mat-radio-button>
                                        </div>
                                    </mat-radio-group>
                                </div>
                            </div>

                            <div *ngSwitchCase="'MULTI_CHOICE'">
                                <div class="field-type-options">
                                    <div class="field-type-option" *ngFor="let option of getQuestionOptionsControls(i); let j = index;" fxLayout="row" fxLayoutAlign="space-between center">
                                        <mat-checkbox disabled>
                                            {{option.value.name}} <span *ngIf="questionItem.value.price"> - ({{option.value.price | currency:'$'}})</span>
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>

                            <div *ngSwitchCase="'GROUP'">
                                <div class="field-type-options">
                                    <mat-radio-group disabled value="none">
                                        <div class="field-type-option" *ngFor="let option of getQuestionOptionsControls(i); let j = index;" fxLayout="row" fxLayoutAlign="space-between center">
                                            <mat-radio-button>
                                                {{option.value.name}}
                                            </mat-radio-button>
                                        </div>
                                    </mat-radio-group>
                                </div>
                            </div>

                            <div *ngSwitchCase="'IMAGE'">
                                <span class="field-type-image" fxLayout="row" fxLayoutAlign="space-between center">
                                    <mat-icon>cloud_upload</mat-icon>
                                    <span translate>questionsDialog.image_answer</span>
                                </span>
                            </div>
                            <span *ngSwitchDefault>...</span>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </form>
        <ng-template #noQuestions>
            <div class="no-questions-container">
                <mat-icon>help_outline</mat-icon>
                <p translate>
                    questionsDialog.no_questions_text
                </p>
            </div>
        </ng-template>
    </div>
    <button class="speed-dial-action-button" (click)="addQuestion()" mat-fab color="accent">
        <span translate>questionsDialog.add_question</span>&nbsp;<mat-icon>help_outline</mat-icon>
    </button>
</div>

