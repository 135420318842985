<div fxLayout="row wrap" fxLayoutAlign="center start">
  <div fxFlex="100">
    <mat-card class="bank-card">
      <div
        class="card-title"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <h2 translate>labels.payment_link</h2>
        <fa-icon class="button-icon" [icon]="faLink"></fa-icon>
      </div>
      <mat-card-content>
        <P translate> messages.payment_link_customer_message</P>
        <a
          class="text-blue-500 cursor-pointer mt-2 w-full"
          [href]="currentPaymentLink"
        >
          {{currentPaymentLink}}
        </a>
      </mat-card-content>
    </mat-card>
  </div>
</div>
