<div id="fab-dismiss" *ngIf="fabTogglerState==='active'" (click)="onToggleFab()">
</div>
<div class="fab-container">
    <button color="primary" mat-fab class="fab-toggler" (click)="onToggleFab()">
        <app-info-tooltip info="tooltip.category_add_button"></app-info-tooltip>
        <i class="material-icons" [@fabToggler]="{value: fabTogglerState}">add</i>
    </button>
    <div [@speedDialStagger]="buttons.length">
        <!-- and here -->
        <ng-container *ngFor="let btn of buttons">
            <button *ngIf="btn.icon !== 'sort' || canSort" (click)="btn.onClick()" mat-mini-fab class="fab-secondary" color="secondary">
                <span class="label">{{btn.label}}</span>
                <i class="material-icons">{{btn.icon}}</i>
            </button>
        </ng-container>

    </div>
</div>