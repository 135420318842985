<mat-card>
    <div fxLayout="column owrap">
        <div *ngIf="title" fxLayout="row nowrap">
            <mat-icon>info_outline</mat-icon>
            <h1 translate>{{title}}</h1>
        </div>
        <p translate [translateParams]="params">
            {{description}}
            <a *ngIf="link" target="_blank" [href]="link" translate>{{linkText || 'labels.here'}}</a>
        </p>
    </div>
</mat-card>