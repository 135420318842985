<div fxLayout="row" *ngIf="!hidden && hasPrice">
  <h2 class="title" translate>productList.summary</h2>
</div>

<mat-card class="card-container" *ngIf="!hidden && hasPrice">
  <mat-card-content>
    <mat-list>
      <ng-container *ngIf="itemsValue">
        <mat-list-item>
          <div fxLayout="row" fxLayoutAlign="start center" class="row">
            <div fxFlex="60" class="field-name">
              ({{ quantity }}) {{ itemLabel | translate }}:
            </div>
            <div fxFlex="40" class="value">
              {{ itemsValue | currency : currency : currencySymbols[currency] }}
            </div>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </ng-container>

      <ng-container *ngIf="shipping">
        <mat-list-item>
          <div fxLayout="row" fxLayoutAlign="start center" class="row">
            <div fxFlex="60" class="field-name">
              {{ 'labels.shipping' | translate }}:
            </div>
            <div fxFlex="40" class="value">
              {{
                shipping || 0 | currency : currency : currencySymbols[currency]
              }}
            </div>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </ng-container>

      <ng-container *ngIf="tip">
        <mat-list-item>
          <div fxLayout="row" fxLayoutAlign="start center" class="row">
            <div fxFlex="60" class="field-name">
              {{ 'labels.tip' | translate }}:
            </div>
            <div fxFlex="40" class="value">
              {{ tip || 0 | currency : currency : currencySymbols[currency] }}
            </div>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </ng-container>

      <ng-container *ngFor="let answer of getQuestionRows()">
        <mat-list-item>
          <div fxLayout="row" fxLayoutAlign="start center" class="row">
            <div fxFlex="60" class="field-name">{{ answer.name }}:</div>
            <div fxFlex="40" class="value">
              {{
                answer.value | currency : currency : currencySymbols[currency]
              }}
            </div>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </ng-container>

      <ng-container
        *ngFor="let additionalCharge of additionalCharges | keyvalue"
      >
        <mat-list-item>
          <div fxLayout="row" fxLayoutAlign="start center" class="row">
            <div fxFlex="60" class="field-name">
              {{ additionalCharge.key }}:
            </div>
            <div fxFlex="40" class="value">
              {{
                additionalCharge.value
                  | currency : currency : currencySymbols[currency]
              }}
            </div>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </ng-container>

      <form novalidate *ngIf="promoCodeForm" [formGroup]="promoCodeForm">
        <div class="flex mt-3 mb-4">
          <div
            *ngIf="promoCodeAppliedValue"
            class="w-full bg-primary text-white font-bold rounded text-center mt-1.5 p-3 h-11 text-base"
          >
            {{ promoCodeValue }}
          </div>
          <mat-form-field
            appearance="outline"
            class="w-full h-16"
            *ngIf="!promoCodeAppliedValue"
          >
            <input
              matInput
              formControlName="promoCode"
              placeholder="{{ 'formFields.promo_code' | translate }}"
              class="text-base"
            />
            <button
              mat-button
              *ngIf="
                !validatingPromo && promoCodeValue && !promoCodeAppliedValue
              "
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="clearPromoCode()"
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-error *ngIf="promoCode?.hasError('promoCodeError')" translate
              >formErrors.valid_promo_code</mat-error
            >
            <mat-error
              *ngIf="promoCode?.hasError('promoCodeLenthError')"
              translate
              [translateParams]="{ value: 6 }"
              >formErrors.min_length</mat-error
            >
            <mat-error
              *ngIf="promoCode?.hasError('inactivePromoCodeError')"
              translate
              >formErrors.inactive_promo_code</mat-error
            >
            <mat-error
              *ngIf="promoCode?.hasError('overUsagePromoCodeError')"
              translate
              >formErrors.over_usage_promo_code</mat-error
            >
            <mat-error
              *ngIf="promoCode?.hasError('outOfRangePromoCodeError')"
              translate
              >formErrors.out_of_range_promo_code</mat-error
            >
            <mat-error
              *ngIf="promoCode?.hasError('invalidEntityPromoCodeError')"
              translate
              >formErrors.invalid_entity_promo_code</mat-error
            >
          </mat-form-field>
          <button
            class="h-11 ml-4 mt-1.5 bg-accent text-white p-3 rounded disabled:bg-gray-300 disabled:text-gray-700"
            [disabled]="!promoCodeValue"
            (click)="applyClearPromoCode()"
          >
            <span *ngIf="!validatingPromo && !promoCodeAppliedValue" translate
              >buttons.add</span
            >
            <mat-icon *ngIf="!validatingPromo && promoCodeAppliedValue"
              >close</mat-icon
            >
            <mat-spinner diameter="25" *ngIf="validatingPromo"></mat-spinner>
          </button>
        </div>
        <mat-list-item *ngIf="promoCodeAppliedValue" class="mt-[-15px]">
          <div fxLayout="row" fxLayoutAlign="start center" class="row">
            <div fxFlex="60" class="field-name">
              {{
                'labels.promo_percentage'
                  | translate : { percentage: discountPersentage }
              }}:
            </div>
            <div fxFlex="40" class="value">
              -{{
                getPromoDiscount() || 0
                  | currency : currency : currencySymbols[currency]
              }}
            </div>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </form>

      <ng-container *ngIf="tipForm">
        <form novalidate [formGroup]="tipForm">
          <mat-list-item class="select-tips">
            <div fxFlex="60" class="field-name">
              {{ 'labels.tip' | translate }}:
            </div>
            <mat-radio-group formControlName="tip">
              <mat-list-item
                class="tip-option"
                *ngFor="let option of tips; let i = index"
              >
                <mat-radio-button
                  [value]="option"
                  (click)="uncheckRadio(option)"
                >
                  {{ option }}% ({{
                    (option * getTotalNoTips()) / 100
                      | currency : currency : currencySymbols[currency]
                  }})
                </mat-radio-button>
              </mat-list-item>
            </mat-radio-group>
          </mat-list-item>
        </form>
        <mat-divider></mat-divider>
      </ng-container>

      <mat-list-item class="last">
        <div fxLayout="row" fxLayoutAlign="start center" class="row">
          <div fxFlex="60" class="field-name">
            {{ 'productList.total' | translate }}:
          </div>
          <div fxFlex="40" class="value">
            {{ total | currency : currency : currencySymbols[currency] }}
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
