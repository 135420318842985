<div class="slider-container">
  <span class="label" translate
    >{{ label }}
    <app-info-tooltip
      *ngIf="tooltip"
      [info]="tooltip"
      infoClass="secondary"
    ></app-info-tooltip
  ></span>
  <div fxLayout="row wrap" fxLayoutAlign="space-between center">
    <span class="value-label">{{ control.value }}%</span>
    <mat-slider
      color="accent"
      class="app-slider"
      [max]="max"
      [min]="min"
      [step]="step"
      thumbLabel="true"
      tickInterval="1"
      [formControl]="control"
    >
    </mat-slider>
  </div>
  <mat-error
    *ngIf="control.hasError('min')"
    translate
    [translateParams]="{ value: min }"
    >formErrors.min_value</mat-error
  >
  <mat-error
    *ngIf="control.hasError('max')"
    translate
    [translateParams]="{ value: max }"
    >formErrors.max_value</mat-error
  >
</div>
