import { Component, Input} from '@angular/core';
import { Router } from '@angular/router';
import { RouteConstants } from '../../../../app.constants';

@Component({
  selector: 'app-hub-promo-codes-card',
  templateUrl: './hub-promo-codes-card.component.html',
})
export class HubPromoCodesCardComponent {

  @Input() handler = null;
  constructor(private router: Router) {}

  goTopPromoCode(): void {
    const url = this.handler ?  `${this.handler}/promocodes` : `/${RouteConstants.promocodes}`;
    this.router.navigateByUrl(url);
  }
}

