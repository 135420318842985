import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { HttpService } from '../../service/http.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-available-cities-field',
  templateUrl: './available-cities-field.component.html',
  styleUrls: ['./available-cities-field.component.scss'],
})
export class AvailableCitiesFieldComponent implements OnInit {
  @Input() stateForm: UntypedFormControl;
  @Input() city: UntypedFormControl;
  @Output() onChange: EventEmitter<string> = new EventEmitter();

  private readonly apiUrl = environment.apiUrl;

  filteredOptions: Observable<string[]>;

  constructor(private httpService: HttpService) {}

  ngOnInit() {
    this.filteredOptions = this.stateForm.valueChanges.pipe(
      startWith(null),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((value) => this._filter(value))
    );
  }

  onCitySelected() {
    this.onChange.emit();
  }

  private async _filter(value: string) {
    const filterValue = this.stateForm.value;
    if (!filterValue) {
      return [];
    }

    const response = await this.httpService
      .doGet(
        `${this.apiUrl}/hubs/v1/search?querySearch=address.stateOrProvidence:'${filterValue}' AND unlisted:false AND active:true AND restricted:false&page=0&size=100`
      )
      .toPromise();

    if (response && response.content) {
      const items = new Set();
      for (const HUB of response.content) {
        if (HUB.address.city) {
          items.add(HUB.address.city);
        }
      }

      if (this.city.value) {
        items.add(this.city.value);
      }

      return Array.from(items) as string[];
    } else {
      if (this.city.value) {
        return [this.city.value];
      }
      return [];
    }
  }
}
