import { Component, OnInit } from "@angular/core";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { environment } from "../../../environments/environment";
import { AppSettings } from "../../app.settings";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Location } from "@angular/common";
import { HttpService } from "../../service/http.service";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import {
  faGripVertical,
  faHome,
  faInfoCircle,
  faTimes,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { CanGoBackDialogComponent } from "../can-go-back-dialog/can-go-back-dialog.component";
import { ContentType } from "../../models/content-type-enum";
import { firstValueFrom } from "rxjs";
import {
  NavigationMenuEntity,
  CreateNavigationMenuCommand,
  NavigationMenuItem,
  UpdateNavigationMenuCommand,
} from "../../models/navigation-menu";
import { AuthService } from "../../auth/auth.serviceV2";
import { PageEntity } from "../../models/types";
import { ActCatViewEntity } from "../../models/activity-category-view";
import ActivityIconMap from "../../util/activity-icon-map";
import { NotificationDialogComponent } from "../notification-dialog/notification-dialog.component";
import { MAX_MENU_ITEMS } from "../../util/constants";
import { RouteConstants } from "../../app.constants";

@Component({
  selector: "app-sort-hub-menu-management",
  templateUrl: "./sort-hub-menu-management.component.html",
  styleUrls: ["./sort-hub-menu-management.component.scss"],
})
export class SortHubMenuManagementComponent implements OnInit {
  user = null;
  hubMenu: NavigationMenuEntity;
  items: NavigationMenuItem[] = [];
  originalItems: NavigationMenuItem[] = [];
  filteredItems: NavigationMenuItem[] = [];
  readonly faGripVertical = faGripVertical;
  readonly faHome = faHome;
  readonly faInfoCircle = faInfoCircle;
  readonly faTimes = faTimes;
  readonly faPlus = faPlus;
  readonly activityIconMap = ActivityIconMap;
  readonly apiUrl = environment.apiUrl;
  readonly MAX_MENU_ITEMS = MAX_MENU_ITEMS;

  settings;
  hubId = null;
  categoryId = null;
  handler = null;

  hasChenge: boolean = false;
  canGoOut = false;
  searchField: string = "";

  tryToGoToCreateActivity = false;

  constructor(
    private authService: AuthService,
    public router: Router,
    public httpService: HttpService,
    public appSettings: AppSettings,
    public route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private dialog: MatDialog,
    private location: Location
  ) {
    this.settings = this.appSettings.settings;
  }

  async ngOnInit() {
    this.user = this.authService.getUserProfile();
    this.hubId = this.route.snapshot.queryParams["hubId"];
    this.categoryId = this.route.snapshot.queryParams["categoryId"];
    this.handler = this.route.snapshot.queryParams["handler"];
    this.settings.loadingSpinner = true;
    await this.retrieveMenuListContent();
    await this.retrieveContent();
    this.settings.loadingSpinner = false;
  }

  clearSearchField() {
    this.searchField = "";
    this.filterItems();
  }

  openDialog(): void {
    this.dialog.open(NotificationDialogComponent, {
      minWidth: "240px",
      data: {
        title: this.translate.instant("titles.update_navigation_menu"),
        content: this.translate.instant("messages.max_menu_items", {
          count: MAX_MENU_ITEMS,
        }),
      },
    });
  }

  filterDrop(event: CdkDragDrop<NavigationMenuItem[]>) {
    if (this.items.length >= MAX_MENU_ITEMS) {
      this.openDialog();
      return;
    }
    this.drop(event);
  }

  drop(event: CdkDragDrop<NavigationMenuItem[]>) {
    if (event.previousContainer === event.container) {
      if (event.previousIndex === event.currentIndex) {
        return;
      }
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    if (event.previousIndex > event.currentIndex) {
      for (let i = event.currentIndex; i <= event.previousIndex; i++) {
        const current = event.container.data[i];
        if (current) {
          current.position = i;
        }
        
      }
    } else {
      for (let i = event.currentIndex; i >= event.previousIndex; i--) {
        const current = event.container.data[i];
        if (current) {
          current.position = i;
        }
      }
    }
    this.filterItems();
    this.hasChenge = true;
  }

  add(previousIndex: number) {
    if (this.items.length >= MAX_MENU_ITEMS) return;
    transferArrayItem(
      this.filteredItems,
      this.items,
      previousIndex,
      this.items.length
    );
    for (let i = 0; i < this.items.length; i++) {
      const current = this.items[i];
      current.position = i;
    }
    this.filterItems();
    this.hasChenge = true;
  }

  remove(previousIndex: number) {
    transferArrayItem(this.items, this.filteredItems, previousIndex, 0);
    for (let i = 0; i < this.items.length; i++) {
      const current = this.items[i];
      current.position = i;
    }
    this.filterItems();
    this.hasChenge = true;
  }

  back() {
    this.tryToGoToCreateActivity = false;
    this.location.back();
  }

  canGoBack(): boolean {
    if (!this.hasChenge || this.canGoOut) {
      return true;
    }
    const dialogRef = this.dialog.open(CanGoBackDialogComponent, {
      minWidth: "240px",
      data: {
        title: "titles.can_go_back",
        content: "messages.can_go_back",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.canGoOut = true;
        if (this.tryToGoToCreateActivity) {
          this.goToCreateActivity();
        } else {
          this.back();
        }
      }
    });
    return false;
  }

  private async retrieveMenuListContent() {
    try {
      const URL = `${this.apiUrl}/navigation-menu/v1/${this.hubId}`;
      const response: NavigationMenuEntity = await firstValueFrom(
        this.httpService.doGet(URL)
      );
      this.hubMenu = response;
      this.items = response.items;
    } catch (error) {
      if (error && error.status === 404) {
        await this.createActivityListBar();
      } else {
        console.error("Error getting Activity List Bar: ", error);
      }
    }
  }

  private async createActivityListBar() {
    try {
      const URL = `${this.apiUrl}/navigation-menu/v1`;
      const requestBody: CreateNavigationMenuCommand = {
        hubId: this.hubId,
        userId: this.user.userId,
        items: [],
      };
      const response: NavigationMenuEntity = await firstValueFrom(
        this.httpService.doPost(URL, requestBody)
      );
      this.hubMenu = response;
      this.items = response.items;
    } catch (error) {
      console.error("Error creating Activity List Bar: ", error);
    }
  }

  private async retrieveContent() {
    try {
      let URL = `${this.apiUrl}/actcatview/v1/client-search?page=0&size=500&querySearch=hubId:'${this.hubId}'&sort=order,asc`;
      if (this.categoryId) {
        URL = `${this.apiUrl}/actcatview/v1/client-search?page=0&size=500&querySearch=hubId:'${this.hubId}' AND contentType!'${ContentType.CATEGORY}' AND content.categoryId:'${this.categoryId}'&sort=order,asc`;
      }
      const response: PageEntity<ActCatViewEntity> = await firstValueFrom(
        this.httpService.doGet(URL)
      );
      if (response.content && response.content.length > 0) {
        this.originalItems = response.content.map((originalItem) => ({
          type: originalItem.contentType,
          activityId: originalItem.id,
          activityName: originalItem.content.name,
          position: 0,
        }));
        this.filterItems();
      }
    } catch (error) {
      console.error("Error getting ActCat: ", error);
    }
  }

  async save() {
    this.settings.loadingSpinner = true;
    try {
      const URL = `${this.apiUrl}/navigation-menu/v1/`;
      const body: UpdateNavigationMenuCommand = {
        id: this.hubMenu.id,
        hubId: this.hubId,
        userId: this.user.userId,
        items: this.items,
      };
      await firstValueFrom(this.httpService.doPut(URL, body));
      this.hasChenge = false;
      this.showSnackBar(
        this.translate.instant("messages.navigation_menu_saved"),
        "OK",
        2000
      );
    } catch (error) {
      console.error("Error getting ActCat: ", error);
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  filterItems() {
    let temItems = [...this.originalItems];
    if (this.searchField.length > 2) {
      const localSearchField = this.searchField.toLocaleLowerCase();
      temItems = temItems.filter((item: NavigationMenuItem) => {
        if (
          !item.activityName.toLocaleLowerCase().startsWith(localSearchField)
        ) {
          return false;
        }
        return true;
      });
    }
    this.filteredItems = temItems.filter((item: NavigationMenuItem) => {
      return !this.items.find(
        (innerItem: NavigationMenuItem) =>
          innerItem.activityId === item.activityId
      );
    });
  }

  showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  goToCreateActivity(): void {
    const start = this.handler ?  `${this.handler}/activity` : `/${RouteConstants.activity}`;
    let url = `/${start}/manage?hubId=${this.hubId}`;
    if (this.handler) {
      url += `&handler=${this.handler}`;
    }
    this.tryToGoToCreateActivity = true;
    this.router.navigateByUrl(url);
  }
}
