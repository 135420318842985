<mat-card [id]="item.id" class="item-card" [ngClass]="{'expanded' : selected}">
    <div *ngIf="!selected" (click)="selectItem()" class="closed">
        <mat-card-header class="header">
            <img mat-card-avatar class="avatar" [src]="item.images[0]" alt="product"/>
            <mat-card-title>{{item.name}}</mat-card-title>
            <mat-card-subtitle class="description">{{item.infoText}}</mat-card-subtitle>
            <mat-card-subtitle fxLayout="row nowrap" class="variant-subtitle">
                <span class="variant" translate [translateParams]="{value: item.options.length}">productList.has_variant</span>&nbsp;
            </mat-card-subtitle> 
        </mat-card-header>
    </div>

    <div *ngIf="selected" class="opened">
        <mat-card-content class="content">
            <app-swiper-management *ngIf="images && selectedForm" [images]="images" [form]="selectedForm" [square]="true">
            </app-swiper-management>
            <mat-card-header class="header">
                <mat-card-title>
                    <mat-form-field class="item-name">
                        <input matInput 
                            placeholder="{{'productList.product_name' | translate}}" 
                            [formControl]="name">
                        <mat-error *ngIf="selectedForm.get('name').hasError('required')" 
                            translate>
                            productList.error_product
                        </mat-error>
                    </mat-form-field>
                </mat-card-title>

                <mat-card-subtitle class="description">
                    <mat-form-field>
                        <textarea matInput matTextareaAutosize 
                        placeholder="{{'productList.description' | translate}}" 
                        [formControl]="infoText"></textarea>
                        <mat-error *ngIf="selectedForm.get('infoText').hasError('required')" translate>productList.error_description</mat-error>
                    </mat-form-field>
                </mat-card-subtitle>
            </mat-card-header>
            <div class="variant-header" fxLayout="row nowrap" fxLayoutAlign="space-between center">
                <span fxFlex="58" translate>productList.variant_name</span>
                <span fxFlex="32" translate>productList.price</span>
                <span fxFlex="10"></span>
            </div>
            <mat-card-subtitle 
                *ngFor="let variant of getVariantsControls(); let i = index; let length = count;" 
                class="variant" 
                [formGroup]="variant"
                fxLayout="row nowrap" 
                fxLayoutAlign="space-between center">

                <mat-checkbox formControlName="available" color="accent">
                </mat-checkbox>
                
                <mat-form-field fxFlex="58" class="variant-name" floatLabel='never' color="primary">
                    <input matInput 
                    placeholder="{{variant.value.description}}" 
                    formControlName="description" 
                    (focus)="focusOption(variant)"
                    autocomplete="off">
                    <mat-error *ngIf="variant.get('description').hasError('required')" translate>productList.error_required_variant</mat-error>
                    <mat-error *ngIf="variant.get('description').hasError('unique')" translate>productList.error_unique_variant</mat-error>
                </mat-form-field>
        
                <mat-form-field fxFlex="32" class="variant-price" floatLabel='never' color="primary">
                    <input type="number"
                        matInput 
                        formControlName="price">
                        <span matPrefix>$&nbsp;</span>
                    <mat-error *ngIf="variant.get('price').hasError('required')" translate>productList.error_price</mat-error>
                </mat-form-field>
         
                <button fxFlex="10" class="variant-close" type="button" (click)="removeOption(i)" mat-icon-button>
                    <mat-icon *ngIf="length > 1 && !loading" >close</mat-icon>
                </button>
            </mat-card-subtitle>
            <button *ngIf="!loading && getVariants().length < maxVariants" (click)="addOption()" mat-button color="accent"><span translate>productList.add_variant</span></button>
        </mat-card-content>
        
        <app-product-tag-picker [items]="getTags()">
        </app-product-tag-picker>

        <mat-form-field class="product-link">
            <input matInput placeholder="{{'formFields.product_link' | translate}}" [formControl]="links" autocomplete="off">
            <fa-icon matPrefix class="front-icon" [icon]="faLink"></fa-icon>
            <mat-error *ngIf="selectedForm.get('links').hasError('pattern')" translate>formErrors.url_invalid</mat-error>
        </mat-form-field>

        <mat-card-actions class="footer">
            <app-warning-message *ngIf="!active.value"
                message="messages.inactive_product">
            </app-warning-message>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <button [disabled]="loading || item.id === 'new-item-template'" 
                    class="remove-button" 
                    type="button" 
                    (click)="canDelete()" 
                    mat-icon-button color="warn">
                    <mat-icon [ngClass]="{'delete-icon': !loading && item.id !== 'new-item-template'}">
                        delete_forever
                    </mat-icon>
                </button>
                <mat-slide-toggle color="accent" [formControl]="available">
                    <span translate>formFields.available</span>
                </mat-slide-toggle>
                <mat-slide-toggle color="accent" [formControl]="active">
                    <span translate>productList.active</span>
                </mat-slide-toggle>
            </div>
        </mat-card-actions>

        <mat-card-actions fxLayout="row" fxLayoutAlign="space-between center">
            <button [disabled]="loading" color="primary" mat-raised-button (click)="cancel()">
                <span  translate>tinyAll.cancel</span>&nbsp;
            </button>
            <button *ngIf="!loading" [disabled]="!selectedForm.dirty" color="accent" mat-raised-button (click)="save()">
                <span  translate>tinyAll.save</span>&nbsp;
                <mat-icon>save</mat-icon>
            </button>
            <div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="loading">
                <span class="spinner-text" translate>productList.saving_product</span>&nbsp;
                <mat-spinner  class="button-spinner" diameter="30"></mat-spinner>
            </div>
        </mat-card-actions>
    </div>
</mat-card>