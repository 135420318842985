import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RouteConstants } from '../../app.constants';
import { AuthService } from '../../auth/auth.serviceV2';
import { TermsDialogComponent } from '../terms-dialog/terms-dialog.component';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-payment-methods-footer',
  templateUrl: './payment-methods-footer.component.html',
  styleUrls: ['./payment-methods-footer.component.scss'],
})
export class PaymentMethodsFooterComponent implements OnInit {

  @Input() showTermAndConditions: boolean = true;
  user;

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.user = this.authService.getUserProfile();
  }

  openTerms() {
    if (this.user.termOfServiceAgree) {
      this.router.navigate([RouteConstants.auth, 'terms']);
    } else {
      this.dialog.open(TermsDialogComponent, {
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw',
        maxHeight: '100vh',
        panelClass: 'terms-dialog',
      });
    }
  }

  getTermsUrl(): void {
    const user = this.authService.getUserProfile();
    const lang =
      user && user.language ? user.language : this.translate.getBrowserLang();
    const id = lang === 'en' ? environment.termsAndConditionsEn : environment.termsAndConditionsEs;
    window.open(`${environment.appUrl}/su/a/${id}`, '_blank');
  }

  getPrivacyPolicyUrl(): void {
    const user = this.authService.getUserProfile();
    const lang =
      user && user.language ? user.language : this.translate.getBrowserLang();
    const id = lang === 'en' ? environment.privacyPolicyEn : environment.privacyPolicyEs;
    window.open(`${environment.appUrl}/su/a/${id}`, '_blank');
  }
}
