import { Pipe, PipeTransform } from '@angular/core';
import { parseISO, formatDistanceToNowStrict } from 'date-fns';
import esLocale from 'date-fns/locale/es/index.js';
import enLocale from 'date-fns/locale/en-US/index.js';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'timeAgoStrictStatic',
})
export class TimeAgoStrictStaticPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(
    value: Date | string | number,
    unit: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year' = 'day',
    method: 'floor' | 'ceil' | 'round' = 'floor'
  ): any {
    if (!value) return '';
    let dateValue: Date | number;
    if(typeof value === 'string') {
        dateValue = parseISO(value);
    } else {
        dateValue = value as Date | number;
    }
    return formatDistanceToNowStrict(dateValue, {
      addSuffix: true,
      unit: unit,
      roundingMethod: method,
      locale: this.translate.getDefaultLang() === 'en' ? enLocale : esLocale,
    });
  }
}
