import { Component, Input} from '@angular/core';
import { 
  faLink
 } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-custom-links-client-view',
  templateUrl: './custom-links-client-view.component.html',
  styleUrls: ['./custom-links-client-view.component.scss']
})
export class CustomLinksClientViewComponent {

  @Input() customLinks;

  get links(): any[] {
    if (!this.customLinks) {
      return [];
    }
    return Object.keys(this.customLinks);
  }

  readonly faLink = faLink; 

  constructor() {}


}
