import {
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Component, Input } from '@angular/core';
import { Country } from '@angular-material-extensions/select-country';
import { getCountries } from '../../util/countries';
import { TranslateService } from '@ngx-translate/core';
import { LocationPickerDialogComponent } from '../location-picker-dialog/location-picker-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent {
  predefinedCountries: Country[] = [];
  selectedCountry = null;

  @Input() form: UntypedFormGroup;
  @Input() location: UntypedFormControl;
  @Input() addressForm: UntypedFormGroup;
  @Input() alwaysOpen: boolean = false;

  constructor(public translate: TranslateService, public dialog: MatDialog,) {}

  ngOnInit() {
    this.predefinedCountries = getCountries(this.translate.getDefaultLang());
  }

  get name() {
    return this.form.get('name') as UntypedFormControl;
  }


  get country() {
    return this.addressForm.get('country') as UntypedFormControl;
  }

  get stateOrProvidence() {
    return this.addressForm.get('stateOrProvidence') as UntypedFormControl;
  }

  get city() {
    return this.addressForm.get('city') as UntypedFormControl;
  }

  get address() {
    return this.addressForm.get('address');
  }

  onCountrySelected(country: Country) {
    this.country.setValue(country.alpha3Code);
    this.selectedCountry = country;
    this.addressForm.markAsDirty();
  }

  openMap() {
    const dialogRef = this.dialog.open(LocationPickerDialogComponent, {
      width: "100vw",
      height: "100vh",
      maxWidth: "100vw",
      maxHeight: "100vh",
      panelClass: "location-picker-dialog",
      data: {
        location: this.location.value,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.location.setValue(result.location);
        this.location.markAsDirty();
        if (!this.address.value && result.address) {
          this.address.setValue(result.address);
          this.address.markAsDirty();
        }
      }
    });
  }

  addressValidateError(): boolean {
    return (
      this.country.hasError('required') ||
      this.stateOrProvidence.hasError('required') ||
      this.city.hasError('required') ||
      this.address.hasError('required')
    );
  }
  
}
