import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'hub-payment-card',
  templateUrl: './hub-payment-card.component.html',
})
export class HubPaymentCardComponent {

  @Input() handler = null;
  constructor(private router: Router) {}

  gotoPayments(): void {
    // TODO: TinyAll Pay
    // this.router.navigate([this.handler, 'pay', 'pay-transactions']);
    this.router.navigate([this.handler, 'pay', 'payment-methods']);
  }
}
