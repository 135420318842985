import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-mature-content-dialog',
  templateUrl: './mature-content-dialog.component.html',
  styleUrls: ['./mature-content-dialog.component.scss']
})
export class MatureContentDialogComponent { 
    constructor(
      public dialogRef: MatDialogRef<MatureContentDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {}
  
    cancel(): void {
      this.dialogRef.close();
    }
    ok(): void {
      this.dialogRef.close(true);
    }
  
  }