<div fxLayout="row" *ngIf="show">
  <h2 class="title" translate>titles.select_service_type</h2>
</div>

<div fxLayout="row wrap" fxLayoutAlign="center start" *ngIf="show">
  <div class="c-flex-p" fxFlex="100">
    <mat-card class="select-card">
      <mat-card-content>
        <form novalidate *ngIf="form" [formGroup]="form">
          <mat-radio-group
            formControlName="serviceType"
            fxLayout="column"
            fxLayoutGap="10px"
            fxLayoutAlign="center"
            (change)="changeServiceType($event)"
          >
            <div
              class="select-options"
              *ngIf="isServiceTypeSupported(MenuServiceType.RESTAURANT)"
            >
              <mat-radio-button [value]="MenuServiceType.RESTAURANT" mb-6>
                <span translate>formFields.in_restaurant</span>
                <fa-icon class="method-icon" [icon]="faUtensils"></fa-icon>
              </mat-radio-button>
              <mat-divider></mat-divider>
            </div>
            <div
              class="select-options"
              *ngIf="isServiceTypeSupported(MenuServiceType.PICKUP)"
            >
              <mat-radio-button [value]="MenuServiceType.PICKUP" mb-6>
                <span translate>formFields.pick_up</span>
                <fa-icon class="method-icon" [icon]="faShoppingBag"></fa-icon>
              </mat-radio-button>
              <mat-divider></mat-divider>
            </div>
            <div
              class="select-options"
              *ngIf="isServiceTypeSupported(MenuServiceType.DELIVERY)"
            >
              <mat-radio-button [value]="MenuServiceType.DELIVERY" mb-6>
                <span translate>formFields.delivery</span>
                <fa-icon class="method-icon" [icon]="faBiking"></fa-icon>
              </mat-radio-button>
            </div>
          </mat-radio-group>
        </form>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="selectedServiceType.value === MenuServiceType.RESTAURANT && activityMenu.tableRequired" class="location-card-container" class="p-4 mt-2">
      <form novalidate *ngIf="form" [formGroup]="form">
        <h2 class="font-bold" translate>formFields.table_number</h2>
        <mat-form-field appearance="outline" class="w-full h-16">
          <input matInput type="number" formControlName="tableNumber">
        </mat-form-field>
        <mat-error *ngIf="form.hasError('tableNumberMin')" translate>formErrors.table_number_required</mat-error>
        <mat-error *ngIf="form.hasError('tableNumberRequired')" translate>formErrors.table_number_required</mat-error>
      </form>
    </mat-card>

    <mat-card *ngIf="choosenLocationValue" class="location-card-container" (click)="openAddressPicker()">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="p-1">
          <span class="label">{{ choosenLocationValue.name }}</span>
          <p>
            {{ choosenLocationValue.address.address }},
            {{ choosenLocationValue.address.address2 }}
          </p>
          <p>
            {{ choosenLocationValue.address.city }},
            {{ choosenLocationValue.address.stateOrProvidence }},
            {{ getCountryName(choosenLocationValue.address.country) }}
          </p>
          <p>{{ choosenLocationValue.address.zipCode }}</p>
          <p>{{ choosenLocationValue.address.locationHint }}</p>
        </div>
        <mat-icon>check</mat-icon>
      </div>
    </mat-card>

    <button
      *ngIf="
        selectedServiceType &&
        selectedServiceType.value === MenuServiceType.DELIVERY &&
        !choosenLocationValue
      "
      (click)="openAddressForm()"
      mat-raised-button
      color="accent"
    >
      <span translate>buttons.add_a_new_location</span>
    </button>
  </div>
</div>
