import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { ServiceModule } from '../../service/service.module';
import { AuthGuard } from '../../auth/auth.guard';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ActivityEventClientViewComponent } from './activity-event-client-view/activity-event-client-view.component';
import { ActivityEventManagementComponent } from './activity-event-management/activity-event-management.component';
import { EventRequestListClientViewComponent } from './components/event-request-list-client-view/event-request-list-client-view.component';
import { ComponentsModule } from '../../components/componets.module';
import { FormGuard } from '../../guards/form.guard';
import { ActivityEventScannerComponent } from './activity-event-scanner/activity-event-scanner.component';
import { EventStatsCardComponent } from './components/event-stats-card/event-stats-card.component';
import { EventStatsDialogComponent } from './components/event-stats-dialog/event-stats-dialog.component';
import { PipesModule } from '../../theme/pipes/pipes.module';
import { EventRequestListClientViewDialogComponent } from './components/event-request-list-client-view-dialog/event-request-list-client-view-dialog.component';
import { EventRequestDetailsClientViewComponent } from './components/event-request-details-client-view/event-request-details-client-view.component';
import { EventRequestDetailsClientViewDialogComponent } from './components/event-request-details-client-view-dialog/event-request-details-client-view-dialog.component';

export const routes = [
  { path: 'client-view', component: ActivityEventClientViewComponent },
  {
    path: 'manage',
    component: ActivityEventManagementComponent,
    canActivate: [AuthGuard],
    canDeactivate: [FormGuard],
  },
  {
    path: 'scanner',
    component: ActivityEventScannerComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ComponentsModule,
    ServiceModule,
    RouterModule.forChild(routes),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    PipesModule,
  ],
  providers: [],
  declarations: [
    ActivityEventClientViewComponent,
    ActivityEventManagementComponent,
    ActivityEventScannerComponent,
    EventRequestListClientViewComponent,
    EventStatsCardComponent,
    EventStatsDialogComponent,
    EventRequestListClientViewDialogComponent,
    EventRequestDetailsClientViewComponent,
    EventRequestDetailsClientViewDialogComponent,
  ],
  exports: [
    ActivityEventManagementComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ActivityEventModule {}
