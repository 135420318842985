import { AngularEditorConfig,  } from '@kolkov/angular-editor';

export const editConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    enableToolbar: true,
    showToolbar: true,
    //uploadWithCredentials: false,
    sanitize: false,
    outline: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
        [
            //'undo',
            //'redo',
            //'bold',
            //'italic',
            //'underline',
            //'strikeThrough',
            //'subscript',
            //'superscript',
            //'justifyLeft',
            //'justifyCenter',
            //'justifyRight',
            //'justifyFull',
            //'indent',
            //'outdent',
            //'insertUnorderedList',
            //'insertOrderedList',
            //'heading',
            //'fontName'
        ],
        [
            //'fontSize',
            //'textColor',
            //'backgroundColor',
            'customClasses',
            //'link',
            //'unlink',
            //'insertImage',
            'insertVideo',
            //'insertHorizontalRule',
            //'removeFormat',
            'toggleEditorMode'
        ]
    ]
};

export const readConfig: AngularEditorConfig = {
    editable: false,
    spellcheck: false,
    enableToolbar: false,
    showToolbar: false,
    sanitize: false,
    outline: false,
};