<div fxLayout="row wrap" fxLayoutAlign="center start">
  <div fxFlex="100">
    <mat-card class="bank-card">
      <div
        class="card-title"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <h2 translate>labels.payment_link</h2>
        <fa-icon class="button-icon" [icon]="faLink"></fa-icon>
      </div>
      <mat-card-content>
        <P translate> messages.payment_link_order_message </P>
        <form
          novalidate
          *ngIf="(!currentPaymentLink || editMode) && form"
          [formGroup]="form"
        >
          <div class="flex mt-3 mb-4">
            <mat-form-field appearance="outline" class="w-full h-16">
              <input
                matInput
                formControlName="paymentLink"
                placeholder="{{ 'labels.payment_link' | translate }}"
                class="text-base"
              />
              <mat-error
                *ngIf="
                  paymentLink.hasError('required') ||
                  paymentLink.hasError('url')
                "
                translate
                >formErrors.payment_link</mat-error
              >
            </mat-form-field>
            <button
              class="h-11 ml-4 mt-1.5 bg-accent text-white p-3 rounded disabled:bg-gray-300 disabled:text-gray-700"
              (click)="addUpdatePaymentLink()"
            >
              <span *ngIf="!loading" translate>buttons.add</span>
              <mat-spinner diameter="25" *ngIf="loading"></mat-spinner>
            </button>
          </div>
        </form>

        <div class="flex mt-3 mb-4" *ngIf="currentPaymentLink && !editMode">
          <p class="text-blue-500 cursor-pointer mt-2 w-full">
            {{ currentPaymentLink }}
          </p>
          <button
            class="h-11 ml-4 mt-1.5 bg-accent text-white p-3 rounded disabled:bg-gray-300 disabled:text-gray-700"
            (click)="editPaymentLink()"
          >
            <span *ngIf="!loading" translate>buttons.edit</span>
            <mat-spinner diameter="25" *ngIf="loading"></mat-spinner>
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
