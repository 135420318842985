export enum EntityType {
    ACTIVITY_INFO = 'ACTIVITY_INFO', 
    ACTIVITY_MENU = 'ACTIVITY_MENU', 
    ACTIVITY_PRODUCT_LISTING = 'ACTIVITY_PRODUCT_LISTING',
    ACTIVITY_REQUEST = 'ACTIVITY_REQUEST', 
    ACTIVITY_RESERVATION = 'ACTIVITY_RESERVATION', 
    ACTIVITY_EVENT = 'ACTIVITY_EVENT',
    CATEGORY = 'CATEGORY', 
    CATEGORY_ACCESS = 'CATEGORY_ACCESS', 
    HUB = 'HUB', 
    MENU_REQUEST = 'MENU_REQUEST', 
    REQUEST = 'REQUEST', 
    RESERVATION = 'RESERVATION', 
    SHOPPING = 'SHOPPING',
    REVIEW = 'REVIEW'
}
