import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../../app.settings';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.serviceV2';
import { UpdateUserCommand, UserEntity } from '../../models/user';
import { firstValueFrom } from 'rxjs';
import { ActivityInfoEntity } from '../../models/activity-info';
import { HttpService } from '../../service/http.service';

@Component({
  selector: 'app-terms-dialog',
  templateUrl: './terms-dialog.component.html',
  styleUrls: ['./terms-dialog.component.scss'],
})
export class TermsDialogComponent implements OnInit {
  entity: ActivityInfoEntity;
  settings: any;
  public checkBoxForm: UntypedFormGroup;
  private readonly apiUrl = environment.apiUrl;

  constructor(
    public http: HttpClient,
    private httpService: HttpService,
    private authService: AuthService,
    protected appSettings: AppSettings,
    protected snackBar: MatSnackBar,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<TermsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.settings = this.appSettings.settings;
  }

  async ngOnInit() {
    this.settings.loadingSpinner = true;
    this.checkBoxForm = new UntypedFormGroup({
      notifiableFromPromo: new UntypedFormControl(true, []),
      termOfServiceAgree: new UntypedFormControl(false, [
        Validators.requiredTrue,
      ]),
    });

    await this.initPage();

    setTimeout(() => {
      const el = document.querySelector('.terms-dialog>mat-dialog-container');
      if (el) {
        el.scrollTop = 0;
      }
    }, 300);
  }

  private async initPage() {
    const user = this.authService.getUserProfile();
    const lang =
      user && user.language ? user.language : this.translate.getBrowserLang();
    const id =
      lang === 'en'
        ? environment.termsAndConditionsEn
        : environment.termsAndConditionsEs;
    try {
      const response: ActivityInfoEntity = await firstValueFrom(
        this.httpService.doGet(`${environment.apiUrl}/activity-info/v1/${id}`)
      );
      this.entity = response;
    } catch (error) {
      console.log('Error getting terms and conditions: ', error);
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  async accept() {
    await this.update();
  }

  async close() {
    this.showSnackBar(
      this.translate.instant('messages.no_accepted_terms'),
      'OK',
      200000
    );
    this.dialogRef.close();
    await this.authService.logout();
  }

  async update() {
    this.settings.loadingSpinner = true;
    const userProfile = this.authService.getUserProfile();
    const rawValues = this.checkBoxForm.getRawValue();
    const {
      userId,
      cellPhone,
      email,
      name,
      picture,
      language,
      notifiableFromClients,
      notifiableFromHubs,
      notifiableFromPromo,
    } = userProfile;
    const requestBody: UpdateUserCommand = {
      userId,
      cellPhone,
      email,
      name,
      picture,
      language,
      notifiableFromClients,
      notifiableFromHubs,
      notifiableFromPromo,
      ...rawValues,
      termOfServiceAgree: true,
    };
    const url = `${this.apiUrl}/users/v1`;
    const tokenId = this.authService.getIdToken();
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + tokenId,
      Accept: 'application/json',
    });
    try {
      const user: UserEntity = await firstValueFrom(
        this.http.put<UserEntity>(url, JSON.stringify(requestBody), {
          headers: httpHeaders,
        })
      );
      localStorage.setItem('user_profile', JSON.stringify(user));
      this.showSnackBar(this.translate.instant('messages.welcome'), 'OK', 2000);
      this.dialogRef.close();
    } catch (error) {
      console.error(error);
      this.showSnackBar(
        this.translate.instant('messages.error_processing_request'),
        'OK',
        2000
      );
    }

    this.settings.loadingSpinner = false;
  }

  showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }
}
