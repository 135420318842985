<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="title" translate>titles.pick_a_location</h2>
    <button (click)="save()" color="accent" mat-raised-button><span translate>tinyAll.save</span></button>
</div>

<div class="page-container" fxLayout="row wrap" fxLayoutAlign="center start">
    <div id="box-map"></div>
</div>


