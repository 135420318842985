<div class="header no-print" fxLayout="row" fxLayoutAlign="space-between center">
    <button (click)="close()" mat-button><span translate>tinyAll.back</span></button>
    <h2 class="title" translate>titles.event_stats</h2>
</div>

<div class="page-container" fxLayout="column wrap" fxLayoutAlign="center center">
    <div class="charts-container">
        <ngx-charts-pie-chart
            [results]="parentData"
            [doughnut]="true"
            [arcWidth]="0.35"
        >
        </ngx-charts-pie-chart>
    </div>
    <table class="stats-table">
        <tbody>
            <tr class="sold">
                <td><span class="color"></span></td>
                <td><span class="label" translate>labels.sold</span></td>
                <td><span class="value">{{parentData[1].value}}</span></td>
            </tr>
            <tr class="remaining">
                <td><span class="color"></span></td>
                <td><span class="label" translate>labels.remaining</span></td>
                <td><span class="value">{{parentData[0].value}}</span></td>
            </tr>
            <tr class="total">
                <td><span class="color"></span></td>
                <td><span class="label" translate>labels.total</span></td>
                <td><span class="value">{{total}}</span></td>
            </tr>
        </table>
</div>
