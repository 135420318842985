import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormArray,
  Validators,
} from "@angular/forms";
import { RxwebValidators } from "@rxweb/reactive-form-validators";
import { AppSettings } from "../../../app.settings";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpService } from "../../../service/http.service";
import { ImageService } from "../../../service/image.service";
import { BaseFormComponent } from "../../baseform.component";
import { AuthService } from "../../../auth/auth.serviceV2";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { DatetimeAdapter } from "@mat-datetimepicker/core";
import { MatTabGroup } from "@angular/material/tabs";
import { ProductListManagementComponent } from "../components/product-list-management/product-list-management.component";
import QuestionsManagementUtil from "../../../util/questionsManagementUtil";
import AdditionalChargesUtil from "../../../util/additionalChargesUtil";
import { ContentType } from "../../../models/content-type-enum";
import { RichTextEditorComponent } from "../../../components/rich-text-editor/rich-text-editor.component";
import { ShortUrlHandlerComponent } from "../../../components/short-url-handler/short-url-handler.component";
import { setDefaultPageHead } from "../../../util/seo-utils";
import { Meta, Title } from "@angular/platform-browser";
import {
  ActivityProductListingEntity,
  CreateActivityProductListingCommand,
  UpdateActivityProductListingCommand,
} from "../../../models/activity-product-listing";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-activity-product-listing-management",
  templateUrl: "./activity-product-listing-management.component.html",
  styleUrls: ["./activity-product-listing-management.component.scss"],
})
export class ActivityProductListingManagementComponent
  extends BaseFormComponent
  implements OnInit
{
  @ViewChild("productListManager")
  productListManagerRef: ProductListManagementComponent;
  @ViewChild("tabs", { static: false }) tabs: MatTabGroup;

  @Input() hubIdParam: string;
  @Input() categoryIdParam: string;
  @Input() activityIdParam: string;
  @Output() next: EventEmitter<any> = new EventEmitter();
  
  questionsForm: UntypedFormArray;
  additionalChargesForm: UntypedFormArray;
  trackingStatusesForm: UntypedFormArray;
  selectedInnerQuestion: Map<number, Map<number, Set<number>>> = new Map();
  selectedQuestion: number;
  optionName: string;
  activityType = "activity-product-listing";
  private scrolls = [0, 0, 0];
  private mainContent;

  constructor(
    private authService: AuthService,
    public translate: TranslateService,
    public fb: UntypedFormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    public httpService: HttpService,
    public appSettings: AppSettings,
    public snackBar: MatSnackBar,
    public imageService: ImageService,
    public dialog: MatDialog,
    public _adapter: DatetimeAdapter<Date>,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    super(
      httpService,
      appSettings,
      snackBar,
      imageService,
      router,
      dialog,
      translate
    );
    this.optionName = this.translate.instant("questionsDialog.option");
  }

  async ngOnInit() {
    this.settings.loadingSpinner = true;
    setDefaultPageHead(this.titleService, this.metaTagService, false);
    await this.authService.isAuthenticated();
    this.user = this.authService.getUserProfile();
    this.hubId = this.route.snapshot.queryParams["hubId"] || this.hubIdParam;
    this.categoryId = this.route.snapshot.queryParams["categoryId"] || this.categoryIdParam;
    this.activityId = this.route.snapshot.queryParams["activityId"] || this.activityIdParam;
    this.originId = this.route.snapshot.queryParams["originId"];
    this.handler = this.route.snapshot.queryParams["handler"];
    this.mainContent = document.getElementById("main-content");

    this.form = this.fb.group({
      id: [],
      hubId: [this.hubId],
      userId: [this.user?.userId],
      categoryId: [this.categoryId ? this.categoryId : ""],
      name: ["", Validators.required],
      order: [0, [Validators.required, RxwebValidators.numeric()]],
      active: [true],
      unListed: [false],
      includeInMenu: [true],
      root: [!this.categoryId],
      infoText: ["", Validators.required],
      newRequestMessage: [""],
      gpsRequired: [false],
      phoneNumberRequired: [false],
      addressRequired: [false],
      clonable: [false],
      questions: this.fb.array([]),
      additionalCharges: this.fb.array([]),
      createdOn: [""],
      updatedOn: [""],
      currency: ["DOP", Validators.required],
      trackingStatuses: this.fb.array([]),
    });

    this.questionsForm = this.form.get("questions") as UntypedFormArray;
    this.additionalChargesForm = this.form.get(
      "additionalCharges"
    ) as UntypedFormArray;
    this.trackingStatusesForm = this.form.get("trackingStatuses") as UntypedFormArray;

    await this.setIsHubNavigationMenuFull();

    if (this.activityId || this.originId) {
      const url = `${this.apiUrl}/activity-product-listing/v1/${this.activityId ? this.activityId : this.originId}`;
      try {
        let response: ActivityProductListingEntity = await firstValueFrom(
          this.httpService.doGet(url)
        );
        if (this.activityId) {
          this.activityId = response.id;
          this.hubId = response.hubId;
          this.categoryId = response.categoryId;
        } else {
          response = {
            ...response,
            id: null,
            hubId: this.hubId,
            userId: this.user?.userId,
            categoryId: this.categoryId ? this.categoryId : "",
            active: false,
          };
        }
        this.setForm(response);
        if (response.images && response.images.length > 0) {
          this.images = response.images;
        } else {
          this.images = [""];
        }
        if (this.originId) {
          await this.cloneImages();
          this.removeOriginId();
        }
        setTimeout(() => {
          this.settings.loadingSpinner = false;
        }, 300);
      } catch (error) {
        this.settings.loadingSpinner = false;
        console.log("Error getting activity: ", error);
      }
    } else {
      this.images = [""];
      if (this.isHubNavigationMenuFull) {
        this.includeInMenu.disable();
        this.form.patchValue({ includeInMenu: false });
        this.showNavigationMenuLink = true;
      }
      this.settings.loadingSpinner = false;
    }
  }

  get name(): UntypedFormControl {
    return this.form.get("name") as UntypedFormControl;
  }

  get info(): UntypedFormControl {
    return this.form.get("infoText") as UntypedFormControl;
  }

  get newRequestMessage(): UntypedFormControl {
    return this.form.get("newRequestMessage") as UntypedFormControl;
  }

  get active() {
    return this.form.get("active");
  }

  get unListed() {
    return this.form.get("unListed");
  }

  get order() {
    return this.form.get("order");
  }

  get includeInMenu() {
    return this.form.get("includeInMenu");
  }

  get currency() {
    return this.form.get("currency") as UntypedFormControl;
  }

  get getQuestions(): UntypedFormArray {
    return this.form.get("questions") as UntypedFormArray;
  }

  get qrCode() {
    if (!this.handler || !this.hubId || !this.activityId) {
      return false;
    }
    return ShortUrlHandlerComponent.shortActCatUrl(this.activityId);
  }

  canGoBack(): boolean {
    this.forceWarming = this.questionsForm.dirty;
    return super.canGoBack();
  }

  setForm(response: any) {
    this.form.patchValue({ id: response.id });
    this.form.patchValue({ hubId: response.hubId });
    this.form.patchValue({ userId: response.userId });
    this.form.patchValue({ categoryId: response.categoryId });
    this.form.patchValue({ name: response.name });
    this.form.patchValue({ active: response.active });
    this.form.patchValue({ unListed: response.unListed });
    this.form.patchValue({ includeInMenu: response.includeInMenu });
    this.form.patchValue({ order: response.order });
    this.form.patchValue({ infoText: response.infoText });
    this.form.patchValue({ newRequestMessage: response.newRequestMessage });
    this.form.patchValue({ gpsRequired: response.gpsRequired });
    this.form.patchValue({ phoneNumberRequired: response.phoneNumberRequired });
    this.form.patchValue({ addressRequired: response.addressRequired });
    this.form.patchValue({ clonable: response.clonable });
    this.form.patchValue({ createdOn: response.createdOn });
    this.form.patchValue({ updatedOn: response.updatedOn });
    this.form.patchValue({ currency: response.currency });

    if (!response.includeInMenu && this.isHubNavigationMenuFull) {
      this.includeInMenu.disable();
      this.showNavigationMenuLink = true;
    }

    this.trackingStatusesForm.clear();
    for (const STATUS of response.trackingStatuses) {
      this.trackingStatusesForm.push(
        this.fb.control(STATUS, [Validators.required])
      );
    }

    this.questionsForm.clear();
    if (response.questions) {
      for (const QUESTION of response.questions) {
        this.questionsForm.push(
          QuestionsManagementUtil.createQuestionFromResponse(
            QUESTION,
            this.fb,
            this.optionName
          )
        );
      }
    }
    this.selectedInnerQuestion =
      QuestionsManagementUtil.createSelectedContainer(this.questionsForm);

    this.additionalChargesForm.clear();
    if (response.additionalCharges) {
      AdditionalChargesUtil.createAdditionalChargesFromResponse(
        response.additionalCharges,
        this.fb,
        this.additionalChargesForm
      );
    }

    this.descriptionImages = RichTextEditorComponent.getImagesSrcFromHtmlString(
      response.infoText
    );

    this.confirmationMessageImages = RichTextEditorComponent.getImagesSrcFromHtmlString(
      response.newRequestMessage
    );

    this.form.markAsPristine();
    this.originalActivity = response;
  }

  onSubmit() {
    if (this.activityId) {
      this.update();
    } else {
      this.create();
    }
  }

  async create() {
    if (!this.images || this.images.length === 0 || !this.images[0]) {
      this.showSnackBar(
        this.translate.instant("messages.at_least_one_image_or_video"),
        "OK",
        2000
      );
      return;
    }
    this.settings.loadingSpinner = true;
    const url = `${this.apiUrl}/${this.activityType}/v1`;
    const rawValues = this.form.getRawValue();
    rawValues.questions = QuestionsManagementUtil.cleanContent(
      rawValues.questions
    );
    rawValues.additionalCharges = AdditionalChargesUtil.cleanContent(
      rawValues.additionalCharges
    );
    rawValues.images = this.images;
    rawValues.infoText = await RichTextEditorComponent.uploadImages(
      rawValues.infoText,
      this.descriptionImages,
      this.imageService
    );

    rawValues.newRequestMessage = await RichTextEditorComponent.uploadImages(
      rawValues.newRequestMessage,
      this.confirmationMessageImages,
      this.imageService
    );

    const {
      hubId,
      userId,
      categoryId,
      name,
      infoText,
      newRequestMessage,
      order,
      active,
      unListed,
      includeInMenu,
      clonable,
      images,
      additionalCharges,
      phoneNumberRequired,
      gpsRequired,
      addressRequired,
      questions,
      currency,
      trackingStatuses,
    } = rawValues;

    const body: CreateActivityProductListingCommand = {
      hubId,
      userId,
      categoryId,
      name,
      infoText,
      newRequestMessage,
      order,
      active,
      unListed,
      includeInMenu,
      clonable,
      images,
      additionalCharges,
      phoneNumberRequired,
      gpsRequired,
      addressRequired,
      questions,
      currency,
      trackingStatuses,
    };

    try {
      const response: ActivityProductListingEntity = await firstValueFrom(
        this.httpService.doPost(url, body)
      );
      this.activityId = response.id;
      this.categoryId = response.categoryId;
      this.hubId = response.hubId;
      this.setForm(response);
      this.validateActiveLimitReached(
        rawValues["active"],
        response.active,
        ContentType.ACTIVITY_PRODUCT_LISTING
      );
      this.showSnackBar("Operation successful ", "OK", 2000);
    } catch (error) {
      this.validateCreatedLimitReached(
        error,
        ContentType.ACTIVITY_PRODUCT_LISTING
      );
      this.showSnackBar("Error Processing Your Request", "OK", 2000);
      console.log("Error creating activity: ", error);
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  async update() {
    if (!this.images || this.images.length === 0 || !this.images[0]) {
      this.showSnackBar(
        this.translate.instant("messages.at_least_one_image_or_video"),
        "OK",
        2000
      );
      return;
    }
    this.settings.loadingSpinner = true;
    const url = `${this.apiUrl}/${this.activityType}/v1`;
    const rawValues = this.form.getRawValue();
    rawValues.questions = QuestionsManagementUtil.cleanContent(
      rawValues.questions
    );
    rawValues.additionalCharges = AdditionalChargesUtil.cleanContent(
      rawValues.additionalCharges
    );
    rawValues.images = this.images;
    rawValues.infoText = await RichTextEditorComponent.uploadImages(
      rawValues.infoText,
      this.descriptionImages,
      this.imageService
    );

    rawValues.newRequestMessage = await RichTextEditorComponent.uploadImages(
      rawValues.newRequestMessage,
      this.confirmationMessageImages,
      this.imageService
    );

    const {
      id,
      hubId,
      userId,
      categoryId,
      name,
      infoText,
      newRequestMessage,
      order,
      active,
      unListed,
      includeInMenu,
      clonable,
      images,
      additionalCharges,
      phoneNumberRequired,
      gpsRequired,
      addressRequired,
      questions,
      currency,
      trackingStatuses,
    } = rawValues;

    const body: UpdateActivityProductListingCommand = {
      id,
      hubId,
      userId,
      categoryId,
      name,
      infoText,
      newRequestMessage,
      order,
      active,
      unListed,
      includeInMenu,
      clonable,
      images,
      additionalCharges,
      phoneNumberRequired,
      gpsRequired,
      addressRequired,
      questions,
      currency,
      trackingStatuses,
    };

    try {
      const response: ActivityProductListingEntity = await firstValueFrom(
        this.httpService.doPut(url, body)
      );
      this.activityId = response.id;
      this.categoryId = response.categoryId;
      this.hubId = response.hubId;
      this.setForm(response);
      this.validateActiveLimitReached(
        rawValues["active"],
        response.active,
        ContentType.ACTIVITY_PRODUCT_LISTING
      );
      this.showSnackBar("Operation successful ", "OK", 2000);
      this.next.emit();
    } catch (error) {
      this.validateCreatedLimitReached(
        error,
        ContentType.ACTIVITY_PRODUCT_LISTING
      );
      this.showSnackBar("Error Processing Your Request", "OK", 2000);
      console.log("Error updating activity: ", error);
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  // Tabs

  goToProducts() {
    const tabGroup = this.tabs;
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) {
      return;
    }
    this.saveScroll(tabGroup.selectedIndex);
    tabGroup.selectedIndex = 1;
    this.scrollToSaved(1);
  }

  canGoToProduct(event: MouseEvent) {
    if (!this.activityId || !this.productListManagerRef) {
      event.stopPropagation();
      this.showSnackBar(
        this.translate.instant("messages.cant_add_product"),
        "OK",
        2000
      );
    }
  }

  goToActivity() {
    const tabGroup = this.tabs;
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) {
      return;
    }
    this.saveScroll(tabGroup.selectedIndex);
    tabGroup.selectedIndex = 0;
    this.scrollToSaved(0);
  }

  saveScroll(index: number) {
    if (this.mainContent) {
      this.scrolls[index] = this.mainContent.scrollTop;
    }
  }

  scrollToSaved(index: number) {
    if (this.mainContent) {
      setTimeout(() => {
        this.mainContent.scrollTop = this.scrolls[index];
      }, 200);
    }
  }

  canDelete() {
    super.canDelete(
      "titles.delete_activity",
      "messages.delete_activity",
      async () => {
        try {
          this.settings.loadingSpinner = true;
          await firstValueFrom(
            this.httpService.doDelete(
              `${this.apiUrl}/${this.activityType}/v1/${this.activityId}`
            )
          );
          this.showSnackBar(
            this.translate.instant("messages.activity_deleted"),
            "OK",
            2000
          );
          this.back();
        } catch (error) {
          this.showSnackBar(
            this.translate.instant("messages.activity_delete_error"),
            "OK",
            2000
          );
        } finally {
          this.settings.loadingSpinner = false;
        }
      }
    );
  }


}
