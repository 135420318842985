import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { cover } from "intrinsic-scale";
import { TicketStatus } from "../../models/ticket-status-enum";
import { loadImage, newCanvas } from "../../util/image-utils";

const miniQRsize = 100;

@Component({
  selector: "app-qr-generator",
  templateUrl: "./qr-generator.component.html",
  styleUrls: ["./qr-generator.component.scss"],
})
export class QrGeneratorComponent {
  @ViewChild("canvas") canvas: ElementRef;

  @Input() text: string;
  @Input() imageUrl: string;
  @Input() isTicket: boolean;
  @Input() ticketStatus: TicketStatus;

  withImage = false;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  print() {
    window.print();
  }

  saveImage() {
    let linkSource;
    if (this.withImage) {
      const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
      linkSource = canvasEl.toDataURL();
    } else {
      const el: HTMLImageElement = document.querySelector("ngx-kjua img");
      linkSource = el?.src;
    }
    if (linkSource) {
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.target = "_self";
      downloadLink.download = "QR Code";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }

  async combineQrAndImage() {
    if (!this.withImage) return;
    const el: HTMLImageElement = document.querySelector("ngx-kjua img");
    if (el && this.imageUrl) {
      el.crossOrigin = "anonymous";
      const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
      const ctx = canvasEl.getContext("2d");
      canvasEl.height = canvasEl.width;
      const img: any = await loadImage(this.imageUrl);
      const { width, height } = cover(
        canvasEl.width,
        canvasEl.height,
        img.naturalWidth,
        img.naturalHeight
      );
      const c = await this.multiStepResize(img, width, height);
      ctx.drawImage(
        c,
        canvasEl.width - width,
        canvasEl.height - height,
        width,
        height
      );
      const c2 = await this.multiStepResize(el, miniQRsize, miniQRsize);
      ctx.drawImage(
        c2,
        canvasEl.width - miniQRsize,
        canvasEl.width - miniQRsize,
        miniQRsize,
        miniQRsize
      );
    }
  }

  canvasResize(img, width, height, quality = null) {
    const canvas = newCanvas(width, height);

    const ctx = canvas.getContext("2d");
    if (quality) {
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = quality;
    }
    ctx.drawImage(img, 0, 0, width, height);

    return Promise.resolve(canvas);
  }

  canvasResizeQuality(img, width, height) {
    return this.canvasResize(img, width, height, "high");
  }

  async multiStepResize(img, width, height) {
    let canvas = img;

    while (canvas.width * 0.5 > width) {
      canvas = await this.canvasResize(
        canvas,
        canvas.width / 2,
        canvas.height / 2
      );
    }

    return await this.canvasResize(canvas, width, height);
  }

  private showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }
}
