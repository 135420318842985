import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { HttpService } from '../../../service/http.service';
import { RouteConstants } from '../../../app.constants';
import { HubActivityComponent } from '../../../components/hub-activity/hub-activity.component';
import { ContentType } from '../../../models/content-type-enum';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/auth.serviceV2';
import { AccountStatus } from '../../../models/account-status-enum';
import { setDefaultPageHead } from '../../../util/seo-utils';
import { Meta, Title } from '@angular/platform-browser';
import { ActCatViewEntity } from '../../../models/activity-category-view';
import { firstValueFrom } from 'rxjs';
import { HubEntity } from '../../../models/hub';
import { PageEntity } from '../../../models/types';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivityFilterComponent } from '../components/activity-filter/activity-filter.component';
import { ActivitySortComponent } from '../components/activity-sort/activity-sort.component';
import { UserEntity } from '../../../models/user';
import { UserAdditionalInfoService } from '../../authentication/login/user-additional-info.service';
import { WorkflowService } from '../../../service/hub-workflow.service';
import { ShortUrlHandlerComponent } from '../../../components/short-url-handler/short-url-handler.component';
import { TranslateService } from '@ngx-translate/core';
import { WorkflowInfoDialogComponent } from '../components/workflow-info-dialog/workflow-info-dialog.component';

const ContentTypes = [
  'ACTIVITY_EVENT',
  'ACTIVITY_INFO',
  'ACTIVITY_PRODUCT_LISTING',
  'ACTIVITY_REQUEST',
  'ACTIVITY_RESERVATION',
  'ACTIVITY_MENU',
  'CATEGORY',
];

@Component({
  selector: 'app-hub-owner-view',
  templateUrl: './hub-owner-view.component.html',
  styleUrls: ['./hub-owner-view.component.scss'],
})
export class HubOwnerViewComponent implements OnInit {
  @ViewChildren(HubActivityComponent)
  hubActivities: QueryList<HubActivityComponent>;

  readonly apiUrl = environment.apiUrl;
  readonly noImage = '/assets/img/app/manage-hub.png';
  hub: HubEntity = null;
  items: ActCatViewEntity[] = null;
  settings: Settings;
  handler = null;
  user: UserEntity;

  loading = false;
  filterForm: UntypedFormGroup;
  searchCriteria = [];
  searchCriteraOptions = [];

  activeCount: number = 0;
  inactiveCount: number = 0;

  constructor(
    private authService: AuthService,
    private userAdditionalInfoService: UserAdditionalInfoService,
    private workflowService: WorkflowService,
    private router: Router,
    private httpService: HttpService,
    private appSettings: AppSettings,
    public dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private fb: UntypedFormBuilder,
    public translate: TranslateService,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    this.settings = this.appSettings.settings;
  }

  public async ngOnInit() {
    this.settings.loadingSpinner = true;
    setDefaultPageHead(this.titleService, this.metaTagService, false);
    this.createForm();
    this.user = await this.authService.reloadUserSession();
    const isUserReady = await this.userAdditionalInfoService.check(this.router.url);
    if (!isUserReady) return;
    await this.retrieveContent();
    await this.workflowService.check(this.hub, this.router.url);
    this.settings.loadingSpinner = false;
  }

  get qrCode() {
    if (!this.handler) {
      return false;
    }
    return `${environment.appUrl}/${this.handler}`;
  }

  get clientViewUrl() {
    if (!this.handler) {
      return false;
    }
    return ShortUrlHandlerComponent.shortHubUrl(this.handler);
  }

  public gotoManageHub(): void {
    this.router.navigate([this.handler || RouteConstants.hub, 'manage']).then();
  }

  public goToActivity(item: any): void {
    const start = this.handler
      ? `${this.handler}/${item.type}`
      : `/~${item.type}`;
    let url = `/${start}/manage?hubId=${this.hub.id}&activityId=${item.id}`;
    if (this.handler) {
      url += `&handler=${this.handler}`;
    }
    this.router.navigateByUrl(url).then();
  }

  public goToCategory(item: any): void {
    const start = this.handler
      ? `${this.handler}/category`
      : `/${RouteConstants.category}`;
    let url = `/${start}/owner?hubId=${this.hub.id}&categoryId=${item.id}`;
    if (this.handler) {
      url += `&handler=${this.handler}`;
    }
    this.router.navigateByUrl(url).then();
  }

  goToCreateActivity(): void {
    const start = this.handler ?  `${this.handler}/activity` : `/${RouteConstants.activity}`;
    let url = `/${start}/manage?hubId=${this.hub.id}`;
    if (this.handler) {
      url += `&handler=${this.handler}`;
    }
    this.router.navigateByUrl(url);
  }

  gotoReports(): void {
    this.router.navigate([this.handler, 'reports']);
  }

  public back(): void {
    this.router.navigateByUrl('/').then();
  }

  getSubStatus() {
    if (this.user.subExpired) {
      return AccountStatus.EXPIRED;
    }
    return this.user.subType;
  }

  stopVideo(id: string) {
    if (!this.hubActivities) {
      return;
    }
    const hubActivity = this.hubActivities.find(
      (hubAct: HubActivityComponent) => hubAct.activity.id === id
    );
    if (!hubActivity) {
      return;
    }
    hubActivity.stopVideo();
  }

  isActivity(type: string): boolean {
    return type.startsWith('ACTIVITY');
  }

  isCategory(type: string): boolean {
    return type.startsWith('CATEGORY');
  }

  private async retrieveContent() {
    try {
      const hubUrl = this.apiUrl + '/hubs/v1';
      this.hub = await firstValueFrom(this.httpService.doGet(hubUrl));

      if (this.hub) {
        this.handler = this.hub.urlHandler;
        const URL = `${this.apiUrl}/actcatview/v1/owner-search?page=0&size=500&querySearch=(hubId:'${this.hub.id}' AND contentType:'${ContentType.CATEGORY}') OR (hubId:'${this.hub.id}' AND contentType!'${ContentType.CATEGORY}' AND content.categoryId:'')&sort=order,asc`;
        const response: PageEntity<ActCatViewEntity> = await firstValueFrom(
          this.httpService.doGet(URL)
        );

        this.activeCount = 0;
        this.inactiveCount = 0;
        if (response.content && response.content.length > 0) {
          this.activeCount = response.content.reduce((p, c) => c.content.active ? p + 1 : p, 0);
          this.inactiveCount = response.content.length - this.activeCount;
          this.items = response.content;
        }
      }
    } catch (error) {
      console.error('Error getting hub: ', error);
    }
  }

  async search() {
    this.loading = true;
    const queryData = this.filterForm.getRawValue();
    const querySearch = [];

    querySearch.push(
      `querySearch=(hubId:'${this.hub.id}' AND contentType:'${ContentType.CATEGORY}') OR (hubId:'${this.hub.id}' AND contentType!'${ContentType.CATEGORY}' AND content.categoryId:'')`
    );

    let hasType = false;
    let allIncluded = true;
    for (const type of ContentTypes) {
      if (queryData[type]) {
        if (!hasType) {
          querySearch.push('AND (');
          hasType = true;
        } else {
          querySearch.push(' OR ');
        }
        querySearch.push(`contentType:'${type}'`);
      } else {
        allIncluded = false;
      }
    }
    if (hasType) {
      querySearch.push(')');
    }

    // Reset if all status are included
    if (allIncluded) {
      querySearch.length = 0;
      querySearch.push(
        `querySearch=(hubId:'${this.hub.id}' AND contentType:'${ContentType.CATEGORY}') OR (hubId:'${this.hub.id}' AND contentType!'${ContentType.CATEGORY}' AND content.categoryId:'')`
      );
    }

    if (queryData['active'] === true) {
      querySearch.push(' AND content.active:true');
    } else if(queryData['active'] === false) {
      querySearch.push(' AND content.active:false');
    }

    if (queryData['includeInMenu'] === true) {
      querySearch.push(' AND content.includeInMenu:true');
    } else if(queryData['includeInMenu'] === false) {
      querySearch.push(' AND content.includeInMenu:false');
    }

    if (queryData.dateFrom) {
      if (querySearch.length === 0) {
        querySearch.push('querySearch=');
      } else {
        querySearch.push(' AND ');
      }
      const dateFrom = this.resetDate(new Date(queryData.dateFrom));
      dateFrom.setDate(dateFrom.getDate() - 1);
      querySearch.push(
        `createdOn>'${this.normalizeDateFrom(dateFrom.toISOString())}'`
      );
    }

    if (queryData.dateTo) {
      if (querySearch.length === 0) {
        querySearch.push('querySearch=');
      } else {
        querySearch.push(' AND ');
      }
      const dateTo = this.resetDate(new Date(queryData.dateTo));
      dateTo.setDate(dateTo.getDate() + 1);
      querySearch.push(
        `createdOn<'${this.normalizeDateTo(dateTo.toISOString())}'`
      );
    }

    if (this.searchCriteria.length > 0) {
      if (querySearch.length === 0) {
        querySearch.push('querySearch=');
      } else {
        querySearch.push(' AND (');
      }

      let first = true;
      for (const criteria of this.searchCriteria) {
        if (!first) {
          querySearch.push(' OR ');
        } else {
          first = false;
        }
        querySearch.push(`content.name:'*${criteria}*'`);
      }

      querySearch.push(')');
    }

    if (querySearch.length > 0) {
      querySearch.push('&');
    }

    const reportsUrl = `${
      this.apiUrl
    }/actcatview/v1/owner-search?page=0&size=500&${querySearch.join('')}sort=${
      queryData.sort
    }`;

    try {
      const response: PageEntity<ActCatViewEntity> = await firstValueFrom(
        this.httpService.doGet(reportsUrl)
      );

      if (response.content) {
        this.items = response.content;
      }
    } catch (error) {
      console.error('Error getting actcat view: ', error);
    }

    this.loading = false;
    let top = document.getElementById('app-top-search-bar');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  resetDate(date: Date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  normalizeDateFrom(date: string) {
    return `${date.split('T')[0]}T23:59:59.999Z`;
  }

  normalizeDateTo(date: string) {
    return `${date.split('T')[0]}T00:00:00.000Z`;
  }

  createForm() {
    this.filterForm = this.fb.group({
      ACTIVITY_EVENT: [false],
      ACTIVITY_INFO: [false],
      ACTIVITY_PRODUCT_LISTING: [false],
      ACTIVITY_REQUEST: [false],
      ACTIVITY_RESERVATION: [false],
      ACTIVITY_MENU: [false],
      CATEGORY: [false],
      active: [null],
      includeInMenu: [null],
      dateFrom: [''],
      dateTo: [''],
      sort: ['createdOn,desc'],
    });
  }

  openFilter(event: MouseEvent): void {
    this._bottomSheet
      .open(ActivityFilterComponent, {
        panelClass: 'filter-bottom-sheet',
        data: {
          form: this.filterForm.getRawValue(),
        },
      })
      .afterDismissed()
      .subscribe((result) => {
        if (result) {
          this.filterForm.patchValue({ ACTIVITY_EVENT: result.ACTIVITY_EVENT });
          this.filterForm.patchValue({ ACTIVITY_INFO: result.ACTIVITY_INFO });
          this.filterForm.patchValue({
            ACTIVITY_PRODUCT_LISTING: result.ACTIVITY_PRODUCT_LISTING,
          });
          this.filterForm.patchValue({
            ACTIVITY_REQUEST: result.ACTIVITY_REQUEST,
          });
          this.filterForm.patchValue({
            ACTIVITY_RESERVATION: result.ACTIVITY_RESERVATION,
          });
          this.filterForm.patchValue({ ACTIVITY_MENU: result.ACTIVITY_MENU });
          this.filterForm.patchValue({ CATEGORY: result.CATEGORY });
          this.filterForm.patchValue({ active: result.active });
          this.filterForm.patchValue({ includeInMenu: result.includeInMenu });
          this.filterForm.patchValue({ dateFrom: result.dateFrom });
          this.filterForm.patchValue({ dateTo: result.dateTo });
          this.filterForm.markAsPristine();
          this.search();
        }
      });
  }

  openSort(event: MouseEvent): void {
    this._bottomSheet
      .open(ActivitySortComponent, {
        panelClass: 'sort-bottom-sheet',
        data: {
          form: this.filterForm.getRawValue(),
        },
      })
      .afterDismissed()
      .subscribe((result) => {
        if (result) {
          this.filterForm.patchValue({ sort: result.sort });
          this.filterForm.markAsPristine();
          this.search();
        }
      });
  }

  searchCriteriaChanged(items: any) {
    this.searchCriteria = items;
    this.search();
  }

  handleDescriptionClick(event) {
    event.stopPropagation();
  }

  openInfo(event) {
    event.stopPropagation();
    const infoContent = this.translate.instant('hubOwnerView.info');
    const infoAudio = `/assets/audio/hub/${this.translate.getDefaultLang()}/info.wav`;
    if (!infoContent) return;

    this.dialog.open(WorkflowInfoDialogComponent, {
      width: '100vw',
      height: 'fit-content',
      maxWidth: '600px',
      maxHeight: '100vh',
      panelClass: 'workflow-info-dialog',
      data: {
        content: infoContent,
        audio: infoAudio,
      },
    });
  }
}
