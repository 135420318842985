import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { AppSettings } from '../../../../app.settings';
import { ImageService } from '../../../../service/image.service';
import { Settings } from '../../../../app.settings.model';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '../../../../service/http.service';
import { CancelRequestDialogComponent } from '../../../../components/cancel-request-dialog/cancel-request-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RequestDetailsDialogComponent } from '../request-details-dialog/request-details-dialog.component';

@Component({
  selector: 'app-reservation-request-list-client-view',
  templateUrl: './reservation-request-list-client-view.component.html',
  styleUrls: ['./reservation-request-list-client-view.component.scss'],
})
export class ReservationRequestListClientViewComponent implements OnInit {
  @Input()
  activityId: string;

  @Input()
  hubId: string;

  @Input()
  user: any;

  @Input()
  activityType: string;

  @Output()
  hasOpen: EventEmitter<any> = new EventEmitter();

  @Output()
  cancel: EventEmitter<any> = new EventEmitter();

  private readonly apiUrl = environment.apiUrl;

  data: any[];
  pageSize = 5;
  pageIndex = 0;
  length = 0;
  settings: Settings;
  loading = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private translate: TranslateService,
    private appSettings: AppSettings,
    protected imageService: ImageService,
    protected snackBar: MatSnackBar,
    private router: Router,
    private httpService: HttpService,
    public dialog: MatDialog
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.search();
  }

  canCancelRequest(request: any, event: MouseEvent): void {
    event.stopPropagation();
    if (request.status !== 'OPEN' && request.status !== 'PENDING_PAYMENT') {
      return;
    }
    const dialogRef = this.dialog.open(CancelRequestDialogComponent, {
      minWidth: '240px',
      data: {
        title: 'titles.order',
        content: 'messages.want_cancel_order',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cancelRequest(request);
      }
    });
  }

  cancelRequest(request: any) {
    this.loading = false;
    const url = `${this.apiUrl}/activity-reservation/v1/reservation-from-client`;
    this.httpService
      .doPut(url, {
        activityId: this.activityId,
        hubId: this.hubId,
        [`${this.activityType}Id`]: request.id,
        status: 'CANCELED',
        userId: this.user.userId,
      })
      .subscribe(
        (response) => {
          this.cancel.emit();
          this.showSnackBar(
            this.translate.instant('questionsDialog.request_canceled_msg'),
            'OK',
            2000
          );
          this.search();
        },
        (error) => {
          this.loading = false;
          console.log('Error: ', error);
        }
      );
  }

  payRequest(request: any, event: MouseEvent) {
    event.stopPropagation();
    // TODO: PAYMENT
  }

  search() {
    let url = `${this.apiUrl}/activity-reservation/v1/reservation-from-client/${this.activityId}`;
    url += `?page=${this.pageIndex}&size=${this.pageSize}&sort=createdOn,desc`;
    this.loading = true;
    this.httpService.doGet(url).subscribe(
      (response) => {
        if (response) {
          this.data = response.content;
          if (
            this.pageIndex === 0 &&
            this.data &&
            this.data[0] &&
            (this.data[0].status === 'OPEN' ||
              this.data[0].status === 'APPROVED')
          ) {
            this.hasOpen.emit(true);
          }
          this.length = response.totalElements;
        } else {
          this.data = [];
          this.length = 0;
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.log('Error: ', error);
      }
    );
  }

  pageEvent(pageChangedEvent: any) {
    this.pageIndex = pageChangedEvent.pageIndex;
    this.pageSize = pageChangedEvent.pageSize;
    this.search();
  }

  showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  openDetails(request): void {
    const dialogRef = this.dialog.open(RequestDetailsDialogComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'reports-details-dialog',
      data: {
        report: request,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.search();
      }
    });
  }
}
