<mat-card-actions fxLayoutAlign="space-between center" class="p-1 data-row">
    <div fxLayoutAlign="space-between center" fxLayout="column">
        <div class="footer-row" fxLayoutAlign="space-between center" fxLayout="row">
            <span class="count">{{ 'plural.viewsNumber' | pluralTranslate: (views || 0) | translate:{ views: (views || 0) } }}</span>
            <span *ngIf="hasRequest" class="count">{{ 'plural.requestsNumber' | pluralTranslate: (requests || 0) | translate:{ requests: (requests || 0) } }}</span>
            <span class="star-button" mat-icon-button><mat-icon>star</mat-icon>{{reviewRate || 0.0 | number : '1.1-1'}} <span [hidden]="!reviewCount" class="count">({{ 'plural.commentsNumber' | pluralTranslate:reviewCount | translate:{ comments: reviewCount } }})</span></span>
        </div>
        <div class="footer-row" fxLayoutAlign="space-between center" fxLayout="row">
            <span class="time"><span translate>labels.created</span> {{createdOn | timeAgoAlt}}</span>
            <span class="share-button"><mat-icon (click)="openShare($event)">share</mat-icon></span>
        </div>
    </div>
</mat-card-actions>
