import { Component, Input, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RouteConstants } from '../../app.constants';
import { AppSettings } from '../../app.settings';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpService } from '../../service/http.service';
import { Router, ActivatedRoute } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { CanGoBackDialogComponent } from '../can-go-back-dialog/can-go-back-dialog.component';
import { ContentType } from '../../models/content-type-enum';
import { PageEntity } from '../../models/types';
import { ActCatViewEntity, UpdatedOrderCommand } from '../../models/activity-category-view';

@Component({
  selector: 'app-sort-act-cat-management',
  templateUrl: './sort-act-cat-management.component.html',
  styleUrls: ['./sort-act-cat-management.component.scss']
})
export class SortActCatManagementComponent implements OnInit {
  
  @Input()
  items: ActCatViewEntity[];

  readonly faGripVertical = faGripVertical;
  readonly apiUrl = environment.apiUrl;
  settings;
  hubId = null;
  categoryId = null;
  handler = null;

  tryToGoToCreateActivity = false;

  hasChenge = new Set();
  canGoOut = false;
  
  constructor(
    public router: Router,
    public httpService: HttpService,
    public appSettings: AppSettings,
    public route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private dialog: MatDialog,
    ) {
      this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.hubId = this.route.snapshot.queryParams['hubId'];
    this.categoryId = this.route.snapshot.queryParams['categoryId'];
    this.handler = this.route.snapshot.queryParams['handler'];
    this.retrieveContent();
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousIndex === event.currentIndex) {
      return;
    }
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);

    if (event.previousIndex > event.currentIndex) {
      for (let i = event.currentIndex; i <= event.previousIndex; i++) {
        const current = this.items[i];
        current.order = i;
        this.hasChenge.add(current.id);
      }
    } else {
      for (let i = event.currentIndex; i >= event.previousIndex; i--) {
        const current = this.items[i];
        current.order = i;
        this.hasChenge.add(current.id);
      }
    }
  }

  back() {
    this.tryToGoToCreateActivity = false;
    if (this.categoryId) {
      const start = this.handler ?  `${this.handler}/category` : `/${RouteConstants.category}`;
      let url = `${start}/owner?hubId=${this.hubId}&categoryId=${this.categoryId}`;
      if (this.handler) {
        url += `&handler=${this.handler}`;
      }
      this.router.navigateByUrl(url);
    } else {
      this.router.navigateByUrl(`${RouteConstants.hub}/owner`);
    }
  }

  canGoBack(): boolean {
    if (this.hasChenge.size === 0 || this.canGoOut) {
      return true;
    }
    const dialogRef = this.dialog.open(CanGoBackDialogComponent, {
      minWidth: '240px',
      data: {
        title: 'titles.can_go_back',
        content: 'messages.can_go_back',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.canGoOut = true;
        if (this.tryToGoToCreateActivity) {
          this.goToCreateActivity();
        } else {
          this.back();
        }
      }
    });
    return false;
  }

  private async retrieveContent() {
    this.settings.loadingSpinner = true;
    try {
      let URL = `${this.apiUrl}/actcatview/v1/owner-search?page=0&size=500&querySearch=hubId:'${this.hubId}'&sort=order,asc`;
      if(this.categoryId) {
        URL = `${this.apiUrl}/actcatview/v1/owner-search?page=0&size=500&querySearch=hubId:'${this.hubId}' AND contentType!'${ContentType.CATEGORY}' AND content.categoryId:'${this.categoryId}'&sort=order,asc`;
      }
      const response: PageEntity<ActCatViewEntity> = await firstValueFrom(this.httpService.doGet(URL));
      if (response.content && response.content.length > 0) {
        this.items = response.content;
      }
      this.settings.loadingSpinner = false;
    } catch (error) {
      this.settings.loadingSpinner = false;
      console.error('Error getting ActCat: ', error);
    }
  }

  async save() {
    this.settings.loadingSpinner = true;
    try {
      const URL = `${this.apiUrl}/actcatview/v1/`;
      for (const item of this.items) {
        if (this.hasChenge.has(item.id)) {
          const body: UpdatedOrderCommand = {
            hubId: item.hubId,
            id: item.id,
            contentType: item.contentType,
            order: item.order
          };
          await this.httpService.doPut(URL, body).toPromise();
        }
      }
      this.hasChenge.clear();
      this.showSnackBar(this.translate.instant('messages.activities_sorted'), 'OK', 2000);
      this.settings.loadingSpinner = false;
    } catch (error) {
      this.settings.loadingSpinner = false;
      console.error('Error getting ActCat: ', error);
    }
  }

  showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  goToCreateActivity(): void {
    const start = this.handler ?  `${this.handler}/activity` : `/${RouteConstants.activity}`;
    let url = `/${start}/manage?hubId=${this.hubId}`;
    if (this.handler) {
      url += `&handler=${this.handler}`;
    }
    this.tryToGoToCreateActivity = true;
    this.router.navigateByUrl(url);
  }

}
