export default {
    ACTIVITY_INFO: 'activity-info', 
    ACTIVITY_MENU: 'activity-menu', 
    ACTIVITY_PRODUCT_LISTING: 'activity-product-listing',
    ACTIVITY_REQUEST: 'activity-request', 
    ACTIVITY_RESERVATION: 'activity-reservation',
    ACTIVITY_EVENT: 'activity-event',
    CATEGORY: 'categories', 
    HUB: 'hubs', 
    CATEGORY_ACCESS: 'CATEGORY_ACCESS', 
    MENU_REQUEST: 'MENU_REQUEST', 
    REQUEST: 'REQUEST', 
    RESERVATION: 'RESERVATION', 
    SHOPPING: 'SHOPPING',
    TICKET_REQUEST: 'TICKET_REQUEST',
    REVIEW: 'REVIEW',
};