<mat-expansion-panel [(expanded)]="isDefaultOpen">
  <mat-expansion-panel-header>
    <mat-panel-title translate>labels.navigation</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-nav-list>
    <mat-list-item *ngIf="showHome" (click)="goToHome()">
      <div class="list-item-row" fxLayout="row">
        <fa-icon class="list-item-menu-icon" [icon]="faHome"></fa-icon>
        <span mat-line class="list-item-menu-icon">Hub</span>
      </div>
    </mat-list-item>
    <mat-list-item *ngIf="showBautUs" (click)="goToAboutUs()">
      <div class="list-item-row" fxLayout="row">
        <fa-icon class="list-item-menu-icon" [icon]="faInfoCircle"></fa-icon>
        <span mat-line class="list-item-menu-icon" translate>labels.about_us</span>
      </div>
    </mat-list-item>
    <mat-list-item *ngFor="let item of items" (click)="goToEntity(item)">
      <div class="list-item-row" fxLayout="row">
        <fa-icon class="list-item-menu-icon" [icon]="activityIconMap[item.type]"></fa-icon>
        <span mat-line class="list-item-menu-icon">{{item.activityName}}</span>
      </div>
    </mat-list-item>
  </mat-nav-list>
</mat-expansion-panel>
