<mat-card class="card-container request-card" [ngClass]="status.value">
    <mat-card-header fxLayout="column">
        <mat-card-title><a class="link" [href]="getHubUrl()">{{hub.name}}</a></mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>
        <mat-list>
            <mat-list-item>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">Status:</div>
                    <mat-form-field floatLabel="never" *ngIf="reportDetail" >
                        <mat-select [formControl]="status" (selectionChange)="sendRequest()">
                            <mat-option class="field-type-option" 
                                [ngClass]="option" 
                                *ngFor="let option of statusOptions" 
                                [value]="option">{{'questionsDialog.'+option | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>                      
            </mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item *ngIf="reportDetail.paymentStatus" class="use-status">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'labels.payment_status' | translate}}:</div>
                    <div><app-payment-status-tag [status]="reportDetail.paymentStatus"></app-payment-status-tag></div>
                </div>                      
            </mat-list-item>
            <mat-divider *ngIf="reportDetail.paymentStatus" [inset]="true"></mat-divider>
            <mat-list-item>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'reportsDetails.activity_name' | translate}}:</div>
                    <a class="link" [href]="getActivityUrl()">{{activity.name}}</a>
                </div>
            </mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'reportsDetails.activity_type' | translate}}:</div>
                    <div>{{'reportsDetails.'+report.entityType | translate}}</div>
                </div>
            </mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'reportsDetails.created_on' | translate}}:</div>
                    <div>{{report.createdOn | date:'short'}}</div>
                </div>
            </mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'reportsDetails.updated_on' | translate}}:</div>
                    <div>{{report.updatedOn | date:'short'}}</div>
                </div>
            </mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'reportsDetails.request_id' | translate}}:</div>
                    <div>{{report.id}}</div>
                </div>
            </mat-list-item>
            </mat-list>
    </mat-card-content>
</mat-card>
