

<div class="header no-print" fxLayout="row" fxLayoutAlign="space-between center">
    <button (click)="close()" mat-button><span translate>tinyAll.back</span></button>
    <h2 class="title" translate>titles.qr_reader</h2>
</div>

<div class="page-container" fxLayout="row wrap" fxLayoutAlign="center start">
    <app-qr-reader (onRead)="onRead($event)" [expectedCode]="parentData">
    </app-qr-reader>
</div>



