import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RouteConstants } from '../../app.constants';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent { 

    parentData: any; 
    constructor(
      private router: Router,
      public dialogRef: MatDialogRef<InfoDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.parentData = data; 
    }

    close(): void {
      this.dialogRef.close();
    }
  
    ok(): void {
      this.dialogRef.close();
      this.router.navigateByUrl(RouteConstants.pay);
    }
  
  }