import { Component, Input, OnInit } from '@angular/core';
import { 
  faClock,
 } from '@fortawesome/free-regular-svg-icons';
import { DatetimeAdapter } from '@mat-datetimepicker/core';
import {TranslateService} from '@ngx-translate/core';
import DAYS from '../../../../util/days';

@Component({
  selector: 'app-open-hours-client-view',
  templateUrl: './open-hours-client-view.component.html',
  styleUrls: ['./open-hours-client-view.component.scss']
})
export class OpenHoursClientViewComponent implements OnInit {

  @Input() set schedule({hours, daysOff}) {
    if (!hours) {
      this._days = {};
      return;
    }
    const TODAY_DATE = this.today.toISOString().split('T')[0];
    const dayMap = {};
    for (const DAY of hours) {
      if (DAY.intervals && DAY.intervals.length > 0) {
        const INTERVAL = DAY.intervals[0];
        const SPLITTED_START = INTERVAL.start.split('T')[1].split(':');
        const SPLITTED_END = INTERVAL.end.split('T')[1].split(':');
        const START = new Date(`${TODAY_DATE}T${SPLITTED_START[0]}:${SPLITTED_START[1]}:00.000Z`);
        let END = new Date(`${TODAY_DATE}T${SPLITTED_END[0]}:${SPLITTED_END[1]}:00.000Z`);
        START.setDate(this.today.getDate());
        END.setDate(this.today.getDate());
        if(this._adapter.compareDatetime(START, END) >= 0) {
          END = this._adapter.addCalendarDays(END, 1);
        }
        dayMap[DAY.dayOfWeek] = {
          from: this.getFullTimeFormat(START),
          to: this.getFullTimeFormat(END),
          start: START,
          end: END,
        };
      }
    }
    this._days = dayMap;
    this._isOpen = this.isOpen(dayMap, daysOff);
    this.setDaysOff(daysOff, this._isDayOff);
  }

  readonly faClock = faClock; 
  today;
  _days;
  _isOpen = false;
  weekDays = [];
  days;
  stateDate: any = {};
  _isDayOff = new Set<number>();

  constructor(    
    public _adapter: DatetimeAdapter<Date>,
    private translate: TranslateService
    ) {
      _adapter.setLocale(translate.getDefaultLang());
      (_adapter as any)._delegate.setLocale(translate.getDefaultLang());
      this.today = new Date();

      this.days = [...DAYS];
      this.weekDays = this._adapter.getDayOfWeekNames('long'); // 'long' | 'short' | 'narrow'
      const today = this._adapter.getDayOfWeek(new Date());
      if (today > 0) {
        const midWeek = this.weekDays.splice(today);
        this.weekDays = midWeek.concat(this.weekDays);
        const midWeekKey = this.days.splice(today);
        this.days = midWeekKey.concat(this.days);
      }
    }

  ngOnInit(): void {

  }

  isOpen(days, daysOff): boolean {
    const TODAY = days[this.days[0]];
    if (!TODAY) {
      this.stateDate = {
        cause: 'dayoff',
      };
      return false;
    }
    
    const now = new Date();

    const dayOff = daysOff.has(`${now.toISOString().split('T')[0]}T00:00:00Z`);
    const before = !(this._adapter.compareDatetime(TODAY.start, now) <= 0);
    const after = !(this._adapter.compareDatetime(TODAY.end, now) >= 0);

    if (!dayOff && !before && !after) {
      this.stateDate = {
        data: TODAY.to,
      };
      return true;
    }

    if (dayOff) {
      this.stateDate = {
        cause: 'dayoff',
      };
    } else if (before) {
      this.stateDate = {
        cause: 'before',
        data: TODAY.from,
      };
    } else if (after) {
      this.stateDate = {
        cause: 'after',
      };
    }

    return false;
  }

  getFullTimeFormat(date): string {
    let HOUR = date.getHours();
    const MINUTES = date.getMinutes();

    let ampm = 'AM';
    if (HOUR > 11) {
      ampm = 'PM';
    }

    if (HOUR === 0) {
      HOUR = 12;
    } else if (HOUR > 12) {
      HOUR -= 12;
    }

    let HOUR_TEXT = String(HOUR);
    let MINUTES_TEXT = String(MINUTES);
    if (HOUR < 10) {
      HOUR_TEXT = '0' + HOUR_TEXT;
    }
    if (MINUTES < 10) {
      MINUTES_TEXT = '0' + MINUTES_TEXT;
    }

    return `${HOUR_TEXT}:${MINUTES_TEXT}${ampm}`;
  }

  setDaysOff(daysOff, _isDayOffSet: Set<number>): boolean {
    _isDayOffSet.clear();
    for (let i = 0; i < 7; i++) {
      const now = new Date();
      now.setDate(now.getDate() + i);
      if (daysOff.has(`${now.toISOString().split('T')[0]}T00:00:00Z`)) {
        _isDayOffSet.add(i);
      }
    }

    
    
    return true;
  }
  
}
