import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { environment } from "../../../environments/environment";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
//import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { IpLocationService } from "../../service/ip-location.service";
import { GeocoderService } from "../../service/geocoder.service";
import defaultLocation from "../../util/default-location";

@Component({
  selector: "app-location-picker-dialog",
  templateUrl: "./location-picker-dialog.component.html",
  styleUrls: ["./location-picker-dialog.component.scss"],
})
export class LocationPickerDialogComponent implements OnInit {
  private defaultLatLng = defaultLocation;
  location;
  address;
  parentData: any;
  mapboxlib = mapboxgl as typeof mapboxgl;
  mapBox: mapboxgl.Map;
  marker: mapboxgl.Marker;
  geocoder: MapboxGeocoder;
  geolocate;

  constructor(
    public dialogRef: MatDialogRef<LocationPickerDialogComponent>,
    private ipLocationService: IpLocationService,
    private geocoderService: GeocoderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.parentData = data;
    console.log(this.data);
    this.location = this.parentData.location;
    this.mapboxlib.accessToken = environment.mapbox.accessToken;
  }

  ngOnInit(): void {
    this.showMap();
  }

  showMap() {
    const coor = this.location?.coordinates || this.defaultLatLng;
    this.mapBox = new mapboxgl.Map({
      container: "box-map",
      style: "mapbox://styles/mapbox/streets-v11",
      zoom: 13,
      center: [coor[1], coor[0]],
    });

    this.geocoder = new MapboxGeocoder({
      accessToken: environment.mapbox.accessToken,
      proximity: {
        latitude: coor[0],
        longitude: coor[1],
      }
    });

    this.geocoder.on('result', ({ result }) => {
      const { center, place_name: placeName } = result;

      if (!this.marker) {
        this.marker = new mapboxgl.Marker();
        this.marker.setLngLat(center);
        this.marker.addTo(this.mapBox);
      }
      this.marker.setLngLat(center);
      this.location = {
        coordinates: [center[1], center[0]],
        type: "Point",
      };
      this.address = placeName;
    })

    this.mapBox.addControl(this.geocoder);

    this.geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: false,
      showUserHeading: false,
      showAccuracyCircle: false,
      showUserLocation: false,
    });

    this.geolocate.on('geolocate', ({ coords }) => {
      this.geocoder.setProximity(coords);
    });

    this.mapBox.addControl(this.geolocate, 'bottom-right');

    this.mapBox.on("click", async (e) => {
      const {
        lngLat: { lng, lat },
      } = e;
      if (!this.marker) {
        this.marker = new mapboxgl.Marker();
        this.marker.setLngLat([lng, lat]);
        this.marker.addTo(this.mapBox);
      }
      this.marker.setLngLat([lng, lat]);
      this.location = {
        coordinates: [lat, lng],
        type: "Point",
      };

      this.geocoder.clear();

      // TODO: reverseGeocoding
      // const result = await this.geocoderService.reverseGeocoding(Endpoint.PLACES, [lat, lng].toString(), environment.mapbox.accessToken, [], [PlaceType.ADDRESS]).toPromise();
    });

    if (navigator.geolocation && !this.location) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.mapBox.setCenter([
            position.coords.longitude,
            position.coords.latitude,
          ]);
        },
        (error) => {
          this.ipLocationService.getClientLocation().subscribe((response) => {
            this.mapBox.setCenter([response.longitude, response.latitude]);
          });
        }
      );
    } else if(this.location) {
      this.marker = new mapboxgl.Marker();
      this.marker.setLngLat([coor[1], coor[0]]);
      this.marker.addTo(this.mapBox);
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close({
      location: this.location,
      address: this.address,
    });
  }

  async geocodeReverseFromLatLngToPlaceName(lat, lng) {
    return new Promise((resolve, reject) => {
      this.geocoder.mapboxClient.geocodeReverse({
        latitude: parseFloat(lat),
        longitude: parseFloat(lng)
      }, (error, response) => {
        if (error) {
          reject(error);
        }
        // console.log(response.features[0]?.place_name);
        resolve(response.features[0] && response.features[0].place_name);
      });
    })
  }
}
