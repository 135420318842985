export const QuestionTypeClass = {
    DATE: 'app.tinyall.common.form.question.DateQuestion',
    DATETIME: 'app.tinyall.common.form.question.DateTimeQuestion',
    GROUP: 'app.tinyall.common.form.question.GroupQuestion',
    IMAGE: 'app.tinyall.common.form.question.ImageQuestion',
    MULTI_CHOICE_PRICE: 'app.tinyall.common.form.question.MultiChoicePriceQuestion',
    MULTI_CHOICE: 'app.tinyall.common.form.question.MultiChoiceQuestion',
    NUMBER: 'app.tinyall.common.form.question.NumberQuestion',
    SINGLE_CHOICE_PRICE: 'app.tinyall.common.form.question.SingleChoicePriceQuestion',
    SINGLE_CHOICE: 'app.tinyall.common.form.question.SingleChoiceQuestion',
    TEXT: 'app.tinyall.common.form.question.TextQuestion',
    TIME: 'app.tinyall.common.form.question.TimeQuestion',
    GEOPOINT: 'app.tinyall.common.form.question.GeoPointQuestion',
};