<div fxLayout="column wrap" class="page-container">
    <div *ngIf="(order && order.dishRequestedList && order.dishRequestedList.length > 0) || loading;" class="list-container">
        <div *ngIf="loading && !settings.loadingSpinner; else noLoading" class="loading" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
            <mat-spinner color="primary" diameter="60"></mat-spinner>
        </div>
        <ng-template #noLoading>
            <mat-accordion>
                <div fxLayout="column" fxLayoutGap="15px" *ngIf="products">
                    <app-menu-order-dish-item 
                        *ngFor="let item of order.dishRequestedList; let i = index" 
                        [item]="item" [index]="i">
                    </app-menu-order-dish-item>
                </div>
            </mat-accordion>
        </ng-template>
    </div>
</div>
