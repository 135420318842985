import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetectScrollDirective } from './detect-scroll/detect-scroll.directive';
import { IoViewDirective } from './io-view/io-view.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    DetectScrollDirective,
    IoViewDirective
  ],
  exports: [
    DetectScrollDirective,
    IoViewDirective
  ]
})
export class DirectivesModule { }
