import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.scss']
})
export class YesNoDialogComponent { 

    parentData: any; 
    constructor(
      public dialogRef: MatDialogRef<YesNoDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.parentData = data; 
    }
  
    cancel(): void {
      this.dialogRef.close();
    }
    yes(): void {
      this.dialogRef.close(true);
    }
  
  }