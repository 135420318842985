import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { AppSettings } from '../../../../app.settings';
import { ImageService } from '../../../../service/image.service';
import { Settings } from '../../../../app.settings.model';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '../../../../service/http.service';
import { OrderListClientViewDialogComponent } from '../order-list-client-view-dialog/order-list-client-view-dialog.component';
import { OrderDetailsClientViewDialogComponent } from '../order-details-client-view-dialog/order-details-client-view-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CancelRequestDialogComponent } from '../../../../components/cancel-request-dialog/cancel-request-dialog.component';
import CURRENCY_SYMBOLS from '../../../../util/currency-symbols';
import { firstValueFrom } from 'rxjs';
import {
  ShoppingRequestEntity,
  UpdateShoppingRequestFromClientCommand,
} from '../../../../models/activity-product-listing';
import { PageEntity, ShoppingStatus } from '../../../../models/types';

@Component({
  selector: 'app-order-list-client-view',
  templateUrl: './order-list-client-view.component.html',
  styleUrls: ['./order-list-client-view.component.scss'],
})
export class OrderListClientViewComponent implements OnInit {
  @Input() title: boolean;
  @Input() footer: boolean;
  @Input() activityId: string;
  @Input() hubId: string;
  @Input() user: any;
  @Input() activityType: string;
  @Input() open: boolean;
  @Input() size: number;

  @Output() hasOpen: EventEmitter<any> = new EventEmitter();
  @Output() hasOrder: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  private readonly apiUrl = environment.apiUrl;
  readonly currencySymbols = CURRENCY_SYMBOLS;

  data: ShoppingRequestEntity[];
  pageIndex = 0;
  length = 0;
  settings: Settings;
  loading = false;
  anyOpen = false;
  anyOrder = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  get pageSize() {
    return Number(this.size);
  }

  constructor(
    private translate: TranslateService,
    private appSettings: AppSettings,
    protected imageService: ImageService,
    protected snackBar: MatSnackBar,
    private router: Router,
    private httpService: HttpService,
    public dialog: MatDialog
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.search();
  }

  canCancelRequest(request: any, event: MouseEvent): void {
    event.stopPropagation();
    if (request.status !== 'OPEN' && request.status !== 'PENDING_PAYMENT') {
      return;
    }
    const dialogRef = this.dialog.open(CancelRequestDialogComponent, {
      minWidth: '240px',
      data: {
        title: 'titles.order',
        content: 'messages.want_cancel_order',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cancelRequest(request);
      }
    });
  }

  async cancelRequest(request: any) {
    this.loading = false;
    const url = `${this.apiUrl}/activity-product-listing/v1/shopping-request-from-client`;
    try {
      const requestBody: UpdateShoppingRequestFromClientCommand = {
        activityId: this.activityId,
        hubId: this.hubId,
        shoppingRequestId: request.id,
        status: ShoppingStatus.CANCELED,
        userId: this.user.userId,
      };
      await firstValueFrom(this.httpService.doPut(url, requestBody));
      this.cancel.emit();
      this.showSnackBar(
        this.translate.instant('questionsDialog.request_canceled_msg'),
        'OK',
        2000
      );
      this.search();
    } catch (error) {
      this.loading = false;
      console.log('Error: ', error);
    }
  }

  payRequest(request: any, event: MouseEvent) {
    event.stopPropagation();
    // TODO: PAYMENT
  }

  async search() {
    try {
      const orderUrl = this.open
        ? 'activity-product-listing/v1/shopping-request-pending'
        : 'activity-product-listing/v1/shopping-request-from-client';
      let url = `${this.apiUrl}/${orderUrl}/${this.activityId}`;
      url += `?page=${this.pageIndex}&size=${this.pageSize}&sort=createdOn,desc`;
      this.loading = true;
      let response: PageEntity<ShoppingRequestEntity>;
      let responseOne: ShoppingRequestEntity;

      if (this.open) {
        responseOne = await firstValueFrom(this.httpService.doGet(url));
        this.data = [responseOne];
        this.length = 1;
      } else {
        response = await firstValueFrom(this.httpService.doGet(url));
        this.data = response.content;
        this.length = response.totalElements;
      }
      if (response || responseOne) {
        if (this.open && this.length > 0) {
          this.hasOpen.emit(true);
          this.anyOpen = true;
        } else {
          this.hasOpen.emit(false);
          this.anyOpen = false;
        }

        if (this.length === 0) {
          this.hasOrder.emit(false);
          this.anyOrder = false;
        } else {
          this.hasOrder.emit(true);
          this.anyOrder = true;
        }
      } else {
        this.data = [];
        this.length = 0;
        this.hasOrder.emit(false);
        this.anyOrder = false;
        this.hasOpen.emit(false);
        this.anyOpen = false;
      }
    } catch (error) {
      console.log(error);
      this.data = [];
      this.length = 0;
      this.hasOrder.emit(false);
      this.anyOrder = false;
      this.hasOpen.emit(false);
      this.anyOpen = false;
    } finally {
      this.loading = false;
    }

    if (this.open && this.length === 0) {
      this.validateHasOrder();
    }
  }

  async validateHasOrder() {
    let url = `${this.apiUrl}/activity-product-listing/v1/shopping-request-from-client/${this.activityId}`;
    url += `?page=${this.pageIndex}&size=${this.pageSize}&sort=createdOn,desc`;
    try {
      const response: PageEntity<ShoppingRequestEntity> = await firstValueFrom(
        this.httpService.doGet(url)
      );
      if (response && response.content && response.content.length > 0) {
        this.hasOrder.emit(true);
        this.anyOrder = true;
      } else {
        this.hasOrder.emit(false);
        this.anyOrder = false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  pageEvent(pageChangedEvent: any) {
    this.pageIndex = pageChangedEvent.pageIndex;
    this.search();
  }

  showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(OrderListClientViewDialogComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'order-list-client-view-dialog',
      data: {
        activityId: this.activityId,
        activityType: this.activityType,
        hubId: this.hubId,
        user: this.user,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.search();
      }
    });
  }

  openDetails(order): void {
    const dialogRef = this.dialog.open(OrderDetailsClientViewDialogComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'order-details-client-view-dialog',
      data: {
        item: order,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.search();
      }
    });
  }

  getTotal(item) {
    return Number(item.totalPrice);
  }
}
