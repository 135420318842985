import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder,  UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import {CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-dish-list-management',
  templateUrl: './dish-list-management.component.html',
  styleUrls: ['./dish-list-management.component.scss']
})
export class DishListManagementComponent implements OnInit {

  @Input()
  dishesForm: UntypedFormArray;
  @Input()
  sectionListForm: UntypedFormArray;
  @Input()
  sectionIndex: any;
  @Input()
  actForm: UntypedFormGroup;

  form: UntypedFormGroup;


  constructor(
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      dishes: this.dishesForm
    });
  }

  get dishesFormControls() {
    return this.dishesForm.controls as UntypedFormGroup[];
  }

  drop(event: CdkDragDrop<string[]>) {
    this.moveItemInFormArray(this.sectionListForm, event.previousIndex, event.currentIndex);
    this.sectionListForm.markAsDirty();
  }

  moveItemInFormArray(formArray: UntypedFormArray, previusIndex: number, currentIndex: number) {
    const current = formArray.at(previusIndex);
    formArray.removeAt(previusIndex);
    formArray.insert(currentIndex, current);
  }

  removeDish(index: number): void {
    this.dishesForm.removeAt(index);
    for (const control of this.dishesForm.controls) {
      control.get('name').updateValueAndValidity();
    }
    this.dishesForm.markAsDirty()
    this.sectionListForm.markAsDirty();
    this.sectionListForm.updateValueAndValidity();
    this.actForm.updateValueAndValidity();
  }

}
