import { Component, Input} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { QrGeneratorDialogComponent } from '../qr-generator-dialog/qr-generator-dialog.component';

@Component({
  selector: 'app-qr-generator-card',
  templateUrl: './qr-generator-card.component.html',
  styleUrls: ['./qr-generator-card.component.scss']
})
export class QrGeneratorCardComponent {

  @Input() text: string;
  @Input() imageUrl: string;
  icon = faQrcode;

  constructor(public dialog: MatDialog){}

  openGenerator(e): void {
      e.stopPropagation()
      this.dialog.open(QrGeneratorDialogComponent, {
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw',
        maxHeight: '100vh',
        panelClass: 'qr-generator-dialog',
        data: {
          qrCode: this.text,
          imageUrl: this.imageUrl
        }
      });
  }

}
