<mat-card class="custom-activity-card">
    <mat-card-content>
        <div fxLayout="column">
            <div class="text" translate>activityReservation.capacity<app-info-tooltip class="absolute-tooltip info-reservation-capacity" info="tooltip.reservation_capacity" infoClass="secondary"></app-info-tooltip></div>
            <div>
                <mat-slider
                    color="primary"
                    class="app-slider"
                    max="10"
                    min="1"
                    step="1"
                    thumbLabel="true"
                    tickInterval="1"
                    [formControl]="control">
                </mat-slider>
            </div>
        </div>
    </mat-card-content>
</mat-card>