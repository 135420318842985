import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EntityType } from '../../models/entity-type-enum';
import { HttpService } from '../../service/http.service';
import { CanDeleteDialogComponent } from '../can-delete-dialog/can-delete-dialog.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ReportsCommentEntity, UpdateReportsCommentCommand } from '../../models/reports-comment';

@Component({
  selector: 'app-reports-comment',
  templateUrl: './reports-comment.component.html',
  styleUrls: ['./reports-comment.component.scss']
})
export class ReportsCommentComponent {

  @Input() client: boolean = false;
  @Input() user: any;
  @Input() comment: ReportsCommentEntity | any;
  @Input() editingComment: boolean;
  @Output() onEdited: EventEmitter<ReportsCommentEntity> = new EventEmitter();
  @Output() onDeleted: EventEmitter<string> = new EventEmitter();
  @Output() onEditModeChanged: EventEmitter<boolean> = new EventEmitter();


  private readonly apiUrl = environment.apiUrl;
  expanded = false;
  isEditing = false;
  loading = false;
  form: UntypedFormGroup;

  type = EntityType.REVIEW;

  constructor(
    private dialog: MatDialog,
    private httpService: HttpService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
  ) {}

  get commentField() {
    return this.form.get('comment');
  }

  get internalField() {
    return this.form.get('internal');
  }

  set internalField(value: any) {
    this.internalField.setValue(value);
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      comment: ['', Validators.required],
      internal: [true],
    });
  }

  showSnackBar(message, action, duration, panelClass = '') {
    this.snackBar.open(message, action, {
      duration: duration,
      panelClass
    });
  }

  onEditMode() {
    if (this.comment.userId !== this.user.userId) return;
    this.form.patchValue({ comment: this.comment.comment });
    this.form.patchValue({ internal: true });
    this.form.markAsPristine();
    this.isEditing = true;
    this.onEditModeChanged.emit(true);
  }

  async onEdit() {
    if(this.commentField.value.length > 300) return;
    for (var i in this.form.controls) {
      this.form.controls[i].markAsTouched();
    }
    if (this.form.invalid) return;
    this.loading = true;

    const { id, requestId } = this.comment;
    const rawValues = this.form.getRawValue();
    const { comment, internal } = rawValues;

    const body: UpdateReportsCommentCommand = {
      id,
      requestId,
      comment,
      internal: this.client ? false : internal,
    };

    const url = `${this.apiUrl}/request-comment/v1`;
    try {
      const response: ReportsCommentEntity = await firstValueFrom(
        this.httpService.doPut(url, body)
      );
      this.isEditing = false;
      this.onEdited.emit(response);
      this.onEditModeChanged.emit(false);
    } catch(error) {
      this.showSnackBar(
        this.translate.instant('messages.comment_save_error'),
        'OK',
        2000
      );
    } finally {
      this.loading = false;
    }
  }

  cancel() {
    this.isEditing = false;
    this.onEditModeChanged.emit(false);
  }

  canDelete(): void {
    if (this.comment.userId !== this.user.userId) return;
    const dialogRef = this.dialog.open(CanDeleteDialogComponent, {
      minWidth: "240px",
      data: {
        title: "titles.delete_comment",
        content: "messages.delete_comment",
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.loading = true;
        const { id } = this.comment;
        const url = `${this.apiUrl}/request-comment/v1/${id}`;
        try {
          await firstValueFrom(
            this.httpService.doDelete(url)
          );
        } catch(error) {
          this.showSnackBar(
            this.translate.instant('messages.comment_deleted'),
            'OK',
            2000
          );
        } finally {
          this.loading = false;
        }
        this.onDeleted.emit(this.comment.id);
      }
    });
  }
}
