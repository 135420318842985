import { Component, Input } from "@angular/core";

@Component({
  selector: "app-client-view-button",
  templateUrl: "./client-view-button.component.html",
  styleUrls: ["./client-view-button.component.scss"],
})
export class ClientViewButtonComponent {
  @Input() url: string | boolean;
}
