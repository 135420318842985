<mat-sidenav-container>
  <mat-sidenav
    *ngIf="settings.menu == 'vertical'"
    [opened]="settings.sidenavIsOpened"
    [mode]="settings.sidenavIsPinned ? 'side' : 'over'"
    #sidenav
    class="sidenav mat-elevation-z6"
  >
    <app-sidenav></app-sidenav>
  </mat-sidenav>
  <mat-sidenav-content
    [perfectScrollbar]="perfectScrollBarConfig"
    [disabled]="settings.fixedHeader"
    (psScrollY)="onPsScrollY($event)"
  >
    <mat-toolbar
      *ngIf="user || router.url !== '/'"
      color="primary"
      class="flex-p-x relative z-10"
    >
      <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
        <div fxLayout="row" fxLayoutAlign="center center">
          <button
            *ngIf="settings.menu == 'vertical'"
            mat-icon-button
            (click)="toggleSidenav()"
          >
            <mat-icon>menu</mat-icon>
          </button>
          <a
            *ngIf="settings.menu == 'horizontal'"
            mat-raised-button
            color="accent"
            routerLink="/"
            (click)="closeSubMenus()"
            class="small-logo"
            >G</a
          >
          <a
            *ngIf="settings.menu == 'horizontal'"
            class="logo"
            routerLink="/"
            (click)="closeSubMenus()"
            >GRADUS</a
          >
          <a class="logo-wrapper w-[48px]" routerLink="/">
            <img
              class="icon"
              src="/assets/img/app/min-icon.png"
              alt="TinyAll"
            />
          </a>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center">
          <!--<app-flags-menu fxShow="false" fxShow.gt-sm></app-flags-menu>-->
          <button
            *ngIf="!user"
            class="py-1 px-3 flex h-11 text-[12px] text-white rounded-full bg-accent items-center justify-center cursor-pointer uppercase mx-3 text-center min-w-[95px]"
            (click)="logInAndKeepUrl()"
          >
            <span
              class="leading-[1.3] whitespace-break-spaces mt-[2.5px]"
              translate
              >buttons.get_a_free_account</span
            >
          </button>
          <a
            *ngIf="user && user.subType !== AccountStatus.PREMIUM"
            class="py-1 px-3 flex h-11 text-[12px] text-white rounded-full bg-accent items-center justify-center cursor-pointer uppercase mx-3 text-center min-w-[95px]"
            href="/~pay"
          >
            <span
              class="leading-[1.3] whitespace-break-spaces mt-[2.5px]"
              translate
              >buttons.go_premium</span
            >
          </a>
          <app-qr-reader-button></app-qr-reader-button>
          <app-order-notification-button *ngIf="user"></app-order-notification-button>
          <!--<app-fullscreen></app-fullscreen>-->
          <!--<app-applications fxShow="false" fxShow.gt-sm></app-applications>
          <app-messages fxShow="false" fxShow.gt-xs></app-messages>-->
          <app-user-menu></app-user-menu>
        </div>
      </div>
    </mat-toolbar>

    <div
      *ngIf="!user && router.url === '/'"
      class="pl-0 pr-4 sm:px-4 py-2 sm:py-5 max-w-full lg:px-8 bg-white relative z-20 shadow"
    >
      <div
        class="relative flex items-center justify-between mx-auto max-w-screen-lg"
      >
        <a
          href="/"
          aria-label="Company"
          title="Company"
          class="inline-flex items-center"
        >
          <img
            alt="TinyAll"
            class="h-16 sm:h-20 md:h-28 absolute z-10 top-[-10px] sm:top-[-15px] shadow"
            src="/assets/img/app/full-icon.png"
          />
        </a>
        <ul class="items-center space-x-4 sm:space-x-8 flex">
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/tinyallapp1/"
              aria-label="About us"
              title="About us"
              class="tracking-wide text-primary transition-colors duration-200 hover:underline hover:text-accent whitespace-nowrap"
            >
              <fa-icon
                class="mr-1 text-xl sm:text-base"
                [icon]="faFacebook"
              ></fa-icon>
              <span class="hidden sm:inline-block" translate
                >buttons.know_us</span
              >
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/tinyall.app/"
              aria-label="Follow us"
              title="Follow us"
              class="tracking-wide text-primary transition-colors duration-200 hover:underline hover:text-accent whitespace-nowrap"
            >
              <fa-icon
                class="mr-1 text-xl sm:text-base"
                [icon]="faInstagram"
              ></fa-icon>
              <span class="hidden sm:inline-block" translate
                >buttons.follow_us</span
              >
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/@tinyallapp1"
              aria-label="Learn"
              title="Learn"
              class="tracking-wide text-primary transition-colors duration-200 hover:underline hover:text-accent whitespace-nowrap"
            >
              <fa-icon
                class="mr-1 text-xl sm:text-base"
                [icon]="faYoutube"
              ></fa-icon>
              <span class="hidden sm:inline-block" translate
                >buttons.learn</span
              >
            </a>
          </li>
          <li>
            <button
              class="sm:py-2.5 sm:px-4 flex h-11 text-base sm:text-white text-primary underline rounded-full sm:bg-accent whitespace-nowrap items-center cursor-pointer"
              (click)="logIn($event)"
            >
              <mat-icon class="mt-1 mr-1">person_pin_icon</mat-icon>
              <span translate>buttons.log_in</span>
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div
      id="main-content"
      class="inner-sidenav-content transition-2 top-0"
      [perfectScrollbar]="perfectScrollBarConfig"
      [disabled]="!settings.fixedHeader"
      (psScrollY)="onPsScrollY($event)"
      [class.horizontal-menu-hidden]="isStickyMenu"
    >
      <!--<app-breadcrumb></app-breadcrumb>-->
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
<!--*ngIf="user || router.url !== '/'"-->
