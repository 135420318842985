<div fxLayout="row">
  <h2 class="title" translate>titles.summary</h2>
</div>

<mat-card class="card-container">
  <mat-card-content>
    <mat-list>
      <mat-list-item>
        <div fxLayout="row" fxLayoutAlign="start center" class="row">
          <div fxFlex="50" class="field-name">
            ({{ quantity }}) {{ itemLabel | translate }}:
          </div>
          <div fxFlex="50" class="value">
            {{ items | currency : currency : currencySymbols[currency] }}
          </div>
        </div>
      </mat-list-item>
      <mat-divider></mat-divider>

      <ng-container
        *ngFor="let additionalCharge of additionalCharges | keyvalue"
      >
        <mat-list-item>
          <div fxLayout="row" fxLayoutAlign="start center" class="row">
            <div fxFlex="50" class="field-name">
              {{ additionalCharge.key }}:
            </div>
            <div fxFlex="50" class="value">
              {{
                additionalCharge.value
                  | currency : currency : currencySymbols[currency]
              }}
            </div>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </ng-container>

      <ng-container *ngIf="promoCodeEntity">
        <div class="flex mt-3 mb-4">
          <div
            class="w-full bg-primary text-white font-bold rounded text-center mt-1.5 p-3 h-11 text-base"
          >
            {{ promoCodeEntity.code }}
          </div>
        </div>
        <mat-list-item class="mt-[-15px]">
          <div fxLayout="row" fxLayoutAlign="start center" class="row">
            <div fxFlex="50" class="field-name">
              {{
                'labels.promo_percentage'
                  | translate : { percentage: promoCodeEntity.discount }
              }}:
            </div>
            <div fxFlex="50" class="value">
              -{{
                getPromoDiscount() || 0
                  | currency : currency : currencySymbols[currency]
              }}
            </div>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </ng-container>

      <ng-container *ngIf="!form">
        <mat-list-item>
          <div fxLayout="row" fxLayoutAlign="start center" class="row">
            <div fxFlex="50" class="field-name">
              {{ 'labels.tip' | translate }}:
            </div>
            <div fxFlex="50" class="value">
              {{ getTips() || 0 | currency : currency : currencySymbols[currency] }}
            </div>
          </div>
        </mat-list-item>

        <mat-divider></mat-divider>
      </ng-container>

      <form novalidate *ngIf="form" [formGroup]="form">
        <mat-list-item class="select-tips">
          <div fxFlex="50" class="field-name">
            {{ 'labels.tip' | translate }}:
          </div>
          <mat-radio-group formControlName="tip">
            <mat-list-item
              class="tip-option"
              *ngFor="let option of tips; let i = index"
            >
              <mat-radio-button [value]="option" (click)="uncheckRadio(option)">
                {{ option }}% ({{
                  (option * getTotalNoTips()) / 100
                    | currency : currency : currencySymbols[currency]
                }})
              </mat-radio-button>
            </mat-list-item>
          </mat-radio-group>
        </mat-list-item>
        <mat-divider></mat-divider>
      </form>

      <mat-list-item class="last">
        <div fxLayout="row" fxLayoutAlign="start center" class="row">
          <div fxFlex="50" class="field-name">
            {{ 'labels.total' | translate }}:
          </div>
          <div fxFlex="50" class="value">
            {{ getTotal() | currency : currency : currencySymbols[currency] }}
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
