import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { HttpService } from '../../service/http.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-available-state-field',
  templateUrl: './available-state-field.component.html',
  styleUrls: ['./available-state-field.component.scss'],
})
export class AvailableStateFieldComponent implements OnInit {
  @Input() countryForm: UntypedFormControl;
  @Input() state: UntypedFormControl;
  @Output() onChange: EventEmitter<string> = new EventEmitter();

  private readonly apiUrl = environment.apiUrl;

  filteredOptions: Observable<string[]>;

  constructor(private httpService: HttpService) {}

  ngOnInit() {
    this.filteredOptions = this.countryForm.valueChanges.pipe(
      startWith(null),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((value) => this._filter(value))
    );
  }

  onStateSelected() {
    this.onChange.emit();
  }

  private async _filter(value: string) {
    const filterValue = this.countryForm.value;
    if (!filterValue) {
      return [];
    }

    const response = await this.httpService
      .doGet(
        `${this.apiUrl}/hubs/v1/search?querySearch=address.country:'${filterValue}' AND unlisted:false AND active:true AND restricted:false&page=0&size=100`
      )
      .toPromise();

    if (response && response.content) {
      const items = new Set();
      for (const HUB of response.content) {
        if (HUB.address.stateOrProvidence) {
          items.add(HUB.address.stateOrProvidence);
        }
      }

      if (this.state.value) {
        items.add(this.state.value);
      }

      return Array.from(items) as string[];
    } else {
      if (this.state.value) {
        return [this.state.value];
      }
      return [];
    }
  }
}
