<div class="page-container" fxLayout="column">
    <form *ngIf="form" [formGroup]="form" class="main-event-info">
        <app-event-general-info *ngIf="eventRequest"
            [eventRequest]="eventRequest"
            [activity]="activity"
            [status]="status"
            [statusOptions]="getAvailableStatus(eventRequest.status)"
            (statusChanged)="sendRequest()">
        </app-event-general-info>
    </form>

    <div *ngIf="eventRequest && eventRequest.tickets && eventRequest.tickets.length > 0" fxLayout="column">
        <app-ticket-card *ngFor="let ticket of eventRequest.tickets; let i = index;" [label]="(i + 1).toString()" [text]="getTicketQr(ticket)" [status]="ticket.status"></app-ticket-card>
    </div>

    <app-chosen-payment-method *ngIf="eventRequest && eventRequest.chosenPaymentType"
        [chosenPaymentType]="eventRequest.chosenPaymentType"
        [cashReturn]="eventRequest.cashReturn"
        [paymentImage]="eventRequest.paymentImage">
    </app-chosen-payment-method>

    <app-payment-link *ngIf="eventRequest && eventRequest.paymentLink"
        [currentPaymentLink]="eventRequest.paymentLink">
    </app-payment-link>
    
    <app-event-ticket-summary *ngIf="eventRequest && eventRequest.totalPrice"
        itemLabel="labels.ticket_price"
        [itemPrice]="eventRequest.currentTicketPrice"
        [quantity]="eventRequest.quantity"
        currency="DOP"
        [questionAnswerMap]="eventRequest.questionAnswerMap"
        [totalPrice]="eventRequest.totalPrice"
        [additionalCharges]="eventRequest.additionalCharges"
        [promoCodeEntity]="eventRequest.issuedPromoCode">
    </app-event-ticket-summary>

    <app-location-map *ngIf="eventRequest && eventRequest.location" 
        [location]="eventRequest.location" 
        [name]="eventRequest.clientName"
        [launch]="true">
    </app-location-map>

    <app-chosen-address *ngIf="eventRequest && eventRequest.choosenLocation" [chosenLocation]="eventRequest.choosenLocation"></app-chosen-address>

    <app-view-question-list 
        *ngIf="eventRequest" 
        withTitle="true"
        title="{{'titles.checkout_data' | translate}}"
        [answers]="eventRequest.questionAnswerMap">
    </app-view-question-list>
    <app-reports-comments-view *ngIf="eventRequest" [client]="true" [entityId]="eventRequest.id"></app-reports-comments-view>
</div>

