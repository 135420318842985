import { Component, Input} from '@angular/core';
import { Router } from '@angular/router';
import { RouteConstants } from '../../../../app.constants';

@Component({
  selector: 'app-hub-create-activity-card',
  templateUrl: './hub-create-activity-card.component.html',
})
export class HubCreateActivityCardComponent {

  @Input() hubId: string;
  @Input() handler = null;
  constructor(private router: Router) {}

  goToCreateActivity(): void {
    const start = this.handler ?  `${this.handler}/activity` : `/${RouteConstants.activity}`;
    let url = `/${start}/manage?hubId=${this.hubId}`;
    if (this.handler) {
      url += `&handler=${this.handler}`;
    }
    this.router.navigateByUrl(url);
  }
}

