import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../auth/auth.serviceV2';

@Injectable()
export class HttpService {

    constructor(private http: HttpClient, private authService: AuthService) {}

    public doGet(url: string): Observable<any> {
        return this.http.get<any>(url, this.buildHttpHeaders());
    }

    public doPost(url: string, payload: any): Observable<any> {
        return this.http.post<any>(url, JSON.stringify(payload), this.buildHttpHeaders());
    }

    public doPut(url: string, payload: any): Observable<any> {
        return this.http.put<any>(url, JSON.stringify(payload), this.buildHttpHeaders());
    }

    public doDelete(url: string): Observable<any> {
        return this.http.delete<any>(url, this.buildHttpHeaders());
    }

    public doPostFormData(url: string, payload: any): Observable<any> {
        return this.http.post<any>(url, payload);
    }

    private buildHttpHeaders(): any {
        let httpHeaders = new HttpHeaders({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        });
        const tokenId = this.authService.getIdToken();

        if (tokenId && this.authService.isAuthenticatedSync()) {
            httpHeaders = new HttpHeaders({
                'Authorization': 'Bearer ' + tokenId,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            });
        }
        return {headers: httpHeaders};
    }
}
