import { UntypedFormGroup } from "@angular/forms";
import { AppSettings } from "../app.settings";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { ImageService } from "../service/image.service";
import { environment } from "../../environments/environment";
import { RouteConstants } from "../app.constants";
import { MatDialog } from "@angular/material/dialog";
import { CanGoBackDialogComponent } from "../components/can-go-back-dialog/can-go-back-dialog.component";
import { CanDeleteDialogComponent } from "../components/can-delete-dialog/can-delete-dialog.component";
import { Component, OnDestroy } from "@angular/core";
import { InfoDialogComponent } from "../components/info-dialog/info-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { firstValueFrom } from "rxjs";
import { NavigationMenuEntity } from "../models/navigation-menu";
import { HttpService } from "../service/http.service";
import { MAX_MENU_ITEMS } from '../util/constants';

@Component({
  template: "",
})
export abstract class BaseFormComponent implements OnDestroy {
  protected settings;
  readonly apiUrl = environment.apiUrl;
  readonly noImage = "/assets/img/app/no-image.png";
  hubId = null;
  categoryId = null;
  handler = null;
  activityId = null;
  originId = null;
  images = [];
  descriptionImages = [];
  confirmationMessageImages = [];
  user = null;
  form: UntypedFormGroup;
  canGoOut: boolean = false;
  forceWarming: boolean = false;
  isHubNavigationMenuFull: boolean = false;
  showNavigationMenuLink: boolean = false;
  originalActivity;

  constructor(
    protected httpService: HttpService,
    protected appSettings: AppSettings,
    protected snackBar: MatSnackBar,
    protected imageService: ImageService,
    protected router: Router,
    protected dialog: MatDialog,
    public translate: TranslateService
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnDestroy(): void {
    if (!this.activityId && this.images.length > 0) {
      for (const image of this.images) {
        if (image) {
          this.imageService.deleteImage(image).subscribe(
            (r) => {},
            (err) => {}
          );
        }
      }
    }
    if (!this.activityId && this.descriptionImages && this.descriptionImages.length > 0) {
      for (const image of this.descriptionImages) {
        if (image) {
          this.imageService.deleteImage(image).subscribe(
            (r) => {},
            (err) => {}
          );
        }
      }
    }
    if (!this.activityId && this.confirmationMessageImages && this.confirmationMessageImages.length > 0) {
      for (const image of this.confirmationMessageImages) {
        if (image) {
          this.imageService.deleteImage(image).subscribe(
            (r) => {},
            (err) => {}
          );
        }
      }
    }
  }

  back() {
    if (this.categoryId) {
      const start = this.handler
        ? `${this.handler}/category`
        : `/${RouteConstants.category}`;
      let url = `${start}/owner?hubId=${this.hubId}&categoryId=${this.categoryId}`;
      if (this.handler) {
        url += `&handler=${this.handler}`;
      }
      this.router.navigateByUrl(url);
    } else {
      this.router.navigateByUrl(`${RouteConstants.hub}/owner`);
    }
  }

  showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  async uploadImage(image: File, form: UntypedFormGroup, field: string) {
    this.settings.loadingSpinner = true;
    try {
      const url = await this.imageService.uploadImage(image);
      this.settings.loadingSpinner = false;
      let payload = {};
      payload[field] = url;
      form.patchValue(payload);
      form.controls[field].markAsDirty();
    } catch (error) {
      this.settings.loadingSpinner = false;
      this.showSnackBar("Error Processing Your Request", "OK", 2000);
      console.log("image error: ", error);
    }
  }

  deleteImage(image: File) {
    this.imageService.deleteImage(image).subscribe(
      (result) => {},
      (error) => {
        console.log("error deleting image: ", error);
      }
    );
  }

  onSelectedImage(file: FileList, form: UntypedFormGroup, field: string) {
    console.log("onSelectedImage field: " + field);
    let image: File = file.item(0);
    if (form.get(field).value) {
      this.deleteImage(form.get(field).value);
    }
    this.uploadImage(image, form, field);
  }

  canGoBack(): boolean {
    if ((!this.forceWarming && !this.form?.dirty) || this.canGoOut) {
      return true;
    }
    const dialogRef = this.dialog.open(CanGoBackDialogComponent, {
      minWidth: "240px",
      data: {
        title: "titles.can_go_back",
        content: "messages.can_go_back",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.canGoOut = true;
        this.back();
      }
    });
    return false;
  }

  canDelete(title, message, deleteMethod): void {
    const dialogRef = this.dialog.open(CanDeleteDialogComponent, {
      minWidth: "240px",
      data: {
        title: title,
        content: message,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        deleteMethod();
      }
    });
  }

  validateActiveLimitReached(
    requestActive: boolean,
    responseActive: boolean,
    type: string
  ): void {
    if (requestActive === responseActive) {
      return;
    }
    this.dialog.open(InfoDialogComponent, {
      width: "100vw",
      height: "100vh",
      maxWidth: "600px",
      maxHeight: "800px",
      panelClass: "limit-reached-dialog",
      data: {
        content: this.translate.instant("messages.active_limit_reached", {
          type: this.translate.instant(`types.${type}`),
        }),
      },
    });
  }

  validateCreatedLimitReached(errorMessage: string, type: string): void {
    if (errorMessage != "Create limit reached") {
      return;
    }
    this.dialog.open(InfoDialogComponent, {
      width: "100vw",
      height: "100vh",
      maxWidth: "600px",
      maxHeight: "800px",
      panelClass: "limit-reached-dialog",
      data: {
        content: this.translate.instant("messages.created_limit_reached", {
          type: this.translate.instant(`types.${type}`),
        }),
      },
    });
  }

  async setIsHubNavigationMenuFull() {
    const Url = `${this.apiUrl}/navigation-menu/v1/${this.hubId}`;
    try {
      const response: NavigationMenuEntity = await firstValueFrom(
        this.httpService.doGet(Url)
      );
      this.isHubNavigationMenuFull = response.items.length >= MAX_MENU_ITEMS;
    } catch (error) {
      console.log(error);
    }
  }

  gotoNavigationMenu(): void {
    const start = this.handler ?  `${this.handler}` : `/${RouteConstants.hub}`;
    let url = `/${start}/sort-menu?hubId=${this.hubId}`;
    if (this.handler) {
      url += `&handler=${this.handler}`;
    }
    this.router.navigateByUrl(url);
  }

  async cloneImages() {
    if (this.images && this.images[0]) {
      const clonedImages = [];
      for (const imageUrl of this.images) {
        try {
          const clonedImage = await this.imageService.cloneImage(imageUrl);
          clonedImages.push(clonedImage);
        } catch(error) {
          console.log(error);
        }
      }
      this.images = clonedImages;
    }
    
    if (this.descriptionImages && this.descriptionImages.length > 0) {
      const clonedDescriptionImages = [];
      for (const imageUrl of this.descriptionImages) {
        try {
          const clonedImage = await this.imageService.cloneImage(imageUrl);
          clonedDescriptionImages.push(clonedImage);
        } catch(error) {
          console.log(error);
        }
      }
      this.descriptionImages = clonedDescriptionImages;
    }

    if (this.confirmationMessageImages && this.confirmationMessageImages.length > 0) {
      const clonedConfirmationMessageImages = [];
      for (const imageUrl of this.confirmationMessageImages) {
        try {
          const clonedImage = await this.imageService.cloneImage(imageUrl);
          clonedConfirmationMessageImages.push(clonedImage);
        } catch(error) {
          console.log(error);
        }
      }
      this.confirmationMessageImages = clonedConfirmationMessageImages;
    }
  }

  removeOriginId() {
    this.originId = null;
    this.router.navigate([], {
      queryParams: {
        originId: null,
      },
      queryParamsHandling: "merge",
    });
  }

  getHighlightCheckbox(field: string): string {
    return this.originalActivity && this.form && this.originalActivity[field] !== this.form.get(field).value ? 'highlight-checkbox' : ''
  }
}
