import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ServiceModule } from "../service/service.module";
import { DragDropModule } from "@angular/cdk/drag-drop";

import { QuestionListClientViewComponent } from "./question-list-client-view/question-list-client-view.component";
import { QuestionListManagementComponent } from "./question-list-management/question-list-management.component";
import { QuestionsSummaryComponent } from "./questions-summary/questions-summary.component";
import { DummySummaryComponent } from "./dummy-summary/dummy-summary.component";

import { LocationMapComponent } from "./location-map/location-map.component";
import { SwiperManagementComponent } from "./swiper-management/swiper-management.component";
import { SwiperViewComponent } from "./swiper-view/swiper-view.component";
import { TagPickerComponent } from "./tag-picker/tag-picker.component";
import { CurrencyPickerComponent } from "./currency-picker/currency-picker.component";
import { ViewQuestionListComponent } from "./view-question-list/view-question-list.component";

import { OrderProductListComponent } from "./order-product-list/order-product-list.component";
import { OrderProductItemComponent } from "./order-product-item/order-product-item.component";
import { OrderGeneralInfoComponent } from "./order-general-info/order-general-info.component";
import { OrderSummaryComponent } from "./order-summary/order-summary.component";

import { MenuOrderDishesListComponent } from "./menu-order-dishes-list/menu-order-dishes-list.component";
import { MenuOrderDishItemComponent } from "./menu-order-dish-item/menu-order-dish-item.component";
import { MenuSummaryComponent } from "./menu-summary/menu-summary.component";

import { SchedulePickerComponent } from "./schedule-picker/schedule-picker.component";
import { DaysOffPickerComponent } from "./days-off-picker/days-off-picker.component";

import { SentRequestDialogComponent } from "./sent-request-dialog/sent-request-dialog.component";
import { YoutubeUrlDialogComponent } from "./youtube-url-dialog/youtube-url-dialog.component";
import { YouTubePlayerModule } from "@angular/youtube-player";

import { HubActivityComponent } from "./hub-activity/hub-activity.component";
import { HubCategoryComponent } from "./hub-category/hub-category.component";

import { SortActCatManagementComponent } from "./sort-act-cat-management/sort-act-cat-management.component";
import { CanGoBackDialogComponent } from "./can-go-back-dialog/can-go-back-dialog.component";
import { CanDeleteDialogComponent } from "./can-delete-dialog/can-delete-dialog.component";
import { CancelRequestDialogComponent } from "./cancel-request-dialog/cancel-request-dialog.component";
import { SocialShareBottomSheetComponent } from "./social-share-bottom-sheet/social-share-bottom-sheet.component";
import { WarningMessageComponent } from "./warning-message/warning-message.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";

import { QrGeneratorComponent } from "./qr-generator/qr-generator.component";
import { QrGeneratorCardComponent } from "./qr-generator-card/qr-generator-card.component";
import { QrGeneratorDialogComponent } from "./qr-generator-dialog/qr-generator-dialog.component";

import { QrReaderComponent } from "./qr-reader/qr-reader.component";
import { QrReaderDialogComponent } from "./qr-reader-dialog/qr-reader-dialog.component";
import { QrReaderButtonComponent } from "./qr-reader-button/qr-reader-button.component";
import { QrReaderCardComponent } from "./qr-reader-card/qr-reader-card.component";
import { QrReaderMenuComponent } from "./qr-reader-menu/qr-reader-menu.component";

import { TermsDialogComponent } from "./terms-dialog/terms-dialog.component";

import { AvailableStateFieldComponent } from "./available-state-field/available-state-field.component";
import { AvailableCitiesFieldComponent } from "./available-cities-field/available-cities-field.component";

import { StateAutocompleteComponent } from "./state-autocomplete/state-autocomplete.component";
import { CityAutocompleteComponent } from "./city-autocomplete/city-autocomplete.component";

import { YesNoDialogComponent } from "./yes-no-dialog/yes-no-dialog.component";
import { InfoDialogComponent } from "./info-dialog/info-dialog.component";

import { CommentComponent } from "./comment/comment.component";
import { CommentsViewComponent } from "./comments-view/comments-view.component";
import { CommentManagementComponent } from "./comment-management/comment-management.component";
import { CommentsPreviewComponent } from "./comments-preview/comments-preview.component";
import { CommentsDialogComponent } from "./comments-dialog/comments-dialog.component";

import { SocialCardActionsComponent } from "./social-card-actions/social-card-actions.component";

import { PipesModule } from "../theme/pipes/pipes.module";
import { ReservationPickerComponent } from "./reservation-picker/reservation-picker.component";
import { LoadingComponent } from "./loading/loading.component";
import { InfoTooltipComponent } from "./info-tooltip/info-tooltip.component";
import { ReportAbuseDialogComponent } from "./report-abuse-dialog/report-abuse-dialog.component";
import { ReportAbuseButtonComponent } from "./report-abuse-button/report-abuse-button.component";

import { SubscriptionStatusComponent } from "./subscription-status/subscription-status.component";
import { RichTextEditorComponent } from "./rich-text-editor/rich-text-editor.component";
import { RichTextViewComponent } from "./rich-text-view/rich-text-view.component";
import { LocationPickerDialogComponent } from "./location-picker-dialog/location-picker-dialog.component";
import { AddressPickerComponent } from "./address-picker/address-picker.component";
import { CollaboratorsManagementComponent } from "./collaborators-management/collaborators-management.component";

import { UrlHandlerExampleComponent } from "./url-handler-example/url-handler-example.component";
import { QuantityPickerComponent } from "./quantity-picker/quantity-picker.component";
import { EventTicketSummaryComponent } from "./event-ticket-summary/event-ticket-summary.component";
import { EventGeneralInfoComponent } from "./event-general-info/event-general-info.component";
import { TicketCardComponent } from "./ticket-card/ticket-card.component";

import { CodeScannerComponent } from "./code-scanner/code-scanner.component";
import { ShortUrlHandlerComponent } from "./short-url-handler/short-url-handler.component";
import { ClientViewButtonComponent } from "./client-view-button/client-view-button.component";

import { SelectPaymentMethodComponent } from "./select-payment-method/select-payment-method.component";
import { PaymentMethodsFooterComponent } from "./payment-methods-footer/payment-methods-footer.component";
import { AdditionalChargesManagementComponent } from "./additional-charges-management/additional-charges-management.component";
import { ChosenPaymentMethodComponent } from "./chosen-payment-method/chosen-payment-method.component";
import { NotificationDialogComponent } from "./notification-dialog/notification-dialog.component";
import { AzulPaymentFormComponent } from "./azul-payment-form/azul-payment-form.component";
import { PaymentStatusTag } from "./payment-status-tag/payment-status-tag.component";
import { InfoBannerComponent } from "./info-banner/info-banner.component";
import { NavigationMenuComponent } from "./navigation-menu/navigation-menu.component";
import { SortHubMenuManagementComponent } from "./sort-hub-menu-management/sort-hub-menu-management.component";
import { CompanyInfoCardComponent } from "./company-info-card/company-info-card.component";
import { CollapsibleDescriptionComponent } from "./collapsible-description/collapsible-description.component";
import { ActivityBreadcrumbComponent } from "./activity-breadcrumb/activity-breadcrumb.component";
import { BannedMessageComponent } from "./banned-message/banned-message.component";
import { TriStateCheckboxComponent } from "./tri-state-checkbox/tri-state-checkbox.component";
import { AddressFormComponent } from "./address-form/address-form.component";
import { MatSelectCountryModule } from "@angular-material-extensions/select-country";
import { AddressFormDialogComponent } from "./address-form-dialog/address-form-dialog.component";
import { AddressListDialogComponent } from "./address-list-dialog/address-list-dialog.component";
import { ChosenServiceTypeComponent } from "./chosen-service-type/chosen-service-type.component";
import { AddressSelectorComponent } from "./address-selector/address-selector.component";
import { ChosenAddressComponent } from "./chosen-address/chosen-address.component";
import { ReportsCommentsViewComponent } from "./reports-comments-view/reports-comments-view.component";
import { ReportsCommentComponent } from "./reports-comment/reports-comment.component";
import { SliderPickerComponent } from "./slider-picker/slider-picker.component";
import { ActivityPickerComponent } from "./activity-picker/activity-picker.component";
import { PromoBannerComponent } from "./promo-banner/promo-banner.component";
import { PricingBlocksComponent } from "./pricing-blocks/pricing-blocks.component";
import { PaymentLinkFormComponent } from "./payment-link-form/payment-link-form.component";
import { PaymentLinkComponent } from "./payment-link/payment-link.component";
import { ActivityClonerComponent } from "./activity-cloner/activity-cloner.component";
import { HubClonerComponent } from "./hub-cloner/hub-cloner.component";
import { FrequentlyAskedQuestionsComponent } from "./frequently-asked-questions/frequently-asked-questions.component";
import { QuestionsSummaryViewComponent } from "./questions-summary-view/questions-summary-view.component";
import { ActivityListComponent } from "./activity-list/activity-list.component";
import { OrderListComponent } from "./order-list/order-list.component";
import { UrlHandlerCopyComponent } from "./url-handler-copy/url-handler-copy.component";
import { PaymentMethodsComponent } from "../pages/monetization/payment-methods/payment-methods.component";
import { OrderNotificationButtonComponent } from './order-notification-button/order-notification-button.component';
import { SocialShareBottomsComponent } from "./social-share-bottoms/social-share-bottoms.component";
import { MatureContentDialogComponent } from "./mature-content-dialog/mature-content-dialog.component";
import { ReportsLogsViewComponent } from "./reports-logs-view/reports-logs-view.component";
import { ConfirmationMessageManagementComponent } from "./confirmation-message-management/confirmation-message-management.component";

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ServiceModule,
    DragDropModule,
    YouTubePlayerModule,
    PipesModule,
    MatSelectCountryModule,
  ],
  providers: [],
  declarations: [
    QuestionListClientViewComponent,
    QuestionListManagementComponent,
    QuestionsSummaryComponent,
    DummySummaryComponent,
    LocationMapComponent,
    SwiperManagementComponent,
    SwiperViewComponent,
    TagPickerComponent,
    CurrencyPickerComponent,
    ViewQuestionListComponent,
    OrderSummaryComponent,
    OrderGeneralInfoComponent,
    OrderProductListComponent,
    OrderProductItemComponent,
    SchedulePickerComponent,
    DaysOffPickerComponent,
    SentRequestDialogComponent,
    YoutubeUrlDialogComponent,
    HubActivityComponent,
    HubCategoryComponent,
    SortActCatManagementComponent,
    SortHubMenuManagementComponent,
    CanGoBackDialogComponent,
    CanDeleteDialogComponent,
    SocialShareBottomSheetComponent,
    CancelRequestDialogComponent,
    WarningMessageComponent,
    PageNotFoundComponent,
    MenuOrderDishesListComponent,
    MenuOrderDishItemComponent,
    MenuSummaryComponent,
    QrGeneratorComponent,
    QrGeneratorCardComponent,
    QrGeneratorDialogComponent,
    QrReaderComponent,
    QrReaderDialogComponent,
    QrReaderButtonComponent,
    QrReaderCardComponent,
    QrReaderMenuComponent,
    TermsDialogComponent,
    AvailableStateFieldComponent,
    AvailableCitiesFieldComponent,
    YesNoDialogComponent,
    StateAutocompleteComponent,
    CityAutocompleteComponent,
    CommentComponent,
    CommentsViewComponent,
    CommentManagementComponent,
    CommentsPreviewComponent,
    CommentsDialogComponent,
    SocialCardActionsComponent,
    ReservationPickerComponent,
    LoadingComponent,
    InfoTooltipComponent,
    InfoDialogComponent,
    ReportAbuseDialogComponent,
    ReportAbuseButtonComponent,
    SubscriptionStatusComponent,
    RichTextEditorComponent,
    RichTextViewComponent,
    LocationPickerDialogComponent,
    CollaboratorsManagementComponent,
    UrlHandlerExampleComponent,
    AddressPickerComponent,
    QuantityPickerComponent,
    EventTicketSummaryComponent,
    EventGeneralInfoComponent,
    TicketCardComponent,
    CodeScannerComponent,
    ShortUrlHandlerComponent,
    ClientViewButtonComponent,
    SelectPaymentMethodComponent,
    PaymentMethodsFooterComponent,
    AdditionalChargesManagementComponent,
    ChosenPaymentMethodComponent,
    NotificationDialogComponent,
    AzulPaymentFormComponent,
    PaymentStatusTag,
    InfoBannerComponent,
    NavigationMenuComponent,
    CompanyInfoCardComponent,
    CollapsibleDescriptionComponent,
    ActivityBreadcrumbComponent,
    BannedMessageComponent,
    TriStateCheckboxComponent,
    AddressFormComponent,
    AddressFormDialogComponent,
    AddressListDialogComponent,
    ChosenServiceTypeComponent,
    AddressSelectorComponent,
    ChosenAddressComponent,
    ReportsCommentsViewComponent,
    ReportsCommentComponent,
    SliderPickerComponent,
    ActivityPickerComponent,
    PromoBannerComponent,
    PricingBlocksComponent,
    PaymentLinkFormComponent,
    PaymentLinkComponent,
    ActivityClonerComponent,
    HubClonerComponent,
    FrequentlyAskedQuestionsComponent,
    QuestionsSummaryViewComponent,
    ActivityListComponent,
    OrderListComponent,
    UrlHandlerCopyComponent,
    PaymentMethodsComponent,
    OrderNotificationButtonComponent,
    SocialShareBottomsComponent,
    MatureContentDialogComponent,
    ReportsLogsViewComponent,
    ConfirmationMessageManagementComponent,
  ],
  exports: [
    QuestionListClientViewComponent,
    QuestionListManagementComponent,
    QuestionsSummaryComponent,
    DummySummaryComponent,
    LocationMapComponent,
    SwiperManagementComponent,
    SwiperViewComponent,
    TagPickerComponent,
    CurrencyPickerComponent,
    ViewQuestionListComponent,
    OrderSummaryComponent,
    OrderGeneralInfoComponent,
    OrderProductListComponent,
    OrderProductItemComponent,
    SchedulePickerComponent,
    DaysOffPickerComponent,
    SentRequestDialogComponent,
    YoutubeUrlDialogComponent,
    HubActivityComponent,
    HubCategoryComponent,
    SortActCatManagementComponent,
    SortHubMenuManagementComponent,
    CanGoBackDialogComponent,
    CanDeleteDialogComponent,
    SocialShareBottomSheetComponent,
    CancelRequestDialogComponent,
    WarningMessageComponent,
    PageNotFoundComponent,
    MenuOrderDishesListComponent,
    MenuOrderDishItemComponent,
    MenuSummaryComponent,
    QrGeneratorComponent,
    QrGeneratorCardComponent,
    QrGeneratorDialogComponent,
    QrReaderComponent,
    QrReaderDialogComponent,
    QrReaderButtonComponent,
    QrReaderCardComponent,
    QrReaderMenuComponent,
    TermsDialogComponent,
    AvailableStateFieldComponent,
    AvailableCitiesFieldComponent,
    YesNoDialogComponent,
    StateAutocompleteComponent,
    CityAutocompleteComponent,
    CommentComponent,
    CommentsViewComponent,
    CommentManagementComponent,
    CommentsPreviewComponent,
    CommentsDialogComponent,
    SocialCardActionsComponent,
    ReservationPickerComponent,
    LoadingComponent,
    InfoTooltipComponent,
    InfoDialogComponent,
    ReportAbuseDialogComponent,
    ReportAbuseButtonComponent,
    SubscriptionStatusComponent,
    RichTextEditorComponent,
    RichTextViewComponent,
    LocationPickerDialogComponent,
    CollaboratorsManagementComponent,
    UrlHandlerExampleComponent,
    AddressPickerComponent,
    QuantityPickerComponent,
    EventTicketSummaryComponent,
    EventGeneralInfoComponent,
    TicketCardComponent,
    CodeScannerComponent,
    ShortUrlHandlerComponent,
    ClientViewButtonComponent,
    SelectPaymentMethodComponent,
    PaymentMethodsFooterComponent,
    AdditionalChargesManagementComponent,
    ChosenPaymentMethodComponent,
    NotificationDialogComponent,
    AzulPaymentFormComponent,
    PaymentStatusTag,
    InfoBannerComponent,
    NavigationMenuComponent,
    CompanyInfoCardComponent,
    CollapsibleDescriptionComponent,
    ActivityBreadcrumbComponent,
    BannedMessageComponent,
    TriStateCheckboxComponent,
    AddressFormComponent,
    AddressFormDialogComponent,
    AddressListDialogComponent,
    ChosenServiceTypeComponent,
    AddressSelectorComponent,
    ChosenAddressComponent,
    ReportsCommentsViewComponent,
    ReportsCommentComponent,
    SliderPickerComponent,
    ActivityPickerComponent,
    PromoBannerComponent,
    PricingBlocksComponent,
    PaymentLinkFormComponent,
    PaymentLinkComponent,
    ActivityClonerComponent,
    HubClonerComponent,
    FrequentlyAskedQuestionsComponent,
    QuestionsSummaryViewComponent,
    ActivityListComponent,
    OrderListComponent,
    UrlHandlerCopyComponent,
    PaymentMethodsComponent,
    OrderNotificationButtonComponent,
    SocialShareBottomsComponent,
    MatureContentDialogComponent,
    ReportsLogsViewComponent,
    ConfirmationMessageManagementComponent,
  ],
})
export class ComponentsModule {}
