import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-event-stats-dialog',
  templateUrl: './event-stats-dialog.component.html',
  styleUrls: ['./event-stats-dialog.component.scss']
})
export class EventStatsDialogComponent {

  parentData: any;
  total: 0;

  constructor(
    public dialogRef: MatDialogRef<EventStatsDialogComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const { availableTicketCount, capacity } = data;
    this.parentData = [
      {
        "name": this.translate.instant('labels.remaining'),
        "value": availableTicketCount
      },
      {
        "name": this.translate.instant('labels.sold'),
        "value": (capacity - availableTicketCount)
      },
    ];
    this.total = capacity;
  }

  close(): void {
    this.dialogRef.close();
  }


  colorScheme = {
    domain: ['#5D6293', '#29A6A4', '#F59C00']
  };

}
