

<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <button (click)="close()" mat-button><span translate>tinyAll.back</span></button>
    <h2 class="title" translate>productList.orders</h2>
</div>

<div class="page-container" fxLayout="row wrap" fxLayoutAlign="center start">
    <app-menu-order-list-client-view 
        [activityId]="parentData.activityId"
        [activityType]="parentData.activityType"
        [hubId]="parentData.hubId"
        [user]="parentData.user"
        [size]="10"
        (cancel)="onCancel()">
    </app-menu-order-list-client-view>
</div>



