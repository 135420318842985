import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { QrCodeReaderService } from '../../service/qr-code-reader.service';
import isUrl from 'is-url';
import { Subscription } from 'rxjs';
import { isImage } from '../../util/image-utils';

@Component({
  selector: 'app-qr-reader',
  templateUrl: './qr-reader.component.html',
  styleUrls: ['./qr-reader.component.scss']
})
export class QrReaderComponent implements OnDestroy {

  @Input() expectedCode: string;
  @Output() onRead: EventEmitter<string> = new EventEmitter();

  scannerEnabled: boolean = true;
  qrCode: string = "";
  imageURL: string = "";
  validURL: boolean = true;
  invalidMenuCode: boolean = false;
  subscription: Subscription;

  constructor(
    protected qrCodeReaderService: QrCodeReaderService,
  ){}

  ngOnDestroy() {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public scanSuccessHandler($event: any) {
    this.scannerEnabled = false;
    this.invalidMenuCode = false;
    this.validURL = true;
    this.qrCode = $event;
    if(this.expectedCode && this.expectedCode !== $event) {
      this.invalidMenuCode = true;
      return;
    }

    if(!isUrl($event)) {
      this.validURL = false;
      return
    }
    this.onRead.emit($event);
  }

  public enableScanner() {
    this.imageURL = '';
    this.scannerEnabled = true;
    this.validURL = false;
    this.qrCode = '';
  }

  onFileChange(event) {
    const file = event.target.files[0];
    if(!isImage(file.name)) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      this.imageURL = e.target.result;
      this.subscription = this.qrCodeReaderService.decode(this.imageURL)
      .subscribe(decodedString => {
          this.scanSuccessHandler(decodedString);
          if(decodedString === "error decoding QR Code") {
            this.validURL = false;
            this.qrCode = '';
          }
      });
    };

  }

}
