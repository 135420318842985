<div fxLayout="column wrap" class="page-container">
    <div *ngIf="(items && items.length > 0) || loading; else noItems" class="list-container">
        <div *ngIf="loading && !settings.loadingSpinner; else noLoading" class="loading" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
            <mat-spinner color="primary" diameter="60"></mat-spinner>
        </div>
        <ng-template #noLoading>
            <div fxLayout="column" fxLayoutGap="15px">
                <ng-container *ngFor="let item of items" >
                    <app-cart-item *ngIf="products && products.get(item.productId) && products.get(item.productId).variants[item.variant]" 
                        [item]="item"
                        [product]="products.get(item.productId)"
                        [selected]="getId(selectedForm.get('id').value, selectedForm.get('variant').value?.description) === getId(item.productId, item.variant)"
                        [loading]="itemLoading"
                        [selectedForm]="selectedForm"
                        [currency]="currency"
                        (onQuantityChange)="quantityChange($event)"
                        (onRemoveFromCart)="removeFromCart()">
                    </app-cart-item>
                </ng-container>


            </div>
        </ng-template>
    </div>
    <ng-template #noItems>
        <div class="no-items-container">
            <fa-icon [icon]="faShoppingCart"></fa-icon>
            <p translate>
                productList.empty_cart_text
            </p>
        </div>
    </ng-template>
</div>
