
<div fxLayout="row" *ngIf="showTitle">
    <h2 class="title" translate>activityReservation.weekly_schedule<app-info-tooltip class="absolute-tooltip info-reservation-choose-hours" info="tooltip.reservation_choose_hours" infoClass="secondary"></app-info-tooltip></h2>
</div>
<mat-card class="custom-activity-card">
    <mat-card-content>
        <div fxLayout="row" [formGroup]="schedule">
            <div fxLayout="column" fxFlex>
                <div class="head" fxFlex fxLayoutAlign="center center">
            
                </div>
                <div class="hour-first" *ngFor="let hour of hours; let i = index;" fxFlex fxLayoutAlign="center center">
                    <span>{{getHourAMPM(hour)}}</span>
                </div>
            </div>
            <div fxLayout="column" *ngFor="let day of DAYS; let i = index" [formGroupName]="day" fxFlex>
                <div class="head" fxFlex fxLayoutAlign="center center">
                    {{weekDays[i]}}
                </div>
                <div class="hour" *ngFor="let hour of hours; let j = index;" fxFlex>
                    <mat-checkbox [formControlName]="hour" color="primary">&nbsp;
                        <mat-icon class="close">close</mat-icon>
                        <mat-icon class="open">check</mat-icon>
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>