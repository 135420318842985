import { 
    COMMA, 
    ENTER, 
    SPACE, 
    SLASH, 
    SEMICOLON,
    TAB
} from '@angular/cdk/keycodes';

export default new Array<number>(
    COMMA, 
    ENTER, 
    SPACE, 
    SLASH, 
    SEMICOLON,
    TAB,
);
