<div fxLayout="row" fxLayoutAlign="start center">
    <button class="close-button" (click)="close()" mat-icon-button>
        <mat-icon>close</mat-icon>
    </button>
</div>
<div fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="row" fxLayoutAlign="center center">
        <img class="icon" src="/assets/img/app/min-icon.png" alt="TinyAll" />
        <h1 class="premium-text">Premium</h1>
    </div>
    <img class="center-image" src="/assets/img/app/create-or-manage-hub.png" alt="hun manage" />
    <h1 translate class="premium-message">messages.premium</h1>
    <div class="content" mat-dialog-content>{{parentData.content | translate}}</div>
    <div fxLayout="row" fxLayoutAlign="center center" class="actions" mat-dialog-actions>
        <button class="premium-button" (click)="ok()" mat-raised-button><span translate>labels.see_plans</span></button>
    </div>
</div>