import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-dish-item-management',
  templateUrl: './dish-item-management.component.html',
  styleUrls: ['./dish-item-management.component.scss']
})
export class DishItemManagementComponent {

  @Input() itemForm: UntypedFormGroup;
  @Input() sectionName: any;
  @Input() index: number;
  @Output() onRemove: EventEmitter<void> = new EventEmitter();

  optionName: string;

  maxVariants = 20;
  images = ['']
  isOpen = false;

  constructor(
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
  ) {
    this.optionName = this.translate.instant('productList.variant');

  }

  ngOnInit(): void {
    if(!this.name) {
      this.isOpen = true;
    }
  }

  get name() {
    return this.itemForm.get('name').value;
  }

  get description() {
    return this.itemForm.get('description').value;
  }

  get available() {
    return this.itemForm.get('available').value;
  }

  get image() {
    return this.itemForm.get('image').value || '/assets/img/app/no-image.png';
  }

  getVariantsControls(): UntypedFormGroup[] {
    const OPTIONS =  this.itemForm.get('dishOptions') as UntypedFormArray;
    return OPTIONS.controls as UntypedFormGroup[];
  }

  getVariants(): UntypedFormArray {
    const OPTIONS =  this.itemForm.get('dishOptions') as UntypedFormArray;
    return OPTIONS;
  }

  createOption(optionName: string, price: String, available: boolean): UntypedFormGroup {
    return this.fb.group({
      description: [optionName, [Validators.required, RxwebValidators.unique()]],
      price: [price, [Validators.required]],
      available: available,
    });
  }

  focusOption(option: UntypedFormGroup) {
    const value: string = option.value.description;
    if (value && (value.startsWith('Variant') || value.startsWith('Variante'))) {
      option.controls.description.setValue('');
    }
  }

  addOption(): void {
    const optionsForm = this.getVariants();
    const OPTION = this.createOption(`${this.optionName} ${optionsForm.length + 1}`, '0.00', true);
    OPTION.get('description').markAsTouched();
    optionsForm.push(OPTION);
    this.itemForm.markAsDirty();
  }

  removeOption(optionIndex: number): void {
    const optionsForm = this.getVariants();
    if (optionsForm.length > 1) {
      optionsForm.removeAt(optionIndex);
      this.itemForm.markAsDirty();
      for (const control of optionsForm.controls) {
        control.get('description').updateValueAndValidity();
      }
    }
  }

  onImageChange(images: string[]) {
    this.itemForm.get('image').setValue(images[0] || '');
    this.itemForm.markAsDirty();
  }

  removeDish() {
    this.onRemove.emit();
  }

  get id() {
    return `${this.sectionName}${this.index}`;
  }

  onItemOpen(){
    setTimeout(() => {
      const element = document.getElementById(this.id);
      if (element !== null) {
        element.scrollIntoView();
      }
    }, 200);
  }

}
