<div
  class="flex xs:justify-between rounded-full items-center border-accent border-[3px] cursor-pointer px-6 py-1 mx-2 my-1"
  (click)="goTopPromoCode()"
>
  <div class="flex items-center justify-center h-[45px]">
    <img
      src="/assets/img/app/payment4.png"
      alt="Manage your order"
      class="object-contain h-full w-auto"
    />
  </div>
  <h3 class="text-accent font-semibold text-base ml-4 uppercase xs:mr-4 xs:text-center" translate>
    titles.hub_promo_codes
  </h3>
  <div class="hidden xs:flex items-center justify-center h-[45px]">
  </div>
</div>
