import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { RequestReportEntity } from '../../../../models/report';
import {
  ActivityRequestEntity,
  RequestEntity,
} from '../../../../models/activity-request';
import { ShortUrlHandlerComponent } from '../../../../components/short-url-handler/short-url-handler.component';
import { HubEntity } from '../../../../models/hub';
import { YesNoDialogComponent } from '../../../../components/yes-no-dialog/yes-no-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-request-request-general-info',
  templateUrl: './request-request-general-info.component.html',
  styleUrls: ['./request-request-general-info.component.scss'],
})
export class RequestRequestGeneralInfoComponent {
  @Input() hub: HubEntity;
  @Input() report: RequestEntity;
  @Input() reportDetail: RequestEntity;
  @Input() activity: ActivityRequestEntity;
  @Input() statusOptions: string[];
  @Input() status: UntypedFormControl;
  @Output() statusChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    private dialog: MatDialog,
  ) {}

  sendRequest() {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      minWidth: '240px',
      data: {
        title: 'messages.order_status_change',
      }
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.statusChanged.emit();
      } else {
        this.status.setValue(this.reportDetail.status);
      }
    });
  }
  
  getHubUrl() {
    return ShortUrlHandlerComponent.shortHubUrl(this.hub.urlHandler);
  }

  getActivityUrl() {
    return ShortUrlHandlerComponent.shortActCatUrl(this.report.activityId);
  }
}
