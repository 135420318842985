import { UntypedFormBuilder, UntypedFormArray, Validators, UntypedFormControl } from '@angular/forms';
import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-collaborators-management',
  templateUrl: './collaborators-management.component.html',
  styleUrls: ['./collaborators-management.component.scss']
})
export class CollaboratorsManagementComponent {


  @Input() form: UntypedFormArray;

  constructor(private fb: UntypedFormBuilder) {}

  get collaborators() {
    return this.form.controls as UntypedFormControl[]
  }

  add() {
    const fg = this.fb.control('', [Validators.email, Validators.required]);
    this.form.push(fg);
  }

  remove(index: number): void {
    this.form.removeAt(index);
    this.form.markAsDirty();
  }
}
