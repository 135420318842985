<div class="header mt-4" fxLayout="row" fxLayoutAlign="space-between center">
    <button (click)="back()" mat-button><span translate>tinyAll.back</span></button>
    <button class="text-accent" (click)="goToCreateActivity()" translate>titles.create_new_activity</button>
</div>
<div class="flex justify-between mt-4">
    <h2 class="title" translate>titles.sort_activities</h2>
    <button (click)="save()" mat-raised-button [disabled]="hasChenge.size === 0" color="accent"><span translate>tinyAll.save</span></button>
</div>
<div class="page-container" fxLayout="row wrap" fxLayoutAlign="center start">
    <div class="list-container">
        <div fxLayout="column" fxLayoutGap="5px">
            <div class="questions-boundary"
                cdkDropList 
                (cdkDropListDropped)="drop($event)"
                novalidate 
                fxLayout="column" 
                fxLayoutAlign="center">
                <div *ngFor="let item of items; let i = index;" 
                  cdkDragLockAxis="y" 
                  cdkDrag>
                    <mat-card class="card-container" cdkDragHandle>
                        <div fxLayout="row" class="hanlde-container">
                            <fa-icon class="handle-move" [icon]="faGripVertical"></fa-icon>
                            <h3>{{item.content.name}}</h3>
                        </div>
                    </mat-card>
    
                </div>
            </div>
        </div>
    </div>
</div>