import { Component, Input, OnInit } from "@angular/core";
import { environment } from "../../../environments/environment";
import { AppSettings } from "../../app.settings";
import { HttpService } from "../../service/http.service";
import { firstValueFrom } from "rxjs";
import { AuthService } from "../../auth/auth.serviceV2";
import { PageEntity } from "../../models/types";
import ActivityIconMap from "../../util/activity-icon-map";
import { RequestReportEntity } from "../../models/report";
import { ShortUrlHandlerComponent } from "../short-url-handler/short-url-handler.component";
import { ReportContentType } from "../../models/report-content-type-enum";

@Component({
  selector: "app-order-list",
  templateUrl: "./order-list.component.html",
  styleUrls: ["./order-list.component.scss"],
})
export class OrderListComponent implements OnInit {
  user = null;
  items: RequestReportEntity[] = [];
  originalItems: RequestReportEntity[] = [];
  filteredItems: RequestReportEntity[] = [];

  readonly activityIconMap = ActivityIconMap;
  readonly apiUrl = environment.apiUrl;

  settings;
  @Input() orderIds: string[] = [];
  searchField: string = "";

  constructor(
    private authService: AuthService,
    public httpService: HttpService,
    public appSettings: AppSettings,
  ) {
    this.settings = this.appSettings.settings;
  }

  async ngOnInit() {
    this.user = this.authService.getUserProfile();
    this.settings.loadingSpinner = true;
    await this.retrieveContent();
    this.settings.loadingSpinner = false;
  }

  get ReportContentType() {
    return ReportContentType;
  }

  clearSearchField() {
    this.searchField = "";
    this.filterItems();
  }

  getParamFromOrderIds(): string {
    return this.orderIds.reduce((prev, current, index) => {
      if (index > 0) {
        prev = prev + ' OR ';
      }
      prev = prev + `id:'${current}'`;
      return prev;
    } , '')
  }

  private async retrieveContent() {
    console.log(this.orderIds.length);
    if (this.orderIds.length < 1) return;
    try {
      const URL = `${this.apiUrl}/request-report/v1/search?page=0&size=500&querySearch=(${this.getParamFromOrderIds()})&sort=order,asc`;

      const response: PageEntity<RequestReportEntity> = await firstValueFrom(
        this.httpService.doGet(URL)
      );
      if (response.content && response.content.length > 0) {
        this.originalItems = response.content;
        this.filterItems();
      }
    } catch (error) {
      console.error("Error getting Orders: ", error);
    }
  }

  filterItems() {
    let temItems: RequestReportEntity[] = [...this.originalItems];
    if (this.searchField.length > 2) {
      const localSearchField = this.searchField.toLocaleLowerCase();
      temItems = temItems.filter((item: RequestReportEntity) => {
        if (
          (item.content.activityName && !item.content.activityName.toLocaleLowerCase().startsWith(localSearchField)) || 
          (item.content.categoryName && !item.content.categoryName.toLocaleLowerCase().startsWith(localSearchField)) ||
          (item.content.clientName && !item.content.clientName.toLocaleLowerCase().startsWith(localSearchField))
        ) {
          return false;
        }
        return true;
      });
    }
    this.filteredItems = temItems.filter((item: RequestReportEntity) => {
      return !this.items.find(
        (innerItem: RequestReportEntity) =>
          innerItem.id === item.id
      );
    });
  }

  getOrderUrl(hubHandler: string, orderId: string) {
    return ShortUrlHandlerComponent.longOrderUrl(hubHandler, orderId);
  }
}
