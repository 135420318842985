import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { isWithinInterval, parseISO } from 'date-fns';
import CURRENCY_SYMBOLS from '../../util/currency-symbols';
import { Subscription, firstValueFrom } from 'rxjs';
import { HttpService } from '../../service/http.service';
import { environment } from '../../../environments/environment';
import { HubPromoCodeEntity } from '../../models/hub-promo-code';
import { PromoCodeStatus } from '../../models/promo-code-status-enum';

@Component({
  selector: 'app-questions-summary',
  templateUrl: './questions-summary.component.html',
  styleUrls: ['./questions-summary.component.scss'],
})
export class QuestionsSummaryComponent implements OnInit, OnDestroy {
  readonly apiUrl = environment.apiUrl;

  @Input() entityId;
  @Input() hubId;
  @Input() questionAnswerMap: UntypedFormGroup;
  @Input() totalPrice: UntypedFormControl;
  @Input() originalQuestions: any = [];
  @Input() currency: string = 'DOP';
  @Input() hidden: boolean;
  @Input() additionalCharges = {};
  @Input() itemLabel = 'productList.products';
  @Input() set items(value: string | number) {
    this.itemsValue = Number(value || 0);
    this.getTotal();
  }
  @Input() quantity = 0;
  @Input() shipping: string | number = 0;
  @Input() tip = 0;
  @Input() tipForm: UntypedFormGroup;
  @Input() promoCodeForm: UntypedFormGroup;

  readonly currencySymbols = CURRENCY_SYMBOLS;
  readonly tips = [0, 10, 15, 20];

  itemsValue = 0;
  total = 0;
  topDiscount = 0;
  discountPersentage = 0;
  hasPrice = false;
  formSubs: Subscription;
  formSubs2: Subscription;

  validatingPromo = false;

  constructor(private httpService: HttpService) {}

  get promoCode() {
    return this.promoCodeForm.get('promoCode');
  }

  get promoCodeValue() {
    return this.promoCodeForm.get('promoCode')?.value;
  }

  get promoCodeApplied() {
    return this.promoCodeForm.get('promoCodeApplied');
  }

  get promoCodeAppliedValue() {
    return this.promoCodeForm.get('promoCodeApplied')?.value;
  }

  applyClearPromoCode() {
    if (this.promoCodeAppliedValue) {
      this.clearPromoCode();
    } else {
      this.applyPromoCode();
    }
  }

  clearPromoCode() {
    this.promoCode.setValue(null);
    this.promoCode.setErrors(null);
    this.promoCode.enable();
    this.promoCodeApplied.setValue(false);
    this.discountPersentage = 0;
    this.topDiscount = 0;
    this.getTotal();
  }

  clearPromoCodeFromEvent() {
    this.discountPersentage = 0;
    this.topDiscount = 0;
    this.getTotal();
  }

  async applyPromoCode() {
    if (this.validatingPromo || !this.promoCodeValue) return;
    if (this.promoCodeValue.length < 6) {
      this.promoCode.setErrors({
        promoCodeLenthError: true,
      });
      return;
    }
    this.validatingPromo = true;
    try {
      const URL = `${this.apiUrl}/hub-promo-code/v1/searchByPromoCode?promoCode=${this.promoCodeValue}&hubId=${this.hubId}`;
      const response: HubPromoCodeEntity = await firstValueFrom(
        this.httpService.doGet(URL)
      );
      const {
        linkedActivities,
        status,
        usesCount,
        numberOfUses,
        startDate,
        endDate,
      } = response;

      if (status != PromoCodeStatus.ACTIVE) {
        this.promoCode.setErrors({
          inactivePromoCodeError: true,
        });
        return;
      } else if (usesCount >= numberOfUses) {
        this.promoCode.setErrors({
          overUsagePromoCodeError: true,
        });
        return;
      } else if (
        !isWithinInterval(new Date(), {
          start: parseISO(startDate),
          end: parseISO(endDate),
        })
      ) {
        this.promoCode.setErrors({
          outOfRangePromoCodeError: true,
        });
        return;
      } else if (
        !linkedActivities ||
        !linkedActivities.includes(this.entityId)
      ) {
        this.promoCode.setErrors({
          invalidEntityPromoCodeError: true,
        });
        return;
      }
      this.promoCode.setErrors(null);
      this.promoCodeApplied.setValue(true);
      this.promoCode.disable();
      const { discount, topDiscount } = response;
      this.topDiscount = topDiscount || 0;
      this.discountPersentage = discount;
    } catch (error) {
      this.promoCode.setErrors({
        promoCodeError: true,
      });
    } finally {
      this.validatingPromo = false;
    }
    this.getTotal();
  }

  async applyPromoCodeFromEvent() {
    if (this.validatingPromo || !this.promoCodeValue) return;
    if (this.promoCodeValue.length < 6) {
      return;
    }
    this.validatingPromo = true;
    try {
      const URL = `${this.apiUrl}/hub-promo-code/v1/searchByPromoCode?promoCode=${this.promoCodeValue}&hubId=${this.hubId}`;
      const response: HubPromoCodeEntity = await firstValueFrom(
        this.httpService.doGet(URL)
      );
      const {
        linkedActivities,
        status,
        usesCount,
        numberOfUses,
        startDate,
        endDate,
      } = response;

      if (status != PromoCodeStatus.ACTIVE) {
        this.promoCode.setErrors({
          inactivePromoCodeError: true,
        });
        return;
      } else if (usesCount >= numberOfUses) {
        return;
      } else if (
        !isWithinInterval(new Date(), {
          start: parseISO(startDate),
          end: parseISO(endDate),
        })
      ) {
        return;
      } else if (
        !linkedActivities ||
        !linkedActivities.includes(this.entityId)
      ) {
        return;
      }
      const { discount } = response;
      this.discountPersentage = discount;
    } catch (error) {
    } finally {
      this.validatingPromo = false;
    }
    this.getTotal();
  }


  ngOnInit() {
    this.formSubs = this.questionAnswerMap.valueChanges.subscribe((val) => {
      this.getTotal();
    });
    this.formSubs2 = this.promoCodeApplied.valueChanges.subscribe((val) => {
      if (val) {
        this.applyPromoCodeFromEvent();
      } else {
        this.clearPromoCodeFromEvent();
      }
    });
    this.getTotal();
  }

  ngOnDestroy(): void {
    if (this.formSubs) {
      this.formSubs.unsubscribe();
    }
    if (this.formSubs2) {
      this.formSubs2.unsubscribe();
    }
  }

  uncheckRadio(value) {
    if (value === this.tipForm.get('tip').value) {
      setTimeout(() => {
        this.tipForm.reset();
        this.getTotal();
      }, 300);
    } else {
      setTimeout(() => {
        this.getTotal();
      }, 300);
    }
  }

  getTips(total: number) {
    let selectedTip = 0;
    if (this.tipForm) {
      selectedTip = (total * this.tipForm.get('tip').value) / 100 || 0;
    }
    return Number(selectedTip);
  }

  getQuestionRows() {
    const questionRows = [];
    for (const QUESTION of this.originalQuestions) {
      if (QUESTION.type === 'SINGLE_CHOICE_PRICE') {
        const option = this.questionAnswerMap.controls[QUESTION.question].value;
        const value = Number(QUESTION.values[option]);
        if (option) {
          questionRows.push({ name: option, value });
        }
      } else if (QUESTION.type === 'MULTI_CHOICE_PRICE') {
        const options =
          this.questionAnswerMap.controls[QUESTION.question].value;
        if (options && options.length > 0) {
          for (const OPTION of options) {
            const value = Number(QUESTION.values[OPTION]);
            questionRows.push({ name: OPTION, value });
          }
        }
      } else if (QUESTION.type === 'GROUP') {
        const VALUE = this.questionAnswerMap.controls[QUESTION.question].value;
        if (VALUE) {
          for (const INNER_QUESTION of QUESTION.groups[VALUE]) {
            if (INNER_QUESTION.type === 'SINGLE_CHOICE_PRICE') {
              const INNER_OPTION =
                this.questionAnswerMap.controls[INNER_QUESTION.question].value;
              const value = Number(INNER_QUESTION.values[INNER_OPTION]);
              if (INNER_OPTION) {
                questionRows.push({ name: INNER_OPTION, value });
              }
            } else if (INNER_QUESTION.type === 'MULTI_CHOICE_PRICE') {
              const INNER_VALUE =
                this.questionAnswerMap.controls[INNER_QUESTION.question].value;
              if (INNER_VALUE && INNER_VALUE.length > 0) {
                for (const INNER_OPTION of INNER_VALUE) {
                  const value = Number(INNER_QUESTION.values[INNER_OPTION]);
                  questionRows.push({ name: INNER_OPTION, value });
                }
              }
            }
          }
        }
      }
    }
    return questionRows;
  }

  getQuestionsTotal() {
    this.getQuestionRows();
    let total = 0;
    for (const QUESTION of this.originalQuestions) {
      if (QUESTION.type === 'SINGLE_CHOICE_PRICE') {
        const VALUE = this.questionAnswerMap.controls[QUESTION.question].value;
        if (VALUE) {
          total += Number(QUESTION.values[VALUE]);
        }
      } else if (QUESTION.type === 'MULTI_CHOICE_PRICE') {
        const VALUE = this.questionAnswerMap.controls[QUESTION.question].value;
        if (VALUE && VALUE.length > 0) {
          for (const OPTION of VALUE) {
            total += Number(QUESTION.values[OPTION]);
          }
        }
      } else if (QUESTION.type === 'GROUP') {
        const VALUE = this.questionAnswerMap.controls[QUESTION.question].value;
        if (VALUE) {
          for (const INNER_QUESTION of QUESTION.groups[VALUE]) {
            if (INNER_QUESTION.type === 'SINGLE_CHOICE_PRICE') {
              const INNER_VALUE =
                this.questionAnswerMap.controls[INNER_QUESTION.question].value;
              if (INNER_VALUE) {
                total += Number(INNER_QUESTION.values[INNER_VALUE]);
              }
            } else if (INNER_QUESTION.type === 'MULTI_CHOICE_PRICE') {
              const INNER_VALUE =
                this.questionAnswerMap.controls[INNER_QUESTION.question].value;
              if (INNER_VALUE && INNER_VALUE.length > 0) {
                for (const INNER_OPTION of INNER_VALUE) {
                  total += Number(INNER_QUESTION.values[INNER_OPTION]);
                }
              }
            }
          }
        }
      }
    }
    return total;
  }

  getAdditionalChargesTotal() {
    return Object.values<number>(this.additionalCharges || {}).reduce(
      (prev: number, current: number) => prev + current,
      0
    );
  }

  getTotalNoTips() {
    return (
      this.getQuestionsTotal() +
      this.getAdditionalChargesTotal() +
      Number(this.itemsValue) +
      Number(this.shipping)
    );
  }

  getPromoDiscount() {
    if (this.discountPersentage <= 0) return 0;
    const discount = this.getTotalNoTips() * (this.discountPersentage / 100);
    return this.topDiscount > 0 ? Math.min(discount, this.topDiscount) : discount;
  }

  getTotal() {
    this.total = this.getTotalNoTips() - this.getPromoDiscount();
    this.total += this.getTips(this.total) + Number(this.tip);
    this.hasPrice = this.total > 0;
    this.totalPrice?.setValue(this.total);
    return this.total;
  }
}
