<mat-card *ngIf="links.length > 0">
    <mat-card-content>
        <div class="subtitle">
            {{'subtitles.custom_links' | translate}}
        </div>
        <ng-container *ngFor="let key of links">
            <mat-divider></mat-divider>
            <div class="p-1 link-container">
                <fa-icon matPrefix class="front-icon" [icon]="faLink"></fa-icon>
                <a class="link" [href]="customLinks[key]" target="_blank">
                    {{key}}
                </a>
            </div>
        </ng-container>
    </mat-card-content>
</mat-card>