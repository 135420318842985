import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { BaseFormComponent } from '../pages/baseform.component';
import { SortActCatManagementComponent } from '../components/sort-act-cat-management/sort-act-cat-management.component';
import { SortHubMenuManagementComponent } from '../components/sort-hub-menu-management/sort-hub-menu-management.component';

@Injectable()
export class FormGuard implements CanDeactivate<BaseFormComponent> {

    canDeactivate(component: BaseFormComponent | SortActCatManagementComponent | SortHubMenuManagementComponent): boolean | any {
        return component.canGoBack();
    }
}
