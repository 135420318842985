import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { ServiceModule } from '../../service/service.module';
import { AuthGuard } from '../../auth/auth.guard';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ComponentsModule } from '../../components/componets.module';
import { DirectivesModule } from '../../directives/directives.module';
import { FormGuard } from '../../guards/form.guard';
import { ActivityMenuManagementComponent } from './activity-menu-management/activity-menu-management.component';

import { MenuSectionListManagementComponent } from './components/menu-section-list-management/menu-section-list-management.component';
import { MenuSectionListClientViewComponent } from './components/menu-section-list-client-view/menu-section-list-client-view.component';

import { DishListManagementComponent } from './components/dish-list-management/dish-list-management.component';
import { DishItemManagementComponent } from './components/dish-item-management/dish-item-management.component';

import { DishListClientViewComponent } from './components/dish-list-client-view/dish-list-client-view.component';
import { DishItemClientViewComponent } from './components/dish-item-client-view/dish-item-client-view.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { TableDishComponent } from './components/table-dish/table-dish.component';
import { TableDishesListComponent } from './components/table-dishes-list/table-dishes-list.component';
import { ActivityMenuClientViewComponent } from './activity-menu-client-view/activity-menu-client-view.component';
import { MenuOrderDetailsClientViewComponent } from './components/menu-order-details-client-view/menu-order-details-client-view.component';
import { MenuOrderListClientViewComponent } from './components/menu-order-list-client-view/menu-order-list-client-view.component';
import { MenuOrderDetailsClientViewDialogComponent } from './components/menu-order-details-client-view-dialog/menu-order-details-client-view-dialog.component';
import { MenuOrderListClientViewDialogComponent } from './components/menu-order-list-client-view-dialog/menu-order-list-client-view-dialog.component';
import { ServicesTypeManagementComponent } from './components/services-type-management/services-type-management.component';
import { SelectServiceTypeComponent } from './components/select-service-type/select-service-type.component';

export const routes = [
  { path: 'client-view', component: ActivityMenuClientViewComponent },
  {
    path: 'manage',
    component: ActivityMenuManagementComponent,
    canActivate: [AuthGuard],
    canDeactivate: [FormGuard],
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ComponentsModule,
    DirectivesModule,
    ServiceModule,
    RouterModule.forChild(routes),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    DragDropModule,
  ],
  providers: [],
  declarations: [
    ActivityMenuManagementComponent,
    MenuSectionListManagementComponent,
    DishListManagementComponent,
    DishItemManagementComponent,
    TableDishComponent,
    TableDishesListComponent,
    ActivityMenuClientViewComponent,
    MenuSectionListClientViewComponent,
    DishListClientViewComponent,
    DishItemClientViewComponent,
    MenuOrderListClientViewComponent,
    MenuOrderDetailsClientViewDialogComponent,
    MenuOrderDetailsClientViewComponent,
    MenuOrderListClientViewDialogComponent,
    ServicesTypeManagementComponent,
    SelectServiceTypeComponent,
  ],
  exports: [
    ActivityMenuManagementComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ActivityMenuModule {}
