<div fxLayout="row">
    <h2 class="title" translate>titles.address</h2>
</div>

<div fxLayout="row wrap" fxLayoutAlign="center start">
  <mat-card *ngIf="chosenLocation" class="location-card-container">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div class="p-1">
        <span class="label">{{ chosenLocation.name }}</span>
        <p>
          {{ chosenLocation.address.address }},
          {{ chosenLocation.address.address2 }}
        </p>
        <p>
          {{ chosenLocation.address.city }},
          {{ chosenLocation.address.stateOrProvidence }},
          {{ getCountryName(chosenLocation.address.country) }}
        </p>
        <p>{{ chosenLocation.address.zipCode }}</p>
        <p>{{ chosenLocation.address.locationHint }}</p>
      </div>
    </div>
    <app-location-map
      *ngIf="chosenLocation.location"
      [location]="chosenLocation.location"
      [noCard]="true"
      [launch]="true"
    >
    </app-location-map>
  </mat-card>
</div>
