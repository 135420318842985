import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.serviceV2';
import {RouteConstants} from '../app.constants';


@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActiveInternal(state);
    }

    private async canActiveInternal(state: RouterStateSnapshot): Promise<boolean> {
        let isAuth = false;
        try {
            isAuth = await this.authService.isAuthenticated();
        } catch (error) {
            console.error(error);
        }
        if (!isAuth) {
            localStorage.setItem(RouteConstants.returnUrl, state.url);
            await this.router.navigate([RouteConstants.auth]);
            return false;
        }
        return true;
    }
}
