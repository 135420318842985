import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { RouteConstants } from '../../../app.constants';
import { Meta, Title } from '@angular/platform-browser';
import { setDefaultPageHead } from '../../../util/seo-utils';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent implements AfterViewInit {
  public settings: Settings;

  constructor(
    public appSettings: AppSettings,
    public router: Router,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    this.settings = this.appSettings.settings;
    setDefaultPageHead(this.titleService, this.metaTagService, true);
  }

  searchResult(): void {
    this.router.navigate([RouteConstants.searchVenue]);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    }, 300);
  }
}
