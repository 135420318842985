import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-warning-message',
  templateUrl: './warning-message.component.html',
  styleUrls: ['./warning-message.component.scss']
})
export class WarningMessageComponent {
  @Input() message: string;
  @Input() href?: string;
  @Input() linkLabel?: string;
}
