import { Component, Inject} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CreateAbuseReportCommand } from '../../models/abuse-report';
import { HttpService } from '../../service/http.service';
import { EntityType } from '../../models/entity-type-enum';

@Component({
  selector: 'app-report-abuse-dialog',
  templateUrl: './report-abuse-dialog.component.html',
  styleUrls: ['./report-abuse-dialog.component.scss']
})
export class ReportAbuseDialogComponent {
  private readonly apiUrl = environment.apiUrl;
  EntityType = EntityType;

  noImage = '/assets/img/app/no-image.png';
  parentData: any;
  form: UntypedFormGroup;
  loading: boolean = false;
  entity;
  expanded = false;

  constructor(
    private httpService: HttpService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ReportAbuseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
      this.parentData = data;
      this.entity = this.parentData.entity;
      this.form = this.fb.group({
        message: ['', [Validators.required, Validators.maxLength(300), Validators.minLength(20)]],
        canBeContact: [true],
        entityName: [this.parentData.entityName],
        entityType: [this.parentData.entityType],
        entityId: [this.parentData.entityId],
        userId: [this.parentData.user.userId],
      });
  }

  async send() {
    const url = `${this.apiUrl}/admin-center/abuse/v1`;
    this.loading = true;
    const rawValues = this.form.getRawValue();
    rawValues['message'] = rawValues['message'].trim();
    const {
      entityId,
      entityType,
      entityName,
      message,
    } = rawValues;

    const requestBody: CreateAbuseReportCommand = {
      entityId,
      entityType,
      entityName,
      message,
    };
    
    try {
      await firstValueFrom(this.httpService.doPost(url, requestBody));
      this.loading = false;
      this.showSnackBar(this.translate.instant('messages.thank_you_for_your_report'), 'OK', 2000);
      this.dialogRef.close(true);
    } catch (error) {
      this.loading = false;
      console.log('Error: ', error);
      this.showSnackBar(this.translate.instant('messages.error_processing_request'), 'OK', 2000);
    }
  }

  get message() {
    return this.form.get('message');
  }

  close(): void {
    this.dialogRef.close();
  }

  showSnackBar(message, action, duration, panelClass = '') {
    this.snackBar.open(message, action, {
      duration: duration,
      panelClass
    });
  }

  isActivity(type: EntityType): boolean {
    return  [
      EntityType.ACTIVITY_INFO,
      EntityType.ACTIVITY_MENU,
      EntityType.ACTIVITY_PRODUCT_LISTING,
      EntityType.ACTIVITY_REQUEST,
      EntityType.ACTIVITY_RESERVATION,
      EntityType.ACTIVITY_EVENT,
      EntityType.CATEGORY,
    ].includes(type);
  }

}
