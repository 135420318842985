import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppSettings } from '../../app.settings';
import { ImageService } from '../../service/image.service';
import { DatetimeAdapter } from '@mat-datetimepicker/core';
import { AuthService } from '../../auth/auth.serviceV2';
import { Router } from '@angular/router';
import { RouteConstants } from '../../app.constants';
import { MatDialog } from "@angular/material/dialog";
import { LocationPickerDialogComponent } from '../location-picker-dialog/location-picker-dialog.component';
import { environment } from '../../../environments/environment';
import { isImage } from '../../util/image-utils';

@Component({
  selector: 'app-question-list-client-view',
  templateUrl: './question-list-client-view.component.html',
  styleUrls: ['./question-list-client-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuestionListClientViewComponent implements OnInit {
  @Input()
  form: UntypedFormGroup;

  @Input()
  originalQuestions: any;

  @Input()
  showMap = true;
  settings = null;
  noGeolocationPermissionsMessage = '';

  private readonly validateNumber = /^[0-9]+$/;
  readonly maxImageSize = environment.maxImageSizeInMB;

  maxSizeErrors = {};

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    public router: Router,
    private appSettings: AppSettings,
    protected imageService: ImageService,
    protected snackBar: MatSnackBar,
    public _adapter: DatetimeAdapter<Date>,
    public dialog: MatDialog
  ) {
    this.settings = this.appSettings.settings;
    _adapter.setLocale(translate.getDefaultLang());
    (_adapter as any)._delegate.setLocale(translate.getDefaultLang());
    this.noGeolocationPermissionsMessage = this.translate.instant(
      'questionsDialog.no_geolocation_permissions'
    );
  }

  ngOnInit(): void {
  }

  async setLocation() {
    this.settings.loadingSpinner = true;
    if (navigator.geolocation) {
      const permissionStatus = await navigator.permissions.query({
        name: 'geolocation',
      });
      if (permissionStatus && permissionStatus.state === 'denied') {
        this.showSnackBar(this.noGeolocationPermissionsMessage, 'Error', 2000);
        return;
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            coordinates: [position.coords.latitude, position.coords.longitude],
            type: 'Point',
          };
          this.form.patchValue({ location: location });
          this.form.markAsDirty();
          /*this.map.setView([position.coords.latitude, position.coords.longitude]);
        L.marker([position.coords.latitude, position.coords.longitude])
          .addTo(this.map)
          .bindPopup("Your Location")
          .openPopup();*/
          this.settings.loadingSpinner = false;
        },
        (error) => {
          this.settings.loadingSpinner = false;
          console.log('Geo position not found');
        }
      );
    } else {
      this.settings.loadingSpinner = false;
      console.log('Geo location not found');
    }
  }

  uncheckRadio(value: string, question: string) {
    const fg = this.form.get('questionAnswerMap') as UntypedFormGroup;
    if (value === fg.controls[question].value) {
      setTimeout(() => {
        fg.controls[question].reset();
      }, 300);
    }
  }

  openMap(questionItem) {
    const dialogRef = this.dialog.open(LocationPickerDialogComponent, {
      width: "100vw",
      height: "100vh",
      maxWidth: "100vw",
      maxHeight: "100vh",
      panelClass: "location-picker-dialog",
      data: ( questionItem.location ? { location:questionItem.location } : {}),
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
       questionItem.location = result.location;
      const formGroup = this.form.get('questionAnswerMap') as UntypedFormGroup;
      formGroup.controls[questionItem.question].setValue(result.location );
      }
    });
  }

  uncheckConditionalRadio(value: string, question: string, index: number) {
    const fg = this.form.get('questionAnswerMap') as UntypedFormGroup;
    if (value === fg.controls[question].value) {
      setTimeout(() => {
        fg.controls[question].reset();
        for (const KEY of Object.keys(this.originalQuestions[index].groups)) {
          for (const INNER_QUESTION of this.originalQuestions[index].groups[
            KEY
          ]) {
            fg.controls[INNER_QUESTION.question].clearValidators();
            fg.controls[INNER_QUESTION.question].setErrors(null);
          }
        }
        fg.updateValueAndValidity();
      }, 300);
    }
  }

  questionValue(question: string): any {
    const fg = this.form.get('questionAnswerMap') as UntypedFormGroup;
    return fg.controls[question].value;
  }

  questionIsTouched(question: string): boolean {
    const fg = this.form.get('questionAnswerMap') as UntypedFormGroup;
    return fg.controls[question].touched;
  }

  questionHasError(question: string): boolean {
    const fg = this.form.get('questionAnswerMap') as UntypedFormGroup;
    return fg.controls[question].hasError('required');
  }

  getGroupQuestionOptions(index: number): any {
    return Object.keys(this.originalQuestions[index].groups);
  }

  getPriceQuestionOptions(index: number): any {
    return Object.keys(this.originalQuestions[index].values);
  }

  getPriceQuestionOptionsValue(index: number, key: string): any {
    return this.originalQuestions[index].values[key];
  }

  getQuestionOptions(index: number): any {
    return this.originalQuestions[index].choices;
  }

  getInnerQuestionOptions(
    index: number,
    option: string,
    innerIndex: number
  ): any {
    return this.originalQuestions[index].groups[option][innerIndex].choices;
  }

  getPriceInnerQuestionOptions(
    index: number,
    option: string,
    innerIndex: number
  ): any {
    return Object.keys(
      this.originalQuestions[index].groups[option][innerIndex].values
    );
  }

  getPriceInnerQuestionOptionsValue(
    index: number,
    option: string,
    innerIndex: number,
    key: string
  ): any {
    return this.originalQuestions[index].groups[option][innerIndex].values[key];
  }

  conditionalChange(event, index: number) {
    const fg = this.form.get('questionAnswerMap') as UntypedFormGroup;
    for (const KEY of Object.keys(this.originalQuestions[index].groups)) {
      for (const INNER_QUESTION of this.originalQuestions[index].groups[KEY]) {
        fg.controls[INNER_QUESTION.question].clearValidators();
        fg.controls[INNER_QUESTION.question].setErrors(null);
        if (event.value === KEY && INNER_QUESTION.required) {
          fg.controls[INNER_QUESTION.question].setValidators(
            Validators.required
          );
        }
      }
    }
    fg.updateValueAndValidity();
  }

  async saveImage(image: FileList, question: string) {
    if(!isImage(image.item(0).name)) {
      return;
    }
    this.settings.loadingSpinner = true;
    const fg = this.form.get('questionAnswerMap') as UntypedFormGroup;
    const answer = fg.controls[question];
    if (answer.value) {
      this.imageService.deleteImage(answer.value).subscribe(
        (result) => {
          // nothing to do
        },
        (error) => {
          this.settings.loadingSpinner = false;
          this.showSnackBar('Error Processing Your Request', 'OK', 2000);
          console.error('Image error: ', error);
        }
      );
    }

    const fileSize = image.item(0).size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb > this.maxImageSize) {
      this.maxSizeErrors[question] = true;
      answer.reset();
      answer.markAsDirty();
      this.settings.loadingSpinner = false;
      return;
    } else {
      this.maxSizeErrors[question] = false;
    }

    try {
      const url = await this.imageService.uploadImage(image.item(0));
      answer.setValue(url);
      answer.markAsDirty();
      this.settings.loadingSpinner = false;
    } catch (error) {
      this.settings.loadingSpinner = false;
      this.showSnackBar('Error Processing Your Request', 'OK', 2000);
      console.error('Image error: ', error);
    }
  }

  removeImage(question: string) {
    this.settings.loadingSpinner = true;
    const fg = this.form.get('questionAnswerMap') as UntypedFormGroup;
    const answer = fg.controls[question];
    this.imageService.deleteImage(answer.value).subscribe(
      (result) => {
        answer.setValue('');
        answer.markAsDirty();
        this.settings.loadingSpinner = false;
      },
      (error) => {
        this.settings.loadingSpinner = false;
        this.showSnackBar('Error Processing Your Request', 'OK', 2000);
        console.error('Image error: ', error);
      }
    );
  }

  showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  async uploadImage($event, imagesInput) {
    if (!(await this.authService.isAuthenticated())) {
      localStorage.setItem('return_url', this.router.url);
      localStorage.setItem('return_from_login_url', this.router.url);
      await this.router.navigate([RouteConstants.auth]);
      return;
    }
    imagesInput.click(); 
    $event.preventDefault();
  }

  validateNumberKey(event: any): void {
    if (event.keyCode == 69 || event.key == 'e' || event.key == 'E') {
      event.preventDefault();
    }
  }
}
