<mat-card [id]="item.id" class="item-card">
    <div class="closed">
        <mat-card-header class="header" *ngIf="product">
            <img mat-card-avatar class="avatar" 
            [src]="product.images[0] || noImage" alt="product" />
            <mat-card-title>{{product.name}}</mat-card-title>
            <mat-card-subtitle class="description">{{product.infoText}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-header class="header" *ngIf="!product">
            <img mat-card-avatar class="avatar" 
            [src]="noImage" alt="placeholder" />
            <mat-card-title>-</mat-card-title>
            <mat-card-subtitle class="description">...</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="content">
            <mat-card-subtitle fxLayout="row nowrap" fxLayoutAlign="space-around center">
                <span class="variant">{{item.itemOption.description}}</span>&nbsp;
                <span class="count"><span translate>productList.quantity</span>: {{item.amount}}</span>
            </mat-card-subtitle>  
            <mat-card-subtitle class="quantity" fxLayout="row nowrap" fxLayoutAlign="space-around center">
                <span class="unit"><span translate>productList.unit</span>: {{item.itemOption.price | currency:currency:currencySymbols[currency]}}</span>
                <span class="price"><span translate>productList.total</span>: {{getTotal(item.itemOption.price, item.amount) | currency:currency:currencySymbols[currency]}}</span>
            </mat-card-subtitle>  
        </mat-card-content>
    </div>
</mat-card>