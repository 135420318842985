<div fxLayout="row">
    <h2 class="title" translate>titles.select_payment_method</h2>
</div>

<div fxLayout="row wrap" fxLayoutAlign="center start">
    <div class="c-flex-p" fxFlex="100">
        <mat-card class="select-card">
            <mat-card-content>
                <form novalidate *ngIf="form" [formGroup]="form">
                    <mat-radio-group formControlName="chosenPaymentType" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center" (change)="changePaymentType($event)">
                        <div class="select-options" *ngIf="isPaymentTypeSupported(PaymentType.CASH)">
                            <mat-radio-button [value]="PaymentType.CASH" mb-6>
                                <span translate>labels.deliverypay</span>
                                <fa-icon class="method-icon" [icon]="faMoneyBillAlt"></fa-icon>
                            </mat-radio-button>
                            <mat-divider></mat-divider>
                        </div>
                        <div class="select-options" *ngIf="isPaymentTypeSupported(PaymentType.CC_DELIVERY)">
                            <mat-radio-button [value]="PaymentType.CC_DELIVERY" mb-6>
                                <span translate>labels.cc_delivery_pay</span>
                                <fa-icon class="method-icon" [icon]="faCreditCard"></fa-icon>
                            </mat-radio-button>
                            <mat-divider></mat-divider>
                        </div>
                        <div class="select-options" *ngIf="isPaymentTypeSupported(PaymentType.CC_IN_ADVANCE)">
                            <mat-radio-button [value]="PaymentType.CC_IN_ADVANCE" mb-6>
                                <span translate>labels.cc_in_advance_pay</span>
                                <fa-icon class="method-icon" [icon]="faCreditCard"></fa-icon>
                            </mat-radio-button>
                            <mat-divider></mat-divider>
                        </div>
                        <div class="select-options" *ngIf="isPaymentTypeSupported(PaymentType.PAYMENT_LINK)">
                            <mat-radio-button [value]="PaymentType.PAYMENT_LINK" mb-6>
                                <span translate>labels.payment_link</span>
                                <fa-icon class="method-icon" [icon]="faLink"></fa-icon>
                            </mat-radio-button>
                            <mat-divider></mat-divider>
                        </div>
                        <div class="select-options" *ngIf="isPaymentTypeSupported(PaymentType.BANK_TRANSFERENCE)">
                            <mat-radio-button [value]="PaymentType.BANK_TRANSFERENCE" mb-6>
                                <span translate>labels.banktransfer</span>
                                <fa-icon class="method-icon" [icon]="faWallet"></fa-icon>
                            </mat-radio-button>
                        </div>
                        <!-- TODO: TinyAll Pay -->
                        <!--  <div class="select-options" *ngIf="isPaymentTypeSupported(PaymentType.TINYALL_PAY)">
                            <mat-radio-button [value]="PaymentType.TINYALL_PAY" mb-6>
                                <span translate>labels.tinyallpay</span>
                                <fa-icon class="method-icon" [icon]="faCreditCard"></fa-icon>
                            </mat-radio-button>
                            <mat-divider></mat-divider>
                        </div> -->
                    </mat-radio-group>
                </form>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="c-flex-p" fxFlex="100" *ngIf="selectedPaymentType.value === PaymentType.CASH">
        <mat-card class="cash-card">
            <mat-card-content>
                <form novalidate *ngIf="form" [formGroup]="form">
                    <mat-radio-group formControlName="cashReturn" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center">
                        <div class="select-options">
                            <mat-radio-button [value]="CashReturn.TWO_THOUSAND" mb-6>
                                <span translate>$ 2,000</span>
                            </mat-radio-button>
                            <mat-divider></mat-divider>
                        </div>
                        <div class="select-options">
                            <mat-radio-button [value]="CashReturn.ONE_THOUSAND" mb-6>
                                <span translate>$ 1,000</span>
                            </mat-radio-button>
                            <mat-divider></mat-divider>
                        </div>
                        <div class="select-options">
                            <mat-radio-button [value]="CashReturn.FIVE_HUNDRED" mb-6>
                                <span translate>$ 500</span>
                            </mat-radio-button>
                            <mat-divider></mat-divider>
                        </div>
                        <div class="select-options">
                            <mat-radio-button [value]="CashReturn.TWO_HUNDRED" mb-6>
                                <span translate>$ 200</span>
                            </mat-radio-button>
                            <mat-divider></mat-divider>
                        </div>
                        <div class="select-options">
                            <mat-radio-button [value]="CashReturn.ONE_HUNDRED" mb-6>
                                <span translate>$ 100</span>
                            </mat-radio-button>
                            <mat-divider></mat-divider>
                        </div>
                    </mat-radio-group>
                    <mat-error class="cash-error" *ngIf="form.hasError('cashReturnRequired')" translate>formErrors.field_required</mat-error>
                </form>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="c-flex-p mt-2" fxFlex="100" *ngIf="selectedPaymentType.value === PaymentType.CC_DELIVERY">
        <mat-card>
            <mat-card-content>
                <P translate>
                    messages.cc_delivery_user_message
                </P>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="c-flex-p mt-2" fxFlex="100" *ngIf="selectedPaymentType.value === PaymentType.CC_IN_ADVANCE">
        <mat-card class="bank-card">
            <div class="card-title" fxLayout="row" fxLayoutAlign="space-between center">
                <h2 translate>subtitles.cc_in_advance_info</h2>
                <fa-icon class="button-icon" [icon]="faCreditCard"></fa-icon>
            </div>
            <mat-card-content>
                <P translate>
                    messages.cc_in_advance_user_message
                </P>
            </mat-card-content>
            <!-- TODO: KMA-772 -->
            <form *ngIf="ccInAdvanceInfoForm" novalidate [formGroup]="ccInAdvanceInfoForm">
                <div fxLayout="column" fxLayoutAlign="center">
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="{{ 'formFields.card_holder_name' | translate }}"
                      formControlName="cardHolder"
                    />
                    <mat-error *ngIf="cardHolder?.hasError('required')" translate
                      >formErrors.field_required</mat-error
                    >
                  </mat-form-field>

                  <mat-form-field>
                    <input
                      matInput
                      placeholder="{{ 'formFields.card_number' | translate }}"
                      formControlName="cardNumber"
                      type="tel"
                      autocomplete="cc-number"
                      ccNumber
                    />
                    <mat-error *ngIf="cardNumber?.hasError('required')" translate
                      >formErrors.field_required</mat-error
                    >
                    <mat-error *ngIf="cardNumber?.hasError('ccNumber')" translate
                    >formErrors.field_required</mat-error
                  >
                  </mat-form-field>

                  <mat-form-field>
                    <input
                      matInput
                      placeholder="{{ 'formFields.expiration_date' | translate }}"
                      formControlName="expirationDate"
                      type="tel"
                      autocomplete="cc-exp"
                      ccExp
                    />
                    <mat-error *ngIf="expirationDate?.hasError('required')" translate
                      >formErrors.field_required</mat-error
                    >
                    <mat-error *ngIf="expirationDate?.hasError('ccExp')" translate
                        >formErrors.field_required</mat-error
                    >
                  </mat-form-field>

                  <mat-form-field>
                    <input
                      matInput
                      placeholder="{{ 'formFields.cvv' | translate }}"
                      formControlName="cvv"
                      type="tel"
                      autocomplete="off"
                      ccCvc
                    />
                    <mat-error *ngIf="cvv?.hasError('required')" translate
                      >formErrors.field_required</mat-error
                    >
                    <mat-error *ngIf="cvv?.hasError('ccCvc')" translate
                        >formErrors.field_required</mat-error
                    >
                  </mat-form-field>
                </div>
              </form>
        </mat-card>
    </div>

    <div class="c-flex-p mt-2" fxFlex="100" *ngIf="selectedPaymentType.value === PaymentType.PAYMENT_LINK">
        <mat-card>
            <mat-card-content>
                <P translate>
                    messages.payment_link_user_message
                </P>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="c-flex-p" fxFlex="100" *ngIf="selectedPaymentType.value === PaymentType.BANK_TRANSFERENCE && hub.bankTransactionInfo">
        <mat-card class="bank-card">
            <div class="card-title" fxLayout="row" fxLayoutAlign="space-between center">
                <h2 translate>subtitles.banktransfer_info</h2>
                <fa-icon class="button-icon" [icon]="faWallet"></fa-icon>
            </div>
            <mat-card-content>
                <mat-list>
                    <mat-list-item>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div class="field-name">{{'formFields.bank_name' | translate}}:</div>
                            <div>{{hub.bankTransactionInfo.bankName}}</div>
                        </div>
                    </mat-list-item>
                    <mat-divider [inset]="true"></mat-divider>
                    <mat-list-item>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div class="field-name">{{'formFields.account_number' | translate}}:</div>
                            <div>{{hub.bankTransactionInfo.accountNumber}}</div>
                        </div>
                    </mat-list-item>
                    <mat-divider [inset]="true"></mat-divider>
                    <mat-list-item>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div class="field-name">{{'formFields.account_type' | translate}}:</div>
                            <div>{{'labels.' + hub.bankTransactionInfo.accountType | translate}}</div>
                        </div>
                    </mat-list-item>
                    <mat-divider [inset]="true"></mat-divider>
                    <mat-list-item>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div class="field-name">{{'formFields.id_type' | translate}}:</div>
                            <div>{{'labels.' + hub.bankTransactionInfo.idType | translate}}</div>
                        </div>
                    </mat-list-item>
                    <mat-divider [inset]="true"></mat-divider>
                    <mat-list-item>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div class="field-name">{{'formFields.id_number' | translate}}:</div>
                            <div>{{hub.bankTransactionInfo.idNumber}}</div>
                        </div>
                    </mat-list-item>
                    <mat-divider [inset]="true"></mat-divider>
                    <mat-list-item>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div class="field-name">{{'formFields.account_owner_name' | translate}}:</div>
                            <div>{{hub.bankTransactionInfo.accountName}}</div>
                        </div>
                    </mat-list-item>
                    <mat-divider [inset]="true"></mat-divider>
                </mat-list>
                <form novalidate *ngIf="form" [formGroup]="form" class="transfer-image">
                    <p translate>messages.bank_transfer</p>
                    <input hidden="true" type="file" accept="image/*" (change)="saveImage($any($event).target.files)" #imagesInput>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div fxLayout="column" fxLayoutAlign="center center">
                            <button class="image-input-upload" (click)="uploadImage($event, imagesInput)" mat-stroked-button color="accent">
                                <mat-icon>cloud_upload</mat-icon>&nbsp;&nbsp;
                                <span translate>questionsDialog.image_answer</span>
                            </button>
                            <button *ngIf="paymentImage.value" class="image-input-upload remove-image-button" (click)="removeImage()" mat-stroked-button color="warn">
                                <mat-icon>delete_forever</mat-icon>&nbsp;&nbsp;
                                <span translate>questionsDialog.remove</span>
                            </button>
                        </div>
                        <div class="add-image-link" fxLayout="row" fxLayoutAlign="center center">
                            <img class="placeholder-image" (click)="imagesInput.click()" [src]="paymentImage.value" alt="bank transfer"/>
                        </div>
                    </div>
                    <mat-error *ngIf="form.hasError('bankTransferImageRequired')" translate>formErrors.field_required</mat-error>
                </form>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="c-flex-p pay-button" fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center" *ngIf="selectedPaymentType.value === PaymentType.TINYALL_PAY">
        <span class="totalPrice">
            <span translate>labels.total</span>: {{getTotalPrice() | currency}}
        </span>
        <button class="speed-dial-action-button-fix" 
            [disabled]="form.invalid || disabled"
            (click)="pay()" 
            mat-fab color="accent">
            <span>{{buyLabel | translate}}</span>&nbsp;<mat-icon>credit_card</mat-icon>
        </button>
    </div>
    
    <app-company-info-card
        *ngIf="selectedPaymentType.value === PaymentType.TINYALL_PAY && hub"
        [name]="hub.name"
        [address]="hub.address"
        [phone]="hub.phoneNumber"
        [email]="hub.email"
        [handler]="hub.urlHandler"
        [image]="hub.icon"
    >
    </app-company-info-card>
    <app-payment-methods-footer *ngIf="selectedPaymentType.value === PaymentType.TINYALL_PAY"></app-payment-methods-footer>
</div>