import { Component, OnInit, Input } from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import { Observable, firstValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, switchMap} from 'rxjs/operators';
import { HttpService } from '../../service/http.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-state-autocomplete',
  templateUrl: './state-autocomplete.component.html',
  styleUrls: ['./state-autocomplete.component.scss']
})
export class StateAutocompleteComponent implements OnInit {

  @Input() state: UntypedFormControl;

  private readonly apiUrl = environment.apiUrl;

  filteredOptions: Observable<string[]>;

  constructor(private httpService: HttpService) {}

  ngOnInit() {
    this.filteredOptions = this.state.valueChanges.pipe(
        startWith(null),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(value => this._filter(value)),
      );
  }

  private async _filter(value: string) {
    const filterValue = this.state.value;
    if (!filterValue) {
      return [];
    }

    const response = await firstValueFrom(this.httpService
    .doGet(`${this.apiUrl}/hubs/v1/search?querySearch=address.stateOrProvidence:'${filterValue}*' AND unlisted:false AND active:true&page=0&size=10`));
    
    if (response && response.content) {
      const items = new Set();
      for (const HUB of response.content) {
        if (HUB.address.stateOrProvidence) {
          items.add(HUB.address.stateOrProvidence);
        }
      }
      return Array.from(items) as string[];
    } else {
      return [];
    }
  }

}
