<mat-card [ngClass]="status" matRipple (click)="open($event)">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxLayoutAlign="center">
            <h2><span class="label" translate>labels.ticket</span>: {{label}}</h2>
            <h2><span class="label" translate>formFields.status</span>: {{"reportsFilter." + status | translate}}</h2>
            <h2 *ngIf="ticketChangeLogs && ticketChangeLogs[0]"><span class="label" translate>{{"reportsFilter.changed_by" | translate}} </span>: {{lastTicketLog.userEmail}}</h2>
            <h2 *ngIf="ticketChangeLogs && ticketChangeLogs[0]"><span class="label" translate>{{"reportsFilter.date" | translate}} </span>: {{lastTicketLog.timestamp| date: 'dd-MM-yyyy HH:mm:ss'}}</h2>
        </div>
        <fa-icon class="qr-icon" [icon]="icon"></fa-icon>
    </div>
</mat-card>