import * as i18nIsoCountries from 'i18n-iso-countries';
import { Country } from '@angular-material-extensions/select-country';
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/es.json'));

export const getCountryFromAlpha3Code = (code: string, lang: string) => {
  return {
    name: i18nIsoCountries.getName(code, lang),
    alpha2Code: i18nIsoCountries.alpha3ToAlpha2(code),
    alpha3Code: code,
    numericCode: i18nIsoCountries.alpha3ToNumeric(code),
  } as Country;
};

export const getCountries = (lang: string) => {
  const firstCountries = ['DOM', 'USA'];
  const filteredCountries = Object.keys(
    i18nIsoCountries.getAlpha3Codes()
  ).filter((country: string) => !firstCountries.includes(country));
  filteredCountries.unshift('USA');
  filteredCountries.unshift('DOM');
  return filteredCountries.map((code: string) => {
    return getCountryFromAlpha3Code(code, lang);
  });
};

export const getCountryName = (country: string, lang?: string) => {
  return i18nIsoCountries.getName(country, lang ? lang : 'en');
};
