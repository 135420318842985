<mat-card class="card-container request-card" [ngClass]="status.value">
    <mat-card-content>
        <mat-list>
            <mat-list-item>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">Status:</div>
                    <mat-form-field floatLabel="never" *ngIf="order" >
                        <mat-select [formControl]="status" (selectionChange)="sendRequest()">
                            <mat-option class="field-type-option" 
                                [ngClass]="option" 
                                *ngFor="let option of statusOptions" 
                                [value]="option">{{'questionsDialog.'+option | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>                      
            </mat-list-item>
            <mat-list-item *ngIf="order.paymentStatus" class="use-status">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'labels.payment_status' | translate}}:</div>
                    <div><app-payment-status-tag [status]="order.paymentStatus"></app-payment-status-tag></div>
                </div>                      
            </mat-list-item>
            <mat-divider *ngIf="order.paymentStatus" [inset]="true"></mat-divider>
            <mat-divider *ngIf="order.trackingStatus" [inset]="true"></mat-divider>
            <mat-list-item *ngIf="order.trackingStatus">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'subtitles.tracking_status' | translate}}:</div>
                    <div>{{order.trackingStatus}}</div>
                </div>
            </mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'reportsDetails.activity_name' | translate}}:</div>
                    <div>{{activity.name}}</div>
                </div>
            </mat-list-item>

            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'reportsDetails.created_on' | translate}}:</div>
                    <div>{{order.createdOn | date:'short'}}</div>
                </div>
            </mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'reportsDetails.updated_on' | translate}}:</div>
                    <div>{{order.updatedOn | date:'short'}}</div>
                </div>
            </mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'reportsDetails.order_id' | translate}}:</div>
                    <div>{{order.id}}</div>
                </div>
            </mat-list-item>
            <mat-divider *ngIf="activity.currency" [inset]="true"></mat-divider>
            <mat-list-item *ngIf="activity.currency">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'formFields.currency' | translate}}:</div>
                    <div>{{activity.currency}}</div>
                </div>
            </mat-list-item>
            <mat-divider [inset]="true" *ngIf="activity.tableRequired"></mat-divider>
            <mat-list-item *ngIf="activity.tableRequired">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'formFields.table_number' | translate}}:</div>
                    <div>{{order.tableNumber}}</div>
                </div>
            </mat-list-item>
            </mat-list>
    </mat-card-content>
</mat-card>
