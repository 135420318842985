<mat-expansion-panel [id]="id" [(expanded)]="isOpen" (opened)="onItemOpen()">
    <mat-expansion-panel-header>
        <div *ngIf="!isOpen" class="closed">
            <mat-card-header class="header">
                <img mat-card-avatar class="avatar" [src]="image" alt="dish" />
                <mat-card-title>{{name}}</mat-card-title>
                <mat-card-subtitle class="description">{{description}}</mat-card-subtitle>
                <mat-card-subtitle fxLayout="row nowrap" class="variant-subtitle">
                    <span class="variant" translate
                        [translateParams]="{value: getVariants().length}">messages.has_variant</span>&nbsp;
                </mat-card-subtitle>
            </mat-card-header>
        </div>
    </mat-expansion-panel-header>

    <mat-card class="item-card">

        <form [formGroup]="itemForm">
            <div class="opened">
                <mat-card-content class="content">
                    <app-swiper-management
                        *ngIf="itemForm"
                        [images]="[itemForm.get('image').value]"
                        [form]="itemForm"
                        [max]="1" (changed)="onImageChange($event)"
                        [square]="true"
                    >
                    </app-swiper-management>
                    <mat-card-header class="header">
                        <mat-card-title>
                            <mat-form-field class="item-name">
                                <input matInput placeholder="{{'formFields.name' | translate}}" formControlName="name">
                                <mat-error *ngIf="itemForm.get('name').hasError('required')" translate>
                                    formErrors.name_required
                                </mat-error>
                            </mat-form-field>
                        </mat-card-title>

                        <mat-card-subtitle class="description">
                            <mat-form-field>
                                <textarea matInput matTextareaAutosize
                                    placeholder="{{'formFields.description' | translate}}"
                                    formControlName="description"></textarea>
                            </mat-form-field>
                        </mat-card-subtitle>
                    </mat-card-header>
                    <div class="variant-header" fxLayout="row nowrap" fxLayoutAlign="space-between center">
                        <span fxFlex="10" translate></span>
                        <span fxFlex="53" translate>formFields.variant_name</span>
                        <span fxFlex="27" translate>formFields.price</span>
                        <span fxFlex="10"></span>
                    </div>
                    <mat-card-subtitle
                        *ngFor="let variant of getVariantsControls(); let i = index; let length = count;"
                        class="variant" [formGroup]="variant" fxLayout="row nowrap"
                        fxLayoutAlign="space-between center">

                        <mat-checkbox fxFlex="10" formControlName="available" color="accent">
                        </mat-checkbox>

                        <mat-form-field fxFlex="53" class="variant-name" floatLabel='never' color="primary">
                            <input matInput placeholder="{{variant.value.description}}" formControlName="description"
                                (focus)="focusOption(variant)" autocomplete="off">
                            <mat-error *ngIf="variant.get('description').hasError('required')" translate>
                                formErrors.variant_required</mat-error>
                            <mat-error *ngIf="variant.get('description').hasError('unique')" translate>
                                formErrors.variant_unique</mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex="27" class="variant-price" floatLabel='never' color="primary">
                            <input type="number" matInput formControlName="price">
                            <span matPrefix>$&nbsp;</span>
                            <mat-error *ngIf="variant.get('price').hasError('required')" translate>
                                formErrors.price_required</mat-error>
                        </mat-form-field>

                        <button fxFlex="10" class="variant-close" type="button" (click)="removeOption(i)"
                            mat-icon-button>
                            <mat-icon *ngIf="length > 1">close</mat-icon>
                        </button>
                    </mat-card-subtitle>
                    <button class="info-tooltip-container" *ngIf="getVariants().length < maxVariants" (click)="addOption()" mat-button
                        color="accent"><span translate>buttons.add_variant</span><app-info-tooltip class="variant-tooltip" info="tooltip.dish_options" infoClass="secondary"></app-info-tooltip></button>
                </mat-card-content>


                <mat-card-actions class="footer">
                    <div fxLayout="row" fxLayoutAlign="end center">
                        <button class="remove-button" type="button" (click)="removeDish()" mat-icon-button color="warn">
                            <mat-icon class="delete-icon">
                                delete_forever
                            </mat-icon>
                        </button>
                        <mat-slide-toggle color="accent" formControlName="available">
                            <span translate>formFields.available</span>
                        </mat-slide-toggle>
                    </div>
                </mat-card-actions>
            </div>
        </form>
    </mat-card>

</mat-expansion-panel>