<mat-card *ngIf="showSocialLinks(socialLinks)">
    <mat-card-content>
        <div class="scroll-container" [perfectScrollbar]="perfectScrollBarConfig">
            <a *ngIf="socialLinks.facebook" [href]="socialLinks.facebook" target="_blank">
                <fa-icon matPrefix class="front-icon facebook" [icon]="faFacebookSquare"></fa-icon>
            </a>
    
            <a *ngIf="socialLinks.twitter" [href]="socialLinks.twitter" target="_blank">
                <fa-icon matPrefix class="front-icon twitter" [icon]="faTwitterSquare"></fa-icon>
            </a>
    
            <a *ngIf="socialLinks.linkedin" [href]="socialLinks.linkedin" target="_blank">
                <fa-icon matPrefix class="front-icon linkedin" [icon]="faLinkedin"></fa-icon>
            </a>
    
            <a *ngIf="socialLinks.instagram" [href]="socialLinks.instagram" target="_blank">
                <fa-icon matPrefix class="front-icon instagram" [icon]="faInstagramSquare"></fa-icon>
            </a>
    
            <a *ngIf="socialLinks.snapchat" [href]="socialLinks.snapchat" target="_blank">
                <fa-icon matPrefix class="front-icon snapchat" [icon]="faSnapchatSquare"></fa-icon>
            </a>
    
            <a *ngIf="socialLinks.pinterest" [href]="socialLinks.pinterest" target="_blank">
                <fa-icon matPrefix class="front-icon pinterest" [icon]="faPinterestSquare"></fa-icon>
            </a>
    
            <a *ngIf="socialLinks.reddit" [href]="socialLinks.reddit" target="_blank">
                <fa-icon matPrefix class="front-icon reddit" [icon]="faRedditSquare"></fa-icon>
            </a>
    
            <a *ngIf="socialLinks.whatsapp" [href]="socialLinks.whatsapp" target="_blank">
                <fa-icon matPrefix class="front-icon whatsapp" [icon]="faWhatsappSquare"></fa-icon>
            </a>
    
            <!--<a *ngIf="socialLinks.tumblr" [href]="socialLinks.tumblr" target="_blank">
                <fa-icon matPrefix class="front-icon tumblr" [icon]="faTumblrSquare"></fa-icon>
            </a>-->
    
            <a *ngIf="socialLinks.telegram" [href]="socialLinks.telegram" target="_blank">
                <fa-icon matPrefix class="front-icon telegram" [icon]="faTelegram"></fa-icon>
            </a>
    
            <a *ngIf="socialLinks.youtube" [href]="socialLinks.youtube" target="_blank">
                <fa-icon matPrefix class="front-icon youtube" [icon]="faYoutubeSquare"></fa-icon>
            </a>
    
            <a *ngIf="socialLinks.medium" [href]="socialLinks.medium" target="_blank">
                <fa-icon matPrefix class="front-icon medium" [icon]="faMedium"></fa-icon>
            </a>
        </div>
    
    </mat-card-content>
</mat-card>
