<h1 *ngIf="user" class="subtitle" translate>subtitles.your_comment</h1>
<mat-card class="card-container" *ngIf="comment && !isEditing && user">
    <mat-card-header>
        <img mat-card-avatar class="avatar" referrerpolicy="no-referrer" [src]="comment.userProfileImage" alt="user" />
        <div fxLayout="column">
            <mat-card-title>{{comment.userName}}</mat-card-title>
            <div fxLayout="row" class="rating-container">
                <star-rating [value]="comment.rate" [totalstars]="5" checkedcolor="#29a6a4" uncheckedcolor="#cccccc" size="18px" [readonly]="true"></star-rating>
                <span class="time">{{comment.updatedOn | timeAgo}}</span>
            </div>
        </div>
        <button *ngIf="!loading" class="menu-button" mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
        <mat-menu #menu="matMenu">
            <button class="delete" mat-menu-item (click)="delete()"><span translate>buttons.delete</span></button>
        </mat-menu>
    </mat-card-header>
    <mat-card-content>
        <p [class.expanded]="expanded" (click)="expanded = !expanded" class="text">
            {{comment.comment}}
        </p>
        <section class="actions" fxLayoutAlign="space-between">
            <button *ngIf="!loading" mat-button color="accent" class="edit-button" (click)="isEditing = true"><span translate>buttons.edit_your_comment</span></button>
        </section>
    </mat-card-content>
</mat-card>

<mat-card class="card-container" *ngIf="isEditing && user">
    <mat-card-content>
        <star-rating [value]="rate" [totalstars]="5" checkedcolor="#29a6a4" uncheckedcolor="#cccccc" size="50px" [readonly]="false" (rate)="onRate($event)"></star-rating>
        <form novalidate [formGroup]="form" fxLayout="column" fxLayoutAlign="center">
            <mat-form-field>
                <textarea rows="4" matInput matTextareaAutosize placeholder="{{'placeholders.your_opinion' | translate}}" formControlName="comment"></textarea>
                <mat-error *ngIf="commentField.hasError('maxlength')" translate>formErrors.comment_max_length</mat-error>
            </mat-form-field>
        </form>

        <section class="actions" fxLayoutAlign="start">
            <button mat-raised-button color="accent" class="save-button" (click)="onSubmit()" [disabled]="!form || !form.dirty || form.invalid">
                <span *ngIf="!loading" translate>buttons.save_your_comment</span>
                <mat-spinner diameter="25" *ngIf="loading"></mat-spinner>
            </button>

            <button *ngIf="comment && !loading" mat-button class="cancel-button" (click)="cancel()">
                <span translate>buttons.cancel</span>
            </button>
        </section>
    </mat-card-content>
</mat-card>
