import { Component, Input } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SocialShareBottomSheetComponent } from '../social-share-bottom-sheet/social-share-bottom-sheet.component';
import { Router } from '@angular/router';
import { CommentsPreviewComponent } from '../comments-preview/comments-preview.component';
import { ShortUrlHandlerComponent } from '../short-url-handler/short-url-handler.component';

@Component({
  selector: 'app-social-card-actions',
  templateUrl: './social-card-actions.component.html',
  styleUrls: ['./social-card-actions.component.scss'],
})
export class SocialCardActionsComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() images: string[];
  @Input() preview: CommentsPreviewComponent;
  @Input() views: number;
  @Input() requests: number;
  @Input() hasRequest: boolean = true;
  @Input() id: string;
  @Input() createdOn: string;

  constructor(private _bottomSheet: MatBottomSheet, private router: Router) {}

  get reviewRate() {
    return this.preview?.reviewRate;
  }

  get reviewCount() {
    return this.preview?.reviewCount;
  }

  openShare(event: MouseEvent): void {
    event.stopPropagation();
    this._bottomSheet.open(SocialShareBottomSheetComponent, {
      panelClass: 'share-bottom-sheet',
      data: {
        url: ShortUrlHandlerComponent.shortActCatUrl(this.id),
        title: this.title,
        description: this.description,
        images: this.images,
        tags: null,
      },
    });
  }
}
