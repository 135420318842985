<div fxLayout="row">
    <h2 class="title info-tooltip-container" translate>titles.sections<app-info-tooltip class="absolute-tooltip" info="tooltip.sections" infoClass="primary"></app-info-tooltip></h2>
</div>
<div class="list-container">
    <div fxLayout="column" fxLayoutGap="20px">
        <form *ngIf="sectionListForm.length > 0; else noSections" 
            class="section-boundary"
            cdkDropList 
            (cdkDropListDropped)="drop($event)"
            novalidate 
            [formGroup]="form" 
            fxLayout="column" 
            fxLayoutAlign="center">
            <mat-accordion>
                <div class="flex-p" 
                    formArrayName="sectionList" 
                    *ngFor="let sectionItem of sectionListForm.controls; let i = index;" 
                    cdkDragLockAxis="y" 
                    cdkDrag>
                    <mat-card [id]="'section'+i" class="section-container" [formGroupName]="i" [ngClass]="{'error': sectionListForm.controls[i].invalid || sectionListForm.controls[i].get('dishes').invalid }">
                        <div class="hanlde-container">
                            <mat-icon class="handle-move" cdkDragHandle>drag_handle</mat-icon>
                        </div>
                        <mat-card-content>
                            <mat-expansion-panel (opened)="onSectionOpen('section'+i)" (afterExpand)="onSectionOpen()">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{sectionListForm.controls[i].get('sectionName').value}}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                
                                <div fxLayout="column" fxLayoutAlign="center" class="section-body">
                                    <mat-form-field class="main-field">
                                        <input matInput placeholder="{{'formFields.section_name' | translate}}" formControlName="sectionName">
                                        <mat-error *ngIf="sectionListForm.controls[i].get('sectionName').hasError('required')" translate>formErrors.section_name_required</mat-error>
                                        <mat-error *ngIf="sectionListForm.controls[i].get('sectionName').hasError('unique')" translate>formErrors.section_name_unique</mat-error>
                                    </mat-form-field>
                
                                    <mat-form-field>
                                        <textarea matInput matTextareaAutosize placeholder="{{'formFields.description' | translate}}" formControlName="sectionDescription"></textarea>
                                    </mat-form-field>

                                    <app-dish-list-management
                                        [dishesForm]="getDishes(i)"
                                        [sectionIndex]="i"
                                        [sectionListForm]="sectionListForm"
                                        [actForm]="actForm"
                                    ></app-dish-list-management>
                                
                
                                    <div class="footer" fxLayout="row" fxLayoutAlign="space-between center">
                                        <button class="innerAddQuestion" mat-button (click)="addDish(i)" color="accent"><span translate>buttons.add_dish</span></button>
                                        <button class="remove-button" type="button" (click)="canRemoveSection(i)" mat-icon-button color="warn">
                                            <mat-icon class="delete-icon">delete_forever</mat-icon>
                                        </button>
                                    </div>
                                </div>
                
                            </mat-expansion-panel>
                        </mat-card-content>
                    </mat-card>
                </div>
            </mat-accordion>
        </form>
        <ng-template #noSections>
            <div class="no-sections-container">
                <mat-icon>menu_book</mat-icon>
                <p translate>
                    messages.no_sections_text
                </p>
            </div>
        </ng-template>
    </div>
    <button class="speed-dial-action-button" (click)="addSection()" mat-fab color="accent">
        <span translate>buttons.add_section</span>&nbsp;<mat-icon>menu_book</mat-icon>
    </button>
</div>

