import {Injectable} from '@angular/core';
import {CanActivate, CanActivateChild, Router} from '@angular/router';
import {AuthService} from './auth.serviceV2';
import {RouteConstants} from '../app.constants';
import {LocalStorageType} from './local-storage-type.enum';

@Injectable()
export class IsNewGuard implements CanActivate, CanActivateChild {

    constructor(private router: Router, private auth: AuthService) {
    }

    canActivate() {
        return this.active();
    }

    canActivateChild() {
        return this.active();
    }

    private async active(): Promise<boolean> {
        // check if the user is using the app for first time
        const visited = localStorage.getItem(LocalStorageType.VisitedLanding);

        if (visited.toLowerCase() === 'true') {
            return true;
        }
        await this.router.navigate([RouteConstants.landing]);
        return false;
    }
}
