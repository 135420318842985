<div class="page-container" fxLayout="column">
    <form *ngIf="form" [formGroup]="form" class="main-order-info">
        <app-order-general-info *ngIf="order"
            [order]="order"
            [activity]="activity"
            [status]="status"
            [statusOptions]="getAvailableStatus(order.status)"
            (statusChanged)="sendRequest()">
        </app-order-general-info>
    </form>

    <app-order-product-list *ngIf="order && activity"
        #orderProductList
        [owner]="false"
        [order]="order"
        [activityId]="activity.id"
        [hubId]="order.hubId"
        [userId]="activity.userId"
        [currency]="order.currency">
    </app-order-product-list>

    <app-chosen-payment-method *ngIf="order && order.chosenPaymentType"
        [chosenPaymentType]="order.chosenPaymentType"
        [cashReturn]="order.cashReturn"
        [paymentImage]="order.paymentImage">
    </app-chosen-payment-method>

    <app-payment-link *ngIf="order && order.paymentLink"
        [currentPaymentLink]="order.paymentLink">
    </app-payment-link>

    <app-order-summary *ngIf="orderProductListRef"
        [items]="order.amount"
        [quantity]="orderProductListRef.summary.quantity"
        [additional]="order.totalPrice - order.amount"
        [currency]="order.currency"
        [questionAnswerMap]="order.questionAnswerMap"
        [totalPrice]="order.totalPrice"
        [additionalCharges]="order.additionalCharges"
        [promoCodeEntity]="order.issuedPromoCode">
    </app-order-summary>

    <app-location-map *ngIf="order && order.location" 
        [location]="order.location" 
        [name]="order.clientName"
        [launch]="true">
    </app-location-map>

    <app-chosen-address *ngIf="order && order.choosenLocation" [chosenLocation]="order.choosenLocation"></app-chosen-address>

    <app-view-question-list 
        *ngIf="order" 
        withTitle="true"
        title="{{'titles.checkout_data' | translate}}"
        [answers]="order.questionAnswerMap">
    </app-view-question-list>
    <app-reports-comments-view *ngIf="order" [client]="true" [entityId]="order.id"></app-reports-comments-view>
</div>
