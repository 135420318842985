import {
  Directive, OnInit, OnDestroy, ElementRef, EventEmitter, Input, Output
} from '@angular/core';

@Directive({
  selector: '[io-view]'
})
export class IoViewDirective implements OnInit, OnDestroy {

  private observer: IntersectionObserver;
  @Input() public monitor: boolean = false;
  @Output() public inView: EventEmitter<any> = new EventEmitter();
  @Output() public outView: EventEmitter<any> = new EventEmitter();

  constructor(private element: ElementRef) { }

  public ngOnInit(): void {
    if ('IntersectionObserver' in window) {
      this.createObserver();
    } else {
      console.log('*** IntersectionObserver no supported ***');
    }
  }

  public ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private createObserver(): void {
    this.observer = new IntersectionObserver(entries => this.handler(entries));
    this.observer.observe(this.element.nativeElement);
  }

  private handler(entries): void {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        this.inView.emit();
        if (!this.monitor) {
          this.observer.unobserve(entry.target);
        }
      } else {
        if (this.monitor) {
          this.outView.emit();
        }
      }
    });
  }

  private checkIfIntersecting(entry): boolean {
    if (entry && entry.length) {
      return entry.isIntersecting && entry.target === this.element.nativeElement;
    }
    return false;
  }
}
