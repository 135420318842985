import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "./http.service";

@Injectable()
export class IpLocationService {
    private freeGeoIpUrl: string = 'https://api.freegeoip.app/json/?apikey=';
    private apiKey: string = '5f02fd40-4752-11ec-9d40-815fb81bffbb';

    constructor(private http: HttpService) {
    }

    public getClientLocation(): Observable<any> {
        return this.http.doGet(this.freeGeoIpUrl + this.apiKey);
    }
}