import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { map } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatBottomSheet } from '@angular/material/bottom-sheet';
import { AppSettings } from '../../app.settings';
import { Settings } from '../../app.settings.model';
import { HttpService } from '../../service/http.service';
import { environment } from '../../../environments/environment';
import { RouteConstants } from '../../app.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-order-product-list',
  templateUrl: './order-product-list.component.html',
  styleUrls: ['./order-product-list.component.scss']
})
export class OrderProductListComponent implements OnInit {

  @Input() owner: boolean;
  @Input() order: any;
  @Input() activityId: string;
  @Input() hubId: string;
  @Input() userId: string;
  @Input() currency: string;

  faShoppingCart = faShoppingCart; 

  readonly apiUrl = environment.apiUrl;
 
  settings: Settings;
  products = new Map();

  pageIndex = 0;

  loading = false;
  handler = null;

  searchCriteria = [];

  selectedForm: UntypedFormGroup;
  firstSearch = false;

  summary = {
    quantity: 0,
    total: 0,
  };

  constructor(
    private router: Router,
    private translate: TranslateService,
    protected snackBar: MatSnackBar,
    private httpService: HttpService,
    private appSettings: AppSettings,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private _bottomSheet: MatBottomSheet,
    private fb: UntypedFormBuilder,
    ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.settings.loadingSpinner = true;
    this.loading = true;
    this.createForm();
    this.route.paramMap.pipe(
      map((params: ParamMap) => params.get('handler'))
    ).subscribe((value) => {
        this.handler = value;
        this.retrieveContent();
    });
  }

  createForm() {
    this.selectedForm = this.fb.group({
      id: [''],
      variant: ['']
    });
  }

  private async retrieveContent() {
    this.settings.loadingSpinner = false;
    await this.search();
  }

  getVariantMap(options) {
    const variants = {};
    for (const variant of options) {
      variants[variant.description] = Number(variant.price);
    }
    return variants;
  }

  async search() {
    this.loading = true;
    const querySearch = [];
    querySearch.push(`querySearch=(activityId:'${this.activityId}' AND hubId:'${this.hubId}')`);

    let hasProduts = false;
    this.products.clear();
    for (const product of this.order.shoppingList) {
      if (!hasProduts) {
        querySearch.push('AND (');
        hasProduts = true;
      } else {
        querySearch.push(' OR ');
      }
      querySearch.push(`id:'${product.productItemId}'`);
    }
    if (hasProduts) {
      querySearch.push(')');
    }

    if (!hasProduts) {
      return;
    }

    if (querySearch.length > 0) {
      querySearch.push('&');
    }

    try {
      let url = this.owner ? `activity-product-listing/v1/product-item/search/${this.hubId}` : 'product-item-view/v1/search' ;
      const itemsUrl = `${this.apiUrl}/${url}?${querySearch.join('')}paged=false`;
      const response = await this.httpService.doGet(itemsUrl).toPromise();
      if (response) {
        for (const product of response.content) {
          product['variants'] = this.getVariantMap(product.options);
          this.products.set(product.id, product);
        }
      } else {
        this.products.clear();
      }
      this.setSummary();
      this.loading = false;      
    } catch (error) {
      this.loading = false;
    }
  }

  showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  setSummary() {
    this.summary = this.order.shoppingList.reduce((prev, current) => {
        prev.quantity += current.amount;
        prev.total = Number(current.itemOption.price) * current.amount;
        return prev;
      }, {
        quantity: 0,
        total: 0,
      });
  }


}
