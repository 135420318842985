<mat-expansion-panel *ngIf="form">
    <mat-expansion-panel-header>
        <mat-panel-title translate>subtitles.additional_charges<app-info-tooltip info="tooltip.additional_charges" class="info-additional-charges"></app-info-tooltip></mat-panel-title>
    </mat-expansion-panel-header>

    <div *ngIf="additionalCharges.length > 0" class="charge-header" fxLayout="row nowrap" fxLayoutAlign="space-between center">
        <span fxFlex="58" translate>formFields.name</span>
        <span fxFlex="32" translate>formFields.amount</span>
        <span fxFlex="10"></span>
    </div>
    <div class="charge-body" fxLayout="column" fxLayoutAlign="center start">
        <div fxLayout="row nowrap" fxLayoutGap="15px" fxLayoutAlign="space-between center" *ngFor="let charge of additionalCharges; index as i" class="charges" [formGroup]="charge">
            <mat-form-field fxFlex="58" class="charge-name" floatLabel='never' color="primary">
                <input matInput placeholder="{{'formFields.name' | translate}}" formControlName="name" autocomplete="off">
                <mat-error *ngIf="charge.get('name').hasError('required')" translate>
                    formErrors.name_required</mat-error>
                <mat-error *ngIf="charge.get('name').hasError('unique')" translate>
                    formErrors.name_unique</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="32" floatLabel='never' color="primary">
                <input type="number" matInput formControlName="value">
                <span matPrefix>$&nbsp;</span>
                <mat-error *ngIf="charge.get('value').hasError('required')" translate>formErrors.amount_required</mat-error>
                <mat-error *ngIf="charge.get('value').hasError('numeric')" translate>formErrors.amount_numeric</mat-error>
                <mat-error *ngIf="charge.get('value').hasError('min')" translate>formErrors.amount_min</mat-error>
            </mat-form-field>

            <button fxFlex="10" class="charge-close" type="button" (click)="remove(i)"
                mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>

        </div>
        <button (click)="add()" mat-button color="accent"><span translate>buttons.add_charge</span></button>
    </div>
</mat-expansion-panel>