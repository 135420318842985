<div class="container">
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
      <button (click)="cancel()" color="accent" mat-button><span translate>reportsFilter.cancel</span></button>
      <h2 class="title" translate>titles.share_bottom_sheet</h2>
  </div>
  <div class="container" fxLayout="row wrap" fxLayoutAlign="start start">
    <button mat-fab class="whatsapp" (click)="whatsappShare()">
        <fa-icon matPrefix class="front-icon" [icon]="faWhatsappSquare"></fa-icon>
    </button>

    <button mat-fab class="facebook" (click)="facebookShare()">
        <fa-icon matPrefix class="front-icon" [icon]="faFacebookSquare"></fa-icon>
    </button>

    <button mat-fab class="twitter" (click)="twitterShare()">
        <fa-icon matPrefix class="front-icon" [icon]="faTwitterSquare"></fa-icon>
    </button>

    <button mat-fab class="linkedin" (click)="linkedinShare()">
        <fa-icon matPrefix class="front-icon" [icon]="faLinkedin"></fa-icon>
    </button>

    <button mat-fab class="pinterest" (click)="pinterestShare()">
        <fa-icon matPrefix class="front-icon" [icon]="faPinterestSquare"></fa-icon>
    </button>

    <button mat-fab class="reddit" (click)="redditShare()">
        <fa-icon matPrefix class="front-icon" [icon]="faRedditSquare"></fa-icon>
    </button>

    <button mat-fab class="tumblr" (click)="tumblrShare()">
        <fa-icon matPrefix class="front-icon" [icon]="faTumblrSquare"></fa-icon>
    </button>

    <button mat-fab class="telegram" (click)="telegramShare()">
        <fa-icon matPrefix class="front-icon" [icon]="faTelegram"></fa-icon>
    </button>

    <button mat-fab class="skype" (click)="skypeShare()">
        <fa-icon matPrefix class="front-icon" [icon]="faSkype"></fa-icon>
    </button>

    <button mat-fab class="line" (click)="lineShare()">
        <fa-icon matPrefix class="front-icon" [icon]="faLine"></fa-icon>
    </button>

    <button *ngIf="user && user.email" mat-fab class="email" (click)="emailShare()">
        <fa-icon matPrefix class="front-icon" [icon]="faEnvelope"></fa-icon>
    </button>
    
    <a *ngIf="isMobile" [href]="'sms:?&body=' + encodedUrl">    
        <button  mat-fab class="sms">
        <fa-icon matPrefix class="front-icon" [icon]="faSms"></fa-icon>
        </button>
    </a>

    <button mat-fab class="link" (click)="copyToClipboard($event)">
        <fa-icon matPrefix class="front-icon" [icon]="faLink"></fa-icon>
    </button>
  </div>
</div>