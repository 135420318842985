import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

@Injectable()
export class TokenService {
    private readonly REFRESH_TOKEN_URL = 'https://securetoken.googleapis.com/v1/token?key=' + environment.firebaseConfig.apiKey;

    constructor(private http: HttpClient) {
    }

    public async getTokenFromRefreshToken(refreshToken: string): Promise<string | null> {
        const result = await this.refreshTokenInternal(refreshToken);
        if (result && result.id_token) {
            return result.id_token;
        }
        return null;
    }

    private async refreshTokenInternal(refreshToken: string): Promise<any> {
        const httpHeaders = new HttpHeaders(
            {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            });
        const payload = new HttpParams()
            .set('grant_type', 'refresh_token')
            .set('refresh_token', refreshToken);
        return this.http.post<any>(this.REFRESH_TOKEN_URL, payload, {headers: httpHeaders}).toPromise();
    }
}
