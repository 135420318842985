import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-banned-message',
  templateUrl: './banned-message.component.html',
  styleUrls: ['./banned-message.component.scss']
})
export class BannedMessageComponent {
  @Input() message: string;
}
