import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-qr-generator-dialog',
  templateUrl: './qr-generator-dialog.component.html',
  styleUrls: ['./qr-generator-dialog.component.scss']
})
export class QrGeneratorDialogComponent {

  parentData: any;

  constructor(
    public dialogRef: MatDialogRef<QrGeneratorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
      this.parentData = {isTicket: false, ticketStatus: null, ...data};
  }

  close(): void {
    this.dialogRef.close();
  }

}
