<mat-expansion-panel>

    <mat-expansion-panel-header>
        <mat-panel-title translate>subtitles.collaborator_email<app-info-tooltip info="tooltip.collaborators" class="info-collaborator"></app-info-tooltip></mat-panel-title>
    </mat-expansion-panel-header>


    <div fxLayout="column" fxLayoutAlign="center start">
        <div fxLayout="row" *ngFor="let collaborator of collaborators; index as i" class="collaborators">
            <div fxLayout="column" fxFlex="90" fxLayoutAlign="center start">   
                <mat-form-field>
                    <input matInput placeholder="{{'formFields.collaborator_email' | translate}}" [formControl]="collaborator" autocomplete="off">
                    <mat-error *ngIf="collaborator.hasError('required')" translate>formErrors.collaborator_required</mat-error>
                    <mat-error *ngIf="collaborator.hasError('email')" translate>formErrors.error_email</mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="column" fxFlex="10" fxLayoutAlign="center center">
                <button class="variant-close" type="button" (click)="remove(i)" mat-icon-button>
                    <mat-icon>close</mat-icon>
                </button>
            </div>

        </div>
        <button (click)="add()" mat-button color="accent"><span translate>buttons.add_collaborator</span></button>
    </div>

</mat-expansion-panel>