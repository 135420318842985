import { Component, Input} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faChartPie } from '@fortawesome/free-solid-svg-icons';
import { EventStatsDialogComponent } from '../event-stats-dialog/event-stats-dialog.component';

@Component({
  selector: 'app-event-stats-card',
  templateUrl: './event-stats-card.component.html',
  styleUrls: ['./event-stats-card.component.scss']
})
export class EventStatsCardComponent {

  @Input() availableTicketCount: number;
  @Input() capacity: number;
  icon = faChartPie;

  constructor(public dialog: MatDialog){}

  open(e): void {
      e.stopPropagation()
      this.dialog.open(EventStatsDialogComponent, {
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw',
        maxHeight: '100vh',
        panelClass: 'event-stats-dialog',
        data: {
          availableTicketCount: this.availableTicketCount,
          capacity: this.capacity
        }
      });
  }

}
