<mat-card class="card-container" *ngIf="preview && preview.reviewEntityList.length > 0 && !loading" (click)="openComments()" [ngClass]="{'hidden' : !show}">
    <div (click)="showHide($event)" fxLayout="row" fxLayoutAlign="space-between" class="show-hide-preview">
        <span *ngIf="show" class="show-hide-label" translate>buttons.hide</span>
        <span *ngIf="!show" class="show-hide-label" translate>buttons.show_preview</span>
        <mat-icon *ngIf="show" class="hide-button">expand_less</mat-icon>
        <mat-icon *ngIf="!show" class="hide-button">expand_more</mat-icon>
    </div>
    <div *ngFor="let review of preview.reviewEntityList" class="preview-row" [hidden]="!show">
        <mat-card-header>
            <img mat-card-avatar class="avatar" referrerpolicy="no-referrer" [src]="review?.userProfileImage ? review.userProfileImage : noImage" alt="user" />
            <div fxLayout="column" fxFlex>
                <mat-card-title>{{review.userName}}</mat-card-title>
                <div fxLayout="row" class="rating-container">
                    <star-rating [value]="review.rate" [totalstars]="5" checkedcolor="#29a6a4" uncheckedcolor="#cccccc" size="18px" [readonly]="true"></star-rating>
                    <span class="time">{{review.createdOn | timeAgo}}</span>
                </div>
                <p class="text">
                    {{review.comment}}
                </p>
            </div>
            <span *ngIf="review.likeCount > 0" class="likes"><mat-icon>thumb_up</mat-icon><br/>{{review.likeCount}}</span>
        </mat-card-header>
    </div>
</mat-card>
<mat-card class="card-container" *ngIf="(!preview || preview.reviewEntityList.length === 0) && !loading">
    <mat-card-content>
        <section class="actions" fxLayoutAlign="center">
            <button mat-raised-button color="accent" class="leave-a-comment-button" (click)="openComments()">
                <span translate>buttons.leave_your_review</span>
            </button>
        </section>
    </mat-card-content>
</mat-card>
