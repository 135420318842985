<div class="page-container" *ngIf="logs && logs.length && logs.length > 0">
  <div fxLayoutAlign="space-between enter">
    <h1 class="subtitle" translate>subtitles.logs</h1>
  </div>

  <mat-card class="card-container" *ngFor="let log of logs">
    <mat-card-content>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
          <span class="mr-1.5 text-gray-500 whitespace-nowrap">{{
            log.userName
          }}</span>
          <div>
            <span class="time">{{ log.eventDate | date : 'medium' }}</span>
          </div>
        </div>
        <div>
          <span class="status-container" [ngClass]="log.status">{{
            'questionsDialog.' + log.status | translate
          }}</span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
