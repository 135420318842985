import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-menu-order-dish-item',
  templateUrl: './menu-order-dish-item.component.html',
  styleUrls: ['./menu-order-dish-item.component.scss']
})
export class MenuOrderDishItemComponent {

  @Input() item: any;
  @Input() index: number;

  isOpen = false;
  
  readonly noImage = '/assets/img/app/default-dish.png';

  constructor() {}

  getTotal(price, quantity) {
    return Number(price) * Number(quantity);
  }

  get id() {
    return `${this.item.dishName}${this.item.dishOptDescription}${this.index}`;
  }

  onItemOpen(){
    setTimeout(() => {
      const element = document.getElementById(this.id);
      if (element !== null) {
        element.scrollIntoView();
      }
    }, 200);
  }


}
