

<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <button (click)="close()" mat-button><span translate>tinyAll.back</span></button>
    <h2 class="title" translate>labels.tickets</h2>
</div>

<div class="page-container" fxLayout="row wrap" fxLayoutAlign="center start">
    <app-event-request-details-client-view 
        [eventRequest]="parentData"
        (updated)="onUpdated()">
    </app-event-request-details-client-view>
</div>



