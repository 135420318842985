import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-menu-order-list-client-view-dialog',
  templateUrl: './menu-order-list-client-view-dialog.component.html',
  styleUrls: ['./menu-order-list-client-view-dialog.component.scss']
})
export class MenuOrderListClientViewDialogComponent {

  parentData: any;
  hasUpdate = false;

  constructor(
    public dialogRef: MatDialogRef<MenuOrderListClientViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
      this.parentData = data; 
  }

  close(): void {
    this.dialogRef.close(this.hasUpdate);
  }

  onCancel() {
    this.hasUpdate = true;
  }

}
