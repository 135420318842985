import { query } from "@angular/animations";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import QUANTITY_SELECTOR from "../../../../util/quantity-selector";
import CURRENCY_SYMBOLS from "../../../../util/currency-symbols";

@Component({
  selector: "app-cart-item",
  templateUrl: "./cart-item.component.html",
  styleUrls: ["./cart-item.component.scss"],
})
export class CartItemComponent implements OnInit {
  @Input() item: any;
  @Input() product: any;
  @Input() selected: boolean;
  @Input() loading: boolean;
  @Input() selectedForm: UntypedFormGroup;
  @Input() currency: string;

  @Output() onSelectItem = new EventEmitter();
  @Output() onQuantityChange = new EventEmitter();
  @Output() onRemoveFromCart = new EventEmitter();

  readonly quantitySelector = QUANTITY_SELECTOR;
  readonly currencySymbols = CURRENCY_SYMBOLS;
  readonly noImage = "/assets/img/app/default-product.png";

  actualVariant: any;
  lastQuantity: number;

  constructor() {}

  ngOnInit(): void {
    this.actualVariant = {
      description: this.item.variant,
      price: this.product.variants[this.item.variant],
    };
  }

  get quantity() {
    return this.selectedForm.get("quantity") as UntypedFormControl;
  }

  selectItem() {
    if (this.loading) {
      return;
    }

    this.selectedForm.reset();

    this.selectedForm.get("id").setValue(this.product.id);
    this.selectedForm.get("variant").setValue(this.actualVariant);
    this.selectedForm.get("quantity").setValue(this.item.quantity);
    this.lastQuantity = this.item.quantity;

    setTimeout(() => {
      const element = document.getElementById(
        this.getId(this.product.id, this.item.variant)
      );
      if (element !== null) {
        element.scrollIntoView();
      }
    }, 200);
    this.onSelectItem.emit(this.item);
  }

  quantityChange(event) {
    let action = "+";
    let quantity = 0;
    if (this.lastQuantity > event.value) {
      quantity = this.lastQuantity - event.value;
      action = "-";
    } else {
      quantity = event.value - this.lastQuantity;
    }

    this.lastQuantity = event.value;
    this.item.quantity = this.lastQuantity;
    this.onQuantityChange.emit({
      name: this.product.name,
      action,
      quantity,
    });
  }

  removeFromCart() {
    if (this.loading) {
      return;
    }
    this.onRemoveFromCart.emit();
  }

  close() {
    this.selectedForm.get("id").setValue("");
    setTimeout(() => {
      const element = document.getElementById(
        this.getId(this.product.id, this.item.variant)
      );
      if (element !== null) {
        element.scrollIntoView();
      }
    }, 200);
  }

  getTotal(price, quantity) {
    return Number(price) * Number(quantity);
  }

  getId(id: string, variant: string) {
    return `${id}/${variant.replace(/ /g, "")}`;
  }

  getImages() {
    if (
      !this.product ||
      !this.product.images ||
      this.product.images.length === 0
    ) {
      return [this.noImage];
    }
    return this.product.images;
  }
}
