<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <button (click)="back()" mat-button><span translate>tinyAll.back</span></button>
    <app-client-view-button [url]="qrCode"></app-client-view-button>
    <button class="menu-button" mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
    <mat-menu #menu="matMenu">
        <button class="save" mat-menu-item (click)="editCategory()"><span translate>buttons.edit</span></button>
        <button class="delete" mat-menu-item (click)="canDelete()" *ngIf="categoryId"><span translate>buttons.delete</span></button>
    </mat-menu>
</div>

<div class="page-container" fxLayout="row wrap" fxLayoutAlign="center start">
    <app-activity-breadcrumb type="CATEGORY" (onBack)="back()"></app-activity-breadcrumb>
    <app-qr-generator-card *ngIf="qrCode" [text]="qrCode" [imageUrl]="category?.images[0]"></app-qr-generator-card>
    <app-url-handler-copy *ngIf="qrCode" [url]="qrCode"></app-url-handler-copy>
    <div class="flex-p" fxFlex="100">
        <mat-card class="card-container">
            <mat-card-header>
                <mat-card-title class="activity-title">{{category?.name}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="relative">
                    <app-swiper-view *ngIf="category?.images && category.images.length > 0" 
                        [images]="category.images"
                        [canPlay]="true"
                        [canLink]="true">
                    </app-swiper-view>
                    <apan *ngIf="category?.active" class="bg-accent rounded-full px-2 py-1 text-white z-10 absolute top-[7px] left-[-5px] shadow-lg" translate>labels.active</apan>
                    <apan *ngIf="!category?.active" class="bg-red-600 rounded-full px-2 py-1 text-white z-10 absolute top-[7px] left-[-5px] shadow-lg" translate>labels.inactive</apan>
                </div>
                <app-rich-text-view [text]="category?.infoText"></app-rich-text-view>
            </mat-card-content>
        </mat-card>
    </div>

    <div *ngFor="let item of items" io-view (inView)="item['show'] = true" fxFlex="100" class="flex-p">
        <app-hub-activity id="item.id" 
            *ngIf="item.show" 
            [activity]="item" 
            [handler]="handler"
            [isOwnerView]="true"
            (click)="goToActivity($event)">
        </app-hub-activity>
    </div>
</div>

<div *ngIf="category" fxLayout="row">
    <app-category-speed-dial-fab 
    [hubId]="hubId" 
    [categoryId]="categoryId"
    [handler]="handler"
    [canSort]="items && items.length > 1"
    ></app-category-speed-dial-fab>
</div>