<mat-card class="custom-category-card" (click)="goToCategory(category.id)">
    <mat-card-header class="p-1" fxLayoutAlign="space-between center">
        <mat-card-subtitle class="m-0 category-title font-semibold" fxLayoutAlign="center">
            <h3>{{category.content.name}}</h3>
        </mat-card-subtitle>
        <fa-icon [icon]="activityIcon"></fa-icon>
    </mat-card-header>

    <app-swiper-view #swiper *ngIf="category.show" [images]="category.content.images" (click)="validateGoToCategory($event)" [ngClass]="{'brightness-[0.7]': !category.content.active}">
    </app-swiper-view>

    <mat-card-actions fxLayoutAlign="space-between center" class="p-1 data-row relative">
        <apan *ngIf="isOwnerView && category.content.active" class="bg-accent rounded-full px-2 py-0 text-white z-10 absolute -top-7 left-2 shadow-lg" translate>labels.active</apan>
        <apan *ngIf="isOwnerView && !category.content.active" class="bg-red-600 rounded-full px-2 py-0 text-white z-10 absolute -top-7 left-2 shadow-lg" translate>labels.inactive</apan>
    
        <span class="time"><span translate>labels.created</span> {{category.createdOn | timeAgoAlt}}</span>
        <span fxLayoutAlign="end center">
            <span class="star-button" mat-icon-button><mat-icon>star</mat-icon>{{category.reviewRate || 0.0 | number : '1.1-1'}} <span [hidden]="!category.reviewCount" class="count">({{ 'plural.commentsNumber' | pluralTranslate:category.reviewCount | translate:{ comments: category.reviewCount } }})</span></span>
            <span class="share-button"><mat-icon (click)="openShare($event)">share</mat-icon></span>
        </span>
    </mat-card-actions>
</mat-card>