<mat-expansion-panel *ngIf="_days">

    <mat-expansion-panel-header>
        <mat-panel-title class="subtitle open" *ngIf="_isOpen; else noOpen">
            <fa-icon class="front-icon" [icon]="faClock"></fa-icon>
            {{'subtitles.closes_at' | translate}} {{stateDate.data}}
        </mat-panel-title>
        <ng-template #noOpen>
            <ng-container [ngSwitch]="stateDate.cause">
                <mat-panel-title class="subtitle close" *ngSwitchCase="'dayoff'">
                    <fa-icon class="front-icon" [icon]="faClock"></fa-icon>
                    {{'subtitles.closed_by_dayoff' | translate}}
                </mat-panel-title>
                <mat-panel-title class="subtitle close" *ngSwitchCase="'before'">
                    <fa-icon class="front-icon" [icon]="faClock"></fa-icon>
                    {{'subtitles.opens_at' | translate}} {{stateDate.data}}
                </mat-panel-title>
                <mat-panel-title class="subtitle close" *ngSwitchCase="'after'">
                    <fa-icon class="front-icon" [icon]="faClock"></fa-icon>
                    {{'subtitles.opens_next_available_day' | translate}}
                </mat-panel-title>
            </ng-container>

        </ng-template>
    </mat-expansion-panel-header>

    <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <ng-container *ngFor="let day of weekDays; let i = index">
            <mat-divider></mat-divider>
            <div fxLayout="row" class="p-1 day-container" [ngClass]="{'first':i === 0}">
                <p class="day" fxFlex>
                    {{day}}
                </p>
                <p class="day" fxFlex *ngIf="_days[days[i]]; else noData">
                   <ng-container *ngIf="_isDayOff.has(i); else noOff">
                        <span class="day-off close">{{'subtitles.closed_by_dayoff' | translate}}</span> <br/>
                        <span class="day-off-schedule">({{_days[days[i]].from}} - {{_days[days[i]].to}})</span>
                   </ng-container>
                   <ng-template #noOff>
                        {{_days[days[i]].from}} - {{_days[days[i]].to}}
                   </ng-template>
                </p>
                <ng-template #noData>
                    <p class="day" fxFlex translate>
                        messages.closed
                     </p>
                </ng-template>

            </div>
        </ng-container>
    </div>

</mat-expansion-panel>