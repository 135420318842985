export const QuestionClassType = {
    'app.tinyall.common.form.question.DateQuestion': 'DATE',
    'app.tinyall.common.form.question.DateTimeQuestion': 'DATETIME',
    'app.tinyall.common.form.question.GroupQuestion': 'GROUP',
    'app.tinyall.common.form.question.ImageQuestion': 'IMAGE',
    'app.tinyall.common.form.question.MultiChoicePriceQuestion': 'MULTI_CHOICE_PRICE',
    'app.tinyall.common.form.question.MultiChoiceQuestion': 'MULTI_CHOICE',
    'app.tinyall.common.form.question.NumberQuestion': 'NUMBER',
    'app.tinyall.common.form.question.SingleChoicePriceQuestion': 'SINGLE_CHOICE_PRICE',
    'app.tinyall.common.form.question.SingleChoiceQuestion': 'SINGLE_CHOICE',
    'app.tinyall.common.form.question.TextQuestion': 'TEXT',
    'app.tinyall.common.form.question.TimeQuestion': 'TIME',
    'app.tinyall.common.form.question.GeoPointQuestion': 'GEOPOINT'
};