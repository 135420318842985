export const AnswerClassType = {
    'app.tinyall.common.form.answer.DateAnswer': 'DATE',
    'app.tinyall.common.form.answer.DateTimeAnswer': 'DATETIME',
    'app.tinyall.common.form.answer.GroupAnswer': 'GROUP',
    'app.tinyall.common.form.answer.ImageAnswer': 'IMAGE',
    'app.tinyall.common.form.answer.MultiChoicePriceAnswer': 'MULTI_CHOICE_PRICE',
    'app.tinyall.common.form.answer.MultiChoiceAnswer': 'MULTI_CHOICE',
    'app.tinyall.common.form.answer.NumberAnswer': 'NUMBER',
    'app.tinyall.common.form.answer.SingleChoicePriceAnswer': 'SINGLE_CHOICE_PRICE',
    'app.tinyall.common.form.answer.SingleChoiceAnswer': 'SINGLE_CHOICE',
    'app.tinyall.common.form.answer.TextAnswer': 'TEXT',
    'app.tinyall.common.form.answer.TimeAnswer': 'TIME',
    'app.tinyall.common.form.answer.GeoPointAnswer': 'GEOPOINT'
};