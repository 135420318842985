import { Component, Input } from '@angular/core';
import CURRENCY_SYMBOLS from '../../util/currency-symbols';
import { PriceOption, QuestionAnswerMap } from '../../models/types';
import { HubPromoCodeEntity } from '../../models/hub-promo-code';
import { AnswerTypeClass } from '../../models/answer-type-class';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent {
  @Input() itemLabel = 'productList.products';
  @Input() items: string | number = '0';
  @Input() quantity = 0;
  @Input() additional = 0;
  @Input() shipping: number = 0;
  @Input() tip = 0;
  @Input() currency: string = 'DOP';
  @Input() additionalCharges = {};
  @Input() questionAnswerMap: QuestionAnswerMap;
  @Input() promoCodeEntity: HubPromoCodeEntity;
  @Input() totalPrice: string;

  readonly currencySymbols = CURRENCY_SYMBOLS;
  
  getPromoDiscount() {
    if (!this.promoCodeEntity || !this.promoCodeEntity.discount || this.promoCodeEntity.discount <= 0) return 0;
    const discount = (Number(this.totalPrice) - Number(this.tip)) * (this.promoCodeEntity.discount / 100);
    return this.promoCodeEntity.topDiscount ? Math.min(discount, this.promoCodeEntity.topDiscount) : discount;
  }

  getQuestionRows() {
    const questionRows = [];
    for (const key in this.questionAnswerMap) {

      const QUESTION = this.questionAnswerMap[key];

      if (QUESTION._class === AnswerTypeClass.SINGLE_CHOICE_PRICE) {
        const option = QUESTION.value as PriceOption;
        if (option.price > 0) {
          questionRows.push({ name: option.option, value: option.price });
        }
      } else if (QUESTION._class === AnswerTypeClass.MULTI_CHOICE_PRICE) {
        const options = QUESTION.value as PriceOption[];
        if (options && options.length > 0) {
          for (const OPTION of options) {
            if (OPTION.price > 0) {
              questionRows.push({ name: OPTION.option, value: OPTION.price });
            }
          }
        }
      }
    }
    return questionRows;
  }

  getTotal() {
    return Number(this.items) + Number(this.additional) +  Number(this.shipping) +  Number(this.tip);
  }
}
