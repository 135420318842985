import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  featureFlags: { [key: string]: boolean };

  constructor() {}

  async loadFeatureFlags(): Promise<any> {
    // TODO: get feature flags from the backend
    this.featureFlags = {
      tinyAllPay: true,
      specialPromoCode: true,
    };
  }

  async getFeatureFlag(key: string): Promise<boolean> {
    if (!this.featureFlags) {
      await this.loadFeatureFlags();
    }
    return this.featureFlags ? this.featureFlags[key] : false;
  }
}
