import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { PaymentType } from '../models/payment-type-enum';
import { MenuServiceType } from '../models/menu-service-type-enum';

export const tableNumberValidator = (tableRequired: boolean):ValidatorFn => (
  control: AbstractControl
): ValidationErrors | null => {
  const serviceType = control.get('serviceType').value;
  const tableNumber = control.get('tableNumber');
  if (serviceType === MenuServiceType.RESTAURANT && tableRequired) {
    if (Validators.required(tableNumber)) {
      return { tableNumberRequired: true };
    } else if (tableNumber.value <= 0) {
      return { tableNumberMin: true };
    }
    return null;

  }
  return null;
};



export const cashReturnValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const paymentType = control.get('chosenPaymentType').value;
  const cashReturn = control.get('cashReturn');
  const totalPrice = control.get('totalPrice').value;
  if (paymentType === PaymentType.CASH && totalPrice) {
    return Validators.required(cashReturn)
      ? { cashReturnRequired: true }
      : null;
  }
  return null;
};

export const bankTransferImageValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const paymentType = control.get('chosenPaymentType').value;
  const paymentImage = control.get('paymentImage');
  const totalPrice = control.get('totalPrice').value;
  if (paymentType === PaymentType.BANK_TRANSFERENCE && totalPrice) {
    return Validators.required(paymentImage)
      ? { bankTransferImageRequired: true }
      : null;
  }
  return null;
};

export const chosenPaymentTypeValidator = (supportedPaymentTypes: PaymentType[]): ValidatorFn => {
  return (
    control: AbstractControl
  ): ValidationErrors | null => {
    const paymentType = control.get('chosenPaymentType');
    const totalPrice = control.get('totalPrice').value;
    if (totalPrice && supportedPaymentTypes?.length > 0) {
      return Validators.required(paymentType)
        ? { chosenPaymentTypeRequired: true }
        : null;
    }
    return null;
  };
}

export const createConfirmValueValidator = (fieldName: string, confirmFieldName: string): ValidatorFn => (
  control: AbstractControl
): any => {
  const fieldValue = control.get(fieldName)?.value;
  const confirmField = control.get(confirmFieldName);
  if (fieldValue && confirmField && confirmField.value && fieldValue !== confirmField.value) {
    confirmField.setErrors({ wrongConfirmValue: true });
  } else {
    confirmField.setErrors(null);
  }
}

export const createHigherValueValidator = (valueFieldName: string, higherValueFieldName: string): ValidatorFn => (
  control: AbstractControl
): any => {
  const valueField = control.get(valueFieldName);
  const higherValueField = control.get(higherValueFieldName);
  if (valueField && valueField.value && higherValueField && higherValueField.value && valueField.value > higherValueField.value) {
    valueField.setErrors({
      maxLimitExceeded: true,
    });
  } else {
    return null;
  }
}

export const atLeastOneCheckboxCheckedValidator = (minRequired = 1): ValidatorFn => {
  return (formGroup: FormGroup) => {
    let checked = 0;

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key];

      if (control.value === true) {
        checked++;
      }
    });

    if (checked < minRequired) {
      return {
        requireCheckboxToBeChecked: true,
      };
    }

    return null;
  };
}

