import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { RouteConstants } from "../../app.constants";
import ActivityIconMap from '../../util/activity-icon-map';

@Component({
  selector: "app-activity-breadcrumb",
  templateUrl: "./activity-breadcrumb.component.html",
  styleUrls: ["./activity-breadcrumb.component.scss"],
})
export class ActivityBreadcrumbComponent {
  
  @Input() categoryId: string;
  @Input() type: string;
  @Output() onBack: EventEmitter<any> = new EventEmitter();

  readonly activityIconMap = ActivityIconMap;

  constructor(public router: Router) {}

  goToHub() {
    if(this.categoryId) {
      this.router.navigateByUrl(`${RouteConstants.hub}/owner`);
    } else {
      this.onBack.emit();
    }
  }

  goBack() {
    this.onBack.emit();
  }
}
