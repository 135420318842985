export enum HubCreationStatus {
  NONE = 'NONE',
  INITIAL_DETAILS = 'INITIAL_DETAILS',
  IMAGES = 'IMAGES',
  DESCRIPTION = 'DESCRIPTION',
  ADDRESS = 'ADDRESS',
  LOCATION = 'LOCATION',
  SOCIAL = 'SOCIAL',
  AVAILABILITY = 'AVAILABILITY',
  PAYMENT_METHODS = 'PAYMENT_METHODS',
  TEMPLATE = 'TEMPLATE',
}

export const getStepFromStatus = (status?: HubCreationStatus): number => {
  switch (status) {
    case HubCreationStatus.INITIAL_DETAILS:
      return 2;
    case HubCreationStatus.IMAGES:
      return 3;
    case HubCreationStatus.ADDRESS:
      return 4;
    case HubCreationStatus.DESCRIPTION:
      return 5;
    case HubCreationStatus.LOCATION:
      return 6;
    case HubCreationStatus.SOCIAL:
      return 7;
    case HubCreationStatus.AVAILABILITY:
      return 8;
    case HubCreationStatus.PAYMENT_METHODS:
      return 9;
    default:
      return 0;
  }
};

export const getStatusFromStep = (step?: number): HubCreationStatus => {
  const statuses = [
    HubCreationStatus.NONE,
    HubCreationStatus.NONE,
    HubCreationStatus.INITIAL_DETAILS,
    HubCreationStatus.IMAGES,
    HubCreationStatus.ADDRESS,
    HubCreationStatus.DESCRIPTION,
    HubCreationStatus.LOCATION,
    HubCreationStatus.SOCIAL,
    HubCreationStatus.AVAILABILITY,
    HubCreationStatus.PAYMENT_METHODS,
  ];

  if (!step || step >= statuses.length) {
    return HubCreationStatus.NONE;
  }

  return statuses[step];
};
