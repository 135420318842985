<div fxLayout="row">
  <h2 class="title" translate>formFields.address</h2>
</div>

<div fxLayout="row wrap" fxLayoutAlign="center start">
  <div class="c-flex-p" fxFlex="100">
    <mat-card *ngIf="choosenLocationValue" class="location-card-container" (click)="openAddressPicker()">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="p-1">
          <span class="label">{{ choosenLocationValue.name }}</span>
          <p>
            {{ choosenLocationValue.address.address }},
            {{ choosenLocationValue.address.address2 }}
          </p>
          <p>
            {{ choosenLocationValue.address.city }},
            {{ choosenLocationValue.address.stateOrProvidence }},
            {{ getCountryName(choosenLocationValue.address.country) }}
          </p>
          <p>{{ choosenLocationValue.address.zipCode }}</p>
          <p>{{ choosenLocationValue.address.locationHint }}</p>
        </div>
        <mat-icon>check</mat-icon>
      </div>
    </mat-card>
    <mat-error class="block" *ngIf="!choosenLocationValue" translate>formErrors.your_address</mat-error>
    <button
      *ngIf="!choosenLocationValue"
      (click)="openAddressForm()"
      mat-raised-button
      color="accent"
    >
      <span translate>buttons.add_a_new_location</span>
    </button>
  </div>
</div>
