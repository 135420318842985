<div fxLayout="row">
    <h2 class="title" translate>productList.products</h2>
</div>

<div fxLayout="column wrap" class="page-container">
    <app-product-search-bar id="app-top-search-bar" 
        [items]="searchCriteria"
        (onSearhChange)="searchCriteriaChanged($event)">
    </app-product-search-bar>

    <div *ngIf="(items && items.length > 0) || loading; else noItems" class="list-container">
        <div *ngIf="loading && !settings.loadingSpinner; else noLoading" class="loading" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
            <mat-spinner color="primary" diameter="60"></mat-spinner>
        </div>
        <ng-template #noLoading>
            <div fxLayout="column" fxLayoutGap="20px">

                <app-product-item *ngFor="let item of items" 
                    [item]="item" 
                    [selected]="selectedForm.get('id').value === item.id"
                    [loading]="loadingAddToCart"
                    [selectedForm]="selectedForm"
                    [currency]="currency"
                    (onAddToCart)="addToCart($event)">
                </app-product-item>

                <mat-card *ngIf="length > pageSize" class="paginator-container">
                    <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex" [hidePageSize]="true" (page)="pageEvent($event)">
                    </mat-paginator>
                </mat-card>
            </div>
        </ng-template>
    </div>
    <ng-template #noItems>
        <div class="no-items-container">
            <mat-icon>local_offer</mat-icon>
            <p translate>
                productList.no_products_text
            </p>
        </div>
    </ng-template>
</div>
