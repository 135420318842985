import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { ServiceModule } from '../../service/service.module';
import { AuthGuard } from '../../auth/auth.guard';
import { DirectivesModule } from '../../theme/directives/directives.module';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { HubManagementComponent } from './hub-management/hub-management.component';
import { HubClientViewComponent } from './hub-client-view/hub-client-view.component';
import { HubOwnerViewComponent } from './hub-owner-view/hub-owner-view.component';
import { SpeedDialFabComponent } from './components/speed-dial-fab/speed-dial-fab.component';
import { HubReportsComponent } from './components/hub-reports/hub-reports.component';
import { SocialMediaLinksManagementComponent } from './components/social-media-links-management/social-media-links-management.component';
import { SocialMediaLinksClientViewComponent } from './components/social-media-links-client-view/social-media-links-client-view.component';
import { CustomLinksManagementComponent } from './components/custom-links-management/custom-links-management.component';
import { CustomLinksClientViewComponent } from './components/custom-links-client-view/custom-links-client-view.component';
import { HubTagPickerComponent } from './components/hub-tag-picker/hub-tag-picker.component';
import { OpenHoursClientViewComponent } from './components/open-hours-client-view/open-hours-client-view.component';
import { OpenHoursManagementComponent } from './components/open-hours-management/open-hours-management.component';
import { ComponentsModule } from '../../components/componets.module';
import { SortActCatManagementComponent } from '../../components/sort-act-cat-management/sort-act-cat-management.component';
import { FormGuard } from '../../guards/form.guard';
import { PipesModule } from '../../theme/pipes/pipes.module';
import { HubPaymentCardComponent } from './components/hub-payment-card/hub-payment-card.component';
import { HubAboutUsComponent } from './hub-about-us/hub-about-us.component';
import { SortHubMenuManagementComponent } from '../../components/sort-hub-menu-management/sort-hub-menu-management.component';
import { HubNavigationMenuCardComponent } from './components/hub-navigation-menu-card/hub-navigation-menu-card.component';
import { ActivitySearchBarComponent } from './components/activity-search-bar/activity-search-bar.component';
import { ActivityFilterComponent } from './components/activity-filter/activity-filter.component';
import { ActivitySortComponent } from './components/activity-sort/activity-sort.component';
import { HubReadinessChecklistComponent } from './components/hub-readiness-checklist/hub-readiness-checklist.component';
import { HubCreateActivityCardComponent } from './components/hub-create-activity-card/hub-create-activity-card.component';
import { HubPromoCodesCardComponent } from './components/hub-promo-codes-card/hub-promo-codes-card.component';
import { HubManagementWorkflowComponent } from './hub-management-workflow/hub-management-workflow.component';
import { WorkflowInfoDialogComponent } from './components/workflow-info-dialog/workflow-info-dialog.component';
import { ActivityInfoModule } from '../activity-info/activity-info.module';
import { ActivityEventModule } from '../activity-event/activity-event.module';
import { ActivityMenuModule } from '../activity-menu/activity-menu.module';
import { ActivityProductListingModule } from '../activity-product-listing/activity-product-listing.module';
import { ActivityRequestModule } from '../activity-request/activity-request.module';
import { ActivityReservationModule } from '../activity-reservation/activity-reservation.module';
import { CategoryModule } from '../category/category.module';
import { SpecialPromoCodeDialogComponent } from './components/special-promo-code-dialog/special-promo-code-dialog.component';
import { HubSortActivitiesCardComponent } from './components/hub-sort-activities-card/hub-sort-activities-card.component';

export const routes = [
  { path: '', component: HubClientViewComponent },
  { path: 'owner', component: HubOwnerViewComponent, canActivate: [AuthGuard] },
  {
    path: 'sort',
    component: SortActCatManagementComponent,
    canActivate: [AuthGuard],
    canDeactivate: [FormGuard],
  },
  {
    path: 'sort-menu',
    component: SortHubMenuManagementComponent,
    canActivate: [AuthGuard],
    canDeactivate: [FormGuard],
  },
  {
    path: 'manage',
    component: HubManagementComponent,
    canActivate: [AuthGuard],
    canDeactivate: [FormGuard],
  },
  { path: 'client-view', component: HubClientViewComponent },
  { path: 'about-us', component: HubAboutUsComponent },
  {
    path: 'workflow',
    component: HubManagementWorkflowComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ComponentsModule,
    ServiceModule,
    DirectivesModule,
    MatSelectCountryModule,
    PipesModule,
    ActivityInfoModule,
    ActivityEventModule,
    ActivityMenuModule,
    ActivityProductListingModule,
    ActivityRequestModule,
    ActivityReservationModule,
    CategoryModule,
    //RouterModule.forChild(routes),
  ],
  declarations: [
    HubAboutUsComponent,
    HubManagementComponent,
    HubClientViewComponent,
    HubOwnerViewComponent,
    SpeedDialFabComponent,
    HubReportsComponent,
    HubPaymentCardComponent,
    HubNavigationMenuCardComponent,
    HubSortActivitiesCardComponent,
    SocialMediaLinksManagementComponent,
    SocialMediaLinksClientViewComponent,
    CustomLinksManagementComponent,
    CustomLinksClientViewComponent,
    HubTagPickerComponent,
    OpenHoursClientViewComponent,
    OpenHoursManagementComponent,
    ActivitySearchBarComponent,
    ActivityFilterComponent,
    ActivitySortComponent,
    HubReadinessChecklistComponent,
    HubCreateActivityCardComponent,
    HubPromoCodesCardComponent,
    HubManagementWorkflowComponent,
    WorkflowInfoDialogComponent,
    SpecialPromoCodeDialogComponent
  ],
  providers: [],
})
export class HubModule {}
