export enum PaymentType {
    CASH = 'CASH',
    CC_DELIVERY = 'CC_DELIVERY',
    PAYMENT_LINK = 'PAYMENT_LINK',
    BANK_TRANSFERENCE = 'BANK_TRANSFERENCE',
    TINYALL_PAY = 'TINYALL_PAY',
    CC_IN_ADVANCE = 'CC_IN_ADVANCE',
}

export const FREE_PAYMENT_TYPES = [
    PaymentType.CASH,
    PaymentType.CC_DELIVERY,
];
