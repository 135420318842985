<ng-container *ngIf="!notFound; else pageNotFound">
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <button (click)="back()" mat-button>
      <span translate>tinyAll.back</span>
    </button>
  </div>

  <div class="page-container" fxLayout="column">
    <div class="flex-p">
      <app-navigation-menu
        *ngIf="hub"
        [hubId]="hub?.id"
        [showBautUs]="false"
        [handler]="hub?.urlHandler"
      >
      </app-navigation-menu>
    </div>

    <div class="flex-p">
      <mat-card class="custom-card">
        <mat-card-content>
          <div class="hub-title" fxLayoutAlign="center">
            <h3>{{ hub?.name }}</h3>
          </div>
          <div class="p-1">
            <app-rich-text-view [text]="hub?.infoText"></app-rich-text-view>
          </div>
          <mat-divider></mat-divider>
          <div class="p-1">
            <p>{{ hub?.email }}</p>
          </div>
          <div class="p-1">
            <p>{{ hub?.phoneNumber }}</p>
          </div>
          <mat-divider></mat-divider>
          <div class="p-1">
            <p>{{ hub?.address.address }}, {{ hub?.address.address2 }}</p>
            <p>
              {{ hub?.address.city }}, {{ hub?.address.stateOrProvidence }},
              {{ countryName }}
            </p>
            <p>{{ hub?.address.zipCode }}</p>
            <p>{{ hub?.address.locationHint }}</p>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <app-location-map
      *ngIf="hub && hub.location"
      [location]="hub.location"
      [name]="hub.name"
      [launch]="true"
    >
    </app-location-map>

    <app-social-media-links-client-view
      *ngIf="fullSocialLinks"
      [socialLinks]="fullSocialLinks"
    >
    </app-social-media-links-client-view>

    <app-custom-links-client-view
      *ngIf="hub && hub.customLinks"
      class="flex-p"
      [customLinks]="hub.customLinks"
    >
    </app-custom-links-client-view>

    <app-open-hours-client-view
      *ngIf="schedule && schedule.hours && schedule.hours.length > 0"
      class="flex-p"
      [schedule]="schedule"
    >
    </app-open-hours-client-view>
  </div>
</ng-container>
<ng-template #pageNotFound>
  <app-page-not-found (back)="back()"></app-page-not-found>
</ng-template>
<app-loading></app-loading>
