import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ReservationEntity } from '../../../../models/activity-reservation';

@Component({
  selector: 'app-request-details-dialog',
  templateUrl: './request-details-dialog.component.html',
  styleUrls: ['./request-details-dialog.component.scss']
})
export class RequestDetailsDialogComponent {

  report: ReservationEntity;
  updatedReport:  any;

  constructor(
    public dialogRef: MatDialogRef<RequestDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
      this.report = data.report; 
  }

  close(): void {
    this.dialogRef.close(this.updatedReport);
  }

  onUpdate(report: any) {
    this.updatedReport = report;
  }

}
