import {
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from "@angular/forms";
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { RxwebValidators } from "@rxweb/reactive-form-validators";
import { TranslateService } from "@ngx-translate/core";
import { faLink } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-product-item-management",
  templateUrl: "./product-item-management.component.html",
  styleUrls: ["./product-item-management.component.scss"],
})
export class ProductItemManagementComponent {
  @Input() item: any;
  @Input() selected: boolean;
  @Input() loading: boolean;
  @Input() selectedForm: UntypedFormGroup;
  @Input() images: string[];

  @Output() onSelectItem = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Output() onDelete = new EventEmitter();

  optionName: string;
  maxVariants = 20;
  readonly faLink = faLink;

  constructor(private translate: TranslateService, private fb: UntypedFormBuilder) {
    this.optionName = this.translate.instant("productList.variant");
  }

  get name() {
    return this.selectedForm.get("name") as UntypedFormControl;
  }

  get infoText() {
    return this.selectedForm.get("infoText") as UntypedFormControl;
  }

  get active() {
    return this.selectedForm.get("active") as UntypedFormControl;
  }

  get available() {
    return this.selectedForm.get("available") as UntypedFormControl;
  }

  get links() {
    return this.selectedForm.get("links") as UntypedFormControl;
  }
  
  getVariantsControls(): UntypedFormGroup[] {
    const OPTIONS = this.selectedForm.get("options") as UntypedFormArray;
    return OPTIONS.controls as UntypedFormGroup[];
  }

  getVariants(): UntypedFormArray {
    const OPTIONS = this.selectedForm.get("options") as UntypedFormArray;
    return OPTIONS;
  }

  getTags(): UntypedFormArray {
    return this.selectedForm.get("tags") as UntypedFormArray;
  }

  createOption(
    optionName: string,
    price: String,
    available: boolean
  ): UntypedFormGroup {
    return this.fb.group({
      description: [
        optionName,
        [Validators.required, RxwebValidators.unique()],
      ],
      price: [price, [Validators.required]],
      available: [available],
    });
  }

  focusOption(option: UntypedFormGroup) {
    const value: string = option.value.description;
    if (
      value &&
      (value.startsWith("Variant") || value.startsWith("Variante"))
    ) {
      option.controls.description.setValue("");
    }
  }

  addOption(): void {
    if (this.loading) {
      return;
    }
    const optionsForm = this.getVariants();
    const OPTION = this.createOption(
      `${this.optionName} ${optionsForm.length + 1}`,
      "0.00",
      true
    );
    OPTION.get("description").markAsTouched();
    optionsForm.push(OPTION);
    this.selectedForm.markAsDirty();
  }

  removeOption(optionIndex: number): void {
    if (this.loading) {
      return;
    }

    const optionsForm = this.getVariants();
    if (optionsForm.length > 1) {
      optionsForm.removeAt(optionIndex);
      this.selectedForm.markAsDirty();
      for (const control of optionsForm.controls) {
        control.get("description").updateValueAndValidity();
      }
    }
  }

  selectItem() {
    if (this.loading) {
      return;
    }
    this.selectedForm.reset();

    this.selectedForm.get("id").setValue(this.item.id);
    this.selectedForm.get("name").setValue(this.item.name);
    this.selectedForm.get("infoText").setValue(this.item.infoText);
    this.selectedForm.get("active").setValue(this.item.active);
    this.selectedForm.get("available").setValue(this.item.available);
    this.selectedForm.get("activityId").setValue(this.item.activityId);
    this.selectedForm.get("hubId").setValue(this.item.hubId);
    this.selectedForm.get("userId").setValue(this.item.userId);
    this.selectedForm
      .get("links")
      .setValue(
        Array.isArray(this.item.links) ? this.item.links[0] : this.item.links
      );

    this.images.length = 0;
    this.images.push(...this.item.images);
    if (this.images.length === 0) {
      this.images.push("");
    }

    const VARIANT_FORM = this.getVariants();
    VARIANT_FORM.clear();

    for (const VARIANT of this.item.options) {
      VARIANT_FORM.push(
        this.createOption(VARIANT.description, VARIANT.price, VARIANT.available)
      );
    }

    const TAGS = this.getTags();
    TAGS.clear();

    for (const TAG of this.item.tags) {
      TAGS.push(this.fb.control(TAG));
    }

    setTimeout(() => {
      const element = document.getElementById(this.item.id);
      if (element !== null) {
        element.scrollIntoView();
      }
    }, 200);
    this.onSelectItem.emit(this.item);
  }

  save() {
    if (this.loading || this.selectedForm.invalid) {
      this.selectedForm.markAllAsTouched();
      return;
    }
    this.onSave.emit();
  }

  cancel() {
    if (this.loading) {
      return;
    }
    this.getVariants().clear();
    this.getTags().clear();
    this.selectedForm.reset();
    setTimeout(() => {
      const ID =
        this.item.id === "new-item-template"
          ? "app-top-search-bar"
          : this.item.id;
      const element = document.getElementById(ID);
      if (element !== null) {
        element.scrollIntoView();
      }
    }, 200);
  }

  canDelete(): void {
    this.onDelete.emit();
  }
}
