import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, switchMap} from 'rxjs/operators';
import { HttpService } from '../../service/http.service';
import { environment } from '../../../environments/environment';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import SEPARATOR_KEYS_CODES from '../../util/separator-keys-codes';

@Component({
  selector: 'app-tag-picker',
  templateUrl: './tag-picker.component.html',
  styleUrls: ['./tag-picker.component.scss']
})
export class TagPickerComponent implements OnInit {

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;


  @Input() items: any;
  @Input() max = 1000;
  @Output() onTagChange: EventEmitter<any> = new EventEmitter();

  private readonly apiUrl = environment.apiUrl;
  private readonly validateTag = /^[ña-zÑA-Z0-9\-]+$/;
  private readonly validateAdd = /[^0-9ña-z\-]/gi;

  tag = new UntypedFormControl();
  
  filteredOptions: Observable<string[]>;
  separatorKeysCodes = SEPARATOR_KEYS_CODES;

  constructor(private httpService: HttpService) {}

  ngOnInit() {
    this.filteredOptions = this.tag.valueChanges.pipe(
        startWith(null),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(value => this._filter(value)),
      );
  }

  validateKey(event: any): void {
    if (!event.data || typeof event.data !== 'string') {
      return;
    }
    if (!this.validateTag.test(event.data)) {
      this.add({
        input: event.target,
        value: event.target.value
      } as MatChipInputEvent);
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    if (!event.value || !event.value.trim()) {
      return;
    }
    const value = event.value.replace(this.validateAdd, '');
    if (!value) {
      return;
    }

    const readyValue = value.toLocaleLowerCase();
    if (this.validateUnique(readyValue)) { 
      this.items.push(readyValue);
      this.tagChange();
    }

    if (input) {
      input.value = '';
    }

    this.tag.setValue(null);
  }

  remove(item: string): void {
    this.tagInput.nativeElement.value = '';
    this.tag.setValue(null);
    const index = this.items.indexOf(item);

    if (index >= 0) {
      this.items.splice(index, 1);
    }
    this.tagChange();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.items.pop();
    const value = event.option.viewValue.toLocaleLowerCase();
    if (this.validateUnique(value)) { 
      this.items.push(value);
      this.tagInput.nativeElement.value = '';
      this.tag.setValue(null);
      this.tagChange();
      return; 
    }

    this.tagInput.nativeElement.value = '';
    this.tag.setValue(null);
  }

  validateUnique(value: string) {
    for (const control of this.items) {
      if (control === value) { return false; }
    }
    return true;
  } 

  tagChange() {
    this.onTagChange.emit(this.items);
  }

  private async _filter(value: string) {
    if (!value) {
      return [];
    }
    const filterValue = value.toLowerCase();

    const response = await this.httpService
    .doGet(`${this.apiUrl}/hubs/v1/search?querySearch=tags%23'${filterValue}*' AND unlisted:false AND active:true&page=0&size=20`)
    .toPromise();
    
    if (response && response.content) {
      const tags = new Set();
      for (const HUB of response.content) {
        for (const TAG of HUB.tags) {
          if (TAG.toLowerCase().startsWith(filterValue)) {
            tags.add(TAG);
          }
        }
      }

      return Array.from(tags) as string[];
    } else {
      return [];
    }
  }

  cleanTag() {
    this.items.length = 0;
    this.tagChange();
  }

  clear(event: MatChipInputEvent): void {
    this.items.pop();
    this.tagChange();
  }

  panelOpened() {
    const el: any = document.getElementsByClassName('cdk-overlay-pane')[0];
    if (el) {
      el.classList.add('tag-picker-panel-overlay');
    }
  }

  panelClosed() {
    const el: any = document.getElementsByClassName('cdk-overlay-pane')[0];
    if (el) {
      el.classList.remove('tag-picker-panel-overlay');
    }
  }
}
