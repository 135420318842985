import { Component, Input } from '@angular/core';
import { AnswerClassType } from '../../models/answer-class-type';
import { last } from 'lodash';

@Component({
  selector: 'app-view-question-list',
  templateUrl: './view-question-list.component.html',
  styleUrls: ['./view-question-list.component.scss'],
})
export class ViewQuestionListComponent {
  readonly AnswerClassType = AnswerClassType;

  isDownloadingImage = false;

  @Input()
  answers: any;

  @Input()
  title: string;

  encrypted = true;

  constructor() {}

  isEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  getQuestions(object) {
    return Object.keys(object);
  }

  getMultiChoicePrice(content: any) {
    return Object.keys(content);
  }

  getSingleChoicePrice(inAnswer: any) {
    return `${inAnswer.option} - (${inAnswer.price})`;
  }

  getTime(time: string) {
    return `2020-00-00T${time}.000Z`;
  }

  async downloadImage(imageSrc: string) {
    if (this.isDownloadingImage) return;
    try {
      this.isDownloadingImage = true;
      const src = imageSrc.replace('q_auto/', '');
      const imageName = last(imageSrc.split('/'));
  
      const image = await fetch(src);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);
  
      const link = document.createElement('a');
      link.href = imageURL;
      link.download = imageName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.isDownloadingImage = false;
    } catch (_error) {
      this.isDownloadingImage = false;
    }

  }
}
