import { UntypedFormGroup } from "@angular/forms";
import { Component, Input } from "@angular/core";
import {
  faFacebookSquare,
  faTwitterSquare,
  faLinkedin,
  faInstagramSquare,
  faSnapchatSquare,
  faPinterestSquare,
  faRedditSquare,
  faWhatsappSquare,
  faTumblrSquare,
  faTelegram,
  faYoutubeSquare,
  faMedium,
} from "@fortawesome/free-brands-svg-icons";
import REGEX from "../../../../util/regex";
import socialMediaPrefix from "../../../../util/social-media-map";

const dividers = {
  facebook: "facebook.com/",
  twitter: "twitter.com/",
  linkedin: "linkedin.com/in/",
  instagram: "instagram.com/",
  snapchat: "snapchat.com/add/",
  pinterest: "pinterest.com/",
  reddit: "reddit.com/user/",
  whatsapp: "wa.me/",
  tumblr: ".tumblr.com",
  telegram: "telegram.me/",
  youtube: "youtube.com/channel/",
  medium: "medium.com/",
};

@Component({
  selector: "app-social-media-links-management",
  templateUrl: "./social-media-links-management.component.html",
  styleUrls: ["./social-media-links-management.component.scss"],
})
export class SocialMediaLinksManagementComponent {
  @Input() form: UntypedFormGroup;
  @Input() noCard: boolean = false;

  readonly faFacebookSquare = faFacebookSquare;
  readonly faTwitterSquare = faTwitterSquare;
  readonly faLinkedin = faLinkedin;
  readonly faInstagramSquare = faInstagramSquare;
  readonly faSnapchatSquare = faSnapchatSquare;
  readonly faPinterestSquare = faPinterestSquare;
  readonly faRedditSquare = faRedditSquare;
  readonly faWhatsappSquare = faWhatsappSquare;
  readonly faTumblrSquare = faTumblrSquare;
  readonly faTelegram = faTelegram;
  readonly faYoutubeSquare = faYoutubeSquare;
  readonly faMedium = faMedium;

  readonly urlRegx = REGEX.URL;
  readonly socialMediaPrefix = socialMediaPrefix;

  constructor() {}

  fixURL(name: string): void {
    const control = this.form.controls[name];
    if (control.value && !control.value.startsWith("https://")) {
      control.setValue("https://" + control.value);
    }
  }

  removePrefix(name: string) {
    const control = this.form.controls[name];
    let value = control.value;
    if (value && value.includes(dividers[name])) {
      value = value.split(dividers[name])[1];
      control.setValue(value);
    }

    if (this.urlRegx.test(socialMediaPrefix[name] + value)) {
      control.setErrors(null);
    } else {
      control.setErrors({ pattern: true });
    }
  }

  removeSuffix(name: string) {
    const control = this.form.controls[name];
    let value = control.value;
    if (value && value.includes(dividers[name])) {
      value = value.split(dividers[name])[0];
      if (value.startsWith("https://")) {
        value = value.split("https://")[1];
      }
      control.setValue(value);
    }

    if (this.urlRegx.test("https://" + value + socialMediaPrefix[name])) {
      control.setErrors(null);
    } else {
      control.setErrors({ pattern: true });
    }
  }
}
