import { Injectable } from '@angular/core';
import { AuthService } from '../../../auth/auth.serviceV2';
import { Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TermsDialogComponent } from '../../../components/terms-dialog/terms-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RouteConstants } from '../../../app.constants';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { AuthErrorCodes, EmailAuthProvider } from 'firebase/auth';
import Swal from 'sweetalert2';

@Injectable()
export class UserAdditionalInfoService {

  settings: Settings;
  
  constructor(
    private appSettings: AppSettings,
    private authService: AuthService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.settings = this.appSettings.settings;
  }

  async check(redirect:string = null): Promise<boolean> {
    let user = null;

    try {
      user = await this.authService.getLoginRedirectResult();
    } catch (error) {
      if (error?.code === AuthErrorCodes.NEED_CONFIRMATION) {
        const currentProvider = error.customData._tokenResponse.verifiedProvider[0];
        const message = `${this.translate.instant(`firebase.${error.code}`)} ${this.translate.instant(`firebase.${currentProvider}`)}`;
        Swal.fire({
          titleText: this.translate.instant('firebase.title'),
          text: message,
          icon: 'error',
          confirmButtonColor: '#5D6293',
        });
      }
      return false;
    }

    if (!user) {
      return false;
    }

    const language = user.language || this.translate.getBrowserLang();
    switch (language.toLowerCase()) {
      case 'en':
        registerLocaleData(localeEn, 'en');
        this.translate.setDefaultLang('en');
        break;
      default:
        registerLocaleData(localeEs, 'es');
        this.translate.setDefaultLang('es');
    }

    if (!user.termOfServiceAgree) {
      const dialogRef = this.openTerms();
      this.settings.loadingSpinner = false;
      await firstValueFrom(dialogRef.afterClosed());
    }

    const idToken = await this.authService.getCurrentProvider();
    const userProvider = idToken?.signInProvider;

    if (user && (!user.cellPhone || !user.name || (userProvider == EmailAuthProvider.PROVIDER_ID && !(await this.authService.fetchSignInMethodsByEmail(user.email)).includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)))) {
      if(redirect) {
        localStorage.setItem('return_from_login_url', redirect);
        localStorage.setItem('return_url', redirect);
      }
      await this.router.navigateByUrl(`${RouteConstants.auth}/data-collector`);
      return false;
    } else if (!redirect) {
      await this.router.navigateByUrl(
        localStorage.getItem(RouteConstants.returnUrl)
      );
    }
    return true;
  }

  private openTerms() {
    return this.dialog.open(TermsDialogComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'terms-dialog',
    });
  }
}
