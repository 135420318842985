import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { faInfo } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-info-tooltip',
  templateUrl: './info-tooltip.component.html',
  styleUrls: ['./info-tooltip.component.scss']
})
export class InfoTooltipComponent {
  infoText = '';
  @Input() set info (value: any) {
    this.infoText = this.translate.instant(value);
  }
  @Input() infoClass = 'primary';

  readonly faInfo = faInfo;
  constructor(private translate: TranslateService) {}

  clickHandler(e, tooltip) {
    tooltip.toggle();
    e.stopPropagation();
  }
}
