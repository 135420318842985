
<div class="terms-and-conditions" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1rem">
    <div class="terms-and-conditions-container">
        <app-rich-text-view *ngIf="entity" [text]="entity.infoText"></app-rich-text-view>
        <div class="fixed bottom-0 pb-4 px-4 border-t left-0 right-0 bg-[#fafafa]">
            <div class="checkbox-container max-w-[350px] mx-auto" fxLayout.gt-md="row" fxLayoutAlign.gt-md="center center" fxLayoutGap.gt-md="4rem">
                <form [formGroup]="checkBoxForm">
                    <mat-checkbox formControlName="notifiableFromPromo" color="primary"><span translate>login.i_agree_to_promo_email</span></mat-checkbox>
                    <br />
                    <br />
                    <strong translate>login.terms_and_conditions_question</strong>
                    <br/>
                    <mat-checkbox formControlName="termOfServiceAgree" color="primary"><span translate>login.i_agree</span></mat-checkbox>
                </form>
            </div> 
        
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4rem">
                <button mat-raised-button color="primary" type="button"  [disabled]="!checkBoxForm.get('termOfServiceAgree').value" (click)='accept()'><span translate>login.accept</span></button>
            </div>
        </div>
    </div>
</div>




