import { Component, OnInit, Input } from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, switchMap} from 'rxjs/operators';
import { HttpService } from '../../service/http.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-city-autocomplete',
  templateUrl: './city-autocomplete.component.html',
  styleUrls: ['./city-autocomplete.component.scss']
})
export class CityAutocompleteComponent implements OnInit {

  @Input() city: UntypedFormControl;

  private readonly apiUrl = environment.apiUrl;

  filteredOptions: Observable<string[]>;

  constructor(private httpService: HttpService) {}

  ngOnInit() {
    this.filteredOptions = this.city.valueChanges.pipe(
        startWith(null),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(value => this._filter(value)),
      );
  }

  private async _filter(value: string) {
    const filterValue = this.city.value;
    if (!filterValue) {
      return [];
    }

    const response = await this.httpService
    .doGet(`${this.apiUrl}/hubs/v1/search?querySearch=address.city:'${filterValue}*' AND unlisted:false AND active:true AND restricted:false&page=0&size=10`)
    .toPromise();
    
    if (response && response.content) {
      const items = new Set();
      for (const HUB of response.content) {
        if (HUB.address.city) {
          items.add(HUB.address.city);
        }
      }
      return Array.from(items) as string[];
    } else {
      return [];
    }
  }

}
