import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { TicketStatus } from '../../models/activity-event';
import { TicketChangeLog } from '../../models/ticket-change-log';
import { QrGeneratorDialogComponent } from '../qr-generator-dialog/qr-generator-dialog.component';

@Component({
  selector: 'app-ticket-card',
  templateUrl: './ticket-card.component.html',
  styleUrls: ['./ticket-card.component.scss'],
})
export class TicketCardComponent implements OnInit {
  @Input() label: string;
  @Input() text: string;
  @Input() status: TicketStatus;
  @Input() ticketChangeLogs: TicketChangeLog[];

  public lastTicketLog: TicketChangeLog;

  icon = faQrcode;

  constructor(public dialog: MatDialog) {}

  ngOnInit() {
    if (this.ticketChangeLogs && this.ticketChangeLogs.length) {
      this.lastTicketLog =
        this.ticketChangeLogs[this.ticketChangeLogs.length - 1];
    }
  }

  open(e): void {
    e.stopPropagation();

    this.dialog.open(QrGeneratorDialogComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'qr-generator-dialog',
      data: {
        qrCode: this.text,
        isTicket: true,
        ticketStatus: this.status,
        ticketChangeLogs: this.ticketChangeLogs,
      },
    });
  }
}
