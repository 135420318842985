<mat-toolbar color="primary" [fxLayoutAlign]="(settings.menuType != 'mini') ? 'space-between center' : 'center center'" class="sidenav-header">
    <a class="logo-wrapper" routerLink="/" (click)="closeSubMenus()">
        <img class="icon" src="/assets/img/app/min-icon.png" />
    </a>
</mat-toolbar>

<div fxLayout="column" fxLayoutAlign="center center" class="user-block transition-2" [class.show]="settings.sidenavUserBlock">
    <div *ngIf="auth.isAuthenticatedSync()" [fxLayout]="(settings.menuType != 'default') ? 'column' : 'row'" [fxLayoutAlign]="(settings.menuType != 'default') ? 'center center' : 'space-around center'" class="user-info-wrapper">
        <img referrerpolicy="no-referrer" [src]="userProfile?.picture ? userProfile.picture : noImage" alt="user-image" class="rounded mat-elevation-z6 object-cover w-16 h-16">
        <div class="user-info">
            <p class="name">{{userProfile?.name}} <br> <small>{{userProfile?.email}}</small></p>
        </div>
    </div>
    <div *ngIf="!auth.isAuthenticatedSync()" [fxLayout]="(settings.menuType != 'default') ? 'column' : 'row'" [fxLayoutAlign]="(settings.menuType != 'default') ? 'center center' : 'space-around center'" class="user-info-wrapper">
        <img [src]="noImage" alt="user-image" width="70" height="70" class="rounded mat-elevation-z6">
        <div class="user-info">
            <p class="name" translate>userMenu.user_anonymous</p>
        </div>
    </div>
    <div *ngIf="settings.menuType != 'mini'" fxLayout="row" fxLayoutAlign="space-around center" class="w-100 muted-text">
        <!--
        <a (click)="logout()" mat-icon-button>
            <mat-icon>power_settings_new</mat-icon>
        </a>
        -->
    </div>
</div>

<div id="sidenav-menu-outer" class="sidenav-menu-outer" [perfectScrollbar]="perfectScrollBarConfig" [class.user-block-show]="settings.sidenavUserBlock">
    <span *ngIf="!menuItems" translate>tinyAll.loading</span>
    <app-vertical-menu [menuItems]="menuItems" [menuParentId]="0"></app-vertical-menu>
</div>
<div class="app-version">{{currentApplicationVersion}}</div>