import { UntypedFormControl } from "@angular/forms";
import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { DatetimeAdapter } from "@mat-datetimepicker/core";
import { DateAdapter } from "@angular/material/core";
import { TranslateService } from "@ngx-translate/core";
import DAYS from "../../util/days";
import { MatCalendar } from "@angular/material/datepicker";

@Component({
  selector: "app-reservation-picker",
  templateUrl: "./reservation-picker.component.html",
  styleUrls: ["./reservation-picker.component.scss"],
})
export class ReservationPickerComponent implements AfterViewInit {
  @Input() title = "activityReservation.make_reservation";
  @Input() isLoading: boolean;
  @Input() daySelected: Date;
  @Input() reservationSelectedControl: UntypedFormControl;
  @Input() daySchedule: number[];
  @Input() offsetMinutes: number;
  @Input() daysOff: Set<string>;
  @Input() busySlots: Set<string>;
  @Input() reservationDuration: number;
  @Input() daysWithSchedule: Set<string>;

  @Output()
  dateChanged: EventEmitter<Date> = new EventEmitter();

  @Output()
  reservationChanged: EventEmitter<Date> = new EventEmitter();

  @ViewChild("calendar") calendar: MatCalendar<Date>;

  private readonly minDate = new Date();
  private readonly MAX_DURATION = 60;
  private readonly reservationCells = ["0:00", "0:15", "0:30", "0:45"];

  constructor(
    public _adapter: DatetimeAdapter<Date>,
    public _dateAdapter: DateAdapter<Date>,
    private translate: TranslateService
  ) {
    _adapter.setLocale(translate.getDefaultLang());
    (_adapter as any)._delegate.setLocale(translate.getDefaultLang());
    _dateAdapter.setLocale(translate.getDefaultLang());
  }

  ngAfterViewInit(): void {
    if (!this.daySelected || this.daysWithSchedule.size === 0) {
      return;
    }

    let date = this.daySelected;
    let week_day = this._adapter.getDayOfWeek(date);

    if (this.daysWithSchedule.has(DAYS[week_day])) {
      return;
    }

    while (
      !this.daysWithSchedule.has(DAYS[week_day]) ||
      this.daysOff.has(`${date.toISOString().split("T")[0]}T00:00:00Z`)
    ) {
      date = this._adapter.addCalendarDays(date, 1);
      week_day = this._adapter.getDayOfWeek(date);
    }

    this.daySelected = date;
    this.dateChanged.emit(this.daySelected);
    this.calendar.updateTodaysDate();
    this.calendar._goToDateInView(this.daySelected, "month");
    setTimeout(() => {
      const mainContent = document.getElementById("main-content");
      if (mainContent) {
        mainContent.scrollTop = 0;
      }
    }, 500);
  }

  filterDates = (date: Date): boolean => {
    if (this._adapter.compareDate(this.minDate, date) > 0) {
      return false;
    }
    const WEEK_DAY = this._adapter.getDayOfWeek(date);
    if (!this.daysWithSchedule.has(DAYS[WEEK_DAY])) {
      return false;
    }

    if (this.daysOff.has(`${date.toISOString().split("T")[0]}T00:00:00Z`)) {
      return false;
    }
    return true;
  };

  isSelected = (event: any) => {
    return this._adapter.sameDate(this.daySelected, event) ? "selected" : null;
  };

  select(event: any, calendar: MatCalendar<Date>) {
    if (this.isLoading) {
      return;
    }
    if (!this._adapter.sameDate(this.daySelected, event)) {
      this.daySelected = event;
      this.reservationSelectedControl.setValue(null);
      this.dateChanged.emit(this.daySelected);
      calendar.updateTodaysDate();
    }
  }

  getReservationCells(size: number) {
    switch (size) {
      case 15:
        return ["0:00", "0:15", "0:30", "0:45"];
      case 30:
        return ["0:00", "0:30"];
      case 60:
        return ["0:00"];
    }
  }

  getReservationCellsMinutes(size: number) {
    switch (size) {
      case 15:
        return ["00", "15", "30", "45"];
      case 30:
        return ["00", "30"];
      case 60:
        return ["00"];
    }
  }

  getArray(size: number) {
    return Array(Math.floor(this.MAX_DURATION / size)).fill("D");
  }

  getHourAMPM(hour: number, minutes: string): string {
    const [hourLabel, AMPMLabel] = this.getHourLable(hour);
    return `${hourLabel}:${minutes}${AMPMLabel}`;
  }

  getHourLable(hour: number): string[] {
    let hour_label;
    if (hour === 0) {
      hour_label = "12";
    } else {
      hour_label = hour > 12 ? `${hour - 12}` : `${hour}`;
    }

    if (this.offsetMinutes > 0) {
      hour_label += `:${this.offsetMinutes}`;
    }

    let am_pm_label;
    if (hour === 12) {
      am_pm_label = "PM";
    } else if (hour === 0) {
      am_pm_label = "AM";
    } else if (hour > 12) {
      am_pm_label = "PM";
    } else {
      am_pm_label = "AM";
    }

    return [hour_label, am_pm_label];
  }

  getValueDate(hour: number, slot: number): Date {
    let date = this._adapter.createDatetime(
      this._adapter.getYear(this.daySelected),
      this._adapter.getMonth(this.daySelected),
      this._adapter.getDate(this.daySelected),
      hour,
      slot * this.reservationDuration
    );
    if (this.offsetMinutes > 0) {
      date = this._adapter.addCalendarMinutes(date, this.offsetMinutes);
    }
    return date;
  }

  getValue(hour: number, slot: number): string {
    const date = this.getValueDate(hour, slot);

    /*const tzoffset = date.getTimezoneOffset();

    if(tzoffset > 0 && tzoffset / 60) {

    }*/

    //const tzoffset = date.getTimezoneOffset() * 60000;
    //const localISOTime = (new Date(date.getTime() - tzoffset));
    return this._adapter.toIso8601(date) + ":00Z";
  }

  isBusy(hour: number, slot: number) {
    const date = this.getValueDate(hour, slot);
    if (this._adapter.compareDatetime(new Date(), date) > 0) {
      return true;
    }
    return this.busySlots.has(this._adapter.toIso8601(date) + ":00Z");
  }

  onChanged(event) {}
}
