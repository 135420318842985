import {Component, Input } from '@angular/core';

@Component({
  selector: 'app-slider-picker',
  templateUrl: './slider-picker.component.html',
  styleUrls: ['./slider-picker.component.scss']
})
export class SliderPickerComponent {
  @Input() tooltip: string | null;
  @Input() label: string;
  @Input() control: any;
  @Input() min = 0;
  @Input() max = 100;
  @Input() step = 1;
}
