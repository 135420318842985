import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hub-reports',
  templateUrl: './hub-reports.component.html',
})
export class HubReportsComponent {
  @Input() handler = null;
  constructor(private router: Router) {}

  gotoReports(): void {
    this.router.navigate([this.handler, 'reports']);
  }
}
