import { Component, Input, Output, EventEmitter} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { YesNoDialogComponent } from '../yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'app-event-general-info',
  templateUrl: './event-general-info.component.html',
  styleUrls: ['./event-general-info.component.scss']
})
export class EventGeneralInfoComponent {

  @Input() eventRequest: any;
  @Input() activity: any;
  @Input() statusOptions: string[];
  @Input() status: UntypedFormControl;
  @Output() statusChanged: EventEmitter<any> = new EventEmitter();
  
  constructor(
    private dialog: MatDialog,
  ) {}

  sendRequest() {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      minWidth: '240px',
      data: {
        title: 'messages.order_status_change',
      }
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.statusChanged.emit();
      } else {
        this.status.setValue(this.eventRequest.status);
      }
    });
  }

}
