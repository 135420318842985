import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { AppSettings } from "../../../../app.settings";
import { HttpService } from "../../../../service/http.service";
import { AuthService } from "../../../../auth/auth.serviceV2";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "../../../../../environments/environment";
import { MenuOrderDishesListComponent } from "../../../../components/menu-order-dishes-list/menu-order-dishes-list.component";
import { PaymentType } from "../../../../models/payment-type-enum";

@Component({
  selector: "app-menu-order-details-client-view",
  templateUrl: "./menu-order-details-client-view.component.html",
  styleUrls: ["./menu-order-details-client-view.component.scss"],
})
export class MenuOrderDetailsClientViewComponent implements OnInit {
  @ViewChild("menuOrderDishesList")
  menuOrderDishesListRef: MenuOrderDishesListComponent;
  @Input() order: any;
  @Output() updated: EventEmitter<any> = new EventEmitter();
  settings = null;
  form: UntypedFormGroup;
  activity = null;
  user;
  private readonly apiUrl = environment.apiUrl;

  constructor(
    private httpService: HttpService,
    private appSettings: AppSettings,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private authService: AuthService
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.settings.loadingSpinner = true;
    this.user = this.authService.getUserProfile();
    this.retrieve().then(() => {
      this.createForm().then();
      this.settings.loadingSpinner = false;
    });
  }

  get status() {
    return this.form.get("status") as UntypedFormControl;
  }

  async sendRequest() {
    const url = `${this.apiUrl}/activity-menu/v1/menu-request-from-client`;
    this.settings.loadingSpinner = true;
    const rawValues = this.form.getRawValue();
    this.httpService.doPut(url, rawValues).subscribe(
      (response) => {
        this.order = response;
        this.updated.emit(this.order);
        this.settings.loadingSpinner = false;
        this.showSnackBar("Operation successful ", "OK", 2000);
      },
      (error) => {
        this.form.get("status").setValue(this.order.status);
        this.settings.loadingSpinner = false;
        console.log("Error sending request: ", error);
        this.showSnackBar("Error Processing Your Request", "OK", 2000);
      }
    );
  }

  getAvailableStatus(currentStatus: string): string[] {
    switch (currentStatus) {
      case "OPEN":
        if(this.order.chosenPaymentType === PaymentType.TINYALL_PAY) return ["OPEN"];
        return ["OPEN", "CANCELED"];
      case "CANCELED":
      case "REJECTED":
      case "RESOLVED":
      case "APPROVED":
        return [currentStatus];
    }
    return [currentStatus];
  }

  private async retrieve(): Promise<any> {
    try {
      const activityURL = `${this.apiUrl}/activity-menu/v1/${this.order.activityId}`;
      const responseActivity = await this.httpService
        .doGet(activityURL)
        .toPromise();
      if (responseActivity) {
        this.activity = responseActivity;
      }
    } catch (error) {
      this.settings.loadingSpinner = false;
      console.log("Error getting activity: ", error);
    }
  }

  private async createForm() {
    this.form = this.fb.group({
      activityId: [this.order.activityId],
      hubId: [this.order.hubId],
      menuRequestId: [this.order.id],
      status: [this.order.status],
      userId: [this.order.clientId],
    });
  }

  private showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }
}
