<div class="request-list-container">
    <h2 *ngIf="loading || (data && data.length > 0)" class="title" translate>questionsDialog.requests</h2>
    <div *ngIf="loading; else noLoading" class="loading" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
        <mat-spinner color="primary" diameter="60"></mat-spinner>
    </div>
    <ng-template #noLoading>
        <div fxLayout="column" fxLayoutGap="20px">
            <mat-card *ngFor="let item of data" class="request-card" [ngClass]="item.status" (click)="openDetails(item)">
                <div class="card-header">
                    <mat-card-subtitle>
                        {{item.createdOn | date:'medium'}}
                    </mat-card-subtitle>
                    <span class="status-container">{{'questionsDialog.'+item.status | translate}}</span>
                </div>
                <div *ngIf="item.totalPrice > 0" fxLayout="row" fxLayoutAlign="space-between center" class="reservation-date">
                    <p><span>{{item.totalPrice | currency:"$"}}</span></p>
                    <app-payment-status-tag [status]="item.paymentStatus"></app-payment-status-tag>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center" class="reservation-date">
                    <span>
                        <mat-icon>calendar_today</mat-icon>
                        <p>
                            {{item.reservationTime | date:'medium'}}
                        </p>
                    </span>
                    <div fxLayout="row" fxLayoutGap="20px" *ngIf="item.status === 'OPEN' && item.chosenPaymentType !== 'TINYALL_PAY'">
                        <button mat-button (click)="canCancelRequest(item, $event)">
                            <span translate>questionsDialog.cancel</span>
                        </button>
                    </div>
                </div>
            </mat-card>
            <mat-card *ngIf="length > pageSize" class="paginator-container">
                <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex" [hidePageSize]="true" (page)="pageEvent($event)">
                </mat-paginator>
            </mat-card>
        </div>
    </ng-template>
</div>