import { UntypedFormGroup } from '@angular/forms';
import { AppSettings } from '../app.settings';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ImageService } from '../service/image.service';
import { environment } from '../../environments/environment';
import QuestionsClientViewUtil from '../util/questionsClientViewUtil';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';
import { PaymentStatus } from '../models/payment-status-enum';
import { delay } from '../util';

export abstract class BaseClientViewComponent {
  readonly apiUrl = environment.apiUrl;
  readonly noImage = '/assets/img/app/no-image.png';
  hubId = null;
  categoryId = null;
  handler = null;
  activityId = null;
  images = [];
  user = null;
  form: UntypedFormGroup;
  entity: any = null;
  locked = false;
  notFound = false;
  protected settings;
  hub = null;

  protected constructor(
    protected appSettings: AppSettings,
    protected snackBar: MatSnackBar,
    protected imageService: ImageService,
    protected router: Router,
    protected location: Location,
    protected translate: TranslateService
  ) {
    this.settings = this.appSettings.settings;
  }

  back() {
    if (
      !this.locked &&
      this.entity &&
      this.form &&
      this.form.get('questionAnswerMap') &&
      this.entity.questions
    ) {
      QuestionsClientViewUtil.cleanImages(
        this.entity.questions,
        this.form.get('questionAnswerMap') as UntypedFormGroup,
        this.imageService
      );
    }
    if (
      this.handler === environment.adminHandlerEn ||
      this.handler === environment.adminHandlerEs
    ) {
      this.location.back();
      return;
    }
    let url = `/${this.handler}/client-view?hubId=${this.hubId}`;
    if (
      (this.entity && this.entity.categoryId) ||
      (!this.entity && this.categoryId)
    ) {
      const localCategoryId =
        this.entity && this.entity.categoryId
          ? this.entity.categoryId
          : this.categoryId;
      url = `/${this.handler}/category/client-view?hubId=${this.hubId}&categoryId=${localCategoryId}`;
    }
    this.router.navigateByUrl(url).then();
  }

  showSnackBar(message, action, duration, panelClass = '') {
    this.snackBar.open(message, action, {
      duration: duration,
      panelClass,
    });
  }

  async uploadImage(image: File, form: UntypedFormGroup, field: string) {
    this.settings.loadingSpinner = true;
    try {
      const url = await this.imageService.uploadImage(image);
      this.settings.loadingSpinner = false;
      const payload = {};
      payload[field] = url;
      form.patchValue(payload);
      form.controls[field].markAsDirty();
    } catch (error) {
      this.settings.loadingSpinner = false;
      this.showSnackBar('Error Processing Your Request', 'OK', 2000);
      console.log('image error: ', error);
    }
  }

  deleteImage(image: File) {
    this.imageService.deleteImage(image).subscribe(
      (result) => {},
      (error) => {
        console.log('error deleting image: ', error);
      }
    );
  }

  async onSelectedImage(file: FileList, form: UntypedFormGroup, field: string) {
    console.log('onSelectedImage field: ' + field);
    const image: File = file.item(0);
    if (form.get(field).value) {
      this.deleteImage(form.get(field).value);
    }
    await this.uploadImage(image, form, field);
  }

  async showPaymentResult(status: string) {
    if (!status) return;
    // Avoid trasnlation error
    await delay(1500);
    switch (status) {
      case PaymentStatus.VERIFIED_APPROVED:
        Swal.fire({
          titleText: this.translate.instant('titles.payment_approved'),
          text: this.translate.instant('messages.payment_approved'),
          icon: 'success',
          confirmButtonColor: '#5D6293',
        });
        break;
      case PaymentStatus.VERIFIED_DENIED:
        Swal.fire({
          titleText: this.translate.instant('titles.payment_declined'),
          text: this.translate.instant('messages.payment_declined'),
          icon: 'error',
          confirmButtonColor: '#5D6293',
        });
        break;
      case PaymentStatus.CANCELED:
        Swal.fire({
          titleText: this.translate.instant('titles.payment_canceled'),
          text: this.translate.instant('messages.payment_canceled'),
          icon: 'error',
          confirmButtonColor: '#5D6293',
        });
        break;
    }

    this.router.navigate([], {
      queryParams: {
        status: null,
      },
      queryParamsHandling: 'merge',
    });
  }
}
