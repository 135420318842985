import { UntypedFormGroup } from '@angular/forms';
import {
  Component,
  Input,
  ViewChild,
  ViewEncapsulation,
  HostListener,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppSettings } from '../../app.settings';
import { ImageService } from '../../service/image.service';
import { Settings } from '../../app.settings.model';
import { MatDialog } from '@angular/material/dialog';
import { YoutubeUrlDialogComponent } from '../youtube-url-dialog/youtube-url-dialog.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-swiper-management',
  templateUrl: './swiper-management.component.html',
  styleUrls: ['./swiper-management.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SwiperManagementComponent implements OnInit {
  @Input() images: string[];
  @Input() form: UntypedFormGroup;
  @Input() allowVideo: boolean;
  @Input() max: number = 0;
  @Output() changed = new EventEmitter<string[]>();
  @Input() square: boolean = false;

  @ViewChild('swiper') swiper: any;

  readonly noImage = '/assets/img/app/no-image.png';
  readonly maxImageSize = environment.maxImageSizeInMB;

  settings: Settings;
  videoHeight = '100%';
  videoWidth = '100%';
  videoWidthNum = 0;
  youtubeRegExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
  maxSizeError = false;

  constructor(
    private appSettings: AppSettings,
    protected imageService: ImageService,
    protected snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.calculateVideoSize(window.innerWidth);
  }

  addImage() {
    if (this.max > 0 && this.images.length >= this.max) return;
    const size = this.images.length;
    if (this.images[size - 1] !== '') {
      this.images.push('');
      setTimeout(() => {
        this.swiper.directiveRef.instance.slideTo(this.images.length);
      }, 100);
    } else if (!this.swiper.directiveRef.instance.isEnd) {
      this.swiper.directiveRef.instance.slideTo(this.images.length);
    }
  }

  removeImage() {
    const activeIndex = this.swiper.directiveRef.instance.activeIndex;
    if (!this.images[activeIndex]) {
      this.form.markAsDirty();
      this.images.splice(activeIndex, 1);
      return;
    }

    if (this.validateYoutubeURL(this.images[activeIndex])) {
      this.form.markAsDirty();
      this.images.splice(activeIndex, 1);
      if (this.images.length === 0) {
        this.images.push('');
      }
      return;
    }

    this.settings.loadingSpinner = true;
    this.imageService.deleteImage(this.images[activeIndex]).subscribe(
      (result) => {
        this.form.markAsDirty();
        this.images.splice(activeIndex, 1);
        if (this.images.length === 0) {
          this.images.push('');
        }
        this.changed.emit(this.images);
        this.settings.loadingSpinner = false;
      },
      (error) => {
        this.settings.loadingSpinner = false;
        this.showSnackBar('Error Processing Your Request', 'OK', 2000);
        console.error('Image error: ', error);
      }
    );
  }

  async saveImage(image: FileList, index) {
    this.settings.loadingSpinner = true;

    // don't delete image on update to avoid error if the chanes are not save.
    if (this.images[index] && false) {
      const imageURL = this.images[index];
      this.imageService.deleteImage(imageURL).subscribe(
        (result) => {
          // nothing to do
        },
        (error) => {
          this.settings.loadingSpinner = false;
          this.showSnackBar('Error Processing Your Request', 'OK', 2000);
          console.error('Image error: ', error);
        }
      );
    }

    const fileSize = image.item(0).size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb > this.maxImageSize) {
      this.maxSizeError = true;
      this.images[index] = undefined;
      this.settings.loadingSpinner = false;
      return;
    } else {
      this.maxSizeError = false;
    }

    try {
      const url = await this.imageService.uploadImage(image.item(0));
      this.form.markAsDirty();
      this.settings.loadingSpinner = false;
      this.images[index] = url;
      this.changed.emit(this.images);
    } catch (error) {
      this.settings.loadingSpinner = false;
      this.showSnackBar('Error Processing Your Request', 'OK', 2000);
      console.error('Image error: ', error);
    }
  }

  addVideo(index: number) {
    const URL = this.images[index];
    const isValidYoutubeUrl = this.validateYoutubeURL(URL);

    const dialogRef = this.dialog.open(YoutubeUrlDialogComponent, {
      minWidth: '300px',
      maxWidth: '500px',
      width: '100%',
      data: {
        url: isValidYoutubeUrl ? URL : '',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.settings.loadingSpinner = true;
        if (URL && !isValidYoutubeUrl) {
          this.imageService.deleteImage(URL).subscribe(
            (result) => {
              // nothing to do
            },
            (error) => {
              this.settings.loadingSpinner = false;
              this.showSnackBar('Error Processing Your Request', 'OK', 2000);
              console.error('Image error: ', error);
            }
          );
        }

        this.images[index] = result;
        this.changed.emit(this.images);
        this.settings.loadingSpinner = false;
        this.form.markAsDirty();
      }
    });
  }

  showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  validateYoutubeURL(url: string) {
    if (url) {
      const match = url.match(this.youtubeRegExp);
      if (match && match[2].length === 11) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calculateVideoSize(event.target.innerWidth);
  }

  calculateVideoSize(width: number) {
    const realWidth = Math.min(width - 32, 584);
    this.videoHeight = `${realWidth * 0.5635}px`;
    this.videoWidthNum = realWidth;
  }

  getYoutubeUrl(url) {
    const match = url.match(this.youtubeRegExp);
    return match[2];
  }

  getYoutubeImage(url) {
    const match = url.match(this.youtubeRegExp);
    return `https://img.youtube.com/vi/${match[2]}/0.jpg`;
  }
}
