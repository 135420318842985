<div fxLayout="column wrap" class="page-container">
    <app-product-search-bar id="app-top-search-bar" 
        [items]="searchCriteria"
        (onSearhChange)="searchCriteriaChanged($event)">
    </app-product-search-bar>

    <div class="list-container">
        <div *ngIf="loading && !settings.loadingSpinner; else noLoading" class="loading" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
            <mat-spinner color="primary" diameter="60"></mat-spinner>
        </div>
        <ng-template #noLoading>
            <div fxLayout="column" fxLayoutGap="20px">

                <app-product-item-management #newItemForm
                    class="newItemForm"
                    [hidden]="selectedForm.get('id').value !== newItemTemplate.id"
                    [item]="newItemTemplate" 
                    [selected]="selectedForm.get('id').value === newItemTemplate.id"
                    [loading]="loadingSave"
                    [selectedForm]="selectedForm"
                    [images]="images"
                    (onSave)="save()"
                    (onDelete)="canDelete()">
                </app-product-item-management>

                <ng-container *ngIf="(items && items.length > 0) || loading; else noItems">
                    <app-product-item-management *ngFor="let item of items" 
                        [item]="item" 
                        [selected]="selectedForm.get('id').value === item.id"
                        [loading]="loadingSave"
                        [selectedForm]="selectedForm"
                        [images]="images"
                        (onSave)="save()"
                        (onDelete)="canDelete()">
                    </app-product-item-management>

                    <mat-card *ngIf="length > pageSize" class="paginator-container">
                        <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex" [hidePageSize]="true" (page)="pageEvent($event)">
                        </mat-paginator>
                    </mat-card>
                </ng-container>

                <ng-template #noItems>
                    <div class="no-items-container" *ngIf="selectedForm.get('id').value !== newItemTemplate.id">
                        <mat-icon>local_offer</mat-icon>
                        <p translate>
                            productList.no_products_management_text
                        </p>
                    </div>
                </ng-template>

            </div>
        </ng-template>
    </div>

    <div *ngIf="selectedForm.get('id').value !== newItemTemplate.id" fxLayout="row" fxLayoutAlign="end center">
        <button [disabled]="loadingSave || loading" (click)="addNewItem()" class="speed-dial-action-button"  mat-fab color="accent">
            <span translate>productList.add_product</span>&nbsp;<mat-icon>local_offer</mat-icon>
        </button>
    </div>
    
</div>
