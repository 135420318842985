<div fxLayout="row">
    <h3 class="title" translate>titles.dishes</h3>
</div>
<div fxLayout="column" fxLayoutGap="20px">
    <form *ngIf="dishesForm.length > 0; else noItems" 
        class="section-boundary"
        cdkDropList 
        (cdkDropListDropped)="drop($event)"
        novalidate 
        [formGroup]="form" 
        fxLayout="column" 
        fxLayoutAlign="center">
        <mat-accordion>
            <div class="flex-p" 
                formArrayName="dishes" 
                *ngFor="let dishItem of dishesFormControls; let i = index;" 
                cdkDragLockAxis="y" 
                cdkDrag>
                <mat-card class="item-container" [formGroupName]="i" [ngClass]="{'error': dishesForm.controls[i].invalid}">
                    <div class="hanlde-container">
                        <mat-icon class="handle-move" cdkDragHandle>drag_handle</mat-icon>
                    </div>
                    <mat-card-content class="dish-item">
                        <app-dish-item-management 
                            (onRemove)="removeDish(i)"
                            [sectionName]="sectionIndex"
                            [index]="i"
                            [itemForm]="dishItem">
                        </app-dish-item-management>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-accordion>
    </form>
    <ng-template #noItems>
        <div class="no-items-container">
            <mat-icon>local_dining</mat-icon>
            <p translate>
                messages.no_dishes_text
            </p>
        </div>
    </ng-template>
</div>
