<div fxLayout="column" fxLayoutAlign="center center">

    <app-code-scanner *ngIf="scannerEnabled" [canFlip]="true" [canTorch]="true" (scanSuccessHandler)="scanSuccessHandler($event)"></app-code-scanner>

    <ngx-kjua *ngIf="qrCode" [text]="qrCode" fill="#000" render="image" [size]="550" [crisp]="true"></ngx-kjua>
    <img *ngIf="!scannerEnabled && !qrCode && imageURL" class="qr-image" [src]="imageURL" alt="qr code">

    <app-warning-message *ngIf="!scannerEnabled && qrCode && !validURL" 
        message="messages.no_qr_url">
    </app-warning-message>

    <app-warning-message *ngIf="!scannerEnabled && qrCode && invalidMenuCode" 
        message="messages.no_valid_menu_qr_code">
    </app-warning-message>

    <a class="qr-link" *ngIf="!scannerEnabled && validURL && !invalidMenuCode" [href]="qrCode" target="_blank">
        {{qrCode}}
    </a>

    <p class="qr-text" *ngIf="!scannerEnabled && qrCode && (!validURL || invalidMenuCode)">
        {{qrCode}}
    </p>

    <p class="qr-text" *ngIf="scannerEnabled || (!scannerEnabled && !qrCode && imageURL)" translate>messages.no_qr_detected</p>

    <input hidden="true" type="file" accept="image/*" (change)="onFileChange($event)" #imagesInput accept="image/*">
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-arround space-arround">
        <button class="scan-button" (click)="enableScanner()" *ngIf="!scannerEnabled" mat-fab color="accent">
            <span translate>buttons.scan</span>&nbsp;<mat-icon>qr_code_scanner</mat-icon>
        </button>

        <button class="scan-button" (click)="imagesInput.click(); $event.preventDefault();" mat-fab color="accent">
            <span translate>buttons.pick_an_image</span>&nbsp;<mat-icon>photo_library</mat-icon>
        </button>
    </div>
</div>