import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import CURRENCY_SYMBOLS from '../../util/currency-symbols';
import { HubPromoCodeEntity } from '../../models/hub-promo-code';

@Component({
  selector: 'app-menu-summary',
  templateUrl: './menu-summary.component.html',
  styleUrls: ['./menu-summary.component.scss']
})
export class MenuSummaryComponent {
  @Input() itemLabel = 'productList.products';
  @Input() items = 0;
  @Input() quantity = 0;
  @Input() tip = 0;
  @Input() currency: string = 'DOP';
  @Input() form: UntypedFormGroup;
  @Input() additionalCharges = {};
  @Input() promoCodeEntity: HubPromoCodeEntity;
  @Input() tipPercentage = 0;

  readonly currencySymbols = CURRENCY_SYMBOLS;
  readonly tips = [0, 10, 15, 20];

  uncheckRadio(value) {
    if (value === this.form.get('tip').value) {
      setTimeout(() => {
        this.form.reset();
      }, 300);
    }
  }

  getAdditionalChargesTotal() {
    return Object.values<number>(this.additionalCharges || {}).reduce(
      (prev: number, current: number) => prev + current,
      0
    );
  }

  getTotalNoTipsNoDiscount() {
    return Number(this.items) + this.getAdditionalChargesTotal();
  }

  getPromoDiscount() {
    if (!this.promoCodeEntity || !this.promoCodeEntity.discount || this.promoCodeEntity.discount <= 0) return 0;
    const discount = this.getTotalNoTipsNoDiscount() * (this.promoCodeEntity.discount / 100);
    return this.promoCodeEntity.topDiscount ? Math.min(discount, this.promoCodeEntity.topDiscount) : discount;
  }

  getTotalNoTips() {
    return Number(this.items) + this.getAdditionalChargesTotal() - this.getPromoDiscount();
  }

  getTips() {
    let selectedTip = 0;
    const additionalAndItems = this.getTotalNoTips();
    if(this.form) {
      selectedTip = (additionalAndItems * this.form.get('tip').value)/100 || 0;
    } else {
      selectedTip = (additionalAndItems * this.tipPercentage)/100 || 0;
    }
    return selectedTip;
  }

  getTotal() {
    return this.getTotalNoTips() + this.getTips();
  }


}
