<div class="flex flex-row items-center justify-start mb-5 gap-1">
  <h3 class="text-primary font-semibold" translate>
    hubWorkflow.special_promo_step.head
  </h3>
</div>
<div class="flex flex-col justify-center items-center">
  <div class="flex flex-col justify-center items-center px-10">
    <h1 class="text-accent font-bold text-2xl mb-7 text-center" translate>
      hubWorkflow.special_promo_step.title
    </h1>

    <h2 class="text-accent font-bold mb-7 text-center" translate>
      hubWorkflow.special_promo_step.subtitle
    </h2>

    <div
      class="text-white bg-primary rounded-full text-xl font-bold mb-9 py-2 px-6 text-center"
      translate
    >
      hubWorkflow.special_promo_step.center_text
    </div>
  </div>

  <div>
    <h3 class="text-accent text-left mb-4" translate>
      hubWorkflow.special_promo_step.action_title
    </h3>

    <ul class="list-disc pl-5">
      <li class="text-accent" translate>
        hubWorkflow.special_promo_step.action_1
      </li>
      <li class="text-accent" translate>
        hubWorkflow.special_promo_step.action_2
      </li>
      <li class="text-accent" translate>
        hubWorkflow.special_promo_step.action_3
      </li>
      <li class="text-accent" translate>
        hubWorkflow.special_promo_step.action_4
      </li>
    </ul>
  </div>

  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    class="actions"
    mat-dialog-actions
  >
    <button
      class="border border-white bg-accent text-white px-5 py-0.5 rounded my-3"
      (click)="ok()"
    >
      <span translate>buttons.ok</span>
    </button>
  </div>
</div>
