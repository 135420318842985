import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { AppSettings } from '../../../../app.settings';
import { ImageService } from '../../../../service/image.service';
import { Settings } from '../../../../app.settings.model';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '../../../../service/http.service';
import { MenuOrderListClientViewDialogComponent } from '../menu-order-list-client-view-dialog/menu-order-list-client-view-dialog.component';
import { MenuOrderDetailsClientViewDialogComponent } from '../menu-order-details-client-view-dialog/menu-order-details-client-view-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CancelRequestDialogComponent } from '../../../../components/cancel-request-dialog/cancel-request-dialog.component';
import CURRENCY_SYMBOLS from '../../../../util/currency-symbols';
import { firstValueFrom } from 'rxjs';
import {
  MenuRequestEntity,
  UpdateMenuRequestFromClientCommand,
} from '../../../../models/activity-menu';
import { MenuRequestStatus, PageEntity } from '../../../../models/types';

@Component({
  selector: 'app-menu-order-list-client-view',
  templateUrl: './menu-order-list-client-view.component.html',
  styleUrls: ['./menu-order-list-client-view.component.scss'],
})
export class MenuOrderListClientViewComponent implements OnInit {
  @Input() title: boolean;
  @Input() footer: boolean;
  @Input() activityId: string;
  @Input() hubId: string;
  @Input() user: any;
  @Input() activityType: string;
  @Input() open: boolean;
  @Input() size: number;

  @Output() hasOpen: EventEmitter<any> = new EventEmitter();
  @Output() hasOrder: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  private readonly apiUrl = environment.apiUrl;
  readonly currencySymbols = CURRENCY_SYMBOLS;

  data: MenuRequestEntity[];
  pageIndex = 0;
  length = 0;
  settings: Settings;
  loading = false;
  anyOpen = false;
  anyOrder = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  get pageSize() {
    return Number(this.size);
  }

  constructor(
    private translate: TranslateService,
    private appSettings: AppSettings,
    protected imageService: ImageService,
    protected snackBar: MatSnackBar,
    private httpService: HttpService,
    public dialog: MatDialog
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.search();
  }

  canCancelRequest(request: any, event: MouseEvent): void {
    event.stopPropagation();
    if (request.status !== 'OPEN' && request.status !== 'PENDING_PAYMENT') {
      return;
    }
    const dialogRef = this.dialog.open(CancelRequestDialogComponent, {
      minWidth: '240px',
      data: {
        title: 'titles.order',
        content: 'messages.want_cancel_order',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cancelRequest(request);
      }
    });
  }

  async cancelRequest(request: any) {
    this.loading = false;
    const url = `${this.apiUrl}/activity-menu/v1/menu-request-from-client`;
    try {
      const requestBody: UpdateMenuRequestFromClientCommand = {
        activityId: this.activityId,
        hubId: this.hubId,
        menuRequestId: request.id,
        status: MenuRequestStatus.CANCELED,
        userId: this.user.userId,
      };
      const response: MenuRequestEntity = await firstValueFrom(
        this.httpService.doPut(url, requestBody)
      );
      this.cancel.emit();
      this.showSnackBar(
        this.translate.instant('questionsDialog.request_canceled_msg'),
        'OK',
        2000
      );
      this.search();
    } catch (error) {
      this.loading = false;
      console.log('Error: ', error);
    }
  }

  payRequest(request: any, event: MouseEvent) {
    event.stopPropagation();
    // TODO: PAYMENT
  }

  async search() {
    try {
      const orderUrl = 'activity-menu/v1/menu-request-from-client';
      let url = `${this.apiUrl}/${orderUrl}/${this.activityId}`;
      url += `?page=${this.pageIndex}&size=${this.pageSize}&sort=createdOn,desc`;
      this.loading = true;
      const response: PageEntity<MenuRequestEntity> = await firstValueFrom(
        this.httpService.doGet(url)
      );
      if (response && response.content.length > 0) {
        if (this.open) {
          this.data = response.content.filter(
            (order) => order.status === 'OPEN'
          );
          if (this.data.length === 0) {
            this.data = [response.content[0]];
          }
          this.length = this.data.length;
        } else {
          this.data = response.content;
          this.length = response.totalElements;
        }

        if (this.open && this.length > 0) {
          this.hasOpen.emit(true);
          this.anyOpen = true;
        } else {
          this.hasOpen.emit(false);
          this.anyOpen = false;
        }

        if (this.length === 0) {
          this.hasOrder.emit(false);
          this.anyOrder = false;
        } else {
          this.hasOrder.emit(true);
          this.anyOrder = true;
        }
      } else {
        this.data = [];
        this.length = 0;
        this.hasOrder.emit(false);
        this.anyOrder = false;
        this.hasOpen.emit(false);
        this.anyOpen = false;
      }
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.loading = false;
      this.data = [];
      this.length = 0;
      this.hasOrder.emit(false);
      this.anyOrder = false;
      this.hasOpen.emit(false);
      this.anyOpen = false;
    }

    if (this.open && this.length === 0) {
      this.validateHasOrder();
    }
  }

  async validateHasOrder() {
    let url = `${this.apiUrl}/activity-menu/v1/menu-request-from-client/${this.activityId}`;
    url += `?page=${this.pageIndex}&size=${this.pageSize}&sort=createdOn,desc`;
    try {
      const response: PageEntity<MenuRequestEntity> = await firstValueFrom(
        this.httpService.doGet(url)
      );
      if (response && response.content && response.content.length > 0) {
        this.hasOrder.emit(true);
        this.anyOrder = true;
      } else {
        this.hasOrder.emit(false);
        this.anyOrder = false;
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  pageEvent(pageChangedEvent: any) {
    this.pageIndex = pageChangedEvent.pageIndex;
    this.search();
  }

  showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(MenuOrderListClientViewDialogComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'menu-order-list-client-view-dialog',
      data: {
        activityId: this.activityId,
        activityType: this.activityType,
        hubId: this.hubId,
        user: this.user,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.search();
      }
    });
  }

  openDetails(order): void {
    const dialogRef = this.dialog.open(
      MenuOrderDetailsClientViewDialogComponent,
      {
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw',
        maxHeight: '100vh',
        panelClass: 'menu-order-details-client-view-dialog',
        data: {
          item: order,
        },
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.search();
      }
    });
  }

  getTotal(item) {
    return Number(item.totalPrice);
  }
}
