import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-sent-request-dialog',
  templateUrl: './sent-request-dialog.component.html',
  styleUrls: ['./sent-request-dialog.component.scss']
})
export class SentRequestDialogComponent { 

    parentData: any; 
    constructor(
      public dialogRef: MatDialogRef<SentRequestDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.parentData = data; 
    }
  
    close(): void {
      this.dialogRef.close();
    }
  
  }