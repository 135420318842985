import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

const createMenuFromResponse = (section: any, fb: UntypedFormBuilder): UntypedFormGroup => {
  const { sectionName, sectionDescription, dishes } = section;

  const dishesFormArray = fb.array([]);
  dishes.forEach(dish => {
    const { name, description, image, dishOptions, available } = dish;

    const dishOptionsFormArray = fb.array([]);
    dishOptions.forEach(option => {
      dishOptionsFormArray.push(fb.group({
        description: [option.description, [Validators.required, RxwebValidators.unique()]],
        price: [option.price, Validators.required],
        available: [option.available]
      }))
    });

    dishesFormArray.push(
      fb.group({
        name: [name, [Validators.required, RxwebValidators.unique()]],
        description: [description],
        image: [image],
        dishOptions: dishOptionsFormArray,
        available: [available]
      })
    );
  });

  return fb.group({
    sectionName: [sectionName, [Validators.required, RxwebValidators.unique()]],
    sectionDescription: [sectionDescription],
    dishes: dishesFormArray
  });

};

const createSelectedContainer = (questions: UntypedFormArray): Map<number, Map<number, Set<number>>> => {
  const selectedContainer = new Map();
  let index = 0;
  for (const QUESTION of questions.controls) {
    const newMap = new Map();
    const OPTIONS_CONTENT_ARRAY = QUESTION.get('questionGroups') as UntypedFormArray;
    let innerIndex = 0;
    for (const INNER of OPTIONS_CONTENT_ARRAY.controls) {
      newMap.set(innerIndex, new Set());
      innerIndex++;
    }
    selectedContainer.set(index, newMap);
    index++;
  }

  return selectedContainer;
};

/*const cleanContent = (sections: Array<any>): Array<any> => {
  const sectionList = [];
  for (const section of sections) {
    const SECTION = Object.assign({}, section);
    

    if(['MULTI_CHOICE', 'SINGLE_CHOICE'].includes(QUESTION.type)){
      const OPTIONS = [];
      for (const option of QUESTION.content) {
        OPTIONS.push(option.name);
      }
      QUESTION.choices = OPTIONS;
      
    } else if(QUESTION.type === 'GROUP') {
      const CONTENT = {};
      for (let i = 0; i < QUESTION.questionGroups.length; i++) {
        CONTENT[QUESTION.content[i].name] = cleanContent(QUESTION.questionGroups[i]);
      }
      QUESTION.groups = CONTENT;
    } else if(['MULTI_CHOICE_PRICE', 'SINGLE_CHOICE_PRICE'].includes(QUESTION.type)){
      const OPTIONS = {};
      for (const option of QUESTION.content) {
        OPTIONS[option.name] = option.price || '0.00';
      }
      QUESTION.values =  OPTIONS;
    } else {
      delete QUESTION.content;
    }

    delete QUESTION.questionGroups;
    questionList.push(QUESTION);
  }
  return questionList;
};*/

export default {
    createMenuFromResponse,
    createSelectedContainer,
    //cleanContent
};
