import { Component, OnInit, ViewChild } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { HttpService } from '../../../service/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { ImageService } from '../../../service/image.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseClientViewComponent } from '../../baseclientview.component';
import { CommentsPreviewComponent } from '../../../components/comments-preview/comments-preview.component';
import { EntityType } from '../../../models/entity-type-enum';
import { AuthService } from '../../../auth/auth.serviceV2';
import { Meta, Title } from '@angular/platform-browser';
import {
  setActivityPageHead,
  setDefaultPageHead,
} from '../../../util/seo-utils';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { ActivityInfoEntity } from '../../../models/activity-info';
import { HubEntity } from '../../../models/hub';
import { MatureContentService } from '../../../service/mature-content.service';

@Component({
  selector: 'app-actinfo-client-view',
  templateUrl: './activity-info-client-view.component.html',
  styleUrls: ['./activity-info-client-view.component.scss'],
})
export class ActivityInfoClientViewComponent
  extends BaseClientViewComponent
  implements OnInit
{
  type = EntityType.ACTIVITY_INFO;
  reported = false;
  entity: ActivityInfoEntity;

  @ViewChild('commentsPreview') commentsPreview: CommentsPreviewComponent;

  constructor(
    private authService: AuthService,
    private httpService: HttpService,
    public appSettings: AppSettings,
    public router: Router,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    protected imageService: ImageService,
    protected translate: TranslateService,
    protected location: Location,
    private matureContentService: MatureContentService,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    super(appSettings, snackBar, imageService, router, location, translate);
  }

  async ngOnInit() {
    this.user = this.authService.getUserProfile();
    this.hubId = this.route.snapshot.queryParams['hubId'];
    this.activityId = this.route.snapshot.queryParams['activityId'];
    this.categoryId = this.route.snapshot.queryParams['categoryId'];
    this.handler = this.route.snapshot.paramMap.get('handler');

    this.settings.loadingSpinner = true;
    await this.retrieveHub(this.hubId);
    this.matureContentService.check(this.hub.adultContent);
    this.initPage();
  }

  private async retrieveHub(hubId: string) {
    try {
      const hubUrl = `${this.apiUrl}/hubs/v1/${hubId}`;
      const hubResponse: HubEntity = await firstValueFrom(
        this.httpService.doGet(hubUrl)
      );
      if (!hubResponse) return;
      this.hub = hubResponse;
    } catch (error) {
      console.log('Error getting activity: ', error);
    }
  }

  private async initPage() {
    const url = `${this.apiUrl}/activity-info/v1/${this.activityId}`;
    try {
      const response: ActivityInfoEntity = await firstValueFrom(this.httpService.doGet(url));
      setActivityPageHead(
        this.titleService,
        this.metaTagService,
        response,
        null
      );
      this.entity = response;
    } catch(error) {
      setDefaultPageHead(this.titleService, this.metaTagService, true);
      console.log('Error getting activity: ', error);
      if (error.status === 404) {
        this.notFound = true;
      }
    } finally {
      this.settings.loadingSpinner = false;
    }
  }
}
