import { Component, Input } from '@angular/core';
import { speedDialFabAnimations } from './category-speed-dial-fab.animations';
import { RouteConstants } from '../../../../app.constants';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-category-speed-dial-fab',
    templateUrl: './category-speed-dial-fab.component.html',
    styleUrls: ['./category-speed-dial-fab.component.scss'],
    animations: speedDialFabAnimations
  })
  export class CategorySpeedDialFabComponent {
  
    @Input() hubId;
    @Input() categoryId;
    @Input() handler;
    @Input() canSort: boolean;

    fabButtons;
    buttons = [];
    fabTogglerState = 'inactive';
  
    constructor(private translate: TranslateService, public router: Router) { 
        this.fabButtons = [
            {
              label: this.translate.instant('tinyAll.activity'),
              icon: 'widgets',
              onClick: () => { this.createActivity(); },
            },
            {
              label: this.translate.instant('buttons.edit'),
              icon: 'edit',
              onClick: () => { this.editCategory(); },
            },
            {
              label: this.translate.instant('buttons.sort'),
              icon: 'sort',
              onClick: () => { this.sort(); },
            }
          ];
    }
  
    showItems() {
      this.fabTogglerState = 'active';
      this.buttons = this.fabButtons;
    }
  
    hideItems() {
      this.fabTogglerState = 'inactive';
      this.buttons = [];
    }
  
    onToggleFab() {
      this.buttons.length ? this.hideItems() : this.showItems();
    }

    editCategory(): void {
        const start = this.handler ?  `${this.handler}/category` : `/${RouteConstants.category}`;
        let url = `/${start}/manage?hubId=${this.hubId}&categoryId=${this.categoryId}`;
        if (this.handler) {
          url += `&handler=${this.handler}`;
        }
        this.router.navigateByUrl(url);
    }

    createActivity(): void {
      const start = this.handler ?  `${this.handler}/activity` : `/${RouteConstants.activity}`;
      let url = `/${start}/manage?hubId=${this.hubId}&categoryId=${this.categoryId}`;
      if (this.handler) {
        url += `&handler=${this.handler}`;
      }
      this.router.navigateByUrl(url);
    }

    sort() {
      const start = this.handler ?  `${this.handler}/category` : `/${RouteConstants.category}`;
      let url = `/${start}/sort?hubId=${this.hubId}&categoryId=${this.categoryId}`;
      if (this.handler) {
        url += `&handler=${this.handler}`;
      }
      this.router.navigateByUrl(url);
    }
  }
