<h1 mat-dialog-title>{{'titles.add_youtube_video' | translate}}</h1>
<div class="content" mat-dialog-content [formGroup]="form">
    <p>{{'messages.add_youtube_video_url' | translate}}</p>
    <mat-form-field>
        <input matInput placeholder="{{'formFields.youtube_url' | translate}}" formControlName="url" autocomplete="off">
        <mat-error *ngIf="form.get('url').hasError('required') || form.get('url').hasError('url') || form.get('url').hasError('pattern')" translate>formErrors.youtube_url_invalid</mat-error>
    </mat-form-field>
</div>
<div fxLayout="row" fxLayoutAlign="end center" class="actions" mat-dialog-actions>
    <button (click)="close()" mat-button><span translate>tinyAll.cancel</span></button>
    <button [disabled]="!form || !form.dirty || form.invalid" (click)="addVideo()" color="accent" mat-raised-button><span translate>tinyAll.ok</span></button>
</div>