<mat-expansion-panel *ngIf="!noCard; else noCardContent">
  <mat-expansion-panel-header>
    <mat-panel-title translate>subtitles.custom_links</mat-panel-title>
  </mat-expansion-panel-header>

  <div fxLayout="column" fxLayoutAlign="center start">
    <div
      fxLayout="row"
      *ngFor="let link of links; index as i"
      [formGroup]="link"
      class="custom-link"
    >
      <div fxLayout="column" fxFlex="90" fxLayoutAlign="center start">
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'formFields.name' | translate }}"
            formControlName="name"
            autocomplete="off"
          />
          <mat-error *ngIf="link.get('name').hasError('required')" translate
            >formErrors.name_required</mat-error
          >
          <mat-error *ngIf="link.get('name').hasError('unique')" translate
            >formErrors.name_unique</mat-error
          >
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'formFields.url' | translate }}"
            formControlName="value"
            autocomplete="off"
            (focusout)="fixURL(i)"
          />
          <fa-icon matPrefix class="front-icon" [icon]="faLink"></fa-icon>
          <mat-error *ngIf="link.get('value').hasError('required')" translate
            >formErrors.url_required</mat-error
          >
          <mat-error *ngIf="link.get('value').hasError('pattern')" translate
            >formErrors.url_invalid</mat-error
          >
        </mat-form-field>
      </div>
      <div fxLayout="column" fxFlex="10" fxLayoutAlign="center center">
        <button
          class="variant-close"
          type="button"
          (click)="removeLink(i)"
          mat-icon-button
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <button (click)="addLink()" mat-button color="accent">
      <span translate>buttons.add_custom_link</span>
    </button>
  </div>
</mat-expansion-panel>

<ng-template #noCardContent>
  <h2 class="font-semibold text-base text-gray-600 mb-4" translate>subtitles.custom_links</h2>
  <div fxLayout="column" fxLayoutAlign="center start">
    <div
      fxLayout="row"
      *ngFor="let link of links; index as i"
      [formGroup]="link"
      class="custom-link"
    >
      <div fxLayout="column" fxFlex="90" fxLayoutAlign="center start">
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'formFields.name' | translate }}"
            formControlName="name"
            autocomplete="off"
          />
          <mat-error *ngIf="link.get('name').hasError('required')" translate
            >formErrors.name_required</mat-error
          >
          <mat-error *ngIf="link.get('name').hasError('unique')" translate
            >formErrors.name_unique</mat-error
          >
        </mat-form-field>

        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'formFields.url' | translate }}"
            formControlName="value"
            autocomplete="off"
            (focusout)="fixURL(i)"
          />
          <fa-icon matPrefix class="front-icon" [icon]="faLink"></fa-icon>
          <mat-error *ngIf="link.get('value').hasError('required')" translate
            >formErrors.url_required</mat-error
          >
          <mat-error *ngIf="link.get('value').hasError('pattern')" translate
            >formErrors.url_invalid</mat-error
          >
        </mat-form-field>
      </div>
      <div fxLayout="column" fxFlex="10" fxLayoutAlign="center center">
        <button
          class="variant-close"
          type="button"
          (click)="removeLink(i)"
          mat-icon-button
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <button (click)="addLink()" mat-button color="accent">
      <span translate>buttons.add_custom_link</span>
    </button>
  </div>
</ng-template>
