import { Day } from "./day-enum";

export const enum AccountType {
  SAVING_ACCOUNT = "SAVING_ACCOUNT",
  CHECKING_ACCOUNT = "CHECKING_ACCOUNT",
}

export const enum IdType {
  PASSPORT = "PASSPORT",
  NATIONAL_ID = "NATIONAL_ID",
}

export const enum RequestStatus {
  OPEN = "OPEN",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  RESOLVED = "RESOLVED",
  CANCELED = "CANCELED",
  AUTOAPPROVED = "AUTOAPPROVED",
}

export const enum MenuRequestStatus {
  OPEN = "OPEN",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  RESOLVED = "RESOLVED",
  CANCELED = "CANCELED",
  AUTOAPPROVED = "AUTOAPPROVED",
}

export const enum EventRequestStatus {
  OPEN = "OPEN",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  RESOLVED = "RESOLVED",
  CANCELED = "CANCELED",
  AUTOAPPROVED = "AUTOAPPROVED",
}

export const enum ReservationStatus {
  OPEN = "OPEN",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  RESOLVED = "RESOLVED",
  CANCELED = "CANCELED",
}

export const enum ShoppingStatus {
  OPEN = "OPEN",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  RESOLVED = "RESOLVED",
  CANCELED = "CANCELED",
}

export const enum CategoryAccessStatus {
  PENDING = "PENDING",
  GRANTED = "GRANTED",
  REJECTED = "REJECTED",
  CANCELED = "CANCELED",
}

export type ChangeLog = {
  userId: string;
  userName: string;
  status: string;
  eventDate: string;
};

export type StringMap = {
  [key: string]: string;
};

export type NumberMap = {
  [key: string]: number;
};

export type Activity = {
  hubId: string;
  userId: string;
  name: string;
  infoText: string;
  newRequestMessage?: string;
  order: number;
  active: boolean;
  unListed: boolean;
  includeInMenu: boolean;
  clonable: boolean;
  images: string[];
};

export type AddressBean = {
  country: string;
  city: string;
  stateOrProvidence: string;
  address: string;
  address2: string;
  zipCode: string;
  locationHint: string;
};

export type LocationBean = {
  defaultLocation: boolean;
  name: string;
  address: AddressBean;
  location: GeoJsonPoint;
};

export type BankTransactionInfo = {
  bankName: string;
  accountNumber: string;
  accountType: AccountType;
  idType: IdType;
  idNumber: string;
  accountName: string;
};

export type GeoJsonPoint = {
  x: number;
  y: number;
  coordinates: number[];
  type: string;
};

export type LocalTime = {
  hour: number;
  minute: number;
  second: number;
  nano: number;
};

export type IntervalBean = {
  start: string;
  end: string;
};

export type ScheduleEntryBean = {
  dayOfWeek: Day;
  intervals: IntervalBean[];
};

export type ScheduleBean = {
  entries: ScheduleEntryBean[];
  durationInMinutes: number;
};

export interface PriceOption {
  option: string;
  price: number;
}

// Question

interface IQuestion {
  require: boolean;
  hidde: boolean;
  questio: string;
  description: string;
  _class: string;
}
export interface TextQuestion extends IQuestion {}

export interface DateQuestion extends IQuestion {}

export interface DateTimeQuestion extends IQuestion {}

export interface GeoPointQuestion extends IQuestion {}

export interface ImageQuestion extends IQuestion {}

export interface NumberQuestion extends IQuestion {}

export interface TimeQuestion extends IQuestion {}

export interface SingleChoiceQuestion extends IQuestion {
  choices: string[];
}
export interface SingleChoicePriceQuestion extends IQuestion {
  priceOptions: PriceOption[];
}
export interface MultiChoiceQuestion extends IQuestion {
  choices: string[];
}
export interface MultiChoicePriceQuestion extends IQuestion {
  priceOptions: PriceOption[];
}

export interface GroupQuestion extends IQuestion {
  groupQuestionMap: { [key: string]: Question[] };
}

export type Question =
  | DateQuestion
  | DateTimeQuestion
  | GeoPointQuestion
  | GroupQuestion
  | ImageQuestion
  | MultiChoicePriceQuestion
  | MultiChoiceQuestion
  | NumberQuestion
  | SingleChoicePriceQuestion
  | SingleChoiceQuestion
  | TextQuestion
  | TimeQuestion;

// Answer
export type Point = {
  x: number;
  y: number;
};

interface IAnswer {
  _class: string;
}
export interface TextAnswer extends IAnswer {
  hidden: boolean;
  value: string;
}

export interface DateAnswer extends IAnswer {
  value: string;
}

export interface DateTimeAnswer extends IAnswer {
  value: string;
}

export interface GeoPointAnswer extends IAnswer {
  value: Point;
}

export interface ImageAnswer extends IAnswer {
  value: string;
}

export interface NumberAnswer extends IAnswer {
  hidden: boolean;
  value: string;
}

export interface TimeAnswer extends IAnswer {
  value: LocalTime;
}

export interface SingleChoiceAnswer extends IAnswer {
  value: string;
}
export interface SingleChoicePriceAnswer extends IAnswer {
  value: PriceOption;
}
export interface MultiChoiceAnswer extends IAnswer {
  value: string[];
}
export interface MultiChoicePriceAnswer extends IAnswer {
  value: PriceOption[];
}

export interface GroupAnswer extends IAnswer {
  value: string;
  group: string;
  questionAnswerMap: QuestionAnswerMap;
}

export type Answer =
  | DateAnswer
  | DateTimeAnswer
  | GeoPointAnswer
  | GroupAnswer
  | ImageAnswer
  | MultiChoicePriceAnswer
  | MultiChoiceAnswer
  | NumberAnswer
  | SingleChoicePriceAnswer
  | SingleChoiceAnswer
  | TextAnswer
  | TimeAnswer;

export type QuestionAnswerMap = {
  [key: string]: Answer;
};

export type SortObject = {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
};

export type PageableObject = {
  sort: SortObject;
  paged: boolean;
  unpaged: boolean;
  pageNumber: number;
  pageSize: number;
  offset: number;
};

export type PageEntity<T> = {
  totalPages: number;
  totalElements: number;
  sort: SortObject;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: PageableObject;
  size: number;
  empty: boolean;
  content: T[];
};
