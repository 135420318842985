import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {ImageService} from '../../service/image.service';
import {editConfig} from '../../util/editor-config';
import isBase64 from 'is-base64';
import {v4 as uuidv4} from 'uuid';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
    selector: 'app-rich-text-editor',
    templateUrl: './rich-text-editor.component.html',
    styleUrls: ['./rich-text-editor.component.scss']
})
export class RichTextEditorComponent {

    public perfectScrollBarConfig: PerfectScrollbarConfigInterface = {
        wheelPropagation: false,
    };

    @Input() mode: string;
    @Input() text: String;
    @Input() fieldName: string;
    @Input() form: UntypedFormGroup;
    @Input() placeholder: string;

    readonly editConfig: AngularEditorConfig = editConfig;

    constructor(protected imageService: ImageService, protected snackBar: MatSnackBar) {
    }

    static getImagesSrcFromHtmlString(htmlString: string): string[] {
        const container = document.createElement('div');
        container.innerHTML = htmlString;
        return Array.from(container.querySelectorAll('img')).map((img: HTMLImageElement) => img.src);
    }

    static async uploadImages(htmlString: string, savedImages: string[], imageService: ImageService): Promise<string> {
        const container = document.createElement('div');
        container.innerHTML = htmlString;
        const imgTags = Array.from(container.querySelectorAll('img'));
        try {
            const images = imgTags.map((img: HTMLImageElement) => img.src);
            const base64List = images.filter((url: string) => isBase64(url, {allowMime: true}));
            const files: File[] = base64List.map((b64: string) => {
                const arr = b64.split(',');
                const mime = arr[0].match(/:(.*?);/)[1];
                const bstr = atob(arr[1]);
                let n = bstr.length;
                const u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], uuidv4(), {type: mime});
            });

            // Upload new images and replace the base64 src for image url
            const Urls = await Promise.all(files.map((file: File) => imageService.uploadImage(file)));
            const cloudUrls = images.map((url: string) => isBase64(url, {allowMime: true}) ? Urls.shift() : url);
            cloudUrls.forEach((cloudUrl: string, index: number) => {
                imgTags[index].src = cloudUrl;
            });

            // Remove unused images
            const imageToBeRemoved = savedImages.filter((url: string) => !cloudUrls.includes(url));
            await Promise.all(imageToBeRemoved.map((url: String) => imageService.deleteImage(url)));

        } catch (error) {
            console.log(error);
            for (const imgTag of imgTags) {
                if (isBase64(imgTag.src, {allowMime: true})) {
                    imgTag.remove();
                }
            }
        }

        return container.innerHTML;
    }
}
