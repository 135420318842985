const isLetter = (chartCode: number): boolean => {
    if((chartCode >= 65 && chartCode <= 90) || (chartCode >= 97 && chartCode <= 122)) {
        return true;
    }
    return false;
}

const isNumber = (chartCode: number): boolean => {
    if(chartCode < 48 || chartCode > 57) {
        return false;
    }
    return true;
}

export const encodeUUID = (uuid: string): string => {
    const splitted = uuid.split('-');
    return splitted.map((value: string) => {
        const chartCode = value.charCodeAt(2) + 1;
        if(isLetter(chartCode) || isNumber(chartCode)) {
            return String.fromCharCode(chartCode);
        }
        return String.fromCharCode(value.charCodeAt(3));
    }).join('');
}

export const isValidCode = (uuid: string, code: string): boolean => {
    return encodeUUID(uuid) === code;
}
