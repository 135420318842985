import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../service/http.service';
import ContentToActivity from '../../util/content-to-activity';
import { EntityType } from '../../models/entity-type-enum';
import EntityTypeToUrlMap from '../../util/entity-type-to-url-map';
import { HubClonerComponent } from '../hub-cloner/hub-cloner.component';
import { AuthService } from '../../auth/auth.serviceV2';
import { ImageService } from '../../service/image.service';
import {v4 as uuidv4} from 'uuid';

@Component({
  selector: 'app-activity-cloner',
  templateUrl: './activity-cloner.component.html',
  styleUrls: ['./activity-cloner.component.scss'],
})
export class ActivityClonerComponent implements OnInit {
  readonly apiUrl = environment.apiUrl;

  @Input() handler;
  @Input() hubId;
  @Input() categoryId;
  user = null;

  form;
  loading = false;
  cloning = false;
  activity;
  baseURL = environment.appUrl;
  cloneFolderContent = false;
  innerActivities = [];
  clonedActivity = 0;

  constructor(
    private authService: AuthService,
    protected imageService: ImageService,
    private httpService: HttpService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.user = this.authService.getUserProfile();
    if (location.hostname === 'localhost') {
      this.baseURL = 'https://localhost:4200';
    }
    this.createForm();
  }

  get activityUrl() {
    return this.form.get('activityUrl') as UntypedFormControl;
  }

  get activityUrlValue() {
    return this.form.get('activityUrl').value;
  }

  private createForm() {
    this.form = this.fb.group({
      activityUrl: ['', [Validators.required]],
    });
  }

  async searchActivity() {
    this.cloneFolderContent = false;
    this.innerActivities = [];
    this.clonedActivity = 0;
    if (this.activity) {
      this.activityUrl.enable();
      this.activity = null;
      return;
    }
    if (this.form.invalid) return;

    const searchParams = this.activityUrlValue.split('?');
    const params = new URLSearchParams(
      searchParams.length > 1 ? searchParams[1] : searchParams[0]
    );

    let actCatId;
    if (params.has('activityId')) {
      actCatId = params.get('activityId');
    } else if (params.has('categoryId')) {
      actCatId = params.get('categoryId');
    } else {
      const paths = new URL(this.activityUrlValue).pathname.split('/');
      actCatId = paths[paths.length - 1];
    }

    if (!actCatId) {
      this.activityUrl.setErrors({
        invalid: true,
      });
      return;
    }

    this.loading = true;
    this.activity = await this.getActCatEntity(actCatId);

    if (!this.activity) {
      this.activityUrl.setErrors({
        invalid: true,
      });
      this.loading = false;
      return;
    } else if (this.activity.entityType === EntityType.CATEGORY) {
      this.innerActivities = await HubClonerComponent.getInnerActCatEntity(
        this.activity.hubId,
        this.activity.id,
        this.httpService
      );
    }

    this.activityUrl.disable();

    this.loading = false;
  }

  async cloneActivity() {
    if (!this.activity || !this.activity.clonable || this.cloning) return;
    this.clonedActivity = 0;
    this.cloning = true;
    if (
      this.activity.entityType === EntityType.CATEGORY &&
      this.cloneFolderContent
    ) {
      const urlToClone = `${this.apiUrl}/${
        EntityTypeToUrlMap[this.activity.entityType]
      }/v1`;
      const body = {
        ...HubClonerComponent.getActivityBody(this.activity),
        name: `${this.activity.name}_${uuidv4().split('-')[0]}`,
        hubId: this.hubId,
        userId: this.user.userId,
        active: false,
        includeInMenu: false,
        order: 0,
      };

      const response = await firstValueFrom(
        this.httpService.doPost(urlToClone, body)
      );

      const innerActivities = await HubClonerComponent.getInnerActCatEntity(
        this.activity.hubId,
        this.activity.id,
        this.httpService
      );

      for (const innerCatAct of innerActivities) {
        const innerActivity = innerCatAct.content;
        const innerUrl = `${this.apiUrl}/${
          EntityTypeToUrlMap[innerActivity.entityType]
        }/v1`;
        const innerBody = {
          ...HubClonerComponent.getActivityBody(innerActivity),
          name: `${innerActivity.name}_${uuidv4().split('-')[0]}`,
          hubId: this.hubId,
          userId: this.user.userId,
          categoryId: response.id,
          active: false,
          includeInMenu: false,
          order: 0,
        };
        try {
          const innerResponse = await firstValueFrom(
            this.httpService.doPost(innerUrl, innerBody)
          );
          await HubClonerComponent.cloneImages(
            innerResponse,
            this.imageService
          );
        } catch (error) {} finally {
          this.clonedActivity += 1;
        }
      }

      const url = `${this.baseURL}/${this.handler}/${
        ContentToActivity[this.activity.entityType]
      }/manage?hubId=${this.hubId}&handler=${this.handler}&categoryId=${
        response.id
      }`;
      location.replace(url);
    } else {
      let url = `${this.baseURL}/${this.handler}/${
        ContentToActivity[this.activity.entityType]
      }/manage?hubId=${this.hubId}&handler=${this.handler}&originId=${
        this.activity.id
      }`;
      if (this.categoryId) {
        url = url + `&categoryId=${this.categoryId}`;
      }
      location.replace(url);
    }

    this.cloneFolderContent = false;
    this.innerActivities = [];
    this.cloning = false;
  }

  async getActCatEntity(id: string) {
    const URL = `${environment.apiUrl}/actcatview/v1/client-search?size=1&querySearch=(id:'${id}')`;
    try {
      let response = await firstValueFrom(this.httpService.doGet(URL));
      if (!response || !response.content || response.content.length === 0) {
        const URL2 = `${environment.apiUrl}/shorturl/v1/search?size=1&querySearch=(id:'${id}')`;
        response = await firstValueFrom(this.httpService.doGet(URL2));
      }
      if (
        response &&
        response.content &&
        response.content.length &&
        response.content[0].content
      ) {
        const entity = response.content[0];
        if (!entity) return;
        return {
          id: entity.id,
          ...entity.content,
        };
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  }

  clearActivityUrl() {
    this.activityUrl.setValue(null);
    this.activityUrl.setErrors(null);
  }
}
