<mat-form-field>
  <input 
  type="text"
  matInput
  placeholder="{{'hubManagement.state_providence' | translate}}"
  [formControl]="state"
  [matAutocomplete]="auto">
  <mat-error *ngIf="state.hasError('required')" translate>hubManagement.error_state_providence</mat-error>
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{option}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
