import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { SwiperViewComponent } from '../swiper-view/swiper-view.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SocialShareBottomSheetComponent } from '../social-share-bottom-sheet/social-share-bottom-sheet.component';
import {
  faLock,
  faFolderOpen,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { ShortUrlHandlerComponent } from '../short-url-handler/short-url-handler.component';
import { ActCatViewEntity } from '../../models/activity-category-view';

const SWIPER_STOP_PROPAGATION = [
  'swiper-button-next',
  'swiper-button-prev',
  'swiper-pagination-handle',
  'swiper-button-next swiper-button-disabled',
  'swiper-button-prev swiper-button-disabled',
];

@Component({
  selector: 'app-hub-category',
  templateUrl: './hub-category.component.html',
  styleUrls: ['./hub-category.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HubCategoryComponent implements OnInit {
  @Input() category: ActCatViewEntity;
  @Input() handler: string;
  @Output() click: EventEmitter<any> = new EventEmitter();
  @Input() isOwnerView: boolean = false;
  
  @ViewChild('swiper') swiper: SwiperViewComponent;

  images = [];
  url: string;
  activityIcon: IconDefinition;

  constructor(private _bottomSheet: MatBottomSheet) {}

  ngOnInit(): void {
    this.activityIcon = this.category.content.restricted
      ? faLock
      : faFolderOpen;
    this.url = ShortUrlHandlerComponent.shortActCatUrl(this.category.id);
    if (this.category.content.images) {
      this.images = this.category.content.images;
    } else {
      this.images = [];
    }
  }

  public goToCategory(categoryId: string): void {
    this.click.emit({
      id: categoryId,
      restricted: this.category.content.restricted,
    });
  }

  public validateGoToCategory(event: any): void {
    if (SWIPER_STOP_PROPAGATION.includes(event.target.className)) {
      event.stopPropagation();
    }
  }

  stopVideo() {
    if (!this.swiper) {
      return;
    }
    this.swiper.youtubeVideoControl(null);
  }

  openShare(event: MouseEvent): void {
    event.stopPropagation();
    this._bottomSheet
      .open(SocialShareBottomSheetComponent, {
        panelClass: 'share-bottom-sheet',
        data: {
          url: this.url,
          title: this.category.content.name,
          description: this.category.content.infoText,
          images: this.category.content.images,
          tags: null,
        },
      })
      .afterDismissed()
      .subscribe((result) => {});
  }
}
