import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppSettings } from '../../../../app.settings';
import { HttpService } from '../../../../service/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../../../environments/environment';
import Category from '../../../../models/category-model';
import {
  CategoryAccessEntity,
  UpdateCategoryAccessCommand,
} from '../../../../models/category';
import { firstValueFrom } from 'rxjs';
import { HubEntity } from '../../../../models/hub';

@Component({
  selector: 'app-request-category-access-details',
  templateUrl: './request-category-access-details.component.html',
  styleUrls: ['./request-category-access-details.component.scss'],
})
export class RequestCategoryAccessDetailsComponent implements OnInit {
  @Input() report: CategoryAccessEntity;
  @Output() updated: EventEmitter<any> = new EventEmitter();

  private readonly apiUrl = environment.apiUrl;
  settings = null;
  reportDetail: CategoryAccessEntity;
  form: UntypedFormGroup;
  category: Category;
  hub: HubEntity;

  constructor(
    private httpService: HttpService,
    private appSettings: AppSettings,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.settings.loadingSpinner = true;
    this.retrieve().then(() => {
      this.createForm();
      this.settings.loadingSpinner = false;
    });
  }

  get status() {
    return this.form.get('status') as UntypedFormControl;
  }

  private async retrieve(): Promise<any> {
    try {
      const reportURL = `${this.apiUrl}/categories/v1/access/category/${
        this.report.clientId
      }/${this.report.categoryId}`;
      const response: CategoryAccessEntity = await firstValueFrom(
        this.httpService.doGet(reportURL)
      );
      if (response) {
        this.reportDetail = response;
      }

      const activityURL = `${this.apiUrl}/categories/v1/${this.reportDetail.categoryId}`;
      const responseCategory: Category = await firstValueFrom(
        this.httpService.doGet(activityURL)
      );
      if (responseCategory) {
        this.category = responseCategory;
      }
    } catch (error) {
      this.settings.loadingSpinner = false;
      console.log('Error getting category: ', error);
    }

    await this.retrieveHub(this.report.hubId);
  }

  private async retrieveHub(hubId: string) {
    try {
      const hubUrl = `${this.apiUrl}/hubs/v1/${hubId}`;
      const hubResponse: HubEntity = await firstValueFrom(
        this.httpService.doGet(hubUrl)
      );
      if (!hubResponse) return;
      this.hub = hubResponse;
    } catch (error) {
      console.log('Error getting activity: ', error);
    }
  }

  private async createForm() {
    this.form = this.fb.group({
      categoryId: [this.reportDetail.categoryId],
      hubId: [this.reportDetail.hubId],
      //ownerId: [this.report.ownerId],
      status: [this.reportDetail.status],
      clientId: [this.reportDetail.clientId],
    });
  }

  async sendRequest() {
    return;
    const url = `${this.apiUrl}/categories/v1/update-access`;
    this.settings.loadingSpinner = true;
    const rawValues = this.form.getRawValue();

    const { ownerId, clientId, hubId, categoryId, status } = rawValues;

    const requestBody: UpdateCategoryAccessCommand = {
      ownerId,
      clientId,
      hubId,
      categoryId,
      status,
    };

    try {
      const response: CategoryAccessEntity = await firstValueFrom(
        this.httpService.doPut(url, requestBody)
      );
      this.reportDetail = response;
      this.updated.emit(this.reportDetail);
      this.showSnackBar('Operation successful ', 'OK', 2000);
    } catch (error) {
      this.form.get('status').setValue(this.reportDetail.status);
      console.log('Error sending request: ', error);
      this.showSnackBar('Error Processing Your Request', 'OK', 2000);
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  getAvailableStatus(currentStatus: string): string[] {
    return [currentStatus];
  }

  private showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  get totalPrice(): number {
    return Number(this.reportDetail?.totalPrice || 0);
  }
}
