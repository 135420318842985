import { Component, Inject } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressAction } from '../../models/address-action-enum';
import { CanDeleteDialogComponent } from '../can-delete-dialog/can-delete-dialog.component';

@Component({
  selector: 'address-form-dialog',
  templateUrl: './address-form-dialog.component.html',
  styleUrls: ['./address-form-dialog.component.scss'],
})
export class AddressFormDialogComponent {
  form: UntypedFormGroup;
  addressForm: UntypedFormGroup;
  user;
  parentData: any;

  constructor(
    public dialogRef: MatDialogRef<AddressFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    protected dialog: MatDialog,
  ) {
    this.parentData = data;
    const { user, location: locationWrapper } = data;
    const { address } = locationWrapper || {};
    this.user = user;
    this.createForm();
    this.setForm(locationWrapper || {});
    if (address) {
      this.setAddressForm(address);
    }
  }

  get location() {
    return this.form.get('location') as UntypedFormControl;
  }

  createForm() {
    this.addressForm = this.fb.group({
      country: ['', Validators.required],
      stateOrProvidence: ['', Validators.required],
      city: ['', Validators.required],
      address: ['', Validators.required],
      address2: [''],
      zipCode: [''],
      locationHint: [''],
    });

    this.form = this.fb.group({
      defaultLocation: [false],
      name: ['', Validators.required],
      address: this.addressForm,
      location: [this.parentData.location, Validators.required],
    });
  }

  setForm(response) {
    this.form.patchValue({ defaultLocation: response.defaultLocation });
    this.form.patchValue({ name: response.name });
    this.form.patchValue({ location: response.location });
    this.form.markAsPristine();
  }

  setAddressForm(address) {
    this.addressForm.patchValue({ country: address.country });
    this.addressForm.patchValue({
      stateOrProvidence: address.stateOrProvidence,
    });
    this.addressForm.patchValue({ city: address.city });
    this.addressForm.patchValue({ address: address.address });
    this.addressForm.patchValue({ address2: address.address2 });
    this.addressForm.patchValue({ zipCode: address.zipCode });
    this.addressForm.patchValue({ locationHint: address.locationHint });
    this.addressForm.markAsPristine();
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    if(this.form.invalid) return;
    const { selectedIndex } = this.parentData;
    this.dialogRef.close({
      selectedIndex,
      action: selectedIndex === undefined ? AddressAction.CREATE : AddressAction.EDIT,
      locationData: this.form.getRawValue()
    });
  }

  delete(): void {
    if(this.form.invalid) return;
    const { selectedIndex } = this.parentData;  
    this.dialogRef.close({
      selectedIndex,
      action: AddressAction.DELETE,
    });
  }

  canDelete(): void {
    const dialogRef = this.dialog.open(CanDeleteDialogComponent, {
      minWidth: "240px",
      data: {
        title: 'titles.delete_address',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.delete();
      }
    });
  }

}
