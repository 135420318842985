import { Component } from '@angular/core';
import { AppSettings } from '../../app.settings';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {

  public settings;

  constructor(
    public appSettings: AppSettings,
    ) {
      this.settings = this.appSettings.settings;
     }

}
