import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-frequently-asked-questions',
  templateUrl: './frequently-asked-questions.component.html',
  styleUrls: ['./frequently-asked-questions.component.scss'],
})
export class FrequentlyAskedQuestionsComponent {

  @Input() questions = [];

  show = -1;

  setOpen(index: number) {
    this.show = index;
  }
}
