import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RouteConstants } from '../../app.constants';
import { AuthService } from '../../auth/auth.serviceV2';
import { ReviewPreviewBean } from '../../models/review';
import { HttpService } from '../../service/http.service';
import { CommentsDialogComponent } from '../comments-dialog/comments-dialog.component';

@Component({
  selector: 'app-comments-preview',
  templateUrl: './comments-preview.component.html',
  styleUrls: ['./comments-preview.component.scss'],
})
export class CommentsPreviewComponent implements OnInit {
  @Input() entityId: string;
  @Input() entityType: string;
  @Input() entityName: string;
  loading = false;
  show = true;
  preview: ReviewPreviewBean;
  user: any;
  public noImage = '../../../assets/img/app/default-user.jpg';
  private readonly apiUrl = environment.apiUrl;

  constructor(
    private dialog: MatDialog,
    private httpService: HttpService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public get reviewRate() {
    if (!this.preview) {
      return 0.0;
    }
    return this.preview.reviewRate;
  }

  public get reviewCount() {
    if (!this.preview) {
      return 0;
    }
    return this.preview.reviewCount;
  }

  ngOnInit(): void {
    this.user = this.authService.getUserProfile();
    if (this.route.snapshot.fragment === 'showReview') {
      const loc = window.location;
      history.pushState('', document.title, loc.pathname + loc.search);
      this.openComments();
    }
    this.search();
  }

  async search() {
    const url = `${this.apiUrl}/review/v1/preview/${this.entityType}/${this.entityId}`;
    this.loading = true;
    try {
      const response: ReviewPreviewBean = await firstValueFrom(
        this.httpService.doGet(url)
      );
      if (response) {
        this.preview = response;
      } else {
        this.preview = null;
      }
    } catch (error) {
      this.preview = null;
      console.log('Error: ', error);
    } finally {
      this.loading = false;
    }
  }

  async openComments(): Promise<void> {
    if (
      !(await this.authService.isAuthenticated()) &&
      (!this.preview || this.preview.reviewEntityList.length === 0)
    ) {
      localStorage.setItem('return_url', `${this.router.url}`);
      this.router.navigate([RouteConstants.auth]);
      return;
    }

    const dialogRef = this.dialog.open(CommentsDialogComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'comments-dialog',
      data: {
        entityId: this.entityId,
        entityType: this.entityType,
        entityName: this.entityName,
      },
    });
    dialogRef.afterClosed().subscribe((_result) => {
      this.search();
    });
  }

  showHide(event) {
    event.stopPropagation();
    this.show = !this.show;
  }
}
