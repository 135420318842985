import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { ServiceModule } from '../../service/service.module';
import { ActivityRequestManagementComponent } from './activity-request-management/activity-request-management.component';
import { ActivityRequestClientViewComponent } from './activity-request-client-view/activity-request-client-view.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AuthGuard } from '../../auth/auth.guard';
import { ComponentsModule } from '../../components/componets.module';
import { FormGuard } from '../../guards/form.guard';
import { RequestListClientViewComponent } from './components/request-list-client-view/request-list-client-view.component';
import { RequestDetailsDialogComponent } from './components/request-details-dialog/request-details-dialog.component';
import { RequestRequestDetailsComponent } from './components/request-request-details/request-request-details.component';
import { RequestRequestGeneralInfoComponent } from './components/request-request-general-info/request-request-general-info.component';

export const routes = [
  { path: 'client-view', component: ActivityRequestClientViewComponent },
  {
    path: 'manage',
    component: ActivityRequestManagementComponent,
    canActivate: [AuthGuard],
    canDeactivate: [FormGuard],
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ComponentsModule,
    ServiceModule,
    RouterModule.forChild(routes),
    DragDropModule,
  ],
  providers: [],
  declarations: [
    ActivityRequestManagementComponent,
    ActivityRequestClientViewComponent,
    RequestListClientViewComponent,
    RequestRequestDetailsComponent,
    RequestDetailsDialogComponent,
    RequestRequestGeneralInfoComponent
  ],
  exports: [
    ActivityRequestManagementComponent,
  ],
})
export class ActivityRequestModule {}
