import { Component, Input} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { QrReaderDialogComponent } from '../qr-reader-dialog/qr-reader-dialog.component';

@Component({
  selector: 'app-qr-reader-card',
  templateUrl: './qr-reader-card.component.html',
  styleUrls: ['./qr-reader-card.component.scss']
})
export class QrReaderCardComponent {

  @Input() text: string;
  icon = faQrcode;

  constructor(public dialog: MatDialog, public router: Router,){}

  openQrReader(): void {
    const dialogRef = this.dialog.open(QrReaderDialogComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'qr-reader-dialog',
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.router.navigateByUrl(result);
      }
    });
  }

}
