<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <button (click)="back()" mat-button><span translate>tinyAll.back</span></button>
    <button class="text-accent" (click)="goToCreateActivity()" translate>titles.create_new_activity</button>
</div>
<div class="flex justify-between mt-4">
    <h2 class="title" translate>titles.sort_navigation_menu</h2>
    <button (click)="save()" mat-raised-button [disabled]="!hasChenge" color="accent"><span translate>tinyAll.save</span></button>
</div>
<div class="page-container" fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="15px">
    <div class="list-container">
        <h2 class="subtitle" translate>titles.navigation_menu</h2>
        <div fxLayout="column" fxLayoutGap="5px">
            <mat-card class="card-container fixed">
                <div fxLayout="row" class="hanlde-container">
                    <fa-icon class="list-item-menu-icon" [icon]="faHome"></fa-icon>
                    <h3>Hub</h3>
                </div>
            </mat-card>
            <mat-card class="card-container fixed">
                <div fxLayout="row" class="hanlde-container">
                    <fa-icon class="list-item-menu-icon" [icon]="faInfoCircle"></fa-icon>
                    <h3 translate>labels.about_us</h3>
                </div>
            </mat-card>
            <div class="questions-boundary"
                #menu="cdkDropList"
                cdkDropList
                (cdkDropListDropped)="filterDrop($event)"
                [cdkDropListData]="items"
                [cdkDropListConnectedTo]="[actCatView]"
                novalidate 
                fxLayout="column" 
                fxLayoutAlign="center">
                <div *ngFor="let item of items; let i = index;" 
                  cdkDragLockAxis="y" 
                  cdkDrag>
                    <mat-card class="card-container">
                        <div fxLayout="row" class="hanlde-container">
                            <fa-icon class="handle-move" [icon]="faGripVertical" cdkDragHandle></fa-icon>
                            <fa-icon class="list-item-menu-icon" [icon]="activityIconMap[item.type]"></fa-icon>
                            <h3>{{item.activityName}}</h3>
                            <fa-icon class="list-item-menu-icon-remove" [icon]="faTimes" (click)="remove(i)"></fa-icon>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
    <div class="list-container">
        <h2 class="subtitle" translate>titles.act_cat_list</h2>
        <mat-form-field appearance="outline" class="search-field">
            <input matInput type="search" [(ngModel)]="searchField" autocomplete="off" (keyup)="filterItems()" (change)="filterItems()" placeholder="{{'formFields.search' | translate}}">
            <button mat-button mat-icon-button matSuffix (click)="clearSearchField()" *ngIf="searchField">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <div fxLayout="column" fxLayoutGap="5px">
            <div class="questions-boundary"
                cdkDropList
                #actCatView="cdkDropList"
                cdkDropListSortingDisabled
                (cdkDropListDropped)="drop($event)"
                [cdkDropListData]="filteredItems"
                [cdkDropListConnectedTo]="[menu]"
                novalidate 
                fxLayout="column" 
                fxLayoutAlign="center">
                <div *ngFor="let item of filteredItems; let i = index;" 
                  cdkDragLockAxis="y" 
                  cdkDrag>
                    <mat-card class="card-container">
                        <div fxLayout="row" class="hanlde-container">
                            <fa-icon class="handle-move" [icon]="faGripVertical" cdkDragHandle></fa-icon>
                            <fa-icon class="list-item-menu-icon" [icon]="activityIconMap[item.type]"></fa-icon>
                            <h3>{{item.activityName}}</h3>
                            <fa-icon *ngIf="this.items.length < MAX_MENU_ITEMS" class="list-item-menu-icon-remove" [icon]="faPlus" (click)="add(i)"></fa-icon>
                        </div>
                    </mat-card>
    
                </div>
            </div>
        </div>
    </div>
</div>