export default {
    facebook: 'https://facebook.com/', 
    twitter: 'https://twitter.com/', 
    linkedin: 'https://linkedin.com/in/',
    instagram: 'https://instagram.com/', 
    snapchat: 'https://snapchat.com/add/',
    pinterest: 'https://pinterest.com/',
    reddit: 'https://reddit.com/user/', 
    whatsapp: 'https://wa.me/', 
    tumblr: '.tumblr.com', 
    telegram: 'https://telegram.me/', 
    youtube: 'https://youtube.com/channel/', 
    medium: 'https://medium.com/',
};