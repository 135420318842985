import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AppSettings } from '../../../app.settings';
import { HttpService } from '../../../service/http.service';
import { ImageService } from '../../../service/image.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../../auth/auth.serviceV2';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { CartListComponent } from '../components/cart-list/cart-list.component';
import { ProductListComponent } from '../components/product-list/product-list.component';
import { SentRequestDialogComponent } from '../../../components/sent-request-dialog/sent-request-dialog.component';
import { BaseClientViewComponent } from '../../baseclientview.component';
import QCVUtil from '../../../util/questionsClientViewUtil';
import { ShoppingRequestStatus } from '../../../models/shopping-request-status-enum';
import { CommentsPreviewComponent } from '../../../components/comments-preview/comments-preview.component';
import { EntityType } from '../../../models/entity-type-enum';
import { RouteConstants } from '../../../app.constants';
import {
  bankTransferImageValidator,
  cashReturnValidator,
  chosenPaymentTypeValidator,
} from '../../../util/form-utils';
import { PaymentType } from '../../../models/payment-type-enum';
import { firstValueFrom } from 'rxjs';
import { isEmpty } from 'lodash';
import AdditionalChargesUtil from '../../../util/additionalChargesUtil';
import { Meta, Title } from '@angular/platform-browser';
import {
  setActivityPageHead,
  setDefaultPageHead,
} from '../../../util/seo-utils';
import { TranslateService } from '@ngx-translate/core';
import { AzulPaymentFormComponent } from '../../../components/azul-payment-form/azul-payment-form.component';
import {
  ActivityProductListingEntity,
  CreateShoppingRequestCommand,
  ShoppingRequestEntity,
} from '../../../models/activity-product-listing';
import { HubEntity } from '../../../models/hub';
import QMUtil from '../../../util/questionsManagementUtil';
import { UserAdditionalInfoService } from '../../authentication/login/user-additional-info.service';
import { MatureContentService } from '../../../service/mature-content.service';

@Component({
  selector: 'app-activity-product-listing-client-view',
  templateUrl: './activity-product-listing-client-view.component.html',
  styleUrls: ['./activity-product-listing-client-view.component.scss'],
})
export class ActivityProductListingClientViewComponent
  extends BaseClientViewComponent
  implements OnInit
{
  @ViewChild('commentsPreview') commentsPreview: CommentsPreviewComponent;
  @ViewChild('requestListTag') requestList;
  @ViewChild('cartList') cartListRef: CartListComponent;
  @ViewChild('productList') productListRef: ProductListComponent;
  @ViewChild('tabs', { static: false }) tabs: MatTabGroup;
  @ViewChild('azulPaymentEl') azulPayment: AzulPaymentFormComponent;

  readonly activityType = 'product-listing';
  coordinates = null;
  entity: ActivityProductListingEntity;
  type = EntityType.ACTIVITY_PRODUCT_LISTING;
  reported = false;
  private scrolls = [0, 0, 0];
  private mainContent;
  sendRequestPin;
  questionsV1 = [];

  constructor(
    private authService: AuthService,
    private userAdditionalInfoService: UserAdditionalInfoService,
    private httpService: HttpService,
    public appSettings: AppSettings,
    public router: Router,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    protected imageService: ImageService,
    protected translate: TranslateService,
    protected location: Location,
    private matureContentService: MatureContentService,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    super(appSettings, snackBar, imageService, router, location, translate);
  }

  async ngOnInit() {
    await this.authService.isAuthenticated();
    this.user = this.authService.getUserProfile();
    this.hubId = this.route.snapshot.queryParams['hubId'];
    this.categoryId = this.route.snapshot.queryParams['categoryId'];
    this.activityId = this.route.snapshot.queryParams['activityId'];
    this.mainContent = document.getElementById('main-content');
    this.settings.loadingSpinner = true;
    this.showPaymentResult(this.route.snapshot.queryParams['status']);
    this.handler = this.route.snapshot.paramMap.get('handler');
    localStorage.removeItem('request_return_url');
    await this.retrieveHub(this.hubId);
    this.matureContentService.check(this.hub.adultContent);
    this.initPage();
  }

  private async initPage() {
    try {
      const activityUrl = `${this.apiUrl}/activity-product-listing/v1/${this.activityId}`;
      const activityResponse: ActivityProductListingEntity =
        await firstValueFrom(this.httpService.doGet(activityUrl));
      if (activityResponse != null) {
        this.entity = activityResponse;
        this.questionsV1 = QMUtil.transformQuestionsV2ToV1(
          activityResponse.questions
        );
        setActivityPageHead(
          this.titleService,
          this.metaTagService,
          activityResponse,
          this.hub
        );
      } else {
        setDefaultPageHead(this.titleService, this.metaTagService, true);
      }
      await this.createForm();
      if (
        this.route.snapshot.fragment === 'request' &&
        (await this.authService.isAuthenticated())
      ) {
        window.location.hash = '';
        if (this.sendRequestPin) {
          clearTimeout(this.sendRequestPin);
        }
        this.sendRequestPin = setTimeout(() => {
          const data = localStorage.getItem(this.entity.id);
          if (data) {
            const parsedData = JSON.parse(data);
            this.sendRequest({
              ...parsedData,
              clientId: this.user.userId,
            });
          } else {
            this.settings.loadingSpinner = false;
          }
        }, 1000);
      } else {
        this.settings.loadingSpinner = false;
      }
    } catch (error) {
      console.log('Error getting activity: ', error);
      if (error.status === 404) {
        this.notFound = true;
      }
      this.settings.loadingSpinner = false;
    }
  }

  private async retrieveHub(hubId: string) {
    try {
      const hubUrl = `${this.apiUrl}/hubs/v1/${hubId}`;
      const hubResponse: HubEntity = await firstValueFrom(
        this.httpService.doGet(hubUrl)
      );
      if (!hubResponse) return;
      this.hub = hubResponse;
    } catch (error) {
      console.log('Error getting activity: ', error);
    }
  }

  private async createForm() {
    if (!this.entity) {
      return;
    }
    this.form = this.fb.group(
      {
        hubId: [this.hubId],
        activityId: [this.activityId],
        clientId: [this.user?.userId],
        status: [ShoppingRequestStatus.OPEN],
        questionAnswerMap: this.fb.group(
          QCVUtil.createQuestionsForm(this.entity.questions)
        ),
        chosenPaymentType: [''],
        cashReturn: [null],
        paymentImage: [''],
        currency: [this.entity.currency],
        totalPrice: [0],
        promoCode: [''],
        promoCodeApplied: [false],
      },
      {
        validators: [
          bankTransferImageValidator,
          cashReturnValidator,
          chosenPaymentTypeValidator(this.hub.supportedPaymentTypes),
        ],
      }
    );

    if (this.entity.phoneNumberRequired) {
      this.form.addControl(
        'clientPhoneNumber',
        this.fb.control(this.user?.cellPhone || '', [
          Validators.required,
          Validators.pattern(
            '^(([1-9][0-9]{2})|[1-9][0-9]{2})[1-9][0-9]{2}[0-9]{4}$'
          ),
        ])
      );
    }

    if (this.entity.gpsRequired) {
      this.form.addControl(
        'location',
        this.fb.control({ value: '', disabled: true }, [Validators.required])
      );

      const permissionStatus = await navigator.permissions.query({
        name: 'geolocation',
      });
      if (permissionStatus && permissionStatus.state === 'granted') {
        this.getCurrentPosition().then((position: any) => {
          let COORDINATES;
          if (position) {
            COORDINATES = {
              coordinates: [
                position.coords.latitude,
                position.coords.longitude,
              ],
              type: 'Point',
            };
            this.form.get('location').setValue(COORDINATES);
          }
        });
      }
    }

    if (this.entity.addressRequired) {
      this.form.addControl(
        'choosenLocation',
        this.fb.control('', [
          Validators.required,
        ])
      );
    }
  }

  get chosenPaymentType() {
    return this.form.get('chosenPaymentType') as UntypedFormControl;
  }

  get totalPrice() {
    return this.form.get('totalPrice') as UntypedFormControl;
  }

  get questionAnswerMap() {
    return this.form.get('questionAnswerMap') as UntypedFormGroup;
  }

  getCurrentPosition(options = {}) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
  }

  getQuestionsLength(): number {
    if (!this.form || !this.form.get('questionAnswerMap')) {
      return 0;
    }
    const GROUP = this.form.get('questionAnswerMap') as UntypedFormGroup;
    return Object.keys(GROUP.controls).length;
  }

  getShoppingList() {
    return this.cartListRef.getShoppingList();
  }

  async sendRequest(body?: any) {
    if (!this.cartListRef) {
      this.settings.loadingSpinner = false;
      return;
    }

    if (!this.entity || !this.form) {
      this.settings.loadingSpinner = false;
      return;
    }

    if (!(await this.authService.isAuthenticated())) {
      localStorage.setItem('return_url', `${this.router.url}#request`);
      localStorage.setItem('request_return_url', `${this.router.url}#request`);
      localStorage.setItem(
        this.entity.id,
        JSON.stringify(this.getRequestBody(body))
      );
      await this.router.navigate([RouteConstants.auth]);
      this.settings.loadingSpinner = false;
      return;
    }

    this.settings.loadingSpinner = true;

    if (!await this.userAdditionalInfoService.check(`${this.router.url}#request`)) {
      localStorage.setItem(
        this.entity.id,
        JSON.stringify(this.getRequestBody(body))
      );
      this.settings.loadingSpinner = false;
      return;
    }

    const url = `${this.apiUrl}/activity-product-listing/v1/shopping-request`;
    const rawValues = this.getRequestBody(body);

    const {
      clientId,
      hubId,
      activityId,
      questionAnswerMap,
      location,
      totalPrice,
      clientPhoneNumber,
      chosenPaymentType,
      cashReturn,
      paymentImage,
      status,
      shoppingList,
      amount,
      currency,
      choosenLocation,
      promoCode,
      promoCodeApplied,
    } = rawValues;

    const requestBody: CreateShoppingRequestCommand = {
      clientId,
      hubId,
      activityId,
      questionAnswerMap: QCVUtil.transformAnswersV1ToV2(questionAnswerMap, this.entity.questions),
      location,
      totalPrice,
      clientPhoneNumber,
      chosenPaymentType,
      cashReturn,
      paymentImage,
      status,
      shoppingList,
      amount,
      currency,
      choosenLocation,
      ...(promoCodeApplied && { promoCode })
    };

    try {
      const response: ShoppingRequestEntity = await firstValueFrom(
        this.httpService.doPost(url, requestBody)
      );
      this.cartListRef.clearLocal();
      this.form.reset();
      this.createForm();
      if (rawValues.chosenPaymentType === PaymentType.TINYALL_PAY) {
        this.azulPayment?.azulPay(response);
        return;
      }
      this.openDialog();
    } catch (error) {
      this.showSnackBar('Error Processing Your Request', 'OK', 2000);
    } finally {
      localStorage.removeItem(this.entity.id);
      this.settings.loadingSpinner = false;
    }
  }

  private getRequestBody(body) {
    if (body) {
      return body;
    }
    const rawValues = this.form.getRawValue();
    rawValues.questionAnswerMap = QCVUtil.cleanContent(
      this.entity.questions,
      rawValues
    );
    rawValues['totalPrice'] =
      rawValues['totalPrice'] > 0 ? rawValues['totalPrice'].toFixed(2) : '0';
    rawValues['shoppingList'] = this.getShoppingList();
    rawValues['amount'] = this.cartListRef.summary.total;
    return AdditionalChargesUtil.creanPaymentContent(rawValues);
  }

  async onCancel() {}

  goToProductList() {
    const tabGroup = this.tabs;
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) {
      return;
    }
    this.saveScroll(tabGroup.selectedIndex);
    tabGroup.selectedIndex = 0;
    this.scrollToSaved(0);
  }

  goToCart() {
    const tabGroup = this.tabs;
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) {
      return;
    }
    this.saveScroll(tabGroup.selectedIndex);
    tabGroup.selectedIndex = 1;
    this.scrollToSaved(1);
  }

  // Product Listing

  goToCheckout() {
    const tabGroup = this.tabs;
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) {
      return;
    }
    this.saveScroll(tabGroup.selectedIndex);
    tabGroup.selectedIndex = 2;
    this.scrollToSaved(2);
  }

  saveScroll(index: number) {
    if (this.mainContent) {
      this.scrolls[index] = this.mainContent.scrollTop;
    }
  }

  scrollToSaved(index: number) {
    if (this.mainContent) {
      setTimeout(() => {
        this.mainContent.scrollTop = this.scrolls[index];
      }, 200);
    }
  }

  updateCart() {
    if (this.cartListRef) {
      this.cartListRef.search();
      this.scrolls[1] = 0;
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(SentRequestDialogComponent, {
      minWidth: '240px',
      data: {
        title: 'titles.order',
        content: 'messages.operation_successful',
        hasRichText: !!this.entity.newRequestMessage,
        richContent: this.entity.newRequestMessage
      },
    });

    dialogRef.afterClosed().subscribe((_result) => {
      this.productListRef.selectedForm.get('id').setValue('');
      this.cartListRef.selectedForm.get('id').setValue('');
      this.requestList.search();
      this.goToProductList();
      setTimeout(() => {
        const element = document.getElementById('request-list');
        if (element !== null) {
          element.scrollIntoView();
        }
      }, 300);
    });
  }

  get showPriceSummary() {
    return (
      !this.locked &&
      this.entity &&
      this.form &&
      this.cartListRef &&
      (Number(this.cartListRef.summary.total || 0) ||
        this.getQuestionsLength() > 0 ||
        !isEmpty(this.entity.additionalCharges || {}))
    );
  }

  get showPaymentMethods() {
    return (
      !this.locked &&
      this.form &&
      this.hub &&
      this.hub.supportedPaymentTypes &&
      this.hub.supportedPaymentTypes.length > 0 &&
      this.totalPrice.value
    );
  }

  get showSendRequest() {
    return (
      !this.locked &&
      this.form &&
      (this.chosenPaymentType.value !== PaymentType.TINYALL_PAY ||
        !this.totalPrice.value)
    );
  }

  get disableSendRequest() {
    return !this.form || this.form.invalid;
  }
}
