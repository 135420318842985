<mat-card>
    <div fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex class="filter-container">
          <button mat-button matSuffix mat-icon-button (click)="openSort($event)">
            <mat-icon>sort_by_alpha</mat-icon>
          </button>
      </div>
        <div fxFlex>
            <form class="form">
                <mat-form-field class="field" floatLabel="never" appearance="standard">
                  <mat-chip-list #chipList aria-label="Search selection">
                    <mat-chip
                      color="primary"
                      *ngFor="let item of items"
                      selectable
                      removable
                      (removed)="remove(item)">
                        <p class="chip-text">{{item}}</p>
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input
                      placeholder="{{'placeholders.activity_filter' | translate}}"
                      #searchInput
                      [formControl]="search"
                      [matAutocomplete]="auto"
                      matAutocompletePosition="below"
                      panelWidth="100%"
                      class="search-bar-panel"
                      [matChipInputFor]="chipList"
                      [matChipInputAddOnBlur]="true"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      (input)="validateKey($event)"
                      (matChipInputTokenEnd)="add($event)">
                  </mat-chip-list>
                  <mat-autocomplete #auto="matAutocomplete"
                      matAutocompletePosition="below"
                      (opened)="panelOpened()" 
                      (closed)="panelClosed()" 
                      minWidth="100%" 
                      panelClass="search-bar-panel" 
                      backdropClass="search-bar-panel" 
                      class="search-bar-panel" 
                      (optionSelected)="selected($event)">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                  <!--<button mat-button *ngIf="items.length > 0" matSuffix mat-icon-button aria-label="Clear" (click)="cleanSearch()">
                    <mat-icon>close</mat-icon>
                  </button>-->
                </mat-form-field>
            </form>
        </div>

        <div fxFlex class="filter-container">
            <button mat-button matSuffix mat-icon-button (click)="openFilter($event)">
                <mat-icon>filter_list</mat-icon>
            </button>
        </div>
    </div>   
</mat-card>