import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { environment } from '../../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-table-dishes-list',
  templateUrl: './table-dishes-list.component.html',
  styleUrls: ['./table-dishes-list.component.scss']
})
export class TableDishesListComponent implements OnInit, OnDestroy {

  @Input() activityId: string;
  @Input() hubId: string;

  readonly apiUrl = environment.apiUrl;

  settings: Settings;
  items = [];
  pageSize = 10000;
  pageIndex = 0;
  loading = false;
  itemLoading = false;
  handler = null;
  formSubs: Subscription;

  private mainContent;
  private storageKey: string;
  private storageTipKey: string;

  summary = {
    quantity: 0,
    total: 0,
    tip: 0
  };

  form: UntypedFormGroup;

  constructor(
    private translate: TranslateService,
    protected snackBar: MatSnackBar,
    private appSettings: AppSettings,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.storageTipKey = `menu/${this.activityId}/tip`;
    this.storageKey = `menu/${this.activityId}`;
    this.mainContent = document.getElementById('main-content');
    this.route.paramMap.pipe(
      map((params: ParamMap) => params.get('handler'))
    ).subscribe((value) => {
        this.handler = value;
        this.retrieveContent();
        this.onChanges();
    });
  }

  private async retrieveContent() {
    await this.search();
  }

  getFromLocal() {
    return JSON.parse(localStorage.getItem(this.storageKey));
  }

  getTip() {
    return JSON.parse(localStorage.getItem(this.storageTipKey));
  }

  setTip() {
    const selectedTip = this.form.get('tip').value;
    if(selectedTip) {
      localStorage.setItem(this.storageTipKey, this.form.get('tip').value || 0);
    }
    else {
      localStorage.removeItem(this.storageTipKey);
    }
  }

  addToLocal(dish) {
    let localTable = this.getFromLocal();
    if (!localTable) {
      localTable = {};
    }

    localTable[dish.key] = dish;

    localStorage.setItem(this.storageKey, JSON.stringify(localTable));
  }


  clearLocal() {
    localStorage.setItem(this.storageKey, JSON.stringify({}));
    localStorage.removeItem(this.storageTipKey);
    this.resetItems();
  }
  
  resetItems() {
    this.items = [];
    this.setSummary();
    this.loading = false;
    this.mainContent.scrollTop = 0;
  }

  async search() {
    const localTable = this.getFromLocal();
    this.items = localTable ? Object.values(localTable) : [];
    this.form = this.fb.group({
      tip: [this.getTip() || 0]
    });
    if (!this.items.length) {
      this.resetItems();
      return;
    }
    this.setSummary();
  }

  quantityChange(event: any) {
    this.addToLocal(event.item);
    this.setSummary();

    const msg = this.translate.instant(
      event.action === '+' 
      ? 'messages.dish_added_msg'
      : 'messages.dish_removed_msg'
      , {
      name: `${event.item.name} - ${event.item.variantName}`,
      quantity: event.quantity
    });
    this.showSnackBar(msg, 'OK', 2000);
  }

  remove(dish) {
    const localTable = this.getFromLocal();
    if(localTable) {
      delete localTable[dish.key];
      localStorage.setItem(this.storageKey, JSON.stringify(localTable));
    }
    this.items = localTable ? Object.values(localTable) : [];
    this.setSummary();
    this.mainContent.scrollTop = Math.max(this.mainContent.scrollTop - 800, 0);
    this.showSnackBar(this.translate.instant('messages.dish_removed_msg', {
      name: `${dish.name} - ${dish.variantName}`,
      quantity: dish.quantity
    }), 'OK', 2000);
  }

  showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  setSummary() {
    this.summary = this.items.reduce((prev, item) => {
        prev.quantity += item.quantity;
        prev.total += (item.price * item.quantity);
        return prev;
      }, {
        quantity: 0,
        total: 0,
      });
      const selectedTip = this.form.get('tip').value;
      this.summary.tip = selectedTip ? ((this.summary.total * selectedTip) / 100) : 0;
  }

  getDishRequestedList() {
    return this.items.map(({description, image, name, variantName, price, quantity}) => {
      return {
        dishDescription: description,
        dishMainImage: image,
        dishName: name,
        dishOptDescription: variantName,
        dishPrice: price,
        quantity,
      }
    });
  }

  onChanges(): void {
    this.formSubs = this.form.valueChanges.subscribe(val => {
      this.setSummary();
    });
  }

  ngOnDestroy(): void {
    if(this.formSubs) {
      this.formSubs.unsubscribe();
    }
  }

}
