import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ViewChild,
} from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { HttpService } from '../../../service/http.service';
import { RouteConstants } from '../../../app.constants';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { HubActivityComponent } from '../../../components/hub-activity/hub-activity.component';
import { HubCategoryComponent } from '../../../components/hub-category/hub-category.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SocialShareBottomSheetComponent } from '../../../components/social-share-bottom-sheet/social-share-bottom-sheet.component';
import { ContentType } from '../../../models/content-type-enum';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { TranslateService } from '@ngx-translate/core';
import { CommentsPreviewComponent } from '../../../components/comments-preview/comments-preview.component';
import { EntityType } from '../../../models/entity-type-enum';
import { AuthService } from '../../../auth/auth.serviceV2';
import socialMediaPrefix from '../../../util/social-media-map';
import { ShortUrlHandlerComponent } from '../../../components/short-url-handler/short-url-handler.component';
import { Meta, Title } from '@angular/platform-browser';
import { setDefaultPageHead, setHubPageHead } from '../../../util/seo-utils';
import { ActCatViewEntity } from '../../../models/activity-category-view';
import { HubEntity } from '../../../models/hub';
import { PageEntity } from '../../../models/types';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-hub-about-us',
  templateUrl: './hub-about-us.component.html',
  styleUrls: ['./hub-about-us.component.scss'],
})
export class HubAboutUsComponent implements OnInit {
  @ViewChildren(HubActivityComponent)
  hubActivities: QueryList<HubActivityComponent>;
  @ViewChildren(HubCategoryComponent)
  hubCategories: QueryList<HubCategoryComponent>;

  public readonly noImage = '/assets/img/app/no-image.png';
  public hub = null;
  handler = null;
  public items: ActCatViewEntity[] = [];
  schedule: any;
  notFound = false;
  countryName = '';
  user = null;
  type = EntityType.HUB;
  reported = false;
  @ViewChild('commentsPreview') commentsPreview: CommentsPreviewComponent;
  private settings = null;
  private readonly apiUrl = environment.apiUrl;
  fullSocialLinks: any;
  isFilterOpen: boolean = false;

  constructor(
    private authService: AuthService,
    private httpService: HttpService,
    private appSettings: AppSettings,
    private router: Router,
    private route: ActivatedRoute,
    private _bottomSheet: MatBottomSheet,
    private translate: TranslateService,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    this.settings = this.appSettings.settings;
  }

  get reviewRate() {
    if (!this.commentsPreview) {
      return 0.0;
    }
    return this.commentsPreview.reviewRate;
  }

  get reviewCount() {
    if (!this.commentsPreview) {
      return 0;
    }
    return this.commentsPreview.reviewCount;
  }
  
  openLink(ev: any) {

  }

  ngOnInit(): void {
    i18nIsoCountries.registerLocale(
      require('i18n-iso-countries/langs/en.json')
    );
    i18nIsoCountries.registerLocale(
      require('i18n-iso-countries/langs/es.json')
    );
    localStorage.setItem('return_from_login_url', this.router.url);
    const hubId = this.route.snapshot.queryParams['hubId'];
    this.user = this.authService.getUserProfile();
    if (hubId) {
      this.settings.loadingSpinner = true;
      this.retrieveHub(hubId)
        .then(() => {
          setHubPageHead(this.titleService, this.metaTagService, this.hub);
          this.settings.loadingSpinner = false;
        })
        .catch((error) => {
          setDefaultPageHead(this.titleService, this.metaTagService, true);
          if (error.status === 404) {
            this.notFound = true;
          }
          this.settings.loadingSpinner = false;
          console.error('Error getting hub: ', error);
        });
    } else {
      this.route.paramMap
        .pipe(map((params: ParamMap) => params.get('handler')))
        .subscribe(async (value) => {
          this.handler = value;
          if (this.handler) {
            this.settings.loadingSpinner = true;
            const url = `${this.apiUrl}/hubs/v1/search?querySearch=urlHandler:'${this.handler}' AND active:true`;
            try {
              const response: PageEntity<HubEntity> = await firstValueFrom(this.httpService.doGet(url));
              if (!response.content || response.content.length === 0) {
                this.notFound = true;
                this.settings.loadingSpinner = false;
                return;
              }
              this.hub = response.content[0];
              if (this.hub?.address?.country) {
                console.log(i18nIsoCountries);
                this.countryName = i18nIsoCountries.getName(
                  this.hub.address.country,
                  this.translate.getDefaultLang()
                );
              }
            } catch(error) {
              if (error.status === 404) {
                this.notFound = true;
              }
              console.log('Error: ', error);
            } finally {
              this.settings.loadingSpinner = false;
            }
          } else {
            console.log('Error: hubId not found in query params');
          }
        });
    }
  }

  back(): void {
    this.router.navigateByUrl(`${this.handler}/client-view?hubId=${this.hub.id}`);
  }

  openShare(event: MouseEvent): void {
    event.stopPropagation();
    this._bottomSheet
      .open(SocialShareBottomSheetComponent, {
        panelClass: 'share-bottom-sheet',
        data: {
          url: ShortUrlHandlerComponent.shortHubUrl(this.hub.urlHandler),
          title: this.hub.name,
          description: this.hub.infoText,
          images: [this.hub.image],
          tags: null,
        },
      })
      .afterDismissed()
      .subscribe((result) => {});
  }

  private retrieveHub(hubId: string): any {
    const hubUrl = `${this.apiUrl}/hubs/v1/${hubId}`;
    return firstValueFrom(this.httpService
      .doGet(hubUrl))
      .then((response: HubEntity) => {
        this.hub = response;
        if (this.hub?.address?.country) {
          this.countryName = i18nIsoCountries.getName(
            this.hub.address.country,
            this.translate.getDefaultLang()
          );
        }

        if (
          this.hub &&
          this.hub.scheduleBean &&
          this.hub.scheduleBean.entries
        ) {
          this.schedule = {
            hours: this.hub.scheduleBean.entries,
            daysOff: new Set(this.hub.exceptions),
          };
        }

        if (this.hub && this.hub.socialLinks) {
          this.fullSocialLinks = {};
          Object.keys(this.hub.socialLinks).map((key) => {
            this.fullSocialLinks[key] =
              socialMediaPrefix[key] + this.hub.socialLinks[key];
          });
        }

        this.settings.loadingSpinner = false;
        this.handler = this.hub.urlHandler;
      });
  }
}
