import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { OverlayContainer } from "@angular/cdk/overlay";
import { CustomOverlayContainer } from "./theme/utils/custom-overlay-container";
import { SharedModule } from "./shared/shared.module";
import { PipesModule } from "./theme/pipes/pipes.module";
import { AuthModule } from "./auth/auth.module";
import { routing } from "./app.routing";
import { AppComponent } from "./app.component";
import { PagesComponent } from "./pages/pages.component";
import { NotFoundComponent } from "./pages/errors/not-found/not-found.component";
import { ErrorComponent } from "./pages/errors/error/error.component";
import { AppSettings } from "./app.settings";
import { SidenavComponent } from "./theme/components/sidenav/sidenav.component";
import { VerticalMenuComponent } from "./theme/components/menu/vertical-menu/vertical-menu.component";
import { HorizontalMenuComponent } from "./theme/components/menu/horizontal-menu/horizontal-menu.component";
import { BreadcrumbComponent } from "./theme/components/breadcrumb/breadcrumb.component";
import { FlagsMenuComponent } from "./theme/components/flags-menu/flags-menu.component";
import { FullScreenComponent } from "./theme/components/fullscreen/fullscreen.component";
import { ApplicationsComponent } from "./theme/components/applications/applications.component";
import { MessagesComponent } from "./theme/components/messages/messages.component";
import { UserMenuComponent } from "./theme/components/user-menu/user-menu.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { environment } from "../environments/environment";
import { ComponentsModule } from "./components/componets.module";
import { MatSelectCountryModule } from "@angular-material-extensions/select-country";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig,
} from "ngx-cookieconsent";
import { provideFirebaseApp, initializeApp, } from "@angular/fire/app";
import { provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
//import { getFirestore, provideFirestore } from "@angular/fire/firestore";
import { provideAuth, getAuth } from "@angular/fire/auth";
import { SETTINGS as AUTH_SETTINGS } from "@angular/fire/compat/auth";
import { CreditCardDirectivesModule } from "angular-cc-library";

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: ".tinyall.app",
  },
  position: "bottom",
  theme: "classic",
  palette: {
    popup: {
      background: "#5d6293",
      text: "#fff",
      link: "#ffffff",
    },
    button: {
      background: "#29a6a4",
      text: "#fff",
      border: "transparent",
    },
  },
  type: "info",
  content: {
    href: `${environment.appUrl}/privacy`,
  },
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    RxReactiveFormsModule,
    ReactiveFormsModule,
    SharedModule,
    AuthModule,
    PipesModule,
    routing,
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    //provideFirestore(() => getFirestore()),
    ComponentsModule,
    MatSelectCountryModule.forRoot("en"),
    SweetAlert2Module.forRoot(),
    NgcCookieConsentModule.forRoot(cookieConfig),
    CreditCardDirectivesModule,
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    NotFoundComponent,
    ErrorComponent,
    SidenavComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    FlagsMenuComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    UserMenuComponent,
  ],
  providers: [
    AppSettings,
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    {
      provide: AUTH_SETTINGS,
      useValue: { appVerificationDisabledForTesting: true },
    },
    ScreenTrackingService,
    UserTrackingService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    "/assets/i18n/",
    ".json?random=" + Math.random()
  );
}
