import { Pipe, PipeTransform } from '@angular/core';
import { parseISO, formatDistanceToNow } from 'date-fns';
import esLocale from 'date-fns/locale/es/index.js';
import enLocale from 'date-fns/locale/en-US/index.js';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'timeAgoAlt',
})
export class TimeAgoAltPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(value: Date | string | number): any {
    if (!value) return '';
    let dateValue: Date | number;
    if (typeof value === 'string') {
      dateValue = parseISO(value);
    } else {
      dateValue = value as Date | number;
    }
    return formatDistanceToNow(dateValue, {
      addSuffix: true,
      includeSeconds: true,
      locale: this.translate.getDefaultLang() === 'en' ? enLocale : esLocale,
    });
  }
}
