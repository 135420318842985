import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {RouteConstants} from '../../app.constants';
import {AuthService} from '../../auth/auth.serviceV2';
import UserProfile from '../../models/user-profile-model';
import {ReportAbuseDialogComponent} from '../report-abuse-dialog/report-abuse-dialog.component';
import {YesNoDialogComponent} from '../yes-no-dialog/yes-no-dialog.component';

@Component({
    selector: 'app-report-abuse-button',
    templateUrl: './report-abuse-button.component.html',
    styleUrls: ['./report-abuse-button.component.scss']
})
export class ReportAbuseButtonComponent {

    @Input() user: UserProfile;
    @Input() entityId: any;
    @Input() entityType: string;
    @Input() entityName: string;
    @Input() entity: any;
    @Output() reported: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private dialog: MatDialog,
        private authService: AuthService,
        private router: Router,
        private translate: TranslateService,
    ) {
    }

    report(): void {
        const dialogRef = this.dialog.open(YesNoDialogComponent, {
            minWidth: '240px',
            data: {
                title: this.translate.instant('messages.report_question', {
                    type: this.translate.instant(`entity_type.${this.entityType}`),
                }),
            }
        });
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                if (!await this.authService.isAuthenticated()) {
                    localStorage.setItem('return_url', `${this.router.url}`);
                    this.router.navigate([RouteConstants.auth]).then();
                    return;
                }
                const dialogRef = this.dialog.open(ReportAbuseDialogComponent, {
                    width: '100vw',
                    height: '100vh',
                    maxWidth: '100vw',
                    maxHeight: '100vh',
                    panelClass: 'report-abuse-dialog',
                    data: {
                        entityId: this.entityId,
                        entityType: this.entityType,
                        entityName: this.entityName,
                        user: this.user,
                        entity: this.entity
                    }
                });
                dialogRef.afterClosed().subscribe(_result => {
                    if (_result) {
                        this.reported.emit(true);
                    }
                });
            }
        });
    }
}
