<div class="container" fxLayout="row wrap">
  <a class="link" (click)="goToHub()">
    Hub
  </a>
  <span class="separator">/</span>
  <a *ngIf="categoryId" class="link" (click)="goBack()" translate>
    tinyAll.category
  </a>
  <span *ngIf="categoryId" class="separator">/</span>
  <a class="inactive-link">
    {{ 'entity_type.' + type | translate }}
    <fa-icon class="list-item-menu-icon" [icon]="activityIconMap[type]"></fa-icon>
  </a>
</div>
