<mat-card [id]="getId(product.id, item.variant)" class="item-card" [ngClass]="{'expanded' : selected}">
    <div *ngIf="!selected" (click)="selectItem()" class="closed">
        <mat-card-header class="header">
            <img mat-card-avatar class="avatar" [src]="product.images[0] || noImage" alt="product" />
            <mat-card-title>{{product.name}}</mat-card-title>
            <mat-card-subtitle class="description">{{product.infoText}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="content">
            <mat-card-subtitle fxLayout="row nowrap" fxLayoutAlign="space-around center">
                <span class="variant">{{item.variant}}</span>&nbsp;
                <span class="count"><span translate>productList.quantity</span>: {{item.quantity}}</span>
            </mat-card-subtitle>  
            <mat-card-subtitle class="quantity" fxLayout="row nowrap" fxLayoutAlign="space-around center">
                <span class="unit-price">
                    <span class="unit unit-before" *ngIf="actualVariant.price !== item.price">
                        <span translate>productList.unit</span>: {{item.price | currency:currency:currencySymbols[currency]}}
                        <mat-icon *ngIf="actualVariant.price > item.price">arrow_upward</mat-icon>
                        <mat-icon class="down" *ngIf="actualVariant.price < item.price">arrow_downward</mat-icon>
                        <br>
                    </span>
                    <span class="unit"><span translate>productList.unit</span>: {{actualVariant.price | currency:currency:currencySymbols[currency]}}</span>
                </span>
                <span class="price">
                    <span translate>productList.total</span>: {{getTotal(actualVariant.price, item.quantity) | currency:currency:currencySymbols[currency]}}
                </span>
            </mat-card-subtitle>  
        </mat-card-content>
    </div>

    <div *ngIf="selected" class="opened">
        <mat-card-content class="content">
            <app-swiper-view [images]="getImages()" [square]="true">
            </app-swiper-view>
            <mat-card-header class="header">
                <mat-card-title>{{product.name}}</mat-card-title>
                <mat-card-subtitle class="description">{{product.infoText}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-subtitle class="variant" fxLayout="row nowrap" fxLayoutAlign="space-around center">
                
                <mat-card-subtitle class="variant">{{actualVariant.description}}</mat-card-subtitle>
                
                <mat-form-field class="count" floatLabel='always'>
                    <mat-label><span translate>productList.quantity</span></mat-label>
                    <mat-select [formControl]="quantity" (selectionChange)="quantityChange($event)">
                        <mat-option *ngFor="let quantityValue of quantitySelector" [value]="quantityValue">
                            {{quantityValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                
            </mat-card-subtitle>
            <mat-card-subtitle class="quantity" fxLayout="row nowrap" fxLayoutAlign="space-around center">
                <span class="unit-price">
                    <span class="unit unit-before" *ngIf="actualVariant.price !== item.price">
                        <span translate>productList.unit</span>: {{item.price | currency:currency:currencySymbols[currency]}}
                        <mat-icon *ngIf="actualVariant.price > item.price">arrow_upward</mat-icon>
                        <mat-icon class="down" *ngIf="actualVariant.price < item.price">arrow_downward</mat-icon>
                        <br>
                    </span>
                    <span class="unit"><span translate>productList.unit</span>: {{actualVariant.price | currency:currency:currencySymbols[currency]}}</span>
                </span>
                <span class="price">
                    <span translate>productList.total</span>: {{getTotal(actualVariant.price, selectedForm.get('quantity').value) | currency:currency:currencySymbols[currency]}}
                </span>
            </mat-card-subtitle> 
        </mat-card-content>

        <mat-card-actions fxLayout="row" fxLayoutAlign="space-between center">
            <button *ngIf="!loading" mat-raised-button (click)="close()" class="close">
                <span  translate>tinyAll.close</span>&nbsp;
                <mat-icon>close</mat-icon>
            </button>
            <button *ngIf="!loading" class="remove" color="warn" mat-raised-button (click)="removeFromCart()">
                <span  translate>productList.remove_from_cart</span>&nbsp;
                <mat-icon>delete_forever</mat-icon>
            </button>
            <div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="loading">
                <span class="spinner-text" translate>productList.updating_cart</span>&nbsp;
                <mat-spinner  class="button-spinner" diameter="30"></mat-spinner>
            </div>
            
        </mat-card-actions>
    </div>
</mat-card>