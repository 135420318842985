import { Component, OnInit, ViewChild} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from '../../app.settings';

@Component({
  selector: 'app-azul-payment-form',
  templateUrl: './azul-payment-form.component.html'
})
export class AzulPaymentFormComponent implements OnInit {

  @ViewChild('azulFormEl') azulFormEl;

  azulForm: UntypedFormGroup;
  settings;

  constructor(
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private appSettings: AppSettings,
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.azulForm = this.fb.group({
      MerchantId: ['', Validators.required],
      MerchantName: ['', Validators.required],
      MerchantType: ['', Validators.required],
      CurrencyCode: ['', Validators.required],
      OrderNumber: ['', Validators.required],
      Amount: ['', Validators.required],

      ApprovedUrl: ['', Validators.required],
      DeclinedUrl: ['', Validators.required],
      CancelUrl: ['', Validators.required],

      UseCustomField1: ['', Validators.required],
      CustomField1Label: ['', Validators.required],
      CustomField1Value: ['', Validators.required],

      UseCustomField2: ['', Validators.required],
      CustomField2Label: ['', Validators.required],
      CustomField2Value: ['', Validators.required],

      Locale: ['', Validators.required],
      ITBIS: ['', Validators.required],
      AuthHash: ['', Validators.required],
    });
  }

  azulPay(requestResponse: any) {
    this.settings.loadingSpinner = true;
    const { payload, id, totalPrice } = requestResponse;
    const { merchantID, merchantName, merchantType, approvedUrl, declinedUrl, cancelUrl, requestHash, itbis, useCustomField1, customField1Label, customField1Value, customField2Label, customField2Value } = payload;
    const price = totalPrice.replace('.','');

    this.azulForm.patchValue({ MerchantId: merchantID });
    this.azulForm.patchValue({ MerchantName: merchantName });
    this.azulForm.patchValue({ MerchantType: merchantType });
    this.azulForm.patchValue({ CurrencyCode: '$' });
    this.azulForm.patchValue({ OrderNumber: id });
    this.azulForm.patchValue({ Amount: price });
    this.azulForm.patchValue({ ApprovedUrl: approvedUrl });
    this.azulForm.patchValue({ DeclinedUrl: declinedUrl });
    this.azulForm.patchValue({ CancelUrl: cancelUrl });
    this.azulForm.patchValue({ UseCustomField1: useCustomField1 });
    this.azulForm.patchValue({ CustomField1Label: customField1Label });
    this.azulForm.patchValue({ CustomField1Value: customField1Value });
    this.azulForm.patchValue({ UseCustomField2: '1' });
    this.azulForm.patchValue({ CustomField2Label: customField2Label });
    this.azulForm.patchValue({ CustomField2Value: customField2Value });
    this.azulForm.patchValue({ Locale: this.translate.getDefaultLang() === 'en' ? 'EN' : 'ES' });
    this.azulForm.patchValue({ ITBIS: itbis });
    this.azulForm.patchValue({ AuthHash: requestHash });

    if(this.azulForm.invalid) {
      this.settings.loadingSpinner = false;
      return;
    }
    this.azulFormEl.nativeElement.submit();
  }
}
