<mat-expansion-panel [id]="id" [(expanded)]="isOpen" (opened)="onItemOpen()">
    <mat-expansion-panel-header>
        <div *ngIf="!isOpen" class="closed">
            <mat-card-header class="header">
                <img mat-card-avatar class="avatar" [src]="item.image || noImage" alt="dish"/>
                <mat-card-title>{{item.name}}</mat-card-title>
                <mat-card-subtitle class="description">{{item.variantName}}</mat-card-subtitle>
                <mat-card-subtitle class="count-container" fxLayout="row nowrap" fxLayoutAlign="space-around center">
                    <span class="count"><span translate>labels.quantity</span>: {{item.quantity}}</span>
                </mat-card-subtitle>  
            </mat-card-header>
            <mat-card-subtitle class="pricing" fxLayout="row nowrap" fxLayoutAlign="space-around center">
                <span class="unit-price">
                    <span class="unit"><span translate>labels.unit</span>: {{item.price | currency}}</span>
                </span>
                <span class="price">
                    <span translate>labels.total</span>: {{getTotal(item.price, item.quantity) | currency}}
                </span>
            </mat-card-subtitle>  
        </div>
    </mat-expansion-panel-header>
    
    <mat-card class="item-card">
        <div class="opened">
            <mat-card-content class="content">
                <app-swiper-view [images]="[item.image || noImage]" [square]="true">
                </app-swiper-view>
                <mat-card-header class="header">
                    <mat-card-title>{{item.name}}</mat-card-title>
                    <mat-card-subtitle class="description">{{item.description}}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-subtitle class="variant" fxLayout="row nowrap" fxLayoutAlign="space-around center">
                    <mat-card-subtitle class="variant">{{item.variantName}}</mat-card-subtitle>
                    <mat-form-field class="count" floatLabel='always'>
                        <mat-label><span translate>labels.quantity</span></mat-label>
                        <mat-select [value]="item.quantity" (selectionChange)="quantityChange($event)">
                            <mat-option *ngFor="let quantity of quantitySelector" [value]="quantity">
                                {{quantity}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </mat-card-subtitle>
                <mat-card-subtitle class="quantity" fxLayout="row nowrap" fxLayoutAlign="space-around center">
                    <span class="unit-price">
                        <span class="unit"><span translate>labels.unit</span>: {{item.price | currency}}</span>
                    </span>
                    <span class="price">
                        <span translate>labels.total</span>: {{getTotal(item.price, item.quantity) | currency}}
                    </span>
                </mat-card-subtitle>  
            </mat-card-content>
            <mat-card-actions fxLayout="row" fxLayoutAlign="space-between center">
                <button class="remove" color="warn" mat-raised-button (click)="remove()">
                    <span  translate>buttons.remove</span>&nbsp;
                    <mat-icon>delete_forever</mat-icon>
                </button>
            </mat-card-actions>
        </div>
    </mat-card>

</mat-expansion-panel>
