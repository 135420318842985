import { Component, Inject } from '@angular/core';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-activity-sort',
  templateUrl: './activity-sort.component.html',
  styleUrls: ['./activity-sort.component.scss'],
})
export class ActivitySortComponent {
  form: UntypedFormGroup;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<ActivitySortComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private fb: UntypedFormBuilder
  ) {
    this.form = this.fb.group({
      sort: [data.form.sort],
    });
  }

  cancel(): void {
    this._bottomSheetRef.dismiss();
  }

  apply(): void {
    this._bottomSheetRef.dismiss(this.form.getRawValue());
  }
}
