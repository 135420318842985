import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Country } from '@angular-material-extensions/select-country';
import { DatetimeAdapter } from '@mat-datetimepicker/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom, Observable, of } from 'rxjs';
import { debounceTime, map, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppSettings } from '../../../app.settings';
import { HttpService } from '../../../service/http.service';
import { ImageService } from '../../../service/image.service';
import { BaseFormComponent } from '../../baseform.component';
import { RouteConstants } from '../../../app.constants';
import DAYS from '../../../util/days';
import {
  getCountries,
  getCountryFromAlpha3Code,
} from '../../../util/countries';
import { RichTextEditorComponent } from '../../../components/rich-text-editor/rich-text-editor.component';
import REGEX from '../../../util/regex';
import { setDefaultPageHead } from '../../../util/seo-utils';
import { Meta, Title } from '@angular/platform-browser';
import {
  CreateHubCommand,
  HubEntity,
  UpdateHubCommand,
} from '../../../models/hub';
import { PageEntity } from '../../../models/types';
import { UserAdditionalInfoService } from '../../authentication/login/user-additional-info.service';
import { environment } from '../../../../environments/environment';
import { HubCreationStatus } from '../../../models/hub-creation-status-enum';
import { WorkflowService } from '../../../service/hub-workflow.service';
import { ShortUrlHandlerComponent } from '../../../components/short-url-handler/short-url-handler.component';
import { isImage } from '../../../util/image-utils';

@Component({
  selector: 'app-hub-management',
  templateUrl: './hub-management.component.html',
  styleUrls: ['./hub-management.component.scss'],
})
export class HubManagementComponent
  extends BaseFormComponent
  implements OnInit
{
  form: UntypedFormGroup;
  addressForm: UntypedFormGroup;

  schedule: UntypedFormGroup;
  lastUpdate = new Date();
  daysOff = new Set<string>();
  canShowMap = false;

  predefinedCountries: Country[] = [];
  selectedCountry = null;

  readonly urlRegx = REGEX.URL;
  readonly maxImageSize = environment.maxImageSizeInMB;

  hub: HubEntity;

  maxSizeErrors = {
    imageMaxSizeError: false,
    splashMaxSizeError: false,
    iconMaxSizeError: false,
  };

  constructor(
    private fb: UntypedFormBuilder,
    protected router: Router,
    protected httpService: HttpService,
    private userAdditionalInfoService: UserAdditionalInfoService,
    private workflowService: WorkflowService,
    public _adapter: DatetimeAdapter<Date>,
    protected appSettings: AppSettings,
    protected snackBar: MatSnackBar,
    protected imageService: ImageService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    super(httpService, appSettings, snackBar, imageService, router, dialog, translate);
    _adapter.setLocale(translate.getDefaultLang());
    (_adapter as any)._delegate.setLocale(translate.getDefaultLang());
  }

  async ngOnInit() {
    setDefaultPageHead(this.titleService, this.metaTagService, false);
    this.predefinedCountries = getCountries(this.translate.getDefaultLang());
    this.settings.loadingSpinner = true;

    const isUserReady = await this.userAdditionalInfoService.check(this.router.url);
    if (!isUserReady) return;

    this.createForm();
    await this.getHub();
    await this.workflowService.check(this.hub, this.router.url);
    this.settings.loadingSpinner = false;
  }

  async getHub() {
    this.settings.loadingSpinner = true;
    try {
      this.hub = await firstValueFrom(
        this.httpService.doGet(`${this.apiUrl}/hubs/v1`)
      );
      this.hubId = this.hub.id;
      if (this.hubId) {
        this.urlHandler.disable();
      }
      this.selectedCountry = this.hub?.address?.country
        ? getCountryFromAlpha3Code(
            this.hub.address.country,
            this.translate.getDefaultLang()
          )
        : null;
      this.setForm(this.hub);
    } catch (error) {
      console.log('Hub not found: ', error);
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  get image() {
    return this.form.get('image');
  }

  get name() {
    return this.form.get('name');
  }

  get urlHandler() {
    return this.form.get('urlHandler');
  }

  get email() {
    return this.form.get('email');
  }

  get phoneNumber() {
    return this.form.get('phoneNumber');
  }

  get location() {
    return this.form.get('location');
  }

  get country() {
    return this.addressForm.get('country') as UntypedFormControl;
  }

  get stateOrProvidence() {
    return this.addressForm.get('stateOrProvidence') as UntypedFormControl;
  }

  get city() {
    return this.addressForm.get('city') as UntypedFormControl;
  }

  get address() {
    return this.addressForm.get('address');
  }

  get socialLinks() {
    return this.form.get('socialLinks') as UntypedFormGroup;
  }

  get customLinks() {
    return this.form.get('customLinks') as UntypedFormArray;
  }

  get active() {
    return this.form.get('active');
  }

  get unlisted() {
    return this.form.get('unlisted');
  }

  get adultContent() {
    return this.form.get('adultContent');
  }

  get clientViewUrl() {
    if (!this.hubId || !this.hub) {
      return false;
    }
    return ShortUrlHandlerComponent.shortHubUrl(this.hub.urlHandler);
  }

  createForm() {
    this.schedule = this.fb.group({
      SUNDAY: this.getDayForm(),
      MONDAY: this.getDayForm(),
      TUESDAY: this.getDayForm(),
      WEDNESDAY: this.getDayForm(),
      THURSDAY: this.getDayForm(),
      FRIDAY: this.getDayForm(),
      SATURDAY: this.getDayForm(),
    });

    this.addressForm = this.fb.group({
      country: ['', Validators.required],
      stateOrProvidence: ['', Validators.required],
      city: ['', Validators.required],
      address: ['', Validators.required],
      address2: [''],
      zipCode: [''],
      locationHint: [''],
    });

    this.form = this.fb.group({
      id: [''],
      userId: [''],
      name: ['', Validators.required],
      urlHandler: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.-]+$'),
          Validators.maxLength(30),
          Validators.minLength(3),
        ],
        this.existingValidator(),
      ],
      email: ['', [Validators.email, Validators.required]],
      phoneNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^(([1-9][0-9]{2})|[1-9][0-9]{2})[1-9][0-9]{2}[0-9]{4}$'
          ),
        ],
      ],
      image: [null, Validators.required],
      icon: [null],
      infoText: [''],
      location: [''],
      active: [true],
      address: this.addressForm,
      tags: this.fb.array([]),
      socialLinks: this.fb.group({
        facebook: [''],
        twitter: [''],
        linkedin: [''],
        instagram: [''],
        snapchat: [''],
        pinterest: [''],
        reddit: [''],
        whatsapp: [''],
        tumblr: [''],
        telegram: [''],
        youtube: [''],
        medium: [''],
      }),
      customLinks: this.fb.array([]),
      createdOn: [''],
      updatedOn: [''],
      scheduleBean: this.fb.group({
        durationInMinutes: [60],
      }),
      unlisted: [false],
      adultContent: [false],
    });
  }

  setForm(response: HubEntity) {
    this.lastUpdate = new Date(response.createdOn);

    this.form.patchValue({ id: response.id });
    this.form.patchValue({ userId: response.userId });
    this.form.patchValue({ name: response.name });
    this.form.patchValue({ urlHandler: response.urlHandler });
    this.form.patchValue({ email: response.email });
    this.form.patchValue({ phoneNumber: response.phoneNumber });
    this.form.patchValue({ image: response.image });
    this.form.patchValue({ icon: response.icon });
    this.form.patchValue({ infoText: response.infoText });
    this.form.patchValue({ location: response.location });
    this.form.patchValue({ active: response.active });
    this.form.patchValue({ address: response.address });
    this.form.patchValue({ socialLinks: response.socialLinks });
    this.form.patchValue({ createdOn: response.createdOn });
    this.form.patchValue({ updatedOn: response.updatedOn });
    this.form.patchValue({ unlisted: response.unlisted });
    this.form.patchValue({ adultContent: response.adultContent });

    if (response.adultContent) {
      this.adultContent.disable();
    }

    if (response.scheduleBean && response.scheduleBean.entries) {
      this.setScheduleTime(response.scheduleBean.entries);
    }
    this.daysOff = new Set(response.exceptions);

    const TAGS = this.getTags();
    TAGS.clear();

    for (const TAG of response.tags) {
      TAGS.push(this.fb.control(TAG));
    }

    const customLinksForm = this.customLinks;
    customLinksForm.clear();

    for (const key of Object.keys(response.customLinks || {})) {
      customLinksForm.push(
        this.fb.group({
          name: this.fb.control(key, [
            Validators.required,
            RxwebValidators.unique(),
          ]),
          value: this.fb.control(response.customLinks[key], [
            Validators.required,
            Validators.pattern(this.urlRegx),
          ]),
        })
      );
    }

    this.descriptionImages = RichTextEditorComponent.getImagesSrcFromHtmlString(
      response.infoText
    );

    this.form.markAsPristine();
    this.schedule.markAsPristine();
  }

  showMap() {
    this.canShowMap = true;
  }

  removeLocation() {
    this.form.patchValue({ location: '' });
    this.form.markAsDirty();
  }

  setLocation() {
    this.settings.loadingSpinner = true;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            coordinates: [position.coords.latitude, position.coords.longitude],
            type: 'Point',
          };
          this.form.patchValue({ location: location });
          this.form.markAsDirty();
          this.settings.loadingSpinner = false;
        },
        (error) => {
          this.settings.loadingSpinner = false;
          console.error('Geo position not found');
        }
      );
    } else {
      this.settings.loadingSpinner = false;
      console.error('Geo location not found');
    }
  }

  dirtyImages(): boolean {
    return (
      this.form.get('image').dirty ||
      this.form.get('icon').dirty
    );
  }

  deleteDirtyImages() {
    if (this.form.get('image').dirty) {
      this.deleteImage(this.form.get('image').value);
    }

    if (this.form.get('icon').dirty) {
      this.deleteImage(this.form.get('icon').value);
    }
  }

  back() {
    if (this.hubId && this.dirtyImages()) {
      this.deleteDirtyImages();
    }
    this.router.navigateByUrl(`${RouteConstants.hub}/owner`).then();
  }

  onSubmit() {
    if (this.hubId) {
      this.updateHub().then();
    } else {
      this.createHub().then();
    }
  }

  async createHub() {
    this.settings.loadingSpinner = true;

    const rawValues = this.form.getRawValue();
    rawValues.socialLinks = this.cleanEmptyMap(rawValues.socialLinks);
    rawValues.customLinks = this.arrayToMap(rawValues.customLinks);

    rawValues.scheduleBean['entries'] = this.getEntries(this.schedule);
    rawValues.exceptions = Array.from(this.daysOff);
    rawValues.urlHandler = rawValues.urlHandler.toLowerCase();

    rawValues.infoText = await RichTextEditorComponent.uploadImages(
      rawValues.infoText,
      this.descriptionImages,
      this.imageService
    );

    const {
      name,
      urlHandler,
      email,
      userId,
      phoneNumber,
      image,
      address,
      active,
      unlisted,
      adultContent,
      exceptions,
      tags,
      socialLinks,
      customLinks,
      location,
      scheduleBean,
      icon,
      infoText,
    } = rawValues;

    const body: CreateHubCommand = {
      name,
      urlHandler,
      email,
      userId,
      phoneNumber,
      image,
      address,
      active,
      unlisted,
      adultContent,
      exceptions,
      tags,
      socialLinks,
      customLinks,
      location,
      scheduleBean,
      icon,
      infoText,
      creationStatus: HubCreationStatus.NONE,
      // supportedPaymentTypes: [],
      // bankTransactionInfo,
    };

    try {
      const response: HubEntity = await firstValueFrom(
        this.httpService.doPost(`${this.apiUrl}/hubs/v1`, body)
      );
      this.hubId = response.id;
      if (this.hubId) {
        this.urlHandler.disable();
      }
      this.setForm(response);
      this.showSnackBar('Operation successful ', 'OK', 2000);
    } catch (error) {
      this.showSnackBar('Error Processing Your Request', 'OK', 2000);
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  async updateHub() {
    this.settings.loadingSpinner = true;
    const rawValues = this.form.getRawValue();
    rawValues.socialLinks = this.cleanEmptyMap(rawValues.socialLinks);
    rawValues.customLinks = this.arrayToMap(rawValues.customLinks);

    rawValues.scheduleBean['entries'] = this.getEntries(this.schedule);
    rawValues.exceptions = Array.from(this.daysOff);
    rawValues.infoText = await RichTextEditorComponent.uploadImages(
      rawValues.infoText,
      this.descriptionImages,
      this.imageService
    );

    const {
      name,
      urlHandler,
      email,
      userId,
      phoneNumber,
      image,
      address,
      active,
      unlisted,
      adultContent,
      exceptions,
      tags,
      socialLinks,
      customLinks,
      location,
      scheduleBean,
      icon,
      infoText,
    } = rawValues;

    const { supportedPaymentTypes, bankTransactionInfo } = this.hub;

    const body: UpdateHubCommand = {
      id: this.hubId,
      name,
      urlHandler,
      email,
      userId,
      phoneNumber,
      image,
      address,
      active,
      unlisted,
      adultContent,
      exceptions,
      tags,
      socialLinks,
      customLinks,
      location,
      scheduleBean,
      icon,
      infoText,
      supportedPaymentTypes,
      bankTransactionInfo,
      creationStatus: HubCreationStatus.NONE,
    };

    try {
      const response: HubEntity = await firstValueFrom(
        this.httpService.doPut(`${this.apiUrl}/hubs/v1`, body)
      );
      this.hubId = response.id;
      this.setForm(response);
      this.showSnackBar('Operation Successful', 'OK', 2000);
    } catch (error) {
      this.showSnackBar('Error Processing Your Request', 'OK', 2000);
      console.error('Error updating hub: ', error);
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  hasHubId(): boolean {
    return !!this.hubId;
  }

  onCountrySelected(country: Country) {
    this.country.setValue(country.alpha3Code);
    this.selectedCountry = country;
    this.addressForm.markAsDirty();
  }

  getTags(): UntypedFormArray {
    return this.form.get('tags') as UntypedFormArray;
  }

  cleanEmptyMap(linksMap) {
    const cleanMap = {};
    for (const key of Object.keys(linksMap)) {
      const value = linksMap[key].trim();
      if (value) {
        cleanMap[key] = value;
      }
    }
    return cleanMap;
  }

  arrayToMap(array: any[]) {
    const tMap = {};
    for (const obj of array) {
      if (obj.value && obj.value.trim() && obj.name) {
        tMap[obj.name] = obj.value.trim();
      }
    }
    return tMap;
  }

  // Scheduler picker
  getDayForm(): UntypedFormGroup {
    return this.fb.group({
      from: [null],
      to: [null],
    });
  }

  setSchedule(entries: any[]) {
    const LAST_UPDATE_DATE = this.lastUpdate.toISOString().split('T')[0];

    for (const ENTRY of entries) {
      const CONTROL_DAY = this.schedule.get(ENTRY.dayOfWeek);
      if (ENTRY.intervals && ENTRY.intervals.length > 0) {
        const INTERVAL = ENTRY.intervals[0];
        const SPLITTED_START = INTERVAL.start.split('T')[1].split(':');
        const SPLITTED_END = INTERVAL.end.split('T')[1].split(':');
        const START = new Date(
          `${LAST_UPDATE_DATE}T${SPLITTED_START[0]}:${SPLITTED_START[1]}:00.000Z`
        );
        const END = new Date(
          `${LAST_UPDATE_DATE}T${SPLITTED_END[0]}:${SPLITTED_END[1]}:00.000Z`
        );
        CONTROL_DAY.get('from').setValue(START.toLocaleTimeString());
        CONTROL_DAY.get('to').setValue(END.toLocaleTimeString());
      }
    }
  }

  setScheduleTime(entries: any[]) {
    for (const ENTRY of entries) {
      const CONTROL_DAY = this.schedule.get(ENTRY.dayOfWeek);
      if (ENTRY.intervals && ENTRY.intervals.length > 0) {
        const INTERVAL = ENTRY.intervals[0];

        const START = new Date(INTERVAL.start).toLocaleTimeString();
        const END = new Date(INTERVAL.end).toLocaleTimeString();

        const SPLITTED_START = START.split(':');
        const SPLITTED_END = END.split(':');

        CONTROL_DAY.get('from').setValue(`${SPLITTED_START[0]}:${SPLITTED_START[1]} ${START.split(' ')[1]}`);
        CONTROL_DAY.get('to').setValue(`${SPLITTED_END[0]}:${SPLITTED_END[1]} ${END.split(' ')[1]}`);
      }
    }
  }

  getEntries(formEntries: UntypedFormGroup) {
    const ENTRIES = [];
    for (const DAY of DAYS) {
      const ENTRY = formEntries.get(DAY);
      if (ENTRY) {
        const FROM = ENTRY.get('from').value;
        const TO = ENTRY.get('to').value;
        if (FROM && TO) {
          ENTRIES.push({
            dayOfWeek: DAY,
            intervals: [
              {
                start: new Date(`January 01, 2000 ${FROM}`).toISOString(),
                end: new Date(`January 01, 2000 ${TO}`).toISOString(),
              },
            ],
          });
        }
      }
    }
    return ENTRIES;
  }

  // Deprecated
  getFullTimeFormat(date): string {
    const HOUR = date.getUTCHours();
    const MINUTES = date.getUTCMinutes();
    let HOUR_TEXT = String(HOUR);
    let MINUTES_TEXT = String(MINUTES);
    if (HOUR < 10) {
      HOUR_TEXT = '0' + HOUR_TEXT;
    }
    if (MINUTES < 10) {
      MINUTES_TEXT = '0' + MINUTES_TEXT;
    }
    return `${HOUR_TEXT}:${MINUTES_TEXT}:00`;
  }

  onDaysOffChange() {
    this.schedule.markAsDirty();
  }

  // END Schedule Picker

  infoValidateError(): boolean {
    return (
      this.name.hasError('required') ||
      this.urlHandler.hasError('required') ||
      this.urlHandler.hasError('pattern') ||
      this.urlHandler.hasError('maxlength') ||
      this.urlHandler.hasError('minlength') ||
      this.urlHandler.hasError('existing') ||
      this.email.hasError('email') ||
      this.email.hasError('required') ||
      this.phoneNumber.hasError('required')
    );
  }

  addressValidateError(): boolean {
    return (
      this.country.hasError('required') ||
      this.stateOrProvidence.hasError('required') ||
      this.city.hasError('required') ||
      this.address.hasError('required')
    );
  }

  isEmptyInputValue(value: any): boolean {
    return value === null || value.length === 0;
  }

  existingValidator(initial: string = ''): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Promise<{ [key: string]: any } | null>
      | Observable<{ [key: string]: any } | null> => {
      if (this.isEmptyInputValue(control.value)) {
        return of(null);
      } else if (control.value === initial) {
        return of(null);
      } else {
        return control.valueChanges.pipe(
          debounceTime(300),
          take(1),
          switchMap((_) =>
            this.getUrlHandler(control.value.toLowerCase()).pipe(
              map((handler) => {
                return handler && handler.content && handler.content.length > 0
                  ? { existing: { value: control.value } }
                  : null;
              })
            )
          )
        );
      }
    };
  }

  getUrlHandler(value): Observable<PageEntity<HubEntity>> {
    return this.httpService.doGet(
      `${this.apiUrl}/hubs/v1/search?querySearch=urlHandler:'${value}'`
    );
  }

  canDelete() {
    super.canDelete('titles.delete_hub', 'messages.delete_hub', async () => {
      try {
        this.settings.loadingSpinner = true;
        await firstValueFrom(
          this.httpService.doDelete(`${this.apiUrl}/hubs/v1/${this.hubId}`)
        );

        this.showSnackBar(
          this.translate.instant('messages.hub_deleted'),
          'OK',
          2000
        );
        this.back();
      } catch (error) {
        this.showSnackBar(
          this.translate.instant('messages.hub_delete_error'),
          'OK',
          2000
        );
      } finally {
        this.settings.loadingSpinner = false;
      }
    });
  }

  onSelectedImage(file: FileList, form: UntypedFormGroup, field: string) {
    if(!isImage(file.item(0).name)) {
      return;
    }
    this.settings.loadingSpinner = true;
    const fileSize = file.item(0).size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb > this.maxImageSize) {
      this.maxSizeErrors[field] = true;
      this.settings.loadingSpinner = false;
      return;
    } else {
      this.maxSizeErrors[field] = false;
    }

    super.onSelectedImage(file, form, field);
  }
}
