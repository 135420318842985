<div fxLayout="row" *ngIf="title">
    <h2 class="title" translate>{{title}}</h2>
</div>

<mat-card class="card-container">
    <mat-card-content>
        <mat-list>
            <mat-list-item>
                <div fxLayout="row" fxLayoutAlign="start center" class="row">
                    <div fxFlex="50" class="field-name">({{quantity}}) {{itemLabel | translate}}:</div>
                    <div fxFlex="50" class="value">{{itemPrice | currency:currency:currencySymbols[currency]}} <span *ngIf="quantity > 1"><br>({{getTotal() | currency:currency:currencySymbols[currency]}})</span></div>
                </div>
            </mat-list-item>
            <mat-divider></mat-divider>

            <ng-container *ngFor="let answer of getQuestionRows()">
                <mat-list-item>
                    <div fxLayout="row" fxLayoutAlign="start center" class="row">
                        <div fxFlex="50" class="field-name">{{answer.name}}:</div>
                        <div fxFlex="50" class="value">{{answer.value | currency:currency:currencySymbols[currency]}}</div>
                    </div>
                </mat-list-item>
                <mat-divider></mat-divider>
            </ng-container>

            <ng-container *ngFor="let additionalCharge of additionalCharges | keyvalue">
                <mat-list-item>
                    <div fxLayout="row" fxLayoutAlign="start center" class="row">
                        <div fxFlex="50" class="field-name">{{additionalCharge.key}}:</div>
                        <div fxFlex="50" class="value">{{additionalCharge.value | currency:currency:currencySymbols[currency]}}</div>
                    </div>
                </mat-list-item>
                <mat-divider></mat-divider>
            </ng-container>

            <ng-container *ngIf="promoCodeEntity">
                <div class="flex mt-3 mb-4">
                    <div class="w-full bg-primary text-white font-bold rounded text-center mt-1.5 p-3 h-11 text-base">{{promoCodeEntity.code}}</div>
                </div>
                <mat-list-item class="mt-[-15px]">
                    <div fxLayout="row" fxLayoutAlign="start center" class="row">
                        <div fxFlex="50" class="field-name">{{'labels.promo_percentage' | translate:{ percentage: promoCodeEntity.discount } }}:</div>
                        <div fxFlex="50" class="value">-{{getPromoDiscount() || 0 | currency:currency:currencySymbols[currency]}}</div>
                    </div>
                </mat-list-item>
                <mat-divider></mat-divider>
            </ng-container>

            <mat-list-item class="last">
                <div fxLayout="row" fxLayoutAlign="start center" class="row">
                    <div fxFlex="50" class="field-name">{{'labels.total' | translate}}:</div>
                    <div fxFlex="50" class="value">{{totalPrice | currency:currency:currencySymbols[currency]}}</div>
                </div>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
</mat-card>
