import { Component, Inject } from '@angular/core';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { DatetimeAdapter } from '@mat-datetimepicker/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-activity-filter',
  templateUrl: './activity-filter.component.html',
  styleUrls: ['./activity-filter.component.scss'],
})
export class ActivityFilterComponent {
  form: UntypedFormGroup;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<ActivityFilterComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private fb: UntypedFormBuilder,
    public _adapter: DatetimeAdapter<Date>,
    private translate: TranslateService
  ) {
    _adapter.setLocale(translate.getDefaultLang());
    (_adapter as any)._delegate.setLocale(translate.getDefaultLang());
    this.form = this.fb.group({
      ACTIVITY_EVENT: [data.form.ACTIVITY_EVENT],
      ACTIVITY_INFO: [data.form.ACTIVITY_INFO],
      ACTIVITY_PRODUCT_LISTING: [data.form.ACTIVITY_PRODUCT_LISTING],
      ACTIVITY_REQUEST: [data.form.ACTIVITY_REQUEST],
      ACTIVITY_RESERVATION: [data.form.ACTIVITY_RESERVATION],
      ACTIVITY_MENU: [data.form.ACTIVITY_MENU],
      CATEGORY: [data.form.CATEGORY],
      active: [data.form.active],
      includeInMenu: [data.form.includeInMenu],
      dateFrom: [data.form.dateFrom],
      dateTo: [data.form.dateTo],
    });
  }

  cancel(): void {
    this._bottomSheetRef.dismiss();
  }

  apply(): void {
    this._bottomSheetRef.dismiss(this.form.getRawValue());
  }

  isFormInvalid(): boolean {
    const from = this.form.get('dateFrom').value;
    const to = this.form.get('dateTo').value;

    if (from && !this._adapter.isValid(from)) {
      return true;
    } else if (to && !this._adapter.isValid(to)) {
      return true;
    }
    return false;
  }

  clearField(field: string, event: MouseEvent) {
    event.stopPropagation();
    this.form.get(field).setValue(null);
  }

  dateChangeFrom($event: any) {
    const from = this.form.get('dateFrom').value;
    const to = this.form.get('dateTo').value;

    if (
      from &&
      this._adapter.isValid(from) &&
      to &&
      this._adapter.isValid(to)
    ) {
      if (this._adapter.compareDate(from, to) > 0) {
        this.form.get('dateTo').setValue(from);
      }
    }
  }

  dateChangeTo($event: any) {
    const from = this.form.get('dateFrom').value;
    const to = this.form.get('dateTo').value;

    if (
      from &&
      this._adapter.isValid(from) &&
      to &&
      this._adapter.isValid(to)
    ) {
      if (this._adapter.compareDate(from, to) > 0) {
        this.form.get('dateFrom').setValue(to);
      }
    }
  }

  changeIndeterminateCheck(field: string) {
    const value = this.form.get(field).value;
    let newValue = null;
    if (value) {
      newValue = false;
    } else if(value === null) {
      newValue = true;
    }
    this.form.get(field).setValue(newValue);
  }
}
