import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {UntypedFormControl, UntypedFormArray, UntypedFormBuilder} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import { HttpService } from '../../../../service/http.service';
import { environment } from '../../../../../environments/environment';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent, MatChipSelectionChange} from '@angular/material/chips';
import SEPARATOR_KEYS_CODES from '../../../../util/separator-keys-codes';

@Component({
  selector: 'app-hub-tag-picker',
  templateUrl: './hub-tag-picker.component.html',
  styleUrls: ['./hub-tag-picker.component.scss']
})
export class HubTagPickerComponent implements OnInit {

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  @Input() items: UntypedFormArray;
  @Input() max = 1000;
  @Output() onTagChange: EventEmitter<any> = new EventEmitter();

  private readonly apiUrl = environment.apiUrl;
  private readonly validateTag = /^[ña-zÑA-Z0-9\-]+$/;
  private readonly validateAdd = /[^0-9ña-z\-]/gi;
  tag = new UntypedFormControl();
  filteredOptions: Observable<string[]>;
  separatorKeysCodes = SEPARATOR_KEYS_CODES;

  constructor(
    private httpService: HttpService,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.filteredOptions = this.tag.valueChanges.pipe(
        startWith(null),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(value => this._filter(value)),
      );
  }

  validateKey(event: any): void {
    if (!event.data || typeof event.data !== 'string') {
      return;
    }
    if (!this.validateTag.test(event.data)) {
      this.add({
        input: event.target,
        value: event.target.value
      } as MatChipInputEvent);
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    if (!event.value || !event.value.trim()) {
      return;
    }
    const value = event.value.replace(this.validateAdd, '');
    if (!value) {
      return;
    }
    
    const readyValue = value.toLocaleLowerCase();
    if (this.validateUnique(readyValue) && !this.items.disabled) { 
      this.items.push(this.fb.control(readyValue));
      this.items.markAsDirty();
      this.tagChange();
    }
   
    if (input) {
      input.value = '';
    }

    this.tag.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.items.controls.pop();
    const value = event.option.viewValue.toLocaleLowerCase();
    if (this.validateUnique(value) && !this.items.disabled) { 
      this.items.push(this.fb.control(value));
      this.items.markAsDirty();
      this.tagInput.nativeElement.value = '';
      this.tag.setValue(null);
      this.tagChange();
      return; 
    }

    this.tagInput.nativeElement.value = '';
    this.tag.setValue(null);

  }

  remove(index: number): void {
    this.tagInput.nativeElement.value = '';
    this.tag.setValue(null);
    if (this.items.disabled) {
      return;
    }
    this.items.removeAt(index);
    this.items.markAsDirty();
    for (const control of this.items.controls) {
      control.updateValueAndValidity();
    }
    this.tagChange();
  }

  cleanTag() {
    if (this.items.disabled) {
      return;
    }
    this.items.clear();
    this.items.markAsDirty();
    this.items.updateValueAndValidity();
    this.tagChange();
  }

  validateUnique(value: string) {
    for (const control of this.items.controls) {
      if (control.value === value) { return false; }
    }
    return true;
  } 

  tagChange() {
    this.onTagChange.emit(this.items);
  }

  private async _filter(value: string) {
    if (!value) {
      return [];
    }
    const filterValue = value.toLowerCase();

    const response = await this.httpService
    .doGet(`${this.apiUrl}/hubs/v1/search?querySearch=tags%23'${filterValue}*' AND unlisted:false AND active:true&page=0&size=20`)
    .toPromise();
    
    if (response && response.content) {
      const tags = new Set();
      for (const ITEM of response.content) {
        for (const TAG of ITEM.tags) {
          const lowerTag = TAG.toLowerCase();
          if (lowerTag.startsWith(filterValue) && !this.items.getRawValue().includes(lowerTag)) {
            tags.add(lowerTag);
          }
        }
      }
      return Array.from(tags) as string[];
    } else {
      return [];
    }
  }

  panelOpened() {
    const el: any = document.getElementsByClassName('cdk-overlay-pane')[0];
    if (el) {
      el.classList.add('product-tag-picker-panel-overlay');
    }
  }

  panelClosed() {
    const el: any = document.getElementsByClassName('cdk-overlay-pane')[0];
    if (el) {
      el.classList.remove('product-tag-picker-panel-overlay');
    }
  }
}
