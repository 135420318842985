<div fxLayout="row">
    <h2 class="title" translate>titles.sections</h2>
</div>
<div class="list-container">
    <div fxLayout="column" fxLayoutGap="20px">
        <mat-accordion *ngIf="sectionList.length > 0" [multi]="true">
            <div class="flex-p" 
                *ngFor="let item of sectionList; let i = index;">
                <mat-card [id]="'section'+i" class="section-container">
                    <mat-card-content>
                        <mat-expansion-panel expanded="'true'" (opened)="onSectionOpen('section'+i)" (closed)="dishesView.closeAll();">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{item.sectionName}}
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <mat-card-header class="header">
                                <mat-card-title>{{item.sectionName}}</mat-card-title>
                                <mat-card-subtitle class="description">{{item.sectionDescription}}</mat-card-subtitle>
                            </mat-card-header>
            
                            <app-dish-list-client-view #dishesView
                                [dishes]="item.dishes"
                                (onAddToTable)="addToTable($event, item)"
                            ></app-dish-list-client-view>
                        </mat-expansion-panel>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-accordion>
    </div>
</div>

