export const ACTIVITY_TYPES = [
    'ACTIVITY_INFO',
    'ACTIVITY_MENU',
    'ACTIVITY_PRODUCT_LISTING',
    'ACTIVITY_REQUEST',
    'ACTIVITY_RESERVATION',
];

export const isActivity = (entityType: string):boolean => {
    return ACTIVITY_TYPES.includes(entityType);
}

export const isCategory = (entityType: string):boolean => {
    return entityType === 'CATEGORY';
}
export const isHub = (entityType: string):boolean => {
    return entityType === 'HUB';
}

export const delay = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}
