import { Component, Input } from '@angular/core';
import { convert } from 'html-to-text';

@Component({
  selector: 'app-collapsible-description',
  templateUrl: './collapsible-description.component.html',
  styleUrls: ['./collapsible-description.component.scss']
})
export class CollapsibleDescriptionComponent {

  readonly maxCharactersBeforeCollapsible: number = 300;
  
  @Input() set text(value: string) {
    if(!value) return;
    this.originalText = value;
    this.plainText = convert(value);
  };
  @Input() collapsible: boolean;
  @Input() hideCard: boolean = false;
  @Input() expandedByDefault: boolean = true;

  originalText: string = '';
  plainText: string = '';

  constructor() {}

}
