<mat-card class="card-container request-card" [ngClass]="status.value">
    <mat-card-content>
        <mat-list>
            <mat-list-item>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">Status:</div>
                    <mat-form-field floatLabel="never" *ngIf="eventRequest" >
                        <mat-select [formControl]="status" (selectionChange)="sendRequest()">
                            <mat-option class="field-type-option" 
                                [ngClass]="option" 
                                *ngFor="let option of statusOptions" 
                                [value]="option">{{'questionsDialog.'+option | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>                      
            </mat-list-item>
            <mat-divider *ngIf="eventRequest.paymentStatus" [inset]="true"></mat-divider>
            <mat-list-item *ngIf="eventRequest.paymentStatus" class="use-status">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'labels.payment_status' | translate}}:</div>
                    <div><app-payment-status-tag [status]="eventRequest.paymentStatus"></app-payment-status-tag></div>
                </div>                      
            </mat-list-item>
            <mat-divider *ngIf="eventRequest.trackingStatus" [inset]="true"></mat-divider>
            <mat-list-item *ngIf="eventRequest.trackingStatus">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'subtitles.tracking_status' | translate}}:</div>
                    <div>{{eventRequest.trackingStatus}}</div>
                </div>
            </mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'reportsDetails.activity_name' | translate}}:</div>
                    <div>{{activity.name}}</div>
                </div>
            </mat-list-item>

            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'reportsDetails.created_on' | translate}}:</div>
                    <div>{{eventRequest.createdOn | date:'short'}}</div>
                </div>
            </mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'reportsDetails.updated_on' | translate}}:</div>
                    <div>{{eventRequest.updatedOn | date:'short'}}</div>
                </div>
            </mat-list-item>
            <mat-divider [inset]="true"></mat-divider>
            <mat-list-item>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'labels.event_request_id' | translate}}:</div>
                    <div>{{eventRequest.id}}</div>
                </div>
            </mat-list-item>
            <mat-divider *ngIf="activity.currency" [inset]="true"></mat-divider>
            <mat-list-item *ngIf="activity.currency">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'formFields.currency' | translate}}:</div>
                    <div>{{activity.currency}}</div>
                </div>
            </mat-list-item>
            <mat-divider [inset]="true" *ngIf="activity.address"></mat-divider>
            <mat-list-item *ngIf="activity.address" class="address-row">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'formFields.address' | translate}}:</div>
                    <div>{{activity.address}}</div>
                </div>
            </mat-list-item>
            <mat-divider [inset]="true" *ngIf="activity.eventDate"></mat-divider>
            <mat-list-item *ngIf="activity.eventDate">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="field-name">{{'formFields.event_datetime' | translate}}:</div>
                    <div>{{activity.eventDate | localizedDate:'EEEE, MMMM d, y, h:mma'}}</div>
                </div>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
</mat-card>
