import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ShortUrlHandlerComponent } from '../../../../components/short-url-handler/short-url-handler.component';
import { CategoryAccessEntity } from '../../../../models/category';
import Category from '../../../../models/category-model';
import { HubEntity } from '../../../../models/hub';
import { YesNoDialogComponent } from '../../../../components/yes-no-dialog/yes-no-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-request-category-access-general-info',
  templateUrl: './request-category-access-general-info.component.html',
  styleUrls: ['./request-category-access-general-info.component.scss'],
})
export class RequestCategoryAccessGeneralInfoComponent {
  @Input() hub: HubEntity;
  @Input() report: CategoryAccessEntity;
  @Input() reportDetail: CategoryAccessEntity;
  @Input() category: Category;
  @Input() statusOptions: string[];
  @Input() status: UntypedFormControl;
  @Output() statusChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    private dialog: MatDialog,
  ) {}

  sendRequest() {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      minWidth: '240px',
      data: {
        title: 'messages.order_status_change',
      }
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.statusChanged.emit();
      } else {
        this.status.setValue(this.reportDetail.status);
      }
    });
  }

  getHubUrl() {
    return ShortUrlHandlerComponent.shortHubUrl(this.hub.urlHandler);
  }

  getActivityUrl() {
    return ShortUrlHandlerComponent.shortActCatUrl(this.report.categoryId);
  }
}
