<div fxLayout="row">
    <h2 class="title" translate>titles.service_type</h2>
</div>

<div fxLayout="row wrap" fxLayoutAlign="center start">
  <div fxFlex="100">
    <mat-card class="select-card">
      <mat-card-content>
        <div
          class="content"
          fxLayout="column"
          fxLayoutGap="10px"
          fxLayoutAlign="center"
          [ngSwitch]="chosenServiceType"
        >
          <div
            class="select-options"
            *ngSwitchCase="MenuServiceType.RESTAURANT"
          >
            <div class="option-content" mb-6>
              <span translate>formFields.in_restaurant</span>
              <fa-icon class="service-icon" [icon]="faUtensils"></fa-icon>
            </div>
            <mat-divider></mat-divider>
          </div>
          <div class="select-options" *ngSwitchCase="MenuServiceType.PICKUP">
            <div class="option-content" mb-6>
              <span translate>formFields.pick_up</span>
              <fa-icon class="service-icon" [icon]="faShoppingBag"></fa-icon>
            </div>
            <mat-divider></mat-divider>
          </div>
          <div class="select-options" *ngSwitchCase="MenuServiceType.DELIVERY">
            <div class="option-content" mb-6>
              <span translate>formFields.delivery</span>
              <fa-icon class="service-icon" [icon]="faBiking"></fa-icon>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <mat-card *ngIf="chosenLocation" class="location-card-container">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div class="p-1">
        <span class="label">{{ chosenLocation.name }}</span>
        <p>
          {{ chosenLocation.address.address }},
          {{ chosenLocation.address.address2 }}
        </p>
        <p>
          {{ chosenLocation.address.city }},
          {{ chosenLocation.address.stateOrProvidence }},
          {{ getCountryName(chosenLocation.address.country) }}
        </p>
        <p>{{ chosenLocation.address.zipCode }}</p>
        <p>{{ chosenLocation.address.locationHint }}</p>
      </div>
    </div>
    <app-location-map
      *ngIf="chosenLocation.location"
      [location]="chosenLocation.location"
      [noCard]="true"
      [launch]="true"
    >
    </app-location-map>
  </mat-card>
</div>
