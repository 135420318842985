import { Component, Input, ViewEncapsulation, OnInit, HostListener, ViewChild } from '@angular/core';
import { YouTubePlayer } from '@angular/youtube-player';

@Component({
  selector: 'app-swiper-view',
  templateUrl: './swiper-view.component.html',
  styleUrls: ['./swiper-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SwiperViewComponent implements OnInit {

  @Input() images: string[];
  @Input() canLink: boolean;
  @Input() canPlay: boolean;
  @Input() square: boolean = false;

  @ViewChild(YouTubePlayer) youtubePlayer: YouTubePlayer;

  videoHeight = '100%';
  videoWidth = '100%';
  videoWidthNum = 0;
  youtubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;

  constructor() {}

  ngOnInit(): void {
    this.calculateVideoSize(window.innerWidth);
  }

  validateYoutubeURL(url: string) {
    if (url) {
        const match = url.match(this.youtubeRegExp);
        if (match && match[2].length === 11) {
          return true;
        } else {
            return false;
        }
    }
    return false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calculateVideoSize(event.target.innerWidth);
  }

  calculateVideoSize(width: number) {
    const realWidth = Math.min(width - 32, 584);
    this.videoHeight = `${realWidth * 0.5635}px`;
    this.videoWidthNum = realWidth;
  }

  getYoutubeUrl(url) {
    const match = url.match(this.youtubeRegExp);
    return match[2];
  }

  getYoutubeImage(url) {
    const match = url.match(this.youtubeRegExp);
    return `https://img.youtube.com/vi/${match[2]}/0.jpg`;
  }

  youtubeVideoControl($event){
    if (this.youtubePlayer && this.youtubePlayer.getPlayerState() === YT.PlayerState.PLAYING) {
      this.youtubePlayer.stopVideo();
    }
  }
}
