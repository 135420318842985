<div fxLayout="row">
    <h2 class="title" translate>productList.summary</h2>
</div>

<mat-card class="card-container">
    <mat-card-content>
        <mat-list>
            <mat-list-item class="last">
                <div fxLayout="row" fxLayoutAlign="start center" class="row">
                    <div fxFlex="50" class="field-name">{{'productList.total' | translate}}:</div>
                    <div fxFlex="50" class="value">{{total | currency:currency:currencySymbols[currency]}}</div>
                </div>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
</mat-card>
