import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { AppSettings } from '../../../../app.settings';
import { ImageService } from '../../../../service/image.service';
import { Settings } from '../../../../app.settings.model';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '../../../../service/http.service';
import { RequestStatus } from '../../../../models/request-status-enum';
import { MatDialog } from '@angular/material/dialog';
import { EventRequestListClientViewDialogComponent } from '../event-request-list-client-view-dialog/event-request-list-client-view-dialog.component';
import { EventRequestDetailsClientViewDialogComponent } from '../event-request-details-client-view-dialog/event-request-details-client-view-dialog.component';
import { CancelRequestDialogComponent } from '../../../../components/cancel-request-dialog/cancel-request-dialog.component';

@Component({
  selector: 'app-event-request-list-client-view',
  templateUrl: './event-request-list-client-view.component.html',
  styleUrls: ['./event-request-list-client-view.component.scss'],
})
export class EventRequestListClientViewComponent implements OnInit {
  @Input() title: boolean;
  @Input() footer: boolean;
  @Input() activityId: string;
  @Input() hubId: string;
  @Input() user: any;
  @Input() activityType: string;
  @Input() open: boolean;
  @Input() size: number;

  @Output() hasOpen: EventEmitter<any> = new EventEmitter();
  @Output() hasOrder: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  private readonly apiUrl = environment.apiUrl;

  data: any[];
  pageIndex = 0;
  length = 0;
  settings: Settings;
  loading = false;
  anyOpen = false;
  anyOrder = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  get pageSize() {
    return Number(this.size);
  }

  constructor(
    private translate: TranslateService,
    private appSettings: AppSettings,
    protected imageService: ImageService,
    protected snackBar: MatSnackBar,
    private httpService: HttpService,
    public dialog: MatDialog
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.search();
  }

  cancelRequest2(request: any) {
    if (request.status !== 'OPEN') {
      return;
    }
    this.loading = false;
    const url = `${this.apiUrl}/activity-event/v1/ticket-request-from-owner`;
    this.httpService
      .doPut(url, {
        activityId: this.activityId,
        hubId: this.hubId,
        ownerId: this.user.userId,
        [`${this.activityType}Id`]: request.id,
        status: RequestStatus.APPROVED,
      })
      .subscribe(
        (response) => {
          this.cancel.emit();
          this.showSnackBar(
            this.translate.instant('questionsDialog.request_canceled_msg'),
            'OK',
            2000
          );
          this.search();
        },
        (error) => {
          this.loading = false;
          console.log('Error: ', error);
        }
      );
  }

  canCancelRequest(request: any, event: MouseEvent): void {
    event.stopPropagation();
    if (request.status !== 'OPEN' && request.status !== 'PENDING_PAYMENT') {
      return;
    }
    const dialogRef = this.dialog.open(CancelRequestDialogComponent, {
      minWidth: '240px',
      data: {
        title: 'titles.request',
        content: 'messages.want_cancel_request',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cancelRequest(request);
      }
    });
  }

  cancelRequest(request: any) {
    if (request.status !== 'OPEN') {
      return;
    }
    this.loading = false;
    const url = `${this.apiUrl}/activity-event/v1/ticket-request-from-client`;
    this.httpService
      .doPut(url, {
        activityId: this.activityId,
        hubId: this.hubId,
        [`${this.activityType}Id`]: request.id,
        status: 'CANCELED',
        userId: this.user.userId,
      })
      .subscribe(
        (response) => {
          this.cancel.emit();
          this.showSnackBar(
            this.translate.instant('questionsDialog.request_canceled_msg'),
            'OK',
            2000
          );
          this.search();
        },
        (error) => {
          this.loading = false;
          console.log('Error: ', error);
        }
      );
  }

  payRequest(request: any, vent: MouseEvent) {
    event.stopPropagation();
    // TODO: PAYMENT
  }

  search() {
    let url = `${this.apiUrl}/activity-event/v1/event-request-from-client/${this.activityId}`;
    url += `?page=${this.pageIndex}&size=${this.pageSize}&sort=createdOn,desc`;
    this.loading = true;
    this.httpService.doGet(url).subscribe(
      (response) => {
        if (response) {
          this.data = response.content;
          if (
            this.pageIndex === 0 &&
            this.data &&
            this.data[0] &&
            (this.data[0].status === RequestStatus.OPEN ||
              this.data[0].status === RequestStatus.APPROVED)
          ) {
            this.hasOpen.emit(true);
            this.anyOpen = true;
          } else {
            this.hasOpen.emit(false);
            this.anyOpen = false;
          }
          this.length = response.totalElements;

          if (this.length === 0) {
            this.hasOrder.emit(false);
            this.anyOrder = false;
          } else {
            this.hasOrder.emit(true);
            this.anyOrder = true;
          }
        } else {
          this.data = [];
          this.length = 0;
          this.hasOrder.emit(false);
          this.anyOrder = false;
          this.hasOpen.emit(false);
          this.anyOpen = false;
        }
        this.loading = false;
      },
      (error) => {
        this.data = [];
        this.length = 0;
        this.hasOrder.emit(false);
        this.anyOrder = false;
        this.hasOpen.emit(false);
        this.anyOpen = false;
        this.loading = false;
        console.log('Error: ', error);
      }
    );
  }

  pageEvent(pageChangedEvent: any) {
    this.pageIndex = pageChangedEvent.pageIndex;
    this.search();
  }

  showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(
      EventRequestListClientViewDialogComponent,
      {
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw',
        maxHeight: '100vh',
        panelClass: 'event-request-list-client-view-dialog',
        data: {
          activityId: this.activityId,
          activityType: this.activityType,
          hubId: this.hubId,
          user: this.user,
        },
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.search();
      }
    });
  }

  openDetails(eventRequest): void {
    const dialogRef = this.dialog.open(
      EventRequestDetailsClientViewDialogComponent,
      {
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw',
        maxHeight: '100vh',
        panelClass: 'event-request-details-client-view-dialog',
        data: {
          item: eventRequest,
        },
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.search();
      }
    });
  }
}
