import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-request-dialog',
  templateUrl: './cancel-request-dialog.component.html',
  styleUrls: ['./cancel-request-dialog.component.scss']
})
export class CancelRequestDialogComponent { 

    parentData: any; 
    constructor(
      public dialogRef: MatDialogRef<CancelRequestDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.parentData = data; 
    }
  
    cancel(): void {
      this.dialogRef.close();
    }
    ok(): void {
      this.dialogRef.close(true);
    }
  
  }