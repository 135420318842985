import { Component, Input, OnInit } from "@angular/core";
import { environment } from "../../../environments/environment";
import { AppSettings } from "../../app.settings";
import { HttpService } from "../../service/http.service";
import { firstValueFrom } from "rxjs";
import { AuthService } from "../../auth/auth.serviceV2";
import { PageEntity } from "../../models/types";
import { ActCatViewEntity } from "../../models/activity-category-view";
import ActivityIconMap from "../../util/activity-icon-map";
import { ShortUrlHandlerComponent } from "../short-url-handler/short-url-handler.component";

@Component({
  selector: "app-activity-list",
  templateUrl: "./activity-list.component.html",
  styleUrls: ["./activity-list.component.scss"],
})
export class ActivityListComponent implements OnInit {
  user = null;
  items: ActCatViewEntity[] = [];
  originalItems: ActCatViewEntity[] = [];
  filteredItems: ActCatViewEntity[] = [];

  readonly activityIconMap = ActivityIconMap;
  readonly apiUrl = environment.apiUrl;

  settings;
  @Input() activitieIds: string[] = [];
  searchField: string = "";

  constructor(
    private authService: AuthService,
    public httpService: HttpService,
    public appSettings: AppSettings,
  ) {
    this.settings = this.appSettings.settings;
  }

  async ngOnInit() {
    this.user = this.authService.getUserProfile();
    this.settings.loadingSpinner = true;
    await this.retrieveContent();
    this.settings.loadingSpinner = false;
  }

  clearSearchField() {
    this.searchField = "";
    this.filterItems();
  }

  getParamFromActivitiesIds(): string {
    return this.activitieIds.reduce((prev, current, index) => {
      if (index > 0) {
        prev = prev + ' OR ';
      }
      prev = prev + `id:'${current}'`;
      return prev;
    } , '')
  }

  private async retrieveContent() {
    console.log(this.activitieIds.length);
    if (this.activitieIds.length < 1) return;
    try {
      const URL = `${this.apiUrl}/actcatview/v1/client-search?page=0&size=500&querySearch=(${this.getParamFromActivitiesIds()})&sort=order,asc`;

      const response: PageEntity<ActCatViewEntity> = await firstValueFrom(
        this.httpService.doGet(URL)
      );
      if (response.content && response.content.length > 0) {
        this.originalItems = response.content;
        this.filterItems();
      }
    } catch (error) {
      console.error("Error getting ActCat: ", error);
    }
  }

  filterItems() {
    let temItems = [...this.originalItems];
    if (this.searchField.length > 2) {
      const localSearchField = this.searchField.toLocaleLowerCase();
      temItems = temItems.filter((item: ActCatViewEntity) => {
        if (
          !item.content.name.toLocaleLowerCase().startsWith(localSearchField)
        ) {
          return false;
        }
        return true;
      });
    }
    this.filteredItems = temItems.filter((item: ActCatViewEntity) => {
      return !this.items.find(
        (innerItem: ActCatViewEntity) =>
          innerItem.id === item.id
      );
    });
  }

  getActivityUrl(activityId: string) {
    return ShortUrlHandlerComponent.shortActCatUrl(activityId);
  }
}
