<mat-card>
  <div fxLayout="row nowrap">
    <div fxLayout="column nowrap">
      <h2 translate>subtitles.60_days_pormo</h2>
      <h1 class="font-bold" translate>titles.60_days_pormo</h1>
      <p>
        <span class="text-sm" translate>messages.60_days_promo</span>
        <span class="code text-sm cursor-pointer" (click)="copyToClipboard($event)">TINY CODE</span>
        <span class="text-sm" translate>messages.60_days_promo2</span>
      </p>
      <div class="rounded text-white font-bold bg-orange-400 px-4 py-1 mt-2 w-fit cursor-pointer" (click)="copyToClipboard($event)">ALLAPP</div>
    </div>
    <img class="image mr-0" src="/assets/img/app/saving-plan.png" alt="saving plan" />
  </div>
</mat-card>
