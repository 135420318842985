<div class="c-flex-p" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center start">
    <mat-card class="company-card">
        <div class="card-title" fxLayout="row" fxLayoutAlign="space-between center">
            <h2 translate>subtitles.company_information</h2>
        </div>
        <mat-card-content>
            <mat-list>
                <mat-list-item>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div class="field-name">{{'labels.company_name' | translate}}:</div>
                        <div>{{name}}</div>
                    </div>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div class="field-name">{{'labels.company_address' | translate}}:</div>
                        <div>
                            <p>{{address.address}}<span *ngIf="address.address2">, {{address.address2}}</span></p>
                            <p>{{address.city}}<span *ngIf="address.stateOrProvidence">, {{address.stateOrProvidence}}</span><span *ngIf="address.country">, {{address.country}}</span></p>
                            <p>{{address.zipCode}}</p>
                            <p>{{address.locationHint}}</p>
                        </div>
                    </div>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div class="field-name">{{'labels.phone_number' | translate}}:</div>
                        <div>{{phone | mask: '(000) 000-0000'}}</div>
                    </div>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div class="field-name">{{'labels.email_address' | translate}}:</div>
                        <div>{{email}}</div>
                    </div>
                </mat-list-item>
                <mat-divider></mat-divider>
            </mat-list>
            <div class="button-container" fxLayout="row" fxLayoutAlign="center center">
                <button class="print-button no-print" mat-raised-button color="accent" (click)="downloadVcf()">
                    <span translate>buttons.add_contact</span>&nbsp;&nbsp;<mat-icon>contact_phone_outline</mat-icon>
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</div>