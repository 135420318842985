import { Component } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { SubscriptionOption, SubscriptionOptionMonths, SubscriptionPrice, SubscriptionStripeProductId } from '../../models/user';
import { AuthService } from '../../auth/auth.serviceV2';

@Component({
  selector: 'app-pricing-blocks',
  templateUrl: './pricing-blocks.component.html',
  styleUrls: ['./pricing-blocks.component.scss'],
})
export class PricingBlocksComponent {

  billFrequency = SubscriptionOption.ONE_MONTH;
  user: any;
  
  constructor(private authService: AuthService) {
    this.user = this.authService.getUserProfile();
  }

  get SubscriptionOption() {
    return SubscriptionOption;
  }

  get SubscriptionPrice() {
    return SubscriptionPrice;
  }

  billFrequencyChange(event: MatButtonToggleChange) {
    console.log('Selection changed:', event.value);
  }

  calculateSaving(selectedFrequency: SubscriptionOption) {
    const total = SubscriptionPrice.ONE_MONTH * SubscriptionOptionMonths[selectedFrequency];
    return Math.ceil(((total - SubscriptionPrice[selectedFrequency]) / total) * 100);
  }

  stripePay() {
    const url = `https://buy.stripe.com/${SubscriptionStripeProductId[this.billFrequency]}?prefilled_email=${this.user.email}&client_reference_id=${this.user.userId}`;
    window.location.href = url;
  }
}
