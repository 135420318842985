import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from '../../service/notification.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { HttpService } from '../../service/http.service';
import { environment } from '../../../environments/environment';
import { LocalStorageType } from '../../auth/local-storage-type.enum';

@Component({
  selector: 'app-order-notification-button',
  templateUrl: './order-notification-button.component.html',
})
export class OrderNotificationButtonComponent implements OnInit, OnDestroy {
  readonly apiUrl = environment.apiUrl;
  subscription: Subscription;
  count = 0;
  timerIntervalPin;

  notificationAudio;

  constructor(
    private notificationService: NotificationService,
    private http: HttpService,
  ) {}

  ngOnInit() {
    this.notificationAudio = new Audio();
    this.notificationAudio.src = "../../../assets/sounds/notification.mp3";
    this.notificationAudio.load();
    this.getNotificationCount();
    this.timerIntervalPin = setInterval(() => {
      this.getNotificationCount();
    }, 60000);

    this.subscription = this.notificationService.count.subscribe({
      next: (newValue) => {
        this.count = newValue > 99 ? 99 : newValue;
      },
    });
  }

  ngOnDestroy() {
    clearInterval(this.timerIntervalPin);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async getNotificationCount() {
    let date = localStorage.getItem(
      LocalStorageType.LatestOrderNotificationUpdateDatetime
    );
    if (!date) {
      date = '0001-01-01T00:00:00Z';
    }
    const URL = `${this.apiUrl}/request-report/v1/search?querySearch=(ownerUpToDate:false AND updatedOn>'${date}')&page=0&size=100`;
    try {
      const response = await firstValueFrom(this.http.doGet(URL));
      if (response) {
        if (response.content.length > this.notificationService.count.value) {
          this.notificationAudio.play();
        }
        this.notificationService.count.next(response.content.length);
      } else {
        this.notificationService.count.next(0);
      }
    } catch(_error) {
      this.notificationService.count.next(0);
      clearInterval(this.timerIntervalPin);
    }
  }
}
