import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-special-promo-code-dialog',
  templateUrl: './special-promo-code-dialog.component.html',
  styleUrls: ['./special-promo-code-dialog.component.scss']
})
export class SpecialPromoCodeDialogComponent { 

    parentData: any; 
    constructor(
      public dialogRef: MatDialogRef<SpecialPromoCodeDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.parentData = data; 
    }

    ok(): void {
      this.dialogRef.close();
    }
  
  }