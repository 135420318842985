  
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

declare const qrcode: any;

@Injectable()
export class QrCodeReaderService {

  decode(data: any): Observable<string> {

    return new Observable(observer => {

      qrcode.callback = (res) => {
        observer.next(res);
        observer.complete();
      };
      qrcode.decode(data);

    });
  }
}