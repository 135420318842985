import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AppSettings } from "../../../app.settings";
import { Settings } from "../../../app.settings.model";
import { MenuService } from "../menu/menu.service";
import { AuthService } from "../../../auth/auth.serviceV2";
import { environment } from "../../../../environments/environment";

import { Router } from "@angular/router";
import { RouteConstants } from "../../../app.constants";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService],
})
export class SidenavComponent implements OnInit {
  public perfectScrollBarConfig: PerfectScrollbarConfigInterface = {};
  public menuItems: Array<any>;
  public settings: Settings;
  public noImage = "../assets/img/app/default-user.jpg";
  public userProfile: any;
  currentApplicationVersion = environment.appVersion;

  constructor(
    public router: Router,
    public appSettings: AppSettings,
    public menuService: MenuService,
    public auth: AuthService
  ) {
    this.settings = this.appSettings.settings;
    this.userProfile = this.auth.getUserProfile();
  }

  ngOnInit() {
    this.menuItems = this.menuService.getVerticalMenuItems();
  }

  public closeSubMenus() {
    const menu = document.querySelector(".sidenav-menu-outer");
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        const child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains("expanded")) {
            child.children[0].classList.remove("expanded");
            child.children[1].classList.remove("show");
          }
        }
      }
    }
  }

  public async logout() {
    await this.auth.logout();
    this.router.navigate([RouteConstants.home]);
  }
}
