import { Component, Input} from '@angular/core';
import { Router } from '@angular/router';
import { RouteConstants } from '../../app.constants';
import { SubscriptionProvider } from '../../models/subscription-provider-enum';
import { AccountStatus } from '../../models/account-status-enum';

@Component({
  selector: 'app-subscription-status',
  templateUrl: './subscription-status.component.html',
  styleUrls: ['./subscription-status.component.scss']
})
export class SubscriptionStatusComponent {

  @Input() status: AccountStatus;
  @Input() provider: SubscriptionProvider;
  @Input() expirationDate: string | null | undefined;
  @Input() linkToPricing: boolean;
  constructor(private router: Router){}

  goToPricing() {
    if(!this.linkToPricing) return;
    this.router.navigateByUrl(RouteConstants.pay);
  }

  get AccountStatus() {
    return AccountStatus;
  }

  get SubscriptionProvider() {
    return SubscriptionProvider;
  }
}
