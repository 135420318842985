import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {AuthGuard} from './auth.guard';
import {IsNewGuard} from './auth.isnewguard';
import {AuthService} from './auth.serviceV2';
import {UserService} from './user.service';
import {TokenService} from './token.service';
import {CallBackComponent} from './callback.component';
import { UserAdditionalInfoService } from '../pages/authentication/login/user-additional-info.service';

@NgModule({
    imports: [
        HttpClientModule,
        CommonModule
    ],
    declarations: [CallBackComponent],
    providers: [
        AuthGuard,
        IsNewGuard,
        AuthService,
        UserService,
        TokenService,
        UserAdditionalInfoService,
    ],
})
export class AuthModule {
}
