<mat-card [id]="comment.id" class="card-container" [ngClass]="{'mine': comment.userId === user.userId}" *ngIf="!isEditing">
    <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>
                <span class="mr-1.5 text-gray-500 whitespace-nowrap">{{comment.userName}}</span>
                <div>
                    <span class="time">{{(comment.edited ? comment.updatedOn : comment.createdOn) | date:'medium'}}</span>
                    <span class="time" *ngIf="comment.edited">({{ 'labels.edited' | translate }})</span>
                </div>
                <span *ngIf="comment.internal" class="tag" translate>labels.internal</span>
            </div>
            <button *ngIf="!editingComment && comment.userId === user.userId" class="menu-button" mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
            <mat-menu #menu="matMenu">
                <button class="edit" mat-menu-item (click)="onEditMode()"><span translate>buttons.edit</span></button>
                <button class="delete" mat-menu-item (click)="canDelete()"><span translate>buttons.delete</span></button>
            </mat-menu>
        </div>
        <p [class.expanded]="expanded" (click)="expanded = !expanded" class="text">
            {{comment.comment}}
        </p>
    </mat-card-content>
</mat-card>

<mat-card class="card-container" *ngIf="isEditing">
    <mat-card-content>
        <form novalidate [formGroup]="form" fxLayout="column" fxLayoutAlign="center">
            <mat-form-field appearance="outline">
                <textarea matInput matTextareaAutosize rows="6" placeholder="{{'placeholders.your_comment' | translate}}" formControlName="comment"></textarea>
                <mat-error *ngIf="commentField.hasError('maxlength')" translate>formErrors.comment_max_length</mat-error>
            </mat-form-field>
            <mat-error *ngIf="commentField.value && commentField.value.length > 300" class="comment-error" translate>formErrors.comment_max_length</mat-error>
            <div class="grid grid-cols-2 rounded overflow-hidden mt-2" *ngIf="!client">
                <button
                  class="bg-primary text-white p-2"
                  [ngClass]="{ 'brightness-50 shadow-inner': !internalField.value }"
                  (click)="internalField = true"
                >
                  <span translate>labels.internal</span>
                </button>
                <button
                  class="bg-accent text-white p-2"
                  [ngClass]="{ 'brightness-50 shadow-inner': internalField.value }"
                  (click)="internalField = false"
                >
                  <span translate>labels.external</span>
                </button>
              </div>
        </form>

        <section class="actions" fxLayoutAlign="start">
            <button mat-raised-button color="accent" class="save-button" (click)="onEdit()" [disabled]="loading || !form || !form.dirty || form.invalid || (commentField.value && commentField.value.length > 300)">
                <span *ngIf="!loading" translate>buttons.save_your_comment</span>
                <mat-spinner class="loading-spinner" diameter="25" *ngIf="loading"></mat-spinner>
            </button>

            <button *ngIf="comment && !loading" mat-button class="cancel-button" (click)="cancel()">
                <span translate>buttons.cancel</span>
            </button>
        </section>
    </mat-card-content>
</mat-card>