import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
  UntypedFormArray,
} from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { PaymentType } from '../models/payment-type-enum';

const createAdditionalCharge = (
  chargeName: string,
  chargeValue: number,
  fb: UntypedFormBuilder
): UntypedFormGroup => {
  return fb.group({
    name: [chargeName, [
      Validators.required,
      RxwebValidators.unique(),
    ]],
    value: [chargeValue, [
      Validators.required,
      Validators.min(0.01),
    ]]
  });
};

const createAdditionalChargesFromResponse = (
  additionalCharges: any,
  fb: UntypedFormBuilder,
  additionalChargesForm: UntypedFormArray,
): UntypedFormArray => {
  if (!additionalCharges) return additionalChargesForm;
  Object.keys(additionalCharges).forEach((key: string) => {
    additionalChargesForm.push(createAdditionalCharge(key, additionalCharges[key], fb));
  });
  return additionalChargesForm;
};

const cleanContent = (charges: Array<any>): Array<any> => {
  return charges.reduce((prev, { name, value }) => ({
    ...prev,
    [name]: value
  }) , {});
};

const creanPaymentContent = (rawValues: any) => {
  if (!rawValues.chosenPaymentType) {
    delete rawValues.chosenPaymentType;
  }
  if (rawValues.chosenPaymentType !== PaymentType.BANK_TRANSFERENCE) {
    delete rawValues.paymentImage;
  }
  return rawValues;
}

export default {
  createAdditionalCharge,
  createAdditionalChargesFromResponse,
  cleanContent,
  creanPaymentContent
};
