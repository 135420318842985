<mat-card [id]="item.id" class="item-card" [ngClass]="{'expanded' : selected}">
    <div *ngIf="!selected" (click)="selectItem()" class="closed">
        <mat-card-header class="header">
            <div mat-card-avatar class="avatar-container">
                <p *ngIf="!item.available" translate>labels.not_available</p>
                <img  class="avatar" [src]="item.images[0] || noImage" alt="product" />
            </div>

            <mat-card-title>{{item.name}}</mat-card-title>
            <mat-card-subtitle class="description">{{item.infoText}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="content">
            <mat-card-subtitle fxLayout="row nowrap" fxLayoutAlign="space-around center">
                <span class="variant">{{actualVariant.description}}:</span>&nbsp;
                <span class="price">{{actualVariant.price | currency:currency:currencySymbols[currency]}}</span>
            </mat-card-subtitle>    
        </mat-card-content>
    </div>

    <div *ngIf="selected" class="opened">
        <mat-card-content class="content">
            <div class="image-container">
                <p *ngIf="!item.available" translate>labels.not_available</p>
                <app-swiper-view [images]="getImages()" [square]="true">
                </app-swiper-view>
            </div>

            <mat-card-header class="header">
                <mat-card-title>{{item.name}}</mat-card-title>
                <mat-card-subtitle class="description">{{item.infoText}}</mat-card-subtitle>
            </mat-card-header>
            <div class="link-container" *ngIf="item.links && item.links[0]">
                <fa-icon matPrefix class="front-icon" [icon]="faLink"></fa-icon>
                <a class="link" [href]="item.links[0]" target="_blank">
                    View more
                </a>
            </div>
            <mat-card-subtitle class="variant" fxLayout="row nowrap" fxLayoutAlign="space-around center">
                <mat-form-field class="variant" floatLabel='always'>
                    <mat-label><span translate>productList.variant</span></mat-label>
                    <mat-select [formControl]="variant" (selectionChange)="variantChange($event)">
                      <mat-option *ngFor="let option of item.options" [value]="option">
                        {{option.description}} <span *ngIf="!item.available" class="no-available-label">(<span translate>labels.not_available</span>)</span>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="count" floatLabel='always'>
                    <mat-label><span translate>productList.quantity</span></mat-label>
                    <mat-select [formControl]="quantity">
                      <mat-option *ngFor="let quantityValue of quantitySelector" [value]="quantityValue">
                        {{quantityValue}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
                
            </mat-card-subtitle>
            <mat-card-subtitle class="quantity" fxLayout="row nowrap" fxLayoutAlign="space-around center">
                <span class="unit"><span translate>productList.unit</span>: {{actualVariant.price | currency:currency:currencySymbols[currency]}}</span>
                <span class="price">
                    <span translate>productList.total</span>: {{getTotal(actualVariant.price, selectedForm.get('quantity').value) | currency:currency:currencySymbols[currency]}}
                </span>
            </mat-card-subtitle>
        </mat-card-content>
        <mat-card-actions fxLayout="row" fxLayoutAlign="space-between center">
            <button *ngIf="!loading" mat-raised-button (click)="close()" class="close">
                <span  translate>tinyAll.close</span>&nbsp;
                <mat-icon>close</mat-icon>
            </button>
            <button *ngIf="!loading" color="accent" mat-raised-button (click)="addToCart()" [disabled]="!item.available || !selectedForm.get('variant').value?.available">
                <span  translate>productList.add_to_cart</span>&nbsp;
                <mat-icon>add_shopping_cart</mat-icon>
            </button>
            <div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="loading">
                <span class="spinner-text" translate>productList.adding_to_cart</span>&nbsp;
                <mat-spinner  class="button-spinner" diameter="30"></mat-spinner>
            </div>
            
        </mat-card-actions>
    </div>
</mat-card>