<div class="request-list-container">
    <h2 *ngIf="loading || (data && data.status != 'GRANTED')" class="title" translate>questionsDialog.requests</h2>
    <div *ngIf="loading; else noLoading" class="loading" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
        <mat-spinner color="primary" diameter="60"></mat-spinner>
    </div>
    <ng-template #noLoading>
        <div fxLayout="column" fxLayoutGap="20px">
            <mat-card *ngIf="(data && data.status != 'GRANTED')" class="request-card" [ngClass]="data.status" (click)="openDetails(data)">
                <div class="card-header">
                    <mat-card-subtitle>
                        {{data.createdOn | date:'medium'}}
                    </mat-card-subtitle>
                    <span class="status-container">{{'questionsDialog.'+data.status | translate}}</span>
                </div>
                <div *ngIf="data.totalPrice > 0" fxLayout="row" fxLayoutAlign="start center" class="button-row">
                    <p><span>{{data.totalPrice | currency:"$"}}</span></p>
                </div>
            </mat-card>
        </div>
    </ng-template>
</div>