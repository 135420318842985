import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-url-handler-example',
  templateUrl: './url-handler-example.component.html',
  styleUrls: ['./url-handler-example.component.scss'],
})
export class UrlHandlerExampleComponent {
  @Input() public urlHandler: string;
  @Input() public title: string;
  @Input() public copy: boolean;
  @Input() public isLink: boolean = false;

  copyText: string;

  readonly appURL = environment.appUrl;

  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    this.copyText = this.translate.instant('hub.copy_text');
  }

  copyToClipboard(event: MouseEvent) {
    event.stopPropagation();
    const el = document.createElement('textarea');
    el.value = `${this.appURL}/${this.urlHandler}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.showSnackBar(this.copyText, 'OK', 2000);
  }

  private showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }
}
