<div fxLayout="row" *ngIf="title && answers && !isEmpty(answers)">
  <h2 class="title">{{ title }}</h2>
</div>
<div class="question-list-container" *ngIf="!isEmpty(answers)">
  <div fxLayout="column" fxLayoutGap="20px">
    <div id="encrypted" fxLayout="row" fxLayoutAlign="end center">
      <mat-slide-toggle [checked]="encrypted" (change)="encrypted = !encrypted">
        <span translate>questionsDialog.encrypted</span>
      </mat-slide-toggle>
    </div>
    <ng-container *ngFor="let questionKey of getQuestions(answers); let i = index">
      <mat-card class="card-container">
        <mat-card-content>
          <p class="main-field">
            {{ questionKey || 'questionsDialog.question' | translate }}
          </p>
          <div [ngSwitch]="AnswerClassType[answers[questionKey]._class]">
            <div *ngSwitchCase="'TEXT'">
              <p class="text">
                <span>{{
                  encrypted &&
                  answers[questionKey].value &&
                  answers[questionKey].hidden
                    ? '•••••••'
                    : answers[questionKey].value
                }}</span>
              </p>
            </div>
            <div *ngSwitchCase="'NUMBER'">
              <p class="number">
                <span>{{
                  encrypted &&
                  answers[questionKey].value &&
                  answers[questionKey].hidden
                    ? '•••••••'
                    : answers[questionKey].value
                }}</span>
              </p>
            </div>
            <div *ngSwitchCase="'DATE'">
              <p class="date">
                <span>{{
                  answers[questionKey].value | date : 'mediumDate'
                }}</span>
              </p>
            </div>
            <div *ngSwitchCase="'TIME'">
              <p class="time">
                <span>{{
                  getTime(answers[questionKey].value) | date : 'shortTime'
                }}</span>
              </p>
            </div>
            <div *ngSwitchCase="'DATETIME'">
              <p class="datetime">
                <span>{{ answers[questionKey].value | date : 'medium' }}</span>
              </p>
            </div>

            <div *ngSwitchCase="'SINGLE_CHOICE'">
              <p class="single-choice">
                <span>{{ answers[questionKey].value }}</span>
              </p>
            </div>

            <div *ngSwitchCase="'SINGLE_CHOICE_PRICE'">
              <p class="single-choice">
                <span
                  >{{ answers[questionKey].value.option }} - ({{
                    answers[questionKey].value.price | currency : '$'
                  }})</span
                >
              </p>
            </div>

            <div *ngSwitchCase="'GEOPOINT'">
              <app-location-map
                [noCard]="true"
                [mapId]="'map' + i"
                [launch]="true"
                [location]="answers[questionKey].value"
              >
              </app-location-map>
            </div>
            <div *ngSwitchCase="'MULTI_CHOICE'">
              <p
                class="multi-choice"
                *ngFor="let option of answers[questionKey].value"
              >
                <span>{{ option }}</span>
              </p>
            </div>

            <div *ngSwitchCase="'MULTI_CHOICE_PRICE'">
              <p
                class="multi-choice"
                *ngFor="let option of answers[questionKey].value"
              >
                <span
                  >{{ option.option }} - ({{
                    option.price | currency : '$'
                  }})</span
                >
              </p>
            </div>

            <div *ngSwitchCase="'IMAGE'">
              <img
                class="placeholder-image image"
                [src]="answers[questionKey].value"
                alt="answer"
              />
              <button
                (click)="downloadImage(answers[questionKey].value)"
                class="border-accent border rounded flex justify-center items-center gap-3 text-accent bg-white px-2 py-1 mx-auto mt-4 mb-2"
              >
                <span translate>buttons.download_image</span>
                <mat-spinner
                  color="accent"
                  diameter="25"
                  *ngIf="isDownloadingImage"
                ></mat-spinner>
                <mat-icon *ngIf="!isDownloadingImage">cloud_download</mat-icon>
              </button>
            </div>

            <div *ngSwitchCase="'GROUP'">
              <p class="main-field !text-gray-600 !font-normal border-b">
                {{ answers[questionKey].group }}
              </p>
              <ng-container *ngFor="let innerQuestionKey of getQuestions(answers[questionKey].questionAnswerMap); let i = index">
                <div class="card-container border-b">
                  <div>
                    <p class="main-field">
                      {{ innerQuestionKey || 'questionsDialog.question' | translate }}
                    </p>
                    <div [ngSwitch]="AnswerClassType[answers[questionKey].questionAnswerMap[innerQuestionKey]._class]">
                      <div *ngSwitchCase="'TEXT'">
                        <p class="text">
                          <span>{{
                            encrypted &&
                            answers[questionKey].questionAnswerMap[innerQuestionKey].value &&
                            answers[questionKey].questionAnswerMap[innerQuestionKey].hidden
                              ? '•••••••'
                              : answers[questionKey].questionAnswerMap[innerQuestionKey].value
                          }}</span>
                        </p>
                      </div>
                      <div *ngSwitchCase="'NUMBER'">
                        <p class="number">
                          <span>{{
                            encrypted &&
                            answers[questionKey].questionAnswerMap[innerQuestionKey].value &&
                            answers[questionKey].questionAnswerMap[innerQuestionKey].hidden
                              ? '•••••••'
                              : answers[questionKey].questionAnswerMap[innerQuestionKey].value
                          }}</span>
                        </p>
                      </div>
                      <div *ngSwitchCase="'DATE'">
                        <p class="date">
                          <span>{{
                            answers[questionKey].questionAnswerMap[innerQuestionKey].value | date : 'mediumDate'
                          }}</span>
                        </p>
                      </div>
                      <div *ngSwitchCase="'TIME'">
                        <p class="time">
                          <span>{{
                            getTime(answers[questionKey].questionAnswerMap[innerQuestionKey].value) | date : 'shortTime'
                          }}</span>
                        </p>
                      </div>
                      <div *ngSwitchCase="'DATETIME'">
                        <p class="datetime">
                          <span>{{ answers[questionKey].questionAnswerMap[innerQuestionKey].value | date : 'medium' }}</span>
                        </p>
                      </div>
          
                      <div *ngSwitchCase="'SINGLE_CHOICE'">
                        <p class="single-choice">
                          <span>{{ answers[questionKey].questionAnswerMap[innerQuestionKey].value }}</span>
                        </p>
                      </div>
          
                      <div *ngSwitchCase="'SINGLE_CHOICE_PRICE'">
                        <p class="single-choice">
                          <span
                            >{{ answers[questionKey].questionAnswerMap[innerQuestionKey].value.option }} - ({{
                              answers[questionKey].questionAnswerMap[innerQuestionKey].value.price | currency : '$'
                            }})</span
                          >
                        </p>
                      </div>
          
                      <div *ngSwitchCase="'GEOPOINT'">
                        <app-location-map
                          [noCard]="true"
                          [mapId]="'map' + i"
                          [launch]="true"
                          [location]="answers[questionKey].questionAnswerMap[innerQuestionKey].value"
                        >
                        </app-location-map>
                      </div>
                      <div *ngSwitchCase="'MULTI_CHOICE'">
                        <p
                          class="multi-choice"
                          *ngFor="let option of answers[questionKey].questionAnswerMap[innerQuestionKey].value"
                        >
                          <span>{{ option }}</span>
                        </p>
                      </div>
          
                      <div *ngSwitchCase="'MULTI_CHOICE_PRICE'">
                        <p
                          class="multi-choice"
                          *ngFor="let option of answers[questionKey].questionAnswerMap[innerQuestionKey].value"
                        >
                          <span
                            >{{ option.option }} - ({{
                              option.price | currency : '$'
                            }})</span
                          >
                        </p>
                      </div>
          
                      <div *ngSwitchCase="'IMAGE'">
                        <img
                          class="placeholder-image image"
                          [src]="answers[questionKey].questionAnswerMap[innerQuestionKey].value"
                          alt="answer"
                        />
                        <button
                          (click)="downloadImage(answers[questionKey].questionAnswerMap[innerQuestionKey].value)"
                          class="border-accent border rounded flex justify-center items-center gap-3 text-accent bg-white px-2 py-1 mx-auto mt-4 mb-2"
                        >
                          <span translate>buttons.download_image</span>
                          <mat-spinner
                            color="accent"
                            diameter="25"
                            *ngIf="isDownloadingImage"
                          ></mat-spinner>
                          <mat-icon *ngIf="!isDownloadingImage">cloud_download</mat-icon>
                        </button>
                      </div>
                      <span *ngSwitchDefault>...</span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <span *ngSwitchDefault>...</span>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-container>
  </div>
</div>
