import {Component, Inject} from '@angular/core';
import {MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {AuthService} from '../../auth/auth.serviceV2';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {
    faFacebookF,
    faTwitter,
    faLinkedinIn,
    faInstagram,
    faSnapchatGhost,
    faPinterestP,
    faRedditAlien,
    faWhatsapp,
    faTumblr,
    faTelegramPlane,
    faYoutube,
    faMediumM,
    faSkype,
    faLine
} from '@fortawesome/free-brands-svg-icons';

import {
    faSms,
    faEnvelope,
    faLink,
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-social-share-bottom-sheet',
    templateUrl: './social-share-bottom-sheet.component.html',
    styleUrls: ['./social-share-bottom-sheet.component.scss']
})
export class SocialShareBottomSheetComponent {

    readonly faFacebookSquare = faFacebookF;
    readonly faTwitterSquare = faTwitter;
    readonly faLinkedin = faLinkedinIn;
    readonly faInstagramSquare = faInstagram;
    readonly faSnapchatSquare = faSnapchatGhost;
    readonly faPinterestSquare = faPinterestP;
    readonly faRedditSquare = faRedditAlien;
    readonly faWhatsappSquare = faWhatsapp;
    readonly faTumblrSquare = faTumblr;
    readonly faTelegram = faTelegramPlane;
    readonly faYoutubeSquare = faYoutube;
    readonly faMedium = faMediumM;
    readonly faSkype = faSkype;
    readonly faLine = faLine;
    readonly faSms = faSms;
    readonly faEnvelope = faEnvelope;
    readonly faLink = faLink;

    url: string;
    encodedUrl: string;
    title: string;
    description: string;
    images: string[];
    tags: any;
    user: any;
    isMobile = false;

    constructor(
        private _bottomSheetRef: MatBottomSheetRef<SocialShareBottomSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        private authService: AuthService,
        private snackBar: MatSnackBar,
        private translate: TranslateService
    ) {
        this.user = this.authService.getUserProfile();
        this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        this.url = data.url;
        this.encodedUrl = encodeURIComponent(this.url);
        this.title = data.title;
        this.description = data.description;
        this.images = data.images;
        this.tags = data.tags;
    }

    cancel(): void {
        this._bottomSheetRef.dismiss();
    }

    facebookShare() {
        window.open(
            `https://www.facebook.com/sharer.php?u=${this.encodedUrl}`,
            '',
            'menubar=no, toolbar=no, width=600, height=600, scrollbars=yes, resizable=no'
        );
        this.cancel();
    }

    twitterShare() {
        window.open(
            `https://twitter.com/intent/tweet?url=${this.encodedUrl}&text=${this.title}&hashtags=tinyall`,
            '',
            'menubar=no, toolbar=no, width=600, height=600, scrollbars=yes, resizable=no'
        );
        this.cancel();
    }

    linkedinShare() {
        window.open(
            `https://www.linkedin.com/shareArticle?url=${this.encodedUrl}&title=${this.title}&source="TinyAll.app"`,
            '',
            'menubar=no, toolbar=no, width=600, height=600, scrollbars=yes, resizable=no'
        );
        this.cancel();
    }

    redditShare() {
        window.open(
            `https://www.reddit.com/submit?url=${this.encodedUrl}&title=${this.title}`,
            '',
            'menubar=no, toolbar=no, width=600, height=600, scrollbars=yes, resizable=no'
        );
        this.cancel();
    }

    pinterestShare() {
        window.open(
            `https://pinterest.com/pin/create/link/?media=${this.images[0]}&url=${this.encodedUrl}&is_video=false&description=${this.title}`,
            '',
            'menubar=no, toolbar=no, width=600, height=600, scrollbars=yes, resizable=no'
        );
        this.cancel();
    }

    tumblrShare() {
        window.open(
            `https://www.tumblr.com/widgets/share/tool?canonicalUrl=${this.encodedUrl}&title=${this.title}&caption=${this.description}&tags=tinyall`,
            '',
            'menubar=no, toolbar=no, width=600, height=600, scrollbars=yes, resizable=no'
        );
        this.cancel();
    }

    whatsappShare() {
        window.open(
            `https://api.whatsapp.com/send?text=${this.title}%20${this.encodedUrl}`,
            '',
            'menubar=no, toolbar=no, width=600, height=600, scrollbars=yes, resizable=no'
        );
        this.cancel();
    }


    skypeShare() {
        window.open(
            `https://web.skype.com/share?url=${this.encodedUrl}&text=${this.title}`,
            '',
            'menubar=no, toolbar=no, width=600, height=600, scrollbars=yes, resizable=no'
        );
        this.cancel();
    }

    lineShare() {
        window.open(
            `https://lineit.line.me/share/ui?url=${this.encodedUrl}&text=${this.title}`,
            '',
            'menubar=no, toolbar=no, width=600, height=600, scrollbars=yes, resizable=no'
        );
        this.cancel();
    }

    telegramShare() {
        window.open(
            `https://telegram.me/share/url?url=${this.encodedUrl}&text=${this.title}`,
            '',
            'menubar=no, toolbar=no, width=600, height=600, scrollbars=yes, resizable=no'
        );
        this.cancel();
    }

    emailShare() {
        window.open(
            `https://mail.google.com/mail/?view=cm&to=${this.user.email}&su=${this.title}&body=${this.encodedUrl}`,
            '',
            'menubar=no, toolbar=no, width=600, height=600, scrollbars=yes, resizable=no'
        );
        this.cancel();
    }

    smsShare() {
        window.open(
            `sms:${this.user.cellPhone}?body=${this.encodedUrl}${this.title}`,
            '',
            'menubar=no, toolbar=no, width=600, height=600, scrollbars=yes, resizable=no'
        );
        this.cancel();
    }


    copyToClipboard(event: MouseEvent) {
        event.stopPropagation();
        const el = document.createElement('textarea');
        el.value = this.url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.showSnackBar(this.translate.instant('messages.copied_link'), 'OK', 2000);
        this.cancel();
    }

    private showSnackBar(message, action, duration) {
        this.snackBar.open(message, action, {
            duration: duration,
        });
    }


}
