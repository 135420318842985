import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from '../../app.settings';
import { Settings } from '../../app.settings.model';
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-menu-order-dishes-list',
  templateUrl: './menu-order-dishes-list.component.html',
  styleUrls: ['./menu-order-dishes-list.component.scss']
})
export class MenuOrderDishesListComponent implements OnInit {

  @Input() owner: boolean;
  @Input() order: any;
  @Input() activityId: string;
  @Input() hubId: string;
  @Input() userId: string;
  @Input() initialTip;

  readonly apiUrl = environment.apiUrl;
 
  settings: Settings;
  products = new Map();

  pageIndex = 0;

  loading = false;
  handler = null;

  searchCriteria = [];

  selectedForm: UntypedFormGroup;
  firstSearch = false;

  summary = {
    quantity: 0,
    total: 0,
    tip: 0
  };

  constructor(
    protected snackBar: MatSnackBar,
    private appSettings: AppSettings,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.settings.loadingSpinner = true;
    this.loading = true;
    this.route.paramMap.pipe(
      map((params: ParamMap) => params.get('handler'))
    ).subscribe((value) => {
        this.handler = value;
        this.settings.loadingSpinner = false;
        this.loading = false
        this.setSummary();
    });
  }

  showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  setSummary() {
    this.summary = this.order.dishRequestedList.reduce((prev, current) => {
        prev.quantity += current.quantity;
        prev.total += Number(current.dishPrice) * current.quantity;
        return prev;
      }, {
        quantity: 0,
        total: 0,
      });

    let tip = 0;
    if(Number(this.initialTip)) {
      tip = (this.summary.total * Number(this.initialTip))/100;
    }
    this.summary.tip = tip;
  }


}
