import { Component, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {MatExpansionPanel} from '@angular/material/expansion';

@Component({
  selector: 'app-dish-item-client-view',
  templateUrl: './dish-item-client-view.component.html',
  styleUrls: ['./dish-item-client-view.component.scss']
})
export class DishItemClientViewComponent {

  readonly noImage = '/assets/img/app/default-dish.png';

  @Input() dish;
  @Input() index: number;
  @Output() onAddToTable: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatExpansionPanel) expansionPanel: MatExpansionPanel;

  isOpen = false;

  constructor() { }

  addToTable({description: variantName, price}) {
    const { name, description, image } = this.dish;
    this.onAddToTable.emit({
      name,
      description,
      image,
      variantName,
      price
    });
  }

  get id() {
    return `${this.dish.name}${this.index}`;
  }

  onItemOpen(){
    setTimeout(() => {
      const element = document.getElementById(this.id);
      if (element !== null) {
        element.scrollIntoView();
      }
    }, 200);
  }

  public close(){
    this.expansionPanel.close();
  }
}
