<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <button (click)="back()" mat-button><span translate>tinyAll.back</span></button>
    <h2 class="title">{{activity?.name}}</h2>
</div>
<div *ngIf="allowed && !showingFeedback" class="page-container" fxLayout="column wrap" fxLayoutAlign="center start">
    <app-code-scanner *ngIf="scannerEnabled" [canFlip]="true" [canTorch]="true" (scanSuccessHandler)="scanSuccessHandler($event)"></app-code-scanner>
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center">
        <button class="scan-button" (click)="enableScanner()" *ngIf="!scannerEnabled" mat-fab color="accent">
            <span translate>buttons.scan</span>&nbsp;<mat-icon>qr_code_scanner</mat-icon>
        </button>
    </div>
</div>
<div *ngIf="!allowed" class="page-container error-container" fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="80" fxFlex.gt-sm="30" fxFlex.sm="60">
        <mat-card class="p-0 mat-elevation-z24 box">
            <div fxLayout="column" fxLayoutAlign="center center" class="bg-primary box-header">
                <button mat-fab color="accent" class="mat-elevation-z12">
                    <mat-icon>error</mat-icon>
                </button>
                <h1 class="error-title" translate>titles.not_authorized</h1>
            </div>
            <mat-card-content fxLayout="column" fxLayoutAlign="end center" class="box-content">
                <mat-card fxLayout="column" fxLayoutAlign="center center" class="mat-elevation-z12 box-content-inner card-content">
                    <p class="box-text" translate>messages.ticket_reader_not_authorized</p>
                </mat-card>
                <button mat-raised-button color="primary" class="mat-elevation-z12 box-button" type="button" (click)="back()"><span translate>tinyAll.back</span></button>
            </mat-card-content>
        </mat-card>

    </div>
</div>
<app-loading></app-loading>
