import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-can-go-back-dialog',
  templateUrl: './can-go-back-dialog.component.html',
  styleUrls: ['./can-go-back-dialog.component.scss']
})
export class CanGoBackDialogComponent { 

    parentData: any; 
    constructor(
      public dialogRef: MatDialogRef<CanGoBackDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.parentData = data; 
    }
  
    cancel(): void {
      this.dialogRef.close();
    }
    exit(): void {
      this.dialogRef.close(true);
    }
  
  }
  