import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { HttpService } from '../../../service/http.service';
import { RouteConstants } from '../../../app.constants';
import { ContentType } from '../../../models/content-type-enum';
import { environment } from '../../../../environments/environment';
import { ShortUrlHandlerComponent } from '../../../components/short-url-handler/short-url-handler.component';
import { CanDeleteDialogComponent } from '../../../components/can-delete-dialog/can-delete-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';
import { setDefaultPageHead } from '../../../util/seo-utils';
import { ActCatViewEntity } from '../../../models/activity-category-view';

@Component({
  selector: 'app-category-owner-view',
  templateUrl: './category-owner-view.component.html',
  styleUrls: ['./category-owner-view.component.scss'],
})
export class CategoryOwnerViewComponent implements OnInit {
  readonly apiUrl = environment.apiUrl;
  readonly noImage =
    'https://cdn.pixabay.com/photo/2019/04/29/16/10/connection-4166469_640.png';
  hubId = null;
  categoryId = null;
  category = null;
  items: ActCatViewEntity[] = null;
  settings: Settings;
  handler = null;

  constructor(
    private router: Router,
    private httpService: HttpService,
    private appSettings: AppSettings,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    protected snackBar: MatSnackBar,
    public translate: TranslateService,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    this.settings = this.appSettings.settings;
  }

  public ngOnInit(): void {
    setDefaultPageHead(this.titleService, this.metaTagService, false);
    this.hubId = this.route.snapshot.queryParams['hubId'];
    this.categoryId = this.route.snapshot.queryParams['categoryId'];
    this.handler = this.route.snapshot.queryParams['handler'];
    this.retrieveContent();
  }

  get qrCode() {
    if (!this.handler || !this.hubId || !this.categoryId) return false;
    return ShortUrlHandlerComponent.shortActCatUrl(this.categoryId);
  }

  private async retrieveContent() {
    try {
      const CATEGORY_URL = `${this.apiUrl}/categories/v1/${this.categoryId}`;
      this.category = await this.httpService.doGet(CATEGORY_URL).toPromise();

      if (this.category) {
        const URL = `${this.apiUrl}/actcatview/v1/owner-search?page=0&size=500&querySearch=hubId:'${this.hubId}' AND contentType!'${ContentType.CATEGORY}' AND content.categoryId:'${this.categoryId}'&sort=order,asc`;
        const response = await this.httpService.doGet(URL).toPromise();

        if (response.content && response.content.length > 0) {
          this.items = response.content;
        }
      }
    } catch (error) {
      console.error('Error getting category: ', error);
    }
  }

  public goToActivity(item: any): void {
    const start = this.handler
      ? `${this.handler}/${item.type}`
      : `/~${item.type}`;
    let url = `/${start}/manage?hubId=${this.hubId}&activityId=${item.id}`;
    if (item.categoryId) {
      url += `&categoryId=${item.categoryId}`;
    }
    if (this.handler) {
      url += `&handler=${this.handler}`;
    }
    this.router.navigateByUrl(url);
  }

  public back(): void {
    this.router.navigateByUrl(`${RouteConstants.hub}/owner`);
  }

  editCategory(): void {
    const start = this.handler
      ? `${this.handler}/category`
      : `/${RouteConstants.category}`;
    let url = `/${start}/manage?hubId=${this.hubId}&categoryId=${this.categoryId}`;
    if (this.handler) {
      url += `&handler=${this.handler}`;
    }
    this.router.navigateByUrl(url);
  }

  canDelete() {
    const dialogRef = this.dialog.open(CanDeleteDialogComponent, {
      minWidth: '240px',
      data: {
        title: 'titles.delete_category',
        content: 'messages.delete_category',
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          this.settings.loadingSpinner = true;
          await this.httpService
            .doDelete(`${this.apiUrl}/categories/v1/${this.categoryId}`)
            .toPromise();
          this.showSnackBar(
            this.translate.instant('messages.category_deleted'),
            'OK',
            2000
          );
          this.router.navigateByUrl(`${RouteConstants.hub}/owner`).then();
          this.settings.loadingSpinner = false;
        } catch (error) {
          this.settings.loadingSpinner = false;
          this.showSnackBar(
            this.translate.instant('messages.category_delete_error'),
            'OK',
            2000
          );
        }
      }
    });
  }

  showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }
}
