import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from '../../../service/http.service';
import { environment } from '../../../../environments/environment';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../../auth/auth.serviceV2';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppSettings } from '../../../app.settings';
import {
  faMoneyBillAlt,
  faCreditCard,
} from '@fortawesome/free-regular-svg-icons';
import { faWallet, faCheck, faLink } from '@fortawesome/free-solid-svg-icons';
import { PaymentType } from '../../../models/payment-type-enum';
import { firstValueFrom } from 'rxjs';
import { setDefaultPageHead } from '../../../util/seo-utils';
import { Meta, Title } from '@angular/platform-browser';
import { FeatureFlagsService } from '../../../service/feature-flags.service';
import { FeatureFlag } from '../../../models/feature-flag-enum';
import { HubEntity, UpdateHubCommand } from '../../../models/hub';
import { AccountStatus } from '../../../models/account-status-enum';
import { HubCreationStatus } from '../../../models/hub-creation-status-enum';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss'],
})
export class PaymentMethodsComponent implements OnInit {
  readonly apiUrl = environment.apiUrl;
  readonly faCheck = faCheck;
  readonly faWallet = faWallet;
  readonly faMoneyBillAlt = faMoneyBillAlt;
  readonly faCreditCard = faCreditCard;
  readonly faLink = faLink;

  isTinyAllPayAvailable = false;

  openBankInfo = false;

  savingBankInfo = false;
  savingPayDelivery = false;
  savingTinyAllPay = false;
  savingCCinStorePickup = false;
  savingCashInStorePickup = false;
  savinginCCDelivery = false;
  savingCCinAdvance = false;
  savingPaymentLink = false;

  showPaymentMethod = 0;

  form: UntypedFormGroup;
  user;
  settings;
  hub: HubEntity;

  @Input() simplified = false;
  @Output() onSave: EventEmitter<any> = new EventEmitter();

  constructor(
    private authService: AuthService,
    private httpService: HttpService,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    protected snackBar: MatSnackBar,
    private appSettings: AppSettings,
    protected location: Location,
    public featureFlags: FeatureFlagsService,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    this.settings = this.appSettings.settings;
  }

  async ngOnInit() {
    setDefaultPageHead(this.titleService, this.metaTagService, false);
    this.user = this.authService.getUserProfile();
    this.form = this.fb.group({
      bankName: ['', Validators.required],
      accountNumber: ['', Validators.required],
      accountType: ['', Validators.required],
      idType: ['', Validators.required],
      idNumber: ['', Validators.required],
      accountName: ['', Validators.required],
    });
    await this.retrieveHub();
    this.isTinyAllPayAvailable = await this.featureFlags.getFeatureFlag(
      FeatureFlag.TINYALL_PAY
    );
    this.settings.loadingSpinner = false;
  }

  get AccountStatus() {
    return AccountStatus;
  }

  get bankName() {
    return this.form.get('bankName');
  }

  get accountNumber() {
    return this.form.get('accountNumber');
  }

  get accountType() {
    return this.form.get('accountType');
  }

  get idType() {
    return this.form.get('idType');
  }

  get idNumber() {
    return this.form.get('idNumber');
  }

  get accountName() {
    return this.form.get('accountName');
  }

  get saving(): boolean {
    return (
      this.savingBankInfo ||
      this.savingPayDelivery ||
      this.savingTinyAllPay ||
      this.savingCCinStorePickup ||
      this.savingCashInStorePickup ||
      this.savinginCCDelivery ||
      this.savingCCinAdvance ||
      this.savingPaymentLink
    );
  }

  get hasTinyAllPay() {
    return (
      this.hub &&
      this.hub.supportedPaymentTypes &&
      this.hub.supportedPaymentTypes.includes(PaymentType.TINYALL_PAY)
    );
  }

  get hasPayDelivery() {
    return (
      this.hub &&
      this.hub.supportedPaymentTypes &&
      this.hub.supportedPaymentTypes.includes(PaymentType.CASH)
    );
  }

  get hasPayCCDelivery() {
    return (
      this.hub &&
      this.hub.supportedPaymentTypes &&
      this.hub.supportedPaymentTypes.includes(PaymentType.CC_DELIVERY)
    );
  }

  get hasPayCCinAdvance() {
    return (
      this.hub &&
      this.hub.supportedPaymentTypes &&
      this.hub.supportedPaymentTypes.includes(PaymentType.CC_IN_ADVANCE)
    );
  }

  get hasPaymentLink() {
    return (
      this.hub &&
      this.hub.supportedPaymentTypes &&
      this.hub.supportedPaymentTypes.includes(PaymentType.PAYMENT_LINK)
    );
  }

  get hasBankTransfer() {
    return (
      this.hub &&
      this.hub.supportedPaymentTypes &&
      this.hub.supportedPaymentTypes.includes(PaymentType.BANK_TRANSFERENCE)
    );
  }

  private async retrieveHub() {
    try {
      const hubUrl = `${this.apiUrl}/hubs/v1`;
      const hubResponse = await firstValueFrom(this.httpService.doGet(hubUrl));
      this.hub = hubResponse;
      this.setForm(hubResponse.bankTransactionInfo);
    } catch (error) {
      console.log('Error getting activity: ', error);
    }
  }

  async enableBankTransfer() {
    this.settings.loadingSpinner = true;
    const supportedPaymentTypesSet = new Set(this.hub.supportedPaymentTypes);
    supportedPaymentTypesSet.add(PaymentType.BANK_TRANSFERENCE);
    const body = {
      ...this.hub,
      supportedPaymentTypes: Array.from(supportedPaymentTypesSet),
      bankTransactionInfo: this.form.getRawValue(),
      creationStatus: this.simplified ? HubCreationStatus.PAYMENT_METHODS : HubCreationStatus.NONE,
    };
    try {
      const response = await firstValueFrom(
        this.httpService.doPut(`${this.apiUrl}/hubs/v1`, body)
      );
      this.settings.loadingSpinner = false;
      this.hub = response;
      this.setForm(response.bankTransactionInfo);
      this.showSnackBar('Operation Successful', 'OK', 2000);
    } catch (error) {
      this.settings.loadingSpinner = false;
      this.showSnackBar('Error Processing Your Request', 'OK', 2000);
      console.error('Error updating hub: ', error);
    }
  }

  async disableBankTransfer() {
    this.settings.loadingSpinner = true;
    const supportedPaymentTypesSet = new Set(this.hub.supportedPaymentTypes);
    supportedPaymentTypesSet.delete(PaymentType.BANK_TRANSFERENCE);
    this.form.reset();
    const body = {
      ...this.hub,
      supportedPaymentTypes: Array.from(supportedPaymentTypesSet),
      bankTransactionInfo: this.form.getRawValue(),
      creationStatus: this.simplified ? HubCreationStatus.PAYMENT_METHODS : HubCreationStatus.NONE,
    };
    try {
      const response = await firstValueFrom(
        this.httpService.doPut(`${this.apiUrl}/hubs/v1`, body)
      );
      this.settings.loadingSpinner = false;
      this.hub = response;
      this.setForm(response.bankTransactionInfo);
      this.showSnackBar('Operation Successful', 'OK', 2000);
    } catch (error) {
      this.settings.loadingSpinner = false;
      this.showSnackBar('Error Processing Your Request', 'OK', 2000);
      console.error('Error updating hub: ', error);
    }
  }

  async updateHub(supportedPaymentTypesSet: any, bankTransactionInfo: any) {
    this.settings.loadingSpinner = true;
    const {
      id,
      name,
      urlHandler,
      email,
      userId,
      phoneNumber,
      image,
      address,
      active,
      unlisted,
      exceptions,
      tags,
      socialLinks,
      customLinks,
      location,
      scheduleBean,
      icon,
      infoText,
    } = this.hub;

    const body: UpdateHubCommand = {
      id,
      name,
      urlHandler,
      email,
      userId,
      phoneNumber,
      image,
      address,
      active,
      unlisted,
      exceptions,
      tags,
      socialLinks,
      customLinks,
      location,
      scheduleBean,
      icon,
      infoText,
      supportedPaymentTypes: Array.from(supportedPaymentTypesSet),
      bankTransactionInfo,
      creationStatus: this.simplified ? HubCreationStatus.PAYMENT_METHODS : HubCreationStatus.NONE,
    };

    try {
      const response: HubEntity = await firstValueFrom(
        this.httpService.doPut(`${this.apiUrl}/hubs/v1`, body)
      );
      this.hub = response;
      this.setForm(response.bankTransactionInfo);
      this.showSnackBar('Operation Successful', 'OK', 2000);
      this.onSave.emit(this.hub);
    } catch (error) {
      this.showSnackBar('Error Processing Your Request', 'OK', 2000);
      console.error('Error updating hub: ', error);
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  setForm(bankTransactionInfo: any) {
    if (!bankTransactionInfo) {
      this.form.reset();
      return;
    }

    const {
      bankName,
      accountNumber,
      accountType,
      idType,
      idNumber,
      accountName,
    } = bankTransactionInfo;

    this.form.patchValue({ bankName });
    this.form.patchValue({ accountNumber });
    this.form.patchValue({ accountType });
    this.form.patchValue({ idType });
    this.form.patchValue({ idNumber });
    this.form.patchValue({ accountName });

    this.form.markAsPristine();
  }

  public back(): void {
    this.location.back();
  }

  handleTinyAllPay() {
    if (this.saving || !this.hub || !this.isTinyAllPayAvailable) return;
    const supportedPaymentTypesSet = new Set(this.hub.supportedPaymentTypes);
    if (supportedPaymentTypesSet.has(PaymentType.TINYALL_PAY)) {
      supportedPaymentTypesSet.delete(PaymentType.TINYALL_PAY);
    } else {
      supportedPaymentTypesSet.add(PaymentType.TINYALL_PAY);
    }
    this.updateHub(supportedPaymentTypesSet, this.form.getRawValue());
  }

  handlePayDelivery() {
    if (this.saving || !this.hub) return;
    const supportedPaymentTypesSet = new Set(this.hub.supportedPaymentTypes);
    if (supportedPaymentTypesSet.has(PaymentType.CASH)) {
      supportedPaymentTypesSet.delete(PaymentType.CASH);
    } else {
      supportedPaymentTypesSet.add(PaymentType.CASH);
    }
    this.updateHub(supportedPaymentTypesSet, this.form.getRawValue());
  }

  handleCCDelivery() {
    if (this.saving || !this.hub) return;
    const supportedPaymentTypesSet = new Set(this.hub.supportedPaymentTypes);
    if (supportedPaymentTypesSet.has(PaymentType.CC_DELIVERY)) {
      supportedPaymentTypesSet.delete(PaymentType.CC_DELIVERY);
    } else {
      supportedPaymentTypesSet.add(PaymentType.CC_DELIVERY);
    }
    this.updateHub(supportedPaymentTypesSet, this.form.getRawValue());
  }

  handleCCinAdvance() {
    if (this.saving || !this.hub) return;
    const supportedPaymentTypesSet = new Set(this.hub.supportedPaymentTypes);
    if (supportedPaymentTypesSet.has(PaymentType.CC_IN_ADVANCE)) {
      supportedPaymentTypesSet.delete(PaymentType.CC_IN_ADVANCE);
    } else {
      supportedPaymentTypesSet.add(PaymentType.CC_IN_ADVANCE);
    }
    this.updateHub(supportedPaymentTypesSet, this.form.getRawValue());
  }

  handlePaymentLink() {
    if (this.saving || !this.hub) return;
    const supportedPaymentTypesSet = new Set(this.hub.supportedPaymentTypes);
    if (supportedPaymentTypesSet.has(PaymentType.PAYMENT_LINK)) {
      supportedPaymentTypesSet.delete(PaymentType.PAYMENT_LINK);
    } else {
      supportedPaymentTypesSet.add(PaymentType.PAYMENT_LINK);
    }
    this.updateHub(supportedPaymentTypesSet, this.form.getRawValue());
  }

  handleBankInfoSave() {
    if (this.saving || !this.hub) return;
    const supportedPaymentTypesSet = new Set(this.hub.supportedPaymentTypes);
    supportedPaymentTypesSet.add(PaymentType.BANK_TRANSFERENCE);
    this.updateHub(supportedPaymentTypesSet, this.form.getRawValue());
  }

  disableBankInfoSave() {
    if (this.saving || !this.hub) return;
    const supportedPaymentTypesSet = new Set(this.hub.supportedPaymentTypes);
    if (supportedPaymentTypesSet.has(PaymentType.BANK_TRANSFERENCE)) {
      supportedPaymentTypesSet.delete(PaymentType.BANK_TRANSFERENCE);
    }
    this.updateHub(supportedPaymentTypesSet, null);
  }

  toggleBankTransfer() {
    if (this.saving) return;
    this.openBankInfo = !this.openBankInfo;
  }

  showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  setOpen(paymentMethod: number) {
    this.showPaymentMethod = paymentMethod;
  }
}
