import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Feature, GeocoderService } from '../../service/geocoder.service';

@Component({
  selector: 'app-address-picker',
  templateUrl: './address-picker.component.html',
  styleUrls: ['./address-picker.component.scss'],
})
export class AddressPickerComponent implements OnInit {
  @Input() field: UntypedFormControl;
  @Input() coordinates: number[];
  @Output() addressChanged: EventEmitter<any> = new EventEmitter();

  filteredOptions: Observable<Feature[]>;
  temOptions: any = {};

  constructor(private geocoderService: GeocoderService) {}

  ngOnInit() {
    this.filteredOptions = this.field.valueChanges.pipe(
      startWith(null),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((value) => this._filter(value))
    );
  }

  private async _filter(value: string) {
    const filterValue = this.field.value;
    if (!filterValue) {
      return [];
    }

    const response = await this.geocoderService.searchWord(
      filterValue,
      this.coordinates
    );

    if (response) {
      const items = new Set();
      for (const result of response) {
        if (result.place_name) {
          items.add(result.place_name);
          this.temOptions[result.place_name] = result.center;
        }
      }
      return Array.from(items) as Feature[];
    } else {
      this.temOptions = {};
      return [];
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.temOptions[event.option.value]) {
      this.addressChanged.emit(this.temOptions[event.option.value]);
    }
  }
}
