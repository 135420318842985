import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { firstValueFrom } from "rxjs";
import { AppSettings } from "../../../../app.settings";
import { HttpService } from "../../../../service/http.service";
import { environment } from "../../../../../environments/environment";
import {
  ActivityReservationEntity,
  ReservationEntity,
  UpdateReservationFromClientCommand,
} from "../../../../models/activity-reservation";
import { PaymentType } from "../../../../models/payment-type-enum";
import { HubEntity } from "../../../../models/hub";

@Component({
  selector: "app-request-reservation-details",
  templateUrl: "./request-reservation-details.component.html",
  styleUrls: ["./request-reservation-details.component.scss"],
})
export class RequestReservationDetailsComponent implements OnInit {
  @Input() report: ReservationEntity;
  @Output() updated: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  private readonly apiUrl = environment.apiUrl;
  settings = null;
  reportDetail: ReservationEntity;
  form: UntypedFormGroup;
  activity: ActivityReservationEntity;
  reservationTime: Date;
  hub: HubEntity;

  constructor(
    private httpService: HttpService,
    private appSettings: AppSettings,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.settings.loadingSpinner = true;
    this.retrieve().then(() => {
      this.createForm();
      this.settings.loadingSpinner = false;
    });
  }

  get status() {
    return this.form.get("status") as UntypedFormControl;
  }

  private async retrieve(): Promise<any> {
    try {
      const reportURL = `${this.apiUrl}/activity-reservation/v1/reservation/${this.report.id}`;
      const response: ReservationEntity = await firstValueFrom(
        this.httpService.doGet(reportURL)
      );
      if (response) {
        this.reportDetail = response;
        this.reservationTime = response.reservationTime
          ? new Date(response.reservationTime)
          : null;
      }

      const activityURL = `${this.apiUrl}/activity-reservation/v1/${this.reportDetail.activityId}`;
      const responseActivity: ActivityReservationEntity = await firstValueFrom(
        this.httpService.doGet(activityURL)
      );
      if (responseActivity) {
        this.activity = responseActivity;
      }
    } catch (error) {
      this.settings.loadingSpinner = false;
      console.log("Error getting activity: ", error);
    }

    await this.retrieveHub(this.report.hubId);
  }

  private async retrieveHub(hubId: string) {
    try {
      const hubUrl = `${this.apiUrl}/hubs/v1/${hubId}`;
      const hubResponse: HubEntity = await firstValueFrom(
        this.httpService.doGet(hubUrl)
      );
      if (!hubResponse) return;
      this.hub = hubResponse;
    } catch (error) {
      console.log('Error getting activity: ', error);
    }
  }


  private async createForm() {
    this.form = this.fb.group({
      activityId: [this.reportDetail.activityId],
      hubId: [this.report.hubId],
      clientId: [this.report.clientId],
      reservationId: [this.report.id],
      reservationTime: [this.reportDetail.reservationTime],
      status: [this.reportDetail.status],
    });
  }

  async sendRequest() {
    const url = `${this.apiUrl}/activity-reservation/v1/reservation-from-owner`;
    this.settings.loadingSpinner = true;
    const rawValues = this.form.getRawValue();
    const {
      reservationId,
      hubId,
      activityId,
      clientId,
      status,
    } = rawValues;
    const requestBody: UpdateReservationFromClientCommand = {
      reservationId,
      hubId,
      activityId,
      userId: clientId,
      status,
    };

    try {
      const response: ReservationEntity = await firstValueFrom(
        this.httpService.doPut(url, requestBody)
      );
      this.reportDetail = response;
      this.updated.emit(this.reportDetail);
      this.showSnackBar("Operation successful ", "OK", 2000);
    } catch (error) {
      this.form.get("status").setValue(this.reportDetail.status);
      console.log("Error sending request: ", error);
      this.showSnackBar("Error Processing Your Request", "OK", 2000);
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  onDateUpdate(event: string) {
    this.reservationTime = new Date(event);
    this.reportDetail.reservationTime = event;
    this.updated.emit(this.reportDetail);
    this.showSnackBar("Reservation updated ", "OK", 2000);
  }

  getAvailableStatus(currentStatus: string): string[] {
    switch (currentStatus) {
      case "OPEN":
        if(this.reportDetail.chosenPaymentType === PaymentType.TINYALL_PAY) return ["OPEN"];
        return ["OPEN", "CANCELED"];
      case "CANCELED":
      case "REJECTED":
      case "RESOLVED":
      case "APPROVED":
        return [currentStatus];
    }
    return [currentStatus];
  }

  private showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  get totalPrice(): number {
    return Number(this.reportDetail?.totalPrice || 0);
  }
}
