<div id="fab-dismiss" *ngIf="fabTogglerState==='active'" (click)="onToggleFab()">
</div>
<div class="fab-container">
    <button color="primary" mat-fab class="fab-toggler" (click)="onToggleFab()">
        <app-info-tooltip info="tooltip.hub_plus_button"></app-info-tooltip>
        <i class="material-icons" [@fabToggler]="{value: fabTogglerState}">add</i>
    </button>
    <div [@speedDialStagger]="buttons.length">
        <ng-container *ngFor="let btn of buttons">
            <button *ngIf="btn.icon !== 'sort' || canSort" (click)="btn.onClick()" mat-mini-fab class="fab-secondary" color="secondary">
                <app-info-tooltip *ngIf="btn.icon == 'category'" info="tooltip.hub_add_category_button"></app-info-tooltip>
                <span class="label">{{btn.label}}</span>
                <i class="material-icons">{{btn.icon}}</i>
            </button>
        </ng-container>
    </div>
</div>