import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageType } from '../auth/local-storage-type.enum';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public count = new BehaviorSubject<number>(0);

  clearNotifications() {
    this.count.next(0);
    const nDate = new Date();
    localStorage.setItem(
      LocalStorageType.LatestOrderNotificationUpdateDatetime,
      nDate.toISOString()
    );
  }
}
