import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import QUANTITY_SELECTOR from '../../../../util/quantity-selector';
import CURRENCY_SYMBOLS from '../../../../util/currency-symbols';
import { 
  faLink
 } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss']
})
export class ProductItemComponent implements OnInit {

  @Input() item: any;
  @Input() selected: boolean;
  @Input() loading: boolean;
  @Input() selectedForm: UntypedFormGroup;
  @Input() currency: string;

  @Output() onSelectItem = new EventEmitter();
  @Output() onAddToCart = new EventEmitter();

  actualVariant: any;

  readonly quantitySelector = QUANTITY_SELECTOR;
  readonly currencySymbols = CURRENCY_SYMBOLS;
  readonly faLink = faLink;
  readonly noImage = '/assets/img/app/default-product.png';

  constructor() {}
  ngOnInit(): void {
    this.actualVariant = this.item.options[0];
  }

  get quantity() {
    return this.selectedForm.get('quantity') as UntypedFormControl;
  }

  get variant() {
    return this.selectedForm.get('variant') as UntypedFormControl;
  }

  selectItem() {
    if (this.loading) {
      return;
    }
    this.selectedForm.reset();

    if (!this.actualVariant) {
      this.actualVariant = this.item.options[0];
    }

    this.selectedForm.get('id').setValue(this.item.id);
    this.selectedForm.get('variant').setValue(this.actualVariant);
    this.selectedForm.get('quantity').setValue(1);

    if(this.item.available) {
      this.selectedForm.enable();
    } else {
      this.selectedForm.disable();
    }

    setTimeout(() => {
      const element = document.getElementById(this.item.id);
      if (element !== null) {
        element.scrollIntoView();
      }
    }, 200);
    this.onSelectItem.emit(this.item);
  }

  addToCart() {
    if (this.loading) {
      return;
    }
    this.onAddToCart.emit(this.item);
  }

  variantChange(event: any) {
    this.actualVariant = event.value;
  }

  close() {
    this.selectedForm.get('id').setValue('');
    setTimeout(() => {
      const element = document.getElementById(this.item.id);
      if (element !== null) {
        element.scrollIntoView();
      }
    }, 200);
  }

  getTotal(price, quantity) {
    return Number(price) * Number(quantity);
  }

  getImages() {
    if(!this.item.images || this.item.images.length === 0) {
      return [this.noImage];
    }
    return this.item.images;
  }


}
