<div class="list-container" [ngClass]="{ 'border border-red-600': error }">
  <h2 class="subtitle" translate>formFields.linked_activities</h2>
  <mat-error *ngIf="error">{{ error | translate }} </mat-error>
  <mat-form-field appearance="outline" class="search-field">
    <input
      matInput
      type="search"
      [(ngModel)]="searchField"
      autocomplete="off"
      (keyup)="filterItems()"
      (change)="filterItems()"
      placeholder="{{ 'formFields.search' | translate }}"
    />
    <button
      mat-button
      mat-icon-button
      matSuffix
      (click)="clearSearchField()"
      *ngIf="searchField"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <div fxLayout="column" fxLayoutGap="5px">
    <div
      class="questions-boundary"
      novalidate
      fxLayout="column"
      fxLayoutAlign="center"
    >
      <div *ngFor="let item of filteredItems; let i = index">
        <mat-card class="card-container" (click)="toggle(item.id)">
          <div fxLayout="row" class="hanlde-container">
            <fa-icon
              class="list-item-menu-icon"
              [icon]="activityIconMap[item.contentType]"
            ></fa-icon>
            <h3>{{ item.content.name }}</h3>
            <fa-icon
              *ngIf="selectedActivities.includes(item.id)"
              class="list-item-menu-icon-remove"
              [icon]="faCheck"
            ></fa-icon>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
