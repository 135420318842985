import { Component, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

@Component({
  selector: "app-quantity-picker",
  templateUrl: "./quantity-picker.component.html",
  styleUrls: ["./quantity-picker.component.scss"],
})
export class QuantityPickerComponent {
  options = [];

  public perfectScrollBarConfig: PerfectScrollbarConfigInterface = {};

  @Input() name: string;
  @Input() form: UntypedFormGroup;
  @Input() label: string = "";
  @Input() set quantity(value: number) {
    this.options = Array(value)
      .fill(0)
      .map((_, i) => i + 1);
  }
}
