
<div fxLayout="row">
    <h2 class="title" translate>{{title}}</h2>
</div>
<mat-error *ngIf="reservationSelectedControl.hasError('required')" translate>formErrors.reservation_time_required</mat-error>
<mat-card class="custom-activity-card" [ngClass]="{'error': reservationSelectedControl.hasError('required')}">
    <mat-card-content>
        <div fxLayout="column">
            <mat-calendar #calendar 
            (selectedChange)="select($event,calendar)" 
            [dateClass]="isSelected"
            [dateFilter]="filterDates"
            ></mat-calendar>
            <mat-radio-group [formControl]="reservationSelectedControl" (change)="onChanged($event)" aria-label="Select an option">
                <div *ngIf="isLoading; else noLoading" class="loading" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
                    <mat-spinner color="primary" diameter="60"></mat-spinner>
                </div>
                
                <ng-template #noLoading>
                    <div fxLayout="row" >
                        <div fxLayout="column" *ngFor="let slot of getReservationCellsMinutes(reservationDuration); let i = index" fxFlex>
                            <div class="head" fxFlex fxLayoutAlign="center center"></div>
                            <div class="hour" *ngFor="let hour of daySchedule; let j = index;" fxFlex>
                                <mat-radio-button [disabled]="isBusy(hour, i)" color="primary" [value]="getValue(hour, i)">&nbsp;
                                    <span class="radio-text">{{getHourAMPM(hour, slot)}}</span>
                                </mat-radio-button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </mat-radio-group>
        </div>
    </mat-card-content>
</mat-card>