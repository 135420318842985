import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.serviceV2';
import { environment } from '../../environments/environment';

@Injectable()
export class ImageService {
  private readonly imageUrl =
    'https://api.cloudinary.com/v1_1/diuejch4r/image/upload';
  private readonly apiUrl = environment.apiUrl;
  private readonly imageFolder = environment.imageFolder;

  constructor(private httpClient: HttpClient, private auth: AuthService) {}

  public async uploadImage(image: File) {
    const { secure_url } = await firstValueFrom(this.uploadImageToCloudinary(
      image
    ));
    const tokenId = this.auth.getIdToken();
    const httpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + tokenId,
      Accept: 'application/json',
    });
    const url = this.apiUrl + '/image-service/v1?imageUrl=' + secure_url;
    await firstValueFrom(this.httpClient.post(url, {}, { headers: httpHeaders }));
    return secure_url;
  }

  public uploadImageToCloudinary(image: File): Observable<any> {
    const formData = new FormData();
    formData.append('api_key', '217387812335733');
    formData.append('upload_preset', 'id6aloak');
    formData.append('api_secret', '2X-U7iU3JwwDQKPPBtIV3dzyJ1I');
    formData.append('file', image);
    formData.append('folder', this.imageFolder);
    return this.httpClient
      .post(this.imageUrl, formData)
      .pipe(map((response) => this.addAutoQualityToUrl(response)));
  }

  public deleteImage(imageUrl: any): Observable<any> {
    const tokenId = this.auth.getIdToken();
    const httpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + tokenId,
      Accept: 'application/json',
    });
    const url = this.apiUrl + '/image-service/v1?imageUrl=' + imageUrl;
    return this.httpClient.delete(url, { headers: httpHeaders });
  }

  private addAutoQualityToUrl(object: any): any {
    if (object.secure_url) {
      const url = object.secure_url.replace('upload/', 'upload/q_auto/');
      object.secure_url = url;
    }
    return object;
  }

  public async cloneImage(imageUrl: string) {
    const tokenId = this.auth.getIdToken();
    const httpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + tokenId,
      Accept: 'application/json',
    });
    const url = this.apiUrl + '/image-service/v1?imageUrl=' + imageUrl;
    await firstValueFrom(this.httpClient.post(url, {}, { headers: httpHeaders }));
    return imageUrl;
  }
}
