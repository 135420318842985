<div class="page-container">
    <app-comment-management [entityType]="entityType" [entityId]="entityId"></app-comment-management>
    <mat-divider *ngIf="user"></mat-divider>
    <div *ngIf="comments.length > 0" class="filter-container" fxLayoutAlign="start center">
        <mat-icon>sort</mat-icon>
        <mat-button-toggle-group [(ngModel)]="sort" (change)="onSort()">
            <mat-button-toggle value="createdOn"><span translate>buttons.most_recent</span></mat-button-toggle>
            <mat-button-toggle value="like"><span translate>buttons.most_helpful</span></mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <app-comment *ngFor="let comment of comments" [entityType]="entityType" [entityName]="entityName" [entityId]="entityId" [comment]="comment" [user]="user"></app-comment>
    <mat-paginator *ngIf="length > pageSize" #paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent($event)">
    </mat-paginator>
    <div *ngIf="comments.length === 0" class="no-comment">
        <fa-icon class="no-comment-icon" [icon]="noCommentIcon"></fa-icon>
        <p translate>messages.no_users_comments</p>
    </div>
</div>