<div fxLayout="column wrap" class="page-container">
    <div *ngIf="(order && order.shoppingList && order.shoppingList.length > 0) || loading;" class="list-container">
        <div *ngIf="loading && !settings.loadingSpinner; else noLoading" class="loading" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
            <mat-spinner color="primary" diameter="60"></mat-spinner>
        </div>
        <ng-template #noLoading>
            <div fxLayout="column" fxLayoutGap="15px" *ngIf="products">
                <app-order-product-item *ngFor="let item of order.shoppingList" 
                    [item]="item"
                    [product]="products.get(item.productItemId)"
                    [currency]="currency"
                    >
                </app-order-product-item>
            </div>
        </ng-template>
    </div>
</div>
