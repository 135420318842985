import { Component, OnInit, Input } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormArray,
  UntypedFormGroup,
  Validators,
  ValidatorFn,
} from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { TranslateService } from '@ngx-translate/core';
import ICON_MAP from '../../util/icon-map';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-question-list-management',
  templateUrl: './question-list-management.component.html',
  styleUrls: ['./question-list-management.component.scss'],
})
export class QuestionListManagementComponent implements OnInit {
  
  @Input()
  questionsForm: UntypedFormArray;
  @Input()
  actForm: UntypedFormGroup;
  @Input()
  selectedInnerQuestion: Map<number, Map<number, Set<number>>>;

  form: UntypedFormGroup;
  selectedQuestion = new Set<number>();
  ICONS = ICON_MAP;
  optionName: string;

  constructor(private translate: TranslateService, private fb: UntypedFormBuilder) {
    this.optionName = this.translate.instant('questionsDialog.option');
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      questions: this.questionsForm,
    });
  }

  // Question Form Operations

  validateQuestions(selected): boolean {
    if (this.questionsForm.controls[selected].invalid) {
      this.questionsForm.controls[selected].markAllAsTouched();
      return false;
    }
    return true;
  }

  validateInnerQuestions(
    questionIndex: number,
    optionIndex: number,
    selected: number
  ): boolean {
    const OPTIONS_CONTENT_ARRAY = this.questionsForm.controls[
      questionIndex
    ].get('questionGroups') as UntypedFormArray;
    const OPTION_CONTENT = OPTIONS_CONTENT_ARRAY.controls[
      optionIndex
    ] as UntypedFormArray;
    if (OPTION_CONTENT.controls[selected].invalid) {
      OPTION_CONTENT.controls[selected].markAllAsTouched();
      return false;
    }
    return true;
  }

  questionsCloser() {
    const selectedList = Array.from(this.selectedQuestion);
    for (const questionIndex of selectedList) {
      if (this.validateQuestions(questionIndex)) {
        this.selectedQuestion.delete(questionIndex);
      }
    }
  }

  innerQuestionsCloser(questionIndex: number, optionIndex: number) {
    const INNER_QUESTIONS_LIST = this.selectedInnerQuestion
      .get(questionIndex)
      .get(optionIndex);
    const selectedList = Array.from(INNER_QUESTIONS_LIST);
    for (const index of selectedList) {
      if (this.validateInnerQuestions(questionIndex, optionIndex, index)) {
        INNER_QUESTIONS_LIST.delete(index);
      }
    }
  }

  closeQuestion(index: number) {
    this.selectedQuestion.delete(index);
  }

  closeInnerQuestion(
    questionIndex: number,
    optionIndex: number,
    index: number
  ) {
    const INNER_QUESTIONS_LIST = this.selectedInnerQuestion
      .get(questionIndex)
      .get(optionIndex);
    INNER_QUESTIONS_LIST.delete(index);
  }

  selectQuestion(index: number): void {
    if (this.selectedQuestion.has(index)) {
      this.questionsCloser();
    } else {
      this.questionsCloser();
      this.selectedQuestion.add(index);
    }
    this.questionsForm.markAsDirty();
  }

  selectInnerQuestion(
    questionIndex: number,
    optionIndex: number,
    index: number
  ): void {
    const INNER_QUESTIONS_LIST = this.selectedInnerQuestion
      .get(questionIndex)
      .get(optionIndex);
    if (INNER_QUESTIONS_LIST.has(index)) {
      this.innerQuestionsCloser(questionIndex, optionIndex);
    } else {
      this.innerQuestionsCloser(questionIndex, optionIndex);
      INNER_QUESTIONS_LIST.add(index);
    }
    this.questionsForm.markAsDirty();
  }

  selectedQuestionHas(
    questionIndex: number,
    optionIndex: number,
    index: number
  ): boolean {
    return this.selectedInnerQuestion
      .get(questionIndex)
      .get(optionIndex)
      .has(index);
  }

  addQuestion(): void {
    this.questionsCloser();
    this.questionsForm.push(this.createQuestion());
    this.selectedQuestion.add(this.questionsForm.length - 1);
    const options = new Map();
    options.set(0, new Set());
    options.set(1, new Set());
    this.selectedInnerQuestion.set(this.questionsForm.length - 1, options);
    this.questionsForm.markAsDirty();
  }

  addInnerQuestion(questionIndex: number, optionIndex: number): void {
    this.innerQuestionsCloser(questionIndex, optionIndex);
    const OPTIONS_CONTENT_ARRAY = this.questionsForm.controls[
      questionIndex
    ].get('questionGroups') as UntypedFormArray;
    const OPTION_CONTENT = OPTIONS_CONTENT_ARRAY.controls[
      optionIndex
    ] as UntypedFormArray;
    OPTION_CONTENT.push(this.createInnerQuestion());
    this.selectedInnerQuestion
      .get(questionIndex)
      .get(optionIndex)
      .add(OPTION_CONTENT.length - 1);
    this.questionsForm.markAsDirty();
  }

  normalizeSet(set: Set<number>, index: number) {
    set.delete(index);
    const values = Array.from(set);
    set.clear();
    values.map((i: number) => {
      if (i > index) {
        set.add(i - 1);
      } else {
        set.add(i);
      }
    });
  }

  normalizeMap(map: Map<number, any>, index: number) {
    map.delete(index);
    const values = Array.from(map);
    map.clear();
    values.map((v: any[]) => {
      if (v[0] > index) {
        map.set(v[0] - 1, v[1]);
      } else {
        map.set(v[0], v[1]);
      }
    });
  }

  removeQuestion(index: number): void {
    this.normalizeSet(this.selectedQuestion, index);
    this.normalizeMap(this.selectedInnerQuestion, index);
    this.questionsForm.removeAt(index);
    for (const control of this.questionsForm.controls) {
      control.get('question').updateValueAndValidity();
    }
    this.questionsForm.markAsDirty();
    this.questionsForm.updateValueAndValidity();
    this.actForm.updateValueAndValidity();
  }

  removeInnerQuestion(
    questionIndex: number,
    optionIndex: number,
    index: number
  ): void {
    const INNER_QUESTIONS_LIST = this.selectedInnerQuestion
      .get(questionIndex)
      .get(optionIndex);
    const OPTIONS_CONTENT_ARRAY = this.questionsForm.controls[
      questionIndex
    ].get('questionGroups') as UntypedFormArray;
    const OPTION_CONTENT = OPTIONS_CONTENT_ARRAY.controls[
      optionIndex
    ] as UntypedFormArray;
    this.normalizeSet(INNER_QUESTIONS_LIST, index);
    OPTION_CONTENT.removeAt(index);
    for (const control of OPTION_CONTENT.controls) {
      control.get('question').updateValueAndValidity();
    }
    OPTION_CONTENT.updateValueAndValidity();
    this.questionsForm.markAsDirty();
    this.questionsForm.updateValueAndValidity();
    this.actForm.updateValueAndValidity();
  }

  getQuestionOptions(index: number): UntypedFormArray {
    const OPTIONS = this.questionsForm.controls[index].get(
      'content'
    ) as UntypedFormArray;
    return OPTIONS;
  }

  getQuestionOptionsControls(index: number): UntypedFormGroup[] {
    const OPTIONS = this.questionsForm.controls[index].get(
      'content'
    ) as UntypedFormArray;
    return OPTIONS.controls as UntypedFormGroup[];
  }

  getInnerQuestionOptions(
    questionIndex: number,
    optionIndex: number,
    index: number
  ): UntypedFormArray {
    const OPTIONS_CONTENT_ARRAY = this.questionsForm.controls[
      questionIndex
    ].get('questionGroups') as UntypedFormArray;
    const OPTION_CONTENT = OPTIONS_CONTENT_ARRAY.controls[
      optionIndex
    ] as UntypedFormArray;
    const OPTIONS = OPTION_CONTENT.controls[index].get('content') as UntypedFormArray;
    return OPTIONS;
  }

  getInnerQuestionOptionsControls(
    questionIndex: number,
    optionIndex: number,
    index: number
  ): UntypedFormGroup[] {
    const OPTIONS_CONTENT_ARRAY = this.questionsForm.controls[
      questionIndex
    ].get('questionGroups') as UntypedFormArray;
    const OPTION_CONTENT = OPTIONS_CONTENT_ARRAY.controls[
      optionIndex
    ] as UntypedFormArray;
    const OPTIONS = OPTION_CONTENT.controls[index].get('content') as UntypedFormArray;
    return OPTIONS.controls as UntypedFormGroup[];
  }

  getQuestionOptionsContent(index: number): UntypedFormArray {
    const OPTIONS_CONTENT = this.questionsForm.controls[index].get(
      'questionGroups'
    ) as UntypedFormArray;
    return OPTIONS_CONTENT;
  }

  getInnerQuestionControls(index: number, option: number) {
    const OPTIONS_CONTENT_ARRAY = this.questionsForm.controls[index].get(
      'questionGroups'
    ) as UntypedFormArray;
    const OPTION_CONTENT = OPTIONS_CONTENT_ARRAY.controls[option] as UntypedFormArray;
    return OPTION_CONTENT.controls as UntypedFormGroup[];
  }

  getInnerQuestions(index: number, option: number) {
    const OPTIONS_CONTENT_ARRAY = this.questionsForm.controls[index].get(
      'questionGroups'
    ) as UntypedFormArray;
    const OPTION_CONTENT = OPTIONS_CONTENT_ARRAY.controls[option] as UntypedFormArray;
    return OPTION_CONTENT;
  }

  addOption(index: number): void {
    const optionsForm = this.getQuestionOptions(index);
    const optionsFormContent = this.getQuestionOptionsContent(index);
    const OPTION = this.createOption(
      `${this.optionName} ${optionsForm.length + 1}`,
      '0.00'
    );
    OPTION.get('name').markAsTouched();
    optionsForm.push(OPTION);
    optionsFormContent.push(this.createOptionContent());
    this.selectedInnerQuestion
      .get(index)
      .set(optionsFormContent.length - 1, new Set());
    this.questionsForm.markAsDirty();
  }

  addInnerOption(
    questionIndex: number,
    optionIndex: number,
    index: number
  ): void {
    const optionsForm = this.getInnerQuestionOptions(
      questionIndex,
      optionIndex,
      index
    );
    const OPTION = this.createOption(
      `${this.optionName} ${optionsForm.length + 1}`,
      '0.00'
    );
    OPTION.get('name').markAsTouched();
    optionsForm.push(OPTION);
    this.questionsForm.markAsDirty();
  }

  removeOption(index: number, optionIndex: number): void {
    const optionsForm = this.getQuestionOptions(index);
    const optionsFormContent = this.getQuestionOptionsContent(index);
    if (optionsForm.length > 2) {
      optionsForm.removeAt(optionIndex);
      optionsFormContent.removeAt(optionIndex);
      this.normalizeMap(this.selectedInnerQuestion.get(index), optionIndex);
      this.questionsForm.markAsDirty();
      for (const control of optionsForm.controls) {
        control.get('name').updateValueAndValidity();
      }
    }
  }

  removeInnerOption(
    questionIndex: number,
    optionIndex: number,
    index: number,
    innerOptionIndex: number
  ): void {
    const optionsForm = this.getInnerQuestionOptions(
      questionIndex,
      optionIndex,
      index
    );
    if (optionsForm.length > 2) {
      optionsForm.removeAt(innerOptionIndex);
      this.questionsForm.markAsDirty();
      for (const control of optionsForm.controls) {
        control.get('name').updateValueAndValidity();
      }
    }
  }

  createQuestion(): UntypedFormGroup {
    return this.fb.group({
      content: this.fb.array([
        this.createOption(`${this.optionName} 1`, '0.00'),
        this.createOption(`${this.optionName} 2`, '0.00'),
      ]),
      questionGroups: this.fb.array([this.fb.array([]), this.fb.array([])]),
      question: ['', [Validators.required, RxwebValidators.unique()]],
      description: [''],
      required: [false],
      hidden: [false],
      type: ['TEXT', Validators.required],
      price: [false],
    });
  }

  createInnerQuestion() {
    return this.fb.group({
      content: this.fb.array([
        this.createOption(`${this.optionName} 1`, '0.00'),
        this.createOption(`${this.optionName} 2`, '0.00'),
      ]),
      question: ['', [Validators.required, RxwebValidators.unique()]],
      description: [''],
      required: [false],
      type: ['TEXT', Validators.required],
      price: [false],
    });
  }

  createOption(optionName: string, optionPrice: string): UntypedFormGroup {
    return this.fb.group({
      name: [optionName, [Validators.required, RxwebValidators.unique()]],
      price: [optionPrice],
    });
  }

  createOptionContent(): UntypedFormArray {
    return this.fb.array([]);
  }

  focusOption(option: UntypedFormGroup) {
    const value: string = option.value.name;
    if (value && (value.startsWith('Option') || value.startsWith('Opción'))) {
      option.controls.name.setValue('');
    }
  }

  uniqueItemValidator(): ValidatorFn {
    const validator: ValidatorFn = (formArray: UntypedFormArray) => {
      const count = formArray.controls.length;

      return count > 0 ? null : { 'No Options': true };
    };

    return validator;
  }
  // End Question Form Operations

  //

  drop(event: CdkDragDrop<string[]>) {
    this.moveItemInFormArray(
      this.questionsForm,
      event.previousIndex,
      event.currentIndex
    );
    this.moveItemInFormMap(event.previousIndex, event.currentIndex);
    this.questionsForm.markAsDirty();
  }

  innerDrop(
    event: CdkDragDrop<string[]>,
    questionIndex: number,
    optionIndex: number
  ) {
    this.moveItemInFormArray(
      this.getInnerQuestions(questionIndex, optionIndex),
      event.previousIndex,
      event.currentIndex
    );
    this.questionsForm.markAsDirty();
  }

  moveItemInFormArray(
    formArray: UntypedFormArray,
    previusIndex: number,
    currentIndex: number
  ) {
    const currentQuestion = formArray.at(previusIndex);
    formArray.removeAt(previusIndex);
    formArray.insert(currentIndex, currentQuestion);
  }

  moveItemInFormMap(previusIndex: number, currentIndex: number) {
    const current = this.selectedInnerQuestion.get(previusIndex);
    const newMap = new Map();
    if (previusIndex < currentIndex) {
      let index = 0;
      for (const key of this.selectedInnerQuestion.keys()) {
        if (index < previusIndex || index > currentIndex) {
          newMap.set(index, this.selectedInnerQuestion.get(key));
        } else if (index === currentIndex) {
          newMap.set(index, current);
        } else {
          newMap.set(index, this.selectedInnerQuestion.get(key + 1));
        }
        index++;
      }
    } else {
      let index = 0;
      for (const key of this.selectedInnerQuestion.keys()) {
        if (index > previusIndex || index < currentIndex) {
          newMap.set(index, this.selectedInnerQuestion.get(key));
        } else if (index === currentIndex) {
          newMap.set(index, current);
        } else {
          newMap.set(index, this.selectedInnerQuestion.get(key - 1));
        }
        index++;
      }
    }

    this.selectedInnerQuestion = newMap;
  }
}
