import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { TokenService } from './token.service';
import {
  Auth,
  GoogleAuthProvider,
  signInWithRedirect,
  signInWithPopup,
  getRedirectResult,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updateProfile,
  UserCredential,
  checkActionCode,
  applyActionCode,
  verifyBeforeUpdateEmail,
  confirmPasswordReset,
  updatePassword,
  User,
  sendSignInLinkToEmail,
  fetchSignInMethodsForEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  FacebookAuthProvider,
  TwitterAuthProvider,
} from '@angular/fire/auth';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { delay } from '../util';
import { firstValueFrom } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthService {
  readonly apiUrl = environment.apiUrl;
  private readonly ID_TOKEN = 'id_token';
  private readonly USER_PROFILE = 'user_profile';
  private readonly EXPIRES_AT = 'expired_at';
  private readonly EXPIRE_TIME = 60 * 58 * 1000;
  private readonly REFRESH_TOKEN = 'refresh_token';

  private readonly baseURL =
    location.hostname === 'localhost'
      ? 'https://localhost:4200'
      : environment.appUrl;

  constructor(
    private angularFireAuth: Auth,
    private userService: UserService,
    private tokenService: TokenService,
    private translate: TranslateService,
    private http: HttpClient
  ) {}

  public async getLoginRedirectResult(): Promise<any> {
    if (this.isAuthenticatedInternal()) {
      return this.getUserProfile();
    }

    const userCredential = await getRedirectResult(this.angularFireAuth);
    if (userCredential && userCredential.user) {
      const user = userCredential.user;
      const token = await user.getIdToken();
      const refreshToken = user.refreshToken;
      const verifiedUser = await this.setSession(user, token, refreshToken);
      if (verifiedUser) {
        return verifiedUser;
      }
    }
    return null;
  }

  public async loginWithGoogle(
    thirdPartyCookieSupported: boolean
  ): Promise<any> {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    if (thirdPartyCookieSupported) {
      await signInWithRedirect(this.angularFireAuth, provider);
    } else {
      try {
        const userCredential = await signInWithPopup(
          this.angularFireAuth,
          provider
        );
        if (userCredential?.user) {
          const user = userCredential.user;
          const token = await userCredential.user.getIdToken();
          const refreshToken = user.refreshToken;
          const verifiedUser = await this.setSession(user, token, refreshToken);
          if (verifiedUser) {
            return verifiedUser;
          }
        }
      } catch (error) {
        console.error(error);
        return error;
      }
      return null;
    }
  }

  public async loginWithFacebook(
    thirdPartyCookieSupported: boolean
  ): Promise<any> {
    const provider = new FacebookAuthProvider();
    if (thirdPartyCookieSupported) {
      await signInWithRedirect(this.angularFireAuth, provider);
    } else {
      const userCredential = await signInWithPopup(
        this.angularFireAuth,
        provider
      );
      if (userCredential?.user) {
        const user = userCredential.user;
        const token = await userCredential.user.getIdToken();
        const refreshToken = user.refreshToken;
        const verifiedUser = await this.setSession(user, token, refreshToken);
        if (verifiedUser) {
          return verifiedUser;
        }
      }

      return null;
    }
  }

  public async loginWithTwitter(
    thirdPartyCookieSupported: boolean
  ): Promise<any> {
    const provider = new TwitterAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    if (thirdPartyCookieSupported) {
      await signInWithRedirect(this.angularFireAuth, provider);
    } else {
      try {
        const userCredential = await signInWithPopup(
          this.angularFireAuth,
          provider
        );
        if (userCredential?.user) {
          const user = userCredential.user;
          const token = await userCredential.user.getIdToken();
          const refreshToken = user.refreshToken;
          const verifiedUser = await this.setSession(user, token, refreshToken);
          if (verifiedUser) {
            return verifiedUser;
          }
        }
      } catch (error) {
        console.error(error);
        return error;
      }
      return null;
    }
  }

  public async sendSignInLinkToUserEmail(email: string): Promise<any> {
    try {
      const language =
        this.translate.getDefaultLang() || this.translate.getBrowserLang();
      const URL = `${this.apiUrl}/users/v1/sign-up`;
      const httpHeaders = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      });
      await firstValueFrom(
        this.http.post<any>(
          URL,
          JSON.stringify({
            email,
            language: language.toUpperCase(),
          }),
          { headers: httpHeaders }
        )
      );
    } catch (error) {
      await sendSignInLinkToEmail(this.angularFireAuth, email, {
        url: `${this.baseURL}/~auth/actions-handler2`,
        handleCodeInApp: true,
      });
    }
  }

  public async loginWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<any> {
    try {
      const userCredential = await signInWithEmailAndPassword(
        this.angularFireAuth,
        email,
        password
      );
      if (userCredential?.user) {
        const user = userCredential.user;
        const token = await userCredential.user.getIdToken();
        const refreshToken = user.refreshToken;
        const verifiedUser = await this.setSession(user, token, refreshToken);
        if (verifiedUser) {
          return verifiedUser;
        }
      }
    } catch (error) {
      console.error(error);
      return error;
    }
    return null;
  }

  public async logout(): Promise<void> {
    this.cleanSession();
    await this.angularFireAuth.signOut();
  }

  public async registerWithEmailAndPassword(user: {
    email: string;
    password: string;
    name: string;
    lastName: string;
  }): Promise<UserCredential> | null {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        this.angularFireAuth,
        user.email,
        user.password
      );

      await updateProfile(userCredential?.user, {
        displayName: user.name + ' ' + user.lastName,
      });
      await this.sendEmailVerification();
      await this.logout();
      return userCredential;
    } catch (error) {
      return error;
    }
  }

  public async resendVerificationEmailLink(email: string, password: string) {
    const userCredential = await signInWithEmailAndPassword(
      this.angularFireAuth,
      email,
      password
    );
    if (userCredential?.user.emailVerified === true) {
      throw Error(this.translate.instant('firebase.email_already_verified'));
    }
    await this.sendEmailVerification();
    await this.logout();
  }

  async confirmSignIn(url, email) {
    try {
      if (isSignInWithEmailLink(this.angularFireAuth, url)) {
        const userCredential = await signInWithEmailLink(
          this.angularFireAuth,
          email,
          url
        );
        if (userCredential?.user) {
          const user = userCredential.user;
          const token = await userCredential.user.getIdToken();
          const refreshToken = user.refreshToken;
          const verifiedUser = await this.setSession(user, token, refreshToken);
          if (verifiedUser) {
            return verifiedUser;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async isAuthenticated(): Promise<boolean> {
    if (this.isAuthenticatedInternal()) {
      return true;
    }

    try {
      const refreshToken = localStorage.getItem(this.REFRESH_TOKEN);
      if (refreshToken) {
        const newToken = await this.tokenService.getTokenFromRefreshToken(
          refreshToken
        );
        const user = await this.userService.getUser(newToken);
        if (user) {
          const verifiedUser = await this.setSession(
            user,
            newToken,
            refreshToken
          );
          if (verifiedUser) {
            return true;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }

    try {
      const currentUser = await this.angularFireAuth.currentUser;
      if (currentUser && currentUser.refreshToken) {
        const newToken = await this.tokenService.getTokenFromRefreshToken(
          currentUser.refreshToken
        );
        if (newToken) {
          await this.setSession(
            currentUser,
            newToken,
            currentUser.refreshToken
          );
          return true;
        }
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }

  public async reloadUserSession() {
    try {
      // Wait for firebase user to be loaded
      await delay(1500);
      const currentUser = await this.angularFireAuth.currentUser;
      if (currentUser && currentUser.refreshToken) {
        const newToken = await this.tokenService.getTokenFromRefreshToken(
          currentUser.refreshToken
        );
        if (newToken) {
          const user = await this.setSession(
            currentUser,
            newToken,
            currentUser.refreshToken
          );
          return user;
        }
      } else {
        return this.getUserProfile();
      }
    } catch (error) {
      console.error(error);
    }
  }

  public isAuthenticatedSync(): boolean {
    return this.isAuthenticatedInternal();
  }

  public getIdToken(): string | null {
    return this.isAuthenticatedInternal()
      ? localStorage.getItem(this.ID_TOKEN)
      : null;
  }

  public getUserProfile(): any | null {
    if (!this.isAuthenticatedInternal()) {
      return null;
    }
    const user = localStorage.getItem(this.USER_PROFILE);
    if (!user) {
      return null;
    }
    return JSON.parse(user);
  }

  public getCurrentUser(): User {
    return this.angularFireAuth.currentUser;
  }

  public async getCurrentProvider() {
    return this.angularFireAuth.currentUser?.getIdTokenResult();
  }

  public async updateEmail(email: string) {
    const currentUser = this.angularFireAuth.currentUser;
    const token = this.getIdToken();
    const user = this.getUserProfile();
    if (!currentUser || !token || !user) return;
    try {
      await verifyBeforeUpdateEmail(currentUser, email);
    } catch (error) {
      if (!error) throw new Error(this.translate.instant('errors.generic'));
      throw error;
    }
  }

  public async resetPassword(email: string): Promise<void> {
    const url = this.baseURL + '/~auth/login-ep';
    await sendPasswordResetEmail(this.angularFireAuth, email, { url: url });
  }

  public async changePassword(
    newPassword: string,
    oobCode?: string
  ): Promise<boolean> {
    const currentUser = this.angularFireAuth.currentUser;
    if (currentUser) {
      const token = this.getIdToken();
      const user = this.getUserProfile();
      if (!token || !user) return;
      await updatePassword(currentUser, newPassword);
      return true;
    } else if (oobCode) {
      await confirmPasswordReset(this.angularFireAuth, oobCode, newPassword);
      return true;
    }
    return false;
  }

  public async checkFirebaseActionCode(code: string) {
    return await checkActionCode(this.angularFireAuth, code);
  }

  public async applyFirebaseActionCode(code: string) {
    return await applyActionCode(this.angularFireAuth, code);
  }

  public async fetchSignInMethodsByEmail(email: string) {
    return await fetchSignInMethodsForEmail(this.angularFireAuth, email);
  }

  //////// private methods ////////

  private async sendEmailVerification() {
    const currentUser = await this.angularFireAuth.currentUser;
    const url = this.baseURL + '/~auth/actions-handler';
    await sendEmailVerification(currentUser, { url: url });
  }

  private async setSession(
    user: any,
    token: string,
    refreshToken
  ): Promise<any> {
    this.cleanSession();
    let verifiedUser = null;
    try {
      verifiedUser = await this.userService.getUser(token);
    } catch (ignored) {}
    if (!verifiedUser) {
      try {
        verifiedUser = await this.userService.registerUser(token, user);
      } catch (error) {
        console.error(error);
      }
    }
    if (verifiedUser) {
      if (verifiedUser.email !== user.email) {
        try {
          await this.userService.updateUser(token, {
            ...verifiedUser,
            email: user.email,
          });
          verifiedUser = await this.userService.getUser(token);
        } catch (ignored) {}
      }
      localStorage.setItem(this.USER_PROFILE, JSON.stringify(verifiedUser));
      localStorage.setItem(
        this.EXPIRES_AT,
        String(new Date().getTime() + this.EXPIRE_TIME)
      );
      localStorage.setItem(this.ID_TOKEN, token);
      localStorage.setItem(this.REFRESH_TOKEN, refreshToken);
      const language = verifiedUser.language || this.translate.getBrowserLang();
      switch (language) {
        case 'en':
          registerLocaleData(localeEn, 'en');
          this.translate.setDefaultLang('en');
          break;
        default:
          registerLocaleData(localeEs, 'es');
          this.translate.setDefaultLang('es');
      }
      return verifiedUser;
    }
    return null;
  }

  private cleanSession() {
    localStorage.removeItem(this.ID_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem(this.USER_PROFILE);
    localStorage.removeItem(this.EXPIRES_AT);
  }

  private isAuthenticatedInternal(): boolean {
    const expiresAt = JSON.parse(localStorage.getItem(this.EXPIRES_AT));
    if (!expiresAt) {
      return false;
    }
    const time = new Date().getTime();
    return Number(expiresAt) > time;
  }
}
