import { Component, OnInit } from '@angular/core';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { AuthService } from './auth/auth.serviceV2';
import { LocalStorageType } from './auth/local-storage-type.enum';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { detect } from 'detect-browser';
import { DataLayerService } from './service/data-layer.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public settings: Settings;
  user = null;

  constructor(
    private appSettings: AppSettings,
    private translate: TranslateService,
    private authService: AuthService,
    private ccService: NgcCookieConsentService,
    private router: Router,
    private dataLayerService: DataLayerService
  ) {
    try {
      this.translate.addLangs(['en', 'es']);
      const browserLang = this.translate.getBrowserLang();
      this.user = this.authService.getUserProfile();
      const lang =
        this.user && this.user.language ? this.user.language : browserLang;
      switch (lang) {
        case 'en':
          registerLocaleData(localeEn, 'en');
          this.translate.setDefaultLang('en');
          break;
        default:
          registerLocaleData(localeEs, 'es');
          this.translate.setDefaultLang('es');
      }
    } catch (error) {
      registerLocaleData(localeEs, 'es');
      this.translate.setDefaultLang('es');
    }

    this.translate
      .get([
        'cookie.header',
        'cookie.message',
        'cookie.dismiss',
        'cookie.allow',
        'cookie.deny',
        'cookie.link',
        'cookie.policy',
      ])
      .subscribe((data) => {
        this.ccService.getConfig().content =
          this.ccService.getConfig().content || {};
        // Override default messages with the translated ones
        this.ccService.getConfig().content.header = data['cookie.header'];
        this.ccService.getConfig().content.message = data['cookie.message'];
        this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
        this.ccService.getConfig().content.allow = data['cookie.allow'];
        this.ccService.getConfig().content.deny = data['cookie.deny'];
        this.ccService.getConfig().content.link = data['cookie.link'];
        this.ccService.getConfig().content.policy = data['cookie.policy'];

        this.ccService.destroy();
        this.ccService.init(this.ccService.getConfig());
      });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        //this.dataLayerService.logPageView(event.url);
      }
    });
  }

  public ngOnInit() {
    // Youtube Video Player
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
    // END Youtube Video Player

    this.settings = this.appSettings.settings;
    const userExists: string = localStorage.getItem(LocalStorageType.IsNew);

    if (!userExists) {
      localStorage.setItem(LocalStorageType.IsNew, 'true');
    }
  }

  openInBrowser(target, browserScheme) {
    var ifc = document.createElement('div');
    ifc.innerHTML = `<iframe src='${browserScheme}${target}' style='width:0;height:0;border:0; border:none;visibility: hidden;'></iframe>`;
    document.body.appendChild(ifc);
  }

  isInApp(appSpecificUserAgents) {
    var userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera;
    for (let i = 0; i <= appSpecificUserAgents.length; i++) {
      if (userAgent.indexOf(appSpecificUserAgents[i]) > -1) return true;
    }
  }

  isInAppBrowser(browser: any) {
    const browsers = [
      'facebook',
      'instagram',
      'ios-webview',
      'chromium-webview',
    ];
    return browsers.includes(browser.name);
  }

  tryOpenBrowser() {
    if (document.body) {
      const browser = detect() || { name: '', os: '' };
      if (this.isInAppBrowser(browser)) {
        const os = ['Mac OS', 'iOS'];
        if (os.includes(browser.os)) {
          this.openInBrowser(
            window.location.href,
            'googlechrome://navigate?url='
          );
        } else {
          this.openInBrowser(
            window.location.href,
            'googlechrome://navigate?url='
          );
        }
      }
    } else {
      window.requestAnimationFrame(this.tryOpenBrowser);
    }
  }
}
