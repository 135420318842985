import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppSettings } from '../../app.settings';
import { AuthService } from '../../auth/auth.serviceV2';
import { EntityType } from '../../models/entity-type-enum';
import { ReviewEntity } from '../../models/review';
import { PageEntity } from '../../models/types';
import { HttpService } from '../../service/http.service';
import { YesNoDialogComponent } from '../yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'app-comments-view',
  templateUrl: './comments-view.component.html',
  styleUrls: ['./comments-view.component.scss'],
})
export class CommentsViewComponent implements OnInit, AfterViewInit {
  @Input() entityId: string;
  @Input() entityType: string;
  @Input() entityName: string;

  comments: ReviewEntity[] = [];

  readonly noCommentIcon = faCommentAlt;
  user: any;
  settings;
  pageSize = 10;
  pageIndex = 0;
  length = 0;
  pageSizeOptions = [10, 20, 50];
  sort = 'createdOn';
  private readonly apiUrl = environment.apiUrl;

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private httpService: HttpService,
    protected appSettings: AppSettings,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.user = this.authService.getUserProfile();
    this.search();
  }

  ngAfterViewInit() {
    const activityType = this.route.snapshot.queryParams['entityType'];
    if (!activityType) {
      return;
    }
    let entityId = this.route.snapshot.queryParams['activityId'];
    if (activityType === EntityType.HUB) {
      entityId = this.route.snapshot.queryParams['hubId'];
    } else if (activityType === EntityType.CATEGORY) {
      entityId = this.route.snapshot.queryParams['categoryId'];
    }
    setTimeout(() => {
      const element = document.getElementById(
        `${this.user.userId}_${activityType}_${entityId}`
      );
      if (element !== null) {
        element.scrollIntoView();
      }
    }, 500);
  }

  async search() {
    const url = `${this.apiUrl}/review/v1/${this.entityType}/${this.entityId}?page=${this.pageIndex}&size=${this.pageSize}&sort=${this.sort},desc`;
    this.settings.loadingSpinner = true;
    try {
      const response: PageEntity<ReviewEntity> = await firstValueFrom(
        this.httpService.doGet(url)
      );
      if (response) {
        this.comments = response.content;
        this.length = response.totalElements;
      } else {
        this.comments = [];
        this.length = 0;
      }
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  report(): void {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      minWidth: '240px',
      data: {
        title: 'messages.report_comment_question',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  pageEvent(pageChangedEvent: any) {
    this.pageIndex = pageChangedEvent.pageIndex;
    this.pageSize = pageChangedEvent.pageSize;
    this.search();
  }

  onSort() {
    this.pageIndex = 0;
    this.search();
  }
}
