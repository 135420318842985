<div fxLayout="column" class="page-container">
    <div *ngIf="(items && items.length > 0) || loading; else noItems" class="list-container">
        <div *ngIf="loading && !settings.loadingSpinner; else noLoading" class="loading" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
            <mat-spinner color="primary" diameter="60"></mat-spinner>
        </div>
        <ng-template #noLoading>
            <mat-accordion>
                <div fxLayout="column" fxLayoutGap="15px">
                
                    <ng-container *ngFor="let item of items; let i = index;" >
                        <app-table-dish 
                            [index]="i"
                            [item]="item"
                            (onQuantityChange)="quantityChange($event)"
                            (onRemove)="remove(item)">
                        </app-table-dish>
                    </ng-container>
               
                </div>
            </mat-accordion>

        </ng-template>
    </div>
    <ng-template #noItems>
        <div class="no-items-container">
            <mat-icon>restaurant</mat-icon>
            <p translate>
                messages.empty_table_text
            </p>
        </div>
    </ng-template>
</div>
