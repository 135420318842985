import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppSettings } from '../../app.settings';
import { AuthService } from '../../auth/auth.serviceV2';
import { PageEntity } from '../../models/types';
import { HttpService } from '../../service/http.service';
import { YesNoDialogComponent } from '../yes-no-dialog/yes-no-dialog.component';
import { CreateReportsCommentCommand, ReportsCommentEntity } from '../../models/reports-comment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reports-comments-view',
  templateUrl: './reports-comments-view.component.html',
  styleUrls: ['./reports-comments-view.component.scss'],
})
export class ReportsCommentsViewComponent implements OnInit {

  @Input() client: boolean = false;
  @Input() entityId: string;

  comments: ReportsCommentEntity[] = [];

  readonly noCommentIcon = faCommentAlt;
  user: any;
  settings;
  pageSize = 10;
  pageIndex = 0;
  length = 0;
  pageSizeOptions = [10, 20, 50];
  sort = 'desc';
  private readonly apiUrl = environment.apiUrl;

  editingComment = false;
  addingComment = false;

  loading = false;

  form: UntypedFormGroup;

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private httpService: HttpService,
    protected appSettings: AppSettings,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {
    this.settings = this.appSettings.settings;
  }

  get commentField() {
    return this.form.get('comment');
  }

  get internalField() {
    return this.form.get('internal');
  }

  set internalField(value: any) {
    this.internalField.setValue(value);
  }

  ngOnInit(): void {
    this.user = this.authService.getUserProfile();
    this.form = this.fb.group({
      comment: ['', Validators.required],
      internal: [true],
    });
    this.search();
  }

  async search() {
    this.cancel();
    const url = `${this.apiUrl}/request-comment/v1/request-id/${this.entityId}`;
    this.settings.loadingSpinner = true;
    try {
      const response: PageEntity<ReportsCommentEntity> = await firstValueFrom(
        this.httpService.doGet(url)
      );
      if (response) {
        this.comments = response.content.reverse();
        this.length = response.totalElements;
      } else {
        this.comments = [];
        this.length = 0;
      }
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  report(): void {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      minWidth: '240px',
      data: {
        title: 'messages.report_comment_question',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  pageEvent(pageChangedEvent: any) {
    this.pageIndex = pageChangedEvent.pageIndex;
    this.pageSize = pageChangedEvent.pageSize;
    this.search();
  }

  onSort() {
    this.pageIndex = 0;
    this.search();
  }

  onEditModeChanged(value: boolean) {
    this.editingComment = value;
  }

  onDeleted(deletedId: string) {
    this.comments = this.comments.filter(({ id }: ReportsCommentEntity | any) => id !== deletedId);
  }

  onEdited(comment: ReportsCommentEntity) {
     const index = this.comments.findIndex(({ id }: ReportsCommentEntity | any) => id == comment.id);
     if (index !== -1) {
      this.comments[index] = comment;
     }
  }

  onAddMode() {
    this.form.patchValue({ comment: '' });
    this.form.patchValue({ internal: true });
    this.form.markAsPristine();
    this.editingComment = true;
    this.addingComment = true;
  }

  async onAdd() {
    if(this.commentField.value.length > 300) return;
    for (var i in this.form.controls) {
      this.form.controls[i].markAsTouched();
    }
    if (this.form.invalid) return;

    this.loading = true;
    const rawValues = this.form.getRawValue();
    const { comment, internal } = rawValues;

    const body: CreateReportsCommentCommand = {
      requestId: this.entityId,
      comment,
      internal: this.client ? false : internal,
    };

    const url = `${this.apiUrl}/request-comment/v1`;
    try {
      const response: ReportsCommentEntity = await firstValueFrom(
        this.httpService.doPost(url, body)
      );
      this.comments.unshift(response);
    } catch(error) {
      this.showSnackBar(
        this.translate.instant('messages.comment_save_error'),
        'OK',
        2000
      );
    } finally {
      this.loading = false;
    }
    
    this.editingComment = false;
    this.addingComment = false;
  }

  cancel() {
    this.editingComment = false;
    this.addingComment = false;
  }

  showSnackBar(message, action, duration, panelClass = '') {
    this.snackBar.open(message, action, {
      duration: duration,
      panelClass
    });
  }

}
