<div fxLayout="row" fxLayoutAlign="space-between center">
    <button (click)="back()" mat-button><span translate>tinyAll.back</span></button>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <app-client-view-button class="mr-10" [url]="clientViewUrl"></app-client-view-button>
        <button class="menu-button" mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
        <mat-menu #menu="matMenu">
            <button class="save"mat-menu-item (click)="onSubmit()" [disabled]="!form || (!form.dirty && !schedule.dirty) || form.invalid || schedule.invalid"><span translate>buttons.save</span></button>
            <button class="delete" mat-menu-item (click)="canDelete()" *ngIf="hubId"><span translate>buttons.delete</span></button>
        </mat-menu>
    </div>
</div>

<div *ngIf="form" class="page-container" fxLayout="column">
    <app-banned-message *ngIf="hub?.restricted"></app-banned-message>
    <div class="flex-p">
        <mat-card-header fxLayoutAlign="center">
            <mat-card-subtitle>
                <h3 translate>hubManagement.title</h3>
            </mat-card-subtitle>
        </mat-card-header>

        <div fxLayout="column" fxLayoutAlign="center">
            <mat-expansion-panel [ngClass]="{'error': image.hasError('required')}">
                <mat-expansion-panel-header>
                    <mat-panel-title translate>hubManagement.images<app-info-tooltip info="tooltip.hub_images_section" class="hub-manage-info"></app-info-tooltip></mat-panel-title>
                </mat-expansion-panel-header>

                <p translate>hubManagement.image<app-info-tooltip info="tooltip.hub_images_section_imagen" infoClass="secondary"></app-info-tooltip></p>
                <section fxLayoutAlign="center">
                    <input hidden="true" type="file" accept="image/*" (change)="onSelectedImage($any($event).target.files, form, 'image')" #imageInput>
                    <a (click)="imageInput.click()">
                        <img height="120" [src]="form.get('image').value || noImage" alt="hub image">
                    </a>
                </section>
                <mat-error class="error" *ngIf="maxSizeErrors['image']" translate>{{ 'formErrors.image_max_size' | translate:{ size: maxImageSize } }}</mat-error>

                <p translate>hubManagement.icon<app-info-tooltip info="tooltip.hub_images_section_icon" infoClass="secondary"></app-info-tooltip></p>
                <section fxLayoutAlign="center">
                    <input hidden="true" type="file" accept="image/*" (change)="onSelectedImage($any($event).target.files, form, 'icon')" #iconInput>
                    <a (click)="iconInput.click()">
                        <img height="120" [src]="form.get('icon').value || noImage" alt="icon">
                    </a>
                </section>
                <mat-error class="error" *ngIf="maxSizeErrors['icon']" translate>{{ 'formErrors.image_max_size' | translate:{ size: maxImageSize } }}</mat-error>

            </mat-expansion-panel>
        </div>

    </div>

    <form novalidate [formGroup]="form" fxLayout="column" fxLayoutAlign="center" autocomplete="off">

        <div hidden>
            <input matInput type="text" formControlName="id">
            <input matInput type="text" formControlName="userId">
            <input matInput type="text" formControlName="image">
            <input matInput type="text" formControlName="icon">
            <input matInput type="text" formControlName="location">
        </div>

        <div class="flex-p">
            <mat-expansion-panel [ngClass]="{'error': infoValidateError()}">
                <mat-expansion-panel-header>
                    <mat-panel-title translate>hubManagement.hub_details<app-info-tooltip info="tooltip.hub_info_section" class="hub-manage-info"></app-info-tooltip></mat-panel-title>
                </mat-expansion-panel-header>

                <div fxLayout="column" fxLayoutAlign="center">
                    <mat-form-field>
                        <input matInput placeholder="{{'hubManagement.name' | translate}}" formControlName="name">
                        <mat-error *ngIf="name.hasError('required')" translate>hubManagement.error_name</mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <app-info-tooltip matSuffix info="tooltip.hub_info_section_account_name" class="info-account-name" infoClass="secondary"></app-info-tooltip>
                        <input matInput placeholder="{{'hubManagement.url_handler' | translate}}" formControlName="urlHandler" (keydown.space)="$event.preventDefault()">
                        <mat-icon matPrefix class="at-icon">alternate_email</mat-icon>
                        <mat-hint *ngIf="!hubId" translate>hubManagement.url_handler_hint</mat-hint>
                        <mat-error *ngIf="urlHandler.hasError('required')" translate>hubManagement.error_url_handler</mat-error>
                        <mat-error *ngIf="urlHandler.hasError('pattern')" translate>hubManagement.error_pattern_url_handler</mat-error>
                        <mat-error *ngIf="urlHandler.hasError('maxlength')" translate>hubManagement.error_max_url_handler</mat-error>
                        <mat-error *ngIf="urlHandler.hasError('minlength')" translate>hubManagement.error_min_url_handler</mat-error>
                        <mat-error *ngIf="urlHandler.hasError('existing')" translate>hubManagement.error_existing_url_handler</mat-error>
                    </mat-form-field>
                    <app-url-handler-example title="titles.link_to_share" [urlHandler]="urlHandler.value" [copy]="hasHubId()"></app-url-handler-example>
                    <div class="warning" *ngIf="!hubId">
                        <mat-icon>warning</mat-icon>
                        <span translate>messages.url_hanlder_warning</span>
                    </div>
                    <mat-form-field>
                        <input matInput placeholder="{{'hubManagement.email' | translate}}" formControlName="email" (keydown.space)="$event.preventDefault()">
                        <mat-error *ngIf="email.hasError('email') || email.hasError('required')" translate>hubManagement.error_email</mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <input matInput placeholder="{{'hubManagement.phone' | translate}}" mask="(000) 000-0000" formControlName="phoneNumber" />
                        <mat-error *ngIf="phoneNumber.hasError('required') || phoneNumber.hasError('pattern')" translate>hubManagement.error_phone</mat-error>
                    </mat-form-field>

                    <app-hub-tag-picker [items]="getTags()">
                    </app-hub-tag-picker>

                    <app-rich-text-editor [form]="form" fieldName="infoText" mode="edit" placeholder="{{'formFields.description' | translate}}"></app-rich-text-editor>
                    <div style="height:15px"></div>

                    <section fxLayout="column" fxLayoutAlign="space-between stretch">
                        <app-warning-message *ngIf="!active.value"
                            message="messages.inactive_hub">
                        </app-warning-message>
                        <mat-checkbox formControlName="active">
                            <span translate>hubManagement.activate_checkbox<app-info-tooltip class="checkbox-tooltip" info="tooltip.publish_hub_checkbox" infoClass="secondary"></app-info-tooltip></span>
                        </mat-checkbox>
                        <p class="field-description" translate>messages.hub_public_checkbox_description</p>
                    </section>
                    <section fxLayout="column" fxLayoutAlign="space-between stretch" class="hub-hidden-check">
                        <mat-checkbox formControlName="unlisted">
                            <span class="info-tooltip-container" translate>hubManagement.hidden_checkbox<app-info-tooltip class="checkbox-tooltip" info="tooltip.hidden_hub_checkbox" infoClass="secondary"></app-info-tooltip></span>
                        </mat-checkbox>
                        <p class="field-description" translate>messages.hub_hidden_checkbox_description</p>
                    </section>
                    <section fxLayout="column" fxLayoutAlign="space-between stretch" class="mt-2">
                        <mat-checkbox formControlName="adultContent">
                            <span class="info-tooltip-container" translate>hubManagement.mature_content</span>
                        </mat-checkbox>
                    </section>
                </div>
            </mat-expansion-panel>
        </div>

        <div class="flex-p">
            <div fxLayout="column" fxLayoutAlign="center">
                <mat-expansion-panel [ngClass]="{'error': addressValidateError()}">

                    <mat-expansion-panel-header>
                        <mat-panel-title translate>hubManagement.address<app-info-tooltip info="tooltip.hub_address_section" class="hub-manage-info"></app-info-tooltip></mat-panel-title>
                    </mat-expansion-panel-header>

                    <div formGroupName="address" fxLayout="column" fxLayoutAlign="center">

                        <mat-select-country
                            [countries]="predefinedCountries"
                            placeHolder="{{'hubManagement.country' | translate}}"
                            [value]="selectedCountry"
                            [itemsLoadSize]="10"
                            (onCountrySelected)="onCountrySelected($event)">
                            <mat-error *ngIf="country.hasError('required')" translate>hubManagement.error_country</mat-error>
                        </mat-select-country>

                        <app-state-autocomplete [state]="stateOrProvidence"></app-state-autocomplete>

                        <app-city-autocomplete [city]="city"></app-city-autocomplete>

                        <mat-form-field>
                            <textarea matInput placeholder="{{'hubManagement.address' | translate}}" formControlName="address"></textarea>
                            <mat-error *ngIf="address.hasError('required')" translate>hubManagement.error_address</mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <textarea matInput placeholder="{{'hubManagement.address2' | translate}}" formControlName="address2"></textarea>
                        </mat-form-field>

                        <mat-form-field>
                            <input matInput placeholder="{{'hubManagement.zip_code' | translate}}" formControlName="zipCode">
                        </mat-form-field>

                        <mat-form-field>
                            <textarea matInput placeholder="{{'hubManagement.location_hint' | translate}}" formControlName="locationHint"></textarea>
                        </mat-form-field>
                    </div>

                </mat-expansion-panel>
            </div>
        </div>

        <div class="flex-p">
            <div fxLayout="column" fxLayoutAlign="center">
                <mat-expansion-panel (opened)="showMap()">
                    <mat-expansion-panel-header>
                        <mat-panel-title translate>hubManagement.location<app-info-tooltip info="tooltip.hub_location_section" class="hub-manage-info"></app-info-tooltip></mat-panel-title>
                    </mat-expansion-panel-header>

                    <section fxLayoutAlign="start center">
                        <button type="button" (click)="setLocation()" mat-mini-fab color="primary">
                            <mat-icon>add_location</mat-icon>
                        </button>
                        <p class="select-location-message" translate>
                            messages.select_current_location_hub
                        </p>
                    </section>
                    <div fxLayout="column" fxLayoutAlign="center">
                        <div class="flex-p map-wrapper">
                            <app-location-map 
                                *ngIf="canShowMap"
                                [noCard]="true"
                                [location]="location.value" 
                                name="Your Location"
                                [launch]="false"
                                [world]="true">
                            </app-location-map>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>
        </div>

        <div class="flex-p">
            <div fxLayout="column" fxLayoutAlign="center">
                <app-social-media-links-management [form]="socialLinks">
                </app-social-media-links-management>
            </div>
        </div>

        <div class="flex-p">
            <div fxLayout="column" fxLayoutAlign="center">
                <app-custom-links-management [form]="customLinks">
                </app-custom-links-management>
            </div>
        </div>

        <div class="flex-p">
            <div fxLayout="column" fxLayoutAlign="center">
                <app-open-hours-management [form]="schedule">
                </app-open-hours-management>
            </div>
        </div>

        <div class="flex-p">
            <div fxLayout="column" fxLayoutAlign="center">
                <mat-expansion-panel class="no-padding">
                    <mat-expansion-panel-header>
                        <mat-panel-title translate>subtitles.pick_up_days_off</mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-days-off-picker [daysSelected]="daysOff" (changed)="onDaysOffChange()">
                    </app-days-off-picker>

                </mat-expansion-panel>
            </div>
        </div>

        <div class="flex-p" *ngIf="form?.invalid && form?.touched">
            <mat-card>
                <mat-card-content>
                    <ul class="error-list">
                        
                        <li *ngIf="image.hasError('required')">
                            <mat-error translate>hubManagement.error_image</mat-error>
                        </li>
                        <li *ngIf="name.hasError('required')">
                            <mat-error translate>hubManagement.error_name</mat-error>
                        </li>
                        <li *ngIf="urlHandler.hasError('required')">
                            <mat-error translate>hubManagement.error_url_handler</mat-error>
                        </li>
                        <li *ngIf="urlHandler.hasError('pattern')">
                            <mat-error translate>hubManagement.error_pattern_url_handler</mat-error>
                        </li>
                        <li *ngIf="urlHandler.hasError('maxlength')">
                            <mat-error translate>hubManagement.error_max_url_handler</mat-error>
                        </li>
                        <li *ngIf="urlHandler.hasError('minlength')">
                            <mat-error translate>hubManagement.error_min_url_handler</mat-error>
                        </li>
                        <li *ngIf="urlHandler.hasError('existing')">
                            <mat-error translate>hubManagement.error_existing_url_handler</mat-error>
                        </li>
                        <li *ngIf="email.hasError('email') || email.hasError('required')">
                            <mat-error translate>hubManagement.error_email</mat-error>
                        </li>
                        <li *ngIf="phoneNumber.hasError('required') || phoneNumber.hasError('pattern')">
                            <mat-error translate>hubManagement.error_phone</mat-error>
                        </li>
                        <li *ngIf="country.hasError('required')">
                            <mat-error translate>hubManagement.error_country</mat-error>
                        </li>
                        <li *ngIf="address.hasError('required')">
                            <mat-error translate>hubManagement.error_address</mat-error>
                        </li>
                        <li *ngIf="stateOrProvidence.hasError('required')">
                            <mat-error translate>hubManagement.error_state_providence</mat-error>
                        </li>
                        <li *ngIf="city.hasError('required')">
                            <mat-error translate>hubManagement.error_city</mat-error>
                        </li>

                    </ul>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="flex-p">
            <mat-card>
                <mat-card-content>
                    <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="7px">
                        <section fxLayoutAlign="space-around">
                            <button type="button" mat-raised-button (click)="back()" color="primary"><span translate>tinyAll.back</span></button>
                            <button (click)="onSubmit()" [disabled]="!form || (!form.dirty && !schedule.dirty) || form.invalid || schedule.invalid" mat-raised-button color="accent"><span translate>tinyAll.save</span></button>
                        </section>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

    </form>
</div>
<app-loading></app-loading>
