import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { CallBackComponent } from './auth/callback.component';
import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ErrorComponent } from './pages/errors/error/error.component';
import { ShortUrlHandlerComponent } from './components/short-url-handler/short-url-handler.component';
import { HubClientViewComponent } from './pages/hub/hub-client-view/hub-client-view.component';
import { HubOwnerViewComponent } from './pages/hub/hub-owner-view/hub-owner-view.component';
import { AuthGuard } from './auth/auth.guard';
import { SortActCatManagementComponent } from './components/sort-act-cat-management/sort-act-cat-management.component';
import { FormGuard } from './guards/form.guard';
import { SortHubMenuManagementComponent } from './components/sort-hub-menu-management/sort-hub-menu-management.component';
import { HubManagementComponent } from './pages/hub/hub-management/hub-management.component';
import { HubAboutUsComponent } from './pages/hub/hub-about-us/hub-about-us.component';
import { HubManagementWorkflowComponent } from './pages/hub/hub-management-workflow/hub-management-workflow.component';
import { routes as HubRoutes } from './pages/hub/hub.module';

export const routes: Routes = [
  { path: '~landing', loadChildren: () => import('app/pages/landing/landing.module').then(m => m.LandingModule) },
  { path: '~auth', loadChildren: () => import('app/pages/authentication/authentication.module').then(m => m.AuthenticationModule) },
  { path: '~register', loadChildren: () => import('app/pages/register/register.module').then(m => m.RegisterModule) },
  { path: 'callback', component: CallBackComponent },
  { path: '~error', component: ErrorComponent },
  { path: 'su/:type/:id', component: ShortUrlHandlerComponent },
  { path: '~not-found', component: NotFoundComponent },
  
  {
    path: '',
    component: PagesComponent, children: [
      { path: '', loadChildren: () => import('app/pages/home/home.module').then(m => m.HomeModule) },
      { path: '~hub', children: HubRoutes },
      { path: '~search-location', loadChildren: () => import('app/pages/search_location/search.module').then(m => m.SearchModule)},
      { path: '~search-venue', loadChildren: () => import('app/pages/search_venue/search.module').then(m => m.SearchModule)},
      { path: '~category', loadChildren: () => import('app/pages/category/category.module').then(m => m.CategoryModule)},
      { path: '~activity', loadChildren: () => import('app/pages/activity/activity.module').then(m => m.ActivityModule)},
      { path: '~actinfo', loadChildren: () => import('app/pages/activity-info/activity-info.module').then(m => m.ActivityInfoModule)},
      { path: '~actrqt', loadChildren: () => import('app/pages/activity-request/activity-request.module').then(m => m.ActivityRequestModule)},
      { path: '~actrvt', loadChildren: () => import('app/pages/activity-reservation/activity-reservation.module').then(m => m.ActivityReservationModule)},
      {
        path: '~actpl',
        loadChildren: () => {
          return import('app/pages/activity-product-listing/activity-product-listing.module')
          .then(m => m.ActivityProductListingModule);
        }
      },
      { path: '~actmenu', loadChildren: () => import('app/pages/activity-menu/activity-menu.module').then(m => m.ActivityMenuModule)},
      { path: '~actevent', loadChildren: () => import('app/pages/activity-event/activity-event.module').then(m => m.ActivityEventModule)},
      { path: '~settings', loadChildren: () => import('app/pages/settings/settings.module').then(m => m.SettingsModule)},
      { path: '~pay', loadChildren: () => import('app/pages/monetization/monetization.module').then(m => m.MonetizationModule)},
      { path: '~reports', loadChildren: () => import('app/pages/reports/reports.module').then(m => m.ReportsModule)},
      { path: '~requests', loadChildren: () => import('app/pages/user-requests/user-requests.module').then(m => m.UserRequestsModule)},
      { path: '~promocodes', loadChildren: () => import('app/pages/hub-promo-code/hub-promo-code.module').then(m => m.HubPromoCodeModule)},
      { path: '~userpromocodes', loadChildren: () => import('app/pages/user-promo-code/user-promo-code.module').then(m => m.UserPromoCodeModule)},
      { path: ':handler', children: [
        { path: '' , children: HubRoutes },
        { path: 'category', loadChildren: () => import('app/pages/category/category.module').then(m => m.CategoryModule)},
        { path: 'activity', loadChildren: () => import('app/pages/activity/activity.module').then(m => m.ActivityModule)},
        { path: 'actinfo', loadChildren: () => import('app/pages/activity-info/activity-info.module').then(m => m.ActivityInfoModule)},
        { path: 'actrqt', loadChildren: () => import('app/pages/activity-request/activity-request.module').then(m => m.ActivityRequestModule)},
        { path: 'actrvt', loadChildren: () => import('app/pages/activity-reservation/activity-reservation.module').then(m => m.ActivityReservationModule)},
        {
          path: 'actpl',
          loadChildren: () => {
            return import('app/pages/activity-product-listing/activity-product-listing.module')
            .then(m => m.ActivityProductListingModule);
          }
        },
        { path: 'actmenu', loadChildren: () => import('app/pages/activity-menu/activity-menu.module').then(m => m.ActivityMenuModule)},
        { path: 'actevent', loadChildren: () => import('app/pages/activity-event/activity-event.module').then(m => m.ActivityEventModule)},
        { path: 'pay', loadChildren: () => import('app/pages/monetization/monetization.module').then(m => m.MonetizationModule)},
        { path: 'reports', loadChildren: () => import('app/pages/reports/reports.module').then(m => m.ReportsModule)},
        { path: 'promocodes', loadChildren: () => import('app/pages/hub-promo-code/hub-promo-code.module').then(m => m.HubPromoCodeModule)},
        { path: 'userpromocodes', loadChildren: () => import('app/pages/user-promo-code/user-promo-code.module').then(m => m.UserPromoCodeModule)},
      ]},
    ]
  },
  { path: '**', component: NotFoundComponent }
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy',
    onSameUrlNavigation: 'reload',
});
