export enum MenuServiceType {
    RESTAURANT = 'RESTAURANT',
    PICKUP = 'PICKUP', 
    DELIVERY = 'DELIVERY',
}

export const getMenuServiceTypeList = (isRestaurant: boolean, pickUp: boolean, delivery: boolean) => {
    const list = [];
    if(isRestaurant) {
        list.push(MenuServiceType.RESTAURANT);
    }
    if (pickUp) {
        list.push(MenuServiceType.PICKUP);
    }
    if (delivery) {
        list.push(MenuServiceType.DELIVERY);
    }
    return list;
}
