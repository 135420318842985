<div class="dish-list-container">
    <div fxLayout="row">
        <h3 class="title" translate>titles.dishes</h3>
    </div>
    <div fxLayout="column" fxLayoutGap="20px">
        <mat-accordion>
            <div class="flex-p" *ngFor="let dish of dishes; let i = index;" >
                <mat-card class="item-container">
                    <mat-card-content class="dish-item">
                        <app-dish-item-client-view [dish]="dish" [index]="i" (onAddToTable)="addToTable($event)">
                        </app-dish-item-client-view>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-accordion>
    </div>
<div>
