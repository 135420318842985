import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { environment } from "../../../environments/environment";
import { AppSettings } from "../../app.settings";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpService } from "../../service/http.service";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { ContentType } from "../../models/content-type-enum";
import { firstValueFrom } from "rxjs";
import { AuthService } from "../../auth/auth.serviceV2";
import { PageEntity } from "../../models/types";
import { ActCatViewEntity } from "../../models/activity-category-view";
import ActivityIconMap from "../../util/activity-icon-map";

@Component({
  selector: "app-activity-picker",
  templateUrl: "./activity-picker.component.html",
  styleUrls: ["./activity-picker.component.scss"],
})
export class ActivityPickerComponent implements OnInit {
  user = null;
  items: ActCatViewEntity[] = [];
  originalItems: ActCatViewEntity[] = [];
  filteredItems: ActCatViewEntity[] = [];
  readonly faTimes = faTimes;
  readonly activityIconMap = ActivityIconMap;
  readonly apiUrl = environment.apiUrl;
  readonly faCheck = faCheck;

  settings;
  @Input() hubId;
  @Input() selectedActivities: string[] = [];
  @Input() error: string;
  @Output() change: EventEmitter<any> = new EventEmitter();

  hasChenge: boolean = false;
  canGoOut = false;
  searchField: string = "";

  constructor(
    private authService: AuthService,
    public router: Router,
    public httpService: HttpService,
    public appSettings: AppSettings,
    public route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {
    this.settings = this.appSettings.settings;
  }

  async ngOnInit() {
    this.user = this.authService.getUserProfile();
    this.settings.loadingSpinner = true;
    await this.retrieveContent();
    this.settings.loadingSpinner = false;
  }

  clearSearchField() {
    this.searchField = "";
    this.filterItems();
  }

  toggle(id: string) {
    const index = this.selectedActivities.indexOf(id);
    if(index === -1) {
      this.selectedActivities.push(id);
    } else {
      this.selectedActivities.splice(index, 1);
    }
    this.hasChenge = true;
    this.change.emit();
  }

  private async retrieveContent() {
    try {
      const URL = `${this.apiUrl}/actcatview/v1/client-search?page=0&size=500&querySearch=hubId:'${this.hubId}' AND contentType!'${ContentType.ACTIVITY_INFO}'&sort=order,asc`;
      const response: PageEntity<ActCatViewEntity> = await firstValueFrom(
        this.httpService.doGet(URL)
      );
      if (response.content && response.content.length > 0) {
        this.originalItems = response.content;
        this.filterItems();
      }
    } catch (error) {
      console.error("Error getting ActCat: ", error);
    }
  }

  async save() {
    this.settings.loadingSpinner = true;
    try {
      const URL = `${this.apiUrl}/navigation-menu/v1/`;
      /*const body: UpdateNavigationMenuCommand = {
        id: this.hubMenu.id,
        hubId: this.hubId,
        userId: this.user.userId,
        items: this.items,
      };
      await firstValueFrom(this.httpService.doPut(URL, body));*/
      this.hasChenge = false;
      this.showSnackBar(
        this.translate.instant("messages.navigation_menu_saved"),
        "OK",
        2000
      );
    } catch (error) {
      console.error("Error getting ActCat: ", error);
    } finally {
      this.settings.loadingSpinner = false;
    }
  }

  filterItems() {
    let temItems = [...this.originalItems];
    if (this.searchField.length > 2) {
      const localSearchField = this.searchField.toLocaleLowerCase();
      temItems = temItems.filter((item: ActCatViewEntity) => {
        if (
          !item.content.name.toLocaleLowerCase().startsWith(localSearchField)
        ) {
          return false;
        }
        return true;
      });
    }
    this.filteredItems = temItems.filter((item: ActCatViewEntity) => {
      return !this.items.find(
        (innerItem: ActCatViewEntity) =>
          innerItem.id === item.id
      );
    });
  }

  showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }
}
