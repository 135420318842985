<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
  <button (click)="close()" mat-button><span translate>tinyAll.back</span></button>
  <button (click)="save()" color="accent" mat-raised-button [disabled]="selectedAddressIndex === undefined">
    <span translate>tinyAll.save</span>
  </button>
  
</div>

<div class="page-container" fxLayout="row wrap" fxLayoutAlign="center start">
  <h2 class="title" translate>titles.my_addresses</h2>
  <div
    class="list-container"
    fxLayout="column"
    fxLayoutGap="5px"
    fxLayoutAlign="center"
  >
    <mat-card class="card-container" *ngFor="let location of user.locations; let i = index;" [ngClass]="{ selected: selectedAddressIndex === i }" (click)="selectAddress(i)">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="p-1">
          <span class="label">{{ location.name }}</span><span *ngIf="location.defaultLocation" class="default-label" translate>formFields.default</span>
          <p>{{ location.address.address }}, {{ location.address.address2 }}</p>
          <p>
            {{ location.address.city }}, {{ location.address.stateOrProvidence }},
            {{ getCountryName(location.address.country) }}
          </p>
          <p>{{ location.address.zipCode }}</p>
          <p>{{ location.address.locationHint }}</p>
        </div>
        <mat-icon *ngIf="selectedAddressIndex === i">check</mat-icon>
      </div>
    </mat-card>
  </div>

</div>
