import { UntypedFormGroup } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { DatetimeAdapter } from '@mat-datetimepicker/core';
import {TranslateService} from '@ngx-translate/core';
import days from '../../util/days';

@Component({
  selector: 'app-schedule-picker',
  templateUrl: './schedule-picker.component.html',
  styleUrls: ['./schedule-picker.component.scss']
})
export class SchedulePickerComponent implements OnInit {

  @Input() showTitle: boolean;

  @Input() public schedule: UntypedFormGroup;
  DAYS = days;
  weekDays = [];
  hours = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0, 1, 2, 3, 4, 5];
  
  constructor(
    public _adapter: DatetimeAdapter<Date>,
    private translate: TranslateService,
  ) {
    _adapter.setLocale(translate.getDefaultLang());
    (_adapter as any)._delegate.setLocale(translate.getDefaultLang());
  }
  
  ngOnInit(): void {
    this.weekDays = this._adapter.getDayOfWeekNames('short'); // 'long' | 'short' | 'narrow'
  }

  getArray(size: number) {
    return Array(size).fill('D');
  }

  getHourAMPM(hour: number): string {
    if (hour === 12) {
      return '12 PM';
    } else if (hour === 0) {
      return '12 AM';
    }
    return hour > 12 ? `${hour - 12} PM` : `${hour} AM`;
  }
}
