import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { MatCalendar } from "@angular/material/datepicker";
import { DatetimeAdapter } from "@mat-datetimepicker/core";
import { DateAdapter } from "@angular/material/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-days-off-picker",
  templateUrl: "./days-off-picker.component.html",
  styleUrls: ["./days-off-picker.component.scss"],
})
export class DaysOffPickerComponent {
  @Input() showTitle: boolean;

  @Input() set daysSelected(value: Set<string>) {
    this._daysSelected = value;
    if (this.calendar) {
      this.calendar.updateTodaysDate();
    }
  }
  @Input() noCard: boolean = false;

  @Output() changed = new EventEmitter<any>();

  @ViewChild("calendar") calendar: MatCalendar<Date>;

  menuTimer: any;
  _daysSelected: Set<String>;

  constructor(
    public _adapter: DatetimeAdapter<Date>,
    public _dateAdapter: DateAdapter<Date>,
    private translate: TranslateService
  ) {
    _adapter.setLocale(translate.getDefaultLang());
    _dateAdapter.setLocale(translate.getDefaultLang());

    (_adapter as any)._delegate.setLocale(translate.getDefaultLang());
  }

  isSelected = (event: any) => {
    const date = `${event.toISOString().split("T")[0]}T00:00:00Z`;
    return this._daysSelected.has(date) ? "days-off-selected" : null;
  };

  select(event: any, calendar: any) {
    const date = `${event.toISOString().split("T")[0]}T00:00:00Z`;
    if (!this._daysSelected.has(date)) {
      this._daysSelected.add(date);
    } else {
      this._daysSelected.delete(date);
    }
    calendar.updateTodaysDate();
    this.changed.emit();
  }

  panelOpened() {
    if (this.menuTimer) {
      clearTimeout(this.menuTimer);
    }
    const el: any = document.getElementsByClassName("cdk-overlay-pane")[0];
    if (el) {
      el.classList.add("days-off-picker-panel-overlay");
    }
  }

  panelClosed() {
    this.menuTimer = setTimeout(() => {
      const el: any = document.getElementsByClassName("cdk-overlay-pane")[0];
      if (el) {
        el.classList.remove("days-off-picker-panel-overlay");
      }
    }, 500);
  }
}
