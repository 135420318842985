import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppSettings } from '../../app.settings';
import { AuthService } from '../../auth/auth.serviceV2';
import { ChangeLog, PageEntity } from '../../models/types';
import { HttpService } from '../../service/http.service';
import { YesNoDialogComponent } from '../yes-no-dialog/yes-no-dialog.component';
import { CreateReportsCommentCommand, ReportsCommentEntity } from '../../models/reports-comment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reports-logs-view',
  templateUrl: './reports-logs-view.component.html',
  styleUrls: ['./reports-logs-view.component.scss'],
})
export class ReportsLogsViewComponent {

  @Input() logs: ChangeLog[] = [];

  user: any;
  settings;
  pageSize = 10;
  pageIndex = 0;
  length = 0;
  pageSizeOptions = [10, 20, 50];

  constructor(
    protected appSettings: AppSettings,
  ) {
    this.settings = this.appSettings.settings;
  }


  pageEvent(pageChangedEvent: any) {
    this.pageIndex = pageChangedEvent.pageIndex;
    this.pageSize = pageChangedEvent.pageSize;

  }

}
