import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { StarRatingComponent } from 'ng-starrating';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.serviceV2';
import UserProfile from '../../models/user-profile-model';
import { HttpService } from '../../service/http.service';
import { YesNoDialogComponent } from '../yes-no-dialog/yes-no-dialog.component';
import entityTypeToUrlMap from '../../util/entity-type-to-url-map';
import { firstValueFrom } from 'rxjs';
import {
  CreateReviewCommand,
  ReviewEntity,
  UpdateReviewCommand,
} from '../../models/review';
import { EntityType } from '../../models/entity-type-enum';

@Component({
  selector: 'app-comment-management',
  templateUrl: './comment-management.component.html',
  styleUrls: ['./comment-management.component.scss'],
})
export class CommentManagementComponent implements OnInit {
  @Input() entityId: string;
  @Input() entityType: string;

  comment: ReviewEntity;
  expanded = false;
  isEditing = false;
  loading = false;
  form: UntypedFormGroup;
  user: UserProfile;
  private readonly apiUrl = environment.apiUrl;

  constructor(
    private dialog: MatDialog,
    private httpService: HttpService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private translate: TranslateService
  ) {}

  get commentField() {
    return this.form.get('comment');
  }

  get rate(): number {
    return this.form.get('rate').value;
  }

  ngOnInit(): void {
    this.user = this.authService.getUserProfile();
    this.form = this.fb.group({
      comment: ['', Validators.maxLength(300)],
      entityId: [this.entityId],
      userId: [this.user?.userId],
      rate: [0, Validators.min(1)],
    });

    if (this.user) {
      this.search();
    }
  }

  setForm(response: any) {
    this.form.patchValue({ rate: response.rate });
    this.form.patchValue({ comment: response.comment });
    this.form.markAsPristine();
  }

  cancel() {
    this.isEditing = false;
  }

  delete(): void {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      minWidth: '240px',
      data: {
        title: 'messages.delete_comment_question',
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        let url = `${this.apiUrl}/review/v1/${this.entityType}/${this.entityId}`;
        this.loading = true;
        try {
          await firstValueFrom(this.httpService.doDelete(url));
          this.comment = null;
          this.form.reset();
          this.isEditing = true;
          this.showSnackBar(
            this.translate.instant('messages.comment_deleted'),
            'OK',
            2000
          );
        } catch (error) {
          console.log('Error: ', error);
          this.showSnackBar(
            this.translate.instant('messages.error_processing_request'),
            'OK',
            2000
          );
        } finally {
          this.loading = false;
        }
      }
    });
  }

  onRate($event: {
    oldValue: number;
    newValue: number;
    starRating: StarRatingComponent;
  }) {
    if ($event.oldValue !== $event.newValue) {
      this.form.get('rate').setValue($event.newValue);
      this.form.markAsDirty();
    }
  }

  onSubmit() {
    if (this.comment) {
      this.update();
    } else {
      this.create();
    }
  }

  async search() {
    const url = `${this.apiUrl}/review/v1/me/${this.entityType}/${this.entityId}`;
    this.loading = true;
    try {
      const response: ReviewEntity = await firstValueFrom(
        this.httpService.doGet(url)
      );
      if (response) {
        this.comment = response;
        this.setForm(response);
      } else {
        this.isEditing = true;
      }
    } catch (error) {
      this.isEditing = true;
      console.log('Error: ', error);
    } finally {
      this.loading = false;
    }
  }

  async update() {
    const url = `${this.apiUrl}/review/v1`;
    this.loading = true;
    const rawValues = this.form.getRawValue();
    rawValues['comment'] = rawValues['comment'].trim();

    const { userId, entityId, rate, comment } = rawValues;

    const requestBody: UpdateReviewCommand = {
      userId,
      entityId,
      rate,
      comment,
      entityType: this.entityType as EntityType,
    };

    try {
      const localComment = await firstValueFrom(this.httpService.doPut(url, requestBody));
      this.comment = {
        ...localComment,
        userProfileImage: this.user.picture,
        rate,
        comment,
        updatedOn: new Date().toISOString(),
        createdOn: new Date().toISOString()
      };
      this.isEditing = false;
      this.showSnackBar(
        this.translate.instant('messages.thank_you_for_your_comment'),
        'OK',
        2000
      );
    } catch (error) {
      console.log('Error: ', error);
      this.showSnackBar(
        this.translate.instant('messages.error_processing_request'),
        'OK',
        2000
      );
    } finally {
      this.loading = false;
    }
  }

  async create() {
    const url = `${this.apiUrl}/${
      entityTypeToUrlMap[this.entityType]
    }/v1/review`;
    this.loading = true;
    const rawValues = this.form.getRawValue();
    rawValues['comment'] = rawValues['comment'].trim();

    const { userId, entityId, rate, comment } = rawValues;

    const requestBody: CreateReviewCommand = {
      userId,
      entityId,
      rate,
      comment,
    };
    try {
      const localComment = await firstValueFrom(this.httpService.doPost(url, requestBody));
      this.comment = {
        ...localComment,
        userProfileImage: this.user.picture,
        rate,
        comment,
        updatedOn: new Date().toISOString(),
        createdOn: new Date().toISOString()
      };
      this.isEditing = false;
      this.showSnackBar(
        this.translate.instant('messages.thank_you_for_your_comment'),
        'OK',
        2000
      );
    } catch (error) {
      console.log('Error: ', error);
      this.showSnackBar(
        this.translate.instant('messages.error_processing_request'),
        'OK',
        2000
      );
    } finally {
      this.loading = false;
    }
  }

  showSnackBar(message, action, duration, panelClass = '') {
    this.snackBar.open(message, action, {
      duration: duration,
      panelClass,
    });
  }
}
