import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-promo-banner',
  templateUrl: './promo-banner.component.html',
  styleUrls: ['./promo-banner.component.scss']
})
export class PromoBannerComponent {

  @Output() onCopy: EventEmitter<string> = new EventEmitter();

  copyText: string;
  code: string = 'ALLAPP';
  
  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    this.copyText = this.translate.instant('labels.copy_text');
  }

  copyToClipboard(event: MouseEvent) {
    event.stopPropagation();
    const el = document.createElement('textarea');
    el.value = this.code;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.onCopy.emit(this.code);
    this.showSnackBar(this.copyText, 'OK', 2000);
  }

  private showSnackBar(message, action, duration) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }
}