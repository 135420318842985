<div fxLayout="row" fxLayoutAlign="space-between center">
  <button (click)="back()" mat-button>
    <span translate>tinyAll.back</span>
  </button>
  <app-client-view-button
    class="mr-4"
    [url]="clientViewUrl"
  ></app-client-view-button>
</div>

<div fxLayout="row wrap" class="page-container">
  <app-banned-message *ngIf="hub?.restricted"></app-banned-message>
  <app-subscription-status
    *ngIf="user"
    [status]="getSubStatus()"
    [provider]="user?.subProvider"
    [expirationDate]="user.subValidUntil"
    [linkToPricing]="true"
  ></app-subscription-status>

  <app-hub-readiness-checklist
    [handler]="handler"
    [hub]="hub"
    [items]="items"
  ></app-hub-readiness-checklist>

  <app-hub-cloner
    *ngIf="hub && (!items || items.length === 0)"
    [userId]="hub.userId"
    [hubId]="hub.id"
    [handler]="handler"
  ></app-hub-cloner>

  <div
    fxFlex="100"
    class="flex-p cursor-pointer"
    [ngClass]="{ 'bg-important rounded-md mx-2 mb-4 mt-2': hub && !hub.active }"
    (click)="gotoManageHub()"
  >
    <app-warning-message
      *ngIf="hub && !hub.active"
      message="messages.inactive_hub"
    >
    </app-warning-message>
    <mat-card class="custom-card">
      <mat-card-header class="p-1 flex justify-between items-center">
        <mat-card-subtitle class="m-0 hub-title" fxLayoutAlign="center">
          <h3 *ngIf="hub; else noHub" class="font-semibold" translate>
            hubOwnerView.manage_your_hub
          </h3>
          <ng-template #noHub>
            <h3 class="font-semibold" translate>
              hubOwnerView.manage_your_hub_no_hub
            </h3>
          </ng-template>
        </mat-card-subtitle>
        <button
          class="text-accent w-fit font-semibold flex items-center mr-4 mt-1.5"
          (click)="openInfo($event)"
        >
          <mat-icon>info</mat-icon>
        </button>
      </mat-card-header>
      <img
        mat-card-image
        class="max-w-none"
        src="{{ hub?.image || noImage }}"
        alt="hub"
      />
      <mat-card-content class="p-1">
        <h3>{{ hub?.name }}</h3>
        <span (click)="handleDescriptionClick($event)">
          <app-collapsible-description
            [text]="hub?.infoText"
            [collapsible]="true"
            [hideCard]="true"
            [expandedByDefault]="false"
          ></app-collapsible-description>
        </span>

        <app-url-handler-example
          *ngIf="hub"
          title="titles.link_to_share"
          [urlHandler]="hub?.urlHandler"
          [copy]="true"
        ></app-url-handler-example>
        <app-qr-generator-card
          *ngIf="qrCode"
          [text]="qrCode"
          [imageUrl]="hub?.image || noImage"
        ></app-qr-generator-card>
      </mat-card-content>
    </mat-card>
  </div>

  <hub-payment-card *ngIf="hub" [handler]="handler"></hub-payment-card>

  <app-hub-promo-codes-card
    *ngIf="hub"
    [handler]="handler"
  ></app-hub-promo-codes-card>

  <app-hub-create-activity-card
    *ngIf="hub"
    [hubId]="hub.id"
    [handler]="handler"
  ></app-hub-create-activity-card>

  <app-hub-navigation-menu-card
    *ngIf="hub"
    [hubId]="hub.id"
    [handler]="handler"
  ></app-hub-navigation-menu-card>

  <app-hub-sort-activities-card
    *ngIf="hub"
    [hubId]="hub.id"
    [handler]="handler"
  ></app-hub-sort-activities-card>

  <app-hub-reports *ngIf="hub" [handler]="handler"></app-hub-reports>

  <app-info-banner
    class="m-2 mt-4"
    title="subtitles.hub_owner_activities_section_title"
    description="messages.hub_owner_activities_section"
    [params]="{ activeCount: activeCount, inactiveCount: inactiveCount }"
  ></app-info-banner>

  <app-activity-search-bar
    id="app-top-search-bar"
    *ngIf="hub"
    [items]="searchCriteria"
    [options]="searchCriteraOptions"
    (onClickFilter)="openFilter($event)"
    (onClickSort)="openSort($event)"
    (onSearhChange)="searchCriteriaChanged($event)"
  >
  </app-activity-search-bar>

  <div
    *ngIf="loading && !settings.loadingSpinner; else noLoading"
    class="loading"
    fxLayout="column"
    fxLayoutGap="20px"
    fxLayoutAlign="center center"
  >
    <mat-spinner color="primary" diameter="60"></mat-spinner>
  </div>

  <ng-template #noLoading>
    <div
      *ngFor="let item of items"
      io-view
      (inView)="item['show'] = true"
      fxFlex="100"
      class="flex-p"
    >
      <app-hub-activity
        id="item.id"
        *ngIf="item.show && isActivity(item.contentType)"
        [activity]="item"
        [handler]="handler"
        [isOwnerView]="true"
        (click)="goToActivity($event)"
      >
      </app-hub-activity>
      <app-hub-category
        id="item.id"
        *ngIf="item.show && isCategory(item.contentType)"
        [category]="item"
        [handler]="handler"
        [isOwnerView]="true"
        (click)="goToCategory($event)"
      >
      </app-hub-category>
    </div>
  </ng-template>
</div>

<div *ngIf="hub" fxLayout="row">
  <button
    class="report-speed-dial-action-button-fix !z-10"
    (click)="gotoReports()"
    mat-fab
    color="primary"
  >
    <span translate>reports.title</span>
    <mat-icon>list_alt</mat-icon>
  </button>
  <app-speed-dial-fab
    [hubId]="hub.id"
    [handler]="handler"
    [canSort]="items && items.length > 1"
  >
  </app-speed-dial-fab>
</div>
<app-loading></app-loading>
