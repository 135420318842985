import { Validators, UntypedFormGroup } from '@angular/forms';
import { AnswerTypeClass } from '../models/answer-type-class';
import { Question } from '../models/types';
import { ImageService } from '../service/image.service';
import questionsManagementUtils from './questionsManagementUtil';
import { QuestionTypeClass } from '../models/question-type-class';

const createQuestionsForm = (questions: Question[]): Object => {
  const questionMap = {};
  if (!questions) return questionMap;
  for (const QUESTIONV2 of questions) {
    const QUESTION =
      questionsManagementUtils.transformQuestionV2ToV1(QUESTIONV2);
    questionMap[QUESTION.question] = QUESTION.required
      ? [null, Validators.required]
      : [null];
    if (QUESTION.type === 'GROUP') {
      for (const INNER_QUESTION_KEY of Object.keys(QUESTION.groups)) {
        for (const INNER_QUESTION of QUESTION.groups[INNER_QUESTION_KEY]) {
          questionMap[INNER_QUESTION.question] = [null];
        }
      }
    }
  }
  return questionMap;
};

const cleanImages = (
  questions: any[],
  form: UntypedFormGroup,
  imageService: ImageService
): void => {
  if (!questions) {
    return;
  }
  for (const question of questions) {
    if (question.type === 'IMAGE') {
      const questonControl = form.controls[question.question];
      if (questonControl.value) {
        imageService.deleteImage(questonControl.value).subscribe(
          (r) => {},
          (err) => {}
        );
      }
    } else if (question.type === 'GROUP') {
      for (const key of Object.keys(question.groups)) {
        for (const inner_question of question.groups[key]) {
          if (inner_question.type === 'IMAGE') {
            const innerQuestonControl = form.controls[inner_question.question];
            if (innerQuestonControl.value) {
              imageService.deleteImage(innerQuestonControl.value).subscribe(
                (r) => {},
                (err) => {}
              );
            }
          }
        }
      }
    }
  }
};

const cleanContent = (questions: any[], formValue: any): any => {
  const answers = {};
  if (!questions) {
    return answers;
  }
  for (const QUESTIONV2 of questions) {
    const QUESTION =
      questionsManagementUtils.transformQuestionV2ToV1(QUESTIONV2);
    const ANSWER = formValue.questionAnswerMap[QUESTION.question];
    if (ANSWER) {
      if (QUESTION.type === 'SINGLE_CHOICE_PRICE') {
        answers[QUESTION.question] = {
          questionType: QUESTION.type,
          values: {
            [ANSWER]: Number(QUESTION.values[ANSWER]).toFixed(2),
          },
        };
      } else if (QUESTION.type === 'MULTI_CHOICE_PRICE') {
        if (ANSWER.length > 0) {
          const VALUE = {};
          for (const PISE of ANSWER) {
            VALUE[PISE] = Number(QUESTION.values[PISE]).toFixed(2);
          }
          answers[QUESTION.question] = {
            questionType: QUESTION.type,
            values: VALUE,
          };
        }
      } else if (QUESTION.type === 'MULTI_CHOICE') {
        if (ANSWER.length > 0) {
          answers[QUESTION.question] = {
            questionType: QUESTION.type,
            choices: ANSWER,
          };
        }
      } else if (QUESTION.type === 'DATE') {
        answers[QUESTION.question] = {
          questionType: QUESTION.type,
          value: ANSWER.toISOString().split('T')[0],
        };
      } else if (QUESTION.type === 'TIME') {
        answers[QUESTION.question] = {
          questionType: QUESTION.type,
          value: ANSWER.toISOString().split('T')[1].split('.')[0],
        };
      } else if (QUESTION.type === 'GEOPOINT') {
        answers[QUESTION.question] = {
          questionType: QUESTION.type,
          geoValue: ANSWER,
        };
      } else if (QUESTION.type === 'GROUP') {
        answers[QUESTION.question] = {
          questionType: QUESTION.type,
          value: ANSWER,
        };
        for (const INNER_QUESTION of QUESTION.groups[ANSWER]) {
          const INNER_ANSWER =
            formValue.questionAnswerMap[INNER_QUESTION.question];
          if (INNER_ANSWER) {
            if (INNER_QUESTION.type === 'SINGLE_CHOICE_PRICE') {
              answers[INNER_QUESTION.question] = {
                questionType: INNER_QUESTION.type,
                values: {
                  [INNER_ANSWER]: Number(
                    INNER_QUESTION.values[INNER_ANSWER]
                  ).toFixed(2),
                },
              };
            } else if (INNER_QUESTION.type === 'MULTI_CHOICE_PRICE') {
              if (INNER_ANSWER.length > 0) {
                const VALUE = {};
                for (const PISE of INNER_ANSWER) {
                  VALUE[PISE] = Number(INNER_QUESTION.values[PISE]).toFixed(2);
                }
                answers[INNER_QUESTION.question] = {
                  questionType: INNER_QUESTION.type,
                  values: VALUE,
                };
              }
            } else if (INNER_QUESTION.type === 'MULTI_CHOICE') {
              if (INNER_ANSWER.length > 0) {
                answers[INNER_QUESTION.question] = {
                  questionType: INNER_QUESTION.type,
                  choices: INNER_ANSWER,
                };
              }
            } else if (INNER_QUESTION.type === 'DATE') {
              answers[INNER_QUESTION.question] = {
                questionType: INNER_QUESTION.type,
                value: INNER_ANSWER.toISOString().split('T')[0],
              };
            } else if (INNER_QUESTION.type === 'TIME') {
              answers[INNER_QUESTION.question] = {
                questionType: INNER_QUESTION.type,
                value: INNER_ANSWER.toISOString().split('T')[1].split('.')[0],
              };
            } else if (INNER_QUESTION.type === 'GEOPOINT') {
              answers[INNER_QUESTION.question] = {
                questionType: INNER_QUESTION.type,
                geoValue: INNER_ANSWER,
              };
            } else {
              answers[INNER_QUESTION.question] = {
                questionType: INNER_QUESTION.type,
                value: INNER_ANSWER,
                values: {
                  hidden: INNER_QUESTION.hidden,
                },
              };
            }
          }
        }
      } else {
        answers[QUESTION.question] = {
          questionType: QUESTION.type,
          value: ANSWER,
          values: {
            hidden: QUESTION.hidden,
          },
        };
      }
    }
  }
  return answers;
};

const transformAnswersV1ToV2 = (
  answersV1,
  quiestions = [],
  conditionalQuestion = null,
  group = null
) => {
  const quiestionsMap = quiestions.reduce((prev, current) => {
    if (current._class === QuestionTypeClass.GROUP) {
      return {
        ...prev,
        [current.question]: {
          ...current,
          groupQuestionMap: Object.keys(current.groupQuestionMap).reduce(
            (iprev, ikey) => {
              return {
                ...iprev,
                [ikey]: current.groupQuestionMap[ikey].reduce(
                  (iiprev, iicurrent) => {
                    return {
                      ...iiprev,
                      [iicurrent.question]: iicurrent,
                    };
                  },
                  {}
                ),
              };
            },
            {}
          ),
        },
      };
    }
    prev[current.question] = current;

    return prev;
  }, {});

  const response = Object.keys(answersV1).reduce((prev, current) => {
    const { questionType, value, choices, geoValue, values } =
      answersV1[current];

    if (
      (quiestions &&
        !conditionalQuestion &&
        !group &&
        !quiestionsMap[current]) ||
      (conditionalQuestion && group && quiestionsMap[current]) ||
      (conditionalQuestion &&
        group &&
        !quiestionsMap[conditionalQuestion].groupQuestionMap[group][current])
    ) {
      return prev;
    }

    let answer = value;
    if (questionType === 'MULTI_CHOICE') {
      answer = choices;
    } else if (questionType === 'GEOPOINT') {
      answer = geoValue;
    } else if (questionType === 'SINGLE_CHOICE_PRICE') {
      answer = Object.keys(values).map((key) => ({
        option: key,
        price: Number(values[key]),
      }))[0];
    } else if (questionType === 'MULTI_CHOICE_PRICE') {
      answer = Object.keys(values).map((key) => ({
        option: key,
        price: Number(values[key]),
      }));
    }

    let answerObj: any = {
      _class: AnswerTypeClass[questionType],
      value: answer,
    };

    if (questionType === 'TEXT' || questionType === 'NUMBER') {
      answerObj.hidden = values?.hidden || false;
    }

    if (questionType === 'GROUP') {
      group:
      answerObj.questionAnswerMap = transformAnswersV1ToV2(
        answersV1,
        quiestions,
        current,
        answer
      );
      
      answerObj.group = answer;
      answerObj.value = answerObj.questionAnswerMap[Object.keys(answerObj.questionAnswerMap)[0]].value;
    }

    return {
      ...prev,
      [current]: answerObj,
    };
  }, {});
  return response;
};

export default {
  createQuestionsForm,
  cleanImages,
  cleanContent,
  transformAnswersV1ToV2,
};
