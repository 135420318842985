import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormArray } from "@angular/forms";
import { RxwebValidators } from "@rxweb/reactive-form-validators";
import { QuestionClassType } from "../models/question-class-type";
import { QuestionTypeClass } from "../models/question-type-class";

const createQuestionFromResponse = (
  questionV2: any,
  fb: UntypedFormBuilder,
  optionName: string,
  transform: boolean = true
): UntypedFormGroup => {
  const question = transform ? transformQuestionV2ToV1(questionV2) : questionV2;
  const createOption = (
    inOptionName: string,
    inOptionPrice: string
  ): UntypedFormGroup => {
    return fb.group({
      name: [inOptionName, [Validators.required, RxwebValidators.unique()]],
      price: [inOptionPrice],
    });
  };
  const OPTIONS = [];
  const OPTION_CONTENT = [];

  if (["MULTI_CHOICE", "SINGLE_CHOICE"].includes(question.type)) {
    for (const OPTION of question.choices) {
      OPTIONS.push(createOption(OPTION, "0.00"));
      const INNER_QUESTIONS = [];
      OPTION_CONTENT.push(fb.array(INNER_QUESTIONS));
    }
  } else if (question.type === "GROUP") {
    for (const OPTION of Object.keys(question.groups)) {
      OPTIONS.push(createOption(OPTION, "0.00"));
      const INNER_QUESTIONS = [];
      if (question.groups[OPTION]) {
        for (const QUESTION of question.groups[OPTION]) {
          INNER_QUESTIONS.push(
            createQuestionFromResponse(QUESTION, fb, optionName, false)
          );
        }
      }
      OPTION_CONTENT.push(fb.array(INNER_QUESTIONS));
    }
  } else if (
    ["MULTI_CHOICE_PRICE", "SINGLE_CHOICE_PRICE"].includes(question.type)
  ) {
    for (const OPTION of Object.keys(question.values)) {
      OPTIONS.push(createOption(OPTION, question.values[OPTION]));
      const INNER_QUESTIONS = [];
      OPTION_CONTENT.push(fb.array(INNER_QUESTIONS));
    }
  } else {
    OPTIONS.push(createOption(`${optionName} 1`, "0.00"));
    OPTIONS.push(createOption(`${optionName} 2`, "0.00"));
    OPTION_CONTENT.push(fb.array([]));
    OPTION_CONTENT.push(fb.array([]));
  }

  return fb.group({
    content: fb.array(OPTIONS),
    question: [
      question.question,
      [Validators.required, RxwebValidators.unique()],
    ],
    description: [question.description],
    required: [question.required],
    hidden: [question.hidden],
    type: [
      ["MULTI_CHOICE_PRICE", "SINGLE_CHOICE_PRICE"].includes(question.type)
        ? question.type.replace("CHOICE_PRICE", "CHOICE")
        : question.type,
      Validators.required,
    ],
    questionGroups: fb.array(OPTION_CONTENT),
    price: [
      ["MULTI_CHOICE_PRICE", "SINGLE_CHOICE_PRICE"].includes(question.type),
    ],
  });
};

const transformQuestionV2ToV1 = (questionV2: any) => {
  const question = {
    ...questionV2,
  };

  question.type = QuestionClassType[question._class];
  if (question.type === "GROUP") {
    question.groups = Object.keys(question.groupQuestionMap).reduce(
      (prev, current) => {
        return {
          ...prev,
          [current]: transformQuestionsV2ToV1(
            question.groupQuestionMap[current]
          ),
        };
      },
      {}
    );

    const OPTIONS = [];
    for (const option in question.groups) {
      OPTIONS.push(option);
    }
    question.content = OPTIONS;
  } else if (
    ["MULTI_CHOICE_PRICE", "SINGLE_CHOICE_PRICE"].includes(question.type)
  ) {
    question.values = question.priceOptions.reduce(
      (prev, { option, price }) => ({
        ...prev,
        [option]: price,
      }),
      {}
    );

    const OPTIONS = [];
    for (const option of question.priceOptions) {
      OPTIONS.push({
        name: option.option,
        price: option.price,
      });
    }
    question.content = OPTIONS;
  }
  delete question._class;
  delete question.priceOptions;
  delete question.groupQuestionMap;
  return question;
};

const transformQuestionsV2ToV1 = (questionsV2) => {
  if (!questionsV2) return [];
  return questionsV2.map((question) => transformQuestionV2ToV1(question));
};

const createSelectedContainer = (
  questions: UntypedFormArray
): Map<number, Map<number, Set<number>>> => {
  const selectedContainer = new Map();
  let index = 0;
  for (const QUESTION of questions.controls) {
    const newMap = new Map();
    const OPTIONS_CONTENT_ARRAY = QUESTION.get("questionGroups") as UntypedFormArray;
    let innerIndex = 0;
    for (const INNER of OPTIONS_CONTENT_ARRAY.controls) {
      newMap.set(innerIndex, new Set());
      innerIndex++;
    }
    selectedContainer.set(index, newMap);
    index++;
  }

  return selectedContainer;
};

const cleanContent = (questions: Array<any>): Array<any> => {
  const questionList = [];
  for (const question of questions) {
    const QUESTION = Object.assign({}, question);

    if (QUESTION.price) {
      QUESTION.type = QUESTION.type.replace("CHOICE", "CHOICE_PRICE");
    }

    delete QUESTION.price;

    if (["MULTI_CHOICE", "SINGLE_CHOICE"].includes(QUESTION.type)) {
      const OPTIONS = [];
      for (const option of QUESTION.content) {
        OPTIONS.push(option.name);
      }
      QUESTION.choices = OPTIONS;
    } else if (QUESTION.type === "GROUP") {
      const CONTENT = {};
      for (let i = 0; i < QUESTION.questionGroups.length; i++) {
        CONTENT[QUESTION.content[i].name] = cleanContent(
          QUESTION.questionGroups[i]
        );
      }
      QUESTION.groups = CONTENT;
    } else if (
      ["MULTI_CHOICE_PRICE", "SINGLE_CHOICE_PRICE"].includes(QUESTION.type)
    ) {
      const OPTIONS = {};
      for (const option of QUESTION.content) {
        OPTIONS[option.name] = option.price || "0.00";
      }
      QUESTION.values = OPTIONS;
    } else {
      delete QUESTION.content;
    }

    delete QUESTION.questionGroups;
    questionList.push(QUESTION);
  }
  //const a = cleanContentV2(questionList);
  return cleanContentV2(questionList);
  //return questionList;
};

const cleanContentV2 = (questions: Array<any>): Array<any> => {
  const questionList = [];
  for (const question of questions) {
    const QUESTION = Object.assign({}, question);
    if (QUESTION.type === "GROUP") {
      QUESTION.groupQuestionMap = QUESTION.content.reduce(
        (prev, { name }) => ({
          ...prev,
          [name]: QUESTION.groups[name],
        }),
        {}
      );
    } else if (
      ["MULTI_CHOICE_PRICE", "SINGLE_CHOICE_PRICE"].includes(QUESTION.type)
    ) {
      const OPTIONS = [];
      for (const option of QUESTION.content) {
        OPTIONS.push({
          option: option.name,
          price: option.price,
        });
      }
      QUESTION.priceOptions = OPTIONS;
    }
    QUESTION._class = QuestionTypeClass[QUESTION.type];
    delete QUESTION.values;
    delete QUESTION.content;
    delete QUESTION.groups;
    delete QUESTION.type;
    questionList.push(QUESTION);
  }
  return questionList;
};

export default {
  createQuestionFromResponse,
  createSelectedContainer,
  cleanContent,
  transformQuestionV2ToV1,
  transformQuestionsV2ToV1,
};
