import { Component, Input } from '@angular/core';
import CURRENCY_SYMBOLS from '../../util/currency-symbols';

@Component({
  selector: 'app-order-product-item',
  templateUrl: './order-product-item.component.html',
  styleUrls: ['./order-product-item.component.scss']
})
export class OrderProductItemComponent {

  @Input() item: any;
  @Input() product: any;
  @Input() currency: string;
  
  readonly noImage = '/assets/img/app/default-product.png';
  readonly currencySymbols = CURRENCY_SYMBOLS;

  constructor() {}

  getTotal(price, quantity) {
    return Number(price) * Number(quantity);
  }

}
