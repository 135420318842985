import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../auth/auth.serviceV2';
import {RouteConstants} from '../../../app.constants';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {

    public noImage = '../assets/img/app/default-user.jpg';
    public userProfile: any;
    userLoggedIn = false;
    currentApplicationVersion = environment.appVersion;

    constructor(
        private auth: AuthService,
        private router: Router,
        protected snackBar: MatSnackBar,
        public translate: TranslateService,
    ) {
        this.userProfile = this.auth.getUserProfile();
    }

    public async ngOnInit() {
        this.userLoggedIn = await this.auth.isAuthenticated();
    }

    async logIn() {
        const result = await this.auth.getLoginRedirectResult();
        if (result) {
            this.userLoggedIn = true;
        } else {
            localStorage.setItem('return_url', `${this.router.url}`);
            await this.router.navigate([RouteConstants.auth]);
        }
    }

    public async logOut() {
        if (this.userLoggedIn) {
            const user = this.auth.getUserProfile();
            await this.auth.logout();
            this.userLoggedIn = false;
            this.showSnackBar(this.translate.instant('messages.goodbye', {
                value: user.name || ''
            }), 'OK', 2000);
        } else {
            localStorage.setItem(RouteConstants.returnUrl, this.router.url);
        }
        await this.router.navigateByUrl(RouteConstants.home);
    }

    async goToUserPreferences() {
        await this.router.navigateByUrl(`${RouteConstants.settings}/user-preferences`);
    }

    showSnackBar(message, action, duration) {
        this.snackBar.open(message, action, {
            duration: duration,
        });
    }
}
