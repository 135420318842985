<div class="list-container w-full">
    <h2 class="subtitle" translate>formFields.linked_activities</h2>
    <mat-form-field appearance="outline" class="search-field">
        <input matInput type="search" [(ngModel)]="searchField" autocomplete="off" (keyup)="filterItems()" (change)="filterItems()" placeholder="{{'formFields.search' | translate}}">
        <button mat-button mat-icon-button matSuffix (click)="clearSearchField()" *ngIf="searchField">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <div fxLayout="column" fxLayoutGap="5px">
        <div class="questions-boundary"
            novalidate 
            fxLayout="column" 
            fxLayoutAlign="center">
            <div *ngFor="let item of filteredItems; let i = index;">
                <mat-card class="card-container">
                    <div fxLayout="row" class="hanlde-container items-center">
                        <fa-icon class="list-item-menu-icon" [icon]="activityIconMap[item.contentType]"></fa-icon>
                        <a class="text-blue-400 ml-2 cursor-pointer" [href]="getActivityUrl(item.content.id)">{{ item.content.name }}</a>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</div>
