import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AppSettings } from '../../app.settings';
import { HttpService } from '../../service/http.service';
import ContentToActivity from '../../util/content-to-activity';
import { ContentType } from '../../models/content-type-enum';
import { ShortUrlType } from '../../models/short-url-type-enum';
import { Meta, Title } from '@angular/platform-browser';
import { setActivityPageHead } from '../../util/seo-utils';
import { firstValueFrom } from 'rxjs';
import { RouteConstants } from '../../app.constants';

@Component({
  selector: 'app-short-url-handler',
  templateUrl: './short-url-handler.component.html',
  styles: [``],
})
export class ShortUrlHandlerComponent implements OnInit {
  settings: any;
  type: string;
  id: string;
  queryParams: string;
  notFound: boolean = false;
  baseURL = environment.appUrl;

  constructor(
    private route: ActivatedRoute,
    protected appSettings: AppSettings,
    private httpService: HttpService,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.type = this.route.snapshot.paramMap.get('type');
    this.id = this.route.snapshot.paramMap.get('id');
    this.queryParams = new URLSearchParams(
      this.route.snapshot.queryParams
    ).toString();
    if (location.hostname === 'localhost') {
      this.baseURL = 'https://localhost:4200';
    }
    this.redirectResolver();
  }

  async redirectResolver() {
    this.settings.loadingSpinner = true;
    if (!this.type || !this.id) {
      this.redirectToNotFount();
      return;
    }

    let decodedId = this.id;
    if (decodedId.length < 36) {
      // uuid shortener logit
    }

    const entity = await this.getEntity(decodedId);

    if (!entity) {
      this.redirectToNotFount();
      return;
    }

    const hub = await this.getHub(entity.hubId);

    if (!hub) {
      this.redirectToNotFount();
      return;
    }

    setActivityPageHead(
      this.titleService,
      this.metaTagService,
      entity.content,
      hub
    );

    let url: string;

    if (ShortUrlType.EVENT_SCANNER === this.type) {
      url = `${this.baseURL}/${hub.urlHandler}/actevent/scanner?hubId=${entity.hubId}&activityId=${entity.id}`;
    } else if (ContentType.CATEGORY === entity.contentType) {
      url = `${this.baseURL}/${hub.urlHandler}/${
        ContentToActivity[entity.contentType]
      }/${entity.restricted ? 'client-view-vip' : 'client-view'}?hubId=${
        entity.hubId
      }&categoryId=${entity.categoryId}`;
    } else {
      url = `${this.baseURL}/${hub.urlHandler}/${
        ContentToActivity[entity.contentType]
      }/client-view?hubId=${entity.hubId}&activityId=${entity.id}`;
    }

    if (this.queryParams) {
      url = `${url}&${this.queryParams}`;
    }

    this.redirectTo(url);
  }

  async getHub(hubId: string) {
    const URL = `${environment.apiUrl}/hubs/v1/${hubId}`;
    try {
      return await this.httpService.doGet(URL).toPromise();
    } catch (error) {
      return;
    }
  }

  async getEntity(id: string) {
    return await this.getActCatEntity(id);
  }

  async getActCatEntity(id: string) {
    const URL = `${environment.apiUrl}/actcatview/v1/client-search?size=1&querySearch=(id:'${id}')`;
    try {
      let response = await firstValueFrom(this.httpService.doGet(URL));
      if (!response || !response.content || response.content.length === 0) {
        const URL2 = `${environment.apiUrl}/shorturl/v1/search?size=1&querySearch=(id:'${id}')`;
        response = await firstValueFrom(this.httpService.doGet(URL2));
      }
      if (
        response &&
        response.content &&
        response.content.length &&
        response.content[0].content
      ) {
        const entity = response.content[0];
        return {
          id: entity.id,
          contentType: entity.contentType,
          hubId: entity.hubId,
          categoryId:
            entity.contentType === ContentType.CATEGORY
              ? entity.id
              : entity.content.categoryId,
          restricted: !!entity.content.restricted,
          content: entity.content,
        };
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  }

  async redirectTo(URL: string) {
    location.replace(URL);
    this.settings.loadingSpinner = false;
  }

  redirectToNotFount() {
    this.notFound = true;
    this.settings.loadingSpinner = false;
  }

  static shortScannerUrl(id: string): string {
    return `su/s/${id}`;
  }

  static longScannerUrl(
    handler: string,
    activityId: string,
    hubId: string
  ): string {
    return `${handler}/actevent/scanner?hubId=${hubId}&activityId=${activityId}`;
  }

  static shortActCatUrl(id: string): string {
    if (location.hostname === 'localhost') {
      return `https://localhost:4200/su/a/${id}`;
    }
    return `${environment.appUrl}/su/a/${id}`;
  }

  static shortHubUrl(handler: string): string {
    if (location.hostname === 'localhost') {
      return `https://localhost:4200/${handler}`;
    }
    return `${environment.appUrl}/${handler}`;
  }

  static longActivityUrl(
    handler: string,
    type: string,
    activityId: string,
    hubId: string
  ): string {
    if (location.hostname === 'localhost') {
      return `https://localhost:4200/${handler}/${type}/client-view?hubId=${hubId}&activityId=${activityId}`;
    }
    return `${environment.appUrl}/${handler}/${type}/client-view?hubId=${hubId}&activityId=${activityId}`;
  }

  static longCategoryUrl(
    handler: string,
    type: string,
    categoryId: string,
    hubId: string
  ): string {
    if (location.hostname === 'localhost') {
      return `https://localhost:4200/${handler}/${type}/client-view?hubId=${hubId}&categoryId=${categoryId}`;
    }
    return `${environment.appUrl}/${handler}/${type}/client-view?hubId=${hubId}&categoryId=${categoryId}`;
  }


  static longOrderUrl(
    handler: string,
    orderId: string
  ): string {
    if (location.hostname === 'localhost') {
      return `https://localhost:4200/${handler}/reports/${orderId}`;
    }
    return `${environment.appUrl}/${handler}/reports/${orderId}`;
  }

  static longOrderUrlWithoutHandler(
    orderId: string
  ): string {
    return ShortUrlHandlerComponent.longOrderUrl(RouteConstants.hub, orderId);
  }


  back() {
    location.replace(environment.appUrl);
  }
}
