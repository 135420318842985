<div class="scannerContainer">
  <zxing-scanner
    #scanner
    [enable]="scannerEnabled"
    [(device)]="desiredDevice"
    [torch]="torch"
    (torchCompatible)="onTorchCompatible($event)"
    (camerasFound)="camerasFoundHandler($event)"
    (camerasNotFound)="camerasNotFoundHandler($event)"
    (scanSuccess)="successScanHandler($event)"
  >
  </zxing-scanner>
  <div class="scannerBar"></div>
</div>
<div class="actions" fxLayout="row" fxLayoutAlign="space-around center">
  <button mat-icon-button (click)="flipCamera()" [disabled]="!showFlip">
    <mat-icon>switch_camera</mat-icon>
  </button>
  <button mat-icon-button (click)="toggleFlash()" [disabled]="!showTorch">
    <mat-icon *ngIf="!torch">flash_on</mat-icon>
    <mat-icon *ngIf="torch">flash_off</mat-icon>
  </button>
</div>
