import { Component, Input } from '@angular/core';
import { PaymentType } from '../../models/payment-type-enum';
import { faLink, faWallet } from '@fortawesome/free-solid-svg-icons';
import {
  faCreditCard,
  faMoneyBillAlt,
} from '@fortawesome/free-regular-svg-icons';
import { CashReturn } from '../../models/cash-return-enum';

@Component({
  selector: 'app-chosen-payment-method',
  templateUrl: './chosen-payment-method.component.html',
  styleUrls: ['./chosen-payment-method.component.scss'],
})
export class ChosenPaymentMethodComponent {
  @Input() chosenPaymentType: string;
  @Input() paymentImage: string;
  @Input() cashReturn?: CashReturn;

  readonly faWallet = faWallet;
  readonly faCreditCard = faCreditCard;
  readonly faMoneyBillAlt = faMoneyBillAlt;
  readonly faLink = faLink;

  get PaymentType() {
    return PaymentType;
  }

  getAmount(value?: CashReturn) {
    if(!value) return '';
    switch(value) {
      case CashReturn.TWO_THOUSAND:
        return 'RD$ 2,000';
      case CashReturn.ONE_THOUSAND:
        return 'RD$ 1,000';
      case CashReturn.FIVE_HUNDRED:
        return 'RD$ 500';
      case CashReturn.TWO_HUNDRED:
        return 'RD$ 200';
      case CashReturn.ONE_HUNDRED:
        return 'RD$ 100';
    }
  }
}
